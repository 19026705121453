import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { landingPageRoute } from "./constants";

export default function RedirectPage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(landingPageRoute)
    });

    return <></>;
}