import React, { useState, useContext } from 'react';
import { Button, Typography, Box, CircularProgress, TextField, MenuItem } from '@mui/material';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { FeedbackContext } from '../../../App';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import APIService from '../../../api/apiService';
import { popupClasses } from '../../utility';

const MetrcTagImportBtn = (props) => {
    const {onImportComplete, farms, currDarkThemeClass } = props

  const [popupOpen, setPopupOpen] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [isFarmSelected, setIsFarmSelected] = useState(false);
  const [tagsCount, setTagsCount] = useState(0);
  const [duplicates, setDuplicates] = useState([]);
  const [checkingDuplicates, setCheckingDuplicates] = useState(false);
  const [importLoading, setImportLoading] = useState(false);

  const feedbackCx = useContext(FeedbackContext);

  const handleImport = () => {
    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validFileTypes = [
        'application/vnd.ms-excel',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];

      if (validFileTypes.includes(fileType)) {
        try {
          setCheckingDuplicates(true);
          let parsedData;
          if (fileType === 'text/csv') {
            parsedData = await parseCSV(selectedFile);
          } else {
            parsedData = await parseExcel(selectedFile);
          }
          setImportedData(parsedData);

          const formData = new FormData();
          formData.append('file', selectedFile);

          const response = await APIService.createInstance('metrc-tag-validate/', formData);
          setTagsCount(response?.data?.tags_count);
          setDuplicates(response?.data?.duplicates);
          setPopupOpen(true);
        } catch (error) {
          console.error('Error processing file:', error);
          feedbackCx.setContextValue(true, {
            type: 'error',
            message: 'Error processing file: ' + error.message,
          });
        } finally {
          setCheckingDuplicates(false);
        }
      } else {
        feedbackCx.setContextValue(true, {
          type: 'error',
          message: 'Please select a valid Excel or CSV file!',
        });
      }
    }
  };

  const parseDate = (dateInput) => {
    if (typeof dateInput === 'number') {
      const excelStartDate = new Date(1900, 0, 1);
      const parsedDate = new Date(excelStartDate.getTime() + (dateInput - 1) * 86400000);
      return moment(parsedDate).format('YYYY-MM-DD');
    }
    const formats = ['YYYY-MM-DD', 'MM/DD/YYYY', 'YYYY/MM/DD', 'DD/MM/YYYY', 'DD-MM-YYYY'];
    for (let format of formats) {
      const parsedDate = moment(dateInput, format, true);
      if (parsedDate.isValid()) {
        return parsedDate.format('YYYY-MM-DD');
      }
    }
    return '';
  };

  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const formattedData = results.data.map(item => ({
            farm_id: selectedFarm, 
            tag: item.Tag || '',
            type: item.Type?.toLowerCase()?.replace(/\s+/g, '_') || '',
            status: item.Status?.toLowerCase()?.replace(/\s+/g, '_') || '',
            commissioned: parseDate(item.Commissioned),
            used_on: parseDate(item.Used) || '',
          }));
          resolve(formattedData);
        },
        error: function(error) {
          reject(error);
        }
      });
    });
  };
  
  const parseExcel = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      
          const formattedData = json.slice(1).map(item => ({
            farm_id: selectedFarm, 
            tag: item[0] || '',
            type: item[1]?.toLowerCase()?.replace(/\s+/g, '_') || '',
            status: item[2]?.toLowerCase()?.replace(/\s+/g, '_') || '',
            commissioned: parseDate(item[3]),
            used_on: parseDate(item[4]) || ''
          }));
      
          resolve(formattedData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handlePopupFarmChange = (event) => {
    const selectedFarmId = event.target.value;
    setSelectedFarm(selectedFarmId);
    setIsFarmSelected(!!selectedFarmId);

    if (selectedFarmId) {
      const updatedImportedData = importedData.map(item => ({
        ...item,
        farm_id: selectedFarmId
      }));
      setImportedData(updatedImportedData);
    }
  };

  const handleSave = async () => {
    if (!isFarmSelected) {
      feedbackCx.setContextValue(true, {
        type: 'error',
        message: 'Please select a farm before importing.',
      });
      return;
    }
  
    try {
      setImportLoading(true);
      const dataToSave = importedData.map(item => ({
        ...item,
        farm_id: selectedFarm 
      }));
      
      const response = await APIService.createInstance('metrc-tag/', dataToSave);
  
      if (response.status === 201) {
        feedbackCx.setContextValue(true, {
          type: 'success',
          message: 'Data imported successfully.',
        });
        onImportComplete();
      } else {
        throw new Error('Import failed');
      }
    } catch (error) {
      console.error("Error during save:", error);
      feedbackCx.setContextValue(true, {
        type: 'error',
        message: 'An error occurred during import.',
      });
    } finally {
      setImportLoading(false);
      setPopupOpen(false);
    }
  };

  
  const SelectFarmProps = {
    className: `${popupClasses?.createStrain?.textField} ${currDarkThemeClass}`,
    fullWidth: true,
  }

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <Button onClick={handleImport}>Import</Button>
      <input
        id="file-input"
        accept=".csv, .xlsx, .xls"
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <EntityDetailPopup
        currDarkThemeClass={currDarkThemeClass}
        className={`popupClasses.lightEntry.textField ${currDarkThemeClass}`}
        headContentText={
          <Box>
            <Typography>Total Tags Imported: {tagsCount}</Typography>
            <Box display="flex" alignItems="center">
              {checkingDuplicates ? (
                <>
                  <CircularProgress size={20} />
                  <Typography style={{ marginLeft: 10 }}>Checking for duplicates...</Typography>
                </>
              ) : (
                <Typography>
                  {Array.isArray(duplicates) && duplicates.length > 0 ? (
                    <>
                      {'Duplicate Tags Found: '}
                      {duplicates.slice(0, 8).map((duplicate, index) => (
                        <span key={duplicate?.tag}>
                          {duplicate?.tag}
                          {index < Math.min(8, duplicates.length) - 1 ? ', ' : ''}
                        </span>
                      ))}
                      {duplicates.length > 8 && (
                        <span>{` ... (${duplicates.length - 8} more)`}</span>
                      )}
                    </>
                  ) : (
                    <span>No duplicates found</span>
                  )}
                </Typography>
              )}
            </Box>
          </Box>
        }
        saveText={
          <>
            {importLoading && <CircularProgress size={20} style={{ marginRight: 10 }} />}
            {importLoading ? 'Importing...' : 'Import'}
          </>
        }
        open={popupOpen}
        onClose={handlePopupClose}
        title="Import Metrc Tags"
        onSaveClick={handleSave}
        toDisableSave={!isFarmSelected || duplicates?.length > 0 || importLoading}
      >
        <TextField
          select
          label="Select Farm"
          value={selectedFarm}
          onChange={handlePopupFarmChange}
          fullWidth
          {... SelectFarmProps}
        >
          <MenuItem value="" disabled>
            Select Farm
          </MenuItem>
          {farms.map(farm => (
            <MenuItem key={farm.id} value={farm.id}>
              {farm.name}
            </MenuItem>
          ))}
        </TextField>
      </EntityDetailPopup>
    </>
  );
};

export default MetrcTagImportBtn;