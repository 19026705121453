import Box from "@mui/material/Box";

import { baseClasses } from "../constants";


export default function BaseChart(props) {
  const {
    otherSvgProps = {},
    otherInnerWrapperProps = {},
    otherPlotAreaProps = {},
    otherXAxisProps = {},
    otherYAxisProps = {},
    otherClasses={},
    currDarkThemeClass='',
  } = props;


  const svgProps = { 
    className: `${baseClasses?.chart?.svg} ${currDarkThemeClass} ${otherClasses?.svg}`,
    component: 'svg',
    ...otherSvgProps,
  };

  const innerWrapperProps = {
    className: `${baseClasses?.chart?.innerWrapper} ${currDarkThemeClass} ${otherClasses?.innerWrapper}`,
    component: 'g',
    ...otherInnerWrapperProps,
  };

  const plotAreaProps = {
    className: `${baseClasses?.chart?.plotArea} ${currDarkThemeClass} ${otherClasses?.plotArea}`,
    component: 'g',
    ...otherPlotAreaProps,
  };

  const xAxisProps = {
    className: `${baseClasses?.chart?.xAxis} ${currDarkThemeClass} ${otherClasses?.xAxis}`,
    component: 'g',
    ...otherXAxisProps,
  };

  const yAxisProps   = {
    className: `${baseClasses?.chart?.yAxis} ${currDarkThemeClass} ${otherClasses?.yAxis}`,
    component: 'g',
    ...otherYAxisProps,
  };

  

  return (
    <Box {...svgProps}>
      <Box {...innerWrapperProps}>
        <Box {...plotAreaProps} />
        <Box {...xAxisProps} />
        <Box {...yAxisProps} />
      </Box>
    </Box>
  );
}
