import { logoutUser } from "../components/utility/constants";


export default function getTokenDetails() {
    let token = localStorage.getItem('token');
    let permissions = localStorage.getItem('permissions');
   
    let tokenDetails = {};

    try {
        tokenDetails = JSON.parse(atob(token?.split('.')?.[1]));
        permissions = JSON.parse(permissions);
    } catch {

        logoutUser();

        return ({
            token: undefined,
            tokenDetails: {},
            permissions: []
        });
    }

    return ({
        token,
        tokenDetails,
        permissions
    });
}