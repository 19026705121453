import React, { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { tableClasses } from '..';

export default function DisplayMetrcTagsTable(props) {
  const {
    entity,
    emptyTableMsg = undefined,
    onClick = () => console.log('Create entity'),
    tableEntries = [],
    tableTitles = [],
    toShowAddBtn = false,
    otherEntryCellSxProps,
    animationClass = '',
    currDarkThemeClass = '',
    enablePagination = true,
    rowsPerPageOptions = [10, 15, 30, 100],
    rowsPerPage = 15,
    totalCount = 0,
    onPageChange,
    loading = false,
    page = 0,
    ...otherProps
  } = props;

  const handleChangePage = useCallback((event, newPage) => {
    onPageChange(newPage, rowsPerPage);
  }, [rowsPerPage, onPageChange]);

  const handleChangeRowsPerPage = useCallback(({ target: { value } }) => {
    onPageChange(0, +value);
  }, [onPageChange]);

  const titlesLen = tableTitles.length;

  const displayTableBaseProps = {
    ...otherProps.baseProps,
  };

  const displayTableContainerProps = {
    className: `${tableClasses?.displayTable?.tableContainer} ${currDarkThemeClass} ${animationClass}`,
    component: Paper,
    variant: 'none',
    ...otherProps?.tableContainerProps,
  };

  const displayTableProps = {
    className: `${tableClasses?.displayTable?.table} ${currDarkThemeClass}`,
    stickyHeader: true,
  };

  const displayTableRowProps = {
    className: `${tableClasses?.displayTable?.tableRow} ${currDarkThemeClass}`,
    ...otherProps?.tableRowProps,
  };

  const displayTableTitleCellProps = i => ({
    className: `${tableClasses?.displayTable?.titleCell} ${currDarkThemeClass}`,
    sx: {
      pl: i === 0 || i === titlesLen - 1 ? '39px' : '20px',
      pr: i === titlesLen - 1 ? '39px' : 'auto',
    },
    variant: 'head',
    ...otherProps?.tableTitleProps,
  });

  const displayTableEntryCellProps = idx => ({
    className: `${tableClasses?.displayTable?.entryCell} ${currDarkThemeClass}`,
    ...(idx === 0 && {
      component: 'th',
      scope: 'row',
    }),
    sx: {
      pl: idx === 0 || idx === titlesLen - 1 ? '39px' : '20px',
      pr: idx === titlesLen - 1 ? '39px' : 'auto',
      ...otherEntryCellSxProps?.(idx),
    },
    variant: 'body',
    ...otherProps?.tableEntryCellProps,
  });

  const tableTitlesList = tableTitles.map((e, i) => (
    <TableCell key={i} {...displayTableTitleCellProps(i)}>
      {e.title}
    </TableCell>
  ));

  const tableEntriesList = tableEntries.map((e, i) => (
    <TableRow key={`row-${i}`} {...displayTableRowProps}>
      {e.map((el, idx) => (
        <TableCell key={`cell-${idx}`} {...displayTableEntryCellProps(idx)}>
          {el ?? ''}
        </TableCell>
      ))}
    </TableRow>
  ));

  const noEntityProps = {
    className: `${tableClasses?.displayTable?.noEntityTableCell} ${currDarkThemeClass}`,
    colSpan: '100%',
  };

  const displayTableFooterPaperProps = {
    className: `${tableClasses?.displayTable?.tableFooterPaper} ${currDarkThemeClass} ${animationClass}`,
    square: true,
    variant: 'none',
  };

  const displayTableAddBtnProps = {
    className: `${tableClasses?.displayTable?.addBtn} ${currDarkThemeClass}`,
    onClick,
  };

  const displayTablePaginationProps = {
    className: `${tableClasses?.displayTable?.pagination} ${currDarkThemeClass}`,
    component: 'div',
    count: totalCount,
    page: page,
    onPageChange: handleChangePage,
    rowsPerPage: rowsPerPage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
  };

  return (
    
    <Box {...displayTableBaseProps}>
      <TableContainer {...displayTableContainerProps}>
        <Table {...displayTableProps}>
          <TableHead>
            <TableRow>{tableTitlesList}</TableRow>
          </TableHead>

      {/* <Fade in={true} timeout={2000}> */}
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={titlesLen} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : tableEntriesList.length === 0 ? (
              <TableRow>
                <TableCell {...noEntityProps}>{emptyTableMsg ?? `No ${entity} Found`}</TableCell>
              </TableRow>
            ) : (
              tableEntriesList
            )}
          </TableBody>
          {/* </Fade> */}
        </Table>
      </TableContainer>
      {enablePagination && <TablePagination {...displayTablePaginationProps} />}
      {toShowAddBtn && (
        <Paper {...displayTableFooterPaperProps}>
          <Button {...displayTableAddBtnProps}>Add {entity}</Button>
        </Paper>
      )}
    </Box>

  );
}