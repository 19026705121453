import { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MetrcKeyContext } from "../../App";

import Button from "@mui/material/Button";
// import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import ConfirmationPopupWithLoading from "../utility/ConfirmationPopupWithLoading";
import CreateStrainPopup from "../utility/CreateStrainPopup";
// import DashDrawer from "../utility/DashDrawer";
import DownloadingMetrcDataMsg from "../utility/DownloadingMetrcDataMsg";
import DetailAndDelBtn from "../utility/DetailAndDelBtn";
import DisplayTable from "../utility/DisplayTable";
import FarmCard from "./FarmCard";
import RoomCard from "./RoomCard";
// import SensorEntryPopup from "../utility/SensorEntryPopup";
import StrainEntryPopup from "../utility/StrainEntryPopup";

// import { dashNavCollapsedWd, dashNavMr, dashNavTotalWd, fadeTimingMs, farmsTableMaxWd } from "../utility/constants";
import { 
    fadeTimingMs,
    farmAppLabel, crudActions, noPermTablePrefixMsg,
    sensorModelName, strainModelName, farmModelName, roomModelName, metrcRoomModelName,
    getDarkThemeClass, farmsClasses, miscClasses, farmsWizardRoute, errFeedbackFn,
} from "../utility/constants";

import { AddFarmIcon, RefreshIcon, SelDropdownIcon } from "../utility/CustomSvgIcons";

import APIService from '../../api/apiService';
// import getTokenDetails from "../../auth/getTokenDetails";
import { failsTokenAndSetupCheck } from "../../checks/setup";
// import TaskStatusSnackbar from "../utility/feedback/TaskStatusSnackbar";
import { FeedbackContext } from "../../App";
import useToken from "../../utility/useToken";
import { hasPermissions } from "../../utility";

// const defaultTab = 'farm-name';
const defaultTab = 'farms';

// const validTabs = ['farm-name', 'rooms', 'strains', 'sensors'];
// const validTabs = ['farms', 'rooms', 'strains', 'sensors'];
const validTabs = ['farms', 'rooms', 'strains']; // Temporary
const validTabNames = [
    // {key: 'Farm Name', value: 'farm-name'},
    {key: 'Farms', value: 'farms'},
    {key: 'Rooms', value: 'rooms'},
    {key: 'Strains', value: 'strains'},
    // {key: 'Sensors', value: 'sensors'}, // Temporary
];

// const dashNavWd = '236px'; // 206 + 30

const title = 'Farms';


export default function Farms(props) {
    // const theme = useTheme();
    const [detailChildren, setDetailChildren] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [savedRes, setSavedRes] = useState({});
    const [values, setValues] = useState({});
    
    // const location = useLocation();
    const {metrcKey, setMetrcKey} = useContext(MetrcKeyContext);
    const feedbackCX = useContext(FeedbackContext);

    // const {state} = location;
    const navigate = useNavigate();
    
    const tab = searchParams.get('tab');

    const [tabValue, setTabValue] = useState(defaultTab);
    
    // let token = localStorage.getItem('token');

    // let tokenDetails = {};

    // try {
    //     tokenDetails = JSON.parse(atob(token?.split('.')?.[1]));
    // } catch {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     token = undefined;
    // }
    const theme = useTheme();

    const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    // const {token, tokenDetails} = getTokenDetails();
    const {token, tokenDetails,permissions} = useToken();

    
    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    const {is_staff=false} = tokenDetails || '';

    const farmPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${farmModelName}`)) ?? [], [token]);
    // const metrcRoomPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${metrcRoomModelName}`)) ?? [], [token]);
    const farmPerms= useMemo(() => hasPermissions([farmModelName], { ...tokenDetails, permissions })[farmModelName] ?? {}, [token]);
    const roomPerms = useMemo(() => hasPermissions([roomModelName], { ...tokenDetails, permissions })[roomModelName] ?? {}, [token]);
    const sensorPerms= useMemo(() => hasPermissions([sensorModelName], { ...tokenDetails, permissions })[sensorModelName] ?? {}, [token]);
    const strainPerms = useMemo(() => hasPermissions([strainModelName], { ...tokenDetails, permissions })[strainModelName] ?? {}, [token]);

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));
    const handleSetSavedRes = (prop, value) => setSavedRes(state => ({...state, [prop]: value}));

    const handleSetLoading = prop => value => handleSetValues('loading', {...values?.loading, [prop]: value});

    // const handleError = err => {
    //     handleSetValues('loading', {all: false});
        
    //     console.log('An error occured');console.log(err);
        
    //     // set error state and display error message
    // }

    const handleErrorWithFeedback = errPropsFn => err => {
        const errProps = errPropsFn?.(err) ?? { severity: 'error', message: 'Changes were not saved.' };

        handleSetValues('loading', {all: false});

        // handleSetValues('feedback', true);
        // handleSetValues('feedbackProps', errProps);
        feedbackCX.setContextValue(true, errProps);
    }

    const handleFinally = () => {
        handleSetValues('loading', {all: false});
    }

    const handleEntityEntryCreateRes = prop => res => {
        handleSetLoading(prop)(false);

        const {data} = res;

        // handleSetValues(prop, [...values?.[prop], data]);
        // handleSetSavedRes(prop, [...savedRes?.[prop], data]);
        const newState = data?.length > 0 ? [...values?.[prop], ...data] : [...values?.[prop], data];
        const newSavedRes = data?.length > 0 ? [...savedRes?.[prop], ...data] : [...savedRes?.[prop], data];

        handleSetValues(prop, newState);
        handleSetSavedRes(prop, newSavedRes);

        return Promise.resolve({success: true});
    }

    const handleEntityEntryModifyRes = prop => res => {
        const {data} = res;

        handleSetValues(prop, values?.[prop]?.map(e => e.id === data.id ? data : e))
        handleSetSavedRes(prop, savedRes?.[prop]?.map(e => e.id === data.id ? data : e));

        return Promise.resolve({success: true});
    };

    const handleEntityDelRes = (prop, id) => res => {
        handleSetValues(prop, values?.[prop]?.filter(e => e.id !== +id));
        handleSetSavedRes(prop, savedRes?.[prop]?.filter(e => e.id !== +id));

        return Promise.resolve({success: true});
    }

    // 17-04-2023: Previously
    // const handleEntityEntryModify = (entity, prop) => value => {
    //     APIService.modifyInstance(entity, value)
    //     .then(handleEntityEntryModifyRes(prop))
    //     .catch(handleError);
    // };

    const handleEntityEntryModify = (entity, prop) => async (value) => {
        
        const errConfig = {};

        let res = {success: false};

        try {
            res = await APIService.modifyInstance(entity, value)
                                  .then(handleEntityEntryModifyRes(prop))
        } catch (err) {
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }

        return res;

    };
    
    // const handleEntityEntryDel = (entityWithID, prop,  id) => {
    //     APIService.deleteInstance(entityWithID)
    //     .then(handleEntityDelRes(prop, id))
    //     .catch(handleError);
    // }

    const handleEntityEntryDel = (entityWithID, prop, id) => setLoading => async () => {
        setLoading(true);

        let res = {success: false};

        try {
            res = await APIService.deleteInstance(entityWithID)
                                  .then(handleEntityDelRes(prop, id));
        } catch (err) {
            // handleError(err);
            const errConfig = {};

            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);

        } 
        // finally {
        //     handleFinally();
        // }

        return res;
    }

    const handleEntityEntryDelConf = (entityWithID, prop,  id) => {
        const entity = values?.[prop]?.find(e => +e?.id === +id);


        setDetailChildren({
            title: `Deleting ${entity?.strain_name ?? entity?.mac_address ?? 'instance'}`,

            onProceed: handleEntityEntryDel(entityWithID, prop, id),
        });

        setOpen(true);
        handleSetValues('confirmation', true);
        handleSetValues('add', false);
    }

    const handleEntitySave = (entity, prop) => async (value) => {
        
        const errConfig = {
            create: true,
            resource: prop === 'sensors' ? 'Sensor' : 'Strain',
        };

        let res = {success: false};

        try {
            res = await APIService.createInstance(entity, value)
                                  .then(handleEntityEntryCreateRes(prop))
        } catch (err) {
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }

        return res;
    };

    const handleProcessingFarmsRes = timerId => res => {
        if (res.status === 202) return;

        clearInterval(timerId);

        // console.log('Fetched farms in processing');console.log(res);
        const {data} = res;
        const {token} = data;

        const stateValueMap = Object.keys(data ?? {}).map( key => [key, (data?.[key]?.results ?? data?.[key] ?? [])] )
        const stateValues = Object.fromEntries(stateValueMap);

        setValues(state => ({
            ...state,
            ...stateValues,
            loading: {
                all: false,
            },
        }));
        setSavedRes(state => ({
            ...state,
            ...stateValues,
        }));

        if (token) {
            localStorage.setItem('token', token);
        }

    }

    const handleFarmsRes = res => {
        
        if (res.status === 202) {
            handleSetValues('loading', {all: true});
            
            const timerId = setInterval(() => {
                
                const errConfig = {};

                // const entity = 'farm/';
                // APIService.fetchAllInstances(entity, metrcKey)
                const entity = 'collection/farm/';

                APIService.fetchInstance(entity)
                .then(handleProcessingFarmsRes(timerId))
                // .catch(handleError)
                .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
                
            }, 30000);

            return ;
        }
        
        handleSetValues('loading', {all: false});
        // console.log('Fetched farms');console.log(res);

        const {data} = res;
        const {token} = data;

        // Will only update the keys in data; So only send values from backend that need to be updated
        const stateValueMap = Object.keys(data ?? {}).map( key => [key, (data?.[key]?.results ?? data?.[key] ?? [])] )
        const stateValues = Object.fromEntries(stateValueMap);

        setValues(state => ({
            ...state,
            ...stateValues,
            loading: {
                all: false,
            },
            
            farmsWizard: stateValues?.rooms?.length === 0,
        }));
        setSavedRes(state => ({
            ...state,
            ...stateValues,
        }));

        if (token) {
            localStorage.setItem('token', token);
        }
    }

    const handleRefreshData = () => {
        // if (!farmPerms?.create || !metrcRoomPerms?.create) return;
        if (!farmPerms?.create) return;

        handleSetLoading('all')(true);

        const errConfig = {};

        // Will need to allow data_fetch=true func in collection/farm/ ?
        // Or, just update farm and metrcrooms when data_fetch = true and save their response
        // const entity = `farm/?metrc_id=${metrcKey}&data_fetch=true`;
        const entity = `farm/?data_fetch=true`;

        APIService.fetchInstance(entity)
        .then(handleFarmsRes)
        // .catch(handleError);
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
    };

    // 22-06-2023: Approach #1 for maintaining consistent popup state; This was too tightly coupled; Hence, Approach #2
    // useEffect(() => {
    //     if ( open ) {

    //         // 22-06-2023: Currently only 'strains' tab has popup
    //         if (tabValue !== 'strains') {
    //             handlePopupClose();
    //         }

    //     }
    // });

    useEffect(() => {
        let tabToSet = '';

        if (!tab || !validTabs.includes(tab)) {
            tabToSet = defaultTab;
        } else if (validTabs.includes(tab)) {
            tabToSet = tab;
        }

        setTabValue(tabToSet);
        setSearchParams({tab: tabToSet});

        // 22-06-2023: Approach #2:  This approach for maintaining consistent popup state requires any open popup to be closed whenever a tab changes since popups are always tab specific
        if ( open ) handlePopupClose();

    }, [tab]);

    useEffect(() => {
        // if (failsTokenAndSetupCheck(token, tokenDetails)) return;
        if (invalidTokenState) return;
        // if (hasNotCompletedSetup(tokenDetails) || values?.rooms?.length === 0) return;

        const {metrc_id} = tokenDetails;

        if (!metrcKey) {
            setMetrcKey(metrc_id);
        }

        handleSetValues('metrc_id', metrc_id);
        handleSetSavedRes('metrc_id', metrc_id);

        handleSetValues('loading', {all: true});

        const errConfig = {};

        const entity = `collection/farm/`;

        APIService.fetchInstance(entity)
        .then(handleFarmsRes)
        // .catch(handleError)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        // .finally(handleFinally); // Not ideal to call handleFinally here since it will be reset in handleFarmsRes if status === 202
        
    }, [metrcKey]);

    // useEffect(() => {
    //     if (!token) {
    //         navigate(defaultNoAuthRoute);
    //     } else if (hasNotCompletedSetup(tokenDetails)){
    //         navigate(defaultNoMetrcRoute);
    //     } 
        
    //     // 14-03-2023: Temporarily removed auto redirect to wizard based on discussion
    //     // else if (values?.rooms?.length === 0 && roomPerms?.create) {
    //     //     navigate(farmsWizardRoute);
    //     // }

    // // }, [token, values?.rooms?.length]);
    // }, [token]);

    useEffect(() => {
        if (values?.farmsWizard) {
            navigate(farmsWizardRoute);
        }
    }, [values?.farmsWizard])

    // if (failsTokenAndSetupCheck(token, tokenDetails)) return <></>;
    if (invalidTokenState) return <></>;

    const handleSetSelSensor = (prop, value) => {
        setDetailChildren(state => ({
            ...state,
            selSensor: {
                ...state.selSensor,
                [prop]: value,
            }
        }));
    }
    
    const handleSetSelStrain = (prop, value) => {
        setDetailChildren(state => ({
            ...state,
            selStrain: {
                ...state.selStrain,
                [prop]: value,
            }
        }));
    }

    // ^
    // const farmsIconProps = ({fill, height='20px', width='20px'}='') => ({
    //     className: `${farmsClasses?.icon} ${currDarkThemeClass}`,
    //     sx: {
    //         height,
    //         width,
    //         '& path': {
    //             fill,
    //         },
    //     },
    // });
    const farmsIconProps = {
        className: `${farmsClasses?.icon} ${currDarkThemeClass}`,
        // sx: {
        //     height,
        //     width,
        //     '& path': {
        //         fill,
        //     },
        // },
    };

    const farmsPaperProps = {
        // className: 'main-content-wrapper customScroll',
        className: `${farmsClasses?.paper} ${currDarkThemeClass} customScroll`,
        square: true,
        variant: 'none',
    };

    const farmsOrgPaperProps = {
        className: `${farmsClasses?.orgPaper} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     backgroundColor: 'transparent',
        //     color: 'grey.700',
        //     display: 'flex',
        //     fontWeight: 500,
        // },
        variant: 'none',
    };

    const farmsOrgTitleTypoProps = {
        className: `${farmsClasses?.orgTitleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'grey.200',
        //     color: 'grey.600',
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     px: '12px',
        // },
    };

    const farmOrgNameTypoProps = {
        className: `${farmsClasses?.orgNameTypo} ${currDarkThemeClass}`,
        // sx: {
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     ml: '12px',
        // },
    };

    const farmsTitleTypoProps = {
        className: `${farmsClasses?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '36px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.08,
        //     mt: '12px',
        // },
    };

    const farmsKeyTypoProps = {
        className: `${farmsClasses?.keyTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '16px',
        //     lineHeight: 1.56,
        //     mt: '8px',
        // },
    };

    const farmsKeyRefreshAddPaperProps = {
        className: `${farmsClasses?.keyRefreshAddPaper} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'transparent',
        //     display: 'flex',
        //     mt: '8px',
        // },
        variant: 'none',
    };

    const handleMetrcChange = ({target: {value}}) => {
        handleSetValues('metrc_id', value);
        handleSetSavedRes('metrc_id', value);
        setMetrcKey(value);
    };

    const farmsKeySelProps = {
        className: `${farmsClasses?.keySelect} ${currDarkThemeClass}`,
        IconComponent: SelDropdownIcon,
        // inputProps: {
        //     sx: {
        //         px: '12px',
        //         py: 0,
        //     },
        // },
        label: ' ',
        onChange: handleMetrcChange,
        // sx: {
        //     borderRadius: 0,
        //     color: 'grey.700',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     py: '12px',
        //     width: '603px',
        // },
        value: values?.metrc_id ?? '',
    };

    const farmsRefreshBtnProps = {
        className: `${farmsClasses?.refreshBtn} ${currDarkThemeClass}`,
        disabled: values?.loading?.all || !farmPerms?.create,
        onClick: handleRefreshData,
        // startIcon: <RefreshIcon {...farmsIconProps({fill: 'primary.100'})}/>,
        startIcon: <RefreshIcon {...farmsIconProps}/>,
        // sx: {
        //     backgroundColor: 'primary.200',
        //     borderRadius: 0,
        //     color: 'primary.800',
        //     flexShrink: 0,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     ml: '16px',
        //     py: '12px',
        //     textTransform: 'none',
        //     width: '190px',
        //     '&:hover': {
        //         backgroundColor: 'primary.200',
        //     },
        // },
    };
    
    const farmsAddFarmBtnProps = {
        className: `${farmsClasses?.addFarmBtn} ${currDarkThemeClass}`,
        // startIcon: <AddFarmIcon {...farmsIconProps({fill: 'primary.100'})}/>,
        startIcon: <AddFarmIcon {...farmsIconProps}/>,
        // sx: {
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     color: 'primary.100',
        //     flexShrink: 0,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     ml: '8px',
        //     p: '12px',
        //     textTransform: 'none',
        //     width: '190px', // 190/1.1
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
    };

    const farmsKeyMenuItemProps = {
        className: `${farmsClasses?.keyMenuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: `${miscClasses?.menuItemFocusDark}`,
    };

    const orgKeyMenuItems = values?.metrc?.map((e, i) => 
        <MenuItem key={i} value={e.metrc_id} {...farmsKeyMenuItemProps}>{e.metrc_id}</MenuItem>
    ) || (
        metrcKey ? <MenuItem key={`metrc-0`} value={metrcKey} {...farmsKeyMenuItemProps} >{metrcKey}</MenuItem>
        : []
    );

    if (orgKeyMenuItems.length === 0) {
        <MenuItem key={-1} disabled value={-1} {...farmsKeyMenuItemProps}>No Org Keys Available</MenuItem>
    }

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams({tab: newValue});
    }

    const farmsTabsProps = {
        className: `${farmsClasses?.tabs} ${currDarkThemeClass}`,
        onChange: handleSetTabValue,
        // sx: {
        //     mt: '24px',
        // },
        // TabIndicatorProps: {
        //     sx: {
        //         backgroundColor: 'grey.700',
        //     },
        // },
        value: tabValue ?? defaultTab,
    };

    const farmsTabProps = {
        className: `${farmsClasses?.tab} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '16px',
        //     lineHeight: 1.56,
        //     px: '10px',
        //     py: '12px',
        //     textTransform: 'none', 
        //     '&.Mui-selected': {
        //         color: 'grey.700',
        //         fontWeight: 500,
        //     },
        // }, 
    }

    const tabList = validTabNames.map((e, i) => 
        <Tab value={e.value} label={e.key} key={i} {...farmsTabProps}/>
    );

    const farmsDividerProps = {
        className: `${farmsClasses?.divider} ${currDarkThemeClass}`,
    };

    // const farmsTabContentFadeProps = (tabName) => ({
    //     in: tabValue === tabName,
    //     easing: 'cubic-bezier(0.42, 0, 0.58, 1.0)', // Not sire if this has any effect
    //     timeout: 800,
    //     unmountOnExit: true,
    // });

    const noEntityProps = {
        className: `${farmsClasses?.noEntityTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '1.5rem',
        //     letterSpacing: '-0.05em',
        //     mx: 'auto',
        // },
    };

    const farmsListPaperProps = {
        // className: 'farmsTabContent',
        className: `${farmsClasses?.listPaper} ${currDarkThemeClass} farmsTabContent`,
        // sx: {
        //     backgroundColor: 'transparent',
        //     display: 'grid',
        //     gridTemplateColumns: 'repeat(3, 1fr)',
        //     gap: '16px',
        //     // display: 'flex',
        //     // flexWrap: 'wrap',
        //     mt: '40px', // 40/1.1
        // },
        variant: 'none',
    };

    const farmsTableListPaperProps = {
        // className: 'farmsTabContent',
        className: `${farmsClasses?.tableListPaper} ${currDarkThemeClass} farmsTabContent`,
        square: true,
        // sx: {
        //     backgroundColor: 'transparent',
        //     display: 'flex',
        //     flexDirection: 'column',
        //     flexWrap: 'wrap',
        //     maxWidth: `${farmsTableMaxWd}px`,
        //     mt: '40px',
        //     pb: '32px',
        // },
        variant: 'none',
    };
    
    const otherFarmCardProps = {
        org_name: values?.organization?.org_name,
        currDarkThemeClass,
    };

    const farmsList = (
        // <Fade {...farmsTabContentFadeProps('farm-name')}>
                // {
        !farmPerms?.read ? <Typography {...noEntityProps}>{noPermTablePrefixMsg} farms</Typography>
            : values?.farms?.length > 0 ?
                <Paper {...farmsListPaperProps}>
                    { values.farms.map((e, i) => <FarmCard key={i} {...e} {...otherFarmCardProps} />) }
                </Paper>
                : <Typography {...noEntityProps}>No Farms Available</Typography>
                // }
        // </Fade>
    );

    const otherRoomCardProps = {
        currDarkThemeClass,
    };

    const roomsList = (
        // <Fade {...farmsTabContentFadeProps('rooms')}>
                // {
        !roomPerms?.read ? <Typography {...noEntityProps}>{noPermTablePrefixMsg} rooms</Typography>
            : values?.rooms?.length > 0 ?
                <Paper {...farmsListPaperProps}>
                    { 
                        values.rooms.map((e, i) => {
                            const attached_room_id = values.metrcrooms.filter(el => el.room === e.id);
                            return <RoomCard key={i} {...e} attached_room_id={attached_room_id} {...otherRoomCardProps} />
                        })
                    }
                </Paper>
                : <Typography {...noEntityProps}>No Alias Rooms Available</Typography>
                // }
        // </Fade>
    );

    const handleAddNewSensor = () => {
        handleSetValues('add', true);

        setOpen(true);

        setDetailChildren({
            handleAddSensor: handleEntitySave('sensors/', 'sensors'),
            rooms: values?.rooms ?? [],

            // 17-04-2023: Handle state for loading in child popup
            // handleLoading: handleSetLoading('sensors'),
            // loading: values?.loading?.sensors ?? false,
            title: 'Sensor',
        });
    };

    const handleSensorEntryView = (id) => {        
        const sensor = Object.assign({}, values?.sensors?.find(e => e.id === id));
        
        setDetailChildren({
            handleSensorSave: handleEntityEntryModify(`sensors/${id}/`, 'sensors'), // 17-04-2023: TODO: Sensor related popup will need to be modified to match with the new async nature of handleEntityEntryModify
            handleSetSelSensor,
            rooms: values?.rooms,
            selSensor: sensor,
            title: sensor.mac_address,

            toShowSave: sensorPerms?.update ?? false,
        });

        handleSetValues('add', false);
        setOpen(true);
    }

    const farmsSensorEntryActionBtnProps = (id) => ({
        onDelClick: () => sensorPerms?.delete ? handleEntityEntryDelConf(`sensors/${id}/`, 'sensors', id) : undefined,
        onDetailClick: () => handleSensorEntryView(id),
        toShowDelBtn: sensorPerms?.delete ?? false,
    });

    const sensorKeyTitleMap = [
        {key: 'company_name', title: 'Company'},
        {key: 'sensor_type', title: 'Sensor Type'},
        {key: 'mac_address', title: 'MAC Address'},
        {key: 'room_name', title: 'Alias Room'},
    ];

    const sensorTableEntries = [];
    const sensorTableTitles = sensorKeyTitleMap.map(e => ({title: e.title}));

    if (sensorPerms?.read) {
        values?.sensors?.forEach((val, idx) => {
            let alias_room = values.rooms.find(e => e.id === (val?.room_id ?? val?.room))?.room_name;

            if (!alias_room) {
                
                const farmsNoSensorRoomTypoProps = {
                    component: 'span',
                    sx: {
                        color: 'grey.400',
                        // fontStyle: 'italic',
                    },
                };

                alias_room = <Typography {...farmsNoSensorRoomTypoProps}>None</Typography>
            }
    
            sensorTableEntries.push([
                `${idx+1}`.padStart(2, 0),
                ...sensorKeyTitleMap.map(e => e.key === 'room_name' ? alias_room : val[e.key]),
                <DetailAndDelBtn {...farmsSensorEntryActionBtnProps(val.id)} />
            ]);
        });
    }

    const sensorTableValues = {
        entity: 'Sensors',
        emptyTableMsg: !sensorPerms?.read ? `${noPermTablePrefixMsg} sensors`: undefined,
        onClick: handleAddNewSensor,
        tableEntries: sensorTableEntries,
        tableTitles: [
            {title: 'Sl. No'},
            ...sensorTableTitles,
            {title: ''},
        ],
        toShowAddBtn: sensorPerms?.create,
        animationClass: 'farmsTabContent',
    };


    const sensorsList = (
        // <Fade {...farmsTabContentFadeProps('sensors')}>
            <Paper {...farmsTableListPaperProps}>
                <DisplayTable {...sensorTableValues} />
            </Paper>
        // </Fade>
    );


    const handleAddNewStrain = () => {
        handleSetValues('add', true);

        setOpen(true);

        setDetailChildren({
            title: 'Strain',
            handleAddStrain: handleEntitySave('strain/', 'strains'),
        });
    };
    
    const handleStrainEntryView = (id) => {
        handleSetValues('add', false);

        setOpen(true);

        const strain = Object.assign({}, values?.strains?.find(e => e.id === id));
        
        setDetailChildren({
            selStrain: strain,
            title: strain.strain_name,
            toShowSave: strainPerms?.update ?? false,

            // fixedEntries: [
            //     {key: 'Name', value: strain.strain_name},
            //     {key: 'Yield', value: strain.strain_yield},
            //     {key: 'Vegetative Days', value: strain.days_required_in_veg},
            //     {key: 'Price ($ per lb)', value: strain.flower_price},
            // ],
            // // handleSaveDetails: handleSaveSelStrainDetails(id),
            handleStrainSave: handleEntityEntryModify(`strain/${id}/`, 'strains'),
            handleSetSelStrain,
        });
    }

    const gDStrainEntryActionBtnProps = (id) => ({
        currDarkThemeClass,
        toShowDelBtn: false,

        // onDelClick: () => strainPerms?.delete ? handleEntityEntryDelConf(`strain/${id}/`, 'strains', id) : undefined,
        onDetailClick: () => handleStrainEntryView(id),

    });

    const strainKeyTitleMap = [
        {key: 'strain_name', title: 'Name'}, // can do {strain_name: 'Name'}
        {key: 'strain_yield', title: 'Yield'},
        // {key: 'days_required_in_veg', title: 'Vegetative Days'},
        {key: 'flower_price', title: 'Price ($ per lb)'},
        // {key: 'qualityplan_name', title: 'Quality Plan'}, // Will be available when crops are fetched but won't be present in strains
        // {key: 'medium_profile', title: 'Medium Profile'},
        // {key: 'medprofile_name', title: 'Medium Profile'},
        // {key: 'planter_size', title: 'Planter Size'},
        // {key: 'medium_type', title: 'Medium Type'},
    ];

    const strainTableEntries = [];
    const strainTableTitles = strainKeyTitleMap.map(e => ({title: e.title}));

    if (strainPerms?.read) {
        values?.strains?.forEach((val, idx) => {
            strainTableEntries.push([
                `${idx+1}`.padStart(2, 0),
                ...strainKeyTitleMap.map(e => e.key !== 'strain_name' ? +val[e.key] : val[e.key]),
                <DetailAndDelBtn {...gDStrainEntryActionBtnProps(val.id)} />
            ]);
        });
    }

    // console.log(strainTableEntries)
    // console.log(strainKeyTitleMap.map( e => ({title: e.title}) ));

    const strainTableValues = {
        animationClass: 'farmsTabContent',
        currDarkThemeClass,
        emptyTableMsg: !strainPerms?.read ? `${noPermTablePrefixMsg} strains` : undefined,
        entity: 'Strains',
        tableEntries: strainTableEntries,
        tableTitles: [
            {title: 'Sl. No'},
            ...strainTableTitles,
            {title: ''},
        ],
        toShowAddBtn: strainPerms?.create,

        onClick: handleAddNewStrain,
    };

    const strainsList = (
        // <Fade {...farmsTabContentFadeProps('strains')}>
            <Paper {...farmsTableListPaperProps}>
                <DisplayTable {...strainTableValues} />
            </Paper>
        // </Fade>
    );

    const handlePopupClose = () => {
        handleSetValues('confirmation', false);
        setDetailChildren({});
        setOpen(false);
    }

    const farmsPopupProps = {
        currDarkThemeClass,
        open,
        
        onClose: handlePopupClose,

        ...detailChildren,
    };

    // const handleSnackbarClose = () => {
    //     handleSetValues('feedback', false);
    //     handleSetValues('feedbackProps', {});
    // }

    // const taskStatusSnackbarProps = {
    //     open: values?.feedback,
    //     handleClose: handleSnackbarClose,
    //     currDarkThemeClass,
    //     ...values?.feedbackProps,
    // };

    const orgDownloadMsgProps = {
        currDarkThemeClass,
    };

    return (
        <>
        {/* <Box sx={{display: 'flex'}}> */}
            {/* <ThemeProvider theme={dashTheme}> */}
            {/* <DashDrawer toOpen={navOpen} setToOpen={setNavOpen} /> */}
            <Fade in={true} timeout={fadeTimingMs}>
                <Paper {...farmsPaperProps}>
                    <Paper {...farmsOrgPaperProps}>
                        <Typography {...farmsOrgTitleTypoProps}>ORGANIZATION</Typography>
                        {/* <Typography {...farmOrgNameTypoProps}>{orgName}</Typography> */}
                        <Typography {...farmOrgNameTypoProps}>{values?.organization?.org_name ?? 'Unknown'}</Typography>
                    </Paper>
                    <Typography {...farmsTitleTypoProps}>{title}</Typography>
                    <Typography {...farmsKeyTypoProps}>Compliance Org Key</Typography>
                    <Paper {...farmsKeyRefreshAddPaperProps}>
                        <Select {...farmsKeySelProps}>{orgKeyMenuItems}</Select>            
                        <Button {...farmsRefreshBtnProps}>Refresh Data</Button>
                        {/* <Button {...farmsAddFarmBtnProps}>Add a new farm</Button> */}
                    </Paper>
                    <Tabs {...farmsTabsProps}>
                        {tabList}
                    </Tabs>
                    <Divider {...farmsDividerProps}/>
                        {
                            values?.loading?.all ? <DownloadingMetrcDataMsg {...orgDownloadMsgProps} />
                            // : tabValue === 'farm-name' ? farmsList
                            : tabValue === 'farms' ? farmsList
                            : tabValue === 'rooms' ? roomsList
                            : tabValue === 'strains' ? strainsList
                            // : tabValue === 'sensors' ? sensorsList
                            : <></>
                        }
                </Paper>
            </Fade>

            {   
                values?.add ? (
                    tabValue === 'strains' ? <CreateStrainPopup {...farmsPopupProps} />
                    : null
                ) : (
                    // tabValue === 'sensors' ? <SensorEntryPopup {...farmsPopupProps} />
                    !values?.confirmation && (
                        tabValue === 'strains' ? <StrainEntryPopup {...farmsPopupProps} />
                        : null
                    )
                )
            }

            {/* Disabled confirmation since strain can no longer be deleted */}
            {/* {
                values?.confirmation ? (
                    tabValue === 'strains' ? <ConfirmationPopupWithLoading {...farmsPopupProps} />
                    : null
                ) : null

            } */}

            {/* { values?.feedback && <TaskStatusSnackbar {...taskStatusSnackbarProps} /> } */}

            {/* </ThemeProvider> */}
        {/* </Box> */}
        </>
    )
}