// import CommandCenterChart from '../CommandCenterChart';
// import useThemeVal from '../../../utility/useThemeVal';
// import { Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';

// export default function CropRunTab({ data }) {
//   const currDarkThemeClass = useThemeVal();

//   const plantKeyTitleMap = [
//     { key: 'phase', title: 'Phase' },
//     { key: 'harvest_count', title: 'Harvest Cnt' },
//     { key: 'strain_count', title: 'Strain Cnt' },
//     { key: 'plant_count', title: 'Plant Cnt' },
//     { key: 'wet_weight', title: 'Wet Weight (lbs)' },
//     { key: 'dry_weight_coef', title: 'Dry Weight Coef' },
//     { key: 'price', title: 'Price ($)' },
//     { key: 'rts_profit', title: 'RTS Profit' },
//     { key: 'destroyed_plant_count', title: 'Destroyed Cnt' },
//   ];

//   const calculatePhaseMetrics = (data, phaseName, phaseType = null) => {
//     const phaseMetrics = {
//       harvestCount: 0,
//       strainCount: 0,
//       plantCount: 0,
//       wetWeight: 0,
//       totalYield: 0,
//       yieldCount: 0,
//       totalPrice: 0,
//       priceCount: 0,
//       inPreHarvestCount: 0,
//       inPreTestCount: 0,
//     };

//     const uniqueStrains = new Set();

//     data.forEach(run => {
//       console.log(`Processing Run for Phase: ${phaseName}`, run);

//       if (phaseType && !run.subphases.some(subphase => subphase.name.toLowerCase() === phaseType.toLowerCase())) {
//         return;
//       }

//       // Use a unique set for phase plans in the current run
//       const runUniquePhasePlans = new Set();

//       if (phaseName === 'In Pre Harvest') {
//         const preHarvestPlants = run.plant_details.plants.filter(plant => plant.plant_phase === 'Vegetative' || plant.plant_phase === 'Flowering');

//         preHarvestPlants.forEach(plant => {
//           if (plant.strain_name) uniqueStrains.add(plant.strain_name);
//           if (plant.phaseplan_id) runUniquePhasePlans.add(plant.phaseplan_id);

//           const strainDetails = run.strains_details.find(detail => detail.strain_name === plant.strain_name);

//           if (strainDetails) {
//             const strainWetWeight = strainDetails.wet_weight || 0;
//             phaseMetrics.wetWeight += strainWetWeight;

//             const plantCount = 1;
//             const dryWeightCoef = strainDetails.strain_yield || 0;
//             const flowerPrice = strainDetails.flower_price || 0;

//             const plantPrice = plantCount * strainWetWeight * dryWeightCoef * flowerPrice;
//             phaseMetrics.totalPrice += plantPrice;

//             phaseMetrics.totalYield += dryWeightCoef;
//             phaseMetrics.yieldCount++;
//           }
//         });

//         phaseMetrics.plantCount += preHarvestPlants.length;
//       } else if (phaseName === 'In Pre Test') {
//         const preTestPlants = run.plant_details.plants.filter(plant => plant.plant_phase === 'Harvested');

//         preTestPlants.forEach(plant => {
//           if (plant.strain_name) uniqueStrains.add(plant.strain_name);
//           if (plant.phaseplan_id) runUniquePhasePlans.add(plant.phaseplan_id);

//           phaseMetrics.wetWeight += plant.wet_weight || 0;

//           const strainDetails = run.strains_details.find(detail => detail.strain_name === plant.strain_name);

//           if (strainDetails) {
//             const plantCount = 1;
//             const wetWeight = plant.wet_weight || 0;
//             const dryWeightCoef = plant.dry_weight || 0;
//             const flowerPrice = strainDetails.flower_price || 0;

//             const plantPrice = wetWeight * dryWeightCoef * flowerPrice;
//             phaseMetrics.totalPrice += plantPrice;

//             phaseMetrics.totalYield += dryWeightCoef;
//             phaseMetrics.yieldCount++;
//           }
//         });

//         phaseMetrics.plantCount += preTestPlants.length;
//       } else if (phaseName === 'In Pre Production') {
//         run.strains?.forEach(strain => {
//           uniqueStrains.add(strain.strain_name);

//           strain.batches?.forEach(batch => {
//             if (batch.phaseplan_id) runUniquePhasePlans.add(batch.phaseplan_id);
//           });

//           phaseMetrics.plantCount += strain.plant_count || 0;

//           const strainDetails = run.strains_details.find(detail => detail.strain_name === strain.strain_name);

//           if (strainDetails) {
//             const wetWeight = strainDetails.wet_weight || 0;
//             const dryWeightCoef = strainDetails.strain_yield || 0;
//             const flowerPrice = strainDetails.flower_price || 0;

//             const plantPrice = (strain.plant_count || 0) * wetWeight * dryWeightCoef * flowerPrice;

//             phaseMetrics.totalPrice += plantPrice;

//             phaseMetrics.wetWeight += wetWeight * (strain.plant_count || 0);

//             phaseMetrics.totalYield += dryWeightCoef;
//             phaseMetrics.yieldCount++;
//           }
//         });
//       }

//       if (run.plant_details) {
//         phaseMetrics.inPreHarvestCount += run.plant_details.in_pre_harvest || 0;
//         phaseMetrics.inPreTestCount += run.plant_details.in_pre_test || 0;
//       }

//       // Add current run's unique phase plans to the total count
//       phaseMetrics.harvestCount += runUniquePhasePlans.size;
//     });

//     phaseMetrics.strainCount = uniqueStrains.size;

//     phaseMetrics.dryWeightCoef = phaseMetrics.yieldCount > 0 ? (phaseMetrics.totalYield / phaseMetrics.yieldCount).toFixed(3) : '0';
//     phaseMetrics.avgPrice = `$${phaseMetrics.totalPrice.toFixed(2)}`;

//     console.log(`Final Phase Metrics for ${phaseName}:`, phaseMetrics);

//     return {
//       phase: phaseName,
//       harvestCount: phaseMetrics.harvestCount,
//       strainCount: phaseMetrics.strainCount,
//       plantCount: phaseMetrics.plantCount.toLocaleString(),
//       wetWeight: phaseMetrics.wetWeight.toLocaleString(),
//       dryWeightCoef: phaseMetrics.dryWeightCoef,
//       avgPrice: phaseMetrics.avgPrice,
//       inPreHarvestCount: phaseMetrics.inPreHarvestCount,
//       inPreTestCount: phaseMetrics.inPreTestCount,
//       rtsProfit: '',
//       destroyedCount: '',
//     };
//   };
//   const calculateMetrics = data => {
//     const preProductionMetrics = calculatePhaseMetrics(data, 'In Pre Production');
//     const preHarvestMetrics = calculatePhaseMetrics(data, 'In Pre Harvest', 'harvest');
//     const preTestMetrics = calculatePhaseMetrics(data, 'In Pre Test', 'test result');
//     const finishGoodInventory = calculatePhaseMetrics(data, 'Finish Good Inventory');
//     const soldInventory = calculatePhaseMetrics(data, 'Sold Inventory');

//     const parsePlantCount = (count) => parseInt(count.replace(/,/g, ''), 10) || 0;

// // Parse and calculate adjusted plant count
// const preProductionAdjustedPlantCount =
//   parsePlantCount(preProductionMetrics.plantCount) -
//   parsePlantCount(preHarvestMetrics.plantCount) -
//   parsePlantCount(preTestMetrics.plantCount);

// // Update the adjusted count with formatted value
// preProductionMetrics.plantCount = preProductionAdjustedPlantCount.toLocaleString();
//     return [
//       [
//         preProductionMetrics.phase,
//         preProductionMetrics.harvestCount,
//         preProductionMetrics.strainCount,
//         preProductionMetrics.plantCount,
//         preProductionMetrics.wetWeight,
//         preProductionMetrics.dryWeightCoef,
//         preProductionMetrics.avgPrice,
//         preProductionMetrics.rtsProfit,
//         preProductionMetrics.destroyedCount,
//       ],
//       [
//         preHarvestMetrics.phase,
//         preHarvestMetrics.harvestCount,
//         preHarvestMetrics.strainCount,
//         preHarvestMetrics.inPreHarvestCount,
//         preHarvestMetrics.wetWeight,
//         preHarvestMetrics.dryWeightCoef,
//         preHarvestMetrics.avgPrice,
//         preHarvestMetrics.rtsProfit,
//         preHarvestMetrics.destroyedCount,
//       ],
//       [
//         preTestMetrics.phase,
//         preTestMetrics.harvestCount,
//         preTestMetrics.strainCount,
//         preTestMetrics.inPreTestCount,
//         preTestMetrics.wetWeight,
//         preTestMetrics.dryWeightCoef,
//         preTestMetrics.avgPrice,
//         preTestMetrics.rtsProfit,
//         preTestMetrics.destroyedCount,
//       ],
//       [finishGoodInventory.phase],
//       [soldInventory.phase],
//     ];
//   };
//   const newTableEntries = data ? calculateMetrics(data) : [];

//   const ccTableProps = {
//     emptyTableMsg: 'No Data',
//     tableTitles: plantKeyTitleMap,
//     tableEntries: newTableEntries,
//     toShowAddBtn: false,
//     currDarkThemeClass,
//   };

//   const DisplayTable = ({ tableTitles, tableEntries, emptyTableMsg, currDarkThemeClass }) => {
//     const getIconForName = name => {
//       // Define a mapping of names to diamond colors
//       const colorMap = {
//         'In Pre Production': '#FFEC1F',
//         'In Pre Harvest': '#D9FFBC',
//         'In Pre Test': '#73DF12',
//         'Finish Good Inventory': '#FF8A3C',
//         'Sold Inventory': '#457DFF',
//       };

//       const diamondColor = colorMap[name] || '#D3D3D3'; // Default color if name is not found

//       return (
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="15"
//           height="15"
//           viewBox="0 0 15 15"
//           fill="none"
//           style={{
//             marginRight: '8px',
//             matrginLeft: '10px',
//             borderRadius: '1px',
//           }}
//         >
//           <g clipPath="url(#clip0_43_6347)">
//             <rect
//               x="7.07129"
//               y="0.428955"
//               width="10"
//               height="10"
//               rx="1"
//               transform="rotate(45 7.07129 0.428955)"
//               fill={diamondColor} // Set the color of the diamond itself
//             />
//           </g>
//           <defs>
//             <clipPath id="clip0_43_6347">
//               <rect width="14.1421" height="14.1421" fill="white" transform="translate(0 0.428955)" />
//             </clipPath>
//           </defs>
//         </svg>
//       );
//     };
//     return (
//       <Table
//         className={currDarkThemeClass} // Apply the dark theme class
//         aria-label="data table"
//         sx={{
//           '&.dark-theme': {
//             backgroundColor: '#1e1e1e',
//             color: '#fff', // Ensure table text color is white in dark mode
//             marginX: '0px', // Adds margin to both left and right sides of the table
//             backgroundColor: 'transparent', // Optional: Customize container background
//           },
//         }}
//       >
//         {/* Table Header */}
//         <TableHead>
//           <TableRow sx={{ height: '25px' }}>
//             {' '}
//             {/* Reduced header row height */}
//             {tableTitles.map((title, index) => (
//               <TableCell
//                 key={title.key}
//                 sx={{
//                   fontWeight: 'bold',
//                   fontSize: '1.1rem',
//                   whiteSpace: 'nowrap',
//                   overflow: 'hidden',
//                   textOverflow: 'ellipsis',
//                   borderBottom: '1px solid #414437',
//                   padding: index === 0 ? '4px 20px 4px 4px' : '4px 4px 4px 4px', // Add extra padding to the first column (Phase)
//                   borderRadius: index === 0 ? '10px 0 0 10px' : index === tableTitles.length - 1 ? '0 20px 20px 0' : 0, // Add border-radius to first and last headers
//                   '&.dark-theme': {
//                     backgroundColor: '#2E2F2D',
//                     color: '#A1A591',
//                   },
//                   '&.light-theme': {
//                     backgroundColor: '#D6E797',
//                   },
//                 }}
//                 className={currDarkThemeClass}
//               >
//                 {title.title}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>

//         {/* Table Body */}
//         <TableBody>
//           {tableEntries.length > 0 ? (
//             tableEntries.map((row, rowIndex) => (
//               <TableRow
//                 key={rowIndex}
//                 className={currDarkThemeClass}
//                 sx={{
//                   height: '40px', // Reduced row height
//                   borderBottom: '1px solid #414437',
//                   '&.dark-theme': {
//                     backgroundColor: 'transparent',
//                     '&:hover': {
//                       backgroundColor: '#2f2f2f',
//                     },
//                   },
//                 }}
//               >
//                 {row.map((cell, cellIndex) => (
//                   <TableCell
//                     key={cellIndex}
//                     sx={{
//                       whiteSpace: 'nowrap',
//                       overflow: 'hidden',
//                       textOverflow: 'ellipsis',
//                       fontSize: '1.1rem', // Reduced font size for compact rows
//                       padding: '4px', // Reduced padding for cells
//                       borderBottom: '1px solid #414437',
//                       color:
//                         cellIndex === 0 // Check if it's the first column (Phase)
//                           ? '#FFF' // Set Phase column text color to #FFF
//                           : row[0] === 'In Pre Production' || row[0] === 'In Pre Harvest'
//                           ?(cellIndex === 2 || cellIndex === 3) && row[0] === 'In Pre Harvest'
//                             ? '#FFF' // Set white color for Strain Cnt and Plant Cnt in "In Pre Harvest"
//                             : '#A1A591' // Set number color for other cases in these phases
//                           : row[0] === 'In Pre Test'
//                           ? '#FFF' // Set number color for In Pre Test
//                           : '', // Default for other rows,
//                     }}
//                     className={currDarkThemeClass}
//                   >
//                     {/* Render the icon for the first column only (e.g., name column) */}
//                     {cellIndex === 0 && getIconForName(cell)}
//                     {cell}
//                   </TableCell>
//                 ))}
//               </TableRow>
//             ))
//           ) : (
//             <TableRow>
//               <TableCell
//                 colSpan={tableTitles.length}
//                 className={currDarkThemeClass}
//                 sx={{
//                   textAlign: 'center',
//                   fontStyle: 'italic',
//                   borderBottom: '1px solid #414437',
//                   '&.dark-theme': {
//                     color: '#888',
//                   },
//                 }}
//               >
//                 {emptyTableMsg}
//               </TableCell>
//             </TableRow>
//           )}
//         </TableBody>
//       </Table>
//     );
//   };

//   return (
//     <Box>
//       <Box
//         sx={{
//           flexGrow: 1,
//           overflowY: 'auto',
//           padding: '2px',
//           width: '100%',
//         }}
//       >
//         <DisplayTable {...ccTableProps} currDarkThemeClass={currDarkThemeClass} />
//       </Box>
//       <Box
//         alignItems="flex-start"
//         sx={{
//           background: `linear-gradient(
// to right,
// rgba(177, 210, 58, 0.1) 50%, /* 10% opacity */
// rgba(177, 210, 58, 0.13) 50% /* 13% opacity */
// )`,
//           backgroundSize: '300px 100%', // Each vertical stripe is 40px wide
//           marginTop: '10px',
//           padding: '5px',
//           width: '100%', /* Ensures it fills the full width */
//           minHeight: '50vh', /* Ensures it fills the full viewport height */
//           height: 'auto',
//           position: 'flote', /* Allows it to span the full screen */
//           top: 0, /* Anchors it to the top */
//           left: 0, /* Anchors it to the left */
//         }}
//       >
//         <CommandCenterChart data={data} currDarkThemeClass={currDarkThemeClass} />
//       </Box>
//     </Box>
//   );
// }
import CommandCenterChart from '../CommandCenterChart';
import useThemeVal from '../../../utility/useThemeVal';
import { Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';

export default function CropRunTab({ data , tableData , statusChoices,selectedStates, setSelectedStates, phaseDetails, onSubphaseClick,drawerData}) {
  const currDarkThemeClass = useThemeVal();

  const plantKeyTitleMap = [
    { key: 'phase', title: 'Phase' },
    { key: 'harvest_count', title: 'Harvest Cnt' },
    { key: 'strain_count', title: 'Strain Cnt' },
    { key: 'plant_count', title: 'Plant Cnt' },
    { key: 'wet_weight', title: 'Wet Weight (lbs)' },
    { key: 'dry_weight_coef', title: 'Dry Weight Coef' },
    { key: 'price', title: 'Price ($)' },
    { key: 'rts_profit', title: 'RTS Profit' },
    { key: 'destroyed_plant_count', title: 'Destroyed Cnt' },
  ];
  
  

  // Directly use the backend data for table entries
  const newTableEntries = tableData || [];

  const ccTableProps = {
    emptyTableMsg: 'No Data',
    tableTitles: plantKeyTitleMap,
    tableEntries: newTableEntries,
    toShowAddBtn: false,
    currDarkThemeClass,
  };
  

  const DisplayTable = ({ tableTitles, tableEntries, emptyTableMsg, currDarkThemeClass }) => {
    const getIconForName = name => {
      // Define a mapping of names to diamond colors
      const colorMap = {
        'In Pre Production': '#FFEC1F',
        'In Pre Harvest': '#D9FFBC',
        'In Pre Test': '#73DF12',
        'Finish Good Inventory': '#FF8A3C',
        'Sold Inventory': '#457DFF',
      };

      const diamondColor = colorMap[name] || '#D3D3D3'; // Default color if name is not found

      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          style={{
            marginRight: '8px',
            matrginLeft: '10px',
            borderRadius: '1px',
          }}
        >
          <g clipPath="url(#clip0_43_6347)">
            <rect
              x="7.07129"
              y="0.428955"
              width="10"
              height="10"
              rx="1"
              transform="rotate(45 7.07129 0.428955)"
              fill={diamondColor} // Set the color of the diamond itself
            />
          </g>
          <defs>
            <clipPath id="clip0_43_6347">
              <rect width="14.1421" height="14.1421" fill="white" transform="translate(0 0.428955)" />
            </clipPath>
          </defs>
        </svg>
      );
    };
    return (
      <Table
        className={currDarkThemeClass} // Apply the dark theme class
        aria-label="data table"
        sx={{
          '&.dark-theme': {
            backgroundColor: '#1e1e1e',
            color: '#fff', // Ensure table text color is white in dark mode
            marginX: '0px', // Adds margin to both left and right sides of the table
            backgroundColor: 'transparent', // Optional: Customize container background
          },
        }}
      >
        {/* Table Header */}
        <TableHead>
          <TableRow sx={{ height: '25px' }}>
            {' '}
            {/* Reduced header row height */}
            {tableTitles.map((title, index) => (
              <TableCell
                key={title.key}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '1.1rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  borderBottom: '1px solid #414437',
                  padding: index === 0 ? '4px 20px 4px 4px' : '4px 4px 4px 4px', // Add extra padding to the first column (Phase)
                  borderRadius: index === 0 ? '10px 0 0 10px' : index === tableTitles.length - 1 ? '0 20px 20px 0' : 0, // Add border-radius to first and last headers
                  '&.dark-theme': {
                    backgroundColor: '#2E2F2D',
                    color: '#A1A591',
                  },
                  '&.light-theme': {
                    backgroundColor: '#D6E797',
                  },
                }}
                className={currDarkThemeClass}
              >
                {title.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {tableEntries.length > 0 ? (
            tableEntries.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                className={currDarkThemeClass}
                sx={{
                  height: '40px', // Reduced row height
                  borderBottom: '1px solid #414437',
                  '&.dark-theme': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: '#2f2f2f',
                    },
                  },
                }}
              >
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '1.1rem', // Reduced font size for compact rows
                      padding: '4px', // Reduced padding for cells
                      borderBottom: '1px solid #414437',
                      color:
                        cellIndex === 0 // Check if it's the first column (Phase)
                          ? '#FFF' // Set Phase column text color to #FFF
                          : row[0] === 'In Pre Production' || row[0] === 'In Pre Harvest'
                          ?(cellIndex === 2 || cellIndex === 3) && row[0] === 'In Pre Harvest'
                            ? '#FFF' // Set white color for Strain Cnt and Plant Cnt in "In Pre Harvest"
                            : '#A1A591' // Set number color for other cases in these phases
                          : row[0] === 'In Pre Test'
                          ? '#FFF' // Set number color for In Pre Test
                          : '', // Default for other rows,
                    }}
                    className={currDarkThemeClass}
                  >
                    {/* Render the icon for the first column only (e.g., name column) */}
                    {cellIndex === 0 && getIconForName(cell)}
                    {/* {cell} */}
                    {tableTitles[cellIndex].key === 'price' ? `$${cell}` : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={tableTitles.length}
                className={currDarkThemeClass}
                sx={{
                  textAlign: 'center',
                  fontStyle: 'italic',
                  borderBottom: '1px solid #414437',
                  '&.dark-theme': {
                    color: '#888',
                  },
                }}
              >
                {emptyTableMsg}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '2px',
          width: '100%',
        }}
      >
        <DisplayTable {...ccTableProps} currDarkThemeClass={currDarkThemeClass} />
      </Box>
      <Box
        alignItems="flex-start"
        sx={{
          background: `linear-gradient(
to right,
rgba(177, 210, 58, 0.1) 50%, /* 10% opacity */
rgba(177, 210, 58, 0.13) 50% /* 13% opacity */
)`,
          backgroundSize: '300px 100%', // Each vertical stripe is 40px wide
          marginTop: '10px',
          padding: '5px',
          width: '100%', /* Ensures it fills the full width */
          minHeight: '50vh', /* Ensures it fills the full viewport height */
          height: 'auto',
          position: 'flote', /* Allows it to span the full screen */
          top: 0, /* Anchors it to the top */
          left: 0, /* Anchors it to the left */
          // overflowY: "auto",  // ✅ Enable scrolling
          overflowX: "hidden",
        }}
      >
        <CommandCenterChart data={data} statusChoices={statusChoices} currDarkThemeClass={currDarkThemeClass}
         selectedStates={selectedStates} // ✅ Pass selected states
         setSelectedStates={setSelectedStates} 
         phaseDetails={phaseDetails}// ✅ Allow updates
         onSubphaseClick={onSubphaseClick}
         drawerData={drawerData}
        />
      </Box>
    </Box>
  );
}
