import { useCallback, useState, useMemo, useEffect } from 'react';
import { cardClasses, DisplayTable, fadeTimingMs, getFormattedTime, metricPlanClasses, miscClasses, NEW, popupClasses, taskOccurrenceChoices } from '../../utility';
import { InputAdornment, Button, Typography, MenuItem, TextField, Box, Paper, Stack, Fade, Alert, FormGroup, FormControlLabel, Checkbox, } from '@mui/material';

const getTableTitleMap = (occurrence) => {
  const baseColumns = [
    { key: 'perform_day', title: 'Perform Day' },
    { key: 'perform_time', title: 'Perform Time' },
  ];
  
  // Add additional columns only for weekly occurrence
  if (occurrence === 2) {
    return [
      ...baseColumns.slice(0, 1),
      { key: 'day_of_week', title: 'Day of Week' },
      { key: 'scheduled', title: 'Scheduled' },
      ...baseColumns.slice(1),
    ];
  }
  
  return baseColumns;
};

const newTableAlertMsg = 'Please generate your Schedule and Save the Changes to ensure future access to the Task Schedule.';

const DAYS_MAP = { sun: 'Sunday', mon: 'Monday', tue: 'Tuesday', wed: 'Wednesday', thu: 'Thursday', fri: 'Friday', sat: 'Saturday' };
const DAYS_MAP_REVERSE = { Sunday: 'sun', Monday: 'mon', Tuesday: 'tue', Wednesday: 'wed', Thursday: 'thu', Friday: 'fri', Saturday: 'sat'};

const getDayOfWeek = (startDay) => {
  const dayIndex = (startDay - 1) % 7;
  return Object.values(DAYS_MAP)[dayIndex];
};
 
const updateTableData = (occurrence, startDay, endDay, selectedDays, customDays, occurrenceTimestamp) => {
  const newTableData = [];
   
  if (occurrence === 0 && startDay) {
    const dayOfWeek = getDayOfWeek(parseInt(startDay));
    newTableData.push([
      1, 
      startDay.toString(),
      getFormattedTime(occurrenceTimestamp)
    ]);
  } 
  else if (occurrence === 1 && startDay && endDay) {
    for (let i = parseInt(startDay); i <= parseInt(endDay); i++) {
      newTableData.push([
        newTableData.length + 1,
        i.toString(),
        getFormattedTime(occurrenceTimestamp)
      ]);
    }
  }
  else if (occurrence === 2 && startDay && endDay) {
    for (let i = parseInt(startDay); i <= parseInt(endDay); i++) {
      const dayOfWeek = getDayOfWeek(i);
      const dayShortName = DAYS_MAP_REVERSE[dayOfWeek];
      const isScheduled = selectedDays[dayShortName];
      
      newTableData.push([
        newTableData.length + 1,
        i.toString(),
        dayOfWeek,
        isScheduled ? 'Yes' : 'No',
        isScheduled ? getFormattedTime(occurrenceTimestamp) : '-'
      ]);
    }
  }
  else if (occurrence === 3 && customDays.length > 0) {
    customDays.forEach((customDay) => {
      newTableData.push([
        newTableData.length + 1,
        customDay.toString(),
        getFormattedTime(occurrenceTimestamp)
      ]);
    });
  }

  return newTableData;
};

export const ScheduleTab = props => {
  const { defaultroutineid, savedRes, defaultRoutinesPerms, values, handleSetValues, currDarkThemeClass } = props;

  const [day, setDay] = useState('');
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');
  const [tableData, setTableData] = useState([]);
  const [customDays, setCustomDays] = useState([]);
  const [generatedSchedule, setGeneratedSchedule] = useState(defaultroutineid !== NEW);
  const [selectedDays, setSelectedDays] = useState({ sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false });

  const currTask = values?.routine_tasks?.[0] ?? {};

  const calculateDayValues = (start, end, total) => {
    if (start && end) {
      const newTotal = end - start + 1;
      return { start, end, total: newTotal };
    } else if (start && total) {
      const newEnd = parseInt(start) + parseInt(total) - 1;
      return { start, end: newEnd, total };
    }
    return { start, end, total };
  };

  const handleSetTask = useCallback(
    (task, prop, value) => {
      const updatedTasks = values?.routine_tasks ? [...values.routine_tasks] : [];
      const taskIndex = updatedTasks.findIndex(e => e.id === task?.id);

      if (taskIndex !== -1) {
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          [prop]: value,
        };
      } else {
        updatedTasks[0] = {
          ...updatedTasks[0],
          [prop]: value,
        };
      }
      handleSetValues('routine_tasks', updatedTasks);
    },
    [values?.routine_tasks, handleSetValues]
  );

  const parseOccurrenceSchedule = useCallback(() => {
    if (currTask?.occurrence_schedule) {
      try {
        if (currTask.occurrence === 0) {
          const dayValue = parseInt(currTask.occurrence_schedule.replace(/[\[\]]/g, ''));
          if (!isNaN(dayValue)) {
            setDay(dayValue);
            setGeneratedSchedule(true);
          }
        } else if (currTask.occurrence === 1) {
          const match = currTask.occurrence_schedule.match(/\[(\d+)-(\d+)\]/);
          if (match) {
            setStartDay(parseInt(match[1]));
            setEndDay(parseInt(match[2]));
            setDay(parseInt(match[2]) - parseInt(match[1]) + 1);
            setGeneratedSchedule(true);
          }
        } else if (currTask.occurrence === 2) {
          // regex to weekly pattern [startDay-endDay|mon,tue,wed]
          const match = currTask.occurrence_schedule.match(/\[(\d+)-(\d+)\|([\w,]+)\]/);
          if (match) {
            const startDayValue = parseInt(match[1]);
            const endDayValue = parseInt(match[2]);
            const selectedWeekDays = match[3].split(',');
            
            setStartDay(startDayValue);
            setEndDay(endDayValue);
            setDay(endDayValue - startDayValue + 1);
            
            const newSelectedDays = { sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false };
            selectedWeekDays.forEach(weekDay => {
              if (newSelectedDays.hasOwnProperty(weekDay)) {
                newSelectedDays[weekDay] = true;
              }
            });
            
            setSelectedDays(newSelectedDays);
            setGeneratedSchedule(true);
            
            const newTableData = updateTableData(
              2, 
              startDayValue,
              endDayValue,
              newSelectedDays,
              [],
              currTask?.occurrence_timestamp
            );
            setTableData(newTableData);
          }
        } else if (currTask.occurrence === 3) {
          const daysArray = JSON.parse(currTask.occurrence_schedule);
          setCustomDays(daysArray);
          setGeneratedSchedule(true);
        }
      } catch (error) {
        console.error('Error parsing occurrence schedule:', error);
      }
    }
  }, [currTask?.occurrence_schedule, currTask?.occurrence, currTask?.occurrence_timestamp]);

  useEffect(() => {
    parseOccurrenceSchedule();
  }, [parseOccurrenceSchedule]);

  const updateDisplayTableData = useCallback(() => {
      if  (!generatedSchedule) return;
  
    const newTableData = updateTableData(
      currTask?.occurrence,
      currTask?.occurrence === 0 ? day : startDay,
      endDay,
      selectedDays,
      customDays,
      currTask?.occurrence_timestamp
    );
  
    setTableData(newTableData);
  }, [currTask, day, startDay, endDay, generatedSchedule, customDays, selectedDays]);

  useEffect(() => {
    updateDisplayTableData();
  }, [updateDisplayTableData, currTask?.occurrence_schedule]);

  const handleGenerateSchedule = () => {
    let scheduleString = '';

    if (currTask?.occurrence === 0) {
      scheduleString = `[${day}]`;
      handleSetTask(currTask, 'occurrence_schedule', scheduleString);
    } else if (currTask?.occurrence === 1) {
      if (startDay && endDay) {
        scheduleString = `[${startDay}-${endDay}]`;
        handleSetTask(currTask, 'occurrence_schedule', scheduleString);
      }
    } else if (currTask?.occurrence === 2) {
      if (startDay && endDay && selectedDays) {
        let scheduleString = `[${startDay}-${endDay}|`;
        const selectedDayKeys = Object.entries(selectedDays)
          .filter(([day, selected]) => selected)
          .map(([day]) => day);

        scheduleString += selectedDayKeys.length > 0 ? selectedDayKeys.join(',') : '';
        scheduleString += ']';

        handleSetTask(currTask, 'occurrence_schedule', scheduleString);
      }
    } else if (currTask?.occurrence === 3 && day) {
      if (!customDays.includes(Number(day))) {
        const newCustomDays = [...customDays, Number(day)].sort((a, b) => a - b);
        setCustomDays(newCustomDays);
        handleSetTask(currTask, 'occurrence_schedule', JSON.stringify(newCustomDays));
        setDay('');
      }
    }

    setGeneratedSchedule(true);
  };

  const handleClearSchedule = () => {
    setDay('');
    setStartDay('');
    setEndDay('');
    setTableData([]);
    setGeneratedSchedule(false);
    setCustomDays([]);
    setSelectedDays({ sun: false, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false,});
    handleSetTask(currTask, 'occurrence_schedule', '');
  };

  const handleDayChange = day => {
    setSelectedDays(prev => ({
      ...prev,
      [day]: !prev[day],
    }));
  };

  const WeeklySchedule = () => (
    <FormGroup row>
      {Object.entries(selectedDays).map(([day, checked]) => (
        <FormControlLabel
          key={day}
          control={
            <Checkbox
              checked={checked}
              onChange={() => handleDayChange(day)}
              disabled={!defaultRoutinesPerms.update || values?.loading || values?.processing || values?.saving}
              sx={{ color: 'white' }}
            />
          }
          label={DAYS_MAP[day]}
          sx={{ color: 'white' }}
        />
      ))}
    </FormGroup>
  );

  const dRDTabTaskTextFieldProps = ({ task, prop, inputProps = {}, otherTextProps = {}, onChange }) => ({
    autoComplete: 'off',
    size: 'small',
    className: `${cardClasses.strainAccordion.textField} ${currDarkThemeClass}`,
    disabled: !defaultRoutinesPerms.update || values?.loading || values?.processing || values?.saving,
    inputProps,
    label: '',
    onChange: onChange || (({ target: { value } }) => handleSetTask(task, prop, value)),
    required: true,
    value: task?.[prop] ?? '',
    sx: { minWidth: '200px' },
    ...otherTextProps,
  });

  const occurrenceProps = task => ({
    prop: 'occurrence',
    task,
    otherTextProps: {
      value: task?.occurrence ?? '',
      select: true,
      InputProps: {
        startAdornment: <InputAdornment position="start">Occurrence: </InputAdornment>,
      },
    },
  });

  const occurrenceTimestampProps = task => ({
    prop: 'occurrence_timestamp',
    task,
    otherTextProps: {
      value: task?.occurrence_timestamp ?? '',
      InputProps: {
        type: 'time',
        startAdornment: <InputAdornment position="start">Occurrence Time: </InputAdornment>,
      },
    },
  });

  const dayProps = task => ({
    prop: 'day',
    task,
    otherTextProps: {
      value: day,
      required: currTask?.occurrence === 3 ? false : true,
      InputProps: {
        type: 'number',
        startAdornment: (
          <InputAdornment position="start">
            {currTask?.occurrence === 0 ? 'Day:' : currTask?.occurrence === 3 ? 'Day:' : 'Total Days:'}{' '}
          </InputAdornment>
        ),
      },
    },
    onChange: e => {
      const newTotal = e.target.value;
      if (currTask?.occurrence === 1 || currTask?.occurrence === 2) {
        const result = calculateDayValues(startDay, null, newTotal);
        setDay(newTotal);
        setEndDay(result.end);
      } else {
        setDay(newTotal);
      }
    },
  });

  const startDayProps = task => ({
    prop: 'start_day',
    task,
    otherTextProps: {
      value: startDay,
      InputProps: {
        type: 'number',
        startAdornment: <InputAdornment position="start">Start Day: </InputAdornment>,
      },
    },
    onChange: e => {
      const newStartDay = e.target.value;
      const result = calculateDayValues(newStartDay, endDay, day);
      setStartDay(newStartDay);
      setEndDay(result.end);
      setDay(result.total);
    },
  });

  const endDayProps = task => ({
    prop: 'end_day',
    task,
    otherTextProps: {
      value: endDay,
      InputProps: {
        type: 'number',
        startAdornment: <InputAdornment position="start">End Day: </InputAdornment>,
      },
    },
    onChange: e => {
      const newEndDay = e.target.value;
      const result = calculateDayValues(startDay, newEndDay, day);
      setEndDay(newEndDay);
      setDay(result.total);
    },
  });

  const getTableTitles = (occurrence) => {
    const baseTitles = [{ title: 'Sl. No' }];
    return [...baseTitles, ...getTableTitleMap(occurrence).map(e => ({ title: e.title }))];
  };

  const tableProps = {
    currDarkThemeClass,
    entity: 'Schedule',
    tableTitles: getTableTitles(currTask?.occurrence),
    tableEntries: tableData,
    toShowAddBtn: false,
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: 5,
  };

  const dRMenuItemProps = {
    className: `${metricPlanClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const occurrenceList = useMemo(
    () =>
      taskOccurrenceChoices.map((e, i) => (
        <MenuItem key={i} value={e.value} {...dRMenuItemProps}>
          {e.label}
        </MenuItem>
      )),
    []
  );

  const hasScheduleChanged = useMemo(() => {
    return currTask?.occurrence_schedule !== savedRes?.routine_tasks?.[0]?.occurrence_schedule;
  }, [currTask?.occurrence_schedule, savedRes]);

  const scheduleDescription = () => {
    if (!currTask) return null;

    const occurrence = currTask?.occurrence;
    const occurrenceTime = currTask?.occurrence_timestamp ? `at ${getFormattedTime(currTask.occurrence_timestamp)}` : '';
    let description = `This task will occur `;

    switch (occurrence) {
      case 0:
        description += `once on Day ${day} ${occurrenceTime}`;
        break;
      case 1:
        description += `daily from Day ${startDay} to Day ${endDay} ${occurrenceTime}`;
        break;
      case 2:
        const days = Object.entries(selectedDays)
          .filter(([_, selected]) => selected)
          .map(([day]) => DAYS_MAP[day])
          .join(', ');
        description += `weekly on ${days} from Day ${startDay} to Day ${endDay} ${occurrenceTime}`;
        break;
      case 3:
        description += `on custom Days: ${customDays.join(', ')} ${occurrenceTime}`;
        break;
      default:
        description += `at an unspecified schedule`;
        break;
    }

    return (
      <Typography style={{ color: 'white', paddingLeft: 16 }}>
        {description} for {values?.name}.
      </Typography>
    );
  };

  const shouldShowAlert = useMemo(() => {
    if (defaultroutineid === NEW) return !generatedSchedule;

    if (defaultroutineid !== NEW) {
      const currentTask = values?.routine_tasks?.[0];
      const savedTask = savedRes?.routine_tasks?.[0];
      if (currentTask?.occurrence !== savedTask?.occurrence) {
        return true;
      }
      if (currentTask?.occurrence_timestamp !== savedTask?.occurrence_timestamp) {
        return true;
      }
      switch (currentTask?.occurrence) {
        case 0:
          const savedDay = parseInt(savedTask?.occurrence_schedule?.replace(/[\[\]]/g, ''));
          return day !== savedDay;

        case 1:
          const savedRange = savedTask?.occurrence_schedule?.match(/\[(\d+)-(\d+)\]/);
          if (!savedRange) return true;
          return startDay !== parseInt(savedRange[1]) || endDay !== parseInt(savedRange[2]);

        case 2:
          const savedWeekly = savedTask?.occurrence_schedule?.match(/\[(\d+)-(\d+)\|([\w,]+)\]/);
          if (!savedWeekly) return true;

          const [, savedStart, savedEnd, savedDays] = savedWeekly;
          const savedSelectedDays = savedDays.split(',');
          const currentSelectedDays = Object.entries(selectedDays)
            .filter(([_, selected]) => selected)
            .map(([day]) => day);

          return (
            startDay !== parseInt(savedStart) ||
            endDay !== parseInt(savedEnd) ||
            savedSelectedDays.length !== currentSelectedDays.length ||
            !savedSelectedDays.every(day => currentSelectedDays.includes(day))
          );

        case 3:
          try {
            const savedCustomDays = JSON.parse(savedTask?.occurrence_schedule || '[]');
            return customDays.length !== savedCustomDays.length || !customDays.every(day => savedCustomDays.includes(day));
          } catch {
            return true;
          }

        default:
          return false;
      }
    }
    return false;
  }, [defaultroutineid, generatedSchedule, values?.routine_tasks, savedRes?.routine_tasks, day, startDay, endDay, selectedDays, customDays]);

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box>
        {shouldShowAlert && <Alert severity="info">{newTableAlertMsg}</Alert>}
        <Typography className={`${popupClasses?.routines?.titleTypo} ${currDarkThemeClass}`} sx={{ paddingY: '20px' }}>
          Schedule Task
        </Typography>
        <Paper className={`${popupClasses?.routines?.tasksContainer} ${currDarkThemeClass}`} variant="none" elevation={0} square>
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <TextField {...dRDTabTaskTextFieldProps(occurrenceProps(currTask))} sx={{ minWidth: '250px' }}>
              {occurrenceList}
            </TextField>

            {(currTask?.occurrence === 0 || currTask?.occurrence === 3) && (
              <>
                <TextField {...dRDTabTaskTextFieldProps(dayProps(currTask))} sx={{ maxWidth: '250px' }} />
                <TextField {...dRDTabTaskTextFieldProps(occurrenceTimestampProps(currTask))} sx={{ maxWidth: '300px' }} />
              </>
            )}

            {(currTask?.occurrence === 1 || currTask?.occurrence === 2) && (
              <>
                <TextField {...dRDTabTaskTextFieldProps(startDayProps(currTask))} sx={{ maxWidth: '250px' }} />
                <TextField {...dRDTabTaskTextFieldProps(dayProps(currTask))} sx={{ maxWidth: '250px' }} />
                <TextField {...dRDTabTaskTextFieldProps(endDayProps(currTask))} sx={{ maxWidth: '250px' }} />
                <TextField {...dRDTabTaskTextFieldProps(occurrenceTimestampProps(currTask))} sx={{ maxWidth: '300px' }} />
              </>
            )}
          </Stack>
          {currTask?.occurrence === 2 && (
            <Box sx={{ pl: 4, pb: 3 }}>
              <WeeklySchedule />
            </Box>
          )}

          <Stack direction="row" gap={2} sx={{ justifyContent: 'flex-start', paddingLeft: '40px' }}>
            <Button onClick={handleGenerateSchedule} variant="contained">
              Generate Schedule
            </Button>
            <Button onClick={handleClearSchedule} variant="contained">
              Clear Schedule
            </Button>
          </Stack>

          {((defaultroutineid !== NEW && hasScheduleChanged) || generatedSchedule) && scheduleDescription()}

          {currTask?.occurrence === 2 && <Typography style={{ color: 'white', paddingLeft: 16 }}>* Note: Day 1 is considered Sunday for the table view.</Typography>}

          {(generatedSchedule || (defaultroutineid !== NEW && hasScheduleChanged)) && (
            <Stack sx={{ paddingRight: 5, paddingLeft: 5 }}>
              <DisplayTable {...tableProps} />
            </Stack>
          )}
        </Paper>
      </Box>
    </Fade>
  );
};
