import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  BaseDetailCard,
  MetrcLogo,
  NEW,
  cardClasses,
  cropClasses,
  endpoints,
  errFeedbackFn,
  fadeTimingMs,
  logMsgToConsole,
  metricPlanCardClasses,
} from '../../../utility';
import { Fade, Typography, Box, Paper, Tabs, Tab, Divider, Stack, Tooltip } from '@mui/material';
import EntityDetailTimeline from '../../utils/EntityDetailTimeline';
import APIService from '../../../../api/apiService';
import NoEntityMsg from '../../../utility/messages/NoEntityMsg';
import RoutinesPopup from '../../Popup/RoutinesPopup';
import { FRTLContext } from '../../../../context/FRTLContext';

const subHeaderkeys = [
  { label: 'Total Tasks:', value: 'tasks_count' },
  { label: 'Pending:', value: 'tasks_status' },
  // { label: 'Elevated:', value: 'elevated_tasks' },
  // { label: 'Assignee:', value: 'assignees_count' },
];

const COMPLETESTATUSKEY = 'complete';
const METRCVALIDATED = 'metrc_validated';
const serialization = 'serialization';
const today = new Date();
today.setHours(0, 0, 0, 0);

export default function _PhasesTab(props) {
  const { currDarkThemeClass, cropplanid, selectedCropRunFarm, values: parentValues = {}, handleErrorWithFeedback, cropPlanPerms, handleSetParentValues } = props;

  const [values, setValues] = useState({
    strain: [],
    farm: null,
    selectedStrain: 0, // State to keep track of the selected strain
    selectedPhase: 0, // State to keep track of the selected phase
  });
  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), []);
  const [detailChildren, setDetailChildren] = useState(null);
  const {frtlContextValue, setFrtlContextValue} = useContext(FRTLContext);

  
  useEffect(() => {
    if (!frtlContextValue) {
  const fetchFrtlData = async () => {
    try {
      const response = await APIService.fetchInstance("new/frtl/");
      setFrtlContextValue(response?.data);
    } catch (error) {
      console.error("Error fetching FRTL data:", error);
    }};
      fetchFrtlData();
    }
  }, [frtlContextValue, setFrtlContextValue]);

  useEffect(() => {
    if (values.strain.length > 0) {
      const cropRunFarm = values.strain[0]?.farm_id;
      handleSetValues('farm', cropRunFarm);
    }
  }, [values.strain]);

  const pathwayTitle = useMemo(
    () =>
      `${values.strain[values.selectedStrain]?.phases[values.selectedPhase]?.name ?? ''} Pathway for ( ${values.strain[
        values.selectedStrain
      ]?.batches_names?.join(', ')} )`,
    [values?.selectedPhase, values?.selectedStrain, values?.strain]
  );

  const getLevelCompletedBySubphase = useCallback(subphase => {
    // Return early if the subphase has no routines
    const routines = subphase.routines || [];
    if (!routines.length) return false;

    // Use Array.every() and Array.some() to short-circuit the iteration
    return routines.every(routine => {
      const routineTasks = routine.routine_tasks || [];
      // If there are no routine tasks, consider the routine incomplete
      if (!routineTasks.length) return false;

      // Use Array.some() to check if any task is not complete
      return !routineTasks.some(task => task.status !== COMPLETESTATUSKEY && task.status !== METRCVALIDATED);
    });
  }, []);

  const handleSetPhaseRes = res => {
    handleSetValues('loading', false);

    const { data } = res;

    setValues(state => ({
      ...state,
      strain: data,
    }));
  };

  useEffect(() => {
    handleSetParentValues('defStrainWithDefRoutines', values?.strain);
  }, [values?.strain]);

  useEffect(() => {
    let entity = '',
      errConfig = {};

    handleSetValues('loading', true);

    if (cropplanid === NEW) {
      entity = `review-cropplan-routines`;

      APIService.createInstance(entity, parentValues)
        .then(handleSetPhaseRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
    } else {
      // todo: change the phase with cropplan id endpoint hit
      entity = `cropplan/phases/?id=${cropplanid}`;

      APIService.fetchInstance(entity)
        .then(handleSetPhaseRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
    }
  }, []);

  const handleStrainChange = (event, newValue) => {
    handleSetValues('selectedStrain', newValue);
    handleSetValues('selectedPhase', 0);
  };

  const handlePhaseChange = (event, newValue) => {
    handleSetValues('selectedPhase', newValue);
  };

  const pTPhProps = {
    variant: 'square',
    elevation: 0,
    sx: {
      background: 'transparent',
      overflow: 'auto',
    },
  };

  const handleRoutineModifyRes = (res, subphase_id) => {
    const { data } = res;
    const routine_id = data?.id;

    handleSetValues(
      'strain',
      values?.strain?.map((strain, i) =>
        values?.selectedStrain === i
          ? {
              ...strain,
              phases: strain?.phases?.map((phase, j) =>
                values?.selectedPhase === j
                  ? {
                      ...phase,
                      subphases: phase?.subphases?.map(subphase =>
                        subphase?.id === subphase_id
                          ? {
                              ...subphase,
                              routines: subphase?.routines?.map(routine => (routine?.id === routine_id ? data : routine)),
                            }
                          : subphase
                      ),
                    }
                  : phase
              ),
            }
          : strain
      )
    );
  };

  const handleRoutinesPopupSave = useCallback(
    (subphase_id, routine_id) => async value => {
      if (cropplanid == NEW) {
        handleSetValues(
          'strain',
          values?.strain?.map((strain, i) =>
            values?.selectedStrain === i
              ? {
                  ...strain,
                  phases: strain?.phases?.map((phase, j) =>
                    values?.selectedPhase === j
                      ? {
                          ...phase,
                          subphases: phase?.subphases?.map(subphase =>
                            subphase?.id === subphase_id
                              ? {
                                  ...subphase,
                                  routines: subphase?.routines?.map(routine => (routine?.id === routine_id ? value : routine)),
                                }
                              : subphase
                          ),
                        }
                      : phase
                  ),
                }
              : strain
          )
        );
        handlePopupClose();
        return;
      }

      let errConfig = {};
      const entityWithID = endpoints.routine.detail(routine_id);
      let res = {};

      try {
        res = await APIService.modifyInstance(entityWithID, value);
        handleRoutineModifyRes(res, subphase_id);
      } catch (err) {
        handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
      }

      handlePopupClose();
    },
    [values]
  );

  const handleSeeMoreRoutines = (routine, subphase) => () => {
    if (!cropPlanPerms?.read) return;
    handleSetValues('routinesOpen', true);

    setDetailChildren({
      routine,
      cropplanid,
      subphase,
      assignees: parentValues?.assignees,
      handleRoutinesPopupSave: handleRoutinesPopupSave(subphase?.id, routine?.id),
    });
  };

  const routineActionBtns = (routine, subphase) => [
    {
      key: 'see details',
      title: 'See Details',
      icon: 'div',
      otherProps: {
        onClick: handleSeeMoreRoutines(routine, subphase),
      },
    },
  ];

  const pTSubHeaderTypo = {
    className: `${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`,
    variant: 'subtitle2',
  };

  const routineSubHeaders = useCallback(
    routine => {
      // Calculate pending tasks count
      const pendingTasksCount = routine?.routine_tasks?.filter(task => task.status !== COMPLETESTATUSKEY && task.status !== METRCVALIDATED).length || 0;
  
      return (
        <Stack direction="row" width="100%" gap={2}>
          {subHeaderkeys.map((subheader, i) => (
            <Typography {...pTSubHeaderTypo} key={i}>
              {`${subheader.label} ${
                subheader.value === 'tasks_count' 
                  ? routine?.tasks_count ?? 0 
                  : subheader.value === 'tasks_status' 
                  ? pendingTasksCount 
                  : 0
              }`}
            </Typography>
          ))}
        </Stack>
      );
    },
    []
  );

  const pTRoutineCardProps = useCallback(
    (key, routine, subphase) => ({
      key,
      currDarkThemeClass,
      baseEntity: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          Routine {+key + 1}: {routine?.name}{' '}
          {routine?.routine_tasks?.length &&
          (routine?.routine_tasks[0].task_template_id === 3 ||
            routine?.routine_tasks[0].task_template_id === 4 ||
            routine?.routine_tasks[0].task_template_id === 5 ||
            routine?.routine_tasks[0].task_template_id === 7) ? (
            <MetrcLogo style={{ width: 24, height: 24, marginLeft: 8 }} />
          ) : (
            ''
          )}
        </div>
      ),
      isHeaderClickable: true,
      headerClickHandler: handleSeeMoreRoutines(routine, subphase),
      NoEntityMsg: 'Unknown Routine',
      cardActionBtns: routineActionBtns(routine, subphase),
      subHeader: routineSubHeaders(routine),
    }),
    [values]
  );

  const routinesList = useCallback(
    subphase => {
      let list = subphase?.routines?.map((routine, i) => <BaseDetailCard {...pTRoutineCardProps(i, routine, subphase)} />);

      if (!list.length) {
        list.push(<NoEntityMsg isLogo={false} isHeadContent={false} msg="No Available Routines" />);
      }

      return list;
    },
    [values]
  );

  const getSubTitle = subphase => {
    let startDate =
      new Date(subphase?.start_date).toLocaleDateString(undefined, { year: '2-digit', month: 'long', day: '2-digit' }) ??
      'No Serialization Subphase exists';
    let endDate =
      new Date(subphase?.end_date).toLocaleDateString(undefined, { year: '2-digit', month: 'long', day: '2-digit' }) ??
      'No Serialization Subphase exists';
    return <Typography>{`${startDate} -- ${endDate} (${subphase?.end_day - subphase?.start_day + 1})`}</Typography>;
  };

  const getDefaultExpandState = subphase => {
    let startDate = new Date(subphase?.start_date);
    let endDate = new Date(subphase?.end_date);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return startDate <= today && endDate >= today;
  };

  const timeLineEntry = useMemo(() => {
    let timelineEntries =
      values?.strain?.[values?.selectedStrain]?.phases?.[values?.selectedPhase]?.subphases?.map(subphase => ({
        key: subphase.name,
        children: routinesList(subphase),
        withSubtitle: true,
        subtitle: getSubTitle(subphase),
        isLevelCompleted: getLevelCompletedBySubphase(subphase),
        defaultExpanded: getDefaultExpandState(subphase),
      })) ?? [];

    return <EntityDetailTimeline currDarkThemeClass={currDarkThemeClass} timelineEntries={timelineEntries} />;
  }, [values?.strain, values?.selectedPhase, values?.selectedStrain]);

  const pTBoxProps = {
    className: `${cropClasses?.phases?.phasesBox} ${currDarkThemeClass}`,
  };

  const dICardTitleTypoProps = {
    className: `${cardClasses?.detailInfo?.titleTypo} ${currDarkThemeClass}`,
    sx: { marginTop: '10px' },
  };

  const strainTabProps = {
    className: `${cropClasses?.phases?.strainTab} ${currDarkThemeClass}`,
  };

  const strainTabsProps = {
    className: `${cropClasses?.phases?.strainTab} ${currDarkThemeClass}`,
    value: values.selectedStrain,
    onChange: handleStrainChange,
  };

  const strainTabList = values?.strain?.map((strain, index) => <Tab key={index} label={strain?.strain_name} {...strainTabProps} />);

  const pTPaperProps = {
    square: true,
    variant: 'none',
    className: `${cropClasses?.phases?.strainContainer} ${currDarkThemeClass}`,
  };

  const phaseTabProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
  };

  const phaseTabsProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
    value: values.selectedPhase,
    onChange: handlePhaseChange,
  };

  const phasesTabsList = values?.strain?.[values?.selectedStrain]?.phases?.map((phase, i) => (
    <Tab key={i} label={phase?.name ?? ''} {...phaseTabProps} />
  ));

  const handlePopupClose = () => {
    setDetailChildren({});

    handleSetValues('routinesOpen', false);
  };
  
const selectedFarm = values?.farm;

  const pTPopupProps = {
    currDarkThemeClass,
    open: values?.routinesOpen,

    onClose: handlePopupClose,
    selectedCropRunFarm: selectedFarm,
    ...detailChildren,
  };

  if (logMsgToConsole?.cropPlansTabs?.phases) {
    console.log(props);
    console.log(values);
  }

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...pTBoxProps}>
        <Tabs {...strainTabsProps}>{strainTabList}</Tabs>
        <Paper {...pTPaperProps}>
          <Tabs {...phaseTabsProps}>{phasesTabsList}</Tabs>
          <Typography {...dICardTitleTypoProps}>{pathwayTitle}</Typography>
          <Paper {...pTPhProps}>{timeLineEntry}</Paper>
        </Paper>
        {values?.routinesOpen && <RoutinesPopup {...pTPopupProps} />}
      </Box>
    </Fade>
  );
}
