import { Fade, Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
  fadeTimingMs,
  baseNewAndDetailClasses,
  BaseDetailCard,
  metricPlanCardClasses,
  EyeIcon2,
  getDefaultRoutineRoute,
  createNewRoutineEndpoint,
  endpoints,
  taskOccurrenceChoices,
} from '../../utility';
import NoEntityMsg from '../../utility/messages/NoEntityMsg';
import { useNavigate } from 'react-router';
import APIService from '../../../api/apiService';
import { FeedbackContext } from '../../../App';

const compliedRoutinesKey = 'complied-routines';
const personalizedRoutinesKey = 'non-complied-routines';
const defaultTab = compliedRoutinesKey;

const tabEntityMap = [
  { key: compliedRoutinesKey, label: 'Complied/Essential Routines' },
  { key: personalizedRoutinesKey, label: 'Custom Routines' },
];

export default function _RoutinesTab(props) {
  const { currDarkThemeClass, defaultRoutinesPerms, parentValues = {}, handleErrorWithFeedback } = props;

  const [assigneeData, setAssigneeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState({ currentTab: defaultTab });

  const feedbackCX = useContext(FeedbackContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssigneeData = async () => {
      setIsLoading(true);
      try {
        const routinesResponse = await APIService.fetchInstance(endpoints.default_routine.new);
        setAssigneeData(routinesResponse.data);
      } catch (error) {
        handleErrorWithFeedback({
          response: {
            status: 500,
            data: { text: 'Failed to fetch assignee data' },
          },
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssigneeData();
  }, [handleErrorWithFeedback]);

  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), []);

  const handleSetTabValue = (_, value) => handleSetValues('currentTab', value);

  const handleShow = useCallback(
    id => () => {
      if (!defaultRoutinesPerms?.update) {
        handleErrorWithFeedback({
          response: {
            status: 400,
            data: { text: 'Do Not have permissions to View/update' },
          },
        });
        return;
      }
      navigate(getDefaultRoutineRoute(id));
    },
    [defaultRoutinesPerms?.update, handleErrorWithFeedback, navigate]
  );

  const cardActionBtns = useCallback(
    routine => [
      {
        key: 'details',
        title: 'See details',
        icon: EyeIcon2,
        otherProps: {
          onClick: handleShow(routine?.id),
        },
      },
    ],
    [handleShow]
  );

  const formatTimeTo12Hour = useCallback(time => {
    if (typeof time !== 'string') return 'Invalid time';
    const [hour, minute] = time.split(':');
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute} ${ampm}`;
  }, []);

  const subHeader = useCallback(
    routine => {
      if (!parentValues.defaulttasks) return null;

      const routineTasks = parentValues.defaulttasks.filter(task => task.default_routine_id === routine.id);

      if (routineTasks.length === 0) {
        return (
          <Typography className={`${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`} variant="subtitle2">
            No Tasks found for this Routine
          </Typography>
        );
      }

      const groupedTasks = routineTasks.reduce((acc, task) => {
        const key = `${task.occurrence_timestamp}-${task.occurrence}-${task.default_parent_task_id}`;

        if (!acc[key]) {
          acc[key] = {
            ...task,
            taskRoles: [task.task_role],
          };
        } else {
          acc[key].taskRoles.push(task.task_role);
        }

        return acc;
      }, {});

      const taskDetails = Object.values(groupedTasks).map(task => {
        const assigneeNames =
          [...new Set(task.taskRoles)]
            .map(roleId => {
              if (!assigneeData?.assignees) return 'Loading...';
              const assignee = assigneeData.assignees.find(item => item.id === roleId);
              return assignee ? assignee.name : 'Unknown';
            })
            .filter(Boolean)
            .join(', ') || 'Unassigned';

        const occurrenceLabel = taskOccurrenceChoices.find(choice => choice.value === task.occurrence)?.label || 'Unknown';

        const dependentRoutineName = parentValues.defaulttasks.find(r => r.id === task.default_parent_task_id)?.name || 'None';

        return `Assigned to ${assigneeNames}, Occurrence: ${occurrenceLabel} at ${formatTimeTo12Hour(task.occurrence_timestamp) || 'N/A'}${
          dependentRoutineName !== 'None' ? `, Dependent Routine: ${dependentRoutineName}` : ''
        }`;
      });

      return (
        <Typography className={`${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`} variant="subtitle2">
          {taskDetails.join(' | ')}
        </Typography>
      );
    },
    [assigneeData, currDarkThemeClass, formatTimeTo12Hour, parentValues.defaulttasks]
  );

  const rCardProps = useCallback(
    (key, routine) => ({
      key,
      baseEntity: routine?.name,
      cardActionBtns: cardActionBtns(routine),
      subHeader: subHeader(routine),
      currDarkThemeClass,
      isHeaderClickable: true,
      headerClickHandler: handleShow(routine?.id),
    }),
    [cardActionBtns, currDarkThemeClass, handleShow, subHeader]
  );

  const getFilteredRoutineList = useCallback(
    (isMetrcCompliant, entity) => {
      if (isLoading) {
        return (
          <Typography className={currDarkThemeClass} variant="body1">
            Loading...
          </Typography>
        );
      }
  
      const filteredRoutines = (parentValues?.defaultroutines ?? []).filter(e => e?.is_metrc_compliance === isMetrcCompliant);
  
      return filteredRoutines.length > 0 ? (
        filteredRoutines.map((e, i) => <BaseDetailCard {...rCardProps(i, e)} />)
      ) : (
        <NoEntityMsg entity={entity} isCreateEnabled={defaultRoutinesPerms?.create} onCreateClick={() => navigate(createNewRoutineEndpoint)} />
      );
    },
    [isLoading, parentValues?.defaultroutines, currDarkThemeClass, defaultRoutinesPerms?.create, navigate, rCardProps]
  );

  const rTabProps = {
    className: `${baseNewAndDetailClasses?.tab} ${currDarkThemeClass}`,
  };

  const rTabsProps = {
    className: `${baseNewAndDetailClasses?.tabs} ${currDarkThemeClass} roomDetailTabsContent`,
    onChange: handleSetTabValue,
    value: values?.currentTab ?? defaultTab,
  };

  const tabsList = tabEntityMap.map((e, i) => <Tab key={i} value={e.key} label={e.label} {...rTabProps} />);

  const compliedRoutinesList = getFilteredRoutineList(true, 'Default Routine');
  const personalizedRoutinesList = getFilteredRoutineList(false, 'Personalized Routine');

  return (
    <Fade in timeout={fadeTimingMs}>
      <Box>
        <Tabs {...rTabsProps}>{tabsList}</Tabs>
        <Box marginTop={3} maxHeight={600} overflow="auto">
          {values?.currentTab === compliedRoutinesKey
            ? compliedRoutinesList
            : values?.currentTab === personalizedRoutinesKey
            ? personalizedRoutinesList
            : null}
        </Box>
      </Box>
    </Fade>
  );
}
