import { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import EntityDetailPopup from "./EntityDetailPopup";
import { popupClasses } from "./constants";

const strainKeys = ['strain_name','wet_weight', 'strain_yield', 'flower_price', 'cbd_level', 'thc_level', 'indica_percentage', 'sativa_percentage'];

export default function StrainEntryPopup(props) {
    
    const {
        handleStrainSave,
        handleSetSelStrain,
        selStrain,
        currDarkThemeClass='',
        ...otherProps
    } = props;

    const [values, setValues] = useState({});

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    const strainEntryTextFieldProps = ({prop, inputProps={}, otherTextProps={}}) => ({
        autoComplete: 'off',
        className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
        disabled: !otherProps?.toShowSave || values?.loading,
        fullWidth: true,
        inputProps,
        InputProps: {
            ...(prop === 'flower_price' && {
                startAdornment: '$',
            }),
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetSelStrain(prop, value),
        required: true,
        readOnly: false,
        value: selStrain?.[prop] ?? '',
        ...otherTextProps,
    });

    const handleStrainEntrySave = async () => {
        if (strainKeys.some(e => !selStrain?.[e])) return;

        handleSetValues('loading', true);
        
        let res = {success: false};

        try {
            res = await handleStrainSave(selStrain);
        } catch {}
        finally {
            handleSetValues('loading', false);
        }

        if (res?.success) {
            otherProps?.onClose?.();
        }
    }

    const entityDetailProps = {
        currDarkThemeClass,
        loading: values?.loading,
        headContentText: `Metrc Strain Id: ${selStrain?.metrc_strain_id}` || "Edit Strain",  
        onSaveClick: handleStrainEntrySave,
        ...otherProps,
    };

    const metrcStrainIdProps = {
        otherTextProps: {
            autoFocus: true,
            label: "Strain Metrc Id",
        },
        prop: 'metrc_strain_id'
    };

    const strainNameProps = {
        otherTextProps: {
            autoFocus: true,
            label: 'Name',
            InputProps: { readOnly: true }
        },
        prop: 'strain_name',
    };

    const strainYieldProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Yield (Dry to Wet Weight)',
            type: 'number',
        },
        prop: 'strain_yield',
    };

    const strainPriceProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Flower Price',
            type: 'number',
        },
        prop: 'flower_price',
    };
    const wetweightProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Wet Weight',
            type: 'number',
        },
        prop: 'wet_weight',
    };

    const cbdLevelProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'CBD Level',
            type: 'number',
        },
        prop: 'cbd_level',
    };

    const thcLevelProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'THC Level',
            type: 'number',
        },
        prop: 'thc_level',
    };

    const sativaPercentageProps = {
        otherTextProps: {
            label: 'Sativa Percentage',
            type: 'number',
        },
        inputProps: {
            max: 100,
            step: 0.01,
        },
        prop: 'sativa_percentage',
    };

    const indicaPercentageProps = {
        otherTextProps: {
            label: 'Indica Percentage',
            type: 'number',
        },
        inputProps: {
            max: 100,
            step: 0.01,
        },
        prop: 'indica_percentage',
    };

    return (
        <EntityDetailPopup {...entityDetailProps}>
            {/* Render metrcStrainId as a TextField here, along with other fields */}
            {/* <TextField {...strainEntryTextFieldProps(metrcStrainIdProps)} /> */}
            {/* <TextField {...strainEntryTextFieldProps(strainNameProps)} /> */}
            <TextField {...strainEntryTextFieldProps(wetweightProps)} />
            <TextField {...strainEntryTextFieldProps(strainYieldProps)} />
            <TextField {...strainEntryTextFieldProps(strainPriceProps)} />
            <TextField {...strainEntryTextFieldProps(cbdLevelProps)} />
            <TextField {...strainEntryTextFieldProps(thcLevelProps)} />
            <TextField {...strainEntryTextFieldProps(indicaPercentageProps)} />
            <TextField {...strainEntryTextFieldProps(sativaPercentageProps)} />
        </EntityDetailPopup>
    );
}
