import { Card, CardActions, Typography, Button, CardHeader, Paper } from '@mui/material';
import React, { useContext } from 'react';
import { EyeIcon2, metricPlanCardClasses, DelIcon2, qualityPlanNewAndDetailRoute, BaseDetailCard } from '../../utility';
import APIService from '../../../api/apiService';
import { useNavigate } from 'react-router';
import { FeedbackContext } from '../../../App';

export default function _QualityPlansTab(props) {
    const { qualityplans, currDarkThemeClass, qualityPlanPerms, handleSetValues, handleErrorWithFeedback } = props;
    const feedbackCX = useContext(FeedbackContext);

    const navigate = useNavigate();

    const handleDelete = (id) => () => {
        if (!qualityPlanPerms?.delete) {
            handleErrorWithFeedback({ 
                response: { 
                    status: 400, 
                    data: { text: 'Do Not have permissions to delete' } 
                } 
            });
            return;
        }
    
        const entityWithID = `qualityplan/${id}/`;
        APIService.deleteInstance(entityWithID)
            .then(() => {
                handleSetValues('qualityplans', qualityplans?.filter(plan => plan?.id !== id))
        feedbackCX.setContextValue(true, {
          type: 'success',
          message: 'Deleted Quality Plan Successfully.',
          autoHideDuration: 3000,
        })
            })
            .catch((error) => {
                if (error.response?.data?.related_cropplans) {
                    const cropPlans = error.response.data.related_cropplans;
                    const cropPlanNames = cropPlans.map(plan => plan.name).join(', ');
                    // Create a detailed error message
                    const errorMessage = {
                        response: {
                            status: 400,
                            data: {
                                text: `Cannot delete this Quality Plan. It is being used by ${cropPlans.length} crop plan: ${cropPlanNames}.`
                            }
                        }
                    };
                    handleErrorWithFeedback(errorMessage);
                } else {
                    handleErrorWithFeedback(error);
                }
            });
    };
    const handleShow = (id) => () => {
        if (!qualityPlanPerms?.update) {
            handleErrorWithFeedback({ response: { status: 400, data: { text: 'Do Not have permissions to View/update' } } });
            return;
        }
        navigate(`${qualityPlanNewAndDetailRoute}/${id}?tab=overview`);
    };

    const qPaperProps = {
        className: `${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`,
        variant: 'square',
        elevation: 0,
    };

    const cardActionBtns = (plan) => ([
        {
            key: 'delete',
            title: 'Delete plan',
            icon: DelIcon2,
            otherClasses: metricPlanCardClasses.delBtn,
            otherProps: {
                onClick: handleDelete(plan?.id)
            }
        },
        {
            key: 'details',
            title: 'Show All Plans',
            icon: EyeIcon2,
            otherProps: {
                onClick: handleShow(plan?.id),
            }
        }
    ]);

    const cdSubHeaderTypo = {
        className: `${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`,
        variant: 'subtitle2'
    };

    const subHeader = (plan) => <Typography {...cdSubHeaderTypo}>Contains: {plan?.metric_plans_count ?? 0} Metric Plans </Typography>;

    const qpCardProps = (key, plan) => ({
        key,
        baseEntity: plan?.name,
        noEntityMsg: 'Unknown Plan',
        currDarkThemeClass,
        cardActionBtns: cardActionBtns(plan),
        subHeader: subHeader(plan),
        isHeaderClickable: true, // Make the header clickable
        headerClickHandler: handleShow(plan?.id), // Use handleShow for navigation
    });

    if (!qualityPlanPerms?.read) return <Typography>No Permissions to View Quality Plans</Typography>;

    return (
        <Paper {...qPaperProps}>
            {
                qualityplans?.map((plan, i) => (<BaseDetailCard {...qpCardProps(i, plan)} />))
            }
        </Paper>
    );
}
