// exec.jsx exists to store all the utility functions in one place;
// Functions that were added to constants.jsx should be moved here as it is semantically correct

import { nodeNames, NEW } from './constants';

export const getValidElementsFilter = elem =>
  elem?.nodeType === 1 && (elem?.nodeName === nodeNames?.INPUT || elem?.nodeName === nodeNames?.TEXTAREA) && !elem?.ariaHidden;

// RoomDetail

export const getFabState = props => {
  const { roomid, tabValue, values = {} } = props;

  let result = {
    disableNext: true,
    disableNextTooltip: tabValue === 'lights',
    disablePrev: true,
    disablePrevTooltip: tabValue === 'general-info',
    nextTitle: '',
    prevTitle: '',
    showNext: tabValue !== 'lights',
    showPrev: tabValue !== 'general-info',
    // stepCompleted: false,
  };

  if (roomid === NEW) {
    if (tabValue === 'general-info') {
      if (!values?.room_name || !values?.room_name?.trim?.()?.length) result.nextTitle = 'Please enter a Room Name';
      // Not Required yet
      // else if ( !values?.description || !values?.description?.trim?.()?.length ) result.nextTitle = 'Please enter a Description';
      else if (!values?.addedMetrcRooms?.length) result.nextTitle = 'Please attach Metrc Room(s)';
      else {
        result.nextTitle = 'Save Details or Add Room Specifics';
        // result.stepCompleted = true;
        result.disableNext = false;
      }

      // result.showPrev = false;

      // return ({ nextTitle, stepCompleted, showNext, showPrev: false });
      return result;
    } else if (tabValue === 'specifics') {
      if (!values?.length || !values?.breadth) result.nextTitle = 'Please input length and breadth';
      else {
        result.nextTitle = 'Add or Modify Tables';
        result.disableNext = false;
      }
      result.disablePrev = false;

      return result;
    }

    result.disablePrev = false;
    result.disableNext = false;

    result.nextTitle =
      tabValue === 'specifics'
        ? 'Add or Modify Tables'
        : tabValue === 'sensors'
        ? 'Add or Modify Lights'
        : tabValue === 'tables'
        ? 'Add or Modify Sensors'
        : '';

    result.prevTitle =
      tabValue === 'specifics'
        ? 'Modify General Info'
        : tabValue === 'sensors'
        ? 'Add or Modify Tables'
        : tabValue === 'tables'
        ? 'Modify Specifics'
        : tabValue === 'lights'
        ? 'Add or Modify Sensors'
        : '';

    // if (tabValue === 'specifics') {
    //     result.nextTitle = 'Add Sensors';
    //     result.prevTitle = 'Modify General Info';
    //     // result.stepCompleted = true;
    //     // result.disablePrev = false;
    //     // result.disableNext = false;

    //     // return ({ nextTitle, stepCompleted, showNext, showPrev });
    // }

    return result;
  }
};

export const handleRoomDetailNextStep = props => () => {
  const {
    roomid,
    tabValue,
    validTabs = [],
    values = {},

    handleSetValues = () => {},
    handleSetTabValue = () => {},
  } = props;

  // const genInfoValid = ( values?.name?.length > 0 ) && ( values?.description?.length > 0 ) && ( values?.addedMetrcRooms?.length > 0 );

  const genInfoValid =
    values?.room_name &&
    values?.room_name?.trim?.()?.length > 0 &&
    // && ( values?.description && values?.description?.trim?.()?.length > 0  )  // Not required yet
    values?.addedMetrcRooms?.length > 0;

  // roomid === NEW
  //      tabValue === 'general-info':
  //          show one button for next step which tells user what to do next if steps not completed
  //

  if (roomid === NEW) {
    const currTab = validTabs?.find(e => e?.value === tabValue);

    if ((tabValue === 'general-info' && genInfoValid) || (tabValue !== 'general-info' && currTab)) {
      // These properties will now be set in RoomDetail without using values?.prevNextFabProps
      // const newPrevNextFabProps = {
      //     ...values?.prevNextFabProps,

      //     disablePrev: false,
      //     disableNext: false, // If general-info is valid, rest tabs are optional
      //     showPrev: true,
      //     showNext: true,
      // };

      // handleSetValues('prevNextFabProps', newPrevNextFabProps);

      if (currTab?.nextTab) {
        handleSetTabValue(null, currTab?.nextTab);
      }

      return;
    }

    // const currTab = validTabs?.find(e => e?.value === tabValue);

    // if (currTab?.nextTab) {
    //     handleSetTabValue(null, currTab?.nextTab);
    // }

    //     return;
    // }
  }
};

export const handleRoomDetailPrevStep = props => () => {
  const {
    roomid,
    tabValue,
    validTabs = [],
    values = {},

    handleSetValues = () => {},
    handleSetTabValue = () => {},
  } = props;

  if (roomid === NEW) {
    const currTab = validTabs?.find(e => e?.value === tabValue);

    if (currTab?.prevTab) {
      handleSetTabValue(null, currTab?.prevTab);
    }

    return;
  }
};

// PhasePlan

export const getPhasePlanFabState = props => {
  const { phaseplanid, tabValue, values } = props;

  let result = {
    disableNext: true,
    disableNextTooltip: tabValue === 'sub-phase-details',
    disablePrev: true,
    disablePrevTooltip: tabValue === 'general-info',
    nextTitle: '',
    prevTitle: '',
    showNext: tabValue !== 'sub-phase-details',
    showPrev: tabValue !== 'general-info',
  };

  if (phaseplanid === NEW) {
    if (tabValue === 'general-info') {
      if (!values?.name?.trim?.()?.length) result.nextTitle = 'Please enter a Phase Plan Name';
      else if (!values?.description?.trim?.()?.length) result.nextTitle = 'Please enter a Description';
      else {
        result.nextTitle = 'Add Sub Phase Details';
        result.disableNext = false;
      }
    } else if (tabValue === 'sub-phase-details') {
      result.prevTitle = 'Modify General Info';
      result.disablePrev = false;
    }

    return result;
  }
};

export const handlePhasePlanNextStep = props => () => {
  const {
    phaseplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;

  const genInfoValid = values?.name?.trim?.()?.length > 0 && values?.description?.trim?.()?.length > 0;

  if (phaseplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if ((tabValue === 'general-info' && genInfoValid) || (tabValue !== 'general-info' && currTab)) {
      if (currTab?.nextTab) {
        handleSetTabValue(currTab?.nextTab);
      }
    }
  }
};

export const handlePhasePlanPrevStep = props => () => {
  const {
    phaseplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;

  if (phaseplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if (currTab?.prevTab) {
      handleSetTabValue(currTab?.prevTab);
    }
  }
};


// Routines
export const getRoutinesFabState = props => {
  const { defaultroutineid, tabValue, values } = props;
  
  let result = {
    disableNext: true,
    disableNextTooltip: tabValue === 'schedule',
    disablePrev: true,
    disablePrevTooltip: tabValue === 'specifics',
    nextTitle: '',
    prevTitle: '',
    showNext: tabValue !== 'schedule',
    showPrev: tabValue !== 'specifics',
  };

  if (defaultroutineid === NEW) {
    if (tabValue === "specifics") {
      if (!values?.name?.trim?.()?.length) result.nextTitle = 'Please Enter a Routine Name.';
      else if (![0, 1, 2, 3, 4].includes(values?.task_level)) {
        result.nextTitle = 'Please select the Routine Level.';
      }
      else {
        result.nextTitle = 'Add Task Details';
        result.disableNext = false;
      }
    } else if (tabValue === 'tasks') {
      if (!values?.routine_tasks[0]?.name?.trim?.()?.length || values?.routine_tasks.length === 0) result.nextTitle = 'Please Enter a Task Name.';
      else if (!values?.routine_tasks[0]?.task_role.length) result.nextTitle = 'Please add Assignees.';
      else  {
        result.prevTitle = 'Modify Specifics Info';
        result.disablePrev = false;
        result.nextTitle = 'Schedule Routine';
        result.disableNext = false;
      }
    } else if (tabValue === 'schedule') {
      result.prevTitle = 'Modify Task Details';
      result.disablePrev = false;
    }
  }
  
  return result;
};

export const handleRoutinesNextStep = props => () => {
  const {
    defaultroutineid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;
  
  const genInfoValid = values?.name?.trim?.()?.length > 0 &&  values?.task_level !== null;
  
  if (defaultroutineid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);
    
    if ((tabValue === 'specifics' && genInfoValid) || (tabValue !== 'specifics' && currTab)) {
      if (currTab?.nextTab) {
        handleSetTabValue(null, currTab?.nextTab); 
      }
    }
  }
};

export const handleRoutinesPrevStep = props => () => {
  const {
    defaultroutineid,
    tabEntityMap,
    tabValue,
    values,
    
    handleSetTabValue,
  } = props;
  
  if (defaultroutineid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);
    
    if (currTab?.prevTab) {
      handleSetTabValue(null, currTab?.prevTab);
    }
  }
};

// metric plan

export const getMetricPlanFabState = props => {
  const { values, tabValue, metricplanid } = props;

  let result = {
    disableNext: true,
    disableNextTooltip: tabValue === 'metric-runs',
    disablePrev: true,
    disablePrevTooltip: tabValue === 'general-info',
    nextTitle: '',
    prevTitle: '',
    showNext: tabValue !== 'metric-runs',
    showPrev: tabValue !== 'general-info',
  };

  if (metricplanid === NEW) {
    if (tabValue === 'general-info') {
      if (!values?.name?.trim?.()?.length) result.nextTitle = 'Please enter a Metric Plan Name';
      else if (isNaN(values?.unit)) result.nextTitle = 'Please enter valid Unit';
      else if (isNaN(values?.phaseplan)) result.nextTitle = 'Please enter valid Phase plan';
      else if (!values?.description?.trim?.()?.length) result.nextTitle = 'Please enter a Description';
      else {
        result.nextTitle = 'Add Metric Runs Details';
        result.disableNext = false;
      }
    } else if (tabValue === 'metric-runs') {
      result.prevTitle = 'Modify General Info';
      result.disablePrev = false;
    }

    return result;
  }
};

export const handleMetricPlanNextStep = props => () => {
  const {
    metricplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;

  const genInfoValid =
    values?.name?.trim?.()?.length > 0 && values?.description?.trim?.()?.length > 0 && !isNaN(values?.phaseplan) && !isNaN(values?.unit);

  if (metricplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if ((tabValue === 'general-info' && genInfoValid) || (tabValue !== 'general-info' && currTab)) {
      if (currTab?.nextTab) {
        handleSetTabValue(null, currTab?.nextTab);
      }
    }
  }
};

export const handleMetricPlanPrevStep = props => () => {
  const {
    metricplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;

  if (metricplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if (currTab?.prevTab) {
      handleSetTabValue(currTab?.prevTab);
    }
  }
};

export const getCropPlanFabState = props => {
  const { values, tabValue, cropplanid } = props;

  let result = {
    disableNext: true,
    disablePrev: true,
    nextTitle: '',
    prevTitle: '',
    showPrev: tabValue !== 'specifics',
  };

  if (cropplanid === NEW) {
    if (tabValue === 'specifics') {
      if (!values?.name?.trim?.()?.length) result.nextTitle = 'Please enter a Valid Crop Name';
      else if (!values?.date_of_serialization?.trim?.()?.length) result.nextTitle = 'Please enter Date of Serialization';
      else if (isNaN(values?.qualityplan)) result.nextTitle = 'Please enter valid Quality plan';
      else if (isNaN(values?.phaseplan)) result.nextTitle = 'Please enter valid Phase plan';
      else if (isNaN(values?.owner)) result.nextTitle = 'Please enter valid Owner';
      else if (isNaN(values?.plant_count)) result.nextTitle = 'Please enter valid Plant Count';
      else if (isNaN(values?.farm)) result.nextTitle = 'Please select valid Farm and Strain';
      else if (!values?.strains || !values?.strains.length) result.nextTitle = 'Please enter at least one Strain';
      else {
        let allBatchesHaveFRTL = true;
      
        values.strains.forEach(strain => {
          strain.batches?.forEach(batch => {
            if (!batch.frtl) {
              allBatchesHaveFRTL = false;
            } }) });

        if (!allBatchesHaveFRTL) {
          result.nextTitle = 'Please ensure FRTL is set for all Batches';
        } else {

        result.disableNext = false;
        }
        result.nextTitle = 'Next Tab'
      }
    }
    if (tabValue === 'phases') {
      result.disablePrev = false;
    }

    return result;
  }
};
export const handleCropPlanNextStep = props => () => {
  const {
    cropplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;

  // const genInfoValid = values?.name?.trim?.()?.length > 0;

  if (cropplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if (tabValue === 'specifics' || (tabValue !== 'specifics' && currTab)) {
      if (currTab?.nextTab) {
        handleSetTabValue(null, currTab?.nextTab);
      }
    }
  }
};
export const handleCropPlanPrevStep = props => () => {
  const {
    cropplanid,
    tabEntityMap,
    tabValue,
    values,

    handleSetTabValue,
  } = props;
  console.log(cropplanid);

  if (cropplanid === NEW) {
    const currTab = tabEntityMap?.find?.(e => e?.validForNew && e?.key === tabValue);

    if (currTab?.prevTab) {
      handleSetTabValue(currTab?.prevTab);
    }
  }
};
