import { useNavigate } from "react-router";

import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';

import { FarmsIcon, RoomCardIcon } from "../utility/CustomSvgIcons";
import { cardClasses } from "../utility/constants";


export default function FarmCard(props) {
    const navigate = useNavigate();

    const {
        id='',
        farm_name='Unknown',
        license_number='Unknown',
        alias_room_count=0,
        room_count=0,
        currDarkThemeClass='',
    } = props;
    
    const farmCardPaperProps = {
        "aria-label": `See ${farm_name} detail page`,
        className: `${cardClasses?.farm?.paper} ${currDarkThemeClass} farmsTabContent`,
        onClick: () => navigate(`/configurations/farm/${id}/`),
        role: 'button',
        square: true,
        // sx: {
        //     borderBottom: '1px solid',
        //     borderColor: 'primary.800',
        //     flexShrink: 0,
        //     px: '28px',
        //     py: '36px',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        // },
        variant: 'none',
    };

    const farmCardIconProps = {
        className: `${cardClasses?.farm?.icon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '48px',
        //     width: '48px',
        // },
    };
    
    const farmCardNameTypoProps = {
        className: `${cardClasses?.farm?.nameTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     mt: '32px',
        //     width: '100%',
        // },
    };

    const farmCardCountTypoProps = {
        className: `${cardClasses?.farm?.countTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '14px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     mt: '8px',
        // },
    };

    const farmCardRoomIconProps = {
        className: `${cardClasses?.farm?.roomIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '16px',
        //     mr: '4px',
        //     verticalAlign: 'middle',
        //     width: '16px',
        //     '& path': {
        //         fill: 'none',
        //         stroke: 'primary.800',
        //         strokeWidth: 4,
        //     },
        // },
    };

    const farmCardLicenseTypoProps = {
        className: `${cardClasses?.farm?.countTypo} ${currDarkThemeClass}`,
        sx: { display: "flex", justifyContent: "flex-end", },
    };

    return (
        <Paper {...farmCardPaperProps}>
            <Typography {...farmCardLicenseTypoProps}>License: {license_number}</Typography>
            <FarmsIcon {...farmCardIconProps}/>
            <Typography {...farmCardNameTypoProps}>{farm_name}</Typography>
            <Typography {...farmCardCountTypoProps}>
                {/* <HexagonOutlinedIcon sx={{color: 'primary.800', transform: 'rotateZ(90deg)'}} />&nbsp; */}
                <RoomCardIcon {...farmCardRoomIconProps}/>
                {alias_room_count} Rooms ({room_count} Metrc Rooms Location)
            </Typography>
        </Paper>
    );
}