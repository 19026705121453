import { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
// import Checkbox from "@mui/material/Checkbox";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import MenuItem from "@mui/material/MenuItem";
import ReplayIcon from '@mui/icons-material/Replay';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
// import Typography from "@mui/material/Typography";

import EntityDetailPopup from "../utility/EntityDetailPopup";

import { errFeedbackFn, getHandleErrorWithFeedbackCX, miscClasses, popupClasses } from "../utility/constants";

import APIService from "../../api/apiService";
import { FeedbackContext } from "../../App";


const keys = ['first_name', 'last_name', 'email'];


export default function InviteUserPopup(props) {
    const {
        edit=false,
        handleAddInvitedUser, // for adding the created user to the list
        handleSaveModifiedInvite,
        permissions=[],
        roles=[],
        user={},
        currDarkThemeClass='',
        ...otherProps
    } = props;
    console.log("=======", props)

    const [values, setValues] = useState({});

    const feedbackCX = useContext(FeedbackContext);

    useEffect(() => {
        if (edit) {
            setValues(user);
            // setValues({
            //     ...role,
            //     data: role?.group?.permissions?.map(e => e?.codename),
            // });
        } 
        // else {
        //     setValues({});
        // }
    }, [edit]);

    useEffect(() => {
        if (!edit) {
            setValues({})
        }
    }, [props]);
    // const navigate = useNavigate();
    
    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    const handleError = errPropsFn => err => {

        handleErrorWithFeedback(errPropsFn)(err);
        
        console.log('An error occured');console.log(err);

        if (err?.response?.data?.sameOrg) {
            handleSetValues('errorProp', 'email');
            handleSetValues('helperText', err.response.data.reason);
        }
    }


    const handleFinally = () => {
        handleSetValues('loading', false);
        handleSetValues('processing', false);
    }

    const handlePermissionsCreateAndSaveRes = res => {
        const {data} = res; 
        // console.log('got data in invite popup');console.log(data);

        setValues({}); // Will also set loading to false

        if (edit) {
            handleSaveModifiedInvite(data);
        } else {
            handleAddInvitedUser(data);
        }

        otherProps?.onClose?.();

    };

    const handleEmailResendRes = res => {
        handleSetValues('processing', false);
    };

    const inviteNameBoxProps = {
        className: `${popupClasses?.inviteUser?.box} ${currDarkThemeClass}`,
        sx: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
        },
    };

    const handleResendEmail = () => {
        if (values?.loading || values?.processing || values?.is_active) return;

        handleSetValues('processing', true);

        const errConfig = {
            400: 'Invalid request. Mail could not be sent',
        };
        
        const entityWithID = `invite/${user.id}/?resend=true`;

        APIService.modifyInstance(entityWithID, {})
        .then(handleEmailResendRes)
        .catch(handleError(errFeedbackFn(errConfig)))
        .finally(handleFinally)
    };

    const inviteResendInviteProps = {
        disabled: values?.loading || values?.processing || values?.is_active,
        onClick: handleResendEmail,
        // START HERE: Style replay icon in dark theme
        className: `${popupClasses?.inviteUser?.replayIcon} ${currDarkThemeClass}`,
        sx: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
    };

    const handleValueChange = (prop, value) => {
        if (values?.errorProp === prop) {
            handleSetValues('errorProp', '');
        }
        handleSetValues(prop, value);
    }

    const inviteTextFieldProps = ({prop, inputProps={}, otherTextProps={}, otherSxProps={}}) => ({
        autoComplete: 'off',
        className: `${popupClasses?.inviteUser?.textField} ${currDarkThemeClass}`,
        disabled: values?.loading || values?.processing,
        error: values?.errorProp === prop,
        helperText: values?.errorProp === prop && values?.helperText,
        fullWidth: true,
        inputProps,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        //     ...inputProps,
        // },
        // InputLabelProps: {
        //     sx: {
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        InputProps: {
            ...(prop === 'email' && {
                // If user is active, make the email field read-only
                readOnly: values?.is_active ? true : false, // disable or make read-only when user is active
                endAdornment: (
                    // Show Resend Invite button only if the user is not active
                    !values?.is_active && edit && (
                        <Tooltip title="Resend Invite">
                            <ReplayIcon {...inviteResendInviteProps} />
                        </Tooltip>
                    )
                ),
            }),
        },
            // sx: {
            //     borderRadius: 0,
            //     '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
            //         borderColor: 'grey.800',
            //     },
            // },

        label: ' ',
        // onChange: ({target: {value}}) => handleSetValues(prop, value),
        onChange: ({target: {value}}) => handleValueChange(prop, value),
        required: true,
        sx: {
            // color: 'grey.800',
            // mt: '20px',
            ...otherSxProps,
        },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const firstNameProps = {
        otherSxProps: {
            mr: '8px',
            width: 'calc(50% - 8px)',
        },
        otherTextProps: {
            autoFocus: true,
            fullWidth: false,
            label: 'First Name',
        },
        prop: 'first_name',
    };

    const lastNameProps = {
        otherSxProps: {
            ml: '8px',
            width: 'calc(50% - 8px)',
        },
        otherTextProps: {
            fullWidth: false,
            label: 'Last Name',
        },
        prop: 'last_name',
    }; 

    const emailProps = {
        otherTextProps: {
            label: 'Email',
            type: 'email',
        },
        prop: 'email',
    };
    

    // const roleProps = {
    //     otherTextProps: {
    //         label: 'Choose a Role',
    //         select: true,
    //         value: values?.role ?? '',
    //     },
    //     prop: 'role',
    // };

    // const inviteRoleMenuItemProps = {
    //     className: `${popupClasses?.inviteUser?.roleMenuItem} ${currDarkThemeClass}`,
    //     focusVisibleClassName: `${miscClasses?.menuItemFocusDark}`,
    // };

    // const rolesList = roles?.map((role, i) => 
    //     <MenuItem key={i} value={role?.id} {...inviteRoleMenuItemProps}>{role?.name}</MenuItem>
    // ) || [];
    
    // if (rolesList.length === 0) {
    //     rolesList.push(<MenuItem key={-1} value={-1} disabled {...inviteRoleMenuItemProps}>No Roles Available</MenuItem>)
    // }

    const permissionProps = {
        otherTextProps: {
            label: 'Choose one or more Roles',
            // onChange: ({target: {value}}) => handleSetValues('groups', value),
            select: true,
            SelectProps: {
                multiple: true,
                renderValue: (value) => roles?.filter(role => value?.includes(+role?.id))?.map(role => role?.name)?.join(', '),
            },
            value: values?.role ?? [],
        },
        prop: 'role',
    };

    const invitePermMenuItemProps = {
        className: `${popupClasses?.inviteUser?.permMenuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: `${miscClasses?.menuItemFocusDark}`,
    };

    const rolesList = roles?.map((role, i) => 
        <MenuItem key={i} value={role?.id} {...invitePermMenuItemProps}>{role?.name}</MenuItem>
    ) || [];
    
    if (rolesList.length === 0) {
        rolesList.push(<MenuItem key={-1} value={-1} disabled {...invitePermMenuItemProps}>No Roles Created Yet...</MenuItem>)
    }

    const toDisable = () => {
        // return true;
        if (edit) {
            //if ((values?.role?.length ?? 0) < 1 || user?.is_active || keys.some(key => values?.[key]?.trim?.()?.length < 1)) return true;
            if ((values?.role?.length ?? 0) < 1 || keys.some(key => values?.[key]?.trim?.()?.length < 1)) return true;
            const forwardCheck = values?.role?.every(role => user?.role?.includes(role));
            const backwardCheck = user?.role?.every(role => values?.role?.includes(role));

            // It is possible to take away permissions

            return keys.every(key => values?.[key] === user?.[key]) && forwardCheck && backwardCheck;
        }
        
        // Add regex test for email or turn Popup into form
        return keys.some(key => !values?.[key]) || ( values?.role?.length ?? 0 ) < 1;
        // return keys.some(key => !values?.[key]); // Allow user with no roles?
    };

    const handleInviteCreateAndSave = () => {
        if (toDisable()) return;

        handleSetValues('loading', true);

        let errConfig = {};

        if (edit) {
            // Check if user is active, then remove the resend invite button from both front and back end
            if (user.is_active) {
                const entityWithID = `user/${user.id}/`;
            // console.log('values');console.log(values);return

            APIService.modifyInstance(entityWithID, values)
            .then(handlePermissionsCreateAndSaveRes)
            .catch(handleError(errFeedbackFn(errConfig)))
            .finally(handleFinally)
                //handleSetValues('loading', false);
                return;
            };

            // const prevPermList = savedRole?.group?.permissions?.map(e => e?.codename);
            // const newPermList = values?.data;

            // const forwardCheck = prevPermList?.every(perm => newPermList.includes(perm));
            // const backwardCheck = newPermList?.every(perm => prevPermList.includes(perm));

            const entityWithID = `invite/${user.id}/`;
            // console.log('values');console.log(values);return

            APIService.modifyInstance(entityWithID, values)
            .then(handlePermissionsCreateAndSaveRes)
            .catch(handleError(errFeedbackFn(errConfig)))
            .finally(handleFinally)

            return;
        }

        errConfig = {
            400: 'Invalid request. Invite not sent',
        };

        // console.log('values');console.log(values);
        const entity = 'invite/';

        APIService.createInstance(entity, values)
        .then(handlePermissionsCreateAndSaveRes)
        .catch(handleError(errFeedbackFn(errConfig)))
        .finally(handleFinally)
    };

    const handleClosePopup = () => {
        setValues({});
        otherProps?.onClose?.();
    }

    const entityDetailProps = {
        currDarkThemeClass,
        headContentText: null,
        loading: values?.loading,
        onClose: handleClosePopup,
        onSaveClick: handleInviteCreateAndSave,
        title: 'Sending invite',
        toDisableSave: toDisable(),
        // toShowSave: edit ? !user?.is_active : true,
        // saveText: 'Send',
        showSendButton: edit || !user?.is_active,
        showSaveButton: edit || user?.is_active,
        saveText: edit && user?.is_active ? 'Save' : 'Send',
        ...otherProps,
    };

    return (
        <EntityDetailPopup {...entityDetailProps}>
            <Box {...inviteNameBoxProps}>
                <TextField {...inviteTextFieldProps(firstNameProps)} />
                <TextField {...inviteTextFieldProps(lastNameProps)} />
            </Box>
            <TextField {...inviteTextFieldProps(emailProps)} />
            {/* <TextField {...inviteTextFieldProps(roleProps)} >
                {rolesList}
            </TextField> */}
            <TextField {...inviteTextFieldProps(permissionProps)} >
                {rolesList}
            </TextField>
        </EntityDetailPopup>
    );
}