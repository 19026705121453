import { createAction, deleteAction, readAction, updateAction } from "../components/utility";

const _crudActionsMap = {
    [createAction]: 'create',
    [readAction]: 'read',
    [deleteAction]: 'delete',
    [updateAction]: 'update'
}

export const hasPermissions = (models = [], tokenDetails) => {    
    const { permissions = [], is_staff = false } = tokenDetails || '';

    const perms = {}

    models.forEach((model) => {        
        if (is_staff) {
            Object.values(_crudActionsMap).forEach((action) => {
                perms[model] = { ...perms[model] ?? {}, [action]: true };
            })
            return;
        }
        let modelPerms = permissions?.filter(perm => perm.includes(`_${model}`)) ?? [];
        let _crudOperationKeys = Object.keys(_crudActionsMap);
        modelPerms.forEach((perm) => {
            let action = perm.split('_')[0].split('.')[1];            
            let mappedAction = _crudActionsMap[action]
            perms[model] = { ...perms[model], [mappedAction]: _crudOperationKeys.includes(action) }
        })
    })

    return perms;

}