import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from "@mui/material/Typography";

import { maxForecastTableHt, maxForecastTableWd, defHarvestForecastTableTitlesMap, defEmptyPropsFn } from "../constants";


// const defTableTitlesMap = [
//     {key: 'Strain', valueKey: 'strain_name'},
//     {key: 'No. of Plants Harvested', valueKey: 'plants_harvest_count'},
//     {key: 'Dry Flower(lbs.)', valueKey: 'dry_flower'},
//     {key: 'Flower Price Point', valueKey: 'flower_price_point'},
//     {key: 'Total Flower Value', valueKey: 'total_flower_value'},
// ];

const defTableTitles = defHarvestForecastTableTitlesMap?.map(e => e?.key);

const defEmptyProps = {};

export default function GrowDashboardDetailTable(props) {

    const {
        emptyTableMsg,
        entity,
        tableEntries=[],
        tableTitles=defTableTitles,

        otherEntryCellProps=defEmptyProps,
        otherEntryCellSxProps=defEmptyPropsFn,
        otherEntryRowSxProps=defEmptyProps,
        otherHeadSxProps=defEmptyProps,
        otherTableContainerSxProps=defEmptyProps,
        otherTitleCellSxProps=defEmptyPropsFn,
        otherTitleRowSxProps=defEmptyProps,
    } = props;

    const titlesLen = tableTitles.length;
    
    const displayTableContainerProps = {
        className: 'customScroll',
        component: Box,
        sx: {
            backgroundColor: 'primary.dashboard.white',
            borderRadius: '12px',
            // maxHeight: `${maxForecastTableHt}px`,
            // minHeight: `${maxForecastTableHt}px`,
            // maxWidth: `${maxForecastTableWd}px`, // 01032023: prev: enabled
            pb: '20px',
            width: '100%',
            ...otherTableContainerSxProps,
        },
        variant: 'none',
    };

    const displayTableProps = {
        stickyHeader: true,
        sx: {
            width: '100%',
        },
    };

    const displayTableHeadProps = {
        sx: {
            ...otherHeadSxProps,
        },
    };

    const displayTableTitleRowProps = {
        sx: {
            ...otherTitleRowSxProps,
        },
    };

    const displayTableRowProps = {
        sx: {
            '& td, & th': { 
                border: 0,
            },
            ...otherEntryRowSxProps,
        },
    };

    const displayTableTitleCellProps = (i) => ({
        sx: {
            backgroundColor: 'primary.dashboard.white',
            borderBottom: 'none',
            color: 'primary.dashboard.cardTitle',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.25,
            pl: i === 0 || i === titlesLen - 1 ? '26px' : '17px',
            pr: i === titlesLen - 1 ? '26px' : 'auto',
            pt: '12px',
            pb: '8px',
            // whiteSpace: 'nowrap',
            // '&:first-of-type': {
            //     borderTopLeftRadius: '12px',
            // },
            // '&:last-of-type': {
            //     borderTopRightRadius: '12px',
            // },
            width: 'fit-content',
            ...otherTitleCellSxProps(i),
        },
        variant: 'head',
        // Can add logic for setting column width here
    });

    const displayTableEntryCellProps = (idx) => ({
        ...(idx === 0 && {
            component: 'th',
            scope: 'row',
        }),
        sx: {
            color: 'grey.700',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: 1.25,
            // pb: 0,
            pl: idx === 0 || idx === titlesLen - 1 ? '26px' : '17px',
            pr: idx === titlesLen - 1 ? '26px' : 'inherit',
            pt: '6px',
            pb: 0,
            // whiteSpace: 'nowrap',
            ...otherEntryCellSxProps(idx),
        },
        variant: 'body',
        ...otherEntryCellProps,
    });

    const tableTitlesList = tableTitles.map((e, i) => 
        <TableCell key={i} {...displayTableTitleCellProps(i)}>{e}</TableCell>
    );
    
    const tableEntriesList = tableEntries.map((e, i) => 
        <TableRow key={`row-${i}`} {...displayTableRowProps}>
            {
                e.map((el, idx) => <TableCell key={`cell-${idx}`} {...displayTableEntryCellProps(idx)}>{el ?? ''}</TableCell>)
            }
        </TableRow>
    );

    const noEntityProps = {
        colSpan: '100%',
        sx: {
            color: 'grey.500',
            fontSize: '20px', 
            letterSpacing: '-0.05em',
            textAlign: 'center',
        },
    };


    return (
        <TableContainer {...displayTableContainerProps}>
            <Table {...displayTableProps}>
                <TableHead {...displayTableHeadProps}>
                    <TableRow {...displayTableTitleRowProps}>
                        {tableTitlesList}
                    </TableRow>
                </TableHead>

                <TableBody> 
                    {
                        tableEntriesList.length === 0 
                    ? <TableRow><TableCell {...noEntityProps}>{emptyTableMsg ?? `No ${entity ?? 'Data'} Available`}</TableCell></TableRow>
                        : tableEntriesList
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}