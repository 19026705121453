import React, { useEffect, useCallback , useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { AddFarmIcon, DelIcon, MetrcLogo, PlusIcon} from '../utility/CustomSvgIcons';
import { InfoIcon } from '../utility/CustomSvgIcons';
import { compClasses } from '../utility/constants';
import { ListItemText, MenuItem  } from '@mui/material';

import { phasePlanClasses} from '../utility/constants';

export default function SubPhaseEntry(props) {
  const { currDarkThemeClass = '', pos, subphase = {}, subPhaseErr = false, values, handleSetValues} = props;
  const [selectedRoutines, setSelectedRoutines] = useState(subphase?.default_routines ?? []);
  useEffect(() => {
    // Ensure new subphases are included by default
    if (subphase?.isIncluded === undefined) {
      handleSetSubPhase('isIncluded', true)({ target: { checked: true } });
    }
  }, [subphase]);
  useEffect(() => {
    if (subphase?.id) {
        setSelectedRoutines(()=>subphase?.default_routines || []);
    }
}, [subphase?.id]); //Runs only when subphase ID changes, prevents unnecessary updates

  useEffect(() => {
    const updatedPhases = [...values?.subphases];  // Copy the subphases
    const currentIndex = pos;  // Current subphase index

    if (updatedPhases[currentIndex]) {
      const startDay = Number(updatedPhases[currentIndex]?.start_day) || 1;
      const endDay = Number(updatedPhases[currentIndex]?.end_day) || startDay;

      // Calculate total_days based on start_day and end_day
      updatedPhases[currentIndex].total_day = Math.max(0, endDay - startDay + 1);  // Ensure total_days is at least 1

      // Set updated values in state
      handleSetValues('subphases', updatedPhases);
    }
  }, [values?.subphases, pos, handleSetValues]);

  const subPhaseEntryBoxProps = {
    className: `${compClasses?.subPhaseEntry?.box} ${currDarkThemeClass}`,
  };

  const subPhaseEntryErrorTitle = 'Invalid value';

  const subPhaseEntryTextInfoIconProps = {
    className: `${compClasses?.subPhaseEntry?.textInfoIcon} ${currDarkThemeClass}`,
  };

  const subPhaseEntryTextFieldProps = ({ prop, inputProps = {}, otherSxProps = {}, otherInputProps = {}, ...otherTextProps }) => ({
    autoComplete: 'off',
    className: `${compClasses?.subPhaseEntry?.textField} ${currDarkThemeClass} ${
      otherTextProps?.disabled ? compClasses?.subPhaseEntry?.disabled : ''
    }`,
    disabled: values?.loading?.processing || values?.loading?.fetching,
    inputProps,
    InputProps: {
      endAdornment: subPhaseErr && prop !== 'name' && subphase?.isIncluded && (
        <Tooltip title={subPhaseEntryErrorTitle}>
          <Box>
            <InfoIcon {...subPhaseEntryTextInfoIconProps} />
          </Box>
        </Tooltip>
      ),
      ...otherInputProps,
    },
    label: ' ',
    required: true,
    sx: {
      ...otherSxProps,
    },
    ...(prop && {
      onChange: handleSetSubPhase(prop),
      value: subphase?.[prop] ?? '',
    }),
    ...otherTextProps,
  });
  const handleAddSubPhaseEntry =useCallback( (values, handleSetValues, index) => () => {
    const id = `tempRef-${values?.subphases?.length || 0}`;
    setSelectedRoutines(()=>[]);
    const newSubPhases = { name: '', id, default_routines: [] , isIncluded:true , is_metrc_compliance:false , 
     // priority: values?.subphases.length,

     priority: index + 1, // Set priority for the new subphase
    };

    // Copy existing subphases
  let updatedSubPhases = [...values?.subphases ];

  // Insert the new subphase directly after the current row (index + 1)
  updatedSubPhases.splice(index + 1, 0, newSubPhases);
 // Update the priorities of all subsequent subphases
 updatedSubPhases = updatedSubPhases.map((subphase, i) => ({
  ...subphase,
  priority: i// Update priority based on the new order
}));
    handleSetValues('subphases', updatedSubPhases);

  },[values?.subphases, handleSetValues]);

  const phasePlanAddSubPhaseIconProps = {
    className: `${phasePlanClasses?.addSubPhaseIcon} ${currDarkThemeClass}`,
  };
  const phasePlanAddSubPhaseBtnProps =  {
    children: 'Add Sub Phase',
    className: `${phasePlanClasses?.addSubPhaseBtn} ${currDarkThemeClass}`,
    disabled: values?.loading?.processing || values?.loading?.fetching,
    onClick: handleAddSubPhaseEntry(values, handleSetValues,pos),
    startIcon: <AddFarmIcon {...phasePlanAddSubPhaseIconProps} />,
  };

  const handleSetSubPhase = useCallback(
    (prop, check = false) =>
      ({ target: { value, checked, valueAsNumber } }) => {
        if ((prop === 'start_day' || prop === 'end_day' || prop === 'total_day') && valueAsNumber < 1) return;
        let updatedPhases = [...values?.subphases];
        const currentIndex = pos; // Current subphase index

        // Update the current subphase
        updatedPhases[currentIndex] = {
          ...updatedPhases[currentIndex],
          [prop]: check ? checked : value,
        };
      // If start day is modified, recalculate the end day based on total days
      if (prop === 'start_day') {
        const newStartDay = Number(value);
        const totalDays = Number(updatedPhases[currentIndex]?.total_day) || 1;
        const newEndDay = newStartDay + totalDays - 1;

        // Update the current subphase end day
        updatedPhases[currentIndex].end_day = newEndDay;
         // Adjust the previous subphase's end day to be one day before the new start day
         if (currentIndex > 0) {
          updatedPhases[currentIndex - 1].end_day = newStartDay ;
        }

          // Adjust the subsequent subphases
          let previousEndDay = newEndDay;
          for (let i = currentIndex + 1; i < updatedPhases.length; i++) {
            const nextStartDay = previousEndDay ;
            const totalDaysNext = updatedPhases[i].total_day || 1;
            const nextEndDay = nextStartDay + totalDaysNext - 1;
  
            updatedPhases[i] = {
              ...updatedPhases[i],
              start_day: nextStartDay,
              end_day: nextEndDay,
            };
           // Update previousEndDay for the next iteration
           previousEndDay = nextEndDay;
          }
        }
          // If total days is modified, recalculate the end day
      if (prop === 'total_day') {
        const newTotalDays = Number(value);
        const startDay = Number(updatedPhases[currentIndex]?.start_day) || 1;
        const newEndDay = startDay + Number(newTotalDays) - 1;
        updatedPhases[currentIndex].end_day = newEndDay;

        // Shift the subsequent subphases
        let previousEndDay = newEndDay;
        for (let i = currentIndex + 1; i < updatedPhases.length; i++) {
          const nextStartDay = previousEndDay ;
          const totalDaysNext = updatedPhases[i].total_day || 1;
          const nextEndDay = nextStartDay + totalDaysNext - 1;

          updatedPhases[i] = {
            ...updatedPhases[i],
            start_day: nextStartDay,
            end_day: nextEndDay,
          };
          previousEndDay = nextEndDay;
        }
      }
      // If end day is modified, recalculate the total days
      if (prop === 'end_day') {
        const newEndDay = Number(value); // Get new end day as a number
        const startDay = Number(updatedPhases[currentIndex]?.start_day) || 1; // Use existing start day
        const newTotalDays = newEndDay - startDay + 1; // Calculate new total days
        updatedPhases[currentIndex].total_day = newTotalDays; // Update total days

        // Adjust the subsequent subphases
        let previousEndDay = newEndDay;
        for (let i = currentIndex + 1; i < updatedPhases.length; i++) {
          const nextStartDay = previousEndDay ;
          const totalDaysNext = updatedPhases[i].total_day || 1;
          const nextEndDay = nextStartDay + totalDaysNext - 1;

          updatedPhases[i] = {
            ...updatedPhases[i],
            start_day: nextStartDay,
            end_day: nextEndDay,
          };
          previousEndDay = nextEndDay;
        }
      }
        // Set updated values
        handleSetValues('subphases', updatedPhases);
      },
    [values?.subphases, subphase?.id, pos, handleSetValues]
  );

  const handleDeleteSubPhase = useCallback(() => {
    let updatedPhases = values?.subphases.filter((_, index) => index !== pos);
     // Reassign priorities sequentially to the remaining subphases
     updatedPhases = updatedPhases.map((subphase, index) => ({
      ...subphase,
      priority: index // Set the priority in sequential order
  }));
    handleSetValues('subphases', updatedPhases);
  }, [values?.subphases, pos, handleSetValues]);

  const startDayProps = {
    autoFocus: pos === 0,
    inputProps: {
      min: 1,
    },
    label: 'Start Day',
    otherSxProps: {
      flexShrink: 1,
      ml: '16px',
    },
    prop: 'start_day',
    required: subphase?.isIncluded ?? true,
    type: 'number',
  };

  const endDayProps = {
    inputProps: {
      min: 1,
    },
    label: 'End Day',
    otherSxProps: {
      flexShrink: 1,
      ml: '16px',
    },
    prop: 'end_day',
    required: subphase?.isIncluded ?? true,
    type: 'number',
  };
  const getTotalDaysProps = (subphase) => ({
    disabled: false, // Total days is calculated, so make it editable
    label: 'Total Days',
    otherSxProps: {
      flexShrink: 1,
      ml: '16px',
    },
    prop: 'total_day',
    required: subphase?.isIncluded ?? true,
    type: 'number', // Display total_days as a number
    value: (+subphase?.end_day - +subphase?.start_day + 1)|| '', // Set the calculated total_days value
  });
  
  const nameProps = {
    disabled :  subphase?.is_metrc_compliance ? true : false ,
    // disabled: subphase?.is_metrc_compliance ? true : (typeof subphase?.id === 'string' ? false : true),
    label: 'Name',
    inputProps: {
      maxLength: 100,
    },
    otherSxProps: {
      flexShrink: 1,
      ml: '16px',
    },
    prop: 'name',
    required: subphase?.is_metrc_compliance ?? true,
  };

// Extract all routines
const allRoutines = values?.default_routines ?? [];

// Separate compiled and non-compiled routines
const compiledRoutines = allRoutines.filter(e => e?.is_metrc_compliance === true);
const nonCompiledRoutines = allRoutines.filter(e => e?.is_metrc_compliance === false);


const handleRoutineChange = (event) => {
  const selectedId = event.target.value;
  setSelectedRoutines((prevSelected) =>
    prevSelected.includes(selectedId)
      ? prevSelected.filter((id) => id !== selectedId)
      : [...prevSelected, selectedId] 
  );
  handleSetSubPhase('default_routines')({ target: { value: selectedRoutines } });
};

const renderRoutinesList = (routineList) =>
  routineList.map((e, i) => (
    <MenuItem 
      key={`routine-${i}`} 
      value={e?.id} 
      onClick={() => handleRoutineChange({ target: { value: e?.id, checked: !selectedRoutines.includes(e?.id) } })}
    >
      <Checkbox
        value={e?.id}
        checked={selectedRoutines.includes(e?.id)}
        onChange={() => {}} // Prevents default blocking issue
      />
      <ListItemText primary={e?.name} />
    </MenuItem>
  ));

// Create dropdown lists
const compiledRoutinesList = renderRoutinesList(compiledRoutines);
const nonCompiledRoutinesList = renderRoutinesList(nonCompiledRoutines);

// Handle empty dropdown cases
if (!compiledRoutinesList.length) {
  compiledRoutinesList.push(
    <MenuItem key={-1} value={-1} disabled>
      No Available Compiled Routines
    </MenuItem>
  );
}

if (!nonCompiledRoutinesList.length) {
  nonCompiledRoutinesList.push(
    <MenuItem key={-2} value={-2} disabled>
      No Available Non-Compiled Routines
    </MenuItem>
  );
}

//  Dropdown Props (compiled & non-compiled)
const compliedRoutinesProps = {
  disabled: false,
  label: 'Complied Routines',
  select: true,
  SelectProps: {
    renderValue: selected =>
      allRoutines
        .filter(e => selected.includes(e?.id) && e?.is_metrc_compliance === true)
        .map(e => e?.name)
        .join(', ') || 'Complied Routines',
    multiple: true,
  },
  otherSxProps: { flexShrink: 1, ml: '16px' },
  required: false,
  value: selectedRoutines, // Keep all selected routines (both types)
  prop: 'default_routines',
  onChange: handleSetSubPhase('default_routines'),
};

const nonCompliedRoutinesProps = {
  disabled: false,
  label: 'Non Complied Routines',
  select: true,
  SelectProps: {
    renderValue: selected =>
      allRoutines
        .filter(e => selected.includes(e?.id) && e?.is_metrc_compliance === false)
        .map(e => e?.name)
        .join(', ') || 'Non Complied Routines',
    multiple: true,
  },
  otherSxProps: { flexShrink: 1, ml: '16px' },
  required: false,
  value: selectedRoutines, // Keep all selected routines (both types)
  prop: 'default_routines',
  onChange: handleSetSubPhase('default_routines'),
};


  // const includeSubPhaseTitle = `${subphase?.name ?? 'This'} Sub Phase is ${subphase?.isIncluded ? 'included' : 'not included'}`;
  const includeSubPhaseMsg = !subphase?.isIncluded ? 'Enable checkbox to add values' : '';

  // const subPhaseEntryCheckboxProps = {
  //   checked: subphase?.isIncluded ?? true,
  //   className: `${compClasses?.subPhaseEntry?.checkbox} ${currDarkThemeClass}`,
  //   disabled: values?.loading?.processing,
  //   onChange: handleSetSubPhase('isIncluded', true),
  // };

  return (
    <Box {...subPhaseEntryBoxProps}>
      <Box sx={{ display: 'flex', alignItems: 'center', ml: '40px' }}>
        {/* Conditionally render MetrcLogo based on is_metrc_compliance */}
        {subphase.is_metrc_compliance && (
          <MetrcLogo sx={{ ml: -2}} /> 
        )}
      </Box>
      <Tooltip title={includeSubPhaseMsg}>
        <TextField {...subPhaseEntryTextFieldProps(nameProps)}  onChange={handleSetSubPhase('name')}  sx={{ width: '20%' }} required
    error={!nameProps?.value}  // Checks if the field is empty
    />
      </Tooltip>
      <Tooltip title={includeSubPhaseMsg}>
        <TextField {...subPhaseEntryTextFieldProps(startDayProps)} sx={{ width: '10%' }} />
      </Tooltip>
      <Tooltip title={includeSubPhaseMsg}>
        <TextField {...subPhaseEntryTextFieldProps(endDayProps)} sx={{ width: '10%' }} />
      </Tooltip>
      <Tooltip title={includeSubPhaseMsg} >
        <TextField {...subPhaseEntryTextFieldProps(getTotalDaysProps(subphase))} sx={{ width: '10%' }}/>
      </Tooltip>
      <Tooltip title={includeSubPhaseMsg}>
        <TextField {...subPhaseEntryTextFieldProps(compliedRoutinesProps)} sx={{ width: '15%' }}>
          {compiledRoutinesList}
        </TextField>
      </Tooltip>
      <Tooltip title={includeSubPhaseMsg}>
        <TextField {...subPhaseEntryTextFieldProps(nonCompliedRoutinesProps)} sx={{ width: '15%' }}>
          {nonCompiledRoutinesList}
        </TextField>
      </Tooltip>
      <Tooltip title="Add Sub Phase Below">
        <Box sx={{ display: 'flex', alignItems: 'center', ml: '10px' }}>
        {<PlusIcon sx={{ ml: -1}} {...phasePlanAddSubPhaseBtnProps}/>}
        </Box>
      </Tooltip>
      <Tooltip title="Delete Sub Phase">
        <Box sx={{ display: 'flex', alignItems: 'center', ml: '10px' }}>
        {!subphase.is_metrc_compliance&& <DelIcon onClick={handleDeleteSubPhase} sx={{ mr: 1}}/>}
        </Box>
      </Tooltip>
    </Box>
  );
}
