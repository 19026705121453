import { Fragment } from 'react';

// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { EditIcon } from './CustomSvgIcons';
import { cardClasses } from './constants';

export default function DetailInfoCard(props) {
    const {
        cardTitleMarginBottom='0px',
        currDarkThemeClass='',
        editLink=() => console.log('Make an edit'),
        entries=[],
        entryMarginValue='16px',
        otherCardPaperSxProps={},
        otherEntriesBoxSxProps={},
        title='Unknown',
        NoEntityMessage='',
        toShowEditBtn=true,
    } = props;
    
    const dICardPaperProps = {
        // className: 'customScroll', // 28-03-2023: Should not have this class
        className: `${cardClasses?.detailInfo?.paper} ${currDarkThemeClass}`,
        square: true,
        sx: {
            // px: '28px',
            // py: '25px',
            // width: '100%',
            ...otherCardPaperSxProps,
        },
        variant: 'none',
    };

    const dICardTitlePaperProps = {
        className: `${cardClasses?.detailInfo?.titlePaper} ${currDarkThemeClass}`,
        square: true,
        sx: {
            // display: 'flex',
            // justifyContent: 'space-between',
            mb: cardTitleMarginBottom,
        },
        variant: 'none',
    };

    const dICardTitleTypoProps = {
        className: `${cardClasses?.detailInfo?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '25px',
        //     fontWeight: 700,
        //     lineHeight: 1.16,
        // },
    };

    const dIEditIconProps = {
        className: `${cardClasses?.detailInfo?.editIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '24px', 
        //     width: '24px'
        // },
    };

    const dICardEditBtnProps = {
        className: `${cardClasses?.detailInfo?.editButton} ${currDarkThemeClass}`,
        disableRipple: true,
        onClick: editLink,
        startIcon: <EditIcon {...dIEditIconProps} />,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     flexShrink: 0,
        //     lineHeight: 1.56,
        //     p: 0,
        //     textTransform: 'none',
        // },
    };

    const dICardEntryTypoProps = {
        className: `${cardClasses?.detailInfo?.entryTypo} ${currDarkThemeClass}`,
        sx: {
            // color: 'grey.700',
            // fontSize: '16px',
            // fontWeight: 500,
            // lineHeight: 1.56,
            mt: entryMarginValue,
        },
    };

    const dIEntriesBoxProps = {
        // className: 'customScroll',
        className: `${cardClasses?.detailInfo?.entriesBox} ${currDarkThemeClass} customScroll`,
        sx: {
            ...otherEntriesBoxSxProps,
        },
    };

    const entriesList = entries.map((e, i) => 
        <Fragment key={i}>
            { e.key && <Typography {...dICardEntryTypoProps}>{e.key}</Typography> }
            { e.value }
        </Fragment>
    )

    return (
        <Paper {...dICardPaperProps}>
            <Paper {...dICardTitlePaperProps}>
                <Typography {...dICardTitleTypoProps}>{title}</Typography>
                {/* <Button {...dICardEditBtnProps}>Edit Information</Button> */}
                {
                    toShowEditBtn && <Button {...dICardEditBtnProps}>Edit</Button>
                }
            </Paper>
            <Box {...dIEntriesBoxProps}>
                {entriesList}
            </Box>
        </Paper>
    );
}