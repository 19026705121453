
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { tableClasses } from '../constants';
import { Paper } from '@mui/material';

export default function SensorTable(props) {
    const { currDarkThemeClass, animationClass, tableTitles = [], tableEntries = [] } = props;

    const displayTableContainerProps = {
        className: `${tableClasses?.displayTable?.tableContainer} ${currDarkThemeClass} ${animationClass}`,
        component: Paper,
        variant: 'none',
    };

    const displayTableProps = {
        className: `${tableClasses?.displayTable?.table} ${currDarkThemeClass}`,
        stickyHeader: true,
    };

    const displayTableTitleCellProps = {
        className: `${tableClasses?.displayTable?.titleCell} ${currDarkThemeClass}`,
    };

    const displayTableRowProps = {
        className: `${tableClasses?.displayTable?.tableRow} ${currDarkThemeClass}`,
    };

    const displayTableEntryCellProps = {
        className: `${tableClasses?.displayTable?.entryCell} ${currDarkThemeClass}`,
    };

    const noEntityProps = {
        className: `${tableClasses?.displayTable?.noEntityTableCell} ${currDarkThemeClass}`,
        colSpan: '100%',
    };

    return (
        <TableContainer {...displayTableContainerProps} sx={{ maxHeight: 380 }}>
            <Table {...displayTableProps}>
                <TableHead>
                    <TableRow >
                        {tableTitles.map((title, i) => (
                            <TableCell
                                key={title?.key}
                                align={title.align ?? 'left'}
                                style={{ minWidth: title.minWidth }}
                                {...displayTableTitleCellProps}
                            >
                                {title.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableEntries.length === 0 ? (
                        <TableRow tabIndex={-1}>
                            <TableCell {...noEntityProps}>No Sensor Added</TableCell>
                        </TableRow>
                    ) : (
                        tableEntries.map((entry, i) => (
                            <TableRow tabIndex={-1} key={`row-${i}`} {...displayTableRowProps}>
                                {tableTitles.map((title) => {
                                    const value = entry[title.key];
                                    return (
                                        <TableCell key={title.key} align={title.align ?? 'left'} {...displayTableEntryCellProps}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}