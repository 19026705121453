import { Fade, Typography, Stack, Paper } from '@mui/material';
import React from 'react';
import { fadeTimingMs, BaseDetailCard, metricPlanCardClasses, getFormattedTableEntry, EyeIcon2, cropsPlanNewAndDetailRoute } from '../../utility';
import NoEntityMsg from '../../utility/messages/NoEntityMsg';
import { useNavigate } from 'react-router';

export default function _CropPlanTab(props) {
  const { values, currDarkThemeClass, cropplanperms, handleErrorWithFeedback } = props;

  const navigate = useNavigate();

  const handleShow = id => () => {
    if (!cropplanperms?.update) {
      handleErrorWithFeedback({ response: { status: 400, data: { text: 'Do Not have permissions to View/update' } } });
      return;
    }
    navigate(`${cropsPlanNewAndDetailRoute}/${id}/`);
  };

  const plansBoxProps = {
    className: `${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`,
    variant: 'square',
    elevation: 0,
  };

  const cdSubHeaderProps = {
    direction: 'row',
    spacing: 2,
    alignItems: 'center',
  };

  const cdSubHeaderTypo = {
    className: `${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`,
    variant: 'subtitle2',
  };

  const subHeader = cropplan => {
    const entryTitleValueMap = [
      //   { title: 'Room', key: 'room_name' },
      // { title: 'Date Of Serialization', key: 'date_of_serialization', isDate: true },
      //   { title: 'Strain Count', key: 'strain_count' },
      // { title: 'Plant Count', key: 'plant_count' },
    ];

    const entries = entryTitleValueMap.map((e, i) => (
      <Typography key={i} {...cdSubHeaderTypo}>
        {e?.title} : {getFormattedTableEntry(cropplan, e)}
      </Typography>
    ));

    return <Stack {...cdSubHeaderProps}>{entries}</Stack>;
  };

  const cardActionBtns = cropplan => [
    {
      key: 'details',
      title: 'Show Plan',
      icon: EyeIcon2,
      otherProps: {
        onClick: handleShow(cropplan?.id),
      },
    },
    // {
    //   key: 'delete',
    //   title: 'Delete Run',
    //   icon: DelIcon2,
    //   otherClasses: metricPlanCardClasses.delBtn,
    //   otherProps: {
    //       onClick: handleDelete(cropplan?.id)
    //   }
    // },
  ];

  const cropPlanCardProps = cropplan => ({
    baseEntity: cropplan?.name,
    noEntityMsg: 'Unknown Cropplan',
    currDarkThemeClass,
    subHeader: subHeader(cropplan),
    cardActionBtns: cardActionBtns(cropplan),
  });

  const plansCardList =
    values?.cropplans?.length > 0 ? (
      values?.cropplans?.map((e, i) => <BaseDetailCard key={i} {...cropPlanCardProps(e)} />)
    ) : (
      <NoEntityMsg msg="No Crop Plans Available" />
    );

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Paper {...plansBoxProps}>{plansCardList}</Paper>
    </Fade>
  );
}
