import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";

import { DownArrowIcon } from "../CustomSvgIcons";

import { fabsClasses } from "../constants";

// NOTE: This component must always be at the topmost level sibling in parent component for it to be positioned correctly
//       For example: <> <Box>{children}</Box> <PrevNextFab /> </>


export default function PrevNextFab(props) {
    
    const {
        currDarkThemeClass='',
        disableNext=false,
        disableNextTooltip=false,
        disablePrevTooltip=false,
        disablePrev=false,
        prevTitle='Modify previous step',
        nextTitle='Please complete previous steps',
        showNext=true,
        showPrev=true,

        handlePrevStep=()=>{},
        handleNextStep=()=>{},
    } = props;

    const fabProps = {
        className: `${fabsClasses?.prevNext?.fab} ${currDarkThemeClass}`,
        size: 'small',
        variant: (!showPrev || !showNext) ? 'circular' : 'extended',
        variant: 'extended',
    };

    const prevStepTooltipProps = {
        title: disablePrevTooltip ? '' : prevTitle,
    };

    const prevStepBoxProps = {
        className: `${fabsClasses?.prevNext?.prevStepBox} ${currDarkThemeClass} ${!showNext ? fabsClasses?.prevNext?.singleStep : ''}`,
        // className: `${fabsClasses?.prevNext?.prevStepBox} ${currDarkThemeClass}`,
    };

    const prevStepProps = {
        className: `${fabsClasses?.prevNext?.prevStep} ${currDarkThemeClass} ${!showNext ? fabsClasses?.prevNext?.singleStep : ''}`,
        // className: `${fabsClasses?.prevNext?.prevStep} ${currDarkThemeClass} ${disableNext ? fabsClasses?.prevNext?.disabledStep : ''}`,
        onClick: !disablePrev ? handlePrevStep : undefined,
    };

    const nextStepTooltipProps = {
        // title: disableNext ? nextTitle : '',
        title: disableNextTooltip ? '' : nextTitle,
    };

    const nextStepBoxProps = {
        className: `${fabsClasses?.prevNext?.nextStepBox} ${currDarkThemeClass} ${!showPrev ? fabsClasses?.prevNext?.singleStep : ''}`,
        // className: `${fabsClasses?.prevNext?.nextStepBox} ${currDarkThemeClass}`,
    };

    const nextStepProps = {
        className: `${fabsClasses?.prevNext?.nextStep} ${currDarkThemeClass} ${disableNext ? fabsClasses?.prevNext?.disabledStep : ''} ${!showPrev ? fabsClasses?.prevNext?.singleStep : ''}`,
        // className: `${fabsClasses?.prevNext?.nextStep} ${currDarkThemeClass} ${disableNext ? fabsClasses?.prevNext?.disabledStep : ''}`,
        onClick: !disableNext ? handleNextStep : undefined,
    };


    return (
        <Fab {...fabProps}>
            { showPrev && 
                <Tooltip {...prevStepTooltipProps}>
                    <Box {...prevStepBoxProps}>
                        <DownArrowIcon {...prevStepProps} />
                    </Box>
                </Tooltip>  
            }
            { showNext && 
                <Tooltip {...nextStepTooltipProps}>
                    <Box {...nextStepBoxProps}>
                        <DownArrowIcon {...nextStepProps} />
                    </Box>
                </Tooltip>  
            }

            {/* <Tooltip {...prevStepTooltipProps}>
                <Box {...prevStepBoxProps}>
                    <DownArrowIcon {...prevStepProps} />
                </Box>
            </Tooltip>  
            <Tooltip {...nextStepTooltipProps}>
                <Box {...nextStepBoxProps}>
                    <DownArrowIcon {...nextStepProps} />
                </Box>
            </Tooltip>   */}

        </Fab>       
    );
}