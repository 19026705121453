import { useMemo } from "react";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { PlansIcon } from "./CustomSvgIcons";
import { cardClasses, PLAN_CARD_CHAR_COUNT, PLAN_CARD_TITLE_COUNT } from "./constants";

const defDesc = "Lörem ipsum pang förlåtande. Prelingar sohiv huruvida denat. Nyfodade biovis biogam benade spengen. Hyponera fogt lugålogi gåledes. Kore prell sijisk.";


export default function PlanCard(props) {
    
    const {
        otherBoxClass='',
        otherDescClass='',
        currDarkThemeClass='',
        description=defDesc,
        icon,
        onClick=() => {},
        sx={},
        title='Card Title',
        hasExtraDetails=false,
        extraDetails,
        label,
    } = props;

    const theme = useTheme();

    const pCBoxProps = {
        className: `${cardClasses?.plan?.box} ${currDarkThemeClass} ${otherBoxClass}`,
        onClick,
        sx,
        // sx: {
        //     backgroundColor: 'primary.200',
        //     borderBottom: '1px solid',
        //     borderColor: 'primary.800',
        //     height: '350px',
        //     px: '28px',
        //     py: '36px',
        //     // width: '396px',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        //     ...sx,
        // },
    };

    const pCHeaderBoxProps = {
        className: `${cardClasses?.plan?.headerBox} ${currDarkThemeClass}`,
    };

    const pCLabelProps = {
        className: `${cardClasses?.plan?.labelTypo} ${currDarkThemeClass}`,
    };


    const pCIconProps = {
        className: `${cardClasses?.plan?.icon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '48px',
        //     width: '48px',
        //     '& path': {
        //         fill: theme.palette.primary['800'],
        //     },
        // },
    };

    const pCTitleProps = {
        className: `${cardClasses?.plan?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '25px',
        //     fontWeight: 500,
        //     lineHeight: 1.16,
        //     mt: '32px',
        // },
    };

    const titleValue = useMemo(() => title?.length > PLAN_CARD_TITLE_COUNT ? `${title?.substring(0, PLAN_CARD_TITLE_COUNT)}...` : title, [title]);

    const titleTooltipProps = {
        title: title ?? '',
    };

    const pCDescProps = {
        className: `${cardClasses?.plan?.descTypo} ${currDarkThemeClass} ${otherDescClass} customScroll`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     mt: '24px',
        //     overflow: 'auto',
        // },
    };

    const descriptionValue = useMemo(() => description?.length > PLAN_CARD_CHAR_COUNT ? `${description?.substring(0, PLAN_CARD_CHAR_COUNT)}...` : description, [description]);

    const descTooltipProps = {
        title: description,
    };

    return (
        <Box {...pCBoxProps}>
            {/* <PlansIcon {...pCIconProps} /> */}
            <Box {...pCHeaderBoxProps}>
                { icon ? icon(pCIconProps) : <PlansIcon {...pCIconProps} /> }
                { label ? <Typography {...pCLabelProps}>{label}</Typography> : <></> }
            </Box>
            <Tooltip {...titleTooltipProps}><Typography {...pCTitleProps}>{titleValue}</Typography></Tooltip>
            <Tooltip {...descTooltipProps}><Typography {...pCDescProps}>{descriptionValue}</Typography></Tooltip>
            { hasExtraDetails && extraDetails }
        </Box>
    );
}