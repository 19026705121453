import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react'
import {
    BaseNewAndDetail,
    RefreshIcon,
    fadeTimingMs,
    farmModelName,
    farmsClasses,
    getDarkThemeClass,
    metrcPlanModelName,
    phasePlanModelName,
    qualityPlanModelName,
    roomModelName,
    sensorModelName,
    strainModelName,
    metrcRunModelName,
    farmsCollectionEndpoint,
    defErrProps,
    errFeedbackFn,
    SelDropdownIcon,
    miscClasses,
    noPermTablePrefixMsg,
    phasePlanNewAndDetailRoute,
    getMetricPlanRoute,
    NEW,
    qualityPlanNewAndDetailRoute,
    farmsWizardRoute,
    defaultRoutineModelName,
    routineNewAndDetailRoute,
    configurationDefaultRoutineNewAndDetailRoute,
} from '../utility'
import { useTheme } from '@emotion/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Fade, Paper, Typography, Select, Button, MenuItem } from '@mui/material';
import useToken from '../../utility/useToken';
import { hasPermissions } from '../../utility/permissions';
import { FeedbackContext, MetrcKeyContext } from '../../App';
import { failsTokenAndSetupCheck } from '../../checks/setup';
import APIService from '../../api/apiService';
import { _StrainsTab, _FarmsTab, _MetricplansTab, _PhaseTab, _QualityPlansTab, _RoutinesTab } from './Tabs';
// constants
const baseEntityName = 'Configurations';
const baseEndpointCheck = false;

const farmsKey = 'farms';
const qualityplansKey = 'quality-plans';
const metricsKey = 'metrics';
const strainsKey = 'strains';
const phasesKey = 'phases';
const routinesKey = 'routines';

const tabEntityMap = [
    { key: farmsKey, label: 'My Farms', defaultForDetail: true, validForNew: false },
    { key: qualityplansKey, label: 'My Quality Plans' },
    { key: metricsKey, label: 'My Metric Plans' },
    { key: strainsKey, label: 'My Strains' },
    { key: phasesKey, label: 'My Phases' },
    { key: routinesKey, label: 'My Routines'}
];

const tabCreateTitleMap = {
    [metricsKey]: 'Metric Plan',
    [qualityplansKey]: 'Quality Plan',
    [strainsKey]: 'Strains',
    [phasesKey]: 'New Phase Plan',
    [routinesKey]: 'Routine'
};

// functions
const showSaveBtn = (tab) => Object.keys(tabCreateTitleMap).includes(tab);

const handleConfigurationsRes = (setValues) => (res) => {
    const { data = [] } = res;
    let stateValues = {}
    for (let [key, value] of Object.entries(data)) {
        stateValues = { ...stateValues, [key]: value?.results ?? value }
    }

    setValues(state => ({ ...state, ...stateValues, farmsWizard: stateValues?.rooms?.length === 0 }));
}

const handleErrorWithFeedback = feedbackCX => (handleSetValues, errPropsFn) => err => {
    console.log('error occureed');
    handleSetValues('error', true);
    handleSetValues('loading', true);
    const errProps = errPropsFn?.(err) ?? defErrProps;

    feedbackCX?.setContextValue?.(true, errProps);
}

// const farmsTableListPaperProps = {
//     className: `${farmsClasses?.tableListPaper} ${currDarkThemeClass} farmsTabContent`,
//     square: true,
//     variant: 'none',
// };

export default function Configurations(props) {

    const [values, setValues] = useState({});

    const { metrcKey, setMetrcKey } = useContext(MetrcKeyContext);

    const navigate = useNavigate();

    const feedbackCX = useContext(FeedbackContext);

    const theme = useTheme();

    const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    const { token, tokenDetails, permissions } = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    const baseSaveBtnChildren = `Add ${tabCreateTitleMap?.[tab] ?? ''}`;

    // disable function to disable save buttons for different tabs.
    const toDisable = () => {
        // different toDisable conditions based on tab
        switch (tab) {
            case metricsKey:
                return !permissionsList?.[metrcPlanModelName]?.create;

            case phasesKey:
                return !permissionsList?.[phasePlanModelName]?.create;
            
            case qualityplansKey:
                return !permissionsList?.[qualityPlanModelName]?.create;
            
            case strainsKey:
                return !permissionsList?.[strainModelName]?.create;

            case routinesKey:
                return !permissionsList?.[defaultRoutineModelName]?.create;

            default:
                return true;
        }
    }

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    const handleSetLoading = prop => value => handleSetValues('loading', { ...values?.loading, [prop]: value });

    const handleEntityEntryCreateRes = prop => res => {
        handleSetLoading(prop)(false);

        const { data } = res;

        // handleSetValues(prop, [...values?.[prop], data]);
        // handleSetSavedRes(prop, [...savedRes?.[prop], data]);
        const newState = data?.length > 0 ? [...values?.[prop], ...data] : [...values?.[prop], data];

        handleSetValues(prop, newState);

        return Promise.resolve({ success: true });
    }

    const handleEntityEntryModifyRes = prop => res => {
        const { data } = res;

        handleSetValues(prop, values?.[prop]?.map(e => e.id === data.id ? data : e))

        return Promise.resolve({ success: true });
    };

    const permissionsList = useMemo(() =>
        hasPermissions(
            [
                farmModelName,
                roomModelName,
                sensorModelName,
                strainModelName,
                metrcRunModelName,
                metrcPlanModelName,
                phasePlanModelName,
                qualityPlanModelName,
                defaultRoutineModelName

            ], {...tokenDetails, permissions}), [token])

    useEffect(() => {
        if (invalidTokenState) return;

        handleSetValues('loading', true);

        const errConfig = {};
        const init = async () => {
            try {
                const { metrc_id } = tokenDetails;

                if (!metrcKey) {
                    setMetrcKey(metrc_id);
                }

                handleSetValues('metrc_id', metrc_id);

                let entityWithID = farmsCollectionEndpoint
                let response = await APIService.fetchInstance(entityWithID)
                handleConfigurationsRes(setValues)(response)
            } catch (error) {
                handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn(errConfig))(error)
            }
        }
        init();

        handleSetValues('loading', false)

    }, []);

    useEffect(() => {
        if (values?.farmsWizard) {
            navigate(farmsWizardRoute);
        }
    }, [values?.farmsWizard]);

    const getDefaultTabForEndpoint = () => tabEntityMap?.find(tab => tab?.defaultForDetail)?.key;

    const farmsProps = {
        currDarkThemeClass,
        organization: values?.organization,
        metrc: values?.metrc,
        farmPerms: permissionsList?.[farmModelName] ?? [],
        farms: values?.farms,
        metrc_id: values?.metrc_id,
        loading: values?.loading,
        metrcKey,
        handleSetValues,
        setMetrcKey,
        feedbackCX,
        handleConfigurationsRes,
        handleErrorWithFeedback
    }

    const strainsProps = {
        currDarkThemeClass,
        strainPerms: permissionsList?.[strainModelName] ?? [],
        strains: values?.strains,
        farms: values?.farms,
        openStrainsPopup: values?.openStrainsPopup ?? false,
        addNew: values?.add ?? false,
        update: values?.update ?? false,
        handleErrorWithFeedback,
        handleEntityEntryCreateRes,
        handleEntityEntryModifyRes,
        handleSetValues

    }

    const metrcPlanProps = {
        currDarkThemeClass,
        metrcPlanPerms: permissionsList?.[metrcPlanModelName],
        metrcplans: values?.metrcplans ?? [],
        handleSetValues,
        handleErrorWithFeedback: handleErrorWithFeedback(feedbackCX)(handleSetValues, errFeedbackFn({}))
    }

    const phaseTabProps = {
        currDarkThemeClass,
        phaseplans: values?.phaseplans ?? [],
        phasePlanPerms: permissionsList?.phaseplan
    }

    const qualityPlanTabProps = {
        currDarkThemeClass,
        qualityPlanPerms: permissionsList?.[qualityPlanModelName],
        qualityplans: values?.qualityplans ?? [],
        handleSetValues,
        handleErrorWithFeedback: handleErrorWithFeedback(feedbackCX)(handleSetValues, errFeedbackFn({}))
    }

    const routinesTabProps = {
      currDarkThemeClass,
      defaultRoutinesPerms: permissionsList?.[defaultRoutineModelName],
      parentValues: values,
      handleErrorWithFeedback: handleErrorWithFeedback(feedbackCX)(handleSetValues, errFeedbackFn({})),
      onRoutineDelete: (deletedId) => {
          const updatedRoutines = values.defaultroutines.filter(routine => routine.id !== deletedId);
          handleSetValues('defaultroutines', updatedRoutines);
        }
    };

    const tabContentMap = {
        [farmsKey]: <_FarmsTab {...farmsProps} />,
        [strainsKey]: <_StrainsTab {...strainsProps} />,
        [metricsKey]: <_MetricplansTab {...metrcPlanProps} />,
        [phasesKey]: <_PhaseTab {...phaseTabProps} />,
        [qualityplansKey]: <_QualityPlansTab {...qualityPlanTabProps} />,
        [routinesKey]: <_RoutinesTab {...routinesTabProps}/>
    };

    const handleSave = () => {
        if (tab === phasesKey) navigate(`${phasePlanNewAndDetailRoute}/new/`);
        else if (tab === metricsKey) navigate(getMetricPlanRoute(NEW));
        else if (tab === qualityplansKey) navigate(`${qualityPlanNewAndDetailRoute}/new/`);
        else if (tab === strainsKey) handleSetValues('openStrainsPopup', true); //
        else if (tab === routinesKey) navigate(`${routineNewAndDetailRoute}/new/`);
    }

    const baseProps = {
        baseEntityName,
        baseid: undefined,
        currDarkThemeClass,
        tabContentMap,
        tabEntityMap,
        createBaseEntityMsg: '',
        baseEndpointCheck,
        baseSaveBtnChildren,
        enablePopup: true,
        showSaveBtn,
        handleSave,
        toDisable,
        getDefaultTabForEndpoint
    }

    return (
        <BaseNewAndDetail {...baseProps} />
    )
}
