import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import BaseWizard from "./BaseWizard";

import useThemeVal from "../../../utility/useThemeVal";

import { 
    NEW,
    PLAN_WIZARD, setWizardDetails, PLAN_WIZARD_TYPE,
    phasePlanNewAndDetailRoute, qualityPlanNewAndDetailRoute, growPlanNewAndDetailRoute, plansHomeWithMetricPlanTabRoute, plansHomeWithTabRoute,
} from "../constants";
import useToken from "../../../utility/useToken";
import { failsTokenAndSetupCheck } from "../../../checks/setup";

// import APIService from "../../../api/apiService";


const wizardType = PLAN_WIZARD_TYPE;
const baseWizardStep = 0; // next step after already completed steps 

const todoMap = [
    {stepTitle: 'Add Phases', step: 0, link: `${phasePlanNewAndDetailRoute}/${NEW}`, action: PLAN_WIZARD.PHASEPLAN_CREATE},
    {stepTitle: 'Add Metric', step: 1, link: `${plansHomeWithMetricPlanTabRoute}`, action: PLAN_WIZARD.METRICPLAN_CREATE},
    {stepTitle: 'Add Quality Plans', step: 2, link: `${qualityPlanNewAndDetailRoute}/${NEW}`, action: PLAN_WIZARD.QUALITYPLAN_CREATE},
    {stepTitle: 'Add Grow Plans', step: 3, link: `${growPlanNewAndDetailRoute}/${NEW}`, action: PLAN_WIZARD.GROWPLAN_CREATE},
    // Disabled since Crop Plan is not part of the Plan stage
    // {stepTitle: 'Add Crop Plan', step: 4, link: `${cropsPlanNewAndDetailRoute}/${NEW}`, action: PLAN_WIZARD.CROPPLAN_CREATE},
];

const stepsList = todoMap?.map(e => e?.step);

const stepCount = todoMap.length;

// const handleActionBtnClick = handleSetValues => () => {
//     const {current} = getWizardDetails(wizardType);

//     // executeStep = current while executing , so current is the source

//     if (+current >= stepCount) {
//         handleSetValues('finishSetup', true);
//         return;
//     }

//     if (!current || isNaN(+current)) {
//         handleSetValues('executeStep', baseWizardStep);
//     } else if (!isNaN(+current)) {
//         handleSetValues('executeStep', +current);
//     }

// };


const handleExecute = (navigate) => executeStep => {

    if (stepsList.includes(executeStep)) {

        const {link, action} = todoMap?.find(e => e?.step === executeStep) || {};
        
        if (!link || (!action && action !== 0)) return;

        const res = {
            action,
            execute: true,
        };
        
        setWizardDetails(wizardType, res);

        navigate(link);
    }

};

const infoTitle = 'Plan Setup Completion Progress';
const infoSubTitle = `Don't worry if its taking too long. Save your progress and Come back later to Continue from here`;


export default function PlansWizard(props) {
    
    const [values, setValues] = useState({});

    const currDarkThemeClass = useThemeVal();

    const navigate = useNavigate();

    const {token, tokenDetails} = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    // const {token, tokenDetails} = useToken(); // TBD: Logout -> Navigate away if token is invalid / expired

    // const {permissions=[], is_staff=false} = tokenDetails || '';

    // const roomPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${roomModelName}`)) ?? [], [token]);

    // const roomPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (roomPermissions?.includes(`${farmAppLabel}.${crudActions?.[action]}_${roomModelName}`) ?? false)]))

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    if (invalidTokenState) return <></>;

    const wizardProps = {
        baseWizardStep,
        currDarkThemeClass,
        // finishSetupRoute: cropsHomeWithTabRoute,
        finishSetupRoute: plansHomeWithTabRoute,
        infoTitle,
        infoSubTitle,
        permCheck: false,
        stepCount,
        todoMap,
        values,
        wizardType,

        handleExecuteStep: handleExecute(navigate),
        handleSetValues,
        navigate,
        // onActionBtnClick: handleActionBtnClick(handleSetValues),
    };


    return <BaseWizard {...wizardProps} />;
}