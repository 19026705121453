import { 
    Dialog,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from '@mui/material';
  import { Close } from '@mui/icons-material';
  import { LongArrowSvg} from '../../../utility/CustomSvgIcons';  


const _PlantHistoryPopup = ({ open, onClose, history, currDarkThemeClass,metrcTagNumber }) => {
    const formatDate = (dateString) => {
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).format(new Date(dateString));
    };
  
    const reversedHistory = [...history].reverse();
    const isDarkTheme = Boolean(currDarkThemeClass);
  
    const styles = {
      dialog: {
        '& .MuiPaper-root': {
          backgroundColor: isDarkTheme ? '#1a1a1a' : '#ffffff',
          color: isDarkTheme ? '#e0e0e0' : '#333333'
        }
      },
      tableContainer: {
        backgroundColor: isDarkTheme 
          ? 'rgba(158, 201, 107, 0.1)' 
          : 'rgba(158, 201, 107, 0.05)',
        border: 'none'
      },
      headerCell: {
        color: '#9ec96b',
        fontWeight: 500,
        fontSize: '0.9rem',
        borderBottom: isDarkTheme 
          ? '1px solid rgba(158, 201, 107, 0.2)'
          : '1px solid rgba(158, 201, 107, 0.3)',
        backgroundColor: isDarkTheme
          ? 'rgba(158, 201, 107, 0.05)'
          : 'rgba(158, 201, 107, 0.1)'
      },
      cell: {
        color: isDarkTheme ? '#e0e0e0' : '#333333',
        borderBottom: isDarkTheme
          ? '1px solid rgba(158, 201, 107, 0.1)'
          : '1px solid rgba(158, 201, 107, 0.2)',
        padding: '12px 16px'
      },
      closeButton: {
        color: '#9ec96b'
      },
      timelineContainer: {
        display: 'flex',
        alignItems: 'stretch',
        gap: '20px'
      },
      arrowColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '48px',
        paddingBottom: '16px',
        position: 'relative',
        width: '40px'
      },
      timeLabel: {
        color: '#9ec96b',
        fontSize: '0.75rem',
        position: 'absolute',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
      },
      dialogTitle: {
        padding: 0,
        marginLeft: 8,
        color: '#9ec96b',
        fontSize: '1.1rem'
      },
      tableRow: {
        '&:hover': {
          backgroundColor: isDarkTheme
            ? 'rgba(158, 201, 107, 0.05) !important'
            : 'rgba(158, 201, 107, 0.1) !important'
        }
      },
      mainContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
      }
    };
  
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        sx={styles.dialog}
      >
        <div style={{ padding: '16px 24px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
            <DialogTitle sx={styles.dialogTitle}>
              Growth Phase History  {metrcTagNumber && ` (${metrcTagNumber})`}
            </DialogTitle>
            <IconButton onClick={onClose} size="small" sx={styles.closeButton}>
              <Close />
            </IconButton>
          </div>
  
          <div style={styles.timelineContainer}>
            <div style={styles.arrowColumn}>
              <LongArrowSvg />
            </div>
  
            <div style={styles.mainContent}>
              <TableContainer component={Paper} elevation={0} sx={styles.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={styles.headerCell}>Status</TableCell>
                      <TableCell sx={styles.headerCell}>Performed Date</TableCell>
                      <TableCell sx={styles.headerCell}>Performed By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reversedHistory.map((item, index) => (
                      <TableRow
                        key={`history-${index}-${item.performed_on}`}
                        hover
                        sx={styles.tableRow}
                      >
                        <TableCell sx={styles.cell}>{item.status}</TableCell>
                        <TableCell sx={styles.cell}>{formatDate(item.performed_on)}</TableCell>
                        <TableCell sx={styles.cell}>{item.performed_by.split('.')[0]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };
  

  export default _PlantHistoryPopup;