import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import { useTheme } from "@mui/material";

import {  settingsClasses } from "../utility/constants";

// import APIService from "../../api/apiService";
// import getTokenDetails from "../../auth/getTokenDetails";
import useThemeVal from "../../utility/useThemeVal";
import useToken from "../../utility/useToken";
import { failsTokenAndSetupCheck } from "../../checks/setup";

const title = 'Settings';

const devMsg = 'This site is under development...';

export default function Settings(props) {
    // const theme = useTheme();

    // const [savedRes, setSavedRes] = useState({});
    // const [values, setValues] = useState({});

    const navigate = useNavigate();

    const currDarkThemeClass = useThemeVal();

    // const {token} = getTokenDetails();
    const {token, tokenDetails} = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    // DO NOT CALL navigate() in render path
    // if (!token) navigate(defaultNoAuthRoute);

    if (invalidTokenState) return <></>;

    const settingsBoxProps = {
        // className: 'main-content-wrapper customScroll',
        className: `${settingsClasses?.box} ${currDarkThemeClass} customScroll`,
        // sx: {
        //     backgroundColor: 'primary.100',
        //     flexGrow: 1,
        //     minHeight: '100vh',
        //     pl: '32px',
        //     pr: '32px',
        //     pt: '60px',
        // },
    };

    const settingsTitleTypoProps = {
        className: `${settingsClasses?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '36px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.08,
        //     mt: '12px',
        // },
    };

    const devTypoProps = {
        className: `${settingsClasses?.devTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.400',
        //     fontSize: '24px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     mt: '60px',
        //     // mx: 'auto',
        //     textAlign: 'center',
        // },
    };
    
    return (
        <Box {...settingsBoxProps}>
            <Typography {...settingsTitleTypoProps}>{title}</Typography>

            <Typography {...devTypoProps}>{devMsg}</Typography>
            
        </Box>
    );
}