import React from 'react';
import Highcharts from 'highcharts';
import HighchartsGantt from 'highcharts/modules/gantt';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@mui/material';

// Initialize Gantt Module
HighchartsGantt(Highcharts);

const DiamondChartComponent = ({ data , currDarkThemeClass}) => {
  const day = 1000 * 60 * 60 * 24;

  // Filter out crop runs with a valid serialization date
  const cropRuns = data.filter(crop => crop.date_of_serialization);
  const isDarkTheme = currDarkThemeClass === 'dark-theme';

  // Function to determine color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case "Planning":
      case "Ready for Grow":
        return "#FFD700"; // 🟡 Yellow
      case "Active":
        return "#808080"; // ⚪ Grey
      case "Completed":
        return "#FFA500"; // 🟠 Orange
      case "Destroyed":
      case "Archived":
        return "#FF0000"; // 🔴 Red
      default:
        return "#FFFFFF"; // ⚪ Default White
    }
  };

  // Calculate the earliest and latest serialization date
  const serializationDates = cropRuns.map(crop => new Date(crop.date_of_serialization).getTime());
  const earliestDate = Math.min(...serializationDates);
  const latestDate = Math.max(...serializationDates);

  // Add 1 month before the earliest and 1 month after the latest date
  const xAxisMin = new Date(earliestDate);
  xAxisMin.setMonth(xAxisMin.getMonth() - 1);

  const xAxisMax = new Date(latestDate);
  xAxisMax.setMonth(xAxisMax.getMonth() + 1);

  // Prepare data for Gantt Chart
  const seriesData = cropRuns.map(crop => {
    const serializationDate = new Date(crop.date_of_serialization).getTime();
    const startDay = crop.subphases?.length ? Math.min(...crop.subphases.map(sub => sub.start_day)) : 0;
    const endDay = crop.subphases?.length ? Math.max(...crop.subphases.map(sub => sub.end_day)) : 0;
    
    return {
      name: crop.name,
      id: `CropRun_${crop.id}`,
      start: serializationDate ,
      milestone: true, //  Keep diamond shape
      collapsed: true,
      run_status: crop.run_status,
      color: getStatusColor(crop.run_status), // Apply color
      marker: {
        symbol: 'diamond', // Force diamond shape
        radius: 8, // Adjust size for better visibility
        fillColor: getStatusColor(crop.run_status), // Ensures color is applied
      },
      
    };
  });
  // Highcharts.setOptions({
  //   chart: { backgroundColor: "transparent" },
  //   // title: { style: { color: isDarkTheme ? "#D9DBD3" : "#000000", fontFamily: "'Space Grotesk', sans-serif", fontWeight: 'normal' } },
  //   xAxis: { labels: { style: { color: isDarkTheme ? "#D9DBD3" : "#000000", fontFamily: "'Space Grotesk', sans-serif", fontWeight: 'normal' } } },
  //   yAxis: { labels: { style: { color: isDarkTheme ? "#D9DBD3" : "#000000", fontFamily: "'Space Grotesk', sans-serif", fontWeight: 'normal' } } },
  // });
  
  
  const options = {
    chart: { type: 'gantt', backgroundColor: 'transparent',styledMode: true, },
    title: { text: 'Crop Runs Diamond View', 
      style: {
      // color: '#FFFFFF' , // Title color based on theme
      color: isDarkTheme ? '#D9DBD3' : '#000000',
      fontFamily: 'Space Grotesk, sans-serif', // Apply font
    }, 
  },
    xAxis: { 
      type: 'datetime', 
      labels: { 
        style: {
          // color: '#FFFFFF' , // Title color based on theme
          color: isDarkTheme ? '#D9DBD3' : '#000000',
          fontFamily: 'Space Grotesk, sans-serif', // Apply font
      }, },
      min: xAxisMin.getTime(), // Set X-Axis min (1 month before)
      max: xAxisMax.getTime(), // Set X-Axis max (1 month after)
    },
    yAxis: { type: 'treegrid', labels: { style: { color: isDarkTheme ? '#D9DBD3' : '#000000', fontFamily: 'Space Grotesk, sans-serif', } } },
    tooltip: {
      useHTML: true,
      // backgroundColor: "#1A1B19", //  Dark background
      // borderColor: "#FFD700", // Optional: Gold border
      style: {
        // color: '#FFFFFF' , // Title color based on theme
        color: isDarkTheme ? '#D9DBD3' : '#000000',
        fontFamily: 'Space Grotesk, sans-serif', //  Apply font
        
      }, 
      formatter: function () {
        const serializationDate = new Date(this.point.start).toLocaleDateString();
        return `<div style="color: #FFFFFF; font-size: 14px; text-align: left;">
                  <b>${this.point.fullName || this.point.name || "N/A"}</b><br>  
                  <b>Status:</b> <span style="color:${this.point.color}">${this.point.run_status || "N/A"}</span><br>
                  <b>Serialization Date:</b> ${serializationDate}  
                </div>`;
      },
    },
    
    
    series: [
      {
        name: 'Crop Runs',
        data: seriesData,
        colorByPoint: false, //  Let markers define color
      },
    ],
    credits: { enabled: false }
  };

  return (
    <Box className={isDarkTheme ? 'highcharts-dark' : 'highcharts-light'}>
      <HighchartsReact 
      key={Date.now()}
        highcharts={Highcharts} 
        constructorType="ganttChart" 
        options={options} 
      />
    </Box>
  );
};

export default DiamondChartComponent;
