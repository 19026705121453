import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from "@mui/material/Typography";

import { BreadcrumbSeparatorIcon } from "../CustomSvgIcons";

import {
    baseNewAndDetailClasses,
    fadeTimingMs,
 } from "../constants";

import DownloadingMetrcDataMsg from "../DownloadingMetrcDataMsg";
// import TaskStatusSnackbar from "../feedback/TaskStatusSnackbar";


// Definitions
// ########################################
// baseEndpointCheck: baseid === NEW
// ########################################

// Constant Component Props
// ########################################

// ########################################


// Default Values
// ########################################

const defBaseEntityName = 'Unknown';
const defBaseSaveBtnChildren = 'Save Details';
const defChangeTab = null;
const defCreateBaseEntityMsg = 'Create New';
const defTabEntityMap = [ {key: 'overview', label: 'Overview', validForNew: true} ];

const defBreadCrumbs = [];
const defTabContentMap = { overview: <></> };

const defBaseEndpointCheck = true;
const defShowSaveBtnSpinner = false;

const defGetDefaultTabForEndpoint = () => defTabEntityMap?.[0]?.key;
const defGetValidTabForNewEntity = defGetDefaultTabForEndpoint;
const defHandleSave = console.log;
const defHandleSetChangeTab = console.log;
const defHandleSubmit = (handleSave=defHandleSave) => event => {
    event.preventDefault();
    
    handleSave(event);
};
const defShowSaveBtn = () => true;
const defToDisable = () => false;

// ########################################


export default function BaseNewAndDetail(props) {
    const {
        // variables
        baseEntityName=defBaseEntityName,
        baseid,
        baseSaveBtnChildren=defBaseSaveBtnChildren,
        changeTab=defChangeTab,
        createBaseEntityMsg=defCreateBaseEntityMsg,
        tabEntityMap=defTabEntityMap,
        loading=false,
        // feedback=false,
        // feedbackProps={},

        // components
        // breadCrumbs=defBreadCrumbs, // Test if [] is valid for breadcrumb
        breadcrumbs,
        tabContentMap=defTabContentMap,
        
        // checks 
        baseEndpointCheck=defBaseEndpointCheck,
        showSaveBtnSpinner=defShowSaveBtnSpinner,
        
        // functions
        getDefaultTabForEndpoint=defGetDefaultTabForEndpoint,
        getValidTabForNewEntity=defGetValidTabForNewEntity,
        handleSave,
        handleSetChangeTab=defHandleSetChangeTab,
        showSaveBtn=defShowSaveBtn,
        toDisable=defToDisable,

        // classes
        otherBaseClasses={}, // 27-03-2023: Added later after baseTabsClass & baseSaveBtnClass; Better approach than passing each individual classes
        // baseTabsClass,
        // baseSaveBtnClass,
        currDarkThemeClass='',

        // otherProps
        otherBaseTabsProps={},
        otherSaveBtnProps={},

    } = props;
    
    const validTabs = tabEntityMap.filter(tab => baseEndpointCheck ? tab?.validForNew : true).map(e => e?.key);

    const defaultTab = getDefaultTabForEndpoint(baseid);

    const [tabValue, setTabValue] = useState(defaultTab);
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams({tab: newValue});
    };

    useEffect(() => {
        if (!tab || !validTabs.includes(tab)) {
            
            handleSetTabValue(null, defaultTab);

        } else {
            
            if (baseEndpointCheck) {
                const tabToSet = getValidTabForNewEntity(tab);
                handleSetTabValue(null, tabToSet);
                return;
            }
            
            handleSetTabValue(null, tab);
        }

        handleSetChangeTab(defChangeTab); // So that useEffect([changeTab]) will run next time

    }, [tab]);

    useEffect(() => {
        if (!changeTab) return;

        if (!validTabs.includes(changeTab)) {
            handleSetTabValue(null, defaultTab);
        } else {

            if (baseEndpointCheck) {
                const tabToSet = getValidTabForNewEntity(changeTab);
                handleSetTabValue(null, tabToSet);
                return;
            }
            
            handleSetTabValue(null, changeTab);
        }

    }, [changeTab]);


    const baseBoxProps = {
        component: 'form',
        // className: 'main-content-wrapper customScroll',
        className: `${baseNewAndDetailClasses?.box} ${currDarkThemeClass} ${otherBaseClasses?.box ?? ''} customScroll`,
        onSubmit: defHandleSubmit(handleSave),
    };

    // baseBoxProps.onSubmit = defHandleSubmit(handleSave);

    const baseHeaderBoxProps = {
        className: `${baseNewAndDetailClasses?.headerBox} ${currDarkThemeClass} ${otherBaseClasses?.headerBox ?? ''}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        // },
    };

    const baseTitleBoxProps = {
        className: `${baseNewAndDetailClasses?.titleBox} ${currDarkThemeClass} ${otherBaseClasses?.titleBox ?? ''}`,
        // sx: {
        //     backgroundColor: 'transparent',
        //     display: 'flex',
        //     flexDirection: 'column',
        // },
    };

    const baseTitleTypoProps = {
        className: `${baseNewAndDetailClasses?.titleTypo} ${currDarkThemeClass} ${otherBaseClasses?.titleTypo ?? ''}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '36px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.08,
        //     mb: '16px',
        // },
    };

    const breadcrumbSepIconProps = {
        className: `${baseNewAndDetailClasses?.breadcrumbSepIcon} ${currDarkThemeClass} ${otherBaseClasses?.breadcrumbSepIcon ?? ''}`,
        // sx: {
        //     height: '16px',
        //     width: '16px',
        // },
    };

    const breadcrumbsProps = {
        separator: <BreadcrumbSeparatorIcon {...breadcrumbSepIconProps} />,
        className: `${baseNewAndDetailClasses?.breadcrumbs} ${currDarkThemeClass} ${otherBaseClasses?.breadcrumbs ?? ''}`,
        // sx: {
        //     lineHeight: '18px',
        //     '& .MuiBreadcrumbs-separator': {
        //         mx: '4px',
        //     },
        // },
    };

    const detailBLinkTypoProps = {
        className: `${baseNewAndDetailClasses?.breadcrumbLinkTypo} ${currDarkThemeClass} ${otherBaseClasses?.breadcrumbLinkTypo ?? ''}`,
        // sx: {
        //     color: 'grey.600',
        //     fontSize: '14px', // 14
        //     letterSpacing: '-0.05em',
        //     lineHeight: '18px',
        //     '&:hover': {
        //         cursor: 'default',
        //     },
        // },
    };

    const baseTabProps = {
        className: `${baseNewAndDetailClasses?.tab} ${currDarkThemeClass} ${otherBaseClasses?.tab ?? ''}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '16px',
        //     lineHeight: 1.56,
        //     textTransform: 'none', 
        //     '&.Mui-selected': {
        //         color: 'grey.700',
        //         fontWeight: 500,
        //     },
        // }, 
    };


    const baseProgProps = {
        className: `${baseNewAndDetailClasses?.circularProgress} ${currDarkThemeClass} ${otherBaseClasses?.circularProgress ?? ''}`,
        size: 16,
        // style: {
        //     color: theme.palette.grey['500'],
        // },
    };

    const baseSaveBtnProps = {
        children: baseSaveBtnChildren,
        // className: baseSaveBtnClass,
        className: `${baseNewAndDetailClasses?.saveBtn} ${currDarkThemeClass} ${otherBaseClasses?.saveBtn ?? ''}`,
        disabled: toDisable() || showSaveBtnSpinner || loading,
        onClick: undefined,
        startIcon: showSaveBtnSpinner && <CircularProgress {...baseProgProps} />,
        // ...(!baseSaveBtnClass && { // TODO: 14022023: Remove sx after baseSaveBtnClass is also received from PhasePlan
        //     sx: {
        //         backgroundColor: 'primary.700',
        //         borderRadius: 0,
        //         boxShadow: 'none',
        //         color: 'primary.100',
        //         fontSize: '16px',
        //         fontWeight: 500,
        //         letterSpacing: '-0.05em',
        //         lineHeight: 1.25,
        //         py: '16px', // 16/1.1
        //         textTransform: 'none',
        //         width: '244px', // 244/1.1
        //         '&:hover': {
        //             backgroundColor: 'primary.700',
        //         }
        //     },
        // }),
        type: 'submit',
        variant: 'contained',
        ...otherSaveBtnProps,
    };

    const baseTabsProps = {
        // className: baseTabsClass ?? 'roomDetailTabsContent', // TODO: 14022023: Eventually remove roomDetailTabsContent
        className: `${baseNewAndDetailClasses?.tabs} ${currDarkThemeClass} ${otherBaseClasses?.tabs ?? ''} roomDetailTabsContent`,
        onChange: handleSetTabValue,
        
        // ...( !baseTabsClass && {sx: {
        //         mt: '32px',
        //     },
        //     TabIndicatorProps: {
        //         sx: {
        //             backgroundColor: 'grey.700',
        //         },
        //     }
        // }),
        value: tabValue ?? defaultTab,
        ...otherBaseTabsProps,
    };

    const tabList = tabEntityMap.filter(tab => baseEndpointCheck ? tab?.validForNew : true).map((e, i) => 
        <Tab key={i} {...baseTabProps} label={e.label} value={e.key} />
    );

    const baseDividerProps = {
        className: `${baseNewAndDetailClasses?.divider} ${currDarkThemeClass} ${otherBaseClasses?.divider ?? ''}`,
    };

    const baseDownloadMsgProps = {
        currDarkThemeClass,
    };

    // const baseTaskStatusSnackbarProps = {
    //     open: feedback,
    //     currDarkThemeClass,
        
    //     ...feedbackProps,

    // };

    return (
        <Box {...baseBoxProps}>
            <Fade in={true} timeout={fadeTimingMs}>
                <Box {...baseHeaderBoxProps} >
                    <Box {...baseTitleBoxProps} sx={{ position:'relative'  }}>
                        <Typography {...baseTitleTypoProps}>{baseEndpointCheck ? createBaseEntityMsg : baseEntityName}</Typography>
                        
                        {/* <Breadcrumbs {...breadcrumbsProps}>
                            {breadCrumbs}
                            <Typography {...detailBLinkTypoProps}>
                                {
                                    breadCrumbs?.length > 0 && ( baseEndpointCheck ? createBaseEntityMsg : baseEntityName )
                                }
                            </Typography>
                        </Breadcrumbs> */}
                        {breadcrumbs}
                    </Box>

                    {
                        showSaveBtn(tab) && 
                        <Fade in={true} timeout={fadeTimingMs}>
                            <Box>
                                <Button {...baseSaveBtnProps}  />
                            </Box>
                        </Fade>
                    }
                </Box>
            </Fade>


            <Tabs {...baseTabsProps}>
                {tabList}
            </Tabs>
            
            <Divider {...baseDividerProps}/>

            { 
                loading 
                    ? <DownloadingMetrcDataMsg {...baseDownloadMsgProps} /> 
                    : tabContentMap?.[tabValue] ?? <></>
            }

            {/* {
                feedback && <TaskStatusSnackbar {...baseTaskStatusSnackbarProps} />
            } */}
        </Box>
    );
}