import { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EntityDetailPopup from "./EntityDetailPopup";
import { popupClasses } from "./constants";

const strainKeys = ['strain_name', 'strain_yield', 'flower_price', 'cbd_level', 'thc_level', 'farm_id', 'indica_percentage', 'sativa_percentage'];

export default function CreateStrainPopup(props) {
    const {
        currDarkThemeClass = '',
        handleAddStrain,
        headContentText,
        farms = [], 
        ...otherProps
    } = props;

    const [values, setValues] = useState({});
    const [error , setError] = useState(false);


    const handleSetValues = useCallback((prop, value) => {
        setValues((prevState) => {
            const updatedValues = {
                ...prevState,
                [prop]:value , // Ensure numeric input
            };
            // Validation: Check if the total percentage exceeds 100
            const total = 
            (parseFloat(updatedValues.indica_percentage)) +
            (parseFloat(updatedValues.sativa_percentage)        );
            
            if (total > 100 || total < 100 || total ===0 ) {
                setError(true); // Set error state
            } else {
                setError(false);// Clear error state
            }
    
            return updatedValues;
        });
    }, [setValues]); 
      
    
    const cSPTextFieldProps = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
        autoComplete: 'off',
        className: `${popupClasses?.createStrain?.textField} ${currDarkThemeClass}`,
        fullWidth: true,
        inputProps,
        InputProps: {
            ...(prop === 'flower_price' && {
                startAdornment: '$',
            }),
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        required: true,
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const handleSaveClick = async () => {
        if (strainKeys.some(e => !values[e])) {
            // Handle validation error
            console.log('Validation error:', strainKeys.filter(e => !values[e]));
            return;
        }

        handleSetValues('loading', true);
        
        let res = {success: false};

        try {
            res = await handleAddStrain(values);
        } catch (error) {
            console.error('Error adding strain:', error);
        } finally {
            handleSetValues('loading', false);
        }

        if (res?.success) {
            setValues({});
            otherProps.onClose();
        }
    };

    const onPopupClose = () => {
        setValues({});
        otherProps.onClose();
    };

    const entityDetailPopupProps = {
        currDarkThemeClass,
        loading: values.loading,
        toDisableSave: strainKeys.some(e => !values[e]) || error,
        onSaveClick: handleSaveClick,
        headContentText: ' ',
        title:  'Add Strain',
        ...otherProps,
        onClose: onPopupClose,
    };

    const strainNameProps = {
        otherTextProps: {
            autoFocus: true,
            label: 'Name',
        },
        prop: 'strain_name',
    };

    const strainYieldProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Yield (Dry to Wet Weight)',
            type: 'number',
        },
        prop: 'strain_yield',
    };

    const strainPriceProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Flower Price',
            type: 'number',
        },
        prop: 'flower_price',
    };
    const wetweightProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'Wet Weight',
            type: 'number',
        },
        prop: 'wet_weight',
    };

    const cbdLevelProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'CBD Level',
            type: 'number',
        },
        prop: 'cbd_level',
    };

    const thcLevelProps = {
        inputProps: {
            min: 0,
            step: 0.01,
        },
        otherTextProps: {
            label: 'THC Level',
            type: 'number',
        },
        prop: 'thc_level',
    };

    const sativaPercentageProps = {
        otherTextProps: {
            label: 'Sativa Percentage',
            type: 'number',
        },
        inputProps: {
            max: 100,
            step: 0.01,
        },
        prop: 'sativa_percentage',
    };

    const indicaPercentageProps = {
        otherTextProps: {
            label: 'Indica Percentage',
            type: 'number',
        },
        inputProps: {
            max: 100,
            step: 0.01,
        },
        prop: 'indica_percentage',
    };


    return (
        <EntityDetailPopup {...entityDetailPopupProps}>
        {error && <p style={{color: 'red', fontSize: '18px'}}>indica and sativa percentage should be equal to 100%</p>}
            <TextField
                select
                value={values.farm_id}
                onChange={(e) => handleSetValues('farm_id', e.target.value)}
                className={`${popupClasses?.createStrain?.textField} ${currDarkThemeClass}`}
                fullWidth
                required
                label="Select Farm"
                InputProps={{
                    ...cSPTextFieldProps({ prop: 'farm_id' }).InputProps,
                }}
            >
                <MenuItem value="" disabled>Select Farm</MenuItem>
                {farms.map((farm) => (
                    <MenuItem key={farm.id} value={farm.id}>
                        {farm.farm_name} [{farm.license_number}]
                    </MenuItem>
                ))}
            </TextField>
            <TextField {...cSPTextFieldProps(strainNameProps)} />
            <TextField {...cSPTextFieldProps(wetweightProps)} />
            <TextField {...cSPTextFieldProps(strainYieldProps)} />
            <TextField {...cSPTextFieldProps(strainPriceProps)} />
            {/* <TextField {...cSPTextFieldProps(wetweightProps)} /> */}
            <TextField {...cSPTextFieldProps(cbdLevelProps)} />
            <TextField {...cSPTextFieldProps(thcLevelProps)} />
            <TextField {...cSPTextFieldProps(indicaPercentageProps)} />
            <TextField {...cSPTextFieldProps(sativaPercentageProps)} />
        </EntityDetailPopup>
    );
}
