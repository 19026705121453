import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { CheckCircleOutline, Sync, SyncDisabled } from '@mui/icons-material';
import APIService from '../../api/apiService';
import RoutinesPopup from '../crops/Popup/RoutinesPopup';
import BasicAccordion from '../crops/utils/BasicAccordion';
import DisplayTable from '../utility/DisplayTable';
import { convertHoursToDaysAndHours, getTimezoneSansLocaleDate } from '../utility/constants';

const tableTitles1 = [
  { title: 'State of Validate', key: 'state_name' },
  { title: 'Crop I.D', key: 'crop_id' },
  { title: 'Event', key: 'routine_task_id' },
  { title: 'Started On', key: 'started_on' },
  { title: 'Ended On', key: 'ended_on' },
  // { title: 'Issued Time', key: 'issue_date_time' },
  { title: 'Days :: Hrs', key: 'total_hours' },
  // { title: 'Cnt./Weight', key: 'cntWeight' },
  // { title: 'FRTL', key: 'frtl' },
  { title: 'Owners', key: 'owner_names' },
  { title: 'Validated', key: 'is_validated' },
  { title: 'Sync to Metrc', key: 'sync_to_metrics' },
];

const tableTitles2 = [
  { title: 'State of Alert', key: 'state_name' },
  { title: 'Crop I.D', key: 'crop_id' },
  { title: 'Routine/ Task I.D', key: 'routine_task_id' },
  { title: 'Subphase', key: 'subphase_name' },
  { title: 'Started On', key: 'started_on' },
  { title: 'Owners', key: 'owner_names' },
  // { title: 'Issued Date', key: 'issue_date' },
  // { title: 'Alert Time', key: 'issue_date_time' },
  { title: 'Days :: Hrs', key: 'total_hours' },
  // { title: 'Plant Count', key: 'plantCount' },
];

const tableTitles3 = [
  { title: 'State of Event', key: 'state_name' },
  { title: 'Crop I.D', key: 'crop_id' },
  { title: 'Routine/ Task I.D', key: 'routine_task_id' },
  { title: 'Subphase', key: 'subphase_name' },
  { title: 'Started On', key: 'started_on' },
  { title: 'Owners', key: 'owner_names' },
  // { title: 'Issued Date', key: 'issue_date' },
  // { title: 'Issued Time', key: 'issue_date_time' },
  { title: 'Days :: Hrs', key: 'total_hours' },
  // { title: 'Plant Count', key: 'plantCount' },
  // { title: 'On Routine', key: 'onRoutine' },
];

const CommandView = ({ onClose, currDarkThemeClass, blah }) => {
  console.log(onClose, currDarkThemeClass, blah);

  const [stateOfValidationData, setStateOfValidationData] = useState([]);
  const [stateOfAlertsData, setStateOfAlertsData] = useState([]);
  const [stateOfRoutinesData, setStateOfRoutinesData] = useState([]);

  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(5);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(5);
  const [page3, setPage3] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(5);
  const [openRoutinePopup, setOpenRoutinePopup] = useState(false);
  const [save, setSave] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [isValidate, setIsValidate] = useState([]);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await APIService.fetchInstance('new/cropplan/');
        setAssignees(response.data.assignees);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };

    const fetchValidationData = async () => {
      try {
        const response = await APIService.fetchInstance('command-center/command-view-state-list/?page=1&page_size=10&state=validate');
        setStateOfValidationData(response.data.results);

        // Initialize isValidate based on fetched data
        setIsValidate(response.data.results.map(item => item.is_validated === true));
      } catch (error) {
        console.error('Error fetching validation data:', error);
      }
    };

    const fetchRoutinesData = async () => {
      try {
        const response = await APIService.fetchInstance('command-center/command-view-state-list/?page=1&page_size=10&state=routine');
        setStateOfRoutinesData(response.data.results);
      } catch (error) {
        console.error('Error fetching routines data:', error);
      }
    };

    const fetchAlertsData = async () => {
      try {
        const response = await APIService.fetchInstance('command-center/command-view-state-list/?page=1&page_size=10&state=alert');
        setStateOfAlertsData(response.data.results);
      } catch (error) {
        console.error('Error fetching alerts data:', error);
      }
    };

    fetchAssignees();
    fetchValidationData();
    fetchRoutinesData();
    fetchAlertsData();

    // Add event listener for escape key press
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = event => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = event => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = event => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };

  const handleRoutineClick = async (id, index) => {
    try {
      const entity = `cropplan-routines/${id}/`;
      let response = await APIService.fetchInstance(entity);
      setSelectedRoutine(response.data);
      setSelectedRowIndex(index);
      setOpenRoutinePopup(true);
    } catch (error) {
      console.error('Error fetching routine data:', error);
    }
  };

  const handleRoutinePopupClose = () => {
    setOpenRoutinePopup(false);
  };

  const handleSave = () => {
    setSave(false);
  }

  const [dataFromChild, setDataFromChild] = useState('');

  // Function to handle data from the child
  const handleDataFromChild = (childData) => {
    setDataFromChild(childData);
  };

  // useEffect to update the stateOfValidationData when dataFromChild changes
  useEffect(() => {
    if (dataFromChild && 'rowIndex' in dataFromChild) {
      const index = dataFromChild['rowIndex'];
      const updatedData = [...stateOfValidationData]; // Create a shallow copy
      updatedData[index] = {
        ...updatedData[index],
        ...dataFromChild
      };
      setStateOfValidationData([...updatedData]); // Update the state with new data
      setIsValidate(updatedData.map(item => item.is_validated === true));
    }
  }, [dataFromChild]);


  const handleValidateClick = async (id, index) => {
    try {
      // Make API request to validate the routine
      const response = await APIService.modifyInstance(`cropplan-routines/${id}/`, { is_validated: true });

      if (response.status === 200) {
        const newIsValidate = [...isValidate];
        newIsValidate[index] = true;
        setIsValidate(newIsValidate); // Update the local state to show the validation as complete
      }
    } catch (error) {
      console.error('Error validating routine:', error);
    }
  };

  const createTableEntries = (data = [], titles = []) =>
    data?.map((row, i) =>
      titles?.map((title, j) => {
        let val = row[title?.key];

        if (title?.key === 'routine_task_id') {
          const routineName = row?.['routine_name']; 
          return (
            <Tooltip title={routineName} arrow>
              <span style={{ color: currDarkThemeClass === 'dark-theme' ? '#f0f0f0' : '#242523', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleRoutineClick(val, i)}
              title={routineName} >
                R{val}
              </span>
            </Tooltip>
          );
        } else if (title?.key === 'started_on' || title?.key === 'ended_on') {
          return getTimezoneSansLocaleDate(val);
        } else if (title?.key === 'total_hours') {
          return convertHoursToDaysAndHours(val);
        }else if (title?.key === 'is_validated' && isValidate[i]) return (
          <Typography style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleOutline style={{ color: '#B1D23A', marginRight: '5px'}} />
            <span style={{color: '#B1D23A'}}>Validated</span> 
          </Typography>
          );
        else if (title?.key === 'is_validated') {
          return (
            <Button
              style={{
                border: '2px solid #B1D23A',
                color: '#B1D23A',
                backgroundColor: 'transparent',
                fontSize: '13px',
                padding: '2px 25px',
                borderRadius: '0px',
                textTransform: 'none',
              }}
              onClick={() => handleRoutineClick(row['routine_task_id'], i)}
            >
              Validate Now
            </Button>
          );
        } else if (title?.key === 'sync_to_metrics') {
          return (
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              {val === 'Synced' ? (
                <>
                  <CheckCircleOutline style={{ color: '#B1D23A', marginRight: '5px'}} />
                  <span style={{color: '#B1D23A'}}>{val}</span>
                </>
              ) : val === 'Syncing...' ? (
                <>
                  <Sync style={{ color: 'orange', marginRight: '5px' }} />
                  <span style={{color: '#B1D23A'}}>{val}</span>
                </>
              ) : (
                <>
                  <SyncDisabled style={{ color: 'orange', marginRight: '5px' }} />
                  <span>{val}</span>
                </>
              )}
            </Typography>
          );
          } else return val;
      })
    );

  const cvSVTableProps = {
    currDarkThemeClass,
    emptyTableMsg: 'No State Validation',
    toShowAddBtn: false,
    tableTitles: tableTitles1,
    tableEntries: createTableEntries(stateOfValidationData, tableTitles1),
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    sx: {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness
        padding: '6px 12px', // Adjust padding for better spacing
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness on rows
      },
    },
  };
  const cvSATableProps = {
    currDarkThemeClass,
    emptyTableMsg: 'No State Alerts',
    toShowAddBtn: false,
    tableTitles: tableTitles2,
    tableEntries: createTableEntries(stateOfAlertsData, tableTitles2),
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    sx: {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness
        padding: '6px 12px', // Adjust padding for better spacing
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness on rows
      },
    },
  };

  const cvSETableProps = {
    currDarkThemeClass,
    emptyTableMsg: 'No State Events',
    toShowAddBtn: false,
    tableTitles: tableTitles3,
    tableEntries: createTableEntries(stateOfRoutinesData, tableTitles3),
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    sx: {
      '& .MuiTableCell-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness
        padding: '6px 12px', // Adjust padding for better spacing
      },
      '& .MuiTableRow-root': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Reduced border thickness on rows
      },
    },
  };

  return (
    <Box className={"command-center-view"} >
      {/* Table 1: State of Validation */}
      <BasicAccordion
        title={
          <Typography variant="subtitle1" style={{ fontSize: '20px' }}>
            State of Validation
          </Typography>
        }
        currDarkThemeClass={currDarkThemeClass}
        defaultExpanded={true}
        accordionCustomProps={{ style: { marginBottom: '10px',backgroundColor: currDarkThemeClass === 'dark-theme' ? '#242523' : '#E6F1C1', padding: '2px 8px' } }}
      >
        <DisplayTable {...cvSVTableProps} />
      </BasicAccordion>

      {/* Table 2: State of Alerts */}
      <BasicAccordion
        title={
          <Typography variant="subtitle1" style={{ fontSize: '20px' }}>
            State of Alerts
          </Typography>
        }
        currDarkThemeClass={currDarkThemeClass}
        defaultExpanded={true}
        accordionCustomProps={{ style: { marginBottom: '10px', backgroundColor: currDarkThemeClass === 'dark-theme' ? '#242523' : '#E6F1C1', padding: '2px 8px' } }}
      >
        <DisplayTable {...cvSATableProps} />
      </BasicAccordion>

      {/* Table 3: State of Event */}
      <BasicAccordion
        title={
          <Typography variant="subtitle1" style={{ fontSize: '20px' }}>
            State of Event
          </Typography>
        }
        currDarkThemeClass={currDarkThemeClass}
        defaultExpanded={true}
        accordionCustomProps={{ style: { marginBottom: '10px', backgroundColor: currDarkThemeClass === 'dark-theme' ? '#242523' : '#E6F1C1', padding: '2px 8px' } }}
      >
        <DisplayTable {...cvSETableProps} />
      </BasicAccordion>

      {/* Routine Popup */}
      {openRoutinePopup && (
        <RoutinesPopup
          rowIndex={selectedRowIndex}
          routine={selectedRoutine}
          subphase={selectedRoutine.subphase}
          cropplanid={selectedRoutine.cropplanid}
          handleRoutinesPopupSave={null}
          assignees={assignees}
          currDarkThemeClass={currDarkThemeClass}
          onClose={handleRoutinePopupClose}
          open={openRoutinePopup}
          handleSave={handleRoutineClick}
          sendDataToParent={handleDataFromChild}
        />
      )}
    </Box>
  );
};

export default CommandView;
