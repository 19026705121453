import { useTheme } from "@mui/material";
import { dashMegaCardClasses, getDarkThemeClass } from "../constants";
import DashMiniCard from "./DashMiniCard";

const boxHt = 302;
const boxPadding = 20;
const boxWd = 376;
const iconHt = '108px';
const iconWd = '106px';
// const textWd = boxWd - 2 * boxPadding;
const textWd = 222;

const iconPropsMap = {
    'GrowDashDoubleFlowerIcon': {
        height: '123px',
        width: '132px',
    },
    'GrowDashLightBulbIcon': {
        height: '133px',
        position: 'absolute',
        right: 10,
        top: 0,
        transform: 'rotate(-15deg)',
        width: '133px',
    },
    'GrowDashLeavesIcon': {
        height: '146px', // 302 - 148 - 8
        position: 'absolute',
        right: 14,
        top: 8,
        width: '144px', // 376 - 218 - 14
    },
    'GrowDashWeightScaleIcon': {
        height: '131px', // 302 - 152 - 19
        position: 'absolute',
        right: 34,
        top: 19,
        width: '130px', // 376 - 212 - 34
    },
    'GrowDashFY2DIcon': {
        height: '100px',
        width: '100px',
    },
    'GrowDashTDF2DIcon': {
        height: '93px',
        width: '93px',
    },
    'GrowDashOH2DIcon': {
        height: '100px',
        width: '100px',
    },
    'GrowDashDFL2DIcon': {
        height: '90px',
        width: '90px',
    },
};

export default function DashMegaCard(props) {

    const theme = useTheme();

    const megaCardProps = {

        otherDetailBoxSxProps: {
            position: 'absolute',
            top: '133px',
        },

        // otherIconSxProps: {
        //     ...iconPropsMap?.[props?.icon]
        // },

        otherBoxProps: {
            className: `${props?.dashGridClass ?? ''} ${dashMegaCardClasses?.box} ${getDarkThemeClass(theme)}`,
        },

        otherIconProps: {
            className: `${dashMegaCardClasses?.icon} ${getDarkThemeClass(theme)}`,
        },
        otherDescTypoProps: {
            className: `${dashMegaCardClasses?.descTypo} ${getDarkThemeClass(theme)}`,
        },
        otherTitleTypoProps: {
            className: `${dashMegaCardClasses?.titleTypo} ${getDarkThemeClass(theme)}`,
        },
        otherValueTypoProps: {
            className: `${dashMegaCardClasses?.valueTypo} ${getDarkThemeClass(theme)}`,
        },
        otherSeeMoreBtnProps: {
            className: `${dashMegaCardClasses?.seeMoreBtn} ${getDarkThemeClass(theme)}`,
        },

        ...props,
    };
    
    return <DashMiniCard {...megaCardProps} />
}