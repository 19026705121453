import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { baseClasses, fadeTimingMs, getDarkThemeClass, landingPageRoute } from "../utility/constants";

import bgImage from '../../images/farm_login_748x982.png';
import bgImageDark from '../../images/farm_login_dark_748x982.png';
import logoImage from '../../images/sg_dash_16x16.png';

const title = 'SMARTGROW';


export default function SetupOutlinePage(props) {

    const {
        showImage=true,
        allowAddFarm=true,
        currDarkThemeClass='',
    } = props;

    const theme = useTheme();

    const navigate = useNavigate();

    // const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    const sOBoxProps = {
        className: `${baseClasses?.setupOutlinePage?.box} ${currDarkThemeClass} ${!showImage ? baseClasses?.setupOutlinePage?.hideImage : ''}`,
        // sx: {
        //     backgroundColor: 'primary.100',
        //     display: 'flex',
        //     width: '100%',
        //     ...(!showImage && {
        //         minHeight: '100vh',
        //     }),
        // },
    };

    const sOBgImageBoxProps = {
        className: `${baseClasses?.setupOutlinePage?.bgImgBox} ${currDarkThemeClass}`,
        // sx: {
        //     flexBasis: '50%',
        // },
    };

    const sOBgImageProps = {
        alt: "Cannabis cultivating farm facility",
        className: `${baseClasses?.setupOutlinePage?.bgImage}`,
        src: !currDarkThemeClass ? bgImage : bgImageDark,
        // style: {
        //     height: '100vh',
        //     minHeight: '100%', 
        //     width: '100%', 
        // },
    };

    const sOContentBoxProps = {
        className: `${baseClasses?.setupOutlinePage?.contentBox} ${currDarkThemeClass} ${!showImage ? baseClasses?.setupOutlinePage?.hideImage : ''}`,
        // sx: {
        //     flexBasis: showImage ? '50%' : '100%',
        //     pl: showImage ? '138px' : '32px',
        //     pt: '37px',
        // },        
    };

    const sOLogoBoxProps = {
        className: `${baseClasses?.setupOutlinePage?.logoBox} ${currDarkThemeClass} ${allowAddFarm ? baseClasses?.setupOutlinePage?.allowAddFarm : ''}`,
        // sx: {},
    };

    const sOLogoImageBoxProps = {
        className: `${baseClasses?.setupOutlinePage?.logoImageBox} ${currDarkThemeClass}`,
        // sx: {},
    };

    const sOBrandTypoProps = {
        onClick: () => navigate(landingPageRoute),
        className: `${baseClasses?.setupOutlinePage?.brandTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '14px',
        //     fontWeight: 700,
        //     lineHeight: '18px',
        //     mt: '8px',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        // },        
    };


    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...sOBoxProps}>
                {
                    showImage && 
                    <Box {...sOBgImageBoxProps}>
                        <img {...sOBgImageProps} />
                    </Box>
                }

                <Box {...sOContentBoxProps}>
                    <Box {...sOLogoBoxProps}>
                        <Box {...sOLogoImageBoxProps}>
                            <img src={logoImage} alt="Smartgrow logo" width='14px' height='14px' />
                        </Box>
                        <Typography {...sOBrandTypoProps}>{title}</Typography>
                    </Box>
                    {props.children}
                </Box>
            </Box>
        </Fade>
    );
}