// import axios from "axios";
import axiosInstance from "./axiosInstance";
// import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_ENDPOINT;

// const _getAuthObject = () => ({
//     auth: {
//         username: localStorage.getItem('username'),
//         password: localStorage.getItem('password'),
//     },
// });

// const _getEncodedAuthKey= () => {
//     const authToken = _getAuthObject().auth;
//     const encodedAuth = btoa(`${authToken.username}:${authToken.password}`);
//     return encodedAuth;
// };

let loggedIn = false;

class APIService {
        
    // Generic MetrcKey Based Functions
    
    fetchAllInstances(entity, metrcKey) {
        // entity = 'entity/';
        const url = API_URL + entity + '?metrc_id=' + metrcKey;
        
        // temporary
        // if (!loggedIn) {
        //     this.login()
        // }

        // return axiosInstance().get(url, _getAuthObject());        
        return axiosInstance().get(url);
    }

    fetchInstance(entityWithID) {
        // entityWithID = 'entity/entityID/';
        const url = API_URL + entityWithID;

        // temporary
        // if (!loggedIn) {
        //     this.login()
        // }

        // return axiosInstance().get(url, _getAuthObject());
        return axiosInstance().get(url);
    }

    createInstance(entity, payload) {
        // entity = 'entity/';
        const url = API_URL + entity;

        // temporary
        // if (!loggedIn) {
        //     this.login()
        // }

        // return axiosInstance().post(url, payload, _getAuthObject());
        return axiosInstance().post(url, payload);
    }

    modifyInstance(entityWithID, payload) {
        // entityWithID = 'entity/entityID/';
        const url = API_URL + entityWithID;
        
        // temporary
        // if (!loggedIn) {
        //     this.login()
        // }

        // return axiosInstance().put(url, payload, _getAuthObject());
        return axiosInstance().put(url, payload);
    }

    patchInstance(entity, payload) {
        const url = `${API_URL}${entity}`
        return axiosInstance().patch(url, payload)
    }
    
    
    deleteInstance(entityWithID, payload) {
        // entityWithID = 'entity/entityID/';
        const url = API_URL + entityWithID;

        // temporary
        // if (!loggedIn) {
        //     this.login()
        // }

        // const encodedAuth = _getEncodedAuthKey();
        const token = localStorage.getItem('token');

        return axiosInstance().delete(url, {
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Basic ${encodedAuth}`
                    "Authorization": `Bearer ${token}`
                },
                data: payload,
        });
    }

}
export default new APIService();