import { useContext, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import BaseNewAndDetail from "../utility/outlines/BaseNewAndDetail";
import NoEntityMsg from "../utility/messages/NoEntityMsg";
import PlanCard from "../utility/PlanCard";

import useThemeVal from "../../utility/useThemeVal";

import { cropPlanModelName, cropsClasses, cropsCollectionEndpoint, cropsPlanNewAndDetailRoute, cropsRunNewAndDetailRoute, defErrProps, errFeedbackFn, fadeTimingMs, getFormattedTableEntry, logMsgToConsole, miscClasses, } from "../utility/constants";

import APIService from "../../api/apiService";
import { FeedbackContext } from "../../App";
import { failsTokenAndSetupCheck } from "../../checks/setup";
import useToken from "../../utility/useToken";
import { hasPermissions } from "../../utility";
import { _CropPlanTab, _CropRunTab } from "./Tabs";
import { FRTLContext } from "../../context/FRTLContext";

// Constant Values

const baseEntityName = 'Crops';

const baseEndpointCheck = false; // will treat it as a detail page

const runKey = 'runs';
const plansKey = 'plans';
const historyKey = 'history';

const tabEntityMap = [
    {key: runKey, label: 'My Crop Runs', defaultForDetail: true},
    {key: plansKey, label: 'My Crop Plans'},
    {key: historyKey, label: 'My Crop History'},
];

const tabCreateTitleMap = {
  [runKey]: 'Crop Run',
  [plansKey]: 'Crop Plan',
};

// Functions

const handleCropsRes = (setValues) => (res) => {
    const {data=[]} = res;

  if (logMsgToConsole?.crops) {
        console.log('got crops res...');console.log(res);
  }

  const cropplans = data?.cropplans?.results ?? [];
  const cropruns = data?.cropruns?.results ?? [];
  const crophistory = data?.crophistory?.results ?? [];
  const qualityPlanExists = data?.quality_plan_or_room_exists ?? false;
  setValues(state => ({ ...state, cropplans, cropruns, crophistory,qualityPlanExists }));
  // setSavedRes(state => ({ ...state, cropplans, cropruns, crophistory }));
    
};

const handleError = (handleSetValues) => (err) => {
  if (logMsgToConsole?.crops) {
        console.log('An error occured');console.log(err);
  }

  handleSetValues('error', true);
}

const handleErrorWithFeedback = feedbackCX => (handleSetValues, errPropsFn) => err => {
  if (logMsgToConsole?.crops) {
        console.log('An error occured');console.log(err);
  }

  handleSetValues('error', true);

  const errProps = errPropsFn?.(err) ?? defErrProps;

  feedbackCX?.setContextValue?.(true, errProps);
}

const handleFinally = (handleSetValues) => (prop='loading') => {
    handleSetValues(prop, false);
}


export default function Crops(props) {
  const { token, tokenDetails, permissions } = useToken();

  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  // const [savedRes, setSavedRes] = useState({});
  const [values, setValues] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  const feedbackCX = useContext(FeedbackContext);
  const {frtlContextValue, setFrtlContextValue} = useContext(FRTLContext);

  const navigate = useNavigate();

  const currDarkThemeClass = useThemeVal();

    const handleSetValues = useCallback((prop, value) => setValues(state => ({...state, [prop]: value})), [setValues]);
  // const handleSetSavedRes = useCallback((prop, value) => setSavedRes(state => ({...state, [prop]: value})), [setSavedRes]);

  const permissionsList = useMemo(() => hasPermissions([cropPlanModelName], { ...tokenDetails, permissions }), [token]);

  useEffect(() => {
    if (invalidTokenState) return;

    handleSetValues('loading', true);

    const errConfig = {};

    const entityWithID = cropsCollectionEndpoint;

    APIService.fetchInstance(entityWithID)
      .then(handleCropsRes(setValues)) // Functional approach; Will prevent using too much use of useCallback
      // .catch(handleError(handleSetValues))
      .catch(handleErrorWithFeedback(feedbackCX)(handleSetValues, errFeedbackFn(errConfig)))
        .finally(handleFinally(handleSetValues))

  }, []);

  const fetchFrtlData = async () => {
    try {
      const response = await APIService.fetchInstance("new/frtl/");
      setFrtlContextValue(response?.data);
    } catch (error) {
      console.error("Error fetching FRTL data:", error);
    }}
    
  useEffect(()=>{
    fetchFrtlData();
  },[])

  if (invalidTokenState) return <></>;

    const baseSaveBtnChildren = `Create ${tabCreateTitleMap?.[tab] ?? '' }`; // Can make it dynamic via otherSaveBtnProps

  const cropsCardExtraTypoProps = {
    className: `${cropsClasses?.cropsCardExtraTypo} ${currDarkThemeClass}`,
  };

  const historyBoxProps = {
        className: `${cropsClasses?.historyBox} ${currDarkThemeClass} ${(values?.crophistory?.length < 1 || values?.error) ? miscClasses?.noEntityAvailable : ''}`,
  };

  const cropHistoryExtraDetails = crophistory => {
    const entryTitleValueMap = [
            {title: 'Room', key: 'room_name'},
            {title: 'Date Of Serialization', key: 'serialization_date', isDate: true},
            {title: 'Strain Count', key: 'strain_count'},
            {title: 'Planter Count', key: 'planter_count'},
    ];

        return entryTitleValueMap.map((e, i) => <Typography key={i} {...cropsCardExtraTypoProps}>{e?.title}: {getFormattedTableEntry(crophistory, e)}</Typography>)
    }

  const cropHistoryCardProps = crophistory => ({
    ...crophistory,
    title: crophistory?.name,
    currDarkThemeClass,
    label: 'Inactive',
    hasExtraDetails: true,
    extraDetails: cropHistoryExtraDetails(crophistory),
    otherBoxClass: cropsClasses?.entityCardBox,
    otherDescClass: `${cropsClasses?.entityCardDescTypo} customScroll`,
  });

    const historyCardList = values?.crophistory?.length > 0 
        ? values?.crophistory?.map((e, i) => <PlanCard key={i} {...cropHistoryCardProps(e)} />)
        : <NoEntityMsg msg='No Crop History Available' />;

  const historyTab = (
    <Fade in={true} timeout={fadeTimingMs}>
            <Box {...historyBoxProps}>
                {historyCardList}
            </Box>
    </Fade>
  );

  const cpTabProps = {
    values,
    currDarkThemeClass,
    cropplanperms: permissionsList?.[cropPlanModelName],
    handleSetValues,
    handleErrorWithFeedback: handleErrorWithFeedback(feedbackCX)(handleSetValues, errFeedbackFn({})),
  };

  const tabContentMap = {
    [runKey]: <_CropRunTab {...cpTabProps} />,
        [plansKey]: <_CropPlanTab {...cpTabProps}/>,
    [historyKey]: historyTab,
  };

  const getDefaultTabForEndpoint = () => tabEntityMap?.find(tab => tab?.defaultForDetail)?.key;

  const handleSave = () => tab === runKey && navigate(`${cropsRunNewAndDetailRoute}/new/`);

  const showSaveBtn = () => tab === runKey;

  const otherSaveBtnProps = {
    className: `${cropsClasses?.saveBtn} ${currDarkThemeClass}`,
    disabled: (!values?.cropruns || values?.cropruns.length === 0) && !values?.qualityPlanExists,
  };

  const baseProps = {
    // variables
    baseEntityName,
    baseid: undefined,
    baseSaveBtnChildren,
    // changeTab: values?.changeTab,
    createBaseEntityMsg: '',
    tabEntityMap,
    loading: values?.loading,

    // components
    // breadCrumbs: PhaseplanDetailBLink,
    // breadcrumbs,
    tabContentMap,

    // checks
    baseEndpointCheck,
    // showSaveBtnSpinner,

    // functions
    getDefaultTabForEndpoint,
    // getValidTabForNewEntity,
    handleSave,
    // handleSetChangeTab,
    showSaveBtn,
    // toDisable,

    //classes
    currDarkThemeClass,
    // otherBaseClasses,

    // otherProps
    otherSaveBtnProps,
  };

  return <BaseNewAndDetail {...baseProps} />;
}