import { useContext, useState, useEffect, useMemo } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import AccordionDetailMsg from "../utility/messages/AccordionDetailMsg";
import ConfirmationPopupWithLoading from "../utility/ConfirmationPopupWithLoading";
import DetailInfoCard from "../utility/DetailInfoCard";
import DownloadingMetrcDataMsg from "../utility/DownloadingMetrcDataMsg";
import DragAccordionCard from "../utility/cards/DragAccordionCard";
// import DragCard from "../utility/DragCard";
import ExpandCard from "../utility/ExpandCard";
import NoEntityMsg from "../utility/messages/NoEntityMsg";
import PlansDetailInfoCard from "../utility/PlansDetailInfoCard";
import RowHeaderTypo from "../utility/messages/RowHeaderTypo";
import { hasPermissions } from "../../utility";

import { DragMetrcPlanIcon, ECIcon, MoistureIcon, NutritionIcon, PlansIcon, EditIcon, UnlinkIcon, DownArrowIcon, ExternalLinkIcon } from "../utility/CustomSvgIcons";
import { QualityPlanBreadcrumbs } from "../utility/BreadcrumbLinks";

import { 
    crudActions, 
     detailQualityPlanEndpoint, qualityPlanNewAndDetailRoute,
    fadeTimingMs, 
    landingPageRoute, listMetricPlanEndpoint, listQualityPlanEndpoint, metrcPlanModelName, metricPlanNewAndDetailRoute, qualityPlanAppLabel, qualityPlanModelName, 
    maxAccordionCardsBoxHt,
    qualPlanClasses,
    PLAN_WIZARD,
    PLAN_WIZARD_TYPE,
    getWizardRoute,
    defErrProps,
    errFeedbackFn,
    logMsgToConsole,
    miscClasses,
    sortByID,
    baseTimelineChartBoxWd,
    baseTimelineChartColGap,
    phasePlanNewAndDetailRoute,
    getMetricPlanRoute,
} from "../utility/constants";

import APIService from '../../api/apiService';
import  { failsTokenAndSetupCheck } from "../../checks/setup";
import { isNotValidDetailPageView } from "../../checks/detailPage";
import { FeedbackContext } from "../../App";
import useThemeVal from "../../utility/useThemeVal";
import useToken from "../../utility/useToken";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const NEW = 'new';
const actionMsg = `Create Quality Plan`;
const selPhasePlanMsg = 'Please select a Phase to see available Metrics';

const tabEntityMap = [
    {key: 'overview', label: 'Overview', validForNew: false},
    {key: 'general-info', label: 'General Info', validForNew: true},
    {key: 'attach-metrics', label: 'Attach Metrics', validForNew: true},
];

// const metrcPlanIconMap = {
//     1: MoistureIcon,
//     2: NutritionIcon,
//     3: ECIcon,
// };

// const qPNewKeys = [
//     {key: 'name', list: false},
//     {key: 'description', list: false},
//     {key: 'metrc_plans', list: true, props: ['id']},
// ];


const qualPlanActionTitle = 'Enter your Quality Plan Name and Description.';
const qualPlanActionDesc = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.";

const attachMsg = 'Drag and Drop Quality Plan Builder.';
const myQPSubMsg = 'Drop Metrics from the column on the right';
const createdMPMsg = 'Created Metrics';
const createdMPSubMsg = 'All the Available Metrics';

// const dragCardKeysMap = [
//     { key: 'High', valueKey: 'high' },
//     { key: 'Low', valueKey: 'low' }
// ];

// const accDetailMsgProps = (e, metrcplan, currDarkThemeClass) => ({
//     currDarkThemeClass,
//     showTitleTooltip: false,
//     showValueTooltip: false,
//     title: e?.key,
//     value: metrcplan?.[e?.valueKey],
// });

// const getDragCardContent = (metrcplan, currDarkThemeClass) => dragCardKeysMap?.map( 
//     (e, i) => <AccordionDetailMsg key={i} {...accDetailMsgProps(e, metrcplan, currDarkThemeClass)} />
// );

const accDetailMsgProps = (metrcplan, currDarkThemeClass) => ({
    currDarkThemeClass,
    otherClasses: {
        box: qualPlanClasses?.accordionDragCardBox,
    },
    showTitleTooltip: false,
    showValueTooltip: false,
    title: `L: ${ (!metrcplan?.low && metrcplan?.low !== 0) ? ' ' : +metrcplan?.low } - H: ${ (!metrcplan?.high && metrcplan?.high !== 0) ? ' ' : +metrcplan?.high }`,
    // value: metrcplan?.[e?.valueKey],
});

const getDragCardContent = (metrcplan, currDarkThemeClass) => <AccordionDetailMsg {...accDetailMsgProps(metrcplan, currDarkThemeClass)} />;

const ROW_CONTENT_VALUE = 'ROW_CONTENT_VALUE';
const PHASES_POS =  0;
const WEEK_POS =  1;
const DAYS_POS =  2;


export default function QualityPlanNewAndDetail(props){
    const theme = useTheme();

    const [detailChildren, setDetailChildren] = useState({});
    const [loading, setLoading] = useState({});
    const [open, setOpen] = useState(false);
    const [savedRes, setSavedRes] = useState({});
    const [values, setValues] = useState({showExpandedChart: true});

    const [searchParams, setSearchParams] = useSearchParams();
    
    const feedbackCX = useContext(FeedbackContext);

    const navigate = useNavigate();

    const {qualityplanid} = useParams();

    const validTabs = tabEntityMap.filter(tab => qualityplanid === NEW ? tab?.validForNew : true).map(tab => tab.key);
    const defaultTab = validTabs[0];
    
    const [tabValue, setTabValue] = useState(defaultTab);
    
    const tab = searchParams.get('tab');

    const currDarkThemeClass = useThemeVal()
    const { token, tokenDetails, permissions } = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);


    const { is_staff = false } = tokenDetails || '';

    const qualityPlanPerms = useMemo(() => hasPermissions([qualityPlanModelName], { ...tokenDetails, permissions })[qualityPlanModelName] ?? {}, [token]);
    const metricPlanPerms = useMemo(() => hasPermissions([metrcPlanModelName], { ...tokenDetails, permissions })[metrcPlanModelName] ?? {}, [token]);

    // const qualityPlanPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${qualityPlanModelName}`)) ?? [], [token]);
    // const metricPlanPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${metrcPlanModelName}`)) ?? [], [token]);
    
    // const qualityPlanPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (qualityPlanPermissions?.includes(`${qualityPlanAppLabel}.${crudActions?.[action]}_${qualityPlanModelName}`) ?? false)]));
    // const metricPlanPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (metricPlanPermissions?.includes(`${qualityPlanAppLabel}.${crudActions?.[action]}_${metrcPlanModelName}`) ?? false)]));

    const filteredMetrcPlans = useMemo(
        () => {
            if ( qualityplanid === NEW || (qualityplanid && !isNaN(+qualityplanid)) ) {
                return values?.metrcplans?.filter?.(e => +e?.phaseplan?.id === values?.phaseplan) || [];
            } 
            return [];
        }
    , [values?.phaseplan, values?.metrcplans?.length]);

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));
    const handleSetSavedRes = (prop, value) => setSavedRes(state => ({...state, [prop]: value}));
    
    const handleSetLoading = (prop, value) => setLoading(state => ({...state, [prop]: value}));

    const handleSetTabValue = (event, value) => {
        setTabValue(value);
        setSearchParams({tab: value});
    }

    const handleErrorWithFeedback = errPropsFn => err => {
        const errProps = errPropsFn?.(err) ?? defErrProps;

        feedbackCX.setContextValue(true, errProps);
    }

    const handleFinally = () => {
        setLoading({});
    }

    const handleMetrcPlanRes = res => {
        if (logMsgToConsole?.qualityplan) {
            console.log('got qualityplan data');console.log(res);
        }

        const { data: { results = [] } = '' } = res;
        
        const phaseplansIDList = [];

        const phaseplans = results?.reduce((res=[], val) => {
            if ( val?.phaseplan?.id && !phaseplansIDList?.includes(val?.phaseplan?.id) ) {
                phaseplansIDList.push(val?.phaseplan?.id);

                res.push(val?.phaseplan);
            }

            return res;
        }, []);

        const metrcplans = results?.map(e => {
            const res = e?.metriccycles?.reduce((res, val) => {
                res.high = ( (!res?.high && res?.high !== 0) || +res?.high < +val?.high ) ? +val?.high : +res.high;
                res.low = ( (!res?.low && res?.low !== 0) || +res?.low > +val?.low ) ? +val?.low : +res.low;
                
                return res;
            }, {high: null, low: null});

            return ({ ...e, ...res });
        });

        metrcplans.sort(sortByID);

        handleSetSavedRes('metrcplans', metrcplans);
        handleSetSavedRes('phaseplans', phaseplans);
        handleSetValues('metrcplans', metrcplans);
        handleSetValues('phaseplans', phaseplans);

        // Here metrc_plans will be [] as we have yet to add elements to it but this entry needs to be added to detail route res
    }

    const handleQPCreateRes = res => {
        
        const defRoute = `${qualityPlanNewAndDetailRoute}/${res.data.id}?tab=overview`;

        const route = getWizardRoute(PLAN_WIZARD_TYPE, PLAN_WIZARD.QUALITYPLAN_CREATE, defRoute);
                
        navigate(route);

    };
    
    const handleQPRes = res => {

        const {data} = res || '';

        if (logMsgToConsole?.qualityplan) {
            console.log('got qualityplan data');console.log(res);
        }

        let {metrc_plans, metrcplans} = data || '';

        // const availableMetrcPlans = metrcplans?.filter(e => !metrc_plans?.includes(e.id));
        // const attachedMetrcPlans = metrcplans?.filter(e => metrc_plans?.includes(e.id));
        
        const phaseplansIDList = [];

        const phaseplans = metrcplans?.reduce((res=[], val) => {
            if ( val?.phaseplan?.id && !phaseplansIDList?.includes(val?.phaseplan?.id) ) {
                phaseplansIDList.push(val?.phaseplan?.id);

                res.push(val?.phaseplan);
            }

            return res;
        }, []);

        metrcplans = metrcplans?.map(e => {
                const res = e?.metriccycles?.reduce((res, val) => {
                    res.high = ( (!res?.high && res?.high !== 0) || +res?.high < +val?.high ) ? +val?.high : +res.high;
                    res.low = ( (!res?.low && res?.low !== 0) || +res?.low > +val?.low ) ? +val?.low : +res.low;
                    
                    return res;
                }, {high: null, low: null});

                return ({ ...e, ...res });
            });

        metrc_plans = metrcplans?.filter(e => data?.metrc_plans?.includes(e?.id));
        
        metrcplans = metrcplans?.filter(e => !data?.metrc_plans?.includes(e?.id));

        metrcplans.sort(sortByID);

        setValues(state => ({
            ...state,
            ...data,
            // metrc_plans: attachedMetrcPlans,
            // metrcplans: availableMetrcPlans,
            phaseplans,
            metrcplans,
            metrc_plans,
        }));

        setSavedRes(state => ({
            ...state,
            ...data,
            phaseplans,
            metrcplans,
            metrc_plans,
        }));

        if (tabValue !== 'overview') {
            handleSetTabValue(null, 'overview');
        }
    };

    useEffect(() => {
        let tabToSet = '';

        if (!tab || !validTabs.includes(tab)) {
            tabToSet = defaultTab;
        } else if (validTabs.includes(tab)) {
            tabToSet = tab;
        }

        handleSetTabValue(null, tabToSet);

        // 22-06-2023: Approach #2:  This approach for maintaining consistent popup state requires any open popup to be closed whenever a tab changes since popups are always tab specific
        if ( open ) handlePopupClose();

    }, [tab]);


    useEffect(() => {
        if (invalidTokenState) return;
        
        let errConfig = {};

        if (qualityplanid === NEW) {
            if (!qualityPlanPerms?.create) return;

            errConfig = {
                404: 'Metrics Not Found. Creating Quality Plan Not Possible',
            };

            const entity = listMetricPlanEndpoint;
    
            APIService.fetchInstance(entity)
            .then(handleMetrcPlanRes)
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
            .finally(handleFinally)

        } else if (isNotValidDetailPageView(token, qualityplanid) || !qualityPlanPerms?.read) {
            return;
        } else {
            // 22-05-2023: TODO: Match detail serializer to send all phaseplans like for new

            const entityWithID = detailQualityPlanEndpoint(qualityplanid);

            handleSetLoading('data', true);
                        
            APIService.fetchInstance(entityWithID)
            .then(handleQPRes)
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
            .finally(handleFinally);

        }

    }, [qualityplanid]);


    useEffect(() => {
        
        if (!invalidTokenState && !qualityPlanPerms?.read) navigate(landingPageRoute);

    }, []);


    if (invalidTokenState || !qualityPlanPerms?.read) return <></>;

    const qPDBoxProps = {
        className: `${qualPlanClasses?.box} ${currDarkThemeClass} customScroll`,
    };


    const qPDHeaderBoxProps = {
        className: `${qualPlanClasses?.headerBox} ${currDarkThemeClass}`,
    };

    const qPDHeaderTitleBoxProps = {
        className: `${qualPlanClasses?.headerTitleBox} ${currDarkThemeClass}`,
    };

    const qPDActionTypoProps = {
        className: `${qualPlanClasses?.actionTypo} ${currDarkThemeClass}`,
    };

    const breadcrumbSepIconProps = {
        className: `${qualPlanClasses?.breadcrumbSepIcon} ${currDarkThemeClass}`,
    };


    const breadcrumbsProps = {
        currLink: qualityplanid === NEW ? (values?.name ?? actionMsg) : (savedRes?.name ?? 'Unknown'),
        currDarkThemeClass,
        navigate,
    };

    const qPDBLinkTypoProps = {
        className: `${qualPlanClasses?.breadcrumbLinkTypo} ${currDarkThemeClass}`,
    };

    const toDisable = () => {
        if (qualityplanid === NEW) {
            if (!qualityPlanPerms?.create) return true;

            const genInfoCheck = values?.name?.trim?.()?.length > 0 && values?.description?.trim?.()?.length > 0 && values?.phaseplan;

            const attachMetricPlanCheck = values?.metrc_plans?.length > 0;

            return !(genInfoCheck && attachMetricPlanCheck);
        }

        if (!qualityPlanPerms?.update) return true;
        
        const genInfoValid = values?.name?.trim?.()?.length > 0 && values?.description?.trim?.()?.length > 0 && values?.phaseplan;
        const genInfoChange = ( values?.name !== savedRes?.name ) || ( values?.description !== savedRes?.description ) || ( values?.phaseplan !== savedRes?.phaseplan );

        const genInfoCheck = genInfoValid && genInfoChange;

        const attachMetricPlanValid = values?.metrc_plans?.length > 0;
        
        const savedMetrcPlans = savedRes?.metrc_plans?.map(e => e?.id);
        const valuesMetrcPlans = values?.metrc_plans?.map(e => e?.id);

        const attachMetricPlanChange = savedMetrcPlans?.some(e => !valuesMetrcPlans?.includes(e)) || valuesMetrcPlans?.some(e => !savedMetrcPlans?.includes(e))
        const attachMetricPlanCheck = attachMetricPlanValid && attachMetricPlanChange;

        return !( (genInfoCheck && attachMetricPlanValid) || (attachMetricPlanCheck && genInfoValid) );

    };

    const handleQPSave = () => {
        if (toDisable()) return;

        handleSetLoading('save', true);

        let errConfig = {};

        const payload = {
            name: values?.name,
            description: values?.description,
            metrc_plans: values?.metrc_plans?.map(e => e.id),
            phaseplan: values?.phaseplan,
        };
        
        if (qualityplanid === NEW) {
            
            errConfig = {
                create: true,
                resource: 'Quality Plan',
            };

            const entity = listQualityPlanEndpoint
            
            APIService.createInstance(entity, payload)
            .then((response) => { handleQPCreateRes(response);
              feedbackCX.setContextValue(true, {
                type: 'success',
                message: 'Created Quality Plan successfully.',
                autoHideDuration: 3000,
              });
            })
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
            .finally(handleFinally);

            return;
        }
        

        const entityWithID = detailQualityPlanEndpoint(qualityplanid);

        APIService.modifyInstance(entityWithID, payload)
        .then((response) => { handleQPRes(response);
            feedbackCX.setContextValue(true, {
              type: 'success',
              message: 'Updated Quality Plan successfully.',
              autoHideDuration: 3000,
            });
          })
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

    };

    const qPDProgressProps = {
        size: 16,
        style: {
            color: theme.palette.grey['500'],
        },
    };

    const qPDSaveBtnProps = {
        children: qualityplanid === NEW ? `Create ${values?.name ?? 'Quality Plan'}`: 'Save Details',
        className: `${qualPlanClasses?.saveBtn} ${currDarkThemeClass} roomDetailTabsContent`,
        disabled: loading?.save || loading?.data || toDisable(),
        onClick: handleQPSave,
        startIcon: loading?.save && <CircularProgress {...qPDProgressProps} />,
    };

    const qPDTabsProps = {
        className: `${qualPlanClasses?.tabs} ${currDarkThemeClass} roomDetailTabsContent`,
        value: tabValue ?? defaultTab,
        onChange: handleSetTabValue,
    };

    const qPDTabProps = {
        className: `${qualPlanClasses?.tab} ${currDarkThemeClass}`,
    };

    const tabList = tabEntityMap.filter(tab => qualityplanid === NEW ? tab?.validForNew : true).map((e, i) => 
        <Tab key={i} {...qPDTabProps} label={e.label} value={e.key} />
    );

    const qPDDividerProps = {
        className: `${qualPlanClasses?.divider} ${currDarkThemeClass}`,
        sx: {
            backgroundColor: 'grey.900',
        },
    };

    const qPDContentBoxProps = {
        className: `${qualPlanClasses?.contentBox} ${currDarkThemeClass}`,
    };

    const downloadMsgProps = {
        currDarkThemeClass,
    };

    const qPDTabTextFieldProps = ({prop, inputProps={}, otherTextProps={}}) => ({
        autoComplete: 'off',
        className: `${qualPlanClasses?.tabTextField} ${currDarkThemeClass}`,
        disabled: ( qualityplanid !== NEW && !qualityPlanPerms?.update ) || loading?.save || loading?.data,
        fullWidth: true,
        inputProps,
        InputLabelProps: {
            ...(prop === 'description' && {
                shrink: true,
            }),
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        required: true,
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const qPDOverviewWrapperBoxProps = {
        className: `${qualPlanClasses?.overviewWrapperBox} ${currDarkThemeClass}`,
    };
    
    if (logMsgToConsole?.qualityplan) {
        console.log('savedRes');console.log(savedRes);
        // console.log('values');console.log(values);
        // console.log(`values?.expandTimelineChart: ${values?.expandTimelineChart}`);
    }

    // Timeline Chart calculations
    const timelineChartEntries = {
        daysRowContent: [],
        phasesRowContent: [],
        weekPlanRowContent: [],
    };

    let startWeek, endWeek, weekCount, daysEntries = [], weekEntries = [], planEntries = [], startDay, endDay, startDayDiff, endDayDiff, gridTemplateColumns = '', daysGridTemplateColumns = '', currGridTempVal = '', planWeekCount;

    savedRes?.metrc_plans?.[0]?.metriccycles?.forEach(e => {
        // const basePhaseCheck = values?.expandTimelineChart && values?.expandKey === e?.cycle_name;
        const basePhaseCheck = values?.expandTimelineChart && values?.expandKey === e?.subphase?.name;
        // const baseDaysCheck = basePhaseCheck && values?.expandRow === DAYS_POS;

        timelineChartEntries.phasesRowContent.push({ 
            otherClass: basePhaseCheck ? qualPlanClasses?.selected: '',
            // value: e?.cycle_name,
            value: e?.subphase?.name,
        });

        // startWeek = Math.ceil(e?.start_day / 7);
        startWeek = Math.ceil(e?.subphase?.start_day / 7);
        // endWeek = Math.ceil(e?.end_day / 7);
        endWeek = Math.ceil(e?.subphase?.end_day / 7);

        weekCount = endWeek - startWeek + 1;
        
        // startDayDiff = (e?.start_day - 1) % 7;
        startDayDiff = (e?.subphase?.start_day - 1) % 7;
        // endDayDiff = e?.end_day % 7;
        endDayDiff = e?.subphase?.end_day % 7;

        // Setting values for rowHeader === 'Days'
        // if (baseDaysCheck) {
        if (basePhaseCheck) {
            weekEntries = Array.from({length: weekCount}).map((_, idx) => ({ 
                otherClass: qualPlanClasses?.chartValueHover,
                otherValueBoxProps: {
                    onClick: () => {
                        if (basePhaseCheck) { // This will cause closure issues when moved outside this function; so we must pass values inside that function as well
                            handleSetValues('expandTimelineChart', false);
                        }
                    },
                },
                value: `Week ${startWeek + idx}`,
            }));

            timelineChartEntries.weekPlanRowContent.push(...weekEntries);

            if (values?.showExpandedChart) {
                daysEntries = Array.from({length: weekCount}).map((_, idx) => {
                    
                    // startDay = e?.start_day - ( ( idx !== 0 && startDayDiff !== 0 ) ? startDayDiff : 0 ) + (idx * 7);
                    startDay = e?.subphase?.start_day - ( ( idx !== 0 && startDayDiff !== 0 ) ? startDayDiff : 0 ) + (idx * 7);
                                
                    // endDay = ( idx === weekCount - 1 ) ? e?.end_day : (idx === 0 && startDayDiff !== 0) ? startDay + (6 - startDayDiff) : startDay + 6;
                    endDay = ( idx === weekCount - 1 ) ? e?.subphase?.end_day : (idx === 0 && startDayDiff !== 0) ? startDay + (6 - startDayDiff) : startDay + 6;
        
                    return ({
                        otherClass: qualPlanClasses?.chartValueHover,
                        otherValueBoxProps: {
                            onClick: () => {
                                // if (values?.expandTimelineChart && values?.expandRow === DAYS_POS && values?.expandCol === idx + 1 && values?.expandKey === e?.cycle_name) {
                                // if (baseDaysCheck) { // This will cause closure issues when moved outside this function; so we must pass values inside that function as well
                                if (basePhaseCheck) { // This will cause closure issues when moved outside this function; so we must pass values inside that function as well
                                    handleSetValues('expandTimelineChart', false);
                                }
                            },
                        },
                        value: `Days: ${startDay} - ${endDay}`,
                    });
                })
            }
        } else {
            timelineChartEntries.weekPlanRowContent.push({ 
                otherClass: qualPlanClasses?.chartValueHover,
                otherValueBoxProps: {
                    onClick: () => {
                        handleSetValues('expandTimelineChart', true);
                        
                        // To be used when days will expand
                        // handleSetValues('expandRow', DAYS_POS);
                        // handleSetValues('expandCol', index + 1); // always adding 1 since we are currently not allowing expanding based on rowHeader click    
                        
                        // handleSetValues('expandKey', e?.cycle_name);
                        handleSetValues('expandKey', e?.subphase?.name);
                    },
                },
                value: `Week ${startWeek} - ${endWeek}`,
            });

            if (values?.showExpandedChart) {
                daysEntries = [{
                    otherClass: qualPlanClasses?.chartValueHover,
                    otherValueBoxProps: {
                        onClick: () => {
                            handleSetValues('expandTimelineChart', true);
                            
                            // To be used when days will expand
                            // handleSetValues('expandRow', DAYS_POS);
                            // handleSetValues('expandCol', index + 1); // always adding 1 since we are currently not allowing expanding based on rowHeader click    
                            
                            // handleSetValues('expandKey', e?.cycle_name);
                            handleSetValues('expandKey', e?.subphase?.name);
                        },
                    },
                    // value: `Days ${e?.start_day} - ${e?.end_day}`,
                    // value: `Days ${e?.subphase?.start_day} - ${e?.end_day}`,
                    value: `Days ${e?.subphase?.start_day} - ${e?.subphase?.end_day}`,
                }]

            }
        }

        // daysEntries = Array.from({length: weekCount}).map((_, idx) => {
            
        //     // if (values?.expandTimelineChart && values?.expandRow === DAYS_POS && values?.expandCol === idx + 1) {
        //     // if (values?.expandTimelineChart && values?.expandRow === DAYS_POS && values?.expandCol === idx + 1 && values?.expandKey === e?.cycle_name) {
        //     if (baseDaysCheck && values?.expandCol === idx + 1) {
        //         console.log(`expanded in ${e?.cycle_name}`);
        //     }

        //     startDay = e?.start_day - ( ( idx !== 0 && startDayDiff !== 0 ) ? startDayDiff : 0 ) + (idx * 7);
                        
        //     endDay = ( idx === weekCount - 1 ) ? e?.end_day : (idx === 0 && startDayDiff !== 0) ? startDay + (6 - startDayDiff) : startDay + 6;

        //     return ({
        //         otherValueBoxProps: {
        //             onClick: () => {
        //                 // if (values?.expandTimelineChart && values?.expandRow === DAYS_POS && values?.expandCol === idx + 1 && values?.expandKey === e?.cycle_name) {
        //                 if (baseDaysCheck && values?.expandCol === idx + 1) { // This will cause closure issues when moved outside this function; so we must pass values inside that function as well
        //                     handleSetValues('expandTimelineChart', false);
        //                 } else {
        //                     handleSetValues('expandTimelineChart', true);
        //                     handleSetValues('expandRow', DAYS_POS);
        //                     handleSetValues('expandCol', idx + 1); // always adding 1 since we are currently not allowing expanding based on rowHeader click    
        //                     handleSetValues('expandKey', e?.cycle_name);
        //                 }
        //             },
        //         },
        //         value: `Days: ${startDay} - ${endDay}`,
        //     });
        // })
        
        timelineChartEntries.daysRowContent.push(...daysEntries);

        currGridTempVal = `${(weekCount * baseTimelineChartBoxWd) + ((weekCount - 1) * baseTimelineChartColGap)}px `;

        // gridTemplateColumns += `${(weekCount * baseTimelineChartBoxWd) + ((weekCount - 1) * baseTimelineChartColGap)}px `; // It must be ensured that baseTimelineChartBoxWd is able to contain all the data, otherwise there will be misalignment
        // gridTemplateColumns += basePhaseCheck ? currGridTempVal : `${baseTimelineChartBoxWd}px `;
        gridTemplateColumns += basePhaseCheck ? currGridTempVal : `minmax(${baseTimelineChartBoxWd}px, 1fr) `;

        // daysGridTemplateColumns += baseDaysCheck 
        daysGridTemplateColumns += basePhaseCheck 
            // ? Array.from({length: weekCount}).map(_ => `minmax(${baseTimelineChartBoxWd}, 1fr)`).join(' ')
            // ? `repeat(${weekCount}, minmax(${baseTimelineChartBoxWd}px, 1fr)) `
            ? `repeat(${weekCount}, ${baseTimelineChartBoxWd}px) `
            : `minmax(${baseTimelineChartBoxWd}px, 1fr) `;
    });

    const otherPhaseRowContentSxProps = {
        gridTemplateColumns,
    };

    const otherRowContentSxProps = {
        gridTemplateColumns: daysGridTemplateColumns,
    }

    const otherDaysRowContentSxProps = {
        gridTemplateColumns: daysGridTemplateColumns,
    }

    const prevPos = Object.keys(timelineChartEntries).length;

    const metricPlanChartEntries = savedRes?.metrc_plans?.map((e, i) => {
        const currPos = prevPos + i;

        // const rowContent = e?.metriccycles?.map((el, idx) => ({ 
        //     otherValueBoxProps: {
        //         onClick: () => {
        //             console.log(`expandRow: ${currPos}, expandCol: ${idx + 1}`)
                    
        //             handleSetValues('expandTimelineChart', true);
        //             handleSetValues('expandRow', prevPos + i + 1);
        //             handleSetValues('expandCol', idx + 1); // always adding 1 since we are currently not allowing expanding based on rowHeader click
        //             // if (!values?.expandTimelineChart) {
        //             //     handleSetValues('expandTimelineChart', true);
        //             //     handleSetValues('expandRow', prevPos + i + 1);
        //             //     handleSetValues('expandCol', idx + 1); // always adding 1 since we are currently not allowing expanding based on rowHeader click
        //             // }
                    
        //         },
        //     },
        //     value: `L: ${el?.low} - H: ${el?.high}`,
        // }));

        const rowContent = e?.metriccycles?.reduce((res, val) => {
            
            // if (values?.expandTimelineChart && values?.expandKey === val?.cycle_name) {
            if (values?.expandTimelineChart && values?.expandKey === val?.subphase?.name) {
                // startWeek = Math.ceil(val?.start_day / 7);
                startWeek = Math.ceil(val?.subphase?.start_day / 7);
                // endWeek = Math.ceil(val?.end_day / 7);
                endWeek = Math.ceil(val?.subphase?.end_day / 7);
                
                planWeekCount = endWeek - startWeek + 1;
                
                // console.log(`startW: ${startWeek}, endW: ${endWeek}, planWeekCount: ${planWeekCount}`);
                planEntries = Array.from({length: planWeekCount})
                    .map(_ => ({ value: e?.is_target ? `T: ${Number(val?.high)} (+/- ${Number(val?.control_limit) ?? 0})` : `L: ${Number(val?.low)} - H: ${Number(val?.high)}`}));

                res.push(...planEntries);
            } else {
                res.push({ value: e?.is_target ? `T: ${Number(val?.high)} (+/- ${Number(val?.control_limit) ?? 0})` : `L: ${Number(val?.low)} - H: ${Number(val?.high)}` });
            }

            return res;
        }, []);

        const rowHeaderTypoProps = {
            currDarkThemeClass,
            value: e?.name,
        };

        // return ({ pos: prevPos + i + 1, rowHeader: e?.name ?? ' ', rowContent, otherRowContentSxProps });
        // return ({ pos: currPos, rowHeader: <RowHeaderTypo {...rowHeaderTypoProps} />, rowContent, otherRowContentSxProps: otherDaysRowContentSxProps });
        return ({ pos: currPos, rowHeader: { name: <RowHeaderTypo {...rowHeaderTypoProps} /> }, rowContent, otherRowContentSxProps });
        // return ({ pos: currPos, rowHeader: <RowHeaderTypo {...rowHeaderTypoProps} />, rowContent });
    }) || [];

    const handleChartWeekHeaderClick = () => {
        handleSetValues('showExpandedChart', !values?.showExpandedChart);
    };

    const arrowIconProps = {
        className: `${qualPlanClasses?.chartWeekIcon} ${currDarkThemeClass} ${values?.showExpandedChart ? qualPlanClasses?.chartExpanded : ''}`,
    };

    const timelineChartProps = {
        currDarkThemeClass,
        otherClasses: {
            box: qualPlanClasses?.timelineChartBox,
        },
        entries: [

            { pos: PHASES_POS, rowHeader: {name: 'Phases'}, rowContent: timelineChartEntries.phasesRowContent, otherRowContentSxProps: otherPhaseRowContentSxProps, },
            
            { pos: WEEK_POS, rowHeader: { name: <>Week of Plan <DownArrowIcon {...arrowIconProps} /></>, otherHeaderBoxProps: { onClick: handleChartWeekHeaderClick }, otherClass: qualPlanClasses?.chartValueHover }, rowContent: timelineChartEntries.weekPlanRowContent, otherRowContentSxProps },

            ...(values?.showExpandedChart ? [
                { pos: DAYS_POS, rowHeader: { name: 'Days' }, rowContent: timelineChartEntries.daysRowContent, otherRowContentSxProps: otherDaysRowContentSxProps },
            
                ...metricPlanChartEntries,    
            ] : []),

        ],
        title: 'Cultivation Phase',
    };

    const qPDOverviewBoxProps = {
        className: `${qualPlanClasses?.overviewBox} ${currDarkThemeClass}`,
    };

    // const metrcTypes = savedRes?.metrcplans
    //     ?.filter(e => savedRes?.metrc_plans?.includes(e.id))
    //     ?.map(e => e?.metrc_type?.type)
    //     ?.join(', ');

    const metrcTypes = [
        ...(new Set(savedRes?.metrc_plans?.map(e => e?.unit?.name)) )
    ].join(', ');

    const phasePlanLinkProps = {
        className: `${qualPlanClasses?.phasePlanLinkIcon} ${currDarkThemeClass}`,
        // onClick: () => navigate(`${phasePlanNewAndDetailRoute}/${id}?tab=overview`),
    };
    
    const phaseplanLinkBoxProps = {
        className: `${qualPlanClasses?.phasePlanLinkBox} ${currDarkThemeClass}`,
        component: 'a',
        href: `${phasePlanNewAndDetailRoute}/${savedRes?.phaseplan}?tab=overview`,
        target: '_blank',
    };

    const phaseplanLink = <Box {...phaseplanLinkBoxProps}><ExternalLinkIcon {...phasePlanLinkProps}/></Box>

    const qPDGenInfoCardProps = {
        cardEntries: [
            {key: 'Name', value: savedRes?.name ?? ' ', icon: PlansIcon},
            {key: 'Phase', value: <>{ savedRes?.phaseplans?.find(e => e?.id === savedRes?.phaseplan)?.name ?? ' ' } {phaseplanLink}</>, icon: PlansIcon},
            {key: 'Metrics Count', value: savedRes?.metrc_plans?.length ?? 0, icon: PlansIcon},
            {key: 'Attached Metric Types', value: metrcTypes || 'None', icon: PlansIcon},
        ],
        cardTitle: 'General Info',
        currDarkThemeClass,
        onClick: () => handleSetTabValue(null, 'general-info'),
        otherBoxProps: {
            gridArea: 'a',
        },
        toAllowEdit: qualityplanid !== NEW && qualityPlanPerms?.update,
    };

    const qPDDescCardProps = {
        cardEntries: [
            {value: savedRes?.description ?? 'No Description Available'},
        ],
        cardTitle: 'Plan Description',
        currDarkThemeClass,
        onClick: () => handleSetTabValue(null, 'general-info'),
        otherBoxProps: {
            gridArea: 'b',
        },
        toAllowEdit: qualityplanid !== NEW && qualityPlanPerms?.update,
    };

    const handleMetricPlanUnlinkRes = res => {
        handleQPRes(res);

        return Promise.resolve({success: true});
    }

    const handleUnlink = id => setLoadingInPopup => async () => {
        if (isNaN(+qualityplanid)) return;

        setLoadingInPopup(true);

        let errConfig = {};

        const payload = {
            metrc_plans: values?.metrc_plans?.filter(e => +e.id !== +id)?.map(e => +e?.id)
        };

        const entityWithID = `qualityplan/${qualityplanid}/`;


        let res = {success: false};

        try {
            res = await APIService.modifyInstance(entityWithID, payload)
                                  .then(handleMetricPlanUnlinkRes);            
        } catch (err) {
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }

        return res;
    }

    const handleMetrcPlanUnlink = (id, name) => () => {
        handleSetValues('delete', true);

        setOpen(true);

        setDetailChildren({
            title: `Unlinking ${name}`,
            onProceed: handleUnlink(id),
        });
    };

    const getDaysCount = (result, val, idx, arr) => {
        // if ( (!result?.start_day && result?.start_day !== 0 ) || +result.start_day > +val.start_day) {
        if ( (!result?.start_day && result?.start_day !== 0 ) || +result.start_day > +val.subphase?.start_day) {
            // result.start_day = val.start_day;
            result.start_day = +val.subphase?.start_day;
        } 

        // if ( (!result?.end_day && +result?.end_day !== 0) || +result.end_day < +val.end_day) {
        if ( (!result?.end_day && +result?.end_day !== 0) || +result.end_day < +val.subphase?.end_day) {
            // result.end_day = val.end_day;
            result.end_day = +val.subphase?.end_day;
        }
        result.days_count = result.end_day - result.start_day;
        
        result.days_count += idx === (arr.length - 1) ? 1 : 0;

        return result;
    };

    const initDaysCount = {start_day: null, end_day: null};

    const expandCardProps = (e) => {
        // Extract the number of metric runs
        const metricRunsCount = e?.metrc_runs?.length ?? 0;
    
        // Generate the chart options based on the number of metric runs
        const options = {
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                height: 50,  // Adjust height to minimize space around the bar
                margin: [0, 0, 0, 0],  // Remove margin around the chart
                spacing: [0, 0, 0, 0],  // Remove spacing around the chart
            },
            title: {
                text: null  // Remove the chart title
            },
            xAxis: {
                visible: false  // Hide the x-axis
            },
            yAxis: {
                visible: false,  // Hide the y-axis
                max: metricRunsCount,
            },
            plotOptions: {
                series: {
                    stacking: 'normal',  // Enable stacking
                    pointWidth: 15,      // Reduce the width of the bar
                    dataLabels: {
                        enabled: true,  // Enable data labels
                        inside: true,   // Place data labels inside the bars
                        format: `{series.name}`,
                        style: {
                            color: '#ffffff',  // Data label color
                            textOutline: 'none'  // Remove text outline
                        }
                    },
                    borderWidth: 0,   // Remove border around the bars
                    shadow: false,    // Remove shadow around the bars
                    states: {
                        hover: {
                            enabled: false  // Disable hover state
                        }
                    }
                }
            },
            credits:{
                enabled: false
            },
            legend: {
                enabled: false  // Hide the legend
            },
            tooltip: {
                pointFormatter: function() {
                    return this.series.name;
                }
            },
            series: e?.metrc_runs?.map((run, index) => ({
                name: run.name ?? `Run ${index + 1}`,  // Use run name or fallback to default
                data: [1]  // Each segment represents a single metric run
            })) ?? []
        };
    
        return {
            actions: [
                ...(metricPlanPerms?.read ? [{ children: 'Edit', icon: EditIcon, onClick: () => navigate(getMetricPlanRoute(e?.id)) }] : []),
                ...(qualityplanid !== NEW && qualityPlanPerms?.update ? [{ children: 'Unlink', icon: UnlinkIcon, onClick: handleMetrcPlanUnlink(e?.id, e?.name) }] : []),
            ],
            cardHeaderInfo: `${metricRunsCount} Metric Runs Attached`,
            cardIcon: PlansIcon,
            cardTitle: e?.name,
            currDarkThemeClass,
            entries: [
                { key: 'Metrc Type', value: e?.unit?.name ?? ' ' },
                // {key: 'Cycle Count', value: e?.metriccycles?.length ?? 0},
                // {key: 'Days Count', value: e?.metriccycles?.reduce(getDaysCount, initDaysCount)?.days_count ?? ' '},
            ],
            chart: <HighchartsReact highcharts={Highcharts} options={options} />
        };
    };
    
     
    // const metricPlanEntries = savedRes?.metrcplans?.filter(e => savedRes?.metrc_plans?.includes(e.id))
    //     ?.map((e, i) => ({ value: <ExpandCard key={i} {...expandCardProps(e)} /> })) || [];

    const metricPlanEntries = savedRes?.metrc_plans?.map(
        (e, i) => ({ value: <ExpandCard key={i} {...expandCardProps(e)} /> })
    ) || [];


    const qPDAttachedPlansCardProps = {
        editLink: () => handleSetTabValue(null, 'attach-metrics'),

        cardTitleMarginBottom: '28px',
        currDarkThemeClass,
        entries: metricPlanEntries?.length > 0 ? metricPlanEntries : [{value: <NoEntityMsg msg='No Metrics Attached'/>}] ,
        otherCardPaperSxProps: {
            gridArea: 'c',
        },
        otherEntriesBoxSxProps: {
            height: `${maxAccordionCardsBoxHt}px`,
            overflow: 'auto',
        },
        title: 'Attached Metrics',
        toShowEditBtn: qualityplanid !== NEW && qualityPlanPerms?.update,
    };

    const qualPlanOverview = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...qPDOverviewWrapperBoxProps}>
                {/* 17112023 disabled because metric plan is now independent of phase plan */}
                {/* <TimelineChart {...timelineChartProps} /> */}
                <Box {...qPDOverviewBoxProps}>
                    <PlansDetailInfoCard {...qPDGenInfoCardProps} />
                    <PlansDetailInfoCard {...qPDDescCardProps} />
                    <DetailInfoCard {...qPDAttachedPlansCardProps} />
                </Box>
            </Box>
        </Fade>
    );

    const qPDGenInfoBoxProps = {
        className: `${qualPlanClasses?.genInfoBox} ${currDarkThemeClass} roomDetailTabsContent`,
    };

    const qPDTabTitleTypoProps = {
        className: `${qualPlanClasses?.tabTitleTypo} ${currDarkThemeClass}`,
    };

    const qPDTabSubTypoProps = {
        className: `${qualPlanClasses?.tabSubTypo} ${currDarkThemeClass}`,
    };

    const qualPlanNameProps = {
        otherTextProps: {
            autoFocus: true,
            label: 'Quality Plan Name',
        },
        prop: 'name',
    };

    const qualPlanPhaseProps = {
        otherTextProps: {
            label: 'Phase Plan',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },

            onChange: ({target: {value}}) => {
                handleSetValues('phaseplan', value);
                
                if (qualityplanid && !isNaN(+qualityplanid)) {
                    handleSetValues('metrcplans', savedRes?.metrcplans) // Available Metrc Plans
                    
                    if (+value === +savedRes?.phaseplan) {
                        handleSetValues('metrc_plans', savedRes?.metrc_plans); // Attached Metrics
                    } else {
                        handleSetValues('metrc_plans', []); // Attached Metrics
                    }

                } else {
                    handleSetValues('metrcplans', savedRes?.metrcplans) // Available Metrc Plans
                    handleSetValues('metrc_plans', []); // Attached Metrc Plans
                }
            },
        },
        prop: 'phaseplan',
    };

    const qPDPhaseMenuItemProps = {
        className: `${qualPlanClasses?.phaseplanMenuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: `${miscClasses?.menuItemFocusDark}`,
    };

    const phasePlansList = 
        savedRes?.phaseplans?.length > 0 
            ? savedRes?.phaseplans?.map( (e, i) => <MenuItem key={i} value={e?.id} {...qPDPhaseMenuItemProps} >{e?.name}</MenuItem> )
            : <MenuItem key={-1} value='' disabled {...qPDPhaseMenuItemProps}>No Available Phases</MenuItem>

    const qualPlanDescProps = {
        otherTextProps: {
            label: 'Description',
            minRows: 6,
            maxRows: 6,
            multiline: true,
            placeholder: 'What is this Plan about?',
        },
        prop: 'description',
    };

    const qualPlanGenInfo = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...qPDGenInfoBoxProps}>
                <Typography {...qPDTabTitleTypoProps}>{qualPlanActionTitle}</Typography>
                {/* <Typography {...qPDTabSubTypoProps}>{qualPlanActionDesc}</Typography> */}
                <TextField {...qPDTabTextFieldProps(qualPlanNameProps)} />
                <TextField {...qPDTabTextFieldProps(qualPlanPhaseProps)}>{phasePlansList}</TextField>
                <TextField {...qPDTabTextFieldProps(qualPlanDescProps)} />
            </Box>
        </Fade>
    );

    const qPDAttachMetrcPlansBoxProps = {
        className: `${qualPlanClasses?.attachMetricPlansBox} ${currDarkThemeClass}`,
    };

    const qPDSelPhaseTypoProps = {
        className: `${qualPlanClasses?.selPhaseTypo} ${currDarkThemeClass}`,
    };

    const qPDAttachMsgTypoProps = {
        className: `${qualPlanClasses?.attachMsgTypo} ${currDarkThemeClass}`,
    };

    const qPDDragAndDropBoxProps = {
        className: `${qualPlanClasses?.dragAndDropBox} ${currDarkThemeClass}`,
    };
    
    const qPDDragAndDropEndBoxProps = {
        className: `${qualPlanClasses?.dragAndDropEndBox} ${currDarkThemeClass}`,
    };

    const qPDMyQPTypoProps = {
        className: `${qualPlanClasses?.myQPTypo} ${currDarkThemeClass}`,
    };

    const qPDMyQPSubTypoProps = {
        className: `${qualPlanClasses?.myQPSubTypo} ${currDarkThemeClass}`,
    };
    
    const handleDrop = e => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    };

    const handleAttachDrag = (e) => {
        e.preventDefault();

        OnClickAttach(values?.dragItem)();
    }

    const qPDAttachedMetrcPlanBoxProps = {
        className: `${qualPlanClasses?.attachedMetricPlansBox} ${currDarkThemeClass} customScroll`,
        id: 'attached-mp-drop-target',
        onDrop: handleDrop,
        onDragOver: handleAttachDrag,
    };
    
    // Disable clicks while loading?
    const OnClickDetach = id => () => {
        const elem = values?.metrc_plans?.find(e => e.id === id);
        
        if (!elem) return;
        
        handleSetValues('metrc_plans', values.metrc_plans.filter(e => e.id !== id));

        handleSetValues('metrcplans', [...( values?.metrcplans ?? [] ), elem].sort(sortByID)); // until drag and drop is available, we can sort after this step for consistency
    }

    const handleDragStart = id => event => {
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'move';
        
        handleSetValues('dragItem', id);
    }

    const qPDDragCardProps = e => ({
        content: getDragCardContent(e, currDarkThemeClass),
        currDarkThemeClass,
        id: `drag-mp-${e.id}`,
        title: e?.name,
        
        onDragStart: handleDragStart(e.id),
    });

    const attachedMetrcPlanList = values?.metrc_plans?.map(e => 
        <DragAccordionCard key={e?.id} {...qPDDragCardProps(e)} />
    ) || [];
    
    const qPDDragAndDropMiddleBoxProps = {
        className: `${qualPlanClasses?.dragAndDropMiddleBox} ${currDarkThemeClass}`,
    };

    const qPDDragIconProps = {
        className: `${qualPlanClasses?.dragIcon} ${currDarkThemeClass}`,
    };

    const qPDDragInfoTypoProps = {
        className: `${qualPlanClasses?.dragInfoTypo} ${currDarkThemeClass}`,
    };

    const qPDDragAndDropStartBoxProps = {
        className: `${qualPlanClasses?.dragAndDropStartBox} ${currDarkThemeClass}`,
    };

    const qPDCreatedMPTypoProps = {
        className: `${qualPlanClasses?.createdMPTypo} ${currDarkThemeClass}`,
    };

    const qPDCreatedMPSubTypoProps = {
        className: `${qualPlanClasses?.createdMPSubTypo} ${currDarkThemeClass}`,
    };

    const handleUnattachDrag = e => {
        e.preventDefault();
        

        OnClickDetach(values?.dragItem)();
    };

    const qPDCreatedMetrcPlanBoxProps = {
        className: `${qualPlanClasses?.createdMetricPlanBox} ${currDarkThemeClass}`,
        id: 'unlinked-mp-drop-target',
        onDrop: handleDrop,
        onDragOver: handleUnattachDrag,
    };

    const OnClickAttach = id => () => {
        const elem = values?.metrcplans?.find(e => e.id === id);

        if (!elem) return;
        
        handleSetValues('metrcplans', values.metrcplans.filter(e => e.id !== id));
        handleSetValues('metrc_plans', [...( values?.metrc_plans ?? [] ), elem]); // until drag and drop is available, we can sort after this step for consistency
    }

    const metrcPlanList = filteredMetrcPlans
        ?.map( e => <DragAccordionCard key={e?.id} {...qPDDragCardProps(e)} /> ) // using e?.id for key to avoid duplicates; since the list changes and some other element inherits the state for the key that was removed
        || [];

    const qualPlanAttachMetrcPlans = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...qPDAttachMetrcPlansBoxProps}>
                {
                    !values?.phaseplan ? <Typography {...qPDSelPhaseTypoProps}>{selPhasePlanMsg}</Typography>
                    : <>
                        <Typography {...qPDAttachMsgTypoProps}>{attachMsg}</Typography>
                        <Box {...qPDDragAndDropBoxProps}>

                            <Box {...qPDDragAndDropStartBoxProps}>
                                <Typography {...qPDCreatedMPTypoProps}>{createdMPMsg}</Typography>
                                <Typography {...qPDCreatedMPSubTypoProps}>{createdMPSubMsg}</Typography>
                                <Box {...qPDCreatedMetrcPlanBoxProps}>
                                    {metrcPlanList}
                                </Box>
                            </Box>

                            <Box {...qPDDragAndDropMiddleBoxProps}>
                                <DragMetrcPlanIcon {...qPDDragIconProps}/>
                                {/* <Typography {...qPDDragInfoTypoProps}>Drag and drop Metrics from left column to right column.</Typography> */}
                                <Typography {...qPDDragInfoTypoProps}>Drag and drop Metrics from left column to right column.</Typography>
                            </Box>


                            <Box {...qPDDragAndDropEndBoxProps}>
                                <Typography {...qPDMyQPTypoProps}>{values?.name ?? 'Your Quality Plan'}</Typography>
                                <Typography {...qPDMyQPSubTypoProps}>{myQPSubMsg}</Typography>
                                <Box {...qPDAttachedMetrcPlanBoxProps}>
                                    {attachedMetrcPlanList}
                                </Box>
                            </Box>

                        </Box>
                    </>
                }
            </Box>
        </Fade>
    );
    
    const handlePopupClose = () => {
        setDetailChildren({});
        setOpen(false);
    };
    
    const qPDPopupProps = {
        currDarkThemeClass,
        open,

        onClose: handlePopupClose,
        
        ...detailChildren,
    };

    return (
        <Box {...qPDBoxProps}>
            <Box {...qPDHeaderBoxProps}>
                <Box {...qPDHeaderTitleBoxProps}>

                <Fade in={true} timeout={fadeTimingMs}>
                    <Typography {...qPDActionTypoProps}>
                        {
                            qualityplanid === NEW ? actionMsg : (savedRes?.name ?? 'Unknown')
                        }
                    </Typography>
                </Fade>

                <QualityPlanBreadcrumbs {...breadcrumbsProps} />
                </Box>
                { 
                    tabValue !== 'overview' && (qualityplanid === NEW ? qualityPlanPerms?.create : qualityPlanPerms?.update) &&
                    <Button {...qPDSaveBtnProps} /> 
                }
            </Box>

            <Fade in={true} timeout={fadeTimingMs}>
                <Box>
                    <Tabs {...qPDTabsProps}>
                        {tabList}
                    </Tabs>
                    <Divider {...qPDDividerProps}/>

                    <Box {...qPDContentBoxProps}>
                        {
                            ( qualityplanid !== NEW && loading?.data ) ? <DownloadingMetrcDataMsg {...downloadMsgProps} />
                            : (
                                tabValue === 'overview' ? qualPlanOverview
                                : tabValue === 'general-info' ? qualPlanGenInfo
                                : tabValue === 'attach-metrics' ? qualPlanAttachMetrcPlans
                                : <></>
                            )
                        }
                    </Box>
                </Box>
            </Fade>
            {
                values?.delete && <ConfirmationPopupWithLoading {...qPDPopupProps} />
            }
        </Box>
    );
}