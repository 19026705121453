import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import { BreadcrumbSeparatorIcon } from './CustomSvgIcons';

import {
    configurationsHomeRoute,
    cropPlanHomeRoute, cropRunHomeRoute, cropsHomeRoute, miscClasses, organizationHomeRoute, plansHomeRoute, plansHomeWithTabRoute,
} from './constants';


const linkProps = {
    component: 'span',
    sx: {
        color: 'grey.600',
        fontSize: '14px', // 14
        letterSpacing: '-0.05em',
        textDecoration: 'none',
        '&:hover': {
            color: 'grey.600',
            cursor: 'pointer',
            textDecoration: 'none',
        },
    },
};

const CommandViewBreadcrumbs = (props) => {
    const {
      currDarkThemeClass = '',
      navigate,
      linksList = [],
      onClose // Added onClose prop to handle closing CommandView
    } = props;
  
    const breadcrumbBoxProps = {
      className: `${miscClasses?.breadcrumb?.box} ${currDarkThemeClass}`,
    };
  
    const breadcrumbEntryProps = (entry) => ({
      className: `${miscClasses?.breadcrumb?.entryBox} ${currDarkThemeClass}`,
      onClick: () => {
        if (entry?.to === '/command-center') {
          onClose(); // Call onClose when "Command Center" is clicked
          navigate?.(entry?.to); // Navigate to "Command Center"
        } else if (entry?.to === null) {
          // Do nothing for "Command View" link
        } else {
          navigate?.(entry?.to); // Navigate normally for other links
        }
      },
    });
  
    const breadcrumbSepIconProps = {
      className: `${miscClasses?.breadcrumb?.sepIcon} ${currDarkThemeClass}`,
    };
  
    const breadcrumbLinks = linksList.map((e, i) =>
      <Fragment key={i}>
        <Box {...breadcrumbEntryProps(e)}>{e?.title}</Box>
        {i < linksList?.length - 1 && <BreadcrumbSeparatorIcon {...breadcrumbSepIconProps} />}
      </Fragment>
    );
  
    return (
      <Box {...breadcrumbBoxProps}>
        {breadcrumbLinks}
      </Box>
    );
  };
  
  export const CommandViewBreadcrumbsComponent = (props) => {
    const linksList = [
      { pos: 1, title: 'Command Center', to: '/command-center' },
      { pos: 2, title: 'Command View', to: null },
    ];
  
    const factoryProps = {
      ...props,
      linksList,
    };
  
    return <CommandViewBreadcrumbs {...factoryProps} />;
  };

// const nonLinkProps = {
//     component: 'span',
//     sx: {
//         color: 'grey.600',
//         fontSize: '14px', // 14
//         letterSpacing: '-0.05em',
//     },
// };

const cropsTitle = 'Crops';
const cropPlansTitle = 'Crop Plans';
const cropRunsTitle = 'Crop Runs';
const cropPlansTabTitle = 'My Crop Plans';
const cropRunsTabTitle = 'My Crop Runs';
const growPlansTabTitle = 'My Grow Plans';
const qualPlansTabTitle = 'My Quality Plans';
const metricPlansTabTitle = 'Metrics';
const defaultRoutineTabTitle = 'My Routines';
const phasePlansTabTitle = 'My Phases';
const plansTitle = 'Plans';
const configurationsTitle = 'Configurations';
const organizationsTitle = 'Organization';
const integrationTabsTitle ='Integration'
const plansHomeLink = plansHomeWithTabRoute;

const cropPlansHomeLink = `${cropsHomeRoute}?tab=plans`;
const cropRunsHomeLink = `${cropsHomeRoute}?tab=runs`;
const growPlansHomeLink = plansHomeLink;
const metricPlansHomeLink = `${configurationsHomeRoute}?tab=metrics`;
const defaultRoutinesHomeLink = `${configurationsHomeRoute}?tab=routines`;
const phasePlansHomeLink = `${configurationsHomeRoute}?tab=phases`;
const qualPlansHomeLink = `${configurationsHomeRoute}?tab=quality-plans`;

// const cropRunsHomeLink = `${cropRunHomeRoute}?tab=my-crop-runs`;
const configurationsHomeLink = `${configurationsHomeRoute}?tab=farms`;
const orgHomeLink = `${organizationHomeRoute}?tab=overview`;
const integrationLink = `${organizationHomeRoute}?tab=integrations`;
export const ConfigurationBLink = (
    <Link to="/configurations" style={{ textDecoration: 'none' }}>
        <Box {...linkProps}>Configurations</Box>
    </Link>
);

export const FarmsBLink = (
    <Link to='/configurations?tab=farms' style={{ textDecoration: 'none' }}>
        <Box {...linkProps}>Farms</Box>
    </Link>
);
// to={`/farms/farm/${farmid}/room/${roomid}/`}

export const CreateAliasRoomBLink = [
    // <Typography key={1} {...nonLinkProps}>Farms</Typography>,

    // <Link key={1} to='' style={{textDecoration: 'none', pointerEvents: 'none'}}>
    //     <Box {...nonLinkProps}>Farms</Box>
    // </Link>,
    <Link key={1} to='' style={{textDecoration: 'none'}}>
        <Box {...linkProps}>Finalizing Farm</Box>
    </Link>,
    <Link key={2} to='' style={{textDecoration: 'none'}}>
        <Box {...linkProps}>Room Setup</Box>
    </Link>
];

export const MetricplanDetailBLink = [
    <Link key={1} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropPlansTitle}</Box>
    </Link>,

    <Link key={2} to={metricPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Metric Plans</Box>
    </Link>
];

export const QualityplanDetailBLink = [
    <Link key={1} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropPlansTitle}</Box>
    </Link>,

    <Link key={2} to={qualPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Quality Plans</Box>
    </Link>
];

export const RoomDetailBLink = (farmid) => ([
    <Link key={1} to="/configurations" style={{ textDecoration: 'none' }}>
        <Box {...linkProps}>Configurations</Box>
    </Link>,

    <Link key={2} to='/configurations?tab=farms' style={{ textDecoration: 'none' }}>
        <Box {...linkProps}>Farms</Box>
    </Link>,

    <Link key={3} to= {`/configurations/farm/${farmid} `} style={{ textDecoration: 'none' }}>
        <Box {...linkProps}>Farm Details</Box>
    </Link>
]);

export const GrowplanDetailBLink = [
    <Link key={1} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropPlansTitle}</Box>
    </Link>,

    <Link key={2} to={growPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Grow Plans</Box>
    </Link>
];

export const CropplanDetailBLink = [
    <Link key={1} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropPlansTitle}</Box>
    </Link>,

    <Link key={2} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Crop Plans</Box>
    </Link>
];

export const CroprunDetailBLink = [
    <Link key={1} to={cropRunsHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropRunsTitle}</Box>
    </Link>,

    <Link key={2} to={cropRunsHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Crop Runs</Box>
    </Link>
];


export const PhaseplanDetailBLink = [
    <Link key={1} to={cropPlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>{cropPlansTitle}</Box>
    </Link>,

    <Link key={2} to={phasePlansHomeLink} style={{textDecoration: 'none'}}>
        <Box {...linkProps}>My Phase Plans</Box>
    </Link>
];


const BreadcrumbFactory = (props) => {

    const {
        currDarkThemeClass='',
        navigate,
        linksList=[],
    } = props;

    const breadcrumbBoxProps = {
        className: `${miscClasses?.breadcrumb?.box} ${currDarkThemeClass}`,
    };

    const breadcrumbEntryProps = (entry) => ({
        className: `${miscClasses?.breadcrumb?.entryBox} ${currDarkThemeClass}`,
        onClick: () => entry?.to ? navigate?.(entry?.to) : null,
    });

    const breadcrumbSepIconProps = {
        className: `${miscClasses?.breadcrumb?.sepIcon} ${currDarkThemeClass}`,
    };

    const breadcrumbLinks = linksList.map((e, i) =>
        <Fragment key={i}>
            <Box {...breadcrumbEntryProps(e)}>{e?.title}</Box>
            { i < linksList?.length - 1 && <BreadcrumbSeparatorIcon {...breadcrumbSepIconProps} /> }
        </Fragment>
    )

    return (
        <Box {...breadcrumbBoxProps}>
            {breadcrumbLinks}
        </Box>
    );
}

export const CropPlanBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: cropsTitle, to: cropRunsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: cropPlansTabTitle, to: cropPlansHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const CropRunBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: cropsTitle, to: cropRunsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: cropRunsTabTitle, to: cropRunsHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const GrowPlanBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: plansTitle, to: plansHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: growPlansTabTitle, to: growPlansHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const QualityPlanBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: configurationsTitle, to: configurationsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: qualPlansTabTitle, to: qualPlansHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const MetricPlanBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: configurationsTitle, to: configurationsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: metricPlansTabTitle, to: metricPlansHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const DefaultRoutineBreadCrumbs = (props) => {
    const {
        currLink = '',
    } = props;

    const linksList = [
        {pos: 1, title: configurationsTitle, to: configurationsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: defaultRoutineTabTitle, to: defaultRoutinesHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}

export const PhasePlanBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: configurationsTitle, to: configurationsHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: phasePlansTabTitle, to: phasePlansHomeLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}


export const IntegrationsBreadcrumbs = (props) => {
    const {
        currLink='',
    } = props;

    const linksList = [
        {pos: 1, title: organizationsTitle, to: orgHomeLink}, // 'pos' may be used for sorting
        {pos: 2, title: integrationTabsTitle, to: integrationLink},
        {pos: 3, title: currLink, to: null},
    ];

    const factoryProps = {
        ...props,
        linksList,
    };

    return <BreadcrumbFactory {...factoryProps} />
}
// export const GrowPlanBreadcrumbs = (props) => {

//     const {
//         currDarkThemeClass='',
//         currLink,
//         navigate,
//     } = props;

//     const linksList = [
//         {pos: 1, title: plansTitle, to: plansHomeLink}, // 'pos' may be used for sorting
//         {pos: 2, title: growPlansTabTitle, to: growPlansHomeLink},
//         {pos: 3, title: currLink, to: null},
//     ];

//     const breadcrumbBoxProps = {
//         className: `${miscClasses?.breadcrumb?.box} ${currDarkThemeClass}`,
//     };

//     const breadcrumbEntryProps = (entry) => ({
//         className: `${miscClasses?.breadcrumb?.entryBox} ${currDarkThemeClass}`,
//         onClick: () => entry?.to ? navigate?.(entry?.to) : null,
//     });

//     const breadcrumbSepIconProps = {
//         className: `${miscClasses?.breadcrumb?.sepIcon} ${currDarkThemeClass}`,
//     };

//     const breadcrumbLinks = linksList.map((e, i) => 
//         <Fragment key={i}>
//             <Box {...breadcrumbEntryProps(e)}>{e?.title}</Box>
//             { i < linksList?.length - 1 && <BreadcrumbSeparatorIcon {...breadcrumbSepIconProps} /> }
//         </Fragment>
//     )

//     return (
//         <Box {...breadcrumbBoxProps}>
//             {breadcrumbLinks}
//         </Box>
//     );

// }



