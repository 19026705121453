import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { 
    maxExternalCardBoxHt, 
    maxExternalCardBoxWd,

    defFunc,
    cardClasses, 
 } from "../constants";

const defXBoxPadding = 28;
const defYBoxPadding = 16;

export default function ExternalCard(props)  {
    
    const {
        logoSrc='',
        onClick=defFunc,
        otherECBoxSxProps={},
        title='',
        xBoxPadding=defXBoxPadding,
        yBoxPadding=defYBoxPadding,
        currDarkThemeClass,
    } = props;


    const eCBoxProps = {
        // className: 'fadeInCards',
        className: `${cardClasses?.externalCard?.box} ${currDarkThemeClass} fadeInCards`,
        onClick,
        sx: {
            // backgroundColor: 'primary.200',
            // borderBottom: '1px solid',
            // borderColor: 'primary.800',
            // display: 'flex',
            height: `${maxExternalCardBoxHt}px`,
            // mb: '16px',
            // mr: '16px',
            p: `${defYBoxPadding}px ${defXBoxPadding}px`,
            width: `${maxExternalCardBoxWd}px`,
            // '&:hover': {
            //     cursor: 'pointer',
            // },
            ...otherECBoxSxProps, // set margin to 0 and width tp 100% when using grid as parent
        },
    };

    const logoImgProps = {
        alt: "Brand logo",
        className: `${cardClasses?.externalCard?.logoImage} ${currDarkThemeClass}`,
        src: logoSrc,
        width: `${maxExternalCardBoxWd - 2 * xBoxPadding}px`,
        height: `${maxExternalCardBoxHt - 2 * yBoxPadding}px`,
        style: {
            objectFit: 'contain',
        },
    };

    return (
        <Box {...eCBoxProps}>
            <img {...logoImgProps} />
        </Box>
    );
}