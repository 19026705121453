import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { FeedbackContext, MetrcKeyContext } from "../../App";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";

import { DownArrowIcon, InfoIcon, WhatsThisIcon } from "../utility/CustomSvgIcons";
import OrgOutlinePage from "./OrgSetupOutline";

import { defaultNoAuthRoute, errFeedbackFn, fadeTimingMs, farmsWizardRoute, getHandleErrorWithFeedbackCX, landingPageRoute, metrcVersion, miscClasses, orgSetupClasses, setupFormMaxWd, states } from "../utility/constants";

import APIService from '../../api/apiService';
import useThemeVal from "../../utility/useThemeVal";
import getTokenDetails from "../../auth/getTokenDetails";
import DownloadingMetrcDataMsg from "../utility/DownloadingMetrcDataMsg";

const totalStepCount = 2;

const orgKeys = [
    {key: 'org_name', otherProps: { label: 'Organization Name' }},
    {key: 'address', otherProps: {label: 'Address'}},
];

const complianceKeys = [
    {key: 'metrc_id', otherProps: {label: 'Metrc Key'}},
    {key: 'state', otherProps: {label: 'State', select: true, SelectProps: {IconComponent: DownArrowIcon}}},
    {key: 'environment_type', otherProps: {label: 'Environment', select: true, SelectProps: {IconComponent: DownArrowIcon}}},
    {key: 'version', otherProps: {label: 'Metrc Version', select: true, SelectProps: {IconComponent: DownArrowIcon}}},
];

const environmentOptions = [
    { code: 1, name: 'Production' },
    { code: 2, name: 'Sandbox' },
  ];
  
  const getEnvironmentName = (code) => {
    const environment = environmentOptions.find((env) => env.code === code);
    return environment ? environment.name : 'Unknown Environment';
  };
  

// const stateList = states.map((e, i) => 
//     <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
// );

const agreeInfo = 'I agree to terms and conditions.';
const allowInfo = 'This allows us to make connection with your compliance organization on your behalf and download data for further processing.'
const downloadTitle1 = 'Downloading your data.';
// const downloadTitle2 = 'Meanwhile';
// const downloadTimeInfo = <>It might take 3 minutes or more to fetch the data from Metrc&nbsp;servers.</>
const downloadTimeInfo = <>It usually takes less than a minute to fetch the data from Metrc&nbsp;servers.</>

const ORG_DETAILS = 1;
const METRC_DETAILS = 2;


export default function OrganizationSetup(props) {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    
    const feebackCX = useContext(FeedbackContext);

    const {metrcKey, setMetrcKey} = useContext(MetrcKeyContext);

    const navigate = useNavigate();
    // const location = useLocation();

    // const {state} = location;
    
    const currDarkThemeClass = useThemeVal(theme);

    // const token = localStorage.getItem('token');

    // let tokenDetails = {};

    // try {
    //     tokenDetails = JSON.parse(atob(token?.split('.')?.[1]));
    // } catch {}

    const {token, tokenDetails} = getTokenDetails();

    const {farmSetup, farmCount=0} = tokenDetails;

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleError = err => {
        setLoading(false);
        console.log('an error occured');console.log(err);
        // enable error feedback messages
        getHandleErrorWithFeedbackCX(feebackCX)(errFeedbackFn({}))(err);
        navigate('/');
    }

    const handleOrgSetupRes = res => {
        // console.log('1. org setup successful');console.log(res);
        setLoading(false);
        
        // Generate a new token after org setup and expire the old one
        // Then reset the localStorage.token in here

        const {data: {token}} = res;
        
        handleSetValues('step', 2);
        handleSetValues('stepDetail', 'Metrc Details');
        handleSetValues('title', 'Please provide your Metrc credentials');
        handleSetValues('agree', false);

        localStorage.setItem('token', token);
        // sessionStorage.setItem('orgSetup', true);
    };

    const handleProcessingFarmsRes = timerId => res => {
        if (res.status === 202) return;
        // console.log('Farm Res Processed');console.log(res);
        clearInterval(timerId);

        const {data: {token}} = res;

        // new token with farmSetup === true;
        localStorage.setItem('token', token);

        // navigate('/farms?tab=farm-name', {replace: true});
        navigate(farmsWizardRoute, {replace: true});

    }

    const handleFarmDetailRes = res => {
        // console.log('3. farmDetailRes...');console.log(res);
        if (res.status === 202) {

            const timerId = setInterval(() => {
                handleSetValues('timerId', timerId);

                const entity = 'farm/?token=true';
                // APIService.fetchAllInstances(entity, metrcKey)
                
                APIService.fetchInstance(entity)
                .then(handleProcessingFarmsRes(timerId))
                .catch(handleError)

            }, 15000);
            
            return;
        };
        // console.log('farmDetailRes... check for token');console.log(res);

        const {data: {token}} = res;
        
        // new token with farmSetup === true;
        localStorage.setItem('token', token);
        
        // navigate('/farms?tab=farm-name', {replace: true});
        navigate(farmsWizardRoute, {replace: true});
    }

    const handleMetrcSetupRes = res => {
        setLoading(false);

        // console.log('2. metrc setup successful');console.log(res);
        const {data: {metrc_id, token}} = res;
        
        setMetrcKey(metrc_id);
        
        // handleSetValues('id', id);
        handleSetValues('downloading', true);
        handleSetValues('metrc_id', metrc_id);
        
        localStorage.setItem('token', token);

        // const entityWithID = `farm/?metrc_id=${metrc_id}&data_fetch=true`;
        const entity = `farm/`;
        // const entity = `farm/?token=true`;
        
        const payload = {
            data_fetch: true,
        };
        // APIService.fetchInstance(entityWithID)

        APIService.createInstance(entity, payload)
        .then(handleFarmDetailRes)
        .catch(handleError)
    };

    useEffect(() => {

        // const metrcSetup = sessionStorage.getItem('metrcSetup');
        // const orgSetup = sessionStorage.getItem('orgSetup');
        // const id = sessionStorage.getItem('id');

        // if (!token || farmSetup) return;
        if (!token || farmCount > 0) return;

        let newTokenDetails = {};

        try {
            newTokenDetails = JSON.parse(atob(token?.split('.')?.[1]))
        } catch { 
            localStorage.clear();
            sessionStorage.clear();

            return <Navigate to={defaultNoAuthRoute} />
        }
        
        const { metrcSetup, orgSetup, metrc_id } = newTokenDetails;

        if (metrcSetup && orgSetup) {
            if (!metrcKey) {
                setMetrcKey(metrc_id);
            }

            setValues({
                downloading: true,
            });

            // const entityWithID = `farm/?metrc_id=${metrc_id}&data_fetch=true`;
            let entity = `farm/`;
            
            if (farmCount === 0) {
                entity += '?token=true';
            }

            // const payload = {
            //     data_fetch: true,
            // };
            
            // APIService.createInstance(entity)
            APIService.fetchInstance(entity)
            .then(handleFarmDetailRes)
            .catch(handleError)    

        } else if (orgSetup) {
            setValues({
                agree: false,
                step: 2,
                stepDetail: 'Metrc Details',
                title: 'Please provide your Metrc credentials',
            });
        } else {
            setValues({
                step: 1,
                stepDetail: 'Organization Details',
                title: 'Tell us about your Organization',
            });
        }
        // console.log('eof')

        return () => clearInterval(values?.timerId);
    }, []);

    // useEffect(() => {
    //     if (values?.downloading) {
    //         const downloadTimerId = setInterval(() => {
    //             clearInterval(values?.downloadTimerId);
    //             // TODO: Can send request_param to specify that only metrc_status is required
    //             //       Will need to ensure other functionality is not affected
    //             // const entityWithID = `metrc-key/${values?.id}/?field=metrc_status`;
                
    //             console.log('executing function');

    //             const entityWithID = `metrc-key/${values?.id}/`;
                
    //             APIService.fetchInstance(entityWithID)
    //             .then(handleMetrcStatusRes)
    //             .catch(handleError)
    //             handleSetValues('downloadTimerId', downloadTimerId);

    //         }, 120000);

    //         handleSetValues('downloadTimerId', downloadTimerId);
    //     }
    // }, [values?.downloading]);

    useEffect(() => {
        if (!token) {
            navigate(defaultNoAuthRoute)
        // } else if (farmSetup) {
        } else if (farmCount > 0) {
            navigate(landingPageRoute)
        }
    // }, [token, farmSetup])
    }, [token, farmCount])

    // if (!token || farmSetup) {
    if (!token || farmCount > 0) {
        return <></>;
    }

    // if (!token ) {
    //     return <Navigate to={defaultNoAuthRoute} />; 
    // }

    // if (farmSetup) {
    //     return <Navigate to='/' replace />;
    // }

    // if (metrcKey) {
    //     return <Navigate to='/' replace />;
    // }

    // if (!state || !token) {
    //     // remove replace from here to allow navigate(-1) to work properly
    //     // Or, have different redirects to /signin or /signup and use replace attribute accordingly
    //     return <Navigate to='/signup' replace />; 
    // }

    const oSOrgOutlineProps = {
        currDarkThemeClass,
    };

    const oSBoxProps = {
        className: `${orgSetupClasses?.box} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     maxWidth: `${setupFormMaxWd}px`,
        //     mb: '32px', // self added; for easy scrolling if any
        //     mt: '96px',
        //     mx: 'auto',
        // },
    }; 

    const oSStepTypoProps = {
        className: `${orgSetupClasses?.stepTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '12px',
        //     fontWeight: 500,
        //     letterSpacing: '0.035em',
        //     lineHeight: 1.58,
        //     textTransform: 'uppercase',
        // },
    };

    const oSProgressBoxProps = {
        className: `${orgSetupClasses?.progressBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     height: '8px',
        //     mt: '12px',
        //     // width: '100%',
        // },
    };

    const oSProgressElemBoxProps = (i) => ({
        className: `${orgSetupClasses?.progressElemBox} ${currDarkThemeClass} ${i < (+values?.step ?? 1) ? orgSetupClasses?.completedStep : ''}`,
        // sx: {
        //     backgroundColor: i < (+values?.step ?? 1) ? 'primary.700' : 'grey.200',
        //     borderRadius: '100px',
        //     flexGrow: 1,
        //     height: '8px',
        //     mr: '4px',
        //     transition: `background-color ${fadeTimingMs}ms ease`,
        //     ...(values?.step > 1 && {
        //         '&:last-of-type': {
        //             mr: 0,
        //         },
        //     }),
        // },
    });

    const progressList = Array.from({length: totalStepCount ?? 2}).map((e, i) => 
        <Box key={i} {...oSProgressElemBoxProps(i)}></Box>
    );


    // const progressList = Array.from({length: values?.step ?? 1}).map((e, i) => 
    //     <Box key={i} {...oSProgressElemBoxProps(i)}></Box>
    // );

    // const remainingSteps = totalStepCount - ( values?.step ?? 1 );

    // const oSProgressDueElemBoxProps = {
    //     sx: {
    //         backgroundColor: 'grey.200',
    //         borderRadius: '100px',
    //         flexGrow: 1,
    //         height: '8px',
    //     },
    // };

    // if (remainingSteps > 0) {
    //     progressList.push(
    //         ...Array.from({length: remainingSteps}).map((e, i) => 
    //             <Box key={'_'+i} {...oSProgressDueElemBoxProps}></Box>
    //         )
    //     )
    // }

    const oSStepDetailTypoProps = {
        className: `${orgSetupClasses?.detailTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        //     mt: '52px',
        // },
    };

    const oSActionTypoProps = {
        className: `${orgSetupClasses?.actionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '30px',
        //     fontWeight: 700,
        //     lineHeight: 1.13,
        //     letterSpacing: '-0.01em',
        //     mt: '12px',
        // },
    };

    const handleSubmit = event => {
        event.preventDefault();
        
        switch (+values?.step) {
            case ORG_DETAILS:
                const orgKeys = ['org_name', 'address'];
                
                if (orgKeys.some(key => !values?.[key])) return;
                
                // const keyMap = [
                //     {key: 'org_name', valueKey: 'org_name'},
                //     {key: 'address', valueKey: 'address'},
                // ];
                // const keyValuePairs = keyMap.map( e => [ e.key, values[e.valueKey] ] );
                setLoading(true);

                const orgKeyValuePairs = orgKeys.map( key => [ key, values[key] ] );

                const orgEntity = 'organization/';
                const orgPayload = Object.fromEntries(orgKeyValuePairs);

                // console.log('orgPayload');console.log(orgPayload);
                
                APIService.createInstance(orgEntity, orgPayload)
                .then(handleOrgSetupRes)
                .catch(handleError)
                
                break;
            
            case METRC_DETAILS:
                const metrcKeys = ['agree', 'metrc_id', 'state','environment_type', 'version'];

                if (metrcKeys.some(key => !values?.[key])) return;

                setLoading(true);

                const metrcEntity = 'metrc-key/';

                const metrcKeyValuePairs = metrcKeys.map( key => [ key, values[key] ] );
                const metrcPayload = Object.fromEntries(metrcKeyValuePairs);

                // console.log('metrcPayload');console.log(metrcPayload);
                
                APIService.createInstance(metrcEntity, metrcPayload)
                .then(handleMetrcSetupRes)
                .catch(handleError)

                break;

            default:
                break;
        }
    }

    const oSFormBoxProps = {
        component: 'form',
        onSubmit: handleSubmit,
        className: `${orgSetupClasses?.formBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     mt: '28px',
        // },
    };

    const oSWhatsThisIconProps = {
        className: `${orgSetupClasses?.whatsThisIcon} ${currDarkThemeClass}`,
        // sx: {
        //     '& path': {
        //         fill: 'none',
        //     },
        // },
    };
        

    const oSTextFieldProps = (prop, otherProps={}) => ({
        autoComplete: 'off',
        autoFocus: prop === 'org_name' || prop === 'metrc_id',
        className: `${orgSetupClasses?.textField} ${currDarkThemeClass}`,
        disabled: loading,
        // InputLabelProps: {
        //     sx: {
        //         color: 'grey.800',
        //         '&.Mui-focused': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        InputProps: {
            endAdornment: prop === 'metrc_id' && <WhatsThisIcon {...oSWhatsThisIconProps} />,
            // sx: {
            //     borderRadius: 0,
            //     '& fieldset.MuiOutlinedInput-notchedOutline': {
            //         borderColor: 'grey.800',
            //     },
            //     '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
            //         borderColor: 'grey.800',
            //     },
            // },
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        required: true,
        // sx: {
        //     mb: '20px',
        //     '&:last-of-type': {
        //         mb: 0,
        //     },
        // },
        value: values?.[prop] ?? '',
        ...otherProps,
    });

    const OrganizationForm = orgKeys.map((e, i) =>
        <TextField key={i} {...oSTextFieldProps(e.key, e.otherProps)} />
    );

    // const ComplianceForm = complianceKeys.map((e, i) =>
    //     <TextField key={i} {...oSTextFieldProps(e.key, e.otherProps)} >
    //         {e.key === 'state' && stateList}
    //     </TextField>
    // );

    const oSAgreeBoxProps = {
        className: `${orgSetupClasses?.agreeBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        // },
    };

    const oSCheckboxProps = {
        className: `${orgSetupClasses?.checkbox} ${currDarkThemeClass}`,
        disabled: loading,
        onChange: ({target: {checked}}) => handleSetValues('agree', checked),
        required: true,
        // sx: {
        //     borderColor: 'primary.800',
        //     height: '16px',
        //     width: '16px',
        //     '& input': {
        //         accentColor: theme.palette.primary['800'],
        //         height: '100%',
        //     },
        // },
        type: 'checkbox',
        value: values?.agree ?? false,
    };

    const oSAgreeTypoProps = {
        className: `${orgSetupClasses?.agreeTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '14px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     ml: '16px',
        // },
    };

    const oSAllowInfoBoxProps = {
        className: `${orgSetupClasses?.allowInfoBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     mt: '12px',
        // },
    };
    
    const oSAllowInfoIconProps = {
        className: `${orgSetupClasses?.allowInfoIcon} ${currDarkThemeClass}`,

    };

    const oSAllowInfoTypoProps = {
        className: `${orgSetupClasses?.allowInfoTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.600',
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: 1.5,
        //     ml: '4px',
        // },
    };

    const oSStateMenuItemProps = {
        className: `${orgSetupClasses?.stateMenuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: miscClasses?.menuItemFocusDark,
    };

    const stateList = states.map((e, i) => <MenuItem key={i} value={e.id} {...oSStateMenuItemProps}>{e.name}</MenuItem>);

    const environmentList = environmentOptions.map((env, i) => (
        <MenuItem key={i} value={env.code} {...oSStateMenuItemProps}>
            {env.name}
        </MenuItem>
    ));

    const metrcVersionList = metrcVersion.map((v, i) => (
        <MenuItem key={v.id} value={v.name} {...oSStateMenuItemProps} >
            {v.name}
        </MenuItem>
    ))

    const ComplianceForm = (
        <>
        {
            complianceKeys.map((e, i) =>
                <TextField key={i} {...oSTextFieldProps(e.key, e.otherProps)} >
                    {e.key === 'state' && stateList}
                    {e.key === 'environment_type' && environmentList}
                    {e.key === 'version' && metrcVersionList}
                </TextField>
            )
        }
            <Box {...oSAgreeBoxProps}>
                <InputBase {...oSCheckboxProps}/>
                <Typography {...oSAgreeTypoProps}>{agreeInfo}</Typography>
            </Box>
            <Box {...oSAllowInfoBoxProps}>
                <InfoIcon {...oSAllowInfoIconProps} />
                <Typography {...oSAllowInfoTypoProps}>{allowInfo}</Typography>
            </Box>
        </>
    )

    const oSProceedProgressProps = {
        color: 'warning',
        size: 20,
    };

    const oSProceedProps = {
        children: 'Proceed',
        disabled: loading,
        onClick: undefined,
        startIcon: loading && <CircularProgress {...oSProceedProgressProps} />,
        className: `${orgSetupClasses?.proceedBtn} ${currDarkThemeClass}`,
        // sx: {
        //     color: '#FFFFFF',
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05em',
        //     lineHeight: 1.56,
        //     mt: '20px',
        //     p: '16px',
        //     textTransform: 'none',
        //     width: '472px',
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
        type: 'submit',
    };

    const downloadMsgProps = {
        currDarkThemeClass,
    };

    return (
        <OrgOutlinePage {...oSOrgOutlineProps}>
            <Box {...oSBoxProps}>
                {   
                    loading || values?.downloading ? <DownloadingMetrcDataMsg {...downloadMsgProps} />
                    : 
                    <>
                        <Typography {...oSStepTypoProps}>STEP {values?.step ?? '#'}/{totalStepCount} COMPLETED</Typography>
                        
                        <Box {...oSProgressBoxProps}>
                            {progressList}
                        </Box>

                        <Typography {...oSStepDetailTypoProps}>Step {values?.step ?? '#'}: {values?.stepDetail ?? 'Unknown'}</Typography>
                        
                        <Typography {...oSActionTypoProps}>{values?.title ?? 'Unknown'}</Typography>

                        <Box {...oSFormBoxProps}>
                            {
                                +values?.step === 1 ? OrganizationForm
                                : +values?.step === 2 ? ComplianceForm
                                : <></>
                            }
                            <Button {...oSProceedProps} />
                        </Box>
                    </>
                }
            </Box>
        </OrgOutlinePage>
    );
}