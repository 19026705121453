import { Box, Button, Fade, MenuItem, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DownArrowIcon, NEW, cropClasses, cropPlanStatusChoices, fadeTimingMs, millisInDay, miscClasses, cardClasses } from '../../../utility';
import { StrainAccordion } from '../../utils/StrainAccordion';
import Specificentrypopup from '../../Popup/Specificentrypopup';
import NoEntityMsg from '../../../utility/messages/NoEntityMsg';

export default function _SpecificsTab(props) {
  const { cropplanid, cropPlanPerms, currDarkThemeClass, queryParam, values, handleSetValues, savedRes, handleErrorWithFeedback } = props;
  const [isSpecificEntryPopupOpen, setSpecificEntryPopupOpen] = useState(false);
  const [selectedFarmId, setSelectedFarmId] = useState(null);
  const [selectedFarmName, setSelectedFarmName] = useState(''); 
  const [filteredStrains, setFilteredStrains] = useState([]);

  useEffect(() => {
    const handleInitialFarm = () => {
    if (values?.farms?.length === 1 && !values?.farm) {
        const farm = values.farms[0];
      handleSetValues('farm', farm.id);
      setSelectedFarmId(farm.id);
      setSelectedFarmName(farm.farm_name);
      }
      else if (values?.farm) {
        const farm = values.farms?.find(f => f.id === values.farm);
        if (farm) {
          setSelectedFarmId(farm.id);
          setSelectedFarmName(farm.farm_name);
        }
      }
    };

    handleInitialFarm();
  }, [values?.farms, values?.farm]);

  useEffect(() => {
    if (selectedFarmId) {
      const strains = values?.farmstrains?.filter(strain => strain.farm === selectedFarmId) ?? [];
      setFilteredStrains(strains);
    } else {
      setFilteredStrains([]);
    } }, [selectedFarmId, values?.farmstrains]);

  const handleFarmChange = (event) => {
    const farmId = event.target.value;
    const farm = values.farms?.find(f => f.id === farmId);
    if (farm) {
    handleSetValues('farm', farmId);
    setSelectedFarmId(farmId);
    setSelectedFarmName(farm.farm_name);
    };
  };
  

  const cropPlanSpecificsTextFieldProps = ({ prop, inputProps = {}, otherSxProps = {}, otherInputProps = {}, ...otherTextProps }) => ({
    autoComplete: 'off',
    className: `${cropClasses?.textField} ${currDarkThemeClass}`,
    disabled: (cropplanid !== NEW && !cropPlanPerms?.update) || values?.processing ||(prop === 'date_of_serialization' && cropplanid !== NEW),
    inputProps,
    InputLabelProps: {
      ...((prop === 'date_of_serialization' || prop === 'description') && {
        shrink: true,
      }),
    },
    InputProps: {
      ...otherInputProps,
    },
    label: ' ',
    onChange: ({ target: { value } }) => handleSetValues(prop, value),
    required: true,
    sx: {
      ...otherSxProps,
    },
    value: values?.[prop] ?? '',
    ...otherTextProps,
  });

  const cropPlanSpecificsBoxProps = {
    className: `${cropClasses?.specificsBox} ${currDarkThemeClass}`,
  };

  const stackwidthProps = {
    className: `${cropClasses?.specificsstack} ${currDarkThemeClass}`,
  };

  const openSpecificEntryPopup = () => {
    setSpecificEntryPopupOpen(true);
  };

  const closeSpecificEntryPopup = () => {
    setSpecificEntryPopupOpen(false);
  };
  const nameArgs = {
    label: 'Name',
    prop: 'name',
    autoFocus: true,
  };

  const descArgs = {
    label: 'Description',
    prop: 'description',
    required: false,
  };

  const cropPlanMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const phasePlansList =
    values?.phaseplans?.map((e, i) => (
      <MenuItem key={i} value={e?.id} {...cropPlanMenuItemProps}>
        {e?.name}
      </MenuItem>
    )) ?? [];

  if (!phasePlansList?.length) {
    phasePlansList.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Phase Plans
      </MenuItem>
    );
  }

  const phaseplanArgs = {
    label: 'Phase Plan',
    prop: 'phaseplan',
    type: 'number',
    select: true,
    SelectProps: {
      IconComponent: DownArrowIcon,
    },
  };

  const qualityPlansList =
    values?.qualityplans?.map((e, i) => (
      <MenuItem key={i} value={e?.id} {...cropPlanMenuItemProps}>
        {e?.name}
      </MenuItem>
    )) ?? [];

  if (!qualityPlansList?.length) {
    qualityPlansList.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Phase Plans
      </MenuItem>
    );
  }

  const qualityplanArgs = {
    label: 'Quality Plan',
    prop: 'qualityplan',
    type: 'number',
    select: true,
    SelectProps: {
      IconComponent: DownArrowIcon,
    },
  };
  const serDateArgs = {
    label: 'Date of Serialization',
    type: 'date',
    prop: 'date_of_serialization',
  };

  const planStatusList = cropPlanStatusChoices.map((choice, i) => (
    <MenuItem key={i} value={choice?.value} {...cropPlanMenuItemProps}>
      {choice.label}
    </MenuItem>
  ));

  const planStatusProps = {
    label: 'Status',
    prop: 'run_status',
    select: true,
    SelectProps: {
      IconComponent: DownArrowIcon,
    },
  };

  const plantCtargs = {
    label: 'Plant Count',
    type: 'number',
    prop: 'plant_count',
  };

  const usersList =
    values?.users?.map((e, i) => (
      <MenuItem key={i} value={e?.id} {...cropPlanMenuItemProps}>
        {e?.first_name}
      </MenuItem>
    )) ?? [];

  if (!usersList?.length) {
    usersList.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Users
      </MenuItem>
    );
  }

  const ownerargs = {
    label: 'Owner',
    type: 'number',
    select: true,
    SelectProps: {
      IconComponent: DownArrowIcon,
    },
    prop: 'owner',
  };

  const farmArgs = {
    label: 'Farm',
    prop: 'farm', 
    autoFocus: true,
    onChange: handleFarmChange,
    select: true,
    SelectProps: {
      IconComponent: DownArrowIcon,
    },
  };
  
  const farmList = values?.farms?.map((e, i) => (
    <MenuItem key={i} value={e?.id} {...cropPlanMenuItemProps}  disabled={cropplanid !== NEW}>
      {e?.farm_name}
    </MenuItem>
  )) ?? [];
  
  if (!farmList.length) {
    farmList.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Farms
      </MenuItem>
    );
  }
  
  const addStrainTypoProps = {
    className: `${cropClasses?.titleTypo} ${currDarkThemeClass}`,
  };

  const addStrainsBtnProps = {
    children: 'Add Strains',
    className: `${cropClasses?.saveBtn} ${currDarkThemeClass}`,
    disabled: !cropPlanPerms?.update || !values?.farm,  
    onClick: openSpecificEntryPopup,
    variant: 'contained',
  };

  const expandCardProps = e => ({
    strain: e,
    currDarkThemeClass,
    selectedFarmId,
    values,
    handleSetValues,
    qualityPlansList,
    phasePlansList,
    handleErrorWithFeedback,
    cropPlanPerms,
  });

  const strainsList = values?.strains?.map((strain, i) => <StrainAccordion key={i} {...expandCardProps(strain)} />);

  const handleAddStrain = payload => handleSetValues('strains', [...(values?.strains ?? []), payload]);

  const sEntryPopupProps = {
    selectedFarmId,
    selectedFarmName, 
    open: isSpecificEntryPopupOpen,
    onClose: closeSpecificEntryPopup,
    currDarkThemeClass,
    strainCount: values?.strains?.length ?? 0,
    plantCount: values.plant_count,
    qualityplan: values?.qualityplan ?? -1,
    phaseplan: values?.phaseplan ?? -1,
    handleAddStrain: handleAddStrain,
    parentValues: values,
    handleErrorWithFeedback,
    filteredStrains,
  };

  const farmField = () => {
    const isSingleFarm = values?.farms?.length === 1
    if (isSingleFarm) {
      return (
    <TextField
      label="Farm"
      value={selectedFarmName}
      disabled
      className={`${cropClasses?.textField} ${currDarkThemeClass}`}
      InputProps={{ readOnly: true }}
      InputLabelProps={{ shrink: true }}  /> ) }

    const farmList = values?.farms?.map((farm) => (
      <MenuItem key={farm.id} value={farm.id} className={`${cropClasses?.menuItem} ${currDarkThemeClass}`} disabled={cropplanid !== NEW}> {farm.farm_name}</MenuItem>
    )) ?? [];
    if (!farmList.length) {
      farmList.push(
        <MenuItem key={-1} value={-1} disabled className={`${cropClasses?.menuItem} ${currDarkThemeClass}`}>
          No Available Farms
        </MenuItem>)}
    return (
      <TextField label="Farm" value={values?.farm ?? ''} onChange={handleFarmChange}
        className={`${cropClasses?.textField} ${currDarkThemeClass}`}
        select
        disabled={cropplanid !== NEW}
        SelectProps={{
          IconComponent: DownArrowIcon
      }}
      InputLabelProps={{ shrink: true }}
    >
      {farmList}
    </TextField>
  ) };
  
  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...cropPlanSpecificsBoxProps}>
        <TextField {...cropPlanSpecificsTextFieldProps(nameArgs)} />
        <TextField {...cropPlanSpecificsTextFieldProps(descArgs)} />
        <Box {...stackwidthProps}>
          <Stack direction="row" spacing={2}>
            <TextField {...cropPlanSpecificsTextFieldProps(serDateArgs)} />
            <TextField {...cropPlanSpecificsTextFieldProps(planStatusProps)}>{planStatusList}</TextField>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField {...cropPlanSpecificsTextFieldProps(phaseplanArgs)}>{phasePlansList}</TextField>
            <TextField {...cropPlanSpecificsTextFieldProps(qualityplanArgs)}>{qualityPlansList}</TextField>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField {...cropPlanSpecificsTextFieldProps(ownerargs)}>{usersList}</TextField>
            <TextField {...cropPlanSpecificsTextFieldProps(plantCtargs)} 
              value={values.plant_count || ''} 
              onChange={(e) => handleSetValues('plant_count', e.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
          {farmField()}
          </Stack>
        </Box>

        {selectedFarmId && (
  <>
    <Stack direction="row" alignItems="center" marginTop={4} justifyContent="space-between">
      <Typography {...addStrainTypoProps}>Strains</Typography>
      {/* <Button {...addStrainsBtnProps} /> */}
    </Stack>
    {!strainsList?.length ? (
      <NoEntityMsg 
        entity="Strain" 
        isCreateEnabled={cropPlanPerms?.update} 
        onCreateClick={() => openSpecificEntryPopup()} 
      />
    ) : (
      strainsList
    )}
    {isSpecificEntryPopupOpen && <Specificentrypopup {...sEntryPopupProps} />}
  </>
)}
      </Box>
    </Fade>
  );
}