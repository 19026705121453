import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTheme } from "@mui/material";

import { MailIcon } from "../utility/CustomSvgIcons";
import SetupOutlinePage from "./SetupOutline";

import APIService from '../../api/apiService';
import { defaultNoMetrcRoute, farmDashboardRoute, getDarkThemeClass, signUpPageClasses } from "../utility/constants";


const signUpInfo = "Let's start by creating your SmartGrow Account.";
// const actionTitle = 'Give us Your Email Address.';
const actionTitle = 'Tell us Your Name and Email.';

const verificationInfo = "Creating your SmartGrow Account.";
const verificationTitle = 'We have sent a 6 digit verification code to your Email Address.';
const enterCodeMsg = "Please enter the verification code to proceed.";

const finalizingInfo = "Finalizing your Account Creation.";
const finalizingTitle = "Create a Password for your Account.";

const accountCreatedTitle = "Account Successfully Created."
const nextStepMsg = "Your next steps."
const nextStepInfoMsg = "Now that your account is created successfully, you can add your Farm Information and SmartGrow will perform its magic."

const defaultValues = {
    emailStage: true,
    verificationStage: false,
};

const instructions = [
    'At least 12 characters.',
    'A mixture of both uppercase and lowercase letters.',
    'A mixture of letters and numbers.',
    'Inclusion of at least one special character. e.g: ! @ # ? ]',
];


export default function SignUpPage(props) {
    const [loading, setLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(60); // 03-04-2023: TODO: Can be done using ref to avoid re-rendering
    // const [remainingTime, setRemainingTime] = useCountState(60); // 03-04-2023: TODO: Can be done using ref to avoid re-rendering
    const [values, setValues] = useState(defaultValues);

    // const remainingTime = useRef(60);
    // const setRemainingTime = func => func(remainingTime?.current?.value);

    const theme = useTheme();

    const navigate = useNavigate();

    const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleSetTimer = () => {
        let i = 60;
        let timerId = setInterval(() => {
            setRemainingTime(state => state > 0 ? state - 1 : 0);

            i--;
            
            if (i === 0) {
                clearInterval(timerId);
                timerId = null;
            }
        }, 1000);
        handleSetValues('timerId', timerId);
    }

    const handleEmailRegisterRes = res => {
        setLoading(false);

        handleSetValues('emailStage', false);
        handleSetValues('verificationStage', true);
        
        sessionStorage.setItem('email', values.email);
        
        handleSetTimer();
        // let i = 60;
        // let timerId = setInterval(() => {
        //     setRemainingTime(state => state > 0 ? state - 1 : 0);

        //     i--;
            
        //     if (i === 0) {
        //         clearInterval(timerId);
        //         timerId = null;
        //     }
        // }, 1000);

        // handleSetValues('timerId', timerId);
    };

    const handleEmailVerification = res => {
        // console.log('email verified');console.log(res);
        setLoading(false);
        
        clearInterval(values?.timerId);
        
        handleSetValues('verificationStage', false);
        
        sessionStorage.setItem('code', res.data);
    };

    const handlePasswordRes = res => {
        setLoading(false);
        // console.log('passwordRes', res);
        // const {data: {id, token} = ''} = res; // Reconsider the usage of id; try using something else
        const {data: {token, invite=false}} = res; // Reconsider the usage of id; try using something else

        handleSetValues('basicSetupComplete', true);
        handleSetValues('code', '');
        handleSetValues('confirmPassword', '');
        handleSetValues('password', '');
        // handleSetValues('id', id);
        localStorage.setItem('token', token);
        sessionStorage.setItem('setupComplete', true);

        if (invite) {
            sessionStorage.clear();
            navigate(farmDashboardRoute);
        } else {
            handleSetValues('allowFarmAdd', true);
        }
    };

    const handleResendCodeRes = res => {
        handleSetValues('code', '');
        handleSetValues('error', false);
    };

    const handleError = err => {
        console.log(err);
        const {response: {status, data: {detail=''}}} = err;
        // set error variables to show error in response

        if (values?.emailStage) {
            handleSetValues('error', true);
            // check for err.status_code and set helperText accordingly

            if (err.code === 'ERR_BAD_REQUEST') {
                handleSetValues('helperText', 'This email is already registered'); // 400
            } else {
                handleSetValues('helperText', 'An error occured'); // 500
            }

        } else if (values?.verificationStage) {
            handleSetValues('error', true);

            if (+status === 429) {
                clearInterval(values?.timerId);
                
                const minWaitTime = detail.match(/\d+/)?.[0];
                let resetTime = ( minWaitTime && +minWaitTime > 60 ) ? +minWaitTime : 60;

                if (resetTime > 60) {
                    setRemainingTime(-1);
                } else {
                    setRemainingTime(resetTime);
                    handleSetTimer();

                    // let i = 60;
                    // let timerId = setInterval(() => {
                    //     setRemainingTime(state => state > 0 ? state - 1 : 0);
                        
                    //     i--;
                        
                    //     if (i === 0) {
                    //         clearInterval(timerId);
                    //         timerId = null;
                    //     }
                    // }, 1000);
                    // handleSetValues('timerId', timerId);
                }
                
                handleSetValues('helperText', `Too many attempts.${resetTime < 61 ? ' Please wait for a minute' : ''}`);

            } else if (+status === 500) {
                handleSetValues('helperText', 'An error occured');
            } else {
                handleSetValues('helperText', 'Invalid code provided');
            }
        } else {
            // Ask user to retry?
            console.log('no match in error');
        }
        setLoading(false);
    };

    const handleProceed = (event) => {
        event.preventDefault();
        
        if (values?.emailStage) {

            // Fallback validation in case the html form is bypassed;
            if(!values?.email || !values?.first_name?.trim() || !values?.last_name?.trim()) {
                handleSetValues('error', true);
                handleSetValues('helperText', '*This is a required value');
                return;
            } else if (!values?.email?.includes('@') || values?.email?.indexOf('@') !== values?.email?.lastIndexOf('@')) {
                handleSetValues('error', true);
                handleSetValues('helperText', '*Please enter a valid email');
                return;
            }
            
            setLoading(true);
            handleSetValues('error', false);
            // console.log('values');console.log(values);return;
            // TODO: Send email via backend
            const entity = 'register/';
            const payload = {
                data: {
                    email: values.email,
                    first_name: values.first_name,
                    last_name: values.last_name,
                },
            };
            
            // console.log('payload');console.log(payload);return;

            APIService.createInstance(entity, payload)
            .then(handleEmailRegisterRes)
            .catch(handleError);

        } else if (values?.verificationStage) {
            // console.log('verification stage handleProceed...');

            if (!values?.code || values?.code?.length !== 6) {
                handleSetValues('error', true);
                handleSetValues('helperText', 'This is a required value');
                return;
            } else if (remainingTime < 0) {
                return ;
            }
            
            setLoading(true);
            handleSetValues('error', false);

            // TODO: do backend verification of code
            const entity = 'verify-email/';
            const payload = {
                email: values.email,
                verification_code: values.code,
            };

            APIService.createInstance(entity, payload)
            .then(handleEmailVerification)
            .catch(handleError)

        } else if (!values?.basicSetupComplete) {
            // event.preventDefault();

            if (!values?.password || !values?.confirmPassword) {
                handleSetValues('error', true);
                handleSetValues('helperText', 'This is a required value');
                return;
            } else if (values.password.length < 12 || values.password !== values.confirmPassword) {
                handleSetValues('error', true);
                handleSetValues('helperText', 'Passwords do not match');
                return;
            }

            // const regexList = [/[0-9]+/, /[a-z]+/, /[A-Z]+/];
            
            // if (!regexList.every(regex => regex.test(values.password))) {
                //     console.log('regex mismatch');
                //     handleSetValues('error', true);
                //     handleSetValues('helperText', 'Please match the password pattern');
                //     return;
                // }

            const regexList = [
                {regex: /[0-9]+/, helperText: 'Please add a number'},
                {regex: /[a-z]+/, helperText: 'Please add a lowercase character'},
                {regex: /[A-Z]+/, helperText: 'Please add an uppercase character'},
                {regex: /\W+|_/, helperText: 'Please add a special character'},
            ];

            for (let item of regexList) {
                if (!item.regex.test(values.password)) {
                    handleSetValues('error', true);
                    handleSetValues('helperText', item.helperText);
                    return;
                }
            }

            setLoading(true);
            handleSetValues('error', false);

            const entity = 'set-password/';
            const payload = {
                email: values.email,
                password: values.password,
                verification_code: values.code,
            };

            // console.log('payload');console.log(payload);return;

            APIService.createInstance(entity, payload)
            .then(handlePasswordRes)
            .catch(handleError)
        }
    };

    useEffect(() => {
        const email = sessionStorage.getItem('email');
        const code = sessionStorage.getItem('code');
        const setupComplete = sessionStorage.getItem('setupComplete');
        const token = localStorage.getItem('token');
        let payload = null;

        try {
            payload = JSON.parse(atob(token.split('.')[1]));
        } catch {}

        // Also decode token for attributes such as email & code
        //   Then check if token.email === email && token.code === code
        
        if (setupComplete && email && code) { 
            handleSetValues('code', code);
            handleSetValues('email', email);
            handleSetValues('basicSetupComplete', true);
            handleSetValues('emailStage', false);
            handleSetValues('verificationStage', false);
            handleSetValues('allowFarmAdd', payload?.user_id > 0);
        } else if (email && code) {
            handleSetValues('code', code);
            handleSetValues('email', email);
            handleSetValues('emailStage', false);
            handleSetValues('verificationStage', false);
        } else if (email) {
            handleSetValues('email', email);
            handleSetValues('emailStage', false);
            handleSetValues('verificationStage', true);
            handleSetTimer();
        }

        return () => !values.basicSetupComplete && sessionStorage.clear();
    }, []);

    const sPOutlineProps = {
        showImage: !values?.basicSetupComplete,
        currDarkThemeClass,
    };

    const sPBoxProps = {
        className: `${signUpPageClasses?.box} ${currDarkThemeClass} ${values?.allowFarmAdd ? signUpPageClasses?.allowFarmAdd : values?.basicSetupComplete ? signUpPageClasses?.basicSetupComplete : ''}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     maxWidth: '472px',
        //     mt: '108px',
        //     ...(values?.basicSetupComplete && {
        //         // mt: '282px',
        //         mx: 'auto',
        //     }),
        // },
    };

    const sPInfoTypoProps = {
        className: `${signUpPageClasses?.infoTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        // },
    };

    const sPActionTypoProps = {
        className: `${signUpPageClasses?.actionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '30px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.13,
        //     mt: '12px',
        // },
    };

    const sPNameBoxProps = {
        className: `${signUpPageClasses?.nameBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     width: '472px',
        // },
    };

    const sPFormBoxProps = {
        className: `${signUpPageClasses?.formBox} ${currDarkThemeClass}`,
        component: 'form',
        onSubmit: handleProceed,
    }

    const sPNextActionTypoProps = {
        className: `${signUpPageClasses?.nextActionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.800',
        //     fontSize: '20px',
        //     fontWeight: '500',
        //     lineHeight: 1.45,
        //     mt: '28px',
        //     mb: '4px', // making an exception; usually all tags should set their margin-top only if possible
        // },
    };

    const handleSetTextField = (prop, value) => {
        if (values?.error) {
            handleSetValues('error', false);
            // handleSetValues('helperText', null);
        }
        handleSetValues(prop, value);
    };

    const handleResendCode = () => {
        // console.log('handleResendCode...');
        if (!values?.email || remainingTime !== 0) return;

        
        // Restart timer?
        setRemainingTime(60);
        handleSetTimer();
        // let i = 60;
        // let timerId = setInterval(() => {
        //     setRemainingTime(state => state > 0 ? state - 1 : 0);

        //     i--;
            
        //     if (i === 0) {
        //         clearInterval(timerId);
        //         timerId = null;
        //     }
        // }, 1000);

        // handleSetValues('timerId', timerId);

        const entity = 'register/';
        const payload = {
            data: {
                email: values.email,
            },
        };

        APIService.createInstance(entity, payload)
        .then(handleResendCodeRes)
        .catch(handleError)
    };

    const sPResendCodeBtnProps = {
        disabled: remainingTime !== 0 || loading,
        onClick: handleResendCode,
        className: `${signUpPageClasses?.resendCodeBtn} ${currDarkThemeClass}`,
        // sx: {
        //     borderRadius: 0,
        //     color: 'primary.800',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     minWidth: 'fit-content',
        //     px: 0,
        //     py: '16px',
        //     textTransform: 'none',
        //     whiteSpace: 'nowrap',
        // },
    };

    const sPShowPasswordBtnIconProps = {
        className: `${signUpPageClasses?.showPasswordIcon} ${currDarkThemeClass}`,
        onClick: () => !loading ? handleSetValues('showPassword', !values?.showPassword) : undefined,
        // sx: {
        //     height: '20px',
        //     width: '20px',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        // },
    };

    const sPMailIcon = {
        className: `${signUpPageClasses?.mailIcon} ${currDarkThemeClass}`,        
        // sx: {
        //     width: '20px', 
        //     height: '20px', 
        //     '& path': {
        //         fill: 'none',
        //     },
        // },
    };

    const sPTextFieldProps = (prop, otherProps, otherSxProps={}, errorCheck=true) => ({
        autoComplete: 'off',
        disabled: loading,
        error: values?.error && (errorCheck),
        fullWidth: true,
        helperText: values?.error && (errorCheck) && values?.helperText,
        // error: values?.error && (!values?.[prop]?.trim() ?? !values?.[prop]),
        // helperText: values?.error && (!values?.[prop]?.trim() ?? !values?.[prop]) && values?.helperText,
        inputProps: {
            // sx: {
            //     px: '15px',
            //     py: '16px',
            // },
            ...otherProps?.inputProps
        },
        InputLabelProps: {
            ...(prop === 'email' && {
                shrink: Boolean(values?.email),
            }),
            // : {
            //     shrink: false
            // }),
            sx: {
                // fontSize: '16px',
                // lineHeight: 1.56,
                // // ml: values?.email ? 0 : '36px',
                ...(prop === 'email' && {
                    ml: values?.email ? 0 : '36px',
                }),
                // '&.MuiInputLabel-root': {
                //     color: 'grey.600',
                // },
            },
        },
        InputProps: {
            endAdornment: prop === 'code' ? <Button {...sPResendCodeBtnProps}>Resend Code</Button>
                            : prop === 'password' || prop === 'confirmPassword' 
                                ? values?.showPassword ? <VisibilityOffIcon {...sPShowPasswordBtnIconProps} /> : <VisibilityIcon {...sPShowPasswordBtnIconProps} />
                            : '',
            startAdornment: prop === 'email' && <MailIcon {...sPMailIcon} />,
            sx: {
                // borderRadius: 0,
                // '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
                //     borderColor: 'grey.800',
                // },
                ...(prop === 'code' && 
                        {
                        '&.MuiOutlinedInput-root.MuiInputBase-root': {
                                pr: '15px',
                            }
                        }
                    ),
            },
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetTextField(prop, value),
        required: true,
        className: `${signUpPageClasses?.textField} ${currDarkThemeClass}`,
        sx: {
            // mt: '28px',
            // // position: 'relative',
            // width: '472px',
            ...otherSxProps,
        },
        value: values[prop] ?? '',
        ...otherProps,
    });

    const firstNameArgs = [
        'first_name',
        {
            autoFocus: true,
            label: 'First Name',
        },
        {
            // mr: '8px',
            // width: 'calc(472px/2 - 8px)',
        },
        !values?.first_name?.trim(),
    ];
    
    const lastNameArgs = [
        'last_name',
        {
            // autoFocus: true,
            label: 'Last Name',
        },
        {
            // ml: '8px',
            // width: 'calc(472px/2 - 8px)',
        },
        !values?.last_name?.trim(),
    ];
    
    // const otherLastNameArgs = {
    //     label: 'Last Name',
    // };

    const otherEmailArgs = {
        // autoFocus: true,
        type: 'email',
        label: 'Your Email Address',
    };

    const sPProgressProps = {
        color: 'warning',
        size: 20,
    };

    const otherEmailBtnArgs = {
        // disabled: loading,
        onClick: undefined,
        // startIcon: loading && <CircularProgress {...sPProgressProps}/>,
        // type: 'submit',
    };
    
    const otherCodeArgs = {
        autoFocus: true,
        // label: !values?.code && '6 digit verification code',
        label: '6 digit verification code',
        required: false,
    };

    const otherVerifyBtnProps = {
        // disabled: loading,
        onClick: undefined,
        // startIcon: loading && <CircularProgress {...sPProgressProps}/>,
        // type: 'submit',
    }
    
    const otherPasswordProps = {
        autoFocus: true,
        inputProps: {
            minLength: 12,
            // pattern: '[a-z+A-Z+0-9+\W+]{12,}',
            // pattern: '\w{12,}',
            // pattern: '[0-9a-zA-Z]' // min one lower, one upper, one number and one spl char
        },
        // label: !values?.password && 'Enter Your Password',
        label: 'Enter Your Password',
        // required: false,
        type: values?.showPassword ? 'text' : 'password',
    };

    const otherConfirmPasswordProps = {
        inputProps: {
            minLength: 12,
            // pattern: '[0-9a-zA-Z\W+]{12,}' min one lower, one upper, one number and one spl char
        },
        // label: !values?.confirmPassword && 'Re-Enter Password to confirm',
        label: 'Re-Enter Password to confirm',
        // required: false,
        type: values?.showPassword ? 'text' : 'password',
        sx: { // check if this is the right key name for applying style
            mt: '20px',
            // width: '472px',
        },
    };

    const otherSubmitBtnProps = {
        // disabled: loading,
        onClick: undefined,
        // startIcon: loading && <CircularProgress {...sPProgressProps}/>,
        // type: 'submit',
    };

    const handleAddFarm = () => {
        sessionStorage.clear();
        navigate(defaultNoMetrcRoute, {replace: true});
    }

    const otherAddFarmArgs = {
        onClick: handleAddFarm,
    };

    // const handleProceed = (event) => {
    //     event.preventDefault();
        
    //     if (values?.emailStage) {

    //         // Fallback validation in case the html form is bypassed;
    //         if(!values?.email || !values?.first_name?.trim() || !values?.last_name?.trim()) {
    //             handleSetValues('error', true);
    //             handleSetValues('helperText', '*This is a required value');
    //             return;
    //         } else if (!values?.email?.includes('@') || values?.email?.indexOf('@') !== values?.email?.lastIndexOf('@')) {
    //             handleSetValues('error', true);
    //             handleSetValues('helperText', '*Please enter a valid email');
    //             return;
    //         }
            
    //         setLoading(true);
    //         handleSetValues('error', false);
    //         // console.log('values');console.log(values);return;
    //         // TODO: Send email via backend
    //         const entity = 'register/';
    //         const payload = {
    //             data: {
    //                 email: values.email,
    //                 first_name: values.first_name,
    //                 last_name: values.last_name,
    //             },
    //         };
            
    //         // console.log('payload');console.log(payload);return;

    //         APIService.createInstance(entity, payload)
    //         .then(handleEmailRegisterRes)
    //         .catch(handleError);

    //     } else if (values?.verificationStage) {
    //         // console.log('verification stage handleProceed...');

    //         if (!values?.code || values?.code?.length !== 6) {
    //             handleSetValues('error', true);
    //             handleSetValues('helperText', 'This is a required value');
    //             return;
    //         } else if (remainingTime < 0) {
    //             return ;
    //         }
            
    //         setLoading(true);
    //         handleSetValues('error', false);

    //         // TODO: do backend verification of code
    //         const entity = 'verify-email/';
    //         const payload = {
    //             email: values.email,
    //             verification_code: values.code,
    //         };

    //         APIService.createInstance(entity, payload)
    //         .then(handleEmailVerification)
    //         .catch(handleError)

    //     } else if (!values?.basicSetupComplete) {
    //         // event.preventDefault();

    //         if (!values?.password || !values?.confirmPassword) {
    //             handleSetValues('error', true);
    //             handleSetValues('helperText', 'This is a required value');
    //             return;
    //         } else if (values.password.length < 12 || values.password !== values.confirmPassword) {
    //             handleSetValues('error', true);
    //             handleSetValues('helperText', 'Passwords do not match');
    //             return;
    //         }

    //         // const regexList = [/[0-9]+/, /[a-z]+/, /[A-Z]+/];
            
    //         // if (!regexList.every(regex => regex.test(values.password))) {
    //             //     console.log('regex mismatch');
    //             //     handleSetValues('error', true);
    //             //     handleSetValues('helperText', 'Please match the password pattern');
    //             //     return;
    //             // }

    //         const regexList = [
    //             {regex: /[0-9]+/, helperText: 'Please add a number'},
    //             {regex: /[a-z]+/, helperText: 'Please add a lowercase character'},
    //             {regex: /[A-Z]+/, helperText: 'Please add an uppercase character'},
    //             {regex: /\W+|_/, helperText: 'Please add a special character'},
    //         ];

    //         for (let item of regexList) {
    //             if (!item.regex.test(values.password)) {
    //                 handleSetValues('error', true);
    //                 handleSetValues('helperText', item.helperText);
    //                 return;
    //             }
    //         }

    //         setLoading(true);
    //         handleSetValues('error', false);

    //         const entity = 'set-password/';
    //         const payload = {
    //             email: values.email,
    //             password: values.password,
    //             verification_code: values.code,
    //         };

    //         // console.log('payload');console.log(payload);return;

    //         APIService.createInstance(entity, payload)
    //         .then(handlePasswordRes)
    //         .catch(handleError)
    //     }
    // };

    const sPProceedBtnProps = (message='Proceed', otherArgs={}) => ({
        // onClick: handleProceed,
        children: message,
        disabled: loading,
        startIcon: loading && <CircularProgress {...sPProgressProps}/>,
        className: `${signUpPageClasses?.proceedBtn} ${currDarkThemeClass}`,
        // sx: {
        //     color: '#FFFFFF',
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     // mt: '20px',
        //     my: '20px',
        //     p: '16px',
        //     textTransform: 'none',
        //     width: '472px',
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
        type: 'submit',
        ...otherArgs
    });

    const sPEnterActionTypoProps = {
        className: `${signUpPageClasses?.enterActionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        //     mt: '16px',
        // },
    };

    const sPResendActionTypoProps = {
        className: `${signUpPageClasses?.resendActionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        //     mt: '20px',
        // },
    };

    const resendCodeMsg = remainingTime > 0 ? `Didn’t Recieve the code? You can Resend in ${remainingTime} seconds.`
                                            : remainingTime === 0 ? `Didn’t Recieve the code? You can Resend it Now.`
                                            : `Please retry after 24 hours.`

    const sPInstructionsBoxProps = {
        component: 'ul',
        className: `${signUpPageClasses?.instructionsBox} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '20px',
        //     pl: '8px',
        // },
    };

    const sPInstructionTypoProps = {
        component: 'li',
        className: `${signUpPageClasses?.instructionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: 1.5,
        //     ml: '16px',
        // },
    };

    const instructionsList = instructions.map((e, i) =>
        <Typography key={i} {...sPInstructionTypoProps}>{e}</Typography>
    );

    return (
        <SetupOutlinePage {...sPOutlineProps}>
            <Box {...sPBoxProps}>
                {
                    values?.emailStage ?
                    <>
                        <Typography {...sPInfoTypoProps}>{signUpInfo}</Typography>
                        <Typography {...sPActionTypoProps}>{actionTitle}</Typography>
                        <Box {...sPFormBoxProps}>
                        {/* <form onSubmit={handleProceed}> */}
                            <Box {...sPNameBoxProps}>
                                <TextField {...sPTextFieldProps(...firstNameArgs)} />
                                <TextField {...sPTextFieldProps(...lastNameArgs)} />
                            </Box>
                            <TextField {...sPTextFieldProps('email', otherEmailArgs)} />
                            <Button {...sPProceedBtnProps('Proceed', otherEmailBtnArgs)} />
                        {/* </form> */}
                        </Box>
                    </>
                    : values?.verificationStage ?
                    <>
                        <Typography {...sPInfoTypoProps}>{verificationInfo}</Typography>
                        <Typography {...sPActionTypoProps}>{verificationTitle}</Typography>
                        <Typography {...sPEnterActionTypoProps}>{enterCodeMsg}</Typography>
                        <Box {...sPFormBoxProps}>
                        {/* <form onSubmit={handleProceed} > */}
                            <TextField {...sPTextFieldProps('code', otherCodeArgs)} />
                            <Typography {...sPResendActionTypoProps}>{resendCodeMsg}</Typography>
                            <Button {...sPProceedBtnProps('Verify Code', otherVerifyBtnProps)} />
                        {/* </form> */}
                        </Box>
                    </>
                    : !values?.basicSetupComplete ?
                    <>
                        <Typography {...sPInfoTypoProps}>{finalizingInfo}</Typography>
                        <Typography {...sPActionTypoProps}>{finalizingTitle}</Typography>
                        <Box {...sPFormBoxProps}>
                        {/* <form onSubmit={handleProceed}> */}
                            <TextField {...sPTextFieldProps('password', otherPasswordProps)} />
                            <TextField {...sPTextFieldProps('confirmPassword', otherConfirmPasswordProps)} />
                            <Box {...sPInstructionsBoxProps}>
                                {instructionsList}
                            </Box>
                            <Button {...sPProceedBtnProps('Submit and Signup', otherSubmitBtnProps)} />
                        {/* </form> */}
                        </Box>
                    </>
                    : values?.allowFarmAdd ?
                    <>
                        <Typography {...sPActionTypoProps}>{accountCreatedTitle}</Typography>
                        <Typography {...sPNextActionTypoProps}>{nextStepMsg}</Typography>
                        <Typography {...sPInfoTypoProps}>{nextStepInfoMsg}</Typography>
                        <Button {...sPProceedBtnProps('Add your farm', otherAddFarmArgs)} />
                    </>
                    : <></>
                }
            </Box>
        </SetupOutlinePage>
    );
}