import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { EditIcon } from "./CustomSvgIcons";

import { cardClasses } from "./constants";

export default function PlansDetailInfoCard(props) {

    const {
        cardEntries=[],
        cardTitle,
        currDarkThemeClass='',
        extraContentMarginTop,
        iconFill,
        iconStroke,
        onClick=() => {},
        otherBoxProps={},
        startIcon,
        toAllowEdit=true,
    } = props;

    const theme = useTheme();

    const pDCBoxProps = {
        className: `${cardClasses?.plansDetailInfo?.box} ${currDarkThemeClass}`,
        sx: {
            // backgroundColor: 'primary.200',
            // minWidth: 'fit-content', // apply here and/or set flexShrink: 0 on parent?
            // px: '28px',
            // py: '24px',
            // width: '100%',
            ...otherBoxProps,
        },
    };

    const pDCHeaderBoxProps = {
        className: `${cardClasses?.plansDetailInfo?.headerBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        // },
    };

    const pDCHeaderTypoProps = {
        className: `${cardClasses?.plansDetailInfo?.headerTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '25px',
        //     fontWeight: 700,
        //     lineHeight: 1.16,
        //     whiteSpace: 'nowrap',
        // },
    };

    const pDCEditIconProps = {
        className: `${cardClasses?.plansDetailInfo?.editIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '24px',
        //     width: '24px',
        // },
    };

    const pDCHeaderEditBtnProps = {
        children: 'Edit',
        disableRipple: true,
        onClick,
        startIcon: startIcon ?? <EditIcon {...pDCEditIconProps} />,
        className: `${cardClasses?.plansDetailInfo?.editBtn} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'transparent',
        //     color: 'grey.700',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     textTransform: 'none',
        //     whiteSpace: 'nowrap',
        //     '&:hover': {
        //         backgroundColor: 'transparent',
        //     },
        // },
    };

    const pDCContentBoxProps = {
        className: `${cardClasses?.plansDetailInfo?.contentBox} ${currDarkThemeClass}`,
        sx: {
            // display: 'flex',
            // flexDirection: 'column',
            mt: extraContentMarginTop ?? 0, // extraContentMarginTop === '12px' for right side box
        },
    };

    const pDCEntryBoxProps = {
        className: `${cardClasses?.plansDetailInfo?.entryBox} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '16px',
        // },
    };

    const pDCEntryKeyTypoProps = {
        className: `${cardClasses?.plansDetailInfo?.entryKeyTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        // },
    };

    const pDCEntryValueTypoProps = {
        className: `${cardClasses?.plansDetailInfo?.entryValueTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        // },
    };

    const pDCEntryValueIconProps = {
        className: `${cardClasses?.plansDetailInfo?.entryValueIcon} ${currDarkThemeClass}`,
        sx: {
        //     height: '24px',
        //     mr: '10px',
        //     width: '24px',
        //     verticalAlign: 'middle',
            '& path': {
                fill: currDarkThemeClass ? theme.palette.primary['600'] : theme.palette.grey['500'],

                ...(iconFill && {
                    fill: currDarkThemeClass ? theme.palette.primary['600'] : iconFill,
                }),
                ...(iconStroke && {
                    stroke: currDarkThemeClass ? theme.palette.primary['600'] : iconStroke,
                    fill: 'none',
                }),
            },
        },
    };


    const keyValueList = cardEntries.map((e, i) => 
        <Box key={i} {...pDCEntryBoxProps}>
            {
                e.key && <Typography {...pDCEntryKeyTypoProps}>{e.key}</Typography>
            }
            <Typography {...pDCEntryValueTypoProps}>
                {e?.icon && e?.icon(pDCEntryValueIconProps)}
                {e.value}
            </Typography>
        </Box>
    );


    return (
        <Box {...pDCBoxProps}>
            <Box {...pDCHeaderBoxProps}>
                <Typography {...pDCHeaderTypoProps}>{cardTitle ?? 'Unknown'}</Typography>
                {
                    toAllowEdit && <Button {...pDCHeaderEditBtnProps} />
                }
            </Box>
            <Box {...pDCContentBoxProps}>
                {keyValueList}
            </Box>
        </Box>
    );
}