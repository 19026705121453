import { Checkbox, FormControlLabel } from "@mui/material";

export const CheckboxField = (props) => {
    const { value, onChange, disabled = false, label } = props;

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={disabled}
                    sx={{'& .MuiSvgIcon-root': { fontSize: 32, justifyItems: "center", alignItems: "center", display: "flex" },
                        color: '#6F745F',
                    }}
                />
            }
            label={label || ''}
        />
    );
};
