import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import TungstenIcon from '@mui/icons-material/Tungsten';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { CropRunIcon, DashboardIcon, FarmsIcon, LogisticsIcon, LogoutIcon, PlansIcon, ProfileIcon, SettingsIcon } from "./CustomSvgIcons";

import { 
    accountAppLabel, dashboardModelName, 
    cropRunHomeRoute, farmsHomeWithTabRoute, growExecutiveRoute, landingPageRoute,
    crudActions, 
    dashNavCollapsedWd, dashNavWd, cropPlanHomeWithTabRoute,
    getDarkThemeClass, DARK_MODE, LIGHT_MODE,
    dashdrawerClasses,
    logoutUser,
    plansHomeWithTabRoute,
    cropsHomeWithTabRoute,
    growCommandCenter,
    configurationsHomeRoute,
    logisticsHomeRoute,
    logisticsHomeWithTabRoute
} from "./constants";

import sg_dash from '../../images/sg_dash_16x16.png';
import getTokenDetails from "../../auth/getTokenDetails";
import useThemeVal from "../../utility/useThemeVal";
import useControlledNavigate from "../../utility/useControlledNavigate";
import { NavControlContext } from "../../App";

// TODO: Close dashProfileMenu if open when menubar is being collapsed

const dashProfileMenuIconProps = {
    sx: {
        height: '20px',
        width: '20px',
    },
};

const openedMixin = (theme) => ({
    overflowX: 'hidden',
    position: 'relative',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: `${dashNavWd}px`,
});

const closedMixin = (theme) => ({
    overflowX: 'hidden',
    position: 'relative',
    transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.leavingScreen,
        easing: theme.transitions.easing.sharp,
    }),
    width: `${dashNavCollapsedWd}px`,
});

const loginTypo = 'LOGGED IN AS';


export default function DashDrawer(props) {
    const {
        toOpen=true,
        setToOpen,
        tokenChange,
        collapseDrawer=false,
        mode,
        themeMode,
        handleSetParentValues,
        isLazyLoading=false,
    } = props;

    const [expandAccordion, setExpandAccordion] = useState(false);

    const theme = useTheme(); // ensure the global theme is inherited; Any intermediate theme might have different behavior 

    const [values, setValues] = useState({});
    
    const navControlContext = useContext(NavControlContext);

    const baseNavigate = useNavigate();
    // const navigate = useControlledNavigate();
    // const navigate = navControlContext?.contextValue?.navigate;
    const {navigate=baseNavigate, isNavModified=false, defaultNav=baseNavigate} = navControlContext?.contextValue || {};

    const profileLinks = useMemo(() => [
        {key: theme?.palette?.themeMode === DARK_MODE ? 'Light Theme' : 'Dark Theme', link: '/dark-theme', icon: theme?.palette?.themeMode === DARK_MODE ? <TungstenIcon {...dashProfileMenuIconProps} /> : <Brightness3Icon {...dashProfileMenuIconProps}/> , isActionBtn: true},

        {key: 'Organization', link: '/organization', icon: <ProfileIcon {...dashProfileMenuIconProps} />},
        {key: 'Profile', link: '/profile', icon: <ProfileIcon {...dashProfileMenuIconProps} />},
        {key: 'Settings', link: '/settings', icon: <SettingsIcon {...dashProfileMenuIconProps} />},
        {key: 'Logout', link: null, icon: <LogoutIcon {...dashProfileMenuIconProps} />, isLogoutBtn: true},
    ], [theme?.palette?.themeMode]);

    // const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);
    const currDarkThemeClass = useThemeVal();

    const {token, tokenDetails} = getTokenDetails();

    const {permissions=[], is_staff=false} = tokenDetails || '';
    
    const growDashPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${dashboardModelName}`)) ?? [], [token]);
    
    const growDashPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (growDashPermissions?.includes(`${accountAppLabel}.${crudActions?.[action]}_${dashboardModelName}`) ?? false)]));
    
    const defaultTabValue = isLazyLoading ? '' : growDashPerms?.read ? 'Executive' : 'Farms';

    const [selectedTab, setSelectedTab] = useState(defaultTabValue);

    const role = is_staff ? 'ADMIN' : 'USER';

    const avatarInitials = ( (values?.first_name?.[0] ?? '') + (values?.last_name?.[0] ?? '') ) || 'SG';

    const avatarTypo = values?.first_name ?? 'SmartGrow';

    const validTabs = useMemo(() => {
        
        const includedTabs = [
            { key: 'command-center', tabValue: 'Command Center'},
            { key: 'configurations', tabValue: 'Configurations'},
            // { key: 'farms', tabValue: 'Farms', },
            // { key: 'plans', tabValue: 'Plans', },
            { key: 'crops', tabValue: 'Crops', },
            { key: 'logistics', tabValue: 'Logistics'},
        ];

        if (growDashPerms?.read) {
            includedTabs.push({ key: 'executive', tabValue: 'Executive' });
        }

        return includedTabs;
    }, [growDashPerms?.read]);

    const tab = window.location.pathname.split('/')?.[1]?.toLowerCase();

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    useEffect(() => {
        const currentTab = validTabs.find(e => e.key.toLowerCase() === tab)?.tabValue;
        setSelectedTab(currentTab);
    }, [tab]);

    useEffect(() => {
        const {tokenDetails: {first_name, last_name}} = getTokenDetails();
        
        setValues(state => ({ ...state, first_name, last_name }));

    }, [tokenChange]);

    useEffect(() => {
        if (!token) return;

        const {is_staff} = tokenDetails;

        handleSetValues('is_staff', is_staff);

    }, [token]);

    const dashCollapseProps = {
        id: 'dashdrawer-collapse',
        in: !collapseDrawer,
        orientation: 'horizontal',
    };

    const drawerProps = {
        className: `${dashdrawerClasses?.drawer} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
        elevation: 0,
        open: true,
        sx: {
            // 09022023: TODO: Check if these mixin styles can be moved over to dashdrawer.scss
            ...(toOpen && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!toOpen && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        },
        variant: 'permanent',
    };

    const handleDrawer = () => {
        setExpandAccordion(false);
        setToOpen(state => !state);
    }

    const dashSGLogoBoxProps = {
        className: `${dashdrawerClasses?.dashLogoBox} ${currDarkThemeClass}`,
        onClick: handleDrawer,
    };

    const dashSGTypoProps = {
        className: `${dashdrawerClasses?.dashLogoTypo} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
        onClick: () => toOpen ? navigate(landingPageRoute) : undefined,
    };

    const APP_TITLE = 'SMARTGROW';
    const APP_INITIALS = 'SG';

    const dashLogoPaperProps = {    
        className: `${dashdrawerClasses?.dashLogoPaper} ${currDarkThemeClass}`,
        square: true,
        variant: 'none',
    };

    const dashSGLogoImgProps = {
        alt: "Smartgrow logo",
        className: `${dashdrawerClasses?.dashLogoImg} ${currDarkThemeClass}`,
        component: 'img',
        src: sg_dash,
    };

    const dashLogo = (
        <Paper {...dashLogoPaperProps}>
            <Box {...dashSGLogoBoxProps}>
                {/* <img src={sg_dash} alt="Smartgrow logo" width='16px' height='16px' style={{verticalAlign: 'top'}} /> */}
                <Box {...dashSGLogoImgProps} />
            </Box>
            
            <Typography {...dashSGTypoProps}>
                {toOpen ? APP_TITLE : APP_INITIALS}
            </Typography>
        </Paper>
    );

    const handleTabSelect = (key, link) => {
        if (!isNavModified) {
            setSelectedTab(key);
        }

        navigate(link);
    } 

    const dashFooterPaperProps = {
        className: `${dashdrawerClasses?.dashFooterPaper} ${currDarkThemeClass}`,
        variant: 'none',
    };

    const dashFooterDividerProps = {
        className: `${dashdrawerClasses?.dashFooterDivider} ${currDarkThemeClass}`,
    };

    const accListItemProps = {
        className: `${dashdrawerClasses?.accListItem} ${currDarkThemeClass}`,
        disablePadding: true,
    };

    const accListTextProps = {
        className: `${dashdrawerClasses?.accListText} ${currDarkThemeClass}`,
    };

    const listItemProps = {
        className: `${dashdrawerClasses?.listItem} ${currDarkThemeClass}`,
        disablePadding: true,
    };

    const listButtonProps = (e) => ({
        className: `${dashdrawerClasses?.listItemBtn} ${currDarkThemeClass}`,
        onClick: () => handleTabSelect(e.key, e.link),
        selected: e.key === selectedTab,
    });

    const listIconProps = (e) => ({
        className: `${dashdrawerClasses?.listItemIcon} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed} ${e.key !== selectedTab ? '' : dashdrawerClasses?.tabSelected}`,
    });

    const dashIconProps = (tabName) => ({
        className: `${dashdrawerClasses?.dashIcon} ${currDarkThemeClass} ${selectedTab === tabName ? dashdrawerClasses?.tabSelected : ''}`,
    });

    const dashMenuPaperProps = {
        className: `${dashdrawerClasses?.dashMenuPaper} customScroll ${currDarkThemeClass}`,
        component: 'ul',
        square: true,
        sx: {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey.600', // *NOTE: The color applied via customScroll is grey.300; So this style should be left as it is 
            },
        },
        variant: 'none',
    };

    const dashLinks = [
        {key: 'Command Center', link: `${growCommandCenter}`, icon: <FarmsIcon {...dashIconProps('Command Center')}/>},
        {key: 'Configurations', link: `${configurationsHomeRoute}`, icon: <PlansIcon {...dashIconProps('Configurations')} />},
        // {key: 'Farms', link: `${farmsHomeWithTabRoute}`, icon: <FarmsIcon {...dashIconProps('Farms')} />},
        // {key: 'Plans', link: `${plansHomeWithTabRoute}`, icon: <PlansIcon {...dashIconProps('Plans')} />},
        {key: 'Crops', link: `${cropsHomeWithTabRoute}`, icon: <CropRunIcon {...dashIconProps('Crops')} />},
        {key: 'Logistics', link: `${logisticsHomeRoute}`, icon: <LogisticsIcon {...dashIconProps('Logistics')} />},
        // {key: 'Logistics', link: `${logisticsHomeWithTabRoute}}`, icon: <CropRunIcon {...dashIconProps('Logistics')} />},
    ];

    if(growDashPerms?.read) {
        dashLinks.unshift({key: 'Executive', link: `${growExecutiveRoute}`, icon: <DashboardIcon {...dashIconProps('Executive')} />});
    }

    const listTextProps = (e) => ({
        className: `${dashdrawerClasses?.listItemText} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed} ${e.key !== selectedTab ? '' : dashdrawerClasses?.tabSelected}`,
    });

    const dashMenuItems = dashLinks.map((e, i) => (
        <ListItem key={i} {...listItemProps}>
            <Tooltip title={ toOpen ? '' : e?.key}>
                <ListItemButton {...listButtonProps(e)}>
                    <ListItemIcon {...listIconProps(e)}>
                        {e.icon}
                    </ListItemIcon>
                    <ListItemText primary={e.key} {...listTextProps(e)} />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    ));

    const dashProfilePaperProps = {
        className: `${dashdrawerClasses?.dashProfilePaper} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
        variant: 'none',
    };

    const dashLoginTypoProps = {
        className: `${dashdrawerClasses?.dashLoginTypo} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
    };

    const dashRoleTypoProps = {
        className: `${dashdrawerClasses?.dashRoleTypo} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
    };

    const handleProfileItemClick = (e) => () => {
        if (e.isActionBtn) {
            localStorage.setItem('themeMode', themeMode === DARK_MODE ? LIGHT_MODE : DARK_MODE); // To preserve dark theme upon reload
            handleSetParentValues('themeMode', themeMode === DARK_MODE ? LIGHT_MODE : DARK_MODE);

        } else if (e.isLogoutBtn) {
            logoutUser();

            // navigate(landingPageRoute); // 
            defaultNav(landingPageRoute);
            
        } else if (e.link && e.link !== 'settings') {
            navigate(e.link);
        }
    }

    const accListButtonProps = (e) => ({
        className: `${dashdrawerClasses?.accListBtn} ${currDarkThemeClass}`,
        onClick: handleProfileItemClick(e),
        selected: e.link === `/${tab}`,
    });

    const accListIconProps = (e) => ({
        className: `${dashdrawerClasses?.accListIcon} ${currDarkThemeClass} ${e.link === `/${tab}` ? dashdrawerClasses?.tabSelected : ''}`,
    });
    
    const profileMenuItems = profileLinks.filter(item => values?.is_staff ? true : item.link !== '/organization').map((e, i) => (
        <ListItem key={i} {...accListItemProps}>
            <ListItemButton {...accListButtonProps(e)}>
                <ListItemIcon {...accListIconProps(e)}>{e.icon}</ListItemIcon>
                <ListItemText primary={e.key} {...accListTextProps} />
            </ListItemButton>
        </ListItem>
    ));
    
    const dashAccordionProps = {
        className: `${dashdrawerClasses?.dashAccordion} ${currDarkThemeClass}`,
        disabled: !toOpen,
        expanded: expandAccordion,
        onChange: (event, expanded) => setExpandAccordion(expanded),
        square: true,
        variant: 'none',
    };    

    const dashAvatarTypoProps = {
        className: `${dashdrawerClasses?.dashAvatarTypo} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
    };

    const dashAccSummaryProps = {
        className: `${dashdrawerClasses?.dashAccSummary} ${currDarkThemeClass}`,
        expandIcon: toOpen && <ExpandLessIcon sx={{color: 'primary.300'}} />,
    };

    const dashAvatarProps = {
        className: `${dashdrawerClasses?.dashAvatar} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
    };

    const dashAccDetailsProps = {
        className: `${dashdrawerClasses?.dashAccDetails} ${currDarkThemeClass} ${toOpen ? '' : dashdrawerClasses?.drawerClosed}`,
    };

    const dashProfile = (
        <Paper {...dashFooterPaperProps}>
            <Divider {...dashFooterDividerProps} />
            <Paper {...dashProfilePaperProps}>
                <Typography {...dashLoginTypoProps}>{loginTypo}</Typography>
                <Typography {...dashRoleTypoProps}>{role}</Typography>
                
                <Accordion {...dashAccordionProps}>
                    <AccordionSummary {...dashAccSummaryProps}>
                        <Avatar {...dashAvatarProps}>{avatarInitials}</Avatar>
                                        
                        <Collapse in={toOpen} orientation='horizontal'>
                            <Typography {...dashAvatarTypoProps}>{avatarTypo}</Typography>
                        </Collapse>
                       
                    </AccordionSummary>
                    
                    <AccordionDetails {...dashAccDetailsProps}>
                        {profileMenuItems}
                    </AccordionDetails>                
                </Accordion>
            </Paper>
        </Paper>
    );

    return collapseDrawer ? <></>
        : (
            <Drawer {...drawerProps}>
                {dashLogo}
                <Paper {...dashMenuPaperProps}>
                    {dashMenuItems}
                </Paper>
                {dashProfile}
            </Drawer>
        );
}
