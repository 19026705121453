import { useEffect } from "react";
import { select, arc } from "d3";

import BaseChart from "./BaseChart";

import { chartClasses } from "../constants";


export default function ProgressRing(props) {

    const {
        completionCent=0,
        currChartClasses=chartClasses,
        currDarkThemeClass='',
        outerRadius=126,
        radDiff=28,
        title='Completed',
        value='0%',
    } = props;

    useEffect(() => {
        const svg = select(`svg.${currChartClasses?.progressRing?.svg}`);

        // const radDiff = 28;
        // const outerRadius = 126;
        const innerRadius = outerRadius - radDiff;
        
        const innerWrapper = svg.select(`.${currChartClasses?.progressRing?.innerWrapper}`)
        
        const plotArea = innerWrapper.select(`g.${currChartClasses?.progressRing?.plotArea}`);

        svg
            .style('width', 2 * outerRadius)
            .style('height', outerRadius);
        
        plotArea.html(null);

        const baseArcGen = arc()
                        .innerRadius(innerRadius)
                        .outerRadius(outerRadius)
                        .startAngle(-Math.PI / 2)
                        .endAngle(Math.PI / 2)
                        .cornerRadius(radDiff);
                
        const progArcGen = arc()
                        .innerRadius(innerRadius)
                        .outerRadius(outerRadius)
                        .startAngle(-Math.PI / 2)
                        .endAngle((-Math.PI / 2) + (completionCent * Math.PI))
                        .cornerRadius(radDiff);

        innerWrapper.style('transform', `translate(50%, 100%)`);

        plotArea
            .append('path')
            .attr('d', baseArcGen);

        plotArea
            .append('path')
            .attr('class', currChartClasses?.progressRing?.completionRing)
            .attr('d', progArcGen);

        plotArea
            .append('text')
            .attr('class', currChartClasses?.progressRing?.title) // 10-04-2023: Since this text will be custom to each parent component, another class passed from parent will be added 
            .text(title);
        
        plotArea
            .append('text')
            .attr('class', currChartClasses?.progressRing?.value) // 10-04-2023: Since this text will be custom to each parent component, another class passed from parent will be added 
            .text(value);

    }, []);

    useEffect(() => {
        if (completionCent > 1 || completionCent < 0) return;

        const svg = select(`svg.${currChartClasses?.progressRing?.svg}`);
        
        const innerWrapper = svg.select(`.${currChartClasses?.progressRing?.innerWrapper}`)
        
        const plotArea = innerWrapper.select(`g.${currChartClasses?.progressRing?.plotArea}`);

        const innerRadius = outerRadius - radDiff;
        
        const progArcGen = arc()
                            .innerRadius(innerRadius)
                            .outerRadius(outerRadius)
                            .startAngle(-Math.PI / 2)
                            .endAngle((-Math.PI / 2) + (completionCent * Math.PI))
                            .cornerRadius(radDiff);

        const completionPathElem = plotArea.select(`.${currChartClasses?.progressRing?.completionRing}`);

        completionPathElem.attr('d', progArcGen);

        const valueElem = plotArea.select(`.${currChartClasses?.progressRing?.value}`);

        valueElem.text(value);

    }, [value]);

    const progressRingProps = {
        currDarkThemeClass,
        otherClasses: {
            svg: currChartClasses?.progressRing?.svg,
            innerWrapper: currChartClasses?.progressRing?.innerWrapper,
            plotArea: currChartClasses?.progressRing?.plotArea,
        },
    };
    
    return <BaseChart {...progressRingProps} />;
}