import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DetailAndDelBtn,
  DownArrowIcon,
  EyeIcon,
  FRTLPopup,
  PLANT_TYPES,
  cardClasses,
  cropClasses,
  cropplanStrainEndpoint,
  errFeedbackFn,
  miscClasses,
} from '../../utility';
import CropRunFRTLPopup from '../../utility/popups/CropRunFRTLPopup';
import APIService from '../../../api/apiService';
import { FRTLContext } from '../../../context/FRTLContext';

const tableTitles = [
  { title: 'Batch Name', key: 'name' },
  { title: 'Mother FRTL', key: 'frtl' },
  { title: 'Mother Plant ID', key: 'mp_tag_number', defShow: true },
  { title: 'Package ID', key: 'package_id', defShow: true },
  { title: 'From Type', key: 'plant_type' },
  { title: 'Plant Count', key: 'plant_count' },
  { title: 'Quality Plan', key: 'qualityplan' },
  { title: 'Phase Plan', key: 'phaseplan' },
  { title: 'General Functions', key: 'action' },
];

export function StrainAccordion(props) {
  const {
    strain,
    currDarkThemeClass,
    selectedFarmId,
    values,
    handleSetValues,
    qualityPlansList = [],
    phasePlansList = [],
    handleErrorWithFeedback,
    cropPlanPerms,
  } = props;
  const [toExpand, setToExpand] = useState(true);

  const [frtlPopupOpen, setFrtlPopupOpen] = useState(false);
  const [frtlPopupDetailChildren, setFrtlPopupDetailChildren] = useState({});
  const {frtlContextValue, setFrtlContextValue} = useContext(FRTLContext)


  useEffect(() => {
    if (!frtlContextValue) {
  const fetchFrtlData = async () => {
    try {
      const response = await APIService.fetchInstance("new/frtl/");
      setFrtlContextValue(response?.data);
    } catch (error) {
      console.error("Error fetching FRTL data:", error);
    }};
      fetchFrtlData();
    }
  }, [frtlContextValue, setFrtlContextValue]);

  const handleFrtlPopupOpen = (batch_id, frtl) => {
    const [farm, room, table, level] = frtl?.split('.').map(e => +e ?? e) ?? [props?.farm?.id ?? '', props?.room ?? '', '', ''];
    setFrtlPopupOpen(true);
    setFrtlPopupDetailChildren({
      currDarkThemeClass,
      farm,
      room,
      table,
      level,
      selectedFarmId,
      handleEditFrtl: value => handleBatchLevelValues(batch_id, 'frtl', value),
    });
  };

  const handleFrtlPopupClose = () => {
    setFrtlPopupOpen(false);
  };

  const plantCount = useMemo(() => strain?.batches?.reduce((acc, curr) => acc + +curr?.plant_count, 0), [strain]);

  // handleSetValues utility at strains level
  const handleStrainLevelValues = (prop, value) => {
    handleSetValues(
      'strains',
      values?.strains?.map(st =>
        (st?.id && st?.id === strain?.id) || (st?.tempRef && st?.tempRef === strain?.tempRef) ? { ...st, [prop]: value } : st
      )
    );
  };

  // handleSetValues utility at batch level
  const handleBatchLevelValues = (batch_id, prop, value) => {
    handleSetValues(
      'strains',
      values?.strains?.map(st =>
        (st?.id && st?.id === strain?.id) || (st?.tempRef && st.tempRef === strain?.tempRef)
          ? {
              ...st,
              batches: strain?.batches?.map(batch =>
                (batch?.id && batch?.id === batch_id) || (batch?.tempRef && batch?.tempRef === batch_id) ? { ...batch, [prop]: value } : batch
              ),
            }
          : st
      )
    );
  };

  const titles = useMemo(
    () =>
      tableTitles.filter(
        title => !title.defShow || (title.defShow && strain?.batches?.every(batch => title.key in batch && batch?.[title.key] !== null))
      ),
    [strain]
  );

  const eCPProps = {
    className: `${cardClasses.strainAccordion?.accordion} ${currDarkThemeClass}`,
    elevation: 0,
    expanded: toExpand,
    onChange: () => setToExpand(!toExpand),
    square: true,
  };

  const eCPSummaryProps = {
    className: `${cardClasses?.strainAccordion?.accordionSummary} ${currDarkThemeClass}`,
    expandIcon: <DownArrowIcon className={`${cardClasses?.strainAccordion.accordionExpandIcon} ${currDarkThemeClass}`} />,
  };

  const tableTitleCellProps = {
    className: `${cardClasses.strainAccordion.tableTitleCell} ${currDarkThemeClass}`,
  };

  const tableTitlesList = titles.map((e, i) => (
    <TableCell key={i} {...tableTitleCellProps}>
      {e.title}
    </TableCell>
  ));

  const tableContainerProps = {
    className: `${cardClasses.strainAccordion.tableHead} ${currDarkThemeClass}`,
  };

  const textFieldProps = ({ prop, inputProps = {}, otherTextProps = {}, otherTextFieldClasses = '', batch_id = null }) => ({
    autoComplete: 'off',
    className: `${cardClasses?.strainAccordion?.textField} ${currDarkThemeClass} ${otherTextFieldClasses}`,
    disabled: values?.processing,
    inputProps,
    required: true,
    value: strain?.[prop],

    onChange: ({ target: { value } }) => handleBatchLevelValues(batch_id, prop, value),

    ...otherTextProps,
  });

  const nameProps = (batch, batch_id) => ({
    prop: 'name',
    batch_id,
    otherTextProps: {
      value: batch?.name ?? '',
    },
  });

  const getFrtlTooltipContent = (frtl) => {
    if ( !frtlContextValue) {
      return (
        <Box className={`${cardClasses.tooltip} ${currDarkThemeClass}`}>
          <Typography>Loading FRTL data...</Typography>
        </Box>
      )}
  
    const [farmId, roomId, tableId, levelId] = (frtl?.split('.') || []).map(e => {
      const num = parseInt(e);
      return isNaN(num) ? 0 : num;
    });
  
    const farm = frtlContextValue.farms?.find(f => f.id === farmId) || { id: farmId, farm_name: 'Unknown Farm' };
  
    return (
      <Box className={`${cardClasses.tooltip} ${currDarkThemeClass}`}>
        <Typography variant="body2">Farm #{farm.id} {farm.farm_name}</Typography>
        {roomId === 0 ? ( 
          <Typography variant="body2">Room #0 All Rooms</Typography> ) : (
          <Typography variant="body2"> Room #{roomId} {frtlContextValue.rooms?.find(r => r.farm_id === farmId && r.id === roomId)?.room_name || 'Unknown Room'} </Typography> )}
        
        {tableId === 0 ? (
          <Typography variant="body2">Table #0 All Tables</Typography> ) : (
          <Typography variant="body2"> Table #{tableId} {frtlContextValue.tables?.find(t => t.room_id === roomId && t.id === tableId)?.name || 'Unknown Table'} </Typography> )}
        
        {levelId === 0 ? (
          <Typography variant="body2">Levels #0 All Levels</Typography> ) : (
          <Typography variant="body2"> Level #{levelId} {frtlContextValue.levels?.find(l => l.roomtable_id === tableId && l.id === levelId)?.name || 'Unknown Level'} </Typography> )}
      </Box>
    );
  };
  
  const frtlProps = (batch, batch_id) => ({
    prop: 'frtl',
    batch_id,
    otherTextProps: {
      label: 'FRTL Notes',
      disabled: true,
      value: batch?.frtl ?? '',
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleFrtlPopupOpen(batch_id, batch?.frtl)}>
              <EyeIcon />
            </IconButton>
          </InputAdornment>
        ),
      },
    },
  });

  const plantCountProps = (batch, batch_id) => ({
    prop: 'plant_count',
    batch_id,
    otherTextProps: {
      value: batch?.plant_count,
    },
    inputProps: {
      type: 'number',
      min: 0,
    },
  });

  const qpProps = (batch, batch_id) => ({
    prop: 'qualityplan',
    batch_id,
    otherTextProps: {
      value: batch?.qualityplan,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  });

  const ppProps = (batch, batch_id) => ({
    prop: 'phaseplan',
    batch_id,
    otherTextProps: {
      value: batch?.phaseplan,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  });

  const mpProps = (batch, batch_id) => ({
    prop: 'mp_tag_number',
    batch_id,
    otherTextProps: {
      value: batch?.mp_tag_number ?? '',
    },
  });

  const pidProps = (batch, batch_id) => ({
    prop: 'package_id',
    batch_id,
    otherTextProps: {
      value: batch?.package_id ?? '',
    },
  });

  const cropPlanMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const plantTypesList = PLANT_TYPES.map((e, i) => (
    <MenuItem key={i} value={e.value} {...cropPlanMenuItemProps}>
      {e.label}
    </MenuItem>
  ));

  const pTProps = (batch, batch_id) => ({
    prop: 'plant_type',
    batch_id,
    otherTextProps: {
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
      value: batch?.plant_type,
    },
  });

  const handleStrainDelRes = id => {
    const key = isNaN(+id) ? 'tempRef' : 'id';
    handleSetValues(
      'strains',
      values?.strains?.filter(e => e?.[key] !== id)
    );
  };

  const handleDeleteStrain = strain => () => {
    if (!cropPlanPerms?.delete) return;

    if ('id' in strain) {
      let errConfig = {};
      const entityWithID = cropplanStrainEndpoint(strain?.id);
      APIService.deleteInstance(entityWithID)
        .then(handleStrainDelRes(strain?.id))
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
    } else {
      handleStrainDelRes(strain?.tempRef);
    }
  };

  const handleBatchDelete = batch_id => () => {
    if (!cropPlanPerms?.delete) return;

    if (isNaN(+batch_id)) {
      handleStrainLevelValues(
        'batches',
        strain?.batches?.filter(batch => (batch?.id && batch?.id !== batch_id) || (batch?.tempRef && batch?.tempRef !== batch_id))
      );
      return;
    }

    const entityWithID = `batches/${batch_id}/`;
    const errConfig = {};

    APIService.deleteInstance(entityWithID)
      .then(() =>
        handleStrainLevelValues(
          'batches',
          strain?.batches?.filter(batch => batch?.id !== batch_id)
        )
      )
      .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
  };

  const actionProps = batch_id => ({
    toShowDetailBtn: false,
    onDelClick: handleBatchDelete(batch_id),
  });

  const delProps = {
    toShowDetailBtn: false,
    onDelClick: handleDeleteStrain(strain),
  };

  const createTableEntries = (batch, batch_id) => {
    const name = <TextField {...textFieldProps(nameProps(batch, batch_id))} />;
    // const frtl = <TextField {...textFieldProps(frtlProps(batch, batch_id))} />;
    const frtl = batch?.frtl ? (
      <Tooltip title={getFrtlTooltipContent(batch.frtl, frtlContextValue, currDarkThemeClass)} placement="left">
        <span>
          <TextField {...textFieldProps(frtlProps(batch, batch_id))} />
        </span>
      </Tooltip>
    ) : (
      <TextField {...textFieldProps(frtlProps(batch, batch_id))} />
    );
    const plant_count = <TextField {...textFieldProps(plantCountProps(batch, batch_id))} />;
    const qualityplan = <TextField {...textFieldProps(qpProps(batch, batch_id))}>{qualityPlansList}</TextField>;
    const phaseplan = <TextField {...textFieldProps(ppProps(batch, batch_id))}>{phasePlansList}</TextField>;
    const action = <DetailAndDelBtn {...actionProps(batch_id)} />;
    const plant_type = <TextField {...textFieldProps(pTProps(batch, batch_id))}>{plantTypesList}</TextField>;
    const mp_tag_number = <TextField {...textFieldProps(mpProps(batch, batch_id))} />;
    const package_id = <TextField {...textFieldProps(pidProps(batch, batch_id))} />;
    return { name, frtl, plant_count, qualityplan, phaseplan, plant_type, action, mp_tag_number, package_id };
  };

  const tableEntriesList = strain?.batches?.map((batch, i) => (
    <TableRow key={`row-${i}`}>
      {titles.map(title => {
        const v1 = createTableEntries(batch, batch?.id ?? batch?.tempRef);
        const value = v1?.[title.key] ?? values?.[title.key];
        return (
          <TableCell key={title.key} align={title.align ?? 'left'}>
            {value}
          </TableCell>
        );
      })}
    </TableRow>
  ));

  const batchTable = (
    <TableContainer>
      <Table>
        <TableHead {...tableContainerProps}>
          <TableRow>{tableTitlesList}</TableRow>
        </TableHead>

        <TableBody>{tableEntriesList}</TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Accordion {...eCPProps}>
      <AccordionSummary {...eCPSummaryProps}>
        <Typography>{strain?.strain_name ?? 'Unkown Strain'}</Typography>
        {/* //todo: convert plant count to textfield */}
        {/* <TextField {...textFieldProps(stPCProps)} /> */}
        <Box display="flex" gap={2}>
          <Typography>Plant Count {plantCount}</Typography>
          <DetailAndDelBtn {...delProps} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>{batchTable}</AccordionDetails>
      {frtlPopupOpen && <CropRunFRTLPopup open={frtlPopupOpen} onClose={handleFrtlPopupClose} {...frtlPopupDetailChildren} />}
    </Accordion>
  );
}
