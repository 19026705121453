import React, { useEffect, useMemo, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsGantt from 'highcharts/modules/gantt';
import HighchartsReact from 'highcharts-react-official';
import fullscreen from "highcharts/modules/full-screen"; 
import exporting from 'highcharts/modules/exporting';
import { Box, Button, ButtonGroup, Popover, FormControlLabel, Checkbox,Typography, DialogTitle, DialogContent , Dialog ,DialoagActions, Drawer, IconButton, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DiamondChartComponent from './DiamondChartComponent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Initialize Highcharts Gantt
HighchartsGantt(Highcharts);

// exporting(Highcharts); 

const CommandCenterChart = ({ data,statusChoices, currDarkThemeClass,selectedStates, setSelectedStates , phaseDetails,onSubphaseClick,drawerData}) => {
  const chartRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState('Crop Runs');
  // const filters = ['Crop Runs', 'Strains'];
  const filters = ['Crop Runs'];
  const allStates = Object.keys(statusChoices);
  const [anchorEl , setAnchorEl] = useState(null);
  const[openDrawer , setOpenDrawer] = useState();
  const[selectedSubphase , setSelectedSubphase] = useState(null);
  const [openDialog , setOpenDialog] = useState(false);
  const [chartType, setChartType] = useState('gantt');
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClosed = () => {
    setMenuAnchor(null);
  };

  const switchChart = (type) => {
    if (chartType !== type) {
      setChartType(type);
    }
    setMenuAnchor(null);
  };
  const handleSubphaseClick = (subphase) => {
    console.log("Subphase Clicked:", subphase);
    setSelectedSubphase(subphase);
    setOpenDialog(true);
  }
  const handleSubphaseClick1 = (subphase) =>{
    if (selectedSubphase?.id !== subphase.id) {
      setSelectedSubphase(subphase);
    }
    setOpenDrawer(true);
  }

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Close Popover
  const handleClose = () => {
    if (anchorEl !== null) {
      setAnchorEl(null);
    }
  };
  useEffect(() => {
    console.log("anchorEl state changed:", anchorEl);
  }, [anchorEl]);
  

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "All States") {
      // If "All States" is checked, check all; otherwise, clear all
      setSelectedStates(checked ? allStates : []);
    } else {
      setSelectedStates((prev) =>
        checked ? [...prev, name] : prev.filter((state) => state !== name)
      );
    }
  };
console.log("routineData", drawerData)
  // Check if Popover is Open
  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;


  const isDarkTheme = currDarkThemeClass === 'dark-theme';

  const day = 1000 * 60 * 60 * 24; // One day in milliseconds

  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  const firstCropRunStart = Math.min(...data.map(crop => new Date(crop.date_of_serialization).getTime()));
  const fiveMonthAfterStart = new Date(firstCropRunStart);
  fiveMonthAfterStart.setMonth(fiveMonthAfterStart.getMonth() + 8);
  // Calculate xAxis range
  const twoYearsBefore = new Date(today);
  twoYearsBefore.setFullYear(today.getFullYear() - 1);

  const twoYearsAfter = new Date(today);
  twoYearsAfter.setFullYear(today.getFullYear() + 1);

  const xAxisMin = twoYearsBefore.getTime();
  const xAxisMax = twoYearsAfter.getTime();
  // const allLevelColor = '#605F58'; // Define the color for all levels

  

  const processCropRunForGraph = (data, phaseDetails) => {
    let minStartDate = Infinity;
    let maxEndDate = -Infinity;

    // Log phaseDetails and crop information for debugging
   

    // Check if phaseDetails is a valid array
    if (!Array.isArray(phaseDetails)) {
        console.warn("phaseDetails is not an array or is undefined");
        return null;  // Return null if phaseDetails is not valid
    }

    // Loop through each phase detail in phaseDetails
    phaseDetails.forEach(phaseDetail => {
        
        
        // Check if this phase is relevant for the current crop
        if (
          data.strains.some(strain => 
              strain.batches.some(batch => 
                  phaseDetail.level_id === batch.cropplanstrain_id &&
                  phaseDetail.default_phaseplan === batch.phaseplan_id
              )
          )
      ){
          

            // Check if subphases is an array and exists
            if (Array.isArray(phaseDetail.subphases) && phaseDetail.subphases.length > 0) {
               

                // Loop through each subphase in subphases
                phaseDetail.subphases.forEach(subphase => {
                    const subphaseStartDate = new Date(subphase.start_date).getTime();
                    const subphaseEndDate = new Date(subphase.end_date).getTime();

                    

                    // Check if the dates are valid before processing
                    if (!isNaN(subphaseStartDate) && !isNaN(subphaseEndDate)) {
                       
                        // Update min and max dates
                        minStartDate = Math.min(minStartDate, subphaseStartDate);
                        maxEndDate = Math.max(maxEndDate, subphaseEndDate);
                    } else {
                        console.warn(`Invalid date for subphase: ${subphase.name}`);
                    }
                });
            } else {
                console.warn(`No subphases found for phase: ${phaseDetail.name}`);
            }
        }
    });

    // Log final calculated timestamps
   


    // Return the min and max date range for the crop
    return {
        actualStartTimestamp: minStartDate !== Infinity ? minStartDate : new Date().getTime(),
        actualEndTimestamp: maxEndDate !== -Infinity ? maxEndDate : new Date().getTime(),
    };
};

  
  const getCropRunDateRange = (data, phaseDetails) => {
      let minDate = Infinity;
      let maxDate = -Infinity;
    
      // Loop through each cropplan data
      data.forEach(crop => {
        // Loop through each matching phase plan detail
        phaseDetails.forEach(phaseDetail => {
          // Ensure the crop's phaseplan matches the phase plan details
          if (
            crop.strains.some(strain => 
                strain.batches.some(batch => 
                    phaseDetail.level_id === batch.cropplanstrain_id &&
                    phaseDetail.default_phaseplan === batch.phaseplan_id
                )
            )
        ) {
            // Loop through the subphases and check their start and end dates
            phaseDetail.subphases.forEach(subphase => {
              const subphaseStartDate = new Date(subphase.start_date).getTime();  // Convert to timestamp
              const subphaseEndDate = new Date(subphase.end_date).getTime();      // Convert to timestamp
              
              // Ensure valid dates
              if (!isNaN(subphaseStartDate) && !isNaN(subphaseEndDate)) {
                // Update min and max dates
                minDate = Math.min(minDate, subphaseStartDate);
                maxDate = Math.max(maxDate, subphaseEndDate);
              } else {
                console.warn(`Invalid date for subphase: ${subphase.name}`);
              }
            });
          }
        });
      });
      
      // If no valid dates found, use the current date as a fallback
      return {
        min: minDate !== Infinity ? minDate : new Date().getTime(),
        max: maxDate !== -Infinity ? maxDate : new Date().getTime(),
      };
    };
    
    // Example usage:
    const { min, max } = getCropRunDateRange(data, phaseDetails);
   
    

  const getStrainCount = (crop) => {return Array.isArray(crop.strains)? crop.strains.length : 0 ; }


  const processChartData = () => {
    if (activeFilter === 'Crop Runs') {
        const filteredData = data.filter(crop => selectedStates.includes(crop.run_status));

        return [
            // Add Crop Runs
            ...filteredData.map(crop => {
                const { actualStartTimestamp, actualEndTimestamp } = processCropRunForGraph(crop, phaseDetails) || {};
                const cropId = `CropRun_${crop.id}`;
                const fullName = crop.name || cropId;
                return {
                    name: fullName.length > 15 ? fullName.substring(0, 15) + '...' : fullName, 
                    fullName, 
                    id: cropId,
                    start: actualStartTimestamp,
                    end: actualEndTimestamp,
                    collapsed: true,
                    run_status: crop.run_status,
                    strain_count: getStrainCount(crop),
                    plant_count: crop.plant_count,
                };
            }),

            // Add Strains for each Crop Run
            ...filteredData.flatMap(crop => {
                const { actualStartTimestamp, actualEndTimestamp } = processCropRunForGraph(crop, phaseDetails);
                const cropId = `CropRun_${crop.id}`;
                return (crop.strains || []).map(strain => {
                    const strainId = `Strain_${strain.id}`;
                    const fullName = strain.strain_name || strainId;
                    return {
                        name: fullName.length > 15 ? fullName.substring(0, 15) + '...' : fullName, 
                        fullName, 
                        id: strainId,
                        parent: cropId,
                        collapsed: false,
                        start: actualStartTimestamp,
                        end: actualEndTimestamp,
                    };
                });
            }),
            

            // Add Phase Plans & Sub-Phases with Correct Start & End Dates
            ...filteredData.flatMap(crop => {
              const { actualStartTimestamp, actualEndTimestamp } = processCropRunForGraph(crop, phaseDetails);
                return (crop.strains || []).flatMap(strain => {
                    const strainId = `Strain_${strain.id}`;
                    
                    return (strain.batches || []).flatMap(batch => {
                        const phasePlanName = batch.phaseplan_name || `PhasePlan_${batch.phaseplan_id}`;
                        const phasePlanId = `${strainId}_${phasePlanName.replace(/\s/g, '_')}`;
                        const name = `${phasePlanName} (${batch.name || `Batch_${batch.id}`})`;

                        // Find matching phaseDetail
                        const matchingPhaseDetail = phaseDetails.find(detail =>
                            detail.level_id === batch.cropplanstrain_id &&
                            detail.default_phaseplan === batch.phaseplan_id
                        );

                        // Get sub-phases
                        let subPhases = [];
                        if (matchingPhaseDetail) {
                            subPhases = matchingPhaseDetail.subphases || [];
                        }

                        // ✅ DEBUG: Check Sub-Phases
                        const phasePlanStart = subPhases.length ? new Date(subPhases[0].start_date).getTime() : null;
                        const phasePlanEnd = subPhases.length ? new Date(subPhases[subPhases.length - 1].end_date).getTime() : null;

                        return [{
                            name: name.length > 15 ? name.substring(0, 15) + '...' : name,
                            fullName: name,
                            id: phasePlanId,
                            parent: strainId,
                            collapsed: false,
                            start: phasePlanStart,  // ✅ Ensure Start is Set
                            end: phasePlanEnd,  // ✅ Ensure End is Set
                        },

                            // ✅ Add Sub-Phases (Ensure Correct Start & End Dates)
                            ...subPhases.map(subphase => {
                                const subPhaseId = `${phasePlanId}_${subphase.name.replace(/\s/g, '_')}`;

                                // Convert dates properly
                                const subPhaseStart = subphase.start_date ;
                                const subPhaseEnd = subphase.end_date ;
                               
                                return {
                                    name: subphase.name.length > 15 ? subphase.name.substring(0, 15) + '...' : subphase.name,
                                    fullName: subphase.name,
                                    id: subPhaseId,
                                    parent: phasePlanId,
                                    collapsed: false,
                                    start: new Date(subphase.start_date).getTime(),  // ✅ Convert to milliseconds
    end: new Date(subphase.end_date).getTime(),  // ✅ Convert to millise
    events:{
      click: () => {
        handleSubphaseClick1(subphase); // ✅ Open popover first
        
        onSubphaseClick(subphase.id);   // ✅ Call API after opening popover
        
    },
                    },
                                };
                            }),
                        ];
                    });
                });
            }), 
      ];
    } 
    else if (activeFilter === 'Strains') {
      const strainMap = new Map();

        data.filter(crop=>selectedStates.includes(crop.run_status)).forEach(crop=>{
        const { actualStartTimestamp, actualEndTimestamp } = processCropRunForGraph(crop) || {};
        

        (crop.strains || []).forEach(strain => {
          const strainNameKey = strain.strain_name;

          const strainDetails = crop.strains_details?.find(details=> details.strain_name === strain.strain_name);
          const wetWeight = strainDetails?.wet_weight || 0;
          const yieldPercentage = strainDetails?.strain_yield || 0; // Get yield percentage
          const price = strainDetails?.flower_price || 0;
          const dryWeight = (wetWeight * yieldPercentage); 

          // Initialize strain if not already added
          if (!strainMap.has(strainNameKey)) {
            strainMap.set(strainNameKey, {
              name: strain.strain_name.length > 15 ? strain.strain_name.substring(0, 15) + '...' : strain.strain_name,
              fullName: strain.strain_name,
              id: `Strain_${strain.id}`,
              collapsed: true,
              start: actualStartTimestamp,
              end: actualEndTimestamp,
              totalPlantCount: 0, 
              totalProjection: 0,
              totalActual: 0,
              totalRevenue: 0,
              children: [],
            });
          }

          const strainEntry = strainMap.get(strainNameKey);

          // Update strain timeline
          strainEntry.start = Math.min(strainEntry.start, actualStartTimestamp);
          strainEntry.end = Math.max(strainEntry.end, actualEndTimestamp);

          strainEntry.totalPlantCount += crop.plant_count || 0; //total plant under the same strain 
          strainEntry.totalProjection = strainEntry.totalPlantCount * wetWeight; // total plant count x strain wet weight under the strain table 
          strainEntry.totalActual = strainEntry.totalPlantCount*dryWeight*price || 0; // total plant count x wet weight under the crop run plant details 
          strainEntry.totalRevenue = strainEntry.totalProjection*price || 0; // total strain wt x price under the strain table 
         
          
          // Create crop run entry
          const cropRunEntry = {
            name: crop.name.length > 15 ? crop.name.substring(0, 15) + '...' : crop.name,
            fullName: crop.name,
            id: `CropRun_${crop.id}`,
            parent: strainEntry.id,
            start: actualStartTimestamp,
            end: actualEndTimestamp,  
            collapsed: true,
            children: [],
            run_status: crop.run_status,
            plant_count: crop.plant_count,
          };

          // Group batches by phase plan
          const phasePlanMap = (strain.batches || []).reduce((acc, batch) => {
            const phasePlanName = batch.phaseplan_name || `PhasePlan_${batch.phaseplan_id}`;
            if (!acc[phasePlanName]) acc[phasePlanName] = [];
            acc[phasePlanName].push(batch);
            return acc;
          }, {});

          // Add phase plans and subphases
          Object.entries(phasePlanMap).forEach(([phasePlanName, batches]) => {
            const phasePlanId = `${cropRunEntry.id}_${phasePlanName.replace(/\s/g, '_')}`;
            const phasePlanEntry = {
              name: phasePlanName.length > 15 ? phasePlanName.substring(0, 15) + '...' : phasePlanName,
              fullName: phasePlanName,
              id: phasePlanId,
              parent: cropRunEntry.id,
              collapsed: false,
              plant_count: crop.plant_count,
              children: [],
              dataLabels: {
                enabled: false, // Disable data labels on the phase plan bar
              },
            };

            // Add subphases under phase plan
            const minStartDay = Math.min(...(crop.subphases || []).map(s => s.start_day));
            (crop.subphases || []).forEach(subphase => {
              // phasePlanEntry.children.push({
              const subphaseEntry = {
                name: subphase.name.length > 15 ? subphase.name.substring(0, 15) + '...' : subphase.name,
                fullName: subphase.name,
                id: `Subphase_${subphase.id}_${phasePlanId}`,
                parent: phasePlanId,
                start: actualStartTimestamp + (subphase.start_day - minStartDay) * day, // Correct subphase start time
                end: actualStartTimestamp + (subphase.end_day - minStartDay) * day, // Correct subphase end time
                events:{
                  click:()=> handleSubphaseClick1(subphase),
                },
                collapsed: true,
                // });
              };
              phasePlanEntry.children.push(subphaseEntry);
            });

            cropRunEntry.children.push(phasePlanEntry);
          });

          // Add crop run to strain
          strainEntry.children.push(cropRunEntry);
        });
      });

      // Flatten the structure for Highcharts Gantt
      return Array.from(strainMap.values()).flatMap(strain => [
        strain,
        ...strain.children.flatMap(cropRun => [cropRun, ...cropRun.children.flatMap(phasePlan => [phasePlan, ...phasePlan.children])]),
      ]);
    }
  };
  // const seriesData = [
  //   {
  //     name: `${activeFilter} Data`,
  //     data: processChartData(),
  //   },
  // ];
  const seriesData = useMemo(() => [
    {
      name: `${activeFilter} Data`,
      data: processChartData(),
    },
  ], [data, activeFilter, selectedStates]);

  // Chart configuration
  const options = {
    chart: {
      type: 'gantt',
      styledMode: true,
      backgroundColor: 'transparent',
    },

    // chart: {
    //   type: 'gantt',
    //   backgroundColor: '#ffcccc',
    //   styledMode: true,
    //   // backgroundColor: 'transparent',
    //   events: {
    //     function (chart) {
    //       // Ensure all crop runs have images and arrows
    //       chart.series[1].data.forEach((point) => {
    //           const yAxis = chart.yAxis[1];
    //           const x = chart.plotLeft + 10; // X position for the image
    //           const y = yAxis.toPixels(point.y) - 10; // Y position for the image
      
    //           // Add image for each crop run
    //           chart.renderer
    //               .image(
    //                   "https://www.highcharts.com/samples/graphics/sun.png", // Image URL
    //                   x,
    //                   y,
    //                   20,
    //                   20 // Size of the image
    //               )
    //               .add();
    //       });
      
    //       // Ensure that the images are rendered properly for each crop run
    //       chart.series[1].data.forEach((point, index) => {
    //           const yAxis = chart.yAxis[1];
    //           const x = chart.plotLeft + 10; // Adjust X position for image
    //           const y = yAxis.toPixels(point.y) - 10; // Adjust Y position for image
      
    //           // Add custom image between crop runs
    //           chart.renderer
    //               .image(
    //                   "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Green_circle.svg/2048px-Green_circle.svg.png", // Image URL for green ball
    //                   x + 20, // Slightly adjust X for spacing
    //                   y + 20, // Slightly adjust Y for proper placement
    //                   20, // Image size
    //                   20  // Image size
    //               )
    //               .add();
    //       });
    //   }
    // }

      
    
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    
    title: {
      text: 'Crop Runs Timeline',
      style: {
        color: isDarkTheme ? '#D9DBD3' : '#000000',
        fontFamily: 'Space Grotesk, sans-serif',
      },
    },
    xAxis: {
      type: 'datetime',
      // min: xAxisMin,
      // max: xAxisMax,
      min: min,
      max: max,
      tickInterval: 1000 * 60 * 60 * 24 * 5, // Tick every 5 days
      minPadding: 0.05,
      maxPadding: 0.05,
      // tickInterval: day * 5,
      labels: {
        formatter: function () {
          const date = new Date(this.value);
          const day = date.getDate();
          return day;
        },
        style: {
          color: isDarkTheme ? '#FFFFFF' : '#000000',
        },
        dateTimeLabelFormats: {
          day: '%e', // Day without leading zeros
        },
      },
      gridLineWidth: 0, // Optional: Remove grid lines
      plotLines: [
        {
          value: today.getTime(), // Today's date timestamp
          strokeWidth: "1px",
          zIndex: 5, 
          stroke: '#FF2626',
          // Ensure the line is on top of other elements
          label: {
            text: `<div style="background-color: #BDC0B2;
    color: #1A1B19 ; border-radius: 8px; padding: 5px 15px; font-weight: bold; margin-top: -10px;">
                ${today.toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
               </div>`, // Date inside a styled box
            align: 'center', // Center the label on the line
            verticalAlign: 'top', // Align it at the top of the chart
            useHTML: true, // Enable HTML formatting for the label
            rotation: 0,
            y: 10,
            x: -5,
          },
        },
      ],
      events: {
        afterSetExtremes: function (event) {
          const range = event.max - event.min;
          if (range > day * 30) {
            this.update({
              tickInterval: day * 5, // Show ticks every 5 days for broader ranges
            });
          } else {
            this.update({
              tickInterval: day, // Show ticks every day for smaller ranges
            });
          }
        },
      },
    },
    yAxis: {
       type: 'treegrid', // Enables collapsible hierarchy
      gridLineWidth: 0, // Remove horizontal grid lines
      
      title: {
        // text: 'Project Stages',
        useHTML: true,
        style: {
            color: '#ffffff',
            backgroundColor: '#007bff',
            padding: '8px',
            borderRadius: '5px',
            fontWeight: 'bold',
            display: 'inline-block'
        }
    },


    // plotBands: [
    //     { from: -0.5, to: 0.5, color: '#ffcccc' }, // Light Red
    //     { from: 0.5, to: 1.5, color: '#ccffcc' }, // Light Green
    //     { from: 1.5, to: 2.5, color: '#ccccff' }  // Light Blue
    // ],

  //   labels: {
  //     useHTML: true,
  //     formatter: function () {
  //         // Default icon URL for crop runs (sun image)
  //         // let iconUrl = 'https://www.highcharts.com/samples/graphics/sun.png'; 
  
  //         // Check if the current label is a crop run or strain based on its value (this.value)
  //         if (this.value && this.value.startsWith('Crop Run')) {
  //             // If it's a crop run, use the sun image
  //             let iconUrl = 'https://www.highcharts.com/samples/graphics/sun.png';
  //             return '<div style="width: 100%; height: 100%; ' +
  //             'padding:  0px; font-weight: bold; ' +
  //             'display: flex; align-items: center; justify-content: center; gap: 10px;">' +
  //             '<img src="' + iconUrl + '" style="width: 20px; height: 20px; margin-left: px ; margin-bottom: 0px">' +
  //             this.value +'</div>';
  //         } 
  //         // else if (this.value && this.value.startsWith('Strain')) {
  //         //     // If it's a strain, use the moon image
  //         //     iconUrl = 'https://upload.wikimedia.org/wikipedia/commons/6/63/Moon_Icon.svg';
  //         // }
  
  //         // return '<div style="width: 100%; height: 100%; ' +
  //         //     'padding:  0px; font-weight: bold; ' +
  //         //     'display: flex; align-items: center; justify-content: center; gap: 10px;">' +
  //         //     '<img src="' + iconUrl + '" style="width: 20px; height: 20px; margin-left: px ; margin-bottom: 0px">' +
  //         //     this.value +'</div>';
  //         // return this.value ;
  //     }
  // }    
    },
    useHTML: true, // Enable HTML rendering
    tooltip: {
      useHTML: true, // Enable HTML rendering
      formatter: function () {
        return `
            <b>${this.point.fullName}</b><br>
            Start: ${Highcharts.dateFormat('%e %b %Y', this.point.start)}<br>
            End: ${Highcharts.dateFormat('%e %b %Y', this.point.end)}
        `;
      },
      backgroundColor:  '#646464', // Dark background with some transparency
      borderColor: '#D9DBD3',
      style: {
        color: '#ffffff', // White text
        fontSize: '12px', // Adjust font size
        
      },
    },
    scrollbar: {
      enabled: true,
    },
    // Add Exporting Options
    // exporting: {
    //   enabled: true,  //  Allows exporting options in the chart menu
    //   buttons: {
    //     contextButton: {
    //       menuItems: ['viewFullscreen']
    //     }
    //   }
    // },
    rangeSelector: {
      enabled: true,
    },

    plotOptions: {
      series: {
        groupPadding: 0.14, // Decrease padding between groups
        pointPadding: 0.14, // Decrease padding around each point
        borderRadius: 7,
        // color: allLevelColor,// Optional: Add rounding to the bars
        dataLabels: {
          enabled: true,
          useHTML: true,
          style: {
            fontSize: '12px',
            // color: allLevelColor,
          },
          states: {
            inactive: {
              opacity: 1, // Keeps nodes fully visible even when collapsed
            },
          },
          
          align: 'left',
          verticalAlign: 'middle',
          crop: false,
          overflow: 'none',
          x: 10,
          y: 0,

          formatter: function () {
            // Define common styles
            const baseStyle = "color:#D9DBD3; font-size:14px; text-align:center; margin-left: 20px;";
            const whiteText = "color:#FFFFFF; font-size:14px; text-align:center;";
        
            if (activeFilter === 'Crop Runs') {
                // Tooltip for Crop Runs
                if (this.point.id.startsWith('CropRun_')) {
                    return `
                    <div style="
                        display: flex; 
                        align-items: center; 
                        justify-content: space-between; 
                        width: 100%; 
                        height: 100%; 
                        text-align: center;
                        font-size: 14px;
                        color: #FFFFFF;
                        white-space: nowrap;  /* Prevents text wrapping */
                        overflow: hidden;
                        padding: 4px;
                        margin-bottom:1px;
                    ">
                        <span style="color:#ffffff; font-size:14px; min-width: 80px;">${this.point.run_status || 'N/A'}</span>
                        <span style="${baseStyle}">Strn Cnt <span style="${whiteText}">${this.point.strain_count || 'N/A'}</span></span>
                        <span style="${baseStyle}">Plant Cnt <span style="${whiteText}">${this.point.plant_count || 'N/A'}</span></span>
                        <a href="${window.location.origin.toString()}/crops/runs/${this.point.id.replace('CropRun_', '')}/?tab=overview" target="_blank">
                            <img src="../../external.png" alt="Icon" style="width: 17px; height: 17px; margin-left: 15px;">
                        </a>
                    </div>
                    `;
                }
                // <span style="${baseStyle}">Proj <span style="${whiteText}">${this.point.proj || 'N/A'} lbs</span></span> 
                //         <span style="${baseStyle}">Act <span style="${whiteText}">$${this.point.act || 'N/A'}</span></span>
                //         <span style="${baseStyle}">Proj <span style="${whiteText}">$${this.point.projp || 'N/A'}</span></span>
            } else if (activeFilter === 'Strains') {
                // Tooltip for Strains
                if (this.point.id.startsWith('Strain_')) {
                    return `
                    <div style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: #FFFFFF;
                        white-space: nowrap;
                        overflow: hidden;
                        padding: 4px;
                    ">
                        <span style="${baseStyle}">Total Plant <span style="${whiteText}">${this.point.totalPlantCount || 'N/A'}</span></span>
                        <span style="${baseStyle}">Proj <span style="${whiteText}">${this.point.totalProjection || 'N/A'} lbs</span></span>
                        
                    </div>
                    `;
                    // <span style="${baseStyle}">Act <span style="${whiteText}">$${this.point.totalActual || 'N/A'}</span></span>
                    //     <span style="${baseStyle}">Revenue <span style="${whiteText}">$${this.point.totalRevenue || 'N/A'}</span></span>
                }
        
                if (this.point.id.startsWith('CropRun_')) {
                    // Tooltip for Crop Runs under Strains
                    return `
                    <div style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        font-size: 14px;
                        color: #FFFFFF;
                        white-space: nowrap;
                        overflow: hidden;
                        padding: 1px;
                    ">
                        <span style="${baseStyle}">Plant Cnt <span style="${whiteText}">${this.point.plant_count || 'N/A'}</span></span>
                        
                    </div>
                    `;
                }
                // <span style="${baseStyle}">Act weight <span style="${whiteText}">${this.point.plant_count || 'N/A'} lbs</span></span>
                //         <span style="${baseStyle}">Revenue <span style="${whiteText}">$${this.point.plant_count || 'N/A'}</span></span>
            }
        
            // Default tooltip for other nodes
            // return `<div style="font-size:14px; text-align:center;">${this.point.fullName || "N/A"}</div>`;
            return ' ';
        }
        
        },
      },
    },

    series: seriesData.map(seriesItem => ({
      ...seriesItem,
    })),
    credits: {
      enabled: false,
    },
  };

  return (
    // <Box className={'command-center-chart '} sx={{ position: 'relative' }}>
    <Box 
    className={'command-center-chart'}
  sx={{ position: 'relative' }}
>


      {(chartType === 'gantt') && (
  <Box
    sx={{
      position: 'absolute',
      top: '-25px',
      left: '15px',
      zIndex: 100,
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }}
  >
    <span style={{ color: '#FFFFFF', fontSize: '14px' }}>View By : </span>

    <ButtonGroup variant="text" aria-label="filter group" sx={{ boxShadow: 'none', border: 'none' }}>
      {filters.map(name => (
        <Button
          key={name}
          sx={{
            backgroundColor: activeFilter === name ? '#444444' : 'transparent', // Highlight active filter
            color: '#FFFFFF',
            fontSize: '13px',
            minWidth: '60px',
            padding: '5px',
            '&:hover': { backgroundColor: '#444444' },
            border: 'none',
            boxShadow: 'none',
          }}
          onClick={() => {
   
            if (activeFilter !== name) {
              setActiveFilter(name);
            }
          }}
        >
          {name}
        </Button>
      ))}
    </ButtonGroup>
  </Box>)}

  {/* IconButton positioned at the top-right corner */}
  <Box sx={{
    position: 'absolute',
    top: '-25px',
    right: '15px', // Move only the three-dot menu to the right
    zIndex: 100,
  }}>
    <IconButton
  onClick={handleMenuClick}
  sx={{
    color: isDarkTheme ? "#D9DBD3" : "#333333", // Dark mode: Light icon, Light mode: Dark icon
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: isDarkTheme ? "#2A2C27" : "#E0E0E0", // Subtle hover effect
    },
  }}
>
  <MoreVertIcon />
</IconButton>
  </Box>
      <Box
        id="container"
        className={isDarkTheme ? 'highcharts-dark' : 'highcharts-light'}
        sx={{
          marginTop: 4,
        }}
      >
<Drawer
  anchor="right" //  This positions it on the right
  open={openDrawer}
  onClose={() => setOpenDrawer(false)}
  sx={{
    "& .MuiDrawer-paper": {
      width: "550px", //  Adjust width as needed
      backgroundColor: "#1A1B19", // Match the dark theme background
      color: "#FFFFFF",
      padding: "20px",
      overflow:"hidden",
      marginTop: '200px', // Push it below the table
    },
  }}
>
  {/* Header Section with Image, Title & Close Button */}
  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginTop: "10px" ,padding:"0 20px"}}>
    {/* Left Side - Image & Title */}
    <Box display="flex" alignItems="center">
      <img src="../../Checked.png" alt="Icon" style={{ width: "24px", height: "24px", marginRight: "10px" }} />
      <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
        {selectedSubphase ? selectedSubphase.name : "No Subphase Selected"}
      </Typography>
    </Box>

    {/* Right Side - Close Button */}
    <IconButton onClick={() => setOpenDrawer(false)} sx={{ color: "#FFFFFF" }}>
      <CloseIcon sx={{ fontSize: "24px" }} />
    </IconButton>
    </Box>

    <Box mt={5} sx={{overflowY:"auto",padding:"0 20px"}}>
    {/* <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} >
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>Moved from</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          <ArrowForwardIcon sx={{ fontSize: "18px", marginRight: "5px" }} />
          {selectedSubphase?.moved_from || "N/A"}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>Moved to</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          <ArrowForwardIcon sx={{ fontSize: "18px", marginRight: "5px" }} />
          {selectedSubphase?.moved_to || "N/A"}
        </Typography>
      </Box>
    </Box> */}

    <Box sx={{  marginBottom: "15px" }} />

    {/* Started On & Ended On */}
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>Started on</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ fontSize: "18px", marginRight: "5px" }} />
          {selectedSubphase?.start_date || "N/A"}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>Ended on</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
          <CalendarMonthIcon sx={{ fontSize: "18px", marginRight: "5px" }} />
          {selectedSubphase?.end_date || "N/A"}
        </Typography>
      </Box>
    </Box>

    <Box sx={{ borderBottom: "1px solid #333", marginBottom: "15px" }} />

    {/* Start Day & End Day */}
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>Start Day</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          {selectedSubphase?.start_day || "N/A"}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>End Day</Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
          {selectedSubphase?.end_day || "N/A"}
        </Typography>
      </Box>
    </Box>
    </Box>


  {/* <Box
  sx={{
    backgroundColor: "#2A2C27", // Dark gray background
    padding: "15px",
    borderRadius: "8px",
    marginTop: "100px",
    marginLeft:"15px",
    width: "486px", //  Set exact width
    height: "184px", //  Set exact height
    overflow: "hidden", // Prevents overflow content
  }}>
    <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginBottom: "8px" }}>
    Sub-phase description
    </Typography>
    <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>
      {selectedSubphase?.description || "No description available."}
    </Typography>
  </Box> */}
  <Box
  sx={{
    backgroundColor: "#2A2C27", // Dark gray background
    padding: "15px",
    borderRadius: "8px",
    marginTop: "50px",
    marginLeft: "15px",
    width: "486px",
    height: "auto", //  Adjust height dynamically based on content
    overflow: "hidden",
  }}
>
  {/* Routines Header */}
  {/* Routines Header */}
<Typography sx={{ fontSize: "20px", fontWeight: "bold", marginBottom: "8px" }}>
  Routines
</Typography>


{/* Check if subphase_routines exist */}
{drawerData?.subphase_routines && drawerData.subphase_routines.length > 0 ? (
  drawerData.subphase_routines.map((routine, index) => (
    
    <Box
      key={routine.id || index}
      sx={{
        backgroundColor: "#1A1B19", // Darker box inside
        padding: "10px",
        borderRadius: "6px",
        marginBottom: "10px",
        width: "100%", // Full width inside parent box
        border: "1px solid #333", // Subtle border
      }}
    >
      {/* Routine Name */}
      <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
        {routine.name}
      </Typography>

      {/* Routine Tasks */}
      {routine.routine_tasks && routine.routine_tasks.length > 0 ? (
        routine.routine_tasks.map((task, taskIndex) => {
          const upcomingTask = task.task_schedule?.[0];
          return (
            <Box
              key={task.task_id || taskIndex}
              sx={{
                backgroundColor: "#2A2B27",
                padding: "8px",
                borderRadius: "4px",
                marginTop: "6px",
                border: "1px solid #444",
              }}
            >
              {/* Task Name */}
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                Task: {task.task_name}
              </Typography>

              {/* Upcoming Task Date & Time */}
              {upcomingTask ? (
                <Typography sx={{ fontSize: "14px", color: "#D9DBD3", marginTop: "4px" }}>
                  <strong>Upcoming:</strong> {new Date(upcomingTask.perform_on).toLocaleDateString()} at {upcomingTask.timestamp}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "14px", color: "#D9DBD3", marginTop: "4px" }}>
                  No upcoming schedule
                </Typography>
              )}
            </Box>
          );
        })
      ) : (
        <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>
          No tasks available.
        </Typography>
      )}
    </Box>
  ))
) : (
  <Typography sx={{ fontSize: "14px", color: "#D9DBD3" }}>
    No routines available.
  </Typography>
)}

  
</Box>


</Drawer>
<Popover
  open={Boolean(menuAnchor)}
  anchorEl={menuAnchor}
  onClose={handleClosed}
  transitionDuration={0}
  anchorOrigin={{ vertical: "top", horizontal: "right" }}
  sx={{
    "& .MuiPaper-root": {
      backgroundColor: isDarkTheme ? "#1A1B19" : "#FFFFFF", // Dark/Light mode background
      color: isDarkTheme ? "#D9DBD3" : "#000000", // Text color
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    },
  }}
>
  <MenuItem
    onClick={() => switchChart("gantt")}
    sx={{
      backgroundColor: "transparent",
      color: isDarkTheme ? "#D9DBD3" : "#000000",
      "&:hover": { backgroundColor: isDarkTheme ? "#333333" : "#F0F0F0" }, // Hover effect
    }}
  >
    Bar Gantt Chart
  </MenuItem>

  <MenuItem
    onClick={() => switchChart("bar")}
    sx={{
      backgroundColor: "transparent",
      color: isDarkTheme ? "#D9DBD3" : "#000000",
      "&:hover": { backgroundColor: isDarkTheme ? "#333333" : "#F0F0F0" }, // Hover effect
    }}
  >
    Diamond Gantt Chart
  </MenuItem>
</Popover>

       {/* Chart Rendering */}
      
      {chartType === 'gantt' ? (
    <HighchartsReact 
    key={Date.now()} // 👈 This forces a full re-render when switching
        highcharts={Highcharts} 
        constructorType="ganttChart" 
        options={options} 
        ref={chartRef} 
    />
) : (
    <DiamondChartComponent 
    currDarkThemeClass={currDarkThemeClass}
        key={Date.now()} // 👈 This forces a re-render
        data={data} 
        allStates={allStates}  
        highcharts={Highcharts} 
    />
)}
        {/* <HighchartsReact  key={Date.now()} highcharts={Highcharts} constructorType="ganttChart" options={options} ref={chartRef}  containerProps={{
      // key={Date.now()}
    }}/> */}
      </Box>
      {/* {chartType === 'gantt' && ( */}
      <Box
        sx={{
          position: "fixed", // Use sticky positioning
          bottom: '25px', // Stick 10px above the bottom of the parent container
          right: '40px', // Stick to the right side
          zIndex: 100, // Ensure it's above other elements
          backgroundColor: '#1A1B19', // Background color
          borderRadius: '8px', // Rounded edges for the box
          padding: '5px', // Spacing around the icon
          width: '40px', // Fixed width
          height: '40px', // Fixed height
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
          display: 'flex',
          alignItems: 'center', // Center content vertically
          justifyContent: 'center', // Center content horizontally
          cursor: 'pointer', // Pointer cursor on hover
          marginLeft: 'auto', // Align the sticky element to the right
        }}
        onClick={(event) => {
          handleFilterClick(event); // Ensure this is defined as a function
        }}
      >
        <img
          src="../../filter.png" // Replace with the correct path to filter.png
          alt="Filter Icon"
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      </Box>
       {/* )}  */}
      {/* Popover Filter Menu */}
      
      <Popover
        // open={open}
        // anchorEl={anchorEl}
        // onClose={handleClose}
      
        
        open={Boolean(anchorEl)}
  anchorEl={anchorEl}
  onClose={handleClose}
  disableAutoFocus // Prevents focus from triggering re-renders
  disableRestoreFocus // Prevents React from refocusing elements
  transitionDuration={0}
        anchorOrigin={{
          vertical: "center",  // Align popover vertically centered to button
          horizontal: "left",   // Opens to the left of the button
        }}
        transformOrigin={{
          vertical: "center",   // Ensures alignment is proper
          horizontal: "right",  //  Keeps popover on the side with space
        }}
        
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#1A1B19",
            borderRadius: "8px",
            color: "#FFFFFF",
            padding: " 30px 0px 0px 30px",
            width: "200px",
            height:"400px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {/* Title */}
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "10px", color: "#FFFFFF" }}>
          Crop run state
        </Typography>

        {/* "All States" Checkbox */}
        
        <FormControlLabel
          control={
            <Checkbox
              name="All States"
              checked={selectedStates.length === allStates.length}
              indeterminate={selectedStates.length > 0 && selectedStates.length < allStates.length}
              onChange={handleCheckboxChange}
              sx={{
                color: "#555",
                "&.Mui-checked": { color: "#007BFF" },
              }}
            />
          }
          label={<Typography sx={{ fontSize: "15px", color: "#FFFFFF" }}>All States</Typography>}
          sx={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "3px" }}
        />
        <Box sx={{ width: "75%", height: "1px", backgroundColor: "#444",marginBottom:"10px", marginTop:"5px" }} />

        {/* Individual State Checkboxes */}
        {allStates.map((state) => (
          <FormControlLabel
            key={state}
            control={
              <Checkbox
                name={state}
                checked={selectedStates.includes(state)}
                onChange={handleCheckboxChange}
                sx={{
                  color: "#555",
                  "&.Mui-checked": { color: "#007BFF" },
                  
                }}
              />
            }
            label={<Typography sx={{ fontSize: "15px", color: selectedStates.includes(state) ? "#D9DBD3" : "#414437"}}>{state}</Typography>}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginBottom: "3px",
            }}
          />
        ))}
      </Popover>
    </Box>
  );
};

export default CommandCenterChart;
