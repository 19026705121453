import Typography from "@mui/material/Typography";

const devMsg = 'This site is under development...';

export default function SiteUnderDevMsg(props) {
    const devTypoProps = {
        sx: {
            color: 'grey.400',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: 1.5,
            mt: '60px',
            // mx: 'auto',
            textAlign: 'center',
        },
    };

    return (
        <Typography {...devTypoProps}>{devMsg}</Typography>
    );
}