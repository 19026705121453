import { useContext, useState } from "react";
import {Link, useLocation, useNavigate } from "react-router-dom";
import { MetrcKeyContext } from "../../App";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SetupOutlinePage from "./SetupOutline";

import APIService from '../../api/apiService';
import useThemeVal from "../../utility/useThemeVal";
import { defaultNoMetrcRoute, landingPageRoute, loginPageClasses } from "../utility/constants";

const signUpInfo = "Welcome to SMARTGROW";
const actionTitle = "Please enter your email address and password.";


export default function LoginPage(props) {
    const [loading, setLoading] = useState(); //why we are giving blank ?? its come like undifiend
    const [values, setValues] = useState({});
    
    const {setMetrcKey} = useContext(MetrcKeyContext);
    const navigate = useNavigate();
    const location = useLocation();

    const currDarkThemeClass = useThemeVal();

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleFinally = () => {
        setLoading(false);
    }

    const handleLoginRes = res => {

        const token = res?.data?.access;
        const permissions = res?.data?.permissions;  

        let tokenDetails = {};

        try {
            tokenDetails = JSON.parse(atob(token.split('.')[1]));
        } catch {
            // console.log('Bad token');
            
            localStorage.clear();
            sessionStorage.clear();
            
            navigate(landingPageRoute);
        }

        const {metrcSetup, orgSetup, farmSetup, metrc_id} = tokenDetails;

        if (!orgSetup || !metrcSetup || !farmSetup) {
            navigate(defaultNoMetrcRoute);
            localStorage.setItem('token', token);
            localStorage.setItem('permissions', JSON.stringify(permissions));
            setMetrcKey(metrc_id);
            return
        }

        localStorage.setItem('token', token);
        localStorage.setItem('permissions', JSON.stringify(permissions));
        
        setMetrcKey(metrc_id);

        navigate('/');
    }

    const handleError = err => {
        // console.log(err);

        const helperText = err?.response?.status === 0 ? 'The server could not be reached'
                            : err?.response?.status === 400 ? 'Please check your inputs'
                            : err?.response?.status === 401 ? 'Invalid credentials'
                            : err?.response?.status === 500 ? 'Internal Server Error. We regret the inconvenience'
                            : 'An error occured';

        handleSetValues('error', true);
        
        handleSetValues('helperText', helperText);
    };

    const handleProceed = (event) => {
        event.preventDefault();

        setLoading(true);

        const entity = 'api-token-auth/';
        const payload = {
            'username': values?.email,
            'password': values?.password
        };
        
        APIService.createInstance(entity, payload)
        .then(handleLoginRes)
        .catch(handleError)
        .finally(handleFinally)
    };

    const sPOutlineProps = {
        currDarkThemeClass,
    };

    const sPBoxProps = {
        className: `${loginPageClasses?.box} ${currDarkThemeClass} ${values?.basicSetupComplete ? loginPageClasses?.setupCompleted : ''}`,
        // sx: {
        //     // display: 'flex',
        //     // flexDirection: 'column',
        //     // maxWidth: '472px',
        //     mt: '108px',
        //     mx: 'auto',
        //     width: '472px',
        //     ...(values?.basicSetupComplete && {
        //         // mt: '282px',
        //         mx: 'auto',
        //     }),
        // },
    };

    const sPInfoTypoProps = {
        className: `${loginPageClasses?.infoTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        // },
    };

    const sPActionTypoProps = {
        className: `${loginPageClasses?.actionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '30px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.13,
        //     mt: '12px',
        // },
    };

    const sPFormBoxProps = {
        component: 'form',
        onSubmit: handleProceed,
    };

    const handleSetTextField = (prop, value) => {
        if (values?.error) {
            handleSetValues('error', false);
            // handleSetValues('helperText', null);
        }
        handleSetValues(prop, value);
    };


    const sPTextFieldProps = (prop, otherProps) => ({
        autoComplete: 'off',
        disabled: loading,
        error: values?.error,
        fullWidth: true,
        helperText: values?.error && values?.helperText,
        inputProps: {
            // sx: {
            //     px: '15px',
            //     py: '16px',
            // },
            ...otherProps?.inputProps
        },
        // InputLabelProps: {
        //     // ...(prop === 'email' ? {
        //     //     shrink: Boolean(values?.email),
        //     // } : {
        //     //     shrink: false
        //     // }),
        //     sx: {
        //         fontSize: '16px',
        //         lineHeight: 1.56,
        //         // ml: values?.email ? 0 : '36px',
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.600',
        //         },
        //     },
        // },
        InputProps: {
            // startAdornment: prop === 'email' && <MailIcon sx={{width: '20px', height: '20px', '& path': {fill: 'none'}}} />,
            sx: {
                // borderRadius: 0,
                // '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
                //     borderColor: 'grey.800',
                // },
                ...(prop === 'code' && 
                        {
                        '&.MuiOutlinedInput-root.MuiInputBase-root': {
                                pr: '15px',
                            }
                        }
                    ),
            },
        },
        label: ' ',
        onChange: ({target: {value}}) => handleSetTextField(prop, value),
        required: true,
        className: `${loginPageClasses?.textField} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '28px',
        //     // position: 'relative',
        //     width: '472px',
        // },
        value: values[prop] ?? '',
        ...otherProps,
    });

    const otherEmailArgs = {
        autoFocus: true,
        type: 'email',
        label: 'Your Email Address',
    };

    const otherPasswordProps = {
        inputProps: {
            minLength: 12,
            // pattern: '[0-9a-zA-Z\W+]{12,}' min one lower, one upper, one number and one spl char
        },
        label: 'Enter Your Password',
        required: true,
        type: 'password',
    };

    const lPProgressProps = {
        color: 'warning',
        size: 20,
    };

    const sPProceedBtnProps = (message='Proceed', otherArgs={}) => ({
        children: message,
        disabled: loading,
        onClick: undefined,
        startIcon: loading && <CircularProgress {...lPProgressProps} />,
        className: `${loginPageClasses?.proceedBtn} ${currDarkThemeClass}`,
        // sx: {
        //     color: '#FFFFFF',
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     // mt: '20px',
        //     my: '20px',
        //     p: '16px',
        //     textTransform: 'none',
        //     width: '472px',
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
        ...otherArgs
    });


    return (
        <SetupOutlinePage {...sPOutlineProps}>
            <Box {...sPBoxProps}>
                <Typography {...sPInfoTypoProps}>{signUpInfo}</Typography>
                <Typography {...sPActionTypoProps}>{actionTitle}</Typography>

                <Box {...sPFormBoxProps}>
                    <TextField {...sPTextFieldProps('email', otherEmailArgs)} />
                    <TextField {...sPTextFieldProps('password', otherPasswordProps)} />
                    <Button {...sPProceedBtnProps('Proceed', {type: 'submit'})} />

                    </Box>
                    <Link to="/forget-password">Forgot Password?</Link> 
                   
            </Box>
               
               
        </SetupOutlinePage>
    );
}