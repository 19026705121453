import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import DashMegaCard from "../utility/cards/DashMegaCard";
import DashMiniCard from "../utility/cards/DashMiniCard";
import DownloadingMetrcDataMsg from "../utility/DownloadingMetrcDataMsg";
import InProdInventory from "./InProdInventory";
import SeeMoreCardPopup from "../utility/popups/SeeMoreCardPopup";

import BedtimeIcon from '@mui/icons-material/Bedtime';
import { 
    EyeIcon, GoBackIcon, SunnyDay2DIcon,
    GrowDashCalendarIcon, GrowDashHarvestIcon, GrowDashPriceIcon, GrowDashCountIcon,    
} from "../utility/CustomSvgIcons";

import { GrowDashFY2DIcon } from "../utility/icons/GrowDashFY2DIcon";
import { GrowDashTDF2DIcon } from "../utility/icons/GrowDashTDF2DIcon";
import { GrowDashOH2DIcon } from "../utility/icons/GrowDashOH2DIcon";
import { GrowDashDFL2DIcon } from "../utility/icons/GrowDashDFL2DIcon";
import { SmartGrowDashIcon } from "../utility/icons/SmartGrowDashIcon";

import { 
    accountAppLabel, dashboardModelName, crudActions, 
    getGrowDashboardEndpoint, landingPageRoute,
    MINI_CARD_0, MINI_CARD_1, MINI_CARD_2, MINI_CARD_3,
    MEGA_CARD_0, MEGA_CARD_1, MEGA_CARD_2, MEGA_CARD_3,
    baseClasses, dashboardClasses,
    getFixedDecimalVal, 
    fadeTimingMs, logMsgToConsole, getDollarFormatNum, getDollarSuffixNum, errFeedbackFn, getHandleErrorWithFeedbackCX, getTimezoneSansLocaleDate,
} from "../utility/constants";

import APIService from "../../api/apiService";
// import getTokenDetails from "../../auth/getTokenDetails";
import { FeedbackContext } from "../../App";
import { failsTokenAndSetupCheck } from "../../checks/setup";
import useThemeVal from "../../utility/useThemeVal";
import useToken from "../../utility/useToken";
import { useRef } from "react";


 const currHour = new Date().getHours();
 const isEvening = currHour >= 18 || currHour <= 6;

const greetingText = `Good ${isEvening ? 'Evening' : 'Morning'}`;
const greetingSubText = 'Your plants are healthy and your data is looking strong.';
const projectionsText = 'In-Production';
const projectionsSubText = 'Inventory';
const miniCardsHeader = 'Finished Inventory - FGI';
const frameFooter = 'See More';
const megaCardsHeader = 'Sold Inventory';

const defState = {
    loading: true,
};

const dashMiniCardProps = [
    {id: MINI_CARD_0, title: '% On Hand FGI', description: '', icon: GrowDashCalendarIcon,},
    {id: MINI_CARD_1, title: 'On Hand FGI', description: '', icon: GrowDashHarvestIcon,},
    {id: MINI_CARD_2, title: 'Total Price FP', description: '(Flower Pound)', icon: GrowDashPriceIcon,},
    {id: MINI_CARD_3, title: 'Inactive Harvest', description: '(Plant Count)', icon: GrowDashCountIcon,},
];

const miniCardValueFn = (values, miniCard) => {
    let on_hand_lbs = 0;
    let total_lbs = 0;
    let on_hand_percentage = 0.00;
    let total_price_fp = 0;
    let inactive_harvest_pc = 0;

    switch(miniCard?.id) {
        case MINI_CARD_0: // % On Hand
            values?.fgi?.details?.forEach(val => {
                on_hand_lbs += +val?.on_hand_lbs;
                total_lbs += +val?.total_lbs;

                on_hand_percentage = total_lbs > 0 ? getFixedDecimalVal(0)((on_hand_lbs * 100) / total_lbs) : 0.00;

            });

            return `${on_hand_percentage} %`;

        case MINI_CARD_1: // On Hand
            on_hand_lbs = values?.fgi?.details?.reduce((res, val) => res + +val?.on_hand_lbs, 0);

            return `${getFixedDecimalVal(0)(on_hand_lbs)} lbs`;

        case MINI_CARD_2: // Total Price FP
            total_price_fp = values?.fgi?.details?.reduce?.((res, val) => res + +val?.net_price, 0);

            // return getDollarSuffixNum(total_price_fp, 1);
            return getDollarSuffixNum(total_price_fp, 0);

        case MINI_CARD_3: // Inactive Harvest
            inactive_harvest_pc = values?.fgi?.details?.reduce((res, val) => res + +val?.plant_count , 0)
            
            return isNaN(+inactive_harvest_pc) ? 0 : inactive_harvest_pc;

        default:
            return '';
    }
};

const miniCardsTableMap = [
    {key: 'harvest', title: 'Harvest', valueFn: e => e},
    {key: 'strain_name', title: 'Strain', valueFn: e => e},
    {key: 'days_in_inventory', title: 'Days in Inv.', valueFn: getFixedDecimalVal(0)},
    {key: 'on_hand_percentage', title: '% On Hand', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'on_hand_lbs', title: 'On Hand (lbs.)', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'total_lbs', title: 'Total (lbs.)', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'price_per_lbs', title: 'Price (per lbs.)', valueFn: e => getDollarFormatNum(e, 0)},
    {key: 'net_price', title: 'Net Price', valueFn: e => getDollarFormatNum(e, 0)},
    {key: 'plant_count', title: 'Plant Count', valueFn: e => e},
];

const dashMegaCardProps = [
    {id: MEGA_CARD_0, title: 'Flower Yield%', description: '(Dry wt/Wet wt)', icon: GrowDashFY2DIcon, otherIconSxProps: {height: '100px', width: '100px'}},
    {id: MEGA_CARD_1, title: 'Total Dry Flower', description: 'lbs. Produced', icon: GrowDashTDF2DIcon, otherIconSxProps: {height: '93px', width: '93px'}},
    {id: MEGA_CARD_2, title: 'Overall Harvest: Dry Flower', description: 'lbs. Produced / Plant', icon: GrowDashOH2DIcon, otherIconSxProps: {height: '100px', width: '100px'}},
    {id: MEGA_CARD_3, title: 'Dry Flower', description: 'lbs. per Light', icon: GrowDashDFL2DIcon, otherIconSxProps: {height: '90px', width: '90px'}},
];

const megaCardValueFn = (values, megaCard) => {
    let flower_yield = 0;
    let total_dry_flower = values?.fgi?.details?.reduce((res, val) => res + +val?.total_lbs, 0);
    let overall_harvest = 0;
    let plant_count = 0;
    let dry_flower_per_light = 0;

    switch(megaCard?.id) {
        case MEGA_CARD_0: // Flower Yield%
        let result = values?.fgi?.details?.reduce((res, val) => {
            res.dry += +val?.total_lbs;
            res.wet += +val?.wet_weight;

            return res;
        }, {dry: 0, wet: 0});

        if (result?.wet > 0) {
            flower_yield = (result?.dry * 100) / result?.wet;
            flower_yield = getFixedDecimalVal(0)(flower_yield); // Changed getFixedDecimalVal to check for isNaN
        }

        return `${flower_yield} %`;

        case MEGA_CARD_1: // Total Dry Flower
            
            return `${getFixedDecimalVal(0)(total_dry_flower)} lbs`;// Changed getFixedDecimalVal to check for isNaN

        case MEGA_CARD_2: // Overall Harvest

            plant_count = values?.fgi?.details?.reduce((res, val) => res + +val?.plant_count, 0);

            overall_harvest = getFixedDecimalVal(2)(total_dry_flower / plant_count);

            return overall_harvest;
            
        case MEGA_CARD_3: // Dry Flower per Light
            dry_flower_per_light = getFixedDecimalVal(2)(total_dry_flower / +values?.sold_inventory?.light_count);
            
            return dry_flower_per_light;
        
        default:
            return '';
    }
};

const megaCardsTableMap = [
    {key: 'harvest', title: 'Harvest', valueFn: e => e},
    {key: 'strain_name', title: 'Strain', valueFn: e => e},
    {key: 'dry_wet_percentage', title: 'Dry/Wet %', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'total_lbs', title: 'Dry Weight (lbs.)', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'wet_weight', title: 'Wet Weight (lbs.)', valueFn: e => getFixedDecimalVal(0)(e)},
    {key: 'plant_count', title: 'Plant Count', valueFn: e => e},
];


export default function Executive(props) {
    const {
        toOpen=true,

        handleSetParentValues,
        handleToggleDashDrawer,
    } = props;

    const [values, setValues] = useState(defState);

    const feedbackCX = useContext(FeedbackContext);

    const navigate = useNavigate();

    const currDarkThemeClass = useThemeVal();

    // const {token, tokenDetails} = getTokenDetails();
    const { token, tokenDetails, permissions } = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    const { is_staff = false, first_name } = tokenDetails || '';
    
    const growDashPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${dashboardModelName}`)) ?? [], [token]);
    
    const growDashPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (growDashPermissions?.includes(`${accountAppLabel}.${crudActions?.[action]}_${dashboardModelName}`) ?? false)]));

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));
    
    const handleError = err => {
        if (logMsgToConsole?.dashboard) {
            console.log('an error occured in dashboard');
            console.log(err);
        }
        handleSetValues('fetchError', true);
    };

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    const handleFinally = () => {
        handleSetValues('loading', false);
    };

    const handleGrowDashboardRes = (res) => {
        if (logMsgToConsole?.dashboard) {
            console.log('got data for grow dashboard');
            console.log(res);
        }

        setValues(state => ({
            ...state,
            ...res?.data,
        }));
    };

    const handleSeeDetail = () => {
        handleSetValues('projectionExpanded', !values?.projectionExpanded);
        handleToggleDashDrawer();
    };

    const handlePopupClose = () => {
        // function to close all popups
        handleSetValues('seeMoreCardOpen', false);
    }

    useEffect(() => {

        if (invalidTokenState || !growDashPerms?.read) {
            
            // Routing based on permission not yet taken care of by useToken; So when the user is authenticated but does not have the perms, we route them away
            if (!invalidTokenState) navigate(landingPageRoute);
          
            return;
        }

        handleSetValues('loading', true);

        const errConfig = {};

        const entity = getGrowDashboardEndpoint;

        APIService.fetchInstance(entity)
        .then(handleGrowDashboardRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

        // Whenever this component unmounts for the last time, it is true that DashDrawer should be expanded
        return () => handleSetParentValues('collapseDrawer', false); 

    }, []);


    // Disabled to test routing via useToken
    // useEffect(() => {
    //     if (!token) {
    //         navigate(defaultNoAuthRoute); // call return after each navigate() since execution context will not transfer away immediately; Or use if-else if-else
    //     }
    //     else if (hasNotCompletedSetup(tokenDetails)) navigate(defaultNoMetrcRoute);
    //     else if (!growDashPerms?.read) navigate(landingPageRoute);
    // });

    // // Routing based on permission not yet taken care of by useToken; So when the user is authenticated but does not have the perms, we route them away
    // useEffect(() => {
    //     if (!token && hasNotCompletedSetup(tokenDetails) && !growDashPerms?.read) {
    //         navigate(landingPageRoute);
            
    //         return;
    //     }
    // })

    if (invalidTokenState || !growDashPerms?.read) return <></>;
    
    const loadingBoxProps = {
        sx: {
            alignItems: 'center',
            backgroundColor: !currDarkThemeClass ? 'transparent' : 'dark.700',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100%',
        },
    };

    const wrapperBoxProps = {
        className: `${baseClasses?.wrapper} ${dashboardClasses?.wrapperBox} ${currDarkThemeClass}`,
        sx: {
            pl: values?.projectionExpanded ? 0 : '32px',
        },
    };

    const middleBoxCollapseProps = {
        className: `${dashboardClasses?.middleBoxCollapse} ${values?.projectionExpanded ? dashboardClasses?.projectionsExpanded : ''}`,
        in: !values?.projectionExpanded,
        orientation: 'horizontal',
    }

    const middleBoxProps = {
        className: `${dashboardClasses?.middleBox} customScroll zeroScrollWd`,
    };

    const innerScollBoxProps = {
        className: dashboardClasses?.innerScrollBox,
    };

    const greetingBoxProps = {
        className: `${dashboardClasses?.greetingBox} ${currDarkThemeClass}`,
    };

    const sunnyDayIconProps = {
        className: `${dashboardClasses?.sunnyDayIcon} ${currDarkThemeClass}`,
    };

    const bedTimeIconProps = {
        className: `${dashboardClasses?.bedTimeIcon} ${currDarkThemeClass}`,
    };

    const greetingTitleTypoProps = {
        className: `${dashboardClasses?.greetingTitleTypo} ${currDarkThemeClass}`,
    };

    const greetingSubTitleTypoProps = {
        className: `${dashboardClasses?.greetingSubTitleTypo} ${currDarkThemeClass}`,
    };

    const dividerProps = {
        className: `${dashboardClasses?.divider} ${currDarkThemeClass}`,
    };

    const dateInfoTypoProps = {
        className: `${dashboardClasses?.dateInfoTypo} ${currDarkThemeClass}`,
    };

    const dateInfo = values?.fgi?.start_of_year ? `*Data available since ${getTimezoneSansLocaleDate(values?.fgi?.start_of_year)}` : '';

    const miniCardsFrameBoxProps = {
        className: `${dashboardClasses?.miniCardsFrameBox} ${currDarkThemeClass}`,
    };

    const miniCardsFrameHeaderTypo = {
        className: `${dashboardClasses?.miniCardsFrameHeaderTypo} ${currDarkThemeClass}`,
    };

    const miniCardsBoxProps = {
        className: `${dashboardClasses?.miniCardsBox}`,
    };

    const handleMiniCardSeeMore = () => {
        handleSetValues('seeMoreCardOpen', true);

        const tableEntries = values?.fgi?.details?.map(el => miniCardsTableMap?.map(e => e?.valueFn?.(el?.[e?.key])));

        let total_lbs = 0, avg_price_per_lbs = 0, totalPriceFP = 0;

        tableEntries?.forEach(val => {
            total_lbs += +val?.[5];
            avg_price_per_lbs += +val?.[6]?.match?.(/[0-9.]*/g)?.join('');
            totalPriceFP += +val?.[7]?.match?.(/[0-9.]*/g)?.join('');
        })

        avg_price_per_lbs /= tableEntries?.length || 1;
        avg_price_per_lbs = getDollarFormatNum(avg_price_per_lbs, 0);

        totalPriceFP = getDollarFormatNum(totalPriceFP, 0);

        total_lbs = `${getFixedDecimalVal(1)(total_lbs)} lbs`;

        const otherSeeMoreCardProps = {
            keyValuesList: [
                {'key': '% On Hand', value: miniCardValueFn(values, {id: MINI_CARD_0})},
                {'key': 'On Hand', value: miniCardValueFn(values, {id: MINI_CARD_1})},
                {'key': 'Total', value: total_lbs},
                {'key': 'Total Price FP', value: totalPriceFP},
                {'key': 'Avg. Price per lbs', value: avg_price_per_lbs},

            ],    
            tableTitles: miniCardsTableMap.map(e => e?.title),
            tableEntries,
            // propTitleCellSxProps: (idx) => ({textAlign: idx === 1 ? 'left' : [6, 7].includes(idx) ? 'right' : 'center'}),
            // propEntryCellSxProps: (idx) => ({textAlign: idx === 1 ? 'left' : [6, 7].includes(idx) ? 'right' : 'center'}),
        };

        handleSetValues('otherSeeMoreCardProps', otherSeeMoreCardProps);

    };

    const miniCardsFrameFooterBtn = {
        children: frameFooter,
        className: `${dashboardClasses?.miniCardsFrameFooterBtn} ${currDarkThemeClass}`,
        onClick: handleMiniCardSeeMore,
    };

    const megaCardsFrameBoxProps = {
        className: `${dashboardClasses?.megaCardsFrameBox} ${currDarkThemeClass}`,
    };
    
    const megaCardsFrameHeaderTypo = {
        className: `${dashboardClasses?.megaCardsFrameHeaderTypo} ${currDarkThemeClass}`,
    };

    const megaCardsBoxProps = {
        className: `${dashboardClasses?.megaCardsBox}`,
    };
    

    const handleMegaCardSeeMore = () => {
        handleSetValues('seeMoreCardOpen', true);

        const tableEntries = values?.fgi?.details?.map(el => megaCardsTableMap?.map(e => e?.valueFn?.(el?.[e?.key])));

        let totalWetFlower = 0;
        let plantCount = 0;

        tableEntries.forEach(val => {
            totalWetFlower += +val?.[4];
            plantCount += +val?.[5];
        });

        totalWetFlower = `${getFixedDecimalVal(0)(totalWetFlower)} lbs`;
        let overallHarvest = `${megaCardValueFn(values, {id: MEGA_CARD_2})} lbs / plant`
        let dryFlowerPerLight = `${megaCardValueFn(values, {id: MEGA_CARD_3})} lbs / light`
        let lightCount = +values?.sold_inventory?.light_count;

        const otherSeeMoreCardProps = {
            keyValuesList: [
                {key: 'Flower Yield %', value: megaCardValueFn(values, {id: MEGA_CARD_0})},
                {key: 'Total Dry Flower', value: megaCardValueFn(values, {id: MEGA_CARD_1})},
                {key: 'Total Wet Flower', value: totalWetFlower},
                {key: 'Plant Count', value: plantCount},
                {key: 'Overall Harvest', value: overallHarvest},
                {key: 'Light Count', value: lightCount},
                {key: 'Dry Flower per Light', value: dryFlowerPerLight},
            ],
            tableTitles: megaCardsTableMap.map(e => e?.title),
            tableEntries,
            // propTitleCellSxProps: (idx) => ({textAlign: idx === 1 ? 'left' : 'center'}),
            // propEntryCellSxProps: (idx) => ({textAlign: idx === 1 ? 'left' : 'center'}),
        };

        handleSetValues('otherSeeMoreCardProps', otherSeeMoreCardProps);

    };

    const megaCardsFrameFooterBtn = {
        children: frameFooter,
        className: `${dashboardClasses?.megaCardsFrameFooterBtn} ${currDarkThemeClass}`,
        onClick: handleMegaCardSeeMore,
    };
    
    const outerEndBoxProps = {
        className: `${dashboardClasses?.outerEndBox} ${currDarkThemeClass} customScroll zeroScrollWd ${values?.projectionExpanded ? dashboardClasses?.outerEndBoxExpanded : '' }`,
    };

    const endBoxProps = {
        className: `${dashboardClasses?.endBoxBase} ${currDarkThemeClass} customScroll zeroScrollWd ${values?.projectionExpanded ? dashboardClasses?.endBoxExpanded : dashboardClasses?.endBox }`,
    };

    const SGDashIconProps = {
        className: `${dashboardClasses?.sgDashIcon} ${currDarkThemeClass}`,
    };

    const plantBoxProps = {
        className: `${dashboardClasses?.plantBox}`,
    };

    const plantPotIconProps = {
        className: `${dashboardClasses?.plantPotIcon} ${currDarkThemeClass}`,
        viewBox: '0 0 322 289',
    };

    const projectionsInfoBoxProps = {
        className: `${values?.projectionExpanded ? dashboardClasses?.projectionsInfoBoxExpanded : dashboardClasses?.projectionsInfoBox}`,
    };

    const projectionsTypoBoxProps = {
        sx: {
            mt: values?.projectionExpanded ? '345px' : '250px', // 414 - 69(parent padding top)
        },
    };

    const projectionsTypoProps = {
        className: `${dashboardClasses?.projectionsTypo} ${currDarkThemeClass}`,
    };

    const projectionsBoxProps = {
        className: `${dashboardClasses?.projectionsBox}`,
    };

    const seeDetailIconProps = {
        className: `${dashboardClasses?.seeDetailIcon} ${currDarkThemeClass}`,
    };

    const goBackIconProps = {
        className: `${dashboardClasses?.goBackIcon} ${currDarkThemeClass}`,
    };

    const seeDetailBtnProps = {
        className: `${dashboardClasses?.seeDetailBtn} ${currDarkThemeClass}`,
        children: values?.projectionExpanded ? 'Back to Executive' : 'See Details',
        onClick: handleSeeDetail,
        startIcon: values?.projectionExpanded ? <GoBackIcon {...goBackIconProps} /> : <EyeIcon {...seeDetailIconProps} />,
        sx: {
            width: values?.projectionExpanded ? '233px' : '219px',
        },
    };

    const inProdInvProps = {
        handleOnClick: handleSeeDetail,
    };

    const otherDashMiniIconSxProps = {
        '& path': {
            fill: '#444444',
        },
    };
    
    const miniCardProps = miniCard => ({
        ...miniCard,
        otherIconSxProps: otherDashMiniIconSxProps,
        value: miniCardValueFn?.(values, miniCard),
    });

    const miniCardsList = dashMiniCardProps.map((e, i) => <DashMiniCard key={i} {...miniCardProps(e)} />);

    const megaCardProps = (megaCard, i) => ({
        ...megaCard,
        dashGridClass: `dashboard-grid-${i}`,
        value: megaCardValueFn?.(values, megaCard),
    });

    const megaCardsList = dashMegaCardProps.map((e, i) => <DashMegaCard key={i} {...megaCardProps(e, i)}/>);

    const projectionsEntryBoxProps = {
        className: `${dashboardClasses?.projectionsEntryBox}`,
    };

    const projectionsEntryValueTypoProps = {
        className: `${dashboardClasses?.projectionsEntryValueTypo} ${currDarkThemeClass}`,
    };
    
    const projectionsEntryTitleTypoProps = {
        className: `${dashboardClasses?.projectionsEntryTitleTypo} ${currDarkThemeClass}`,
    };


    const projectionsList = values?.projections?.map((e, i) => (
        <Box key={i} {...projectionsEntryBoxProps}>
            <Typography {...projectionsEntryValueTypoProps}>{ e?.title === 'Revenue' ? getDollarFormatNum(e?.value, 0) : e?.value}</Typography>
            <Typography {...projectionsEntryTitleTypoProps}>{e?.title}</Typography>
        </Box>
    ));
    
    const harvestChartDashboardProps = {
        open: values?.projectionExpanded,
        harvest: values?.harvest,
    };

    const seeMoreCardPopupProps = {
        open: values?.seeMoreCardOpen,
        
        onClose: handlePopupClose,

        ...values?.otherSeeMoreCardProps,
    };


    const downloadMsgProps = {
        currDarkThemeClass,
    };

    return values?.loading ? <Box {...loadingBoxProps}><DownloadingMetrcDataMsg {...downloadMsgProps} /></Box>
        : (
        <Fade in={true} timing={fadeTimingMs} >
            <Box {...wrapperBoxProps}>
                <Fade in={!values?.projectionExpanded} timeout={fadeTimingMs}>
                    <Collapse {...middleBoxCollapseProps} >
                        <Box {...middleBoxProps}>
                            <Box {...innerScollBoxProps}>
                                <Box {...greetingBoxProps}>
                                    {
                                        isEvening ? <BedtimeIcon {...bedTimeIconProps} /> 
                                            : <SunnyDay2DIcon {...sunnyDayIconProps} />
                                    }
                                    <Typography {...greetingTitleTypoProps}>{greetingText}{first_name && `, ${first_name}`}</Typography>
                                </Box>
                                    <Typography {...greetingSubTitleTypoProps}>{greetingSubText}</Typography>

                                <Divider {...dividerProps}/>

                                <Typography {...dateInfoTypoProps}>{dateInfo}</Typography>
                                
                                <Box {...miniCardsFrameBoxProps}>
                                    <Typography {...miniCardsFrameHeaderTypo}>{miniCardsHeader}</Typography>

                                    {/* Earlier all cards used to have separate See More detail buttons */}
                                    <Box {...miniCardsBoxProps}>
                                        {miniCardsList}
                                    </Box>
                                    
                                    <Button {...miniCardsFrameFooterBtn} />
                                </Box>

                                <Box {...megaCardsFrameBoxProps}>
                                    <Typography {...megaCardsFrameHeaderTypo}>{megaCardsHeader}</Typography>

                                    <Box {...megaCardsBoxProps}>
                                        {megaCardsList}
                                    </Box>

                                    <Button {...megaCardsFrameFooterBtn} />
                                </Box>

                            </Box>
                        </Box>
                    </Collapse>
                </Fade>
                <Box {...outerEndBoxProps}>
                    <Box {...endBoxProps}>
                        {
                            !values?.projectionExpanded ?
                            <>
                                <SmartGrowDashIcon {...SGDashIconProps} />
                                <Box {...projectionsInfoBoxProps}>
                                    <Box {...projectionsTypoBoxProps}>
                                        <Typography {...projectionsTypoProps}>{projectionsText}</Typography>
                                        <Typography {...projectionsTypoProps}>{projectionsSubText}</Typography>
                                    </Box>
                                    <Box {...projectionsBoxProps}>
                                        {projectionsList}
                                    </Box>
                                    <Button {...seeDetailBtnProps} />
                                </Box>
                            </> : <InProdInventory {...inProdInvProps} />
                        }
                        {
                            // values?.projectionExpanded && <HarvestChartDashboard {...harvestChartDashboardProps}/>
                        }
                    </Box>
                </Box>
                {
                    values?.seeMoreCardOpen && <SeeMoreCardPopup {...seeMoreCardPopupProps} />
                }
            </Box>
        </Fade>
    );
}