import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
// import { useTheme } from '@mui/material';

import { fadeTimingMs, getDarkThemeClass, setupFormMaxWd, feedbackClasses } from './constants';

const downloadTitle1 = 'Downloading your data.';
// const downloadTimeInfo = <>It might take 3 minutes or more to fetch the data from metrc&nbsp;servers.</>
const downloadTimeInfo = <>It usually takes less than a minute to fetch the data.</>

export default function DownloadingMetrcDataMsg(props) {
    const {
        currDarkThemeClass='',
    } = props;

    
    // can be done this way to change independently of parent
    // const theme = useTheme();
    // const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode])

    const oSDownloadBoxProps = {
        className: `${feedbackClasses?.downloadingMetrcDataMsg?.downloadBox} ${currDarkThemeClass}`,
        // sx: {
        //     height: '48px',
        //     p: '4px',
        //     width: '48px',
        // },
    };

    const oSProgressProps = {
        color: 'success',
        size: 40,
    };

    const oSDownloadTitleTypoProps = {
        className: `${feedbackClasses?.downloadingMetrcDataMsg?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '30px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.13,
        //     mt: '36px',
        // },
    };

    const oSDownloadTimeTypoProps = {
        className: `${feedbackClasses?.downloadingMetrcDataMsg?.timeTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        //     mt: '12px',
        // },
    };

    const dMDBoxProps = {
        className: `${feedbackClasses?.downloadingMetrcDataMsg?.box} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     mx: 'auto',
        //     maxWidth: `${setupFormMaxWd}px`,
        //     pt: '72px',
        //     width: '100%',
        // },
        ...props
    }

    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...dMDBoxProps}>
                <Box {...oSDownloadBoxProps}>
                    <CircularProgress {...oSProgressProps} />
                </Box>
                <Typography {...oSDownloadTitleTypoProps}>{downloadTitle1}</Typography>
                <Typography {...oSDownloadTimeTypoProps}>{downloadTimeInfo}</Typography>
            </Box>
        </Fade>
    )
};