
import React, { useState } from 'react';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import { specificpopupClasses } from '../../utility';
import { Button } from '@mui/material';
import MotherPlantPopup from './MotherPlantPopup';



export default function Specificentrypopup(props) {
  const { open, onClose, currDarkThemeClass, selectedFarmId, plantCount, filteredStrains, selectedFarmName, farmId, ...otherProps } = props;
  const [motherplantPopupOpen, setMotherplantPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const openMotherPlantPopup = (option) => {
    setMotherplantPopupOpen(true);
    setSelectedOption(option);
  };

  const closePopup = () => {
    setMotherplantPopupOpen(false);
    setSelectedOption(null);
    onClose();
  };

  const handleSaveClick = () => {
    console.log('Save button clicked in Specificentrypopup');
    closePopup();
  };

  const entityDetailPopupProps = {
    open,
    headContentText: '',
    toShowSave: false,
    onClose: closePopup,
    currDarkThemeClass,
    onSaveClick: handleSaveClick,
  };

  const sPProceedBtnProps = (message = 'Option', otherBtnProps = {}) => ({
    variant: 'contained',
    color: 'primary',
    className: `${specificpopupClasses?.proceedBtn} ${currDarkThemeClass}`,
    onClick: () => openMotherPlantPopup(message),
    ...otherBtnProps
  });

  return (
    <EntityDetailPopup {...entityDetailPopupProps}>
      <Button {...sPProceedBtnProps('1', {disabled: true})}>Add Strain From Unknown</Button>
      <Button {...sPProceedBtnProps('2')}>Add Strain From Mother plant</Button>
      <Button {...sPProceedBtnProps('3', {disabled: true})}>Add Strain From Package</Button>

      {motherplantPopupOpen && (
        <MotherPlantPopup
          selectedFarmId={selectedFarmId}
          plantCount={plantCount}  
          filteredStrains={filteredStrains}
          selectedFarmName={selectedFarmName} 
          farmId={farmId} 
          open={motherplantPopupOpen}
          onClose={closePopup}
          selectedOption={selectedOption}
          currDarkThemeClass={currDarkThemeClass}
          {...otherProps}
        />
      )}
    </EntityDetailPopup>
  );
}