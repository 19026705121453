import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import { baseClasses, fadeTimingMs, landingPageRoute } from "../utility/constants";

import logoImage from '../../images/sg_dash_16x16.png';

const title = 'SMARTGROW';

export default function OrgOutlinePage(props) {
    
    const {
        logoBoxProps={},
        currDarkThemeClass='',
    } = props;


    const navigate = useNavigate();

    // const currDarkThemeClass = useThemeVal(theme);

    // console.log('currDarkThemeClass in OrgOutlinePage');
    // console.log(currDarkThemeClass);


    const sOBoxProps = {
        className: `${baseClasses?.orgSetupOutline?.box} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'primary.100',
        //     display: 'flex',
        //     minHeight: '100vh',
        //     width: '100%',
        // },
    };

    // const sOBgImageBoxProps = {
    //     className: `${baseClasses?.orgSetupOutline?.imageBox} ${currDarkThemeClass}`,
    //     sx: {
    //         flexBasis: '50%',
    //     },
    // };

    const sOContentBoxProps = {
        className: `${baseClasses?.orgSetupOutline?.contentBox} ${currDarkThemeClass}`,
        // sx: {
        //     flexBasis: '100%',
        //     pl: '32px',
        //     pt: '37px',
        // },        
    };

    const sOLogoBoxProps = {
        className: `${baseClasses?.orgSetupOutline?.logoBox} ${currDarkThemeClass}`,
        sx: {
            ...logoBoxProps,
        },
    };

    const sOLogoImageBoxProps = {
        className: `${baseClasses?.orgSetupOutline?.logoImageBox} ${currDarkThemeClass}`,
        // sx: {},
    };

    const sOImgProps = {
        alt: 'Smartgrow logo',
        className: `${baseClasses?.orgSetupOutline?.logoImage} ${currDarkThemeClass}`,
        component: 'img',
        height: '14px',
        src: logoImage,
        width: '14px',
    };

    const sOBrandTypoProps = {
        onClick: () => navigate(landingPageRoute),
        className: `${baseClasses?.orgSetupOutline?.brandTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '14px',
        //     fontWeight: 700,
        //     lineHeight: '18px',
        //     mt: '8px',
        //     textTransform: 'uppercase',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        // },
    };


    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...sOBoxProps}>
                <Box {...sOContentBoxProps}>
                    <Box {...sOLogoBoxProps}>
                        <Box {...sOLogoImageBoxProps}>
                            <Box {...sOImgProps} />
                        </Box>
                        <Typography {...sOBrandTypoProps}>{title}</Typography>
                    </Box>
                    {props.children}
                </Box>
            </Box>
        </Fade>
    );
}