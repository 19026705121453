import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { defaultNoAuthRoute, defaultNoMetrcRoute, logoutUser } from '../components/utility/constants';

import hasNotCompletedSetup from '../checks/setup';

const getTokenState = () => {
  let token = localStorage.getItem('token'),
    permissions = localStorage.getItem('permissions'),
    tokenDetails = {},
    tokenErr = false,
    setupErr = false,
    permissionsErr = false;

  try {
    tokenDetails = JSON.parse(atob(token.split('.')[1]));
    permissions = JSON.parse(permissions);

    const { exp = 0 } = tokenDetails;

    if (new Date(exp * 1000) < new Date() || !permissions) throw new Error();
  } catch {
    token = null;
    tokenErr = true;

    logoutUser();
  } finally {
    setupErr = hasNotCompletedSetup(tokenDetails);
    if (!permissions) permissionsErr = true;
  }

  return { token, tokenDetails, permissions, tokenErr, setupErr, permissionsErr };
};

// const defaultDeps = [localStorage.getItem('token')]; // This will return the actual value and might not be reliable
// const defaultDeps = [localStorage?.token]; // Same issue as above

export default function useToken(props) {
  let { dependencies = [] } = props || {};

  const navigate = useNavigate();

  const [values, setValues] = useState(getTokenState);

  useEffect(() => {
    setValues(getTokenState);

    // }, dependencies);
  }, [localStorage.token, ...dependencies]);

  // useEffect(() => setValues(getTokenState)); // This is causing infinite re-render as it should

  useEffect(() => {
    if (values?.tokenErr) {
      navigate(defaultNoAuthRoute);
    } else if (values?.setupErr) {
      navigate(defaultNoMetrcRoute);
    } else if (values?.permissionsErr) {
      navigate(defaultNoAuthRoute);
    }
  }); // Removed dependencies

  // useEffect(() => { if ( values?.tokenErr ) { navigate(defaultNoAuthRoute) } }, [values?.tokenErr]);

  // useEffect(() => { if ( values?.setupErr && !values?.tokenErr ) { navigate(defaultNoMetrcRoute) } }, [values?.setupErr]);

  return { token: values?.token, tokenDetails: values?.tokenDetails, permissions: values?.permissions };
}
