import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import useThemeVal from '../../utility/useThemeVal';
import useToken from '../../utility/useToken';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import BaseNewAndDetail from '../utility/outlines/BaseNewAndDetail';
import DetailInfoCard from '../utility/DetailInfoCard';
import ExpandCard from '../utility/ExpandCard';
import { PhasePlanBreadcrumbs } from '../utility/BreadcrumbLinks';
import PrevNextFab from '../utility/fabs/PrevNextFab';
import SubPhaseEntry from './SubPhaseEntry';
import { hasPermissions } from '../../utility';
import { AddFarmIcon, MetrcLogo, PlansIcon } from '../utility/CustomSvgIcons';

import {
  NEW,
  fadeTimingMs,
  crudActions,
  farmAppLabel,
  phasePlanModelName,
  landingPageRoute,
  detailPhasePlanEndpoint,
  getNewEntityDepsEndpoint,
  logMsgToConsole,
  maxAccordionCardsBoxHt,
  listPhasePlanEndpoint,
  phasePlanNewAndDetailRoute,
  // defUndVal,
  phasePlanClasses,
  PLAN_WIZARD_TYPE,
  PLAN_WIZARD,
  getWizardRoute,
  errFeedbackFn,
} from '../utility/constants';

import APIService from '../../api/apiService';
import { failsTokenAndSetupCheck } from '../../checks/setup';
import { isNotValidDetailPageView } from '../../checks/detailPage';

import { FeedbackContext } from '../../App';
import { getPhasePlanFabState, handlePhasePlanNextStep, handlePhasePlanPrevStep } from '../utility/exec';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import PhasePlanGanttChartBar from './PhasePlanGanttChartBar';

// NOTE:
// isNaN(+id) or similar comparisons indicate whether an entity exists in backend:
//      if true: it means it was created in frontend and given an alphanumeric value
//      else: it exists in backend and is numeric

// Constant Values
// ########################################

const defUndVal = '';

const tabEntityMap = [
  { key: 'overview', label: 'Overview', defaultForDetail: true },
  { key: 'general-info', label: 'General Info', validForNew: true, defaultForNew: true, prevTab: null, nextTab: 'sub-phase-details' },
  { key: 'sub-phase-details', label: 'Sub Phase Details', validForNew: true, prevTab: 'general-info', nextTab: null },
];

const nameArgs = {
  autoFocus: true,
  inputProps: {
    maxLength: 255,
  },
  label: 'Name',
  prop: 'name',
};

const descArgs = {
  inputProps: {
    maxLength: 255,
  },
  label: 'Description',
  maxRows: 7,
  minRows: 7,
  multiline: true,
  prop: 'description',
  placeholder: 'Write a description for your Phase',
};

const subPhaseTitleMap = [
  { key: 'name', title: 'Name' },
  { key: 'start_day', title: 'Start Day' },
  { key: 'end_day', title: 'End Day' },
];

// ########################################

const handleAddSubPhaseEntry = (values, handleSetValues) => () => {
  const id = `tempRef-${values?.subphases?.length || 0}`;

  const newSubPhases = [...(values?.subphases || []), { name: '', id, default_routines: [] , isIncluded:true , is_metrc_compliance:false , priority:  values?.subphases.length,}];
  
    //let updatedSubPhases = [...(values?.subphases || [])];
  handleSetValues('subphases', newSubPhases);
};

export default function PhasePlan(props) {

  const { token, tokenDetails, permissions } = useToken();
  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  const [savedRes, setSavedRes] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState({
    isCompliance: false,
  });

  const feedbackCX = useContext(FeedbackContext);

  const changeRef = useRef(false);

  const { phaseplanid } = useParams();

  const currDarkThemeClass = useThemeVal();

  const { is_staff = false } = tokenDetails || '';

  const phasePlanPerms = useMemo(() => hasPermissions([phasePlanModelName], { ...tokenDetails, permissions })[phasePlanModelName] ?? {}, [token]);

  const baseEndpointCheck = phaseplanid === NEW;

  const tabValue = searchParams.get('tab');

  const showPrevNextFab = useMemo(
    () =>
      !values?.loading?.fetching &&
      !values?.loading?.processing &&
      baseEndpointCheck &&
      Boolean(tabEntityMap?.find(e => e?.validForNew && e?.key === tabValue)),
    [values?.loading?.fetching, values?.loading?.processing, baseEndpointCheck, tabValue]
  );

  const handleSetValues = (prop, value) => setValues(state => ({ ...state, [prop]: value }));
  const handleSetSavedRes = (prop, value) => setSavedRes(state => ({ ...state, [prop]: value }));

  const handleNewPhasePlanDepsRes = res => {
    if (logMsgToConsole?.phaseplan) {
      console.log('got data for new phase');
      console.log(res);
    }

    let { data = [] } = res;

    const subphases = data?.subphases?.map((e, i) => ({ ...e, isIncluded: true, id: `tempRef-${i}` }));

    handleSetValues('subphases', subphases);
    handleSetValues('default_routines', data?.default_routines ?? []);
    handleSetSavedRes('subphases', subphases);
    handleSetSavedRes('default_routines', data?.default_routines ?? []);
  };

  const handlePhasePlanRes =
    (modify = false) =>
    res => {
      if (logMsgToConsole?.phaseplan) {
        console.log('got phase detail');
        console.log(res);
      }

      const { data } = res;

      const subphases = data?.subphases?.map(e => ({ ...e, isIncluded: true , 'total_day':0}));

      setValues(state => ({
        ...state,
        ...data,
        subphases,
        isCompliance: data?.is_metrc_compliance || false, // Ensure isCompliance is set from backend
      }));

      setSavedRes(state => ({
        ...state,
        ...data,
        subphases,
        isCompliance: data?.is_metrc_compliance || false,
        
      }));

      if (modify) {
        handleSetValues('changeTab', tabEntityMap[2].key);
      }
    };

  const handleCreatePhasePlanRes = res => {
    if (logMsgToConsole?.phaseplan) {
      console.log('phase created successfully');
      console.log(res);
    }

    const {
      data: { id },
    } = res;

    const defRoute = `${phasePlanNewAndDetailRoute}/${id}/?tab=${tabEntityMap[0].key}`;

    const route = getWizardRoute(PLAN_WIZARD_TYPE, PLAN_WIZARD.PHASEPLAN_CREATE, defRoute);

    navigate(route);
  };

  const handleErrorWithFeedback = errPropsFn => err => {
    if (logMsgToConsole?.phaseplan) {
      console.log('An error occured in phaseplan');
      console.log(err);
    }

    const errProps = errPropsFn?.(err) ?? { message: 'Operation failed', severity: 'error' };

    feedbackCX.setContextValue(true, errProps);
  };

  const handleFinally = () => {
    handleSetValues('loading', {});
  };

  const toDisable = () => {
    let genInfoCheck = false;
    let subPhaseCheck = false;
    

    if (phaseplanid === NEW) {
      // All checks must pass i.e. return true for save button to be enabled ; Then we send the complement value of all checks
      // NEW: Enabled when all values were provided
      genInfoCheck = values?.name && values?.description;

      subPhaseCheck =
        subPhasesList?.every(e => !e?.props?.subPhaseErr) &&
        values?.subphases?.every(e => (e?.isIncluded ? e?.name && +e?.start_day && +e?.end_day : true));
      return !(genInfoCheck && subPhaseCheck);
      
    } else {
      // Enabled when at least one check has passed i.e. return true and the other tab does not have invalid state

      const genInfoValid = Boolean(values?.name && values?.description);
      const genInfoChange = values?.name !== savedRes?.name || values?.description !== savedRes?.description;

      genInfoCheck = genInfoValid && genInfoChange;

      const subPhaseValid = subPhasesList?.every(e => !e?.props?.subPhaseErr);

      const subPhaseChange =
        values?.subphases?.some(e => isNaN(+e?.id) && e?.isIncluded) ||
        !savedRes?.subphases?.every(e =>
          values?.subphases?.find(
            el =>
              +e?.id === +el?.id &&
              e?.isIncluded === el?.isIncluded &&
              e?.name === el?.name &&
              +e?.start_day === +el?.start_day &&
              +e?.end_day === +el?.end_day &&
              JSON.stringify(e?.default_routines) === JSON.stringify(el?.default_routines)
          )
        ); // Not conducting reverse test since we test first whether each entry has an id number

      subPhaseCheck = subPhaseValid && subPhaseChange ;

      // const isComplianceChange = values?.isCompliance !== savedRes?.is_metrc_compliance;
      // return !((genInfoCheck && subPhaseValid) || (subPhaseCheck && genInfoValid) || isComplianceChange);
      return !((genInfoCheck && subPhaseValid) || (subPhaseCheck && genInfoValid));
      
    }
  };
  
  const navigate = useNavigate();

  // const navigate = useControlledNavigate(toDisable, testControlFn, [changeRef?.current], false);

  // LATEST VERSION IN USE BEFORE RESPONSE CHANGED TO ARRAY
  // const navigate = useControlledNavigate(toDisable, testControlFn, [values?.unsavedChanges], false);

  useEffect(() => {
    if (changeRef.current !== values?.unsavedChanges) {

      handleSetValues('unsavedChanges', changeRef.current);
    }
  });
 //Fetch phase plan details
  useEffect(() => {
    if (invalidTokenState) return;

    let errConfig = {};

    if (baseEndpointCheck) {
      if (!phasePlanPerms?.create) return;

      handleSetValues('loading', { ...values?.loading, fetching: true });

      errConfig = {
        404: 'Phase Types Not Found. Creating Phase not possible',
      };

      const entity = values?.isCompliance ? `${getNewEntityDepsEndpoint('phaseplan')}?isCompliance=${true}` : getNewEntityDepsEndpoint('phaseplan');
      
      APIService.fetchInstance(entity)
      
        .then(handleNewPhasePlanDepsRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally);

      return;
    }

    // if (!phasePlanPerms?.read || isNotValidDetailPageView(token, phaseplanid)) return;
    if (!phasePlanPerms?.read || isNotValidDetailPageView(token, phaseplanid)) {
      navigate(landingPageRoute);

      return;
    }

    handleSetValues('loading', { ...values?.loading, fetching: true });

    const entityWithID = detailPhasePlanEndpoint(phaseplanid);
    APIService.fetchInstance(entityWithID)
      .then(handlePhasePlanRes())
      .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
      .finally(handleFinally);
  }, [phaseplanid , values?.isCompliance]);

  if (invalidTokenState || !phasePlanPerms?.read) return <></>;

  const phasePlanOverviewWrapperBoxProps = {
    className: `${phasePlanClasses?.overviewWrapperBox} ${currDarkThemeClass}`,
  };

  const phasePlanBarBoxProps = {
    className: `${phasePlanClasses?.barBox} ${currDarkThemeClass}`,
  };

  const totalDays = savedRes?.subphases?.reduce((sum, val) => sum + +val?.end_day - +val?.start_day, 0);


  //for the old bar
  //---------------

  // const phasePlanBarEntryProps = ({ start_day, end_day }) => ({
  //   className: `${phasePlanClasses?.barEntryBox} ${currDarkThemeClass}`,
  //   sx: {
  //     flexBasis: +totalDays < 1 ? 'auto' : `${((+end_day - +start_day) / totalDays) * 100}%`,
  //   },
  // });

  // const phasePlanBarDayTypoProps = {
  //   className: `${phasePlanClasses?.barDayTypo} ${currDarkThemeClass}`,
  // };

  // const phasePlanBarTypeTypoProps = {
  //   className: `${phasePlanClasses?.barTypeTypo} ${currDarkThemeClass}`,
  // };

  // const phasePlanBar = savedRes?.subphases?.map((e, i) => (
  //   <Box key={i} {...phasePlanBarEntryProps(e)}>
  //     <Typography {...phasePlanBarDayTypoProps}>{e?.start_day}</Typography>

  //     <Tooltip title={`${e?.name} Phase`}>
  //       <Typography {...phasePlanBarTypeTypoProps}>P{i + 1}</Typography>
  //     </Tooltip>
  //     <Typography {...phasePlanBarDayTypoProps}>{e?.end_day}</Typography>
  //   </Box>
  // ));

  const phasePlanOverviewBoxProps = {
    className: `${phasePlanClasses?.overviewBox} ${currDarkThemeClass} roomDetailTabsContent`,
  };

  const phasePlanOverviewInfoTypoProps = {
    className: `${phasePlanClasses?.overviewInfoTypo} ${currDarkThemeClass}`,
  };

  const phasePlanOverviewInfoIconProps = {
    className: `${phasePlanClasses?.overviewInfoIcon} ${currDarkThemeClass}`,
  };

  const subPhasesTitles = savedRes?.subphases?.map(e => e?.name)?.join(', ');

  const overviewEntries = [
    {
      key: 'Name',
      value: (
        <Typography {...phasePlanOverviewInfoTypoProps}>
          <PlansIcon {...phasePlanOverviewInfoIconProps} />
          {savedRes?.name ?? ''}
        </Typography>
      ),
    },
    {
      key: 'Metrc Compliance',
      value: (
        <Typography {...phasePlanOverviewInfoTypoProps}>
          <PlansIcon {...phasePlanOverviewInfoIconProps} />
          {values?.isCompliance ? 'Yes' : 'No'}
        </Typography>
      ),
    },
    {
      key: 'Sub Phases Count',
      value: (
        <Typography {...phasePlanOverviewInfoTypoProps}>
          <PlansIcon {...phasePlanOverviewInfoIconProps} />
          {savedRes?.subphases?.length}
        </Typography>
      ),
    },
    {
      key: 'Sub Phases',
      value: (
        <Typography {...phasePlanOverviewInfoTypoProps}>
          <PlansIcon {...phasePlanOverviewInfoIconProps} />
          {subPhasesTitles ?? ''}
        </Typography>
      ),
    },
  ];

  const phasePlanOverviewCardProps = {
    currDarkThemeClass,
    editLink: () => handleSetValues('changeTab', tabEntityMap[1].key), // TODO: use this signal to set tab in child useEffect
    entries: overviewEntries,
    title: 'General Info',
    toShowEditBtn: phaseplanid !== NEW && phasePlanPerms?.update,
    otherCardPaperSxProps: {
      gridArea: 'a',
    },
  };

  const planDescEntries = [
    {
      key: ' ', // For adding space above the description
      value: <Typography {...phasePlanOverviewInfoTypoProps}>{savedRes?.description ?? ''}</Typography>,
    },
  ];

  const phasePlanDescProps = {
    currDarkThemeClass,
    editLink: () => handleSetValues('changeTab', tabEntityMap[1].key), // TODO: use this signal to set tab in child useEffect
    entries: planDescEntries,
    title: 'Plan Description',
    toShowEditBtn: phaseplanid !== NEW && phasePlanPerms?.update,
    otherCardPaperSxProps: {
      gridArea: 'b',
    },
  };

  const phasePlanSubAccProps = subphase => ({
    cardIcon: PlansIcon,
    cardTitle: subphase?.name ?? defUndVal,
    currDarkThemeClass,
    entries: [
      { key: 'Start Day', value: subphase?.start_day ?? defUndVal },
      { key: 'End Day', value: subphase?.end_day ?? defUndVal },
    ],
  });

  const subPhaseDetailEntries = savedRes?.subphases?.map((e, i) => ({
    value: <ExpandCard key={i} {...phasePlanSubAccProps(e)} />,
  }));

  const phasePlanSubPhaseDetailsProps = {
    currDarkThemeClass,
    editLink: () => handleSetValues('changeTab', tabEntityMap[2].key), // TODO: use this signal to set tab in child useEffect
    cardTitleMarginBottom: '28px',
    entries: subPhaseDetailEntries,
    otherCardPaperSxProps: {
      gridArea: 'c',
    },
    otherEntriesBoxSxProps: {
      height: `${maxAccordionCardsBoxHt}px`,
      overflow: 'auto',
    },
    title: 'Sub Phase Details',
    toShowEditBtn: phaseplanid !== NEW && phasePlanPerms?.update,
  };
  const OverviewTab =
    phaseplanid === NEW ? (
      <></>
    ) : (
      <Box {...phasePlanOverviewWrapperBoxProps}>
        {/* <Box {...phasePlanBarBoxProps}>{phasePlanBar}</Box> */}
        <PhasePlanGanttChartBar phasesData={savedRes} currDarkThemeClass={currDarkThemeClass}/>
        <Box {...phasePlanOverviewBoxProps}>
          <DetailInfoCard {...phasePlanOverviewCardProps} />
          <DetailInfoCard {...phasePlanDescProps} />
          <DetailInfoCard {...phasePlanSubPhaseDetailsProps} />
        </Box>
      </Box>
     
    );

  const phasePlanTextFieldProps = ({ prop, inputProps = {}, otherSxProps = {}, otherInputProps = {}, ...otherTextProps }) => ({
    autoComplete: 'off',
    className: `${phasePlanClasses?.textField} ${currDarkThemeClass}`,
    disabled: values?.loading?.processing || values?.loading?.fetching,
    inputProps,
    InputLabelProps: {
      ...(prop === 'description' && {
        shrink: true,
      }),
    },
    InputProps: {
      ...otherInputProps,
    },
    label: ' ',
    onChange: ({ target: { value } }) => handleSetValues(prop, value),
    required: true,
    sx: {
      ...otherSxProps,
    },
    value: values?.[prop] ?? '',
    ...otherTextProps,
  });

  const phasePlanGenInfoBoxProps = {
    className: `${phasePlanClasses?.genInfoBox} ${currDarkThemeClass}`,
  };

  const phasePlanGenInfoTitleTypoProps = {
    className: `${phasePlanClasses?.genInfoTitleTypo} ${currDarkThemeClass}`,
  };

  const phasePlanAddSubPhaseIconProps = {
    className: `${phasePlanClasses?.addSubPhaseIcon} ${currDarkThemeClass}`,
  };
// *********for the NEW Add Sub Phase ************

  const phasePlanAddSubPhaseBtnProps = {
    children: 'Add Sub Phase',
    className: `${phasePlanClasses?.addSubPhaseBtn} ${currDarkThemeClass}`,
    disabled: values?.loading?.processing || values?.loading?.fetching,
    onClick: handleAddSubPhaseEntry(values, handleSetValues),
    startIcon: <AddFarmIcon {...phasePlanAddSubPhaseIconProps} />,
  };

  const handleIsComplianceChange = event => {
    const isChecked = event.target.checked;
    handleSetValues('isCompliance', isChecked);
    
  };

  const isComplianceCheckboxProps = {
    checked: values?.isCompliance,
    onChange: handleIsComplianceChange,
    style: { color: 'white' },
  };

  const isComplianceCheckboxLabelProps = {
    control: <Checkbox {...isComplianceCheckboxProps} />,
    label: <Typography style={{ color: 'white' }}>Is Metrc Compliance</Typography>,
  };

  const GeneralInfoTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...phasePlanGenInfoBoxProps}>
        <Typography {...phasePlanGenInfoTitleTypoProps}>Provide Details for this Phase.</Typography>
        <TextField {...phasePlanTextFieldProps(nameArgs)} />
        <TextField {...phasePlanTextFieldProps(descArgs)} />
        {phaseplanid === NEW && (<FormControlLabel {...isComplianceCheckboxLabelProps} />)}
      </Box>
    </Fade>
  );

  const phasePlanSubPhaseBoxProps = {
    className: `${phasePlanClasses?.subPhaseBox} ${currDarkThemeClass}`,
  };

  const phasePlanSubPhaseTitleBoxProps = {
    className: `${phasePlanClasses?.subPhaseTitleBox} ${currDarkThemeClass}`,
  };

const getSubPhaseErr = (subphase) => {
  const { start_day, end_day, isIncluded, id } = subphase || {};

  if (!isIncluded) return false; // Only check included subphases

  return values?.subphases?.some((obj) => {
    // Do not compare with non-included subphases or with self
    if (!obj?.isIncluded || (isNaN(+id) ? obj?.id === id : +obj?.id === +id)) return false;

    // Self-check: Ensure the start_day is not after its own end_day
    if (+start_day > 0 && +end_day > 0) {
      if (+start_day > +end_day) {
        return `Start day (${start_day}) cannot be after end day (${end_day}).`;
      }

      // If start_day is equal to end_day, ensure total_days is set to 1
      if (+start_day === +end_day) {
        subphase.total_day = 1; // Ensure 1 day when start and end days are the same
      } else {
        subphase.total_day = +end_day - +start_day + 1; // Calculate total days normally
      }

      // Ensure end day cannot be negative
      if (+end_day < 0) {
        return `End day (${end_day}) cannot be a negative number.`;
      }
    }

    // Allow multiple subphases on the same day (start_day and end_day can be the same)
    if (+start_day === +obj?.start_day && +end_day === +obj?.end_day) {
      return false; // Allow same-day tasks, no error
    }

    // Check for overlaps: A subphase should not start before another ends
    if (+start_day > 0 && +end_day > 0 && +obj?.start_day > 0 && +obj?.end_day > 0) {
      // Modify overlap logic to allow same-day transitions
      return (
        (+start_day < +obj?.end_day && +end_day > +obj?.start_day) || // No overlapping subphases
        (+end_day > +obj?.start_day && +end_day < +obj?.end_day) || 
        (+start_day < +obj?.start_day && +end_day > +obj?.end_day)
      );
    }

    return false; // No errors if conditions don't apply
  });
};

  
   const phasePlanSubEntryProps = (e, i) => ({
    currDarkThemeClass,
    pos: i,
    subphase: e,
    subPhaseErr: getSubPhaseErr(e),
    values,
    readOnly:true,

    handleSetValues,
  });

  const newAlertMsg = 'please save your changes now.'
  const subPhasesList = values?.subphases?.map((e, i) => <SubPhaseEntry key={i} {...phasePlanSubEntryProps(e, i)} />);

  const SubPhaseDetailsTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...phasePlanSubPhaseBoxProps} >
      {!toDisable() && (<Alert severity="info" sx={{ marginTop: -3.5, marginBottom:1 , position:'sticky', top:0 ,zIndex:10 }}>
  {newAlertMsg}
</Alert>)}
          <Box {...phasePlanSubPhaseTitleBoxProps}>
          <Typography {...phasePlanGenInfoTitleTypoProps}>Provide Start And End Day Values for these Sub Phases.</Typography>
          {!values?.isCompliance && (!values?.subphases || values?.subphases.length === 0) && <Button {...phasePlanAddSubPhaseBtnProps} />}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          {subPhasesList}
        </Box>
      </Box>
    </Fade>
  );

  const tabContentMap = {
    overview: OverviewTab,
    'general-info': GeneralInfoTab,
    'sub-phase-details': SubPhaseDetailsTab,
  };

  const breadcrumbsProps = {
    currLink: baseEndpointCheck ? values?.name ?? 'Create Phase' : savedRes?.name ?? defUndVal,
    currDarkThemeClass,
    navigate,
  };

  const breadcrumbs = <PhasePlanBreadcrumbs {...breadcrumbsProps} />;

  const showSaveBtnSpinner = values?.loading?.processing;

  const handleSave = event => {
    if (toDisable()) return;

    let errConfig = {};

    const payload = {
      ...values,
     
      is_metrc_compliance: values?.isCompliance,  // Top-level is_compliance
      subphases: values?.subphases?.map(subphase => ({
        ...subphase,
        is_metrc_compliance: subphase?.is_metrc_compliance ?? values?.isCompliance, // Retain original compliance for subphase
        priority: Number(subphase?.priority),   // Ensure priority is a number
      })).filter(subphase => subphase?.isIncluded),
      
      default_routines: values?.default_routines?.map(routine => ({
        ...routine,
        is_metrc_compliance: routine?.is_metrc_compliance ?? values?.isCompliance, // Retain original compliance for routines
      })),
    };
    console.log('Saving the following payload:', payload)
    if (phaseplanid === NEW) {
      if (!phasePlanPerms?.create) return;

      errConfig = {
        create: true,
        resource: 'Phase',
      };

      handleSetValues('loading', { ...values?.loading, processing: true });

      const entity = listPhasePlanEndpoint;

      // payload.subphases = payload.subphases?.filter(e => Boolean(payload?.phaseplan?.[e?.type_id])); // Only add included subphase names

      APIService.createInstance(entity, payload)
        .then(handleCreatePhasePlanRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally);

      return;
    }

    // TODO: Modify response if needed for update as well
    if (!phasePlanPerms?.update) return;

    handleSetValues('loading', { ...values?.loading, processing: true });

    errConfig = {};

    const entity = detailPhasePlanEndpoint(phaseplanid);

    APIService.modifyInstance(entity, payload)
      .then(handlePhasePlanRes(true))
      .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
      .finally(handleFinally);
  };

  const handleSetChangeTab = value => handleSetValues('changeTab', value);


  const showSaveBtn = tab => tab !== 'overview' && (baseEndpointCheck ? phasePlanPerms?.create : phasePlanPerms?.update);

  const getDefaultTabForEndpoint = id =>
    id === NEW ? tabEntityMap?.find(tab => tab?.defaultForNew)?.key : tabEntityMap?.find(tab => tab?.defaultForDetail)?.key;

  const getValidTabForNewEntity = tab =>
    tabEntityMap?.find(val => val?.key === tab && val?.validForNew)?.key ?? tabEntityMap?.find(tab => tab?.defaultForNew)?.key;

  const phasePlanProps = {
    // variables
    baseEntityName: savedRes?.name,
    baseid: phaseplanid,
    changeTab: values?.changeTab,
    createBaseEntityMsg: 'Create Phase',
    tabEntityMap,
    loading: values?.loading?.fetching,

    // components
    breadcrumbs,
    tabContentMap,

    //classes
    currDarkThemeClass,

    // checks
    baseEndpointCheck,
    showSaveBtnSpinner,

    // functions
    getDefaultTabForEndpoint,
    getValidTabForNewEntity,
    handleSave,
    handleSetChangeTab,
    showSaveBtn,
    toDisable,
  };

  const fabState = getPhasePlanFabState({ phaseplanid, values, tabValue });

  const prevNextFabProps = {
    currDarkThemeClass,

    ...fabState,

    handleNextStep: handlePhasePlanNextStep({ phaseplanid, values, tabValue, tabEntityMap, handleSetTabValue: handleSetChangeTab }),
    handlePrevStep: handlePhasePlanPrevStep({ phaseplanid, values, tabValue, tabEntityMap, handleSetTabValue: handleSetChangeTab }),
  };

  return (
    <>
      <BaseNewAndDetail {...phasePlanProps} />
      {showPrevNextFab && <PrevNextFab {...prevNextFabProps} />}
    </>
  );
}
