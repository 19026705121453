import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stepper, Step, StepLabel, StepConnector, Typography, Box, Button, Popper, Paper, TextField, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DownArrowIcon } from './CustomSvgIcons';
import { generalFunctionClasses, metricPlanCardClasses, metricPlanClasses, miscClasses, NEW, popupClasses } from './constants';
import { FeedbackContext } from '../../App';

const ThickConnector = styled(StepConnector)({
  '& .MuiStepConnector-line': {
    height: 3,
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 2,
  },
});

const CustomStepIcon = props => {
  const { icon } = props;
  return (
    <Box
      sx={{
        width: 32,
        height: 32,
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold',
        // zIndex: 1,
      }}
    >
      {icon}
    </Box>
  );
};

export const pythonToJsonArray = pythonStr => {
  try {
    const jsonStr = pythonStr
      .replace(/\bTrue\b/g, 'true')
      .replace(/\bFalse\b/g, 'false')
      .replace(/\bNone\b/g, 'null')
      .replace(/'/g, '"')
      .replace(/(\d+):/g, '"$1":');

    const jsonObj = JSON.parse(jsonStr);
    return [jsonObj];
  } catch (error) {
    console.error('Error converting to JSON:', error);
    return null;
  }
};

export const RoutineStepper = props => {
  const { currDarkThemeClass = '', general_fns, handleSetValues, values, handleSetTask, defaultroutineid, handleSetTaskValues, handleAddKeyRow } = props;

  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedFunctions, setSelectedFunctions] = useState(() => {
    return values?.routine_tasks?.[0]?.general_functions?.[0]?.default_task_general_function_orders || [];
  });
  const [selectedFunctionConstant, setSelectedFunctionConstant] = useState(null);
  const [formValues, setFormValues] = useState({
    levelOne: '',
    additionalInstructions: '',
  });

  const currentTask = values?.routine_tasks?.[0];

  const multiGeneralFns = ['id_tag', 'get_weight', 'move', 'destroy'];

  const parseGeneralFunctionDescription = description => {
    try {
      if (typeof description !== 'string') {
        return description;
      }

      // First, check if the string starts with '[' to determine if it's an array
      const isArray = description.trim().startsWith('[');

      // Clean up the Python string syntax
      const jsonStr = description
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false')
        .replace(/\bNone\b/g, 'null')
        .replace(/'/g, '"')
        .replace(/(\d+):/g, '"$1":');

      // Parse the string
      const parsed = JSON.parse(jsonStr);

      return isArray ? parsed[0] : parsed;
    } catch (error) {
      console.error('Error parsing general function description:', error);
      return null;
    }
  };

  const datas = useMemo(() => {
    const eachGeneral = currentTask?.general_functions[0]?.default_task_general_function_orders;
    if (!eachGeneral) return [];

    return eachGeneral.map(eachGen => {
      const parsedDescription = eachGen?.dropdown_description.length === 1 ?  parseGeneralFunctionDescription(eachGen?.dropdown_description[0]) :  parseGeneralFunctionDescription(eachGen?.dropdown_description);
      return {
        ...eachGen,
        dropdown_description: parsedDescription,
      };
    });
  }, [currentTask?.general_functions]);


  const desc = useMemo(() => {
    const baseDesc = options[0]?.description ? options[0]?.description[1] : [];
    return baseDesc;
  }, [options]);

  const canAddGeneralFunction = useCallback((functionKey) => {
    if (functionKey === 'destroy' || functionKey === 'get_weight') {
      const hasWeightOrDestroy = datas.some(data => {
        const key = data.dropdown_description?.key;
        return key === 'destroy' || key === 'get_weight';
      });
      
      if (hasWeightOrDestroy) {
        return false; 
      }
    }
  
    if (!multiGeneralFns.includes(functionKey)) {
      if (functionKey === 'text_with_instructions' || functionKey === 'observation') {
        const existingTextTypes = currentTask?.task_values
          ?.filter(tv => tv.datatype?.startsWith('text_'))
          ?.map(tv => tv.datatype) || [];
        
        if (existingTextTypes.includes('text_01') && existingTextTypes.includes('text_02')) {
          return false;
        }
      }
      return true;
    }
  
    // Check for ID tags and other multi-functions
    const existingFunction = datas.find(data => {
      if (typeof data.dropdown_description === 'string') {
        const parsedDesc = data.dropdown_description;
        return parsedDesc?.key === functionKey;
      }
      return data.dropdown_description?.key === functionKey;
    });
  
    return !existingFunction;
  }, [selectedFunctions, multiGeneralFns, currentTask?.task_values, datas]);

  const getGeneralFunctionsByLevel = useCallback(
    taskLevel => {
      // Find ID tag general function
      const generalFunction = values?.defaultgeneralfns?.find(e => e.key === 'id_tag');
      if (!generalFunction) return null;

      // Level-based mapping for ID tag configurations
      const levelMap = {
        0: { selected_value: 'batch', selected_datatype: ['batch_name'], description: generalFunction.description },
        1: { selected_value: 'strain', selected_datatype: ['strain_name'], description: generalFunction.description },
        2: { selected_value: 'plant', selected_datatype: ['plant_name'], description: generalFunction.description },
        3: { selected_value: 'room', selected_datatype: ['room_name'], description: generalFunction.description },
        4: { selected_value: null, selected_datatype: null, description: generalFunction.description },
      };

      const levelConfig = levelMap[taskLevel] || levelMap[4];

      const parsedDescription = pythonToJsonArray(levelConfig.description);

      if (Array.isArray(parsedDescription) && parsedDescription.length > 0) {
        parsedDescription[0].description[1] = {
          ...parsedDescription[0].description[1],
          selected_value: levelConfig.selected_value,
          selected_datatype: levelConfig.selected_datatype,
        };
      }

      return {
        general_fn: generalFunction,
        selected_value: levelConfig.selected_value,
        tempRef: `func_${Date.now()}`,
        priority: 1,
        dropdown_description: parsedDescription[0],
      };
    }, [values?.defaultgeneralfns]);

  const initializeGeneralFunctionsForTaskLevel = useCallback(() => {
      if (typeof values?.task_level !== 'number') return;

      const initialGeneralFunction = getGeneralFunctionsByLevel(values.task_level);
      if (!initialGeneralFunction) return;

      const dropdownDescriptionArray = Array.isArray(initialGeneralFunction.dropdown_description)
        ? initialGeneralFunction.dropdown_description
        : [initialGeneralFunction.dropdown_description];

      const newSteps = [
        {
          label: initialGeneralFunction.general_fn.name,
          description: `${initialGeneralFunction.general_fn.name}: ${initialGeneralFunction.selected_value || 'Default'}`,
        },
        { label: 'ADD', description: 'Add general Function' },
      ];

      const updatedFunctions = [{ ...initialGeneralFunction, dropdown_description: dropdownDescriptionArray }];
      setSelectedFunctions(updatedFunctions);

      // const currentTask = values?.routine_tasks?.[0];
      if (currentTask) {
        handleSetTask(currentTask, 'general_functions', updatedFunctions);
      }
  }, [values?.task_level, getGeneralFunctionsByLevel, handleSetTask]);

  useEffect(() => {
    if (defaultroutineid === NEW) {
      initializeGeneralFunctionsForTaskLevel();
    }
  }, [values?.task_level]);

  const updateDropdownDescription = (generalFunction, parsedDescription, selectedValue) => {
    let updatedDescription = pythonToJsonArray(parsedDescription);

    if (!updatedDescription[0]?.description?.[1]) {
      return parsedDescription;
    }

    const currentTask = values?.routine_tasks?.[0];
    const existingDatatypes = currentTask?.task_values?.map(task => task.datatype) || [];

    const getNextDatatype = (prefix, max) => {
      for (let i = 1; i <= max; i++) {
        let datatype = `${prefix}_0${i}`;
        if (existingDatatypes.includes(datatype)) {
          return (datatype = `${prefix}_0${i + 1}`);
        }
      }
      return `${prefix}_01`;
    };

    switch (generalFunction.key) {
      case 'get_weight': {
        const weightOption = updatedDescription[0].description[1].options.find(opt => opt.value === selectedValue);
        if (weightOption) {
          updatedDescription[0].description[1] = {
            ...updatedDescription[0].description[1],
            selected_value: selectedValue,
            selected_datatype: ['count_01', 'count_02'],
          };
        }
        break;
      }

      case 'destroy': {
        const destroyOptions = updatedDescription[0].description[1].options.find(opt => opt.value === selectedValue);
        if (destroyOptions) {
          updatedDescription[0].description[1] = {
            ...updatedDescription[0].description[1],
            selected_value: selectedValue,
            selected_datatype: ['count_01', 'count_02'],
          };
        }
        break;
      }

      case 'confirm_location': {
        const locationOption = updatedDescription[0].description[1].options.find(opt => opt.value === selectedValue);
        if (locationOption) {
          updatedDescription[0].description[1] = {...updatedDescription[0].description[1],selected_value: selectedValue,selected_datatype: locationOption.keys};
        }
        break;
      }
      
      case 'move': {
        const moveOption = updatedDescription[0].description[1].options.find(opt => opt.value === selectedValue);
        if (moveOption) {
          updatedDescription[0].description[1] = {
            ...updatedDescription[0].description[1],
            selected_value: selectedValue,
            selected_datatype: moveOption.keys,
          };
        }
        break;
      }

      case 'text_with_instructions':
      case 'observation': {
        const nextTextType = getNextDatatype('text', 2);
        if (nextTextType) {
          updatedDescription[0].description[1] = { ...updatedDescription[0].description[1], selected_value: selectedValue, selected_datatype: [nextTextType],
          };
        }
        break;
      }
    }
    
    return updatedDescription;
  };
  
  const prepareGeneralFunctionData = (selectedFunction, parsedDescription, existingFunctions) => {
    const getNextPriority = () => {
      if (!existingFunctions?.length) return 1;
      const maxPriority = values?.routine_tasks?.[0]?.general_functions?.length || 0;
      return maxPriority + 1;
    };

    const updatedDescription = updateDropdownDescription(selectedFunction.general_fn, parsedDescription, selectedFunction.selected_value);

    const createOrderEntry = (generalFunction, description, priority) => ({
      priority,
      dropdown_description: description,
      general_function: generalFunction.id,
    });

    const prepareGeneralFunction = (generalFunction, selectedValue, tempRef, orderEntry) => ({
      tempRef,
      general_fn: generalFunction,
      selected_value: selectedValue,
      ...orderEntry,
    });

    const priority = getNextPriority();
    const orderEntry = createOrderEntry(selectedFunction.general_fn, updatedDescription, priority);

    return prepareGeneralFunction(selectedFunction.general_fn, selectedFunction.selected_value, selectedFunction.tempRef, orderEntry);
  };

  const handleStepClick = (event, stepData = null) => {
    setPopperAnchorEl(event.currentTarget);
    setSelectedStepId(stepData?.id || stepData?.tempRef);
    setPopperOpen(prevOpen => !prevOpen);

    if (stepData) {
      const selectedFunction = general_fns.find(fn => fn.key === stepData.dropdown_description?.key);
      setSelectedFunctionConstant(selectedFunction);
      
      if (selectedFunction?.description) {
        const parsedDescription = typeof selectedFunction.description === 'string' 
          ? pythonToJsonArray(selectedFunction.description) 
          : [selectedFunction.description];
        setOptions(parsedDescription);
      }

      setFormValues({
        levelOne: stepData.dropdown_description?.description?.[1]?.selected_value || '',
        additionalInstructions: ''
      });
    } else {
      setSelectedFunctionConstant(null);
      setFormValues({ levelOne: '', additionalInstructions: '' });
      setOptions([]);
    }
  };

  const handleUpdateGeneralFunctions = (selectedId) => {
    try {
      if (!selectedId) {
        console.error("No selectedId provided");
        return;
      }
  
      //  existing function
      const existingFunction = selectedFunctions.find(
        func => func.id === selectedId || func.tempRef === selectedId
      );
  
      if (!existingFunction) {
        console.error("Function not found for update");
        return;
      }
  
      // current description and function key
      const currentDescription = typeof existingFunction.dropdown_description === 'string'
        ? parseGeneralFunctionDescription(existingFunction.dropdown_description)
        : existingFunction.dropdown_description;
  
      if (!currentDescription) {
        console.error("Invalid description format");
        return;
      }
  
      const functionKey = currentDescription[0]?.key || currentDescription?.key;
  
      //  current datatypes before update
      const currentDatatypes = Array.isArray(currentDescription)
        ? currentDescription[0]?.description?.[1]?.selected_datatype || []
        : currentDescription?.description?.[1]?.selected_datatype || [];
  
      switch (functionKey) {
        case 'get_weight': {
          const weightTaskValues = currentTask?.task_values?.filter(
            tv => currentDatatypes.includes(tv.datatype)
          );
          
          if (weightTaskValues?.length === 2) {
            const isWetWeight = formValues.levelOne === 'wet_weight';
            const baseKeyName = isWetWeight ? "Wet Weight" : "Dry Weight";
            
            const updatedTaskValues = currentTask.task_values.map(tv => {
              if (tv.datatype === 'count_01') {
                return {
                  ...tv,
                  key: `Estimated ${baseKeyName} Plants`
                };
              } else if (tv.datatype === 'count_02') {
                return {
                  ...tv,
                  key: `Actual ${baseKeyName} Plants`
                };
              }
              return tv;
            });
            
            handleSetTask(currentTask, 'task_values_remove', updatedTaskValues);
          }
          break;
        }
        
        case 'destroy': {
          const destroyTaskValues = currentTask?.task_values?.filter(
            tv => currentDatatypes.includes(tv.datatype)
          );
          
          if (destroyTaskValues?.length === 2) {
            const isPlant = formValues.levelOne === 'plant';
            const baseKeyName = isPlant ? "Plants" : "Packages";
            
            const updatedTaskValues = currentTask.task_values.map(tv => {
              if (tv.datatype === 'count_01') {
                return {
                  ...tv,
                  key: `Estimated ${baseKeyName} to be Destroyed`
                };
              } else if (tv.datatype === 'count_02') {
                return {
                  ...tv,
                  key: `Actual ${baseKeyName} Destroyed`
                };
              }
              return tv;
            });
            
            handleSetTask(currentTask, 'task_values_remove', updatedTaskValues);
          }
          break;
        }

        case 'destroy': {
          const exisitngDestroyValues = currentTask?.task_values?.find(
            tv => currentDatatypes.includes(tv.datatype)
          );
          
          if (exisitngDestroyValues) {
            const newKey = formValues.levelOne === 'plant' ? "Plants" : "Packages";
            handleSetTaskValues(currentTask, exisitngDestroyValues, "key", `${exisitngDestroyValues.key.includes('Estimated') ? 'Estimated' : 'Actual'} ${newKey} ${exisitngDestroyValues.key.includes('Estimated') ? 'to be Destroyed' : 'Destroyed'}`);
          }
          break;
        }
  
        case 'text_with_instructions':
        case 'observation': {
          const existingTextValue = currentTask?.task_values?.find(
            tv => currentDatatypes.includes(tv.datatype)
          );
          
          if (existingTextValue) {
            handleSetTaskValues(currentTask, existingTextValue, 'key', formValues.levelOne);
          }
          break;
        }
  
        case 'confirm_location':
        case 'move': {
          const otherFunctionType = functionKey === 'move' ? 'confirm_location' : 'move';
          const otherFunction = datas.find(data => {
            const key = data?.dropdown_description?.key;
            return key === otherFunctionType && (data.id !== selectedId && data.tempRef !== selectedId);
          });
  
          // new option based on selected value
          const newOption = desc?.options?.find(opt => opt.value === formValues.levelOne);
          if (!newOption) return;
  
          const newDatatypes = newOption.keys;
          const otherDatatypes = otherFunction?.dropdown_description?.description?.[1]?.selected_datatype || [];
  
          const datatypesToKeep = new Set([...newDatatypes, ...otherDatatypes]);
          const currentTaskValues = currentTask?.task_values || [];
  
          const updatedTaskValues = currentTaskValues.filter(taskValue => {
            // Keep the task value
            return !['frtl', 'from_frtl', 'to_frtl'].includes(taskValue.datatype) ||
                   datatypesToKeep.has(taskValue.datatype);
          });
  
          // Add new task values for new datatypes
          newDatatypes.forEach(datatype => {
            if (!updatedTaskValues.some(tv => tv.datatype === datatype)) {
              updatedTaskValues.push({
                tempRef: `temp_${Date.now()}_${Math.random()}`,
                key: datatype.toUpperCase(),
                datatype: datatype,
                value: null,
                dependent_datatype: null,
                isVisible: true,
                isRequired: false,
                isEditable: true
              });
            }
          });
  
          handleSetTask(currentTask, 'task_values_remove', updatedTaskValues);
          break;
        }
  
        default:
          console.warn(`Unhandled function type: ${functionKey}`);
          break;
      }
  
      const updatedDescription = updateDropdownDescription(
        selectedFunctionConstant,
        selectedFunctionConstant.description,
        formValues.levelOne
      );
  
      // Update functions
      const updatedFunctions = selectedFunctions.map(func => {
        if (func.id === selectedId || func.tempRef === selectedId) {
          return {
            ...func,
            selected_value: formValues.levelOne,
            dropdown_description: updatedDescription
          };
        }
        return func;
      });
  
      setSelectedFunctions(updatedFunctions);
      handleCancel();
  
    } catch (error) {
      console.error("Error updating general functions:", error);
    }
  };

  const handleSave = () => {
    if (!selectedFunctionConstant) return;

    const parsedDescription = selectedFunctionConstant.description ? parseGeneralFunctionDescription(selectedFunctionConstant.description) : null;

    // if (selectedStepId) {
    //   handleUpdateGeneralFunctions(selectedStepId);
    // }
    const newFunctionBase = {
      general_fn: selectedFunctionConstant,
      selected_value: formValues.levelOne,
      tempRef: `func_${Date.now()}`,
    };

    const newFunction = prepareGeneralFunctionData(newFunctionBase, selectedFunctionConstant.description, selectedFunctions);

    const updatedFunctions = [...selectedFunctions, newFunction];
    setSelectedFunctions(updatedFunctions);

  
    let currentTask = values?.routine_tasks?.[0];
    if (!currentTask) {
      currentTask = {
        general_functions: [],
        task_values: [],
        task_id: `task_${Date.now()}`,
      };
      if (!values.routine_tasks) {
        handleSetValues({
          ...values,
          routine_tasks: [currentTask],
        });
      }
    }
  
    const existingDatatypes = currentTask.task_values?.map(task => task.datatype) || [];
    
    let newTaskValues = [];
    const updatedDescription = pythonToJsonArray(parsedDescription?.[0]);

    const getNextDatatype = (prefix, max) => {
      for (let i = 1; i <= max; i++) {
        let datatype = `${prefix}_0${i}`;
        if (existingDatatypes.includes(datatype)) {
          return (datatype = `${prefix}_0${i + 1}`);
        }
      }
      return `${prefix}_01`;
    };
  
    switch (selectedFunctionConstant.key) {
      case 'get_weight': {
          const weightType = formValues.levelOne || 'wet_weight';
          const isWetWeight = weightType === 'wet_weight';
          const baseKeyName = isWetWeight ? "Wet Weight" : "Dry Weight";
          
          newTaskValues.push({
            tempRef: `temp_${Date.now()}_${Math.random()}`,
            key: `Estimated ${baseKeyName} Plants`,
            datatype: 'count_01',
            value: null,
            dependent_datatype: null,
            isVisible: true,
            isRequired: false,
            isEditable: true,
          },
          {
            tempRef: `temp_${Date.now()}_${Math.random() + 1}`,
            key: `Actual ${baseKeyName} Plants`,
            datatype: 'count_02',
            value: null,
            dependent_datatype: null,
            isVisible: true,
            isRequired: false,
            isEditable: true,
          })
          if (updatedDescription?.[0]?.description?.[1]) {
            updatedDescription[0].description[1].selected_datatype = ['count_01', 'count_02'];
            updatedDescription[0].description[1].selected_value = weightType;
        }
        break;
      }
  
      case 'destroy': {
        const destroyType = formValues.levelOne;
        const isPlant = destroyType === 'plant';
        const baseKeyName = isPlant ? "Plants" : "Packages";
        
        newTaskValues.push({
          tempRef: `temp_${Date.now()}_${Math.random()}`,
          key: `Estimated ${baseKeyName} to be Destroyed`,
          datatype: 'count_01',
          value: null,
          dependent_datatype: null,
          isVisible: true,
          isRequired: false,
          isEditable: true,
        },
        {
          tempRef: `temp_${Date.now()}_${Math.random() + 1}`,
          key: `Actual ${baseKeyName} Destroyed`,
          datatype: 'count_02',
          value: null,
          dependent_datatype: null,
          isVisible: true,
          isRequired: false,
          isEditable: true,
        });
        if (updatedDescription?.[0]?.description?.[1]) {
          updatedDescription[0].description[1].selected_datatype = ['count_01', 'count_02'];
          updatedDescription[0].description[1].selected_value = destroyType;
        }
        break;
      }

      case 'confirm_location': {
        const locationOption = desc?.options?.find(opt => opt.value === formValues.levelOne);
        if (locationOption?.keys) {
          locationOption.keys.forEach(key => {
            if (!existingDatatypes.includes(key)) {
              newTaskValues.push({
                tempRef: `temp_${Date.now()}_${Math.random()}`,
                key: key.toUpperCase(),
                datatype: key,
                value: null,
                dependent_datatype: null,
                isVisible: true,
                isRequired: false,
                isEditable: true
              });
            }
          });
        }
        break;
      }

      case 'move': {
        const moveOption = desc?.options?.find(opt => opt.value === formValues.levelOne);
        if (moveOption?.keys) {
          moveOption.keys.forEach(key => {
            if (!existingDatatypes.includes(key)) {
              newTaskValues.push({
                tempRef: `temp_${Date.now()}_${Math.random()}`,
                key: key.toUpperCase(),
                datatype: key,
                value: null,
                dependent_datatype: null,
                isVisible: true,
                isRequired: false,
                isEditable: true
              });
            }
          });
        }
        break;
      }

      case 'text_with_instructions':
      case 'observation': {
        const textType = getNextDatatype('text', 2);
        if (textType) {
          const keyValue = formValues.levelOne || selectedFunctionConstant.key;
    
        newTaskValues.push({
          tempRef: `temp_${Date.now()}_${Math.random()}`,
          key: keyValue,
          datatype: textType,
          value: null,
          dependent_datatype: null,
          isVisible: selectedFunctionConstant.key === 'text_with_instructions' ? false : true,
          isRequired: false,
          isEditable: selectedFunctionConstant.key === 'text_with_instructions' ? false : true
        });

        if (updatedDescription?.[0]?.description?.[1]) {
          updatedDescription[0].description[1].selected_datatype = [textType];
          updatedDescription[0].description[1].selected_value = keyValue;
        }
      }
      break;
    }
    }
      
    if (newTaskValues.length > 0) {
      handleSetTask(currentTask, 'task_values', newTaskValues);
    }

    handleCancel();
  };

  useEffect(() => {
    handleSetTask(currentTask, 'general_functions', selectedFunctions);
  }, [selectedFunctions]);

  const handleCancel = () => {
    setSelectedFunctionConstant(null);
    setFormValues({ levelOne: '', additionalInstructions: '' });
    setPopperOpen(false);
  };

  const handleInputChange = (field, value) => {
    setFormValues(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const commonSelectProps = {
    SelectProps: {
      IconComponent: DownArrowIcon,
      MenuProps: {
        className: currDarkThemeClass,
      },
    },
  };

  const handleGeneralFunctionChange = e => {
    const selectedValue = e.target.value;
    const selected = general_fns.find(fc => fc.key === selectedValue);

    if (selected && selected.description) {
      setSelectedFunctionConstant(selected);
      const parsedDescription = typeof selected.description === 'string' ? pythonToJsonArray(selected.description) : [selected.description];

      setOptions(parsedDescription);

      setFormValues({ levelOne: '', additionalInstructions: '' });
    }
  };

  const pythonToJsonArray = pythonStr => {
    try {
      const jsonStr = pythonStr
        .replace(/\bTrue\b/g, 'true')
        .replace(/\bFalse\b/g, 'false')
        .replace(/\bNone\b/g, 'null')
        .replace(/'/g, '"')
        .replace(/(\d+):/g, '"$1":');

      const jsonObj = JSON.parse(jsonStr);

      const jsonArray = [jsonObj];

      return jsonArray;
    } catch (error) {
      console.error('Error converting to JSON:', error);
      return null;
    }
  };

  const generalFunctionProps = {
    select: true,
    label: 'General Function',
    className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
    value: selectedFunctionConstant?.key || '',
    disabled: selectedFunctionConstant?.key === "id_tag",
    onChange: handleGeneralFunctionChange,
    fullWidth: true,
    variant: 'outlined',
    ...commonSelectProps,
  };

  const handleRemoveFunction = () => {
    if (!selectedStepId) return;  
    try {
      const functionToRemove = selectedFunctions.find(
        func => func.id === selectedStepId || func.tempRef === selectedStepId
      );
      if (!functionToRemove) return;
      
      const parsedDescription = typeof functionToRemove.dropdown_description === 'string'
      ? parseGeneralFunctionDescription(functionToRemove.dropdown_description)
      : functionToRemove.dropdown_description;
      const functionKey =  parsedDescription[0]?.key || parsedDescription?.key ;
  
      // Handle id_tag
      if (functionKey === 'id_tag') {
        const updatedFunctions = selectedFunctions
          .filter(func => func.id !== selectedStepId && func.tempRef !== selectedStepId)
          .map((func, index) => ({ ...func, priority: index + 1 }));
        
        setSelectedFunctions(updatedFunctions);
        handleCancel();
        return;
      }

      // handle get_weight and destory
      if (functionKey === 'get_weight' || functionKey === 'destroy') {
        const datatypesToRemove = ['count_01', 'count_02'];
        
        const updatedFunctions = selectedFunctions
          .filter(func => func.id !== selectedStepId && func.tempRef !== selectedStepId)
          .map((func, index) => ({ ...func, priority: index + 1 }));
  
        const updatedTaskValues = currentTask.task_values.filter(
          taskValue => !datatypesToRemove.includes(taskValue.datatype)
        );
  
        setSelectedFunctions(updatedFunctions);
        handleSetTask({ ...currentTask, task_values: updatedTaskValues }, 'task_values_remove', updatedTaskValues);
        handleCancel();
        return;
      }
  
      // Handle confirm_location and move functions
      if (functionKey === 'confirm_location' || functionKey === 'move') {
        const datatypesToRemove = [];

        const currentDatatypes = typeof selectedStepId === "string"
          ? parsedDescription?.[0]?.description?.[1]?.selected_datatype || []
          : parsedDescription?.description?.[1]?.selected_datatype || [];
  
        // find confirm_location or move functions
        const relatedFunctions = datas.filter(data => {
          const key = data?.dropdown_description?.key;
          return (key === 'confirm_location' || key === 'move') && 
                 (data.id !== selectedStepId && data.tempRef !== selectedStepId);
        });
  
        // all datatypes used by other related functions
        const otherFunctionsDatatypes = relatedFunctions.flatMap(func => {
          const desc = func?.dropdown_description?.description?.[1]?.selected_datatype || [];
          return desc;
        });
  
        // remove datatypes that aren't used by others
        currentDatatypes.forEach(datatype => {
          if (!otherFunctionsDatatypes.includes(datatype)) {
            datatypesToRemove.push(datatype);
          }
        });
  
        const updatedFunctions = selectedFunctions
          .filter(func => func.id !== selectedStepId && func.tempRef !== selectedStepId)
          .map((func, index) => ({ ...func, priority: index + 1 }));
  
        const updatedTaskValues = currentTask.task_values.filter(
          taskValue => !datatypesToRemove.includes(taskValue.datatype)
        );
  
        setSelectedFunctions(updatedFunctions);
        handleSetTask({ ...currentTask, task_values: updatedTaskValues }, 'task_values_remove', updatedTaskValues);
        handleCancel();
        return;
      }
  
      const datatypesToRemove = typeof selectedStepId === "string"
        ? parsedDescription?.[0]?.description?.[1]?.selected_datatype || []
        : parsedDescription?.description?.[1]?.selected_datatype || [];
  
      const updatedFunctions = selectedFunctions
        .filter(func => func.id !== selectedStepId && func.tempRef !== selectedStepId)
        .map((func, index) => ({ ...func, priority: index + 1 }));
  
      const updatedTaskValues = currentTask.task_values.filter(
        taskValue => !datatypesToRemove.includes(taskValue.datatype)
      );
  
      setSelectedFunctions(updatedFunctions);
      handleSetTask({ ...currentTask, task_values: updatedTaskValues }, 'task_values_remove', updatedTaskValues);
      handleCancel();
    } catch (error) {
      console.error("Error removing the function and task values:", error);
    }
  };

  const generalFunctionsList = useMemo(
    () =>
      general_fns.map(option => (
        <MenuItem
          key={option.key}
          value={option.key}
          disabled={!canAddGeneralFunction(option.key)}
          className={`${metricPlanClasses?.menuItem} ${currDarkThemeClass}`}
          focusVisibleClassName={miscClasses?.menuItemFocusDark}
        >
          {option.name}
        </MenuItem>
      )),
    [selectedFunctions, options]
  );

  const renderTextField = () => {
    if (!selectedFunctionConstant || !desc) return null;

    const textFieldProps = {
      label: desc?.label,
      multiline: desc?.type === 'text',
      className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
      value: formValues.levelOne,
      onChange: e => handleInputChange('levelOne', e.target.value),
      fullWidth: true,
      disabled: desc?.label === 'ID Level',
      variant: 'outlined',
      required: desc?.required,
      ...commonSelectProps,
    };

    // handle select type
    if (desc?.type === 'select') {
      return (
        <TextField {...textFieldProps} select>
          {desc.options?.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              className={`${metricPlanClasses?.menuItem} ${currDarkThemeClass}`}
              focusVisibleClassName={miscClasses?.menuItemFocusDark}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      );
    }

    return <TextField {...textFieldProps} />;
  };

  return (
    <>
      <Box className={`${generalFunctionClasses?.box} ${currDarkThemeClass}`}>
        <Stepper alternativeLabel connector={<ThickConnector />}>
        {datas.map((d, index) => (
          <Step key={d.id || d.tempRef}  
          sx={{ cursor: 'pointer', '&:hover': {cursor: 'pointer'} }}  
          onClick={(e) => handleStepClick(e, d)}  active={true}>
            <StepLabel
              StepIconComponent={props => (
                <CustomStepIcon 
                  icon={d.dropdown_description?.acronym} 
                  active={true} 
                />
              )}
              sx={{
                '& .MuiStepLabel-label': {
                  fontSize: '16px',
                  color: 'white',
                  cursor: 'pointer'
                }
              }}
            >
              <Typography
                variant="button"
                color="white"
                className={currDarkThemeClass}
              >
                {d.dropdown_description?.name}
              </Typography>
            </StepLabel>
          </Step>
        ))}
        
        <Step active={true}  onClick={(e) => handleStepClick(e)}  >
          <StepLabel
            StepIconComponent={props => (
              <CustomStepIcon 
                icon="+" 
                active={true} 
              />
            )}
            sx={{
              '& .MuiStepLabel-label': {
                fontSize: '16px',
                color: 'white',
                cursor: 'pointer'
              }
            }}
          >
            <Typography
              variant="button"
              color="white"
              className={currDarkThemeClass}
              sx={{ cursor: 'pointer' }}
            >
              Add General Function
            </Typography>
          </StepLabel>
        </Step>
        </Stepper>
      </Box>
      
      <Popper open={popperOpen} anchorEl={popperAnchorEl} placement="bottom" sx={{ zIndex: 1300, backgroundColor: 'black' }}>
        <Paper 
          sx={{ p: 2, width: 300, maxWidth: '100%' }} 
          className={`${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`}>
        <Box className={`${generalFunctionClasses?.popper?.closeIconBox} ${currDarkThemeClass}`}>
          <CloseIcon className={`${popupClasses?.entityDetail?.closeIcon} ${currDarkThemeClass}`} onClick={handleCancel} />
        </Box>

          <Typography className={`${generalFunctionClasses?.popper?.titleTypo} ${currDarkThemeClass}`}>
            {selectedStepId ? 'Edit General Function' : 'Add General Function'}
          </Typography>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField {...generalFunctionProps}>{generalFunctionsList}</TextField>

            {selectedFunctionConstant && desc && renderTextField()}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={selectedStepId ? () => handleUpdateGeneralFunctions(selectedStepId) : handleSave}>
                Add
              </Button>
              {selectedStepId && (
                <Button variant="contained" color="error" onClick={handleRemoveFunction}>
                  Remove
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      </Popper>
    </>
  );
};
