import { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";

import ConfirmationPopupWithLoading from "../utility/ConfirmationPopupWithLoading";
import CreatePermissionsPopup from "../plans/CreatePermissionsPopup";
import DetailAndDelBtn from "../utility/DetailAndDelBtn";
import DisplayTable from "../utility/DisplayTable";
import DownloadingMetrcDataMsg from "../utility/DownloadingMetrcDataMsg";
import ExternalCard from "../utility/cards/ExternalCard";
import InviteUserPopup from "./InviteUserPopup";
import trollmaster_logo  from "../../images/trollmaster_logo.png";
import metrc_logo from "../../images/external_metrc_logo.webp";

import { 
    contentTypeNamesMap, 
    detailOrgEndpoint, externalNewAndDetailRoute, landingPageRoute,
    maxExternalCardBoxWd,
    fadeTimingMs, 
    logMsgToConsole,
    orgClasses, errFeedbackFn, getHandleErrorWithFeedbackCX,
} from "../utility/constants";

import APIService from "../../api/apiService";
import { FeedbackContext } from "../../App";
// import getTokenDetails from "../../auth/getTokenDetails";
import useThemeVal from "../../utility/useThemeVal";
import useToken from "../../utility/useToken";
import { failsTokenAndSetupCheck } from "../../checks/setup";


const tabEntityMap = [
    {key: 'overview', label: 'Overview'},
    {key: 'users', label: 'Users'},
    {key: 'roles', label: 'Roles & Permissions'},
    // {key: 'permissions', label: 'Permissions'},
    {key: 'integrations', label: 'Integrations'},
    //client want to changes the label from external to integrations
];

const validTabs = tabEntityMap.map(e => e.key);
//console.log(validTabs);

const defaultTab = 'overview';

const keys = ['org_name', 'address'];

const orgUsersKeyTitleMap = [
    {key: 'name', title: 'Name', valFunc: (val, _) => `${val?.first_name ?? 'Unknown'} ${val?.last_name ?? ''}`},
    {key: 'email', title: 'Email', valFunc: (val, _) => val?.email},
    {key: 'role', title: 'Roles', valFunc: (val, savedRes) => savedRes?.roles?.filter(role => val?.groups?.includes(role?.group?.id))?.map(role => role?.name)?.join(', ') || 'No Role Found'}, // arg = [val, savedRes]
    {key: 'permissions', title: 'Permissions', valFunc: (val, savedRes) => savedRes?.permissions?.filter(perm => val?.groups?.includes(perm?.group?.id))?.map(perm => perm?.name)?.join(', ') || 'No Permissions Found'}, // arg = [val, savedRes]
    {key: 'role', title: 'Role', valFunc: (val, savedRes) => savedRes?.roles?.filter(role => val?.role?.includes(role?.id))?.map(role => role?.name)?.join(', ') || 'No Roles Found'}, // arg = [val, savedRes]
    {key: 'invite', title: 'Invite', valFunc: (val, _) => val?.is_active ? 'Accepted' : 'Pending'},
];

// Can show 'All' in Controls if all the models are affected
const orgRolesKeyTitleMap = [
    {key: 'name', title: 'Name'},
    {key: 'entity', title: 'Permissions'}, 
    // {key: 'entity', title: 'Permissions', value: someFunc(val)}, 
];

const defState = {
    externals: Array.from({length: 1}).map((e, i) => ({id: i + 1})),
    trolmasters: Array.from({length: 1}).map((e, i) => ({id: i + 2}))
};


export default function Organization() {

    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [detailChildren, setDetailChildren] = useState({});
    const [savedRes, setSavedRes] = useState({});
    const [values, setValues] = useState(defState);

    const feedbackCX = useContext(FeedbackContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const tab = searchParams.get('tab');

    const [tabValue, setTabValue] = useState(defaultTab);

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));
    
    
    const handleSetSavedRes = (prop, value) => setSavedRes(state => ({...state, [prop]: value}));

    const handleSetTabValue = (event, value) => {
        setTabValue(value);
        setSearchParams({tab: value});
    }

    // const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]); // Can turn off dark theme throughout the component by always returning '' from inside the useMemo 
    const currDarkThemeClass = useThemeVal(theme);

    // const {token, tokenDetails} = getTokenDetails();
    const {token, tokenDetails} = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    const {is_staff, organization} = tokenDetails;

    const handleOrgRes = res => {
        const {data} = res;
        // console.log('got organization data');console.log(data);

        setValues(state => ({
            ...state,
            ...data,
        }));
        setSavedRes(state => ({
            ...state,
            ...data,
        }));
    };

    const handleOrgModifyRes = res => {
        const {data} = res;

        setValues(state => ({
            ...state,
            ...data,
        }));
        setSavedRes(state => ({
            ...state,
            ...data,
        }));
    };

    const handlePermissionDelRes = id => () => {
        // setOpen(false);
        // setDetailChildren({});

        handleSetValues('roles', values?.roles?.filter(role => +role.id !== +id));
        handleSetSavedRes('roles', savedRes?.roles?.filter(role => +role.id !== +id));
        // handleSetValues('permissions', values?.permissions?.filter(perm => +perm.id !== +id));
        // handleSetSavedRes('permissions', savedRes?.permissions?.filter(perm => +perm.id !== +id));

        return Promise.resolve({success: true});
    }

    const handleUserEntryDelRes = id => () => {
        handleSetValues('users', values?.users?.filter(user => +user.id !== +id));
        handleSetSavedRes('users', savedRes?.users?.filter(user => +user.id !== +id));

        return Promise.resolve({success: true});
    }

    const handleError = err => {
        console.log(err);

        // setValues(state => ({
        //     ...state,
        //     ...dummyData,
        // }));
        // setSavedRes(state => ({
        //     ...state,
        //     ...dummyData,
        // }));

    };

    const handleErrorWithPromise = err => {
        console.log(err);

        return Promise.reject({success: false});
    }

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    const handleFinally = () => {
        handleSetValues('loading', false);
        // handleSetValues('processing', false);
        handleSetValues('saving', false);
    };

    useEffect(() => {
        let tabToSet = '';

        if (!tab || !validTabs.includes(tab)) {
            tabToSet = defaultTab;
        // } else if (validTabs.includes(tab)) {
        } else {
            tabToSet = tab;
        }

        setTabValue(tabToSet);
        setSearchParams({tab: tabToSet});

        // 22-06-2023: Approach #2:  This approach for maintaining consistent popup state requires any open popup to be closed whenever a tab changes since popups are always tab specific
        if ( open ) handlePopupClose();

    }, [tab]);

    useEffect(() => {
        // if (!token || !is_staff) return;
        if (invalidTokenState || !is_staff) return;

        handleSetValues('loading', true);

        const errConfig = {};

        // const {organization} = tokenDetails;

        // const entityWithID = `organization/${organization}/`;
        const entityWithID = detailOrgEndpoint(organization);

        APIService.fetchInstance(entityWithID)
        .then(handleOrgRes)
        // .catch(handleError)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

    }, []);
    

    useEffect(() => {
        if (token && !is_staff) {
            navigate(landingPageRoute);
        }
    }, [token]);

    if ( invalidTokenState || !is_staff ) return <></>;

    const orgBoxProps = {
        // className: 'main-content-wrapper customScroll',
        className: `${orgClasses?.box} ${currDarkThemeClass} customScroll`,
        // sx: {
        //     backgroundColor: 'primary.100',
        //     flexGrow: 1,
        //     minHeight: '100vh',
        //     pl: '32px',
        //     pr: '32px',
        //     pt: '60px',
        // },
    };

    const orgNavTitleBoxProps = {
        className: `${orgClasses?.navTitleBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     minHeight: '54px',
        // },
    };

    const orgTitleBoxProps = {
        className: `${orgClasses?.titleBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        // },
    };

    const orgTitleTypoProps = {
        className: `${orgClasses?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'grey.200',
        //     color: 'grey.600',
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     px: '12px',
        //     py: '4px',
        //     textTransform: 'uppercase',
        // },
    };

    const orgNameTypoProps = {
        className: `${orgClasses?.nameTypo} ${currDarkThemeClass}`,
        // sx: {
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     ml: '12px',
        // },
    };

    const orgContentBoxProps = {
        className: `${orgClasses?.contentBox} ${currDarkThemeClass}`,
    };

    const orgTabsProps = {
        // className: 'roomDetailTabsContent',
        className: `${orgClasses?.tabs} ${currDarkThemeClass} roomDetailTabsContent`,
        value: tabValue ?? defaultTab,
        onChange: handleSetTabValue,
        // sx: {
        //     mt: '40px',
        // },
        TabIndicatorProps: {
            sx: {
                backgroundColor: !currDarkThemeClass ? 'grey.700' : 'grey.200',
            },
        },
    };

    const orgTabProps = {
        className: `${orgClasses?.tab} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.500',
        //     fontSize: '16px',
        //     lineHeight: 1.56,
        //     textTransform: 'none', 
        //     '&.Mui-selected': {
        //         color: 'grey.700',
        //         fontWeight: 500,
        //     },
        // }, 
    };

    const tabList = tabEntityMap.map((e, i) => 
        <Tab key={i} {...orgTabProps} label={e.label} value={e.key} />
    );

    const orgDividerProps = {
        className: `${orgClasses?.divider} ${currDarkThemeClass}`,
        // sx:{
        //     backgroundColor: 'grey.900'
        // },
    };

    const toDisable = () => keys.every(key => values?.[key] === savedRes?.[key]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // if (toDisable() || +tokenDetails?.organization !== +values?.id) return;
        if (toDisable()) return;

        handleSetValues('saving', true);

        const errConfig = {};

        // const entityWithID = `organization/${values?.id}/`;
        const entityWithID = detailOrgEndpoint(values?.id);
        
        const payload = {
            org_name: values?.org_name,
            address: values?.address,
        }

        APIService.modifyInstance(entityWithID, payload)
        .then(handleOrgModifyRes)
        // .catch(handleError)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally);

        // const timerId = setTimeout(() => {
        //     clearTimeout(timerId);
        //     handleSetValues('saving', false);
        // }, 5000);
    };

    const orgFormBoxProps = {
        className: `${orgClasses?.formBox} ${currDarkThemeClass}`,
        component: 'form',
        onSubmit: handleSubmit,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     mt: '24px', // self assumed
        // },
    };

    const orgInputSectionBoxProps = {
        className: `${orgClasses?.inputSectionBox} ${currDarkThemeClass}`,
        // sx: {
        //     maxWidth: `${detailTextMaxWd}px`,
        //     mb: '32px',
        // },
    };

    const orgTextFieldProps = ({prop, inputProps={}, otherTextProps={}, otherSxProps={}}) => ({
        autoComplete: 'off',
        className: `${orgClasses?.textField} ${currDarkThemeClass}`,
        disabled: values?.loading || values?.saving,
        fullWidth: true,
        inputProps,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        //     ...inputProps,
        // },
        // InputLabelProps: {
        //     // ...(prop === 'description' && {
        //     //     shrink: true,
        //     // }),
        //     sx: {
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        // InputProps: {
        //     sx: {
        //         borderRadius: 0,
        //         '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
        //             borderColor: 'grey.800',
        //         },
        //     },
        // },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        required: true,
        // sx: {
        //     color: 'grey.800',
        //     mt: '20px',
        //     ...otherSxProps,
        // },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const orgNameProps = {
        inputProps: {},
        otherSxProps: {},
        otherTextProps: {
            label: 'Name',
        },
        prop: 'org_name',
    };

    const orgAddressProps = {
        inputProps: {},
        otherSxProps: {},
        otherTextProps: {
            label: 'Address',
        },
        prop: 'address',
    };

    const orgProgressProps = {
        size: 16,
        style: {
            color: theme.palette.grey['400'],
        },
    };

    const saveBtnProps = {
        children: 'Save Details',
        className: `${orgClasses?.saveBtn} ${currDarkThemeClass}`,
        disabled: toDisable() || values?.saving,
        onClick: undefined,
        startIcon: values?.saving && <CircularProgress {...orgProgressProps} />,
        // sx: {
        //     backgroundColor: 'primary.700',
        //     borderRadius: 0,
        //     color: 'primary.100',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05em',
        //     lineHeight: 1.25,
        //     ml: 'auto',
        //     py: '16px',
        //     textTransform: 'none',
        //     width: '244px',
        //     '&:hover': {
        //         backgroundColor: 'primary.700',
        //     },
        // },
        type: 'submit',
        variant: 'contained',
    };

    const overviewTab = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...orgFormBoxProps}>
                <Box {...orgInputSectionBoxProps}>
                    <TextField {...orgTextFieldProps(orgNameProps)} />
                    <TextField {...orgTextFieldProps(orgAddressProps)} />
                </Box>
                <Button {...saveBtnProps} />
            </Box>
        </Fade>
    );

    const orgTableBoxProps = {
        className: `${orgClasses?.tableBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     flexWrap: 'wrap',
        //     maxWidth: `${farmsTableMaxWd}px`,
        //     mt: '56px',
        // },
    };

    const handleUserEntryDel = id => setLoading => async () => {
        setLoading(true);

        const errConfig = {
            400: 'Invalid request. User could not be deleted',
        };

        const entityWithID = `invite/${id}/`;

        // APIService.deleteInstance(entityWithID)
        // .then(handleUserEntryDelRes(id))
        // .catch(handleError)
        // .finally(() => setLoading(false))

        let res = {success: false};

        try {
            res = await APIService.deleteInstance(entityWithID)
                                  .then(handleUserEntryDelRes(id));
        } catch (err) {
            // handleError(err);
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }

        return res;
    }

    const handleUserEntryDelView = user => () => {
        handleSetValues('invite', false);
        // handleSetValues('showRole', false);
        handleSetValues('showPerm', false);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);

        setOpen(true);

        setDetailChildren({
            title: `Deleting ${user?.first_name ?? 'User'} ${user?.last_name ?? ''}`,

            onProceed: handleUserEntryDel(user.id),
        });

    }

    const handleSaveModifiedInviteData = id => data => {
        handleSetValues('users', values.users.map(user => +user.id === +id ? data : user));
        handleSetSavedRes('users', savedRes.users.map(user => +user.id === +id ? data : user));
        console.log("data saved")
    };
    // const handleSave = id => data => {
    //     handleSetValues('users', values.users.map(user => +user.id === +id ? data : user));
    //     handleSetSavedRes('users', savedRes.users.map(user => +user.id === +id ? data : user));
    //     console.log("data saved")
    // };

    const handleUserEntryDetailView = user => () => {
        handleSetValues('invite', true);
        // handleSetValues('showRole', false);
        handleSetValues('showPerm', false);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);

        setOpen(true);

        setDetailChildren({
            title: `Viewing: ${user?.first_name ?? 'User'} ${user?.last_name ?? ''}`,
            
            edit: true,
            permissions: savedRes?.permissions,
            roles: savedRes?.roles,
            user,

            handleSaveModifiedInvite: handleSaveModifiedInviteData(user.id),
            // savedUser: Object.assign({}, user),
        });
    };

    const orgUserEntryActionBtnProps = user => ({
        onDelClick: handleUserEntryDelView(user),
        onDetailClick: handleUserEntryDetailView(user),
        currDarkThemeClass,
    });

    const usersEntries = [];

    const usersTableTitles = orgUsersKeyTitleMap.map(e => ({title: e.title}))

    savedRes?.users?.forEach((val, idx) => {
        usersEntries.push([
            `${idx+1}`.padStart(2,0),
            ...orgUsersKeyTitleMap.map(({valFunc}) => valFunc(val, savedRes)),
            <DetailAndDelBtn {...orgUserEntryActionBtnProps(val)} />,
        ]);
    });

    const handleAddInvitedUserData = user => {
        handleSetValues('users', [...(values?.users ?? []), user]);
        handleSetSavedRes('users', [...(savedRes?.users ?? []), user]);
    };

    const handleOpenInvitePopup = () => {
        handleSetValues('invite', true);
        // handleSetValues('showRole', false);
        handleSetValues('showPerm', false);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);

        setOpen(true);

        setDetailChildren({
            handleAddInvitedUser: handleAddInvitedUserData,
            permissions: savedRes?.permissions,
            roles: savedRes?.roles,
        });
    };

    const usersTableValues = {
        entity: 'User',
        // onClick: handleOpenInvitePopup,
        tableEntries: [
            ...usersEntries,
        ],
        tableTitles: [
            {title: 'Sl. No'},
            ...usersTableTitles,
            {title: ''},
        ],
        toShowAddBtn: false,
        animationClass: 'farmsTabContent',
        currDarkThemeClass,
    };

    const usersTab = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...orgTableBoxProps}>
                <DisplayTable {...usersTableValues} />
            </Box>
        </Fade>
    );

    const permissionsTableBoxProps = {
        className: `${orgClasses?.permTableBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     flexWrap: 'wrap',
        //     maxWidth: `${farmsTableMaxWd}px`,
        //     // mt: '40px',
        //     mt: '56px',
        // },
    };

    // const orgProgressBarProps = {
    //     size: 16,
    //     style: {
    //         color: theme.palette.grey['500'],
    //     },
    // };
    
    const handleDelPermissionEntry = id => setLoading => async () => {

        // handleSetValues('processing', true);
        setLoading(true);

        const errConfig = {
            400: 'Invalid request. Permission could not be deleted',
        };

        const entityWithID = `role/${id}/`;

        // APIService.deleteInstance(entityWithID)
        // .then(handlePermissionDelRes(id))
        // .catch(handleError)
        // .finally(() => setLoading(false));
        
        let res = {success: false};

        try {
            res = await APIService.deleteInstance(entityWithID)
                                  .then(handlePermissionDelRes(id));
        } catch (err) {
            // handleError(err);
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }

        return res;
    };

    const handlePermissionEntryDelView = perm => () => {
        // handleSetValues('showRole', false);
        handleSetValues('showPerm', false);
        // handleSetValues('deleteRole', true);
        handleSetValues('deletePerm', true);

        setOpen(true);

        setDetailChildren({
            message: 'This will take away permissions from existing user(s). Do you wish to continue?',
            title: `Deleting ${perm?.name}`,

            onProceed: handleDelPermissionEntry(perm.id),
        });
    };

    const handleSaveModifiedPermissionData = id => value => {
        // handleSetValues('roles', values?.roles?.map(role => +role.id === +id ? value : role ));
        // handleSetSavedRes('roles', savedRes?.roles?.map(role => +role.id === +id ? value : role ));
        handleSetValues('roles', values?.roles?.map(role => +role.id === +id ? value : role ));
        handleSetSavedRes('roles', savedRes?.roles?.map(role => +role.id === +id ? value : role ));
    };

    const handlePermissionsEntryDetailView = perm => () => {
        // handleSetValues('showRole', true);
        handleSetValues('showPerm', true);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);

        setOpen(true);

        setDetailChildren({
            edit: true,
            handleSaveModifiedPerm: handleSaveModifiedPermissionData(perm.id),
            perm,
            savedPerm: Object.assign({}, perm),
        });
    };

    const orgPermissionsEntryActionBtnProps = permission => ({
        onDelClick: handlePermissionEntryDelView(permission),
        onDetailClick: handlePermissionsEntryDetailView(permission),
        currDarkThemeClass,
    });

    const permissionsEntries = [];

    const permissionsTableTitles = orgRolesKeyTitleMap.map(e => ({title: e.title}));

    savedRes?.roles?.forEach((val, idx) => {

        // What happens if model's name is farm_test_sensor; So use regex
        // let entityValues = val?.group?.permissions?.map(e => e?.codename?.split('_')?.[1]);
        let entityValues = val?.group?.permissions?.map(e => {
            const sepIndex = e?.codename?.indexOf('_');
            const contentType = e?.codename?.substring(sepIndex + 1);

            return contentTypeNamesMap?.[contentType];
        });
        
        if (logMsgToConsole?.organization) {
            console.log('entityValues');
            console.log(entityValues);
        }

        // entityValues = entityValues.reduce((res, val) => res.includes(val) ? res : [...res, val], []).filter(e => e !== 'metrcroom' && e !== 'metrcid'); // 10012023: What model name will show up for grow_dashboard?
        entityValues = new Set(entityValues);
        
        // entityValues.delete('metrcroom');
        // entityValues.delete('metrcid');
        
        entityValues.delete(undefined); // If there's no entry in contentTypeNamesMap, it will return undefined
        entityValues.delete(null); // Just to be safe; Don't expect value to be null


        entityValues = [...entityValues];

        permissionsEntries.push([
            `${idx+1}`.padStart(2,0),
            // ...orgRolesKeyTitleMap.map(({key}) => key !== 'entity' ? val?.group?.[key] : entityValues.join(', ')),
            ...orgRolesKeyTitleMap.map(({key}) => key !== 'entity' ? val?.[key] : entityValues.join(', ')),
            <DetailAndDelBtn {...orgPermissionsEntryActionBtnProps(val)} />,
        ]);
    });

    const handleAddPermissionsData = (value) => {
        // handleSetValues('roles', [...(values?.roles ?? []), value])
        // handleSetSavedRes('roles', [...(savedRes?.roles ?? []), value])
        handleSetValues('roles', [...(values?.roles ?? []), value])
        handleSetSavedRes('roles', [...(savedRes?.roles ?? []), value])
    };

    const handleOpenPermissionPopup = () => {
        // handleSetValues('showRole', true);
        handleSetValues('showPerm', true);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);

        setOpen(true);

        setDetailChildren({
            handleAddPerm: handleAddPermissionsData,
        });
    };

    const permissionsTableValues = {
        entity: 'Role',
        // onClick: handleOpenRolePopup,
        // entity: 'Permissions',
        tableEntries: [
            ...permissionsEntries,
        ],
        tableTitles: [
            {title: 'Sl. No'},
            ...permissionsTableTitles,
            {title: ''},
        ],
        toShowAddBtn: false,
        animationClass: 'farmsTabContent',
        currDarkThemeClass,
    };

    const rolesTab = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...permissionsTableBoxProps}>
                <DisplayTable {...permissionsTableValues} />
            </Box>
        </Fade>
    );

    const externalsTableBoxProps = {
        // className: 'padding-bottom-60',
        className: `${orgClasses?.externalsTableBox} ${currDarkThemeClass}`,
        sx: {
            // mt: '56px',
            // display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, ${maxExternalCardBoxWd}px)`,
            // gap: '16px',
        },
    };

    const externalCardProps = integration => ({
        ...integration,
        logoSrc: metrc_logo,
        onClick: () => navigate(`${externalNewAndDetailRoute}/${integration?.id}/?tab=overview`),
        otherECBoxSxProps: {
            // height: '100%',
            m: 0,
            width: '100%',
            // width: `${maxExternalCardBoxWd}px`,
        },
        currDarkThemeClass,
    });


    const trolMasterCardProps = integration => ({
        ...integration,
        logoSrc: trollmaster_logo,
        onClick: () => navigate(`${externalNewAndDetailRoute}/${integration?.id}/?tab=overview`),
        otherECBoxSxProps: {
            // height: '100%',
            m: 0,
            width: '100%',
            // width: `${maxExternalCardBoxWd}px`,
        },
        currDarkThemeClass,
    });
    const externalsCardList = values?.externals?.map((e, i) => 
    <ExternalCard key={i} {...externalCardProps(e)} />
    )
    const TrollMasterCardList = values?.trolmasters?.map((e, i) => 
     <ExternalCard key={i} {...trolMasterCardProps(e)} />);

    const externalsTab = (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...externalsTableBoxProps}>
                {externalsCardList}
                {TrollMasterCardList}
            </Box>
        </Fade>
    );
    // const rolesTab = (
    //     <Fade in={true} timeout={fadeTimingMs}>
    //         <Box {...rolesTableBoxProps}>
    //             <DisplayTable {...rolesTableValues} />
    //         </Box>
    //     </Fade>
    // );

    const handlePopupClose = () => {
        // handleSetValues('showRole', false);
        handleSetValues('showPerm', false);
        // handleSetValues('deleteRole', false);
        handleSetValues('deletePerm', false);
        
        setOpen(false);
        setDetailChildren({});
    };

    const orgPopupProps = {
        currDarkThemeClass,
        open,
        
        onClose: handlePopupClose,

        ...detailChildren,
    };

    const orgAddBtnProps = {
        // children: tabValue === 'users' ? 'Invite User' : 'Add Role',
        children: tabValue === 'users' ? 'Invite User' : 'Add Roles',
        className: `${orgClasses?.addBtn} ${currDarkThemeClass}`,
        disabled: tabValue === 'overview',
        onClick: tabValue === 'users' ? handleOpenInvitePopup : handleOpenPermissionPopup,
        // sx: {
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     color: 'grey.200',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05rem',
        //     lineHeight: 1.45,
        //     p: '16px',
        //     textTransform: 'none',
        //     width: '244px',
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
        variant: 'contained',
    };

    const orgDownloadMsgProps = {
        currDarkThemeClass,
    };

    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...orgBoxProps}>
                <Box {...orgNavTitleBoxProps}>

                    <Box {...orgTitleBoxProps}>
                        <Typography {...orgTitleTypoProps}>ORGANIZATION</Typography>
                        <Typography {...orgNameTypoProps}>{savedRes?.org_name ?? 'Unknown'}</Typography>
                    </Box>

                    {
                        tabValue !== 'overview' && tabValue !== 'integrations' &&
                        <Fade in={true} timeout={fadeTimingMs}>
                            <Button {...orgAddBtnProps} />
                        </Fade>
                    }
                </Box>

                <Box {...orgContentBoxProps}>
                    <Tabs {...orgTabsProps}>
                        {tabList}
                    </Tabs>

                    <Divider {...orgDividerProps}/>
                    {
                        values?.loading ? <DownloadingMetrcDataMsg {...orgDownloadMsgProps} />
                        : tabValue === 'overview' ? overviewTab
                        : tabValue === 'users' ? usersTab
                        : tabValue === 'roles' ? rolesTab
                        : tabValue === 'integrations' ? externalsTab
                        : <></>
                    }
                </Box>
                {/* {
                    values?.showRole ? <CreateRolePopup {...orgPopupProps} />
                    : values?.deleteRole ? <ConfirmationPopupWithLoading {...orgPopupProps} />
                    : values?.invite ? <InviteUserPopup {...orgPopupProps} />
                        : <ConfirmationPopupWithLoading {...orgPopupProps} />
                } */}
                {
                    values?.showPerm ? <CreatePermissionsPopup {...orgPopupProps} />
                    : values?.deletePerm ? <ConfirmationPopupWithLoading {...orgPopupProps} />
                    : values?.invite ? <InviteUserPopup {...orgPopupProps} />
                        : <ConfirmationPopupWithLoading {...orgPopupProps} />
                }
            </Box>
        </Fade>
    );
}