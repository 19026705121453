import { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SetupOutlinePage from "./SetupOutline";
import APIService from '../../api/apiService';
import useThemeVal from "../../utility/useThemeVal";
import { loginPageClasses, signUpPageClasses } from "../utility/constants";
import { FeedbackContext } from "../../App";
const resetPasswordInfo = "Reset Your Password";
const actionTitle = 'Please enter your new password and confirm.';

const instructions = [
  'At least 12 characters.',
  'A mixture of both uppercase and lowercase letters.',
  'A mixture of letters and numbers.',
  'Inclusion of at least one special character. e.g: ! @ # ? ]',
];
const PasswordResetConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const { token } = useParams(); // Use the useParams hook to get the token from the URL
  const navigate = useNavigate();
  const currDarkThemeClass = useThemeVal();

  const handleSetValues = (prop, value) => setValues((state) => ({ ...state, [prop]: value }));

  const handleFinally = () => {
    setLoading(false);
  };
  const sPInstructionTypoProps = {
    component: 'li',
    className: `${signUpPageClasses?.instructionTypo} ${currDarkThemeClass}`,
   
};
const feedbackCX = useContext(FeedbackContext);
const otherPasswordProps = {
  autoFocus: true,
  inputProps: {
      minLength: 12,
  },
  label: 'New Password',
  type:  'password',
};

const otherConfirmPasswordProps = {
  inputProps: {
      minLength: 12,
  },
  label: 'Confirm Password',
  type:  'password',
  sx: { 
      mt: '20px',
  },
};

  const instructionsList = instructions.map((e, i) =>
        <Typography key={i} {...sPInstructionTypoProps}>{e}</Typography>
    );

  const handleSuccess = () => {
    feedbackCX.setContextValue(true, {
      type: 'success',
      message: 'Password reset successfully!',
    });
    navigate("/login"); // Redirect to the login page after a successful password reset
  
  };

  const handleError = (err) => {
    const helperText =
      err?.response?.status === 0
        ? 'The server could not be reached'
        : 'An error occurred while resetting the password. Please try again.';

    handleSetValues('error', true);
    handleSetValues('helperText', helperText);
  };
  const passwordRegexList = [
    { regex: /[0-9]+/, helperText: 'Please add a number' },
    { regex: /[a-z]+/, helperText: 'Please add a lowercase character' },
    { regex: /[A-Z]+/, helperText: 'Please add an uppercase character' },
    { regex: /\W+|_/, helperText: 'Please add a special character' },
  ];
  const handleResetPassword = (event) => {
    event.preventDefault();
    setLoading(true);

    const newPassword = values?.newPassword;

    if (newPassword === values?.confirmPassword) {
      // Additional password validation using regex
      for (let item of passwordRegexList) {
        if (!item.regex.test(newPassword)) {
          handleSetValues('error', true);
          handleSetValues('helperText', item.helperText);
          setLoading(false);
          return;
        }
      }
      const entity = "accounts/forgot_password/";
      const payload = {
        token: token,
        password: newPassword,
      };

      APIService.patchInstance(entity, payload)
        .then(handleSuccess)
        .catch(handleError)
        .finally(handleFinally);
    } else {
      // Display an error if passwords do not match
      handleSetValues('error', true);
      handleSetValues('helperText', 'Passwords do not match. Please try again.');
      setLoading(false);
    }
  };

  const sPOutlineProps = {
    currDarkThemeClass,
  };

  const sPBoxProps = {
    className: `${loginPageClasses?.box} ${currDarkThemeClass}`,
  };

  const sPInfoTypoProps = {
    className: `${loginPageClasses?.infoTypo} ${currDarkThemeClass}`,
  };

  const sPActionTypoProps = {
    className: `${loginPageClasses?.actionTypo} ${currDarkThemeClass}`,
  };

  const sPFormBoxProps = {
    component: 'form',
    onSubmit: handleResetPassword,
  };

  const handleSetTextField = (prop, value) => {
    if (values?.error) {
      handleSetValues('error', false);
    }
    handleSetValues(prop, value);
  };

  const sPTextFieldProps = (prop, otherProps) => ({
    autoComplete: 'off',
    disabled: loading,
    error: values?.error,
    fullWidth: true,
    helperText: values?.error && values?.helperText,
    InputProps: {
      sx: {
        ...(prop === 'confirmPassword' && {
          '&.MuiOutlinedInput-root.MuiInputBase-root': {
            pr: '15px',
          },
        }),
      },
    },
    label: ' ',
    onChange: ({ target: { value } }) => handleSetTextField(prop, value),
    required: true,
    className: `${loginPageClasses?.textField} ${currDarkThemeClass}`,
    value: values[prop] ?? '',
    ...otherProps,
  });

  const lPProgressProps = {
    color: 'warning',
    size: 20,
  };

  const sPProceedBtnProps = (message = 'Reset Password', otherArgs = {}) => ({
    children: message,
    disabled: loading,
    onClick: undefined,
    startIcon: loading && <CircularProgress {...lPProgressProps} />,
    className: `${loginPageClasses?.proceedBtn} ${currDarkThemeClass}`,
    ...otherArgs,
  });

  return (
    <SetupOutlinePage {...sPOutlineProps}>
      <Box {...sPBoxProps}>
        <Typography {...sPInfoTypoProps}>{resetPasswordInfo}</Typography>
        <Typography {...sPActionTypoProps}>{actionTitle}</Typography>
        {instructionsList}
        <Box {...sPFormBoxProps}>
          <TextField {...sPTextFieldProps('newPassword', otherPasswordProps)} />
          <TextField {...sPTextFieldProps('confirmPassword', otherConfirmPasswordProps)} />
          <Button {...sPProceedBtnProps('Reset Password', { type: 'submit' })} />
        </Box>
      </Box>
    </SetupOutlinePage>
  );
};
       
export default PasswordResetConfirmation;
