import SvgIcon from '@mui/material/SvgIcon';

export const SmartGrowDashIcon = props => (
  <SvgIcon {...props} viewBox="0 0 127 124" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.11377" width="125.919" height="123.325" rx="61.6623" fill="#252720" />
    <path
      d="M35.9903 96.297H33.9396C33.4372 95.3145 23.7797 74.8182 33.7132 62.7263C31.3341 73.7694 33.5145 85.4031 35.9903 96.297ZM26.9897 74.9672C22.3611 80.9566 23.6638 89.8192 28.4443 96.297H30.357C27.1967 87.8595 25.9381 82.6319 26.9869 74.9672H26.9897ZM76.5909 39.6549C64.9324 52.354 57.4719 71.9063 56.2106 96.297H58.1564C61.9957 67.1342 80.1321 44.7886 102.033 27C100.827 27.1739 98.9999 27.3781 96.9878 27.6127C90.2394 28.4049 83.9989 31.59 79.4034 36.5912L76.5909 39.6522V39.6549ZM69.1332 35.007C65.3685 36.7154 62.2082 39.5528 60.1271 43.1243C58.8768 45.2717 57.7948 47.5818 56.8426 50.0162C52.7881 60.3968 50.5745 71.2218 50.4117 82.2621C50.3426 86.9349 50.4034 91.616 50.6628 96.2915H52.5921C53.445 88.4005 54.1267 80.2169 55.5481 72.4307C58.0819 58.5641 64.0464 46.1383 73.8529 35.7218C75.1943 34.2976 76.6351 32.9673 78.1448 31.6231C76.9828 31.9902 75.8043 32.3821 74.6147 32.8017C72.7765 33.4475 70.9466 34.19 69.1332 35.0125V35.007ZM43.1858 76.7861C43.1803 83.253 44.0469 89.1843 45.0654 96.2777L47.0223 96.3246C47.2817 88.3812 47.3839 80.5453 48.0297 72.7537C48.9295 61.9066 50.8422 51.319 57.0331 41.9347C55.6365 42.8538 54.273 43.8005 52.9454 44.761C48.7473 47.8026 45.91 52.365 44.955 57.4574C43.9586 62.7622 43.1941 69.3891 43.1886 76.7861H43.1858ZM36.4595 67.2363C36.0179 77.0124 36.9701 85.3009 39.5121 96.2943H41.469C40.2656 81.9281 38.7531 67.5316 43.0561 53.0523C43.034 53.0716 43.0119 53.0937 42.9898 53.113C39.0181 56.7563 36.7024 61.8514 36.4595 67.2363Z"
      fill="#B1D23A"
    />
  </SvgIcon>
);
