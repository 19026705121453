import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';

import { MappingIcon, RoomCardIcon } from "../utility/CustomSvgIcons";
import { cardClasses } from "../utility/constants";

// import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
// import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

export default function RoomCard(props) {
    const {
        attached_room_id=[],
        room_name='Unknown',
        farm,
        id,
        onClick=() => console.log('room detail redirect'),
        currDarkThemeClass='',
    } = props;

    const navigate = useNavigate();

    const roomCardPaperProps = {
        // className: 'farmsTabContent',
        className: `${cardClasses?.room?.paper} ${currDarkThemeClass} farmsTabContent`,
        onClick: () => farm ? navigate(`/farms/farm/${farm}/room/${id}?tab=overview`) : onClick,
        square: true,
        // sx: {
        //     borderBottom: '1px solid',
        //     borderColor: 'primary.800',
        //     flexShrink: 0,
        //     px: '28px',
        //     py: '36px',
        //     '&:hover': {
        //         cursor: 'pointer',
        //     },
        // },
        variant: 'none',    
    };

    const roomCardIconProps = {
        className: `${cardClasses?.room?.icon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '48px', 
        //     width: '48px',
        //     '& path': {
        //         fill: 'none',
        //         stroke: 'primary.800',
        //     },
        // },
    };
    
    const roomCardNameTypoProps = {
        className: `${cardClasses?.room?.nameTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     mt: '32px',
        //     width: '100%',
        // },
    };

    const roomCardMappingTypoProps = {
        className: `${cardClasses?.room?.mappingTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     mt: '8px',
        // },
    };

    const roomCardMappingIconProps = {
        className: `${cardClasses?.room?.mappingIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '24px',
        //     mr: '4px',
        //     width: '24px',
        // },
    };

    return (
        <Paper {...roomCardPaperProps}>
            <RoomCardIcon {...roomCardIconProps}/>
            <Typography {...roomCardNameTypoProps}>{room_name}</Typography>
            <Typography {...roomCardMappingTypoProps}>
                <MappingIcon {...roomCardMappingIconProps} />{attached_room_id?.length > 0 ? `Mapped to ${attached_room_id.map(e => e.location_name).join(', ')}` : 'No Mapping Found'}
            </Typography>
        </Paper>
    );
}