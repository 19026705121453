import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { tableClasses } from './constants';

const defState = {
  page: 0,
  rowsPerPage: 15,
};

export default function DisplayTable(props) {
  const {
    entity,
    emptyTableMsg = undefined,
    onClick = () => console.log('Create entity'),
    tableEntries = [],
    tableTitles = [],
    toShowAddBtn = true,
    otherEntryCellSxProps,
    animationClass = '',
    currDarkThemeClass = '', // will change the table's theme only when passed from parent
    enablePagination = true,
    rowsPerPageOptions = [10, 15, 30, 100],
    rowsPerPage = 15,
    ...otherProps
  } = props;

  const [values, setValues] = useState({ ...defState, rowsPerPage });
  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

  const handleChangePage = useCallback((event, newPage) => handleSetValues('page', +newPage), [values?.page]);

  const handleChangeRowsPerPage = useCallback(
    ({ target: { value } }) => {
      handleSetValues('page', 0);
      handleSetValues('rowsPerPage', +value);
    },
    [values?.rowsPerPage]
  );

  const titlesLen = tableTitles.length;

  const displayTableBaseProps = {
    ...otherProps.baseProps,
  };

  const displayTableContainerProps = {
    className: `${tableClasses?.displayTable?.tableContainer} ${currDarkThemeClass} ${animationClass}`,
    component: Paper,
    variant: 'none',
    ...otherProps?.tableContainerProps,
  };

  const displayTableProps = {
    className: `${tableClasses?.displayTable?.table} ${currDarkThemeClass}`,
    stickyHeader: true,
  };

  const displayTableRowProps = {
    className: `${tableClasses?.displayTable?.tableRow} ${currDarkThemeClass}`,
    ...otherProps?.tableRowProps,
  };

  const displayTableTitleCellProps = i => ({
    className: `${tableClasses?.displayTable?.titleCell} ${currDarkThemeClass}`,
    sx: {
      pl: i === 0 || i === titlesLen - 1 ? '39px' : '20px',
      pr: i === titlesLen - 1 ? '39px' : 'auto',
    },
    variant: 'head',
    ...otherProps?.tableTitleProps,
    // Can add logic for setting column width here
  });

  const displayTableEntryCellProps = idx => ({
    className: `${tableClasses?.displayTable?.entryCell} ${currDarkThemeClass}`,
    ...(idx === 0 && {
      component: 'th',
      scope: 'row',
    }),
    sx: {
      pl: idx === 0 || idx === titlesLen - 1 ? '39px' : '20px',
      pr: idx === titlesLen - 1 ? '39px' : 'auto',
      ...otherEntryCellSxProps?.(idx),
    },
    variant: 'body',
    ...otherProps?.tableEntryCellProps,
  });

  const tableTitlesList = tableTitles.map((e, i) => (
    <TableCell key={i} {...displayTableTitleCellProps(i)}>
      {e.title}
    </TableCell>
  ));

  const tableEntriesList = tableEntries
    .slice(values?.page * values?.rowsPerPage, values?.page * values?.rowsPerPage + values?.rowsPerPage)
    .map((e, i) => (
      <TableRow key={`row-${i}`} {...displayTableRowProps}>
        {e.map((el, idx) => (
          <TableCell key={`cell-${idx}`} {...displayTableEntryCellProps(idx)}>
            {el ?? ''}
          </TableCell>
        ))}
      </TableRow>
    ));

  const noEntityProps = {
    className: `${tableClasses?.displayTable?.noEntityTableCell} ${currDarkThemeClass}`,
    colSpan: '100%',
  };

  const displayTableFooterPaperProps = {
    className: `${tableClasses?.displayTable?.tableFooterPaper} ${currDarkThemeClass} ${animationClass}`,
    square: true,
    variant: 'none',
  };

  const displayTableAddBtnProps = {
    className: `${tableClasses?.displayTable?.addBtn} ${currDarkThemeClass}`,
    onClick,
  };

  const displayTablePaginationProps = {
    className: `${tableClasses?.displayTable?.pagination} ${currDarkThemeClass}`,
    component: 'div',
    count: tableEntries.length,
    page: values?.page,
    onPageChange: handleChangePage,
    rowsPerPage: values?.rowsPerPage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    // sx: { color: theme?.palette?.grey?.['400'] }
  };

  return (
    <Box {...displayTableBaseProps}>
      <TableContainer {...displayTableContainerProps}>
        <Table {...displayTableProps}>
          <TableHead>
            <TableRow>{tableTitlesList}</TableRow>
          </TableHead>

          <TableBody>
            {tableEntriesList.length === 0 ? (
              <TableRow>
                <TableCell {...noEntityProps}>{emptyTableMsg ?? `No ${entity} Added`}</TableCell>
              </TableRow>
            ) : (
              tableEntriesList
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {enablePagination && <TablePagination {...displayTablePaginationProps} />}
      {toShowAddBtn && (
        <Paper {...displayTableFooterPaperProps}>
          <Button {...displayTableAddBtnProps}>Add {entity}</Button>
        </Paper>
      )}
    </Box>
  );
}
