import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// import { useTheme } from "@mui/material";

import { DownArrowIcon, RoomCardIcon } from "../CustomSvgIcons";
import { cardClasses, transitionDuration } from "../constants";


export default function DragAccordionCard(props) {
    const {
        content,
        currDarkThemeClass='',
        id,
        title='',

        onClick=() => {},
        onDragStart,        
    } = props;

    // 22-05-2023: TODO: Collapse when dragging

    // const theme = useTheme();

    const [expand, setExpand] = useState(false);
        
    const accordionProps = {
        className: `${cardClasses?.dragAccordion?.accordion} ${currDarkThemeClass}`,
        draggable: true,
        elevation: 0,
        expanded: expand,
        id,
        square: true,

        onClick,
        onDragStart,
    };

    const accordionSummaryProps = {
        className: `${cardClasses?.dragAccordion?.accordionSummary} ${currDarkThemeClass}`,
    };

    const boxProps = {
        className: `${cardClasses?.dragAccordion?.box} ${currDarkThemeClass}`,
    };
    
    const titleBoxProps = {
        className: `${cardClasses?.dragAccordion?.titleBox} ${currDarkThemeClass}`,
    };

    const iconProps = {
        className: `${cardClasses?.dragAccordion?.icon} ${currDarkThemeClass}`,
    };

    const titleTypoProps = {
        className: `${cardClasses?.dragAccordion?.typo} ${currDarkThemeClass}`,
    };
    
    const seeMoreIconProps = {
        className: `${cardClasses?.dragAccordion?.seeMoreIcon} ${currDarkThemeClass} ${expand ? cardClasses?.dragAccordion?.expanded : ''}`, // check for mui classes that can do the job
    };

    const seeMoreBtnProps = {
        children: expand ? 'Close' : 'See More',
        className: `${cardClasses?.dragAccordion?.seeMoreBtn} ${currDarkThemeClass}`,
        disableElevation: true,
        disableRipple: true,
        onClick: () => setExpand(state => !state),
        size: 'small',
        endIcon: <DownArrowIcon {...seeMoreIconProps} />,
    };

    const contentBoxProps = {
        className: `${cardClasses?.dragAccordion?.contentBox} ${currDarkThemeClass}`,
    };

    return (
        <Accordion {...accordionProps}>
            <AccordionSummary {...accordionSummaryProps}>
                
                <Box {...boxProps}>
                    <Box {...titleBoxProps}>
                        <RoomCardIcon {...iconProps}/>
                        <Tooltip title={title}><Typography {...titleTypoProps}>{title}</Typography></Tooltip>
                    </Box>
                    <Button {...seeMoreBtnProps} />
                </Box>

                {
                    <Collapse in={expand} timeout={transitionDuration}>
                        <Box {...contentBoxProps}>{expand ? content : ''}</Box>
                    </Collapse>
                }
            
            </AccordionSummary>
        </Accordion>
    );
}