import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function NoPermissionMsg(props) {
    const noPermsBoxProps = {
        sx: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
        },
    };

    const noPermTypoProps = {
        sx: {
            color: 'grey.600',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: 1.56,
            textAlign: 'center',
            width: '100%',
        },
    };
    return (
        <Box {...noPermsBoxProps}>
            <Typography {...noPermTypoProps}>
                You do not have permission to {props?.action ?? 'view'} {props?.entity ?? 'this information'}
            </Typography>
        </Box>
    );
}