import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import { TextField, Box, Stack, Typography, useTheme, MenuItem, Fade, IconButton, InputAdornment } from '@mui/material';
import { FeedbackContext } from '../../../App';
import useThemeVal from '../../../utility/useThemeVal';
import {
  PLANT_TYPES,
  cropClasses,
  dashboardClasses,
  errFeedbackFn,
  fadeTimingMs,
  getDarkThemeClass,
  miscClasses,
  popupClasses,
} from '../../utility/constants';
import Divider from '@mui/material/Divider';
import { DisplayTable, DownArrowIcon, cardClasses } from '../../utility';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const getStrainsTempRef = i => `strain-${i}`;
const getBatchTempRef = i => `batch-${i}`;
const getBatchDefName = i => `Batch#${i}`;
const getRandomIndex = array => Math.floor(Math.random() * array.length);

const generateBatchName = (strainName, index) => {
  if (!strainName) {
    return `Batch#${index}`;
  }
  const date = new Date();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');  // month 
  const year = date.getFullYear().toString().slice(-2);  // 2 digits of year 
  return `${strainName}-${month}/${year}-#${index}`;
};

const titles = [
  { title: 'Batch Name' },
  { title: 'Mother Plant ID', excludeOption: ['1', '3'] },
  { title: 'Package ID', excludeOption: ['1', '2'] },
  { title: 'Plant Count' },
];

const entriesKey = [
  { key: 'name' },
  { key: 'mp_tag_number', excludeOption: ['1', '3'] },
  { key: 'package_id', excludeOption: ['1', '2'] },
  { key: 'plant_count' },
];

const batchKeys = ['mp_tag_number', 'package_id', 'plant_type'];

const fetchValues = values => ({
  ...batchKeys.reduce((acc, curr) => ({ ...acc, [curr]: values[curr] ?? null }), {}),
});

const MotherPlantPopup = props => {
  const { open, onClose, strainCount, handleAddStrain, selectedFarmId, plantCount, filteredStrains, selectedFarmName, selectedOption, ...otherProps } = props;
  const feedbackCX = useContext(FeedbackContext);
  const [values, setValues] = useState({
    tempRef: getStrainsTempRef(strainCount),
    plant_type: '2',
    batch_count: 2,
    batches: []
  });
  const [plantCountState, setPlantCountState] = useState(plantCount);
  const [selectedStrain, setSelectedStrain] = useState(null);

  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), []);

  useEffect(() => {
    if (plantCountState > 0) {
      updateBatches(plantCountState, values.batch_count);
    }
  }, [plantCountState]);

  const updateBatches = (plantCount, batchCount) => {
    let batches = [];
    let remainingPlants = plantCount;
    for (let i = 1; i <= batchCount; i++) {
      const currentBatchSize = Math.ceil(remainingPlants / (batchCount - i + 1));
      batches.push({
        ...fetchValues(values),
        tempRef: `${getBatchTempRef(i)}-${getStrainsTempRef(strainCount)}`,
        name: generateBatchName(values.strain_name, i),
        plant_count: currentBatchSize,
        qualityplan: otherProps?.qualityplan ?? -1,
        phaseplan: otherProps?.phaseplan ?? -1,
        mp_tag_number: values?.selected_motherplants?.[getRandomIndex(values?.selected_motherplants ?? [])] ?? null,
      });
      remainingPlants -= currentBatchSize;
    }
    setValues(prevValues => ({
      ...prevValues,
      batch_count: batchCount,
      batches: batches
    }));
  };

  const handleerror = () => {
    feedbackCX.setContextValue(true, {
      type: 'Failure',
      message: 'Plant count exceeding the limit',
    });
  };
  const currDarkThemeClass = useThemeVal();
  const theme = useTheme();

  const tableTitlesList = useMemo(
    () => titles.filter(title => !title.excludeOption || !title.excludeOption.includes(selectedOption)),
    [selectedOption]
  );

  const handleSaveClick = () => {
    const totalPlantCount = values?.batches.reduce((total, batch) => total + parseInt(batch.plant_count, 10), 0);

    if (totalPlantCount > plantCountState) {
      handleerror();
      return;
    }
    const isPlantCountValid = values?.batches.reduce((valid, batch) => valid && parseInt(batch.plant_count, 10) >= 0, true);

    if (!isPlantCountValid) {
      handleerror();
      return;
    }

    handleAddStrain({...values, plant_count: plantCountState});

    onClose();
  };

  const handleStrainSelect = (strain) => {
    setSelectedStrain(strain);
    setValues((prevValues) => {
      const newValues = {
        ...prevValues,
        farmstrain: strain.id,
        strain_name: strain.strain_name,
      };
      
      if (newValues.batches?.length) {
        newValues.batches = newValues.batches.map((batch, index) => ({
          ...batch,
          name: generateBatchName(strain.strain_name, index + 1)
        }));
      }
      
      return newValues;
    });
  };

  const dividerProps = {
    className: `${dashboardClasses?.divider} ${getDarkThemeClass(theme)}`,
    style: {
      margin: '30px 0',
    },
  };

  const cropPlanSpecificsTextFieldProp = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
    autoComplete: 'off',
    className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
    fullWidth: true,
    inputProps,
    label: '',
    onChange: ({ target: { value } }) => handleSetValues(prop, value),
    required: true,
    value: prop === 'plant_count' ? plantCountState : (values?.[prop] ?? ''),
    ...otherTextProps,
  });

  const cropPlanMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const generatePlantTypeOptions = () => {
    return PLANT_TYPES.map((e, i) => (
      <MenuItem key={i} value={e.value} {...cropPlanMenuItemProps}>
        {e.label}
      </MenuItem>
    ));
  };

  const mpArgs = {
    prop: 'selected_motherplants',
    otherTextProps: {
      label: 'Choose Mother Plant',
      value: values?.selected_motherplants ?? [],
      required: false,
      select: true,
      SelectProps: {
        renderValue: selected =>
          values?.motherplants
            ?.filter(e => selected?.includes(e?.metrc_tag_number))
            ?.map(e => e?.metrc_tag_number)
            ?.join(', '),
        multiple: true,
      },
    },
  };

  const motherPlantsList = (values?.motherplants ?? [])?.map((e, i) => (
    <MenuItem key={i} value={e?.metrc_tag_number} {...cropPlanMenuItemProps}>
      {e?.metrc_tag_number}
    </MenuItem>
  ));

  if (!motherPlantsList?.length) {
    motherPlantsList.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Mother Plants.
      </MenuItem>,
      <MenuItem key={-2} value={-2} disabled {...cropPlanMenuItemProps}>
        Please enter the number of plants. You can add the mother plant thereafter.
      </MenuItem>
    );
  }

  const plantTypeArgs = {
    prop: 'plant_type',
    otherTextProps: {
      label: 'Plant Type',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  };

  const plantCountArgs = {
    prop: 'plant_count',
    otherTextProps: {
      label: 'Number of Plants',
      type: 'number',
      required: false,
      value: plantCountState,
      onChange: ({ target: { value } }) => {
        const newPlantCount = parseInt(value, 10);
        setPlantCountState(newPlantCount);
        updateBatches(newPlantCount, values.batch_count);
      },
    },
  };

  const handleBatchCountChange = value => {
    const newBatchCount = Math.max(value, 1);
    updateBatches(plantCountState, newBatchCount);
  };

  const renderStrainSelection = () => {
      return (
        <TextField
          {...cropPlanSpecificsTextFieldProp({
            prop: 'farmstrain',
            otherTextProps: {
              label: `Available Strains in ${selectedFarmName}`,
            select: true,
              value: values.farmstrain || '',
            onChange: (event) => handleStrainSelect(filteredStrains.find(strain => strain.id === event.target.value)),
            },
          })}
        >
          {filteredStrains.map((strain) => (
            <MenuItem key={strain.id} value={strain.id} {...cropPlanMenuItemProps}>
              {strain.strain_name}
            </MenuItem>
          ))}
        </TextField>
      );
    
  };

  const batchCountArgs = {
    prop: 'batch_count',
    otherTextProps: {
      value: values?.batch_count,
      required: false,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={() => handleBatchCountChange(values.batch_count - 1)}
              disabled={values.batch_count <= 1}
            >
              <RemoveIcon fontSize="small" sx={{ color: '#A1A591' }} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => handleBatchCountChange(values.batch_count + 1)}>
              <AddIcon fontSize="small" sx={{ color: '#A1A591' }} />
            </IconButton>
          </InputAdornment>
        ),
      },
      onChange: ({ target: { value } }) => handleBatchCountChange(parseInt(value, 10)),
    },
  };

  const batchSpecificProps = (batch, prop) => ({
    otherTextProps: {
      required: true,
      className: `${cardClasses?.strainAccordion?.textField} ${currDarkThemeClass}`,
      value: batch?.[prop],
      label: `${prop === 'mp_tag_number' ? 'Mother Plant ID ' : prop}`,
      onChange: ({ target: { value } }) =>
        setValues(prevValues => ({
          ...prevValues,
          batches: prevValues.batches.map(e => (e?.tempRef && e?.tempRef === batch?.tempRef ? { ...e, [prop]: value } : e))
        })),
    },
  });

  const getTitle = () => {
    switch (selectedOption) {
      case '2':
        return 'Create From Mother Plant';
      case '3':
        return 'Create From Package';
      default:
        return 'Create Plant';
    }
  };
  const entityDetailPopupProps = {
    open,
    headContentText: '',
    currDarkThemeClass,
    onClose: onClose,
    title: getTitle(),
    onSaveClick: handleSaveClick,
  };

  const mpStackProps = {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 2,
  };

  const mpTypoProps = {
    className: `${popupClasses?.strainEntry?.typo} ${currDarkThemeClass}`,
  };

  const mpTableTitleProps = {
    className: `${popupClasses?.strainEntry?.tableTitle} ${currDarkThemeClass}`,
  };

  const createTableEntries = (option, batch) => {
    const keys = entriesKey.filter(entry => !entry.excludeOption || !entry.excludeOption.includes(option));
    const entries = keys.map(entry => <TextField {...cropPlanSpecificsTextFieldProp(batchSpecificProps(batch, entry.key))} />);
    return entries;
  };

  const tableEntries = values.batches.map((batch, i) => createTableEntries(selectedOption, batch));

  const mpTableProps = {
    tableTitles: tableTitlesList,
    tableEntries: tableEntries,
    currDarkThemeClass,
    toShowAddBtn: false,
    tableTitleProps: mpTableTitleProps,
    tableEntryCellProps: { style: { border: 'none' } },
  };

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <div>
        <EntityDetailPopup {...entityDetailPopupProps}>
          {renderStrainSelection()}
          {selectedOption === '2' && <TextField {...cropPlanSpecificsTextFieldProp(mpArgs)}>{motherPlantsList}</TextField>}
          <Stack direction="row" spacing={2}>
            <TextField {...cropPlanSpecificsTextFieldProp(plantTypeArgs)} defaultValue={values.plant_type}>
              {generatePlantTypeOptions()}
            </TextField>
            <TextField {...cropPlanSpecificsTextFieldProp(plantCountArgs)} />
          </Stack>

          {plantCountState > 0 && (
            <>
              <Divider {...dividerProps} />

              <Stack {...mpStackProps}>
                <Typography {...mpTypoProps}>{`How many batches do you want to create per ${plantCountState} plants? `}</Typography>
                <TextField {...cropPlanSpecificsTextFieldProp(batchCountArgs)} sx={{ width: '22%' }} />
              </Stack>

              <DisplayTable {...mpTableProps} />
            </>
          )}
        </EntityDetailPopup>
      </div>
    </Fade>
  );
};

export default MotherPlantPopup;
