import React, { useState, useEffect, useContext } from 'react';
import { CreateStrainPopup, DetailAndDelBtn, MetrcLogo, StrainEntryPopup, errFeedbackFn, farmsClasses, metrcTagsClasses, noPermTablePrefixMsg, roomDetailClasses } from '../../utility';
import { Box, Button, Chip, CircularProgress, InputAdornment, OutlinedInput, Paper, Stack, Typography } from '@mui/material';
import APIService from '../../../api/apiService';
import SearchIcon from '@mui/icons-material/Search';
import DisplayMetrcTagsTable from '../../utility/tables/DisplayMetrcTagsTable';
import { debounce } from 'lodash';
import { FeedbackContext } from '../../../App';

const strainKeyTitleMap = [
    { key: 'strain_name', title: 'Name' },
    {key: 'wet_weight', title:'Proj Wet Wt(lbs)'},
    { key: 'strain_yield', title: 'Yield' },
    // {key: 'wet_weight', title:'Wet Weight(lbs)'},
    // {key: 'dry_weight', title:'Dry Weight(lbs)'},
    { key: 'flower_price', title: 'Price ($ per lb)' },
    { key: 'cbd_level', title: 'CDB Level'},
    { key: 'thc_level', title: 'THC Level'},
    { key: 'genetics', title: 'Genetics'}
];

const addNewKey = 'add';
const updateKey = 'update';

export default function _StrainsTab(props) {
    const {
        currDarkThemeClass = '',
        openStrainsPopup = false,
        strainPerms = null,
        farms = [],
        handleSetValues = () => { },
        handleErrorWithFeedback = () => { },
        handleEntityEntryCreateRes = () => { },
        handleEntityEntryModifyRes = () => { },
        ...otherProps
    } = props;

    const [open, setOpen] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [detailChildren, setDetailChildren] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [selectedFarm, setSelectedFarm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [searchTerm, setSearchTerm] = useState('');

    const feedbackCX = useContext(FeedbackContext)

    const fetchData = async () => {
        setLoading(true);
        try {
            let url = `strain/?limit=${rowsPerPage}&page=${page + 1}`;
            if (selectedFilter !== 'All') {
                url += `&farm_id=${selectedFilter}`;
            }
            if (searchTerm) {
                url += `&name__icontains=${encodeURIComponent(searchTerm)}`;
            }
    
            const response = await APIService.fetchInstance(url);
            if (response?.data) {
                const formattedData = formatData(response.data.results || []);
                setData(formattedData);
                setTotalCount(response.data.count || 0);
            }
        } catch (error) {
            handleErrorWithFeedback(errFeedbackFn({}))(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, selectedFilter]);

    const debouncedFetchData = debounce(() => fetchData(), 1500);

    useEffect(() => {
        debouncedFetchData();
        return () => {
            debouncedFetchData.cancel();
        };
    }, [searchTerm]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            debouncedFetchData(); 
        }
    };

    const handlePopupClose = () => {
        setDetailChildren({});
        setOpen(false);
        handleSetValues('openStrainsPopup', false);
    }

    const handleSetSelStrain = (prop, value) => {
        setDetailChildren(state => ({
            ...state,
            selStrain: {
                ...state.selStrain,
                [prop]: value,
            }
        }));
    }

    const handleFilterChange = (farmId) => {
        if (farmId === 'All' || selectedFilter === farmId) {
            setSelectedFilter('All');
            setSelectedFarm(null); 
        } else {
            const farm = farms.find(f => f.id === farmId); 
            setSelectedFilter(farmId);
            setSelectedFarm(farm); 
        }
        setPage(0);
    };

    const handlePageChange = (newPage, newRowsPerPage) => {
        setPage(newPage);
        setRowsPerPage(newRowsPerPage);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const formatData = (formattedData) => {
        return formattedData.map((strain, idx) => [
            `${idx + 1}`.padStart(2, 0),
            ...strainKeyTitleMap.map(e => {
                if (e.key === 'genetics') {
                    return strain[e.key] || 'N/A'; 
                }
                return e.key !== 'strain_name' ? +strain[e.key] : strain[e.key];
            }),
            <DetailAndDelBtn {...gDStrainEntryActionBtnProps(strain)} />
        ]);
    };
      
    const handleEntityEntryModify = (entity, prop) => async (value) => {
        const errConfig = {
            modify: true,
            resource: 'Strain',
        };
        let res = { success: false };
        try {
            res = await APIService.modifyInstance(entity, value)
                .then(handleEntityEntryModifyRes(prop))
            fetchData();
        } catch (err) {
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }
        return res;
    };

    const handleStrainEntryView = (strain) => {
        handleSetValues(updateKey, true)
        setOpen(true);
        console.log('Selected Strain:', strain);

        setDetailChildren({
            selStrain: strain,
            title: strain.strain_name,
            toShowSave: strainPerms?.update ?? false,
            handleStrainSave: handleEntityEntryModify(`strain/${strain.id}/`, 'strains'),
            handleSetSelStrain,
        });
    }
    
    const handleEntitySave = (entity, prop) => async (value) => {
        const errConfig = {
            create: true,
            resource: 'Strain',
        };

        let res = { success: false };

        try {
            res = await APIService.createInstance(entity, value)
                .then((response) => { handleEntityEntryCreateRes(prop)(response);
            fetchData();
              feedbackCX.setContextValue(true, {
                type: 'success',
                message: 'Created Strain successfully.',
                autoHideDuration: 3000,
              });
              return { success: true };
            });
        } catch (err) {
            handleErrorWithFeedback(errFeedbackFn(errConfig))(err);
        }
        return res;
    };

    const handleAddNewStrain = () => {
        handleSetValues(addNewKey, true);
        setOpen(true);
        setDetailChildren({
            title: 'Strain',
            handleAddStrain: handleEntitySave('strain/', 'strains'),
        });
    };

    const gDStrainEntryActionBtnProps = (strain) => ({
        currDarkThemeClass,
        toShowDelBtn: false,
        onDetailClick: () => handleStrainEntryView(strain),
    });

    const sChipsProps = (farm) => ({
        key: farm.id,
        label: farm.farm_name,
        variant: selectedFilter === farm.id ? 'filled' : 'outlined',
        onClick: () => handleFilterChange(farm.id),
        clickable: true,
        className: `${roomDetailClasses?.chip} ${currDarkThemeClass}`
    });

    const metrcTagFilterWrapperProps = {
        direction: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    const metrcTagFarmChipsList = (
        <Stack direction='row' spacing={1}>
            <Chip key="All" label="All"
            size='small'
                variant={selectedFilter === 'All' ? 'filled' : 'outlined'}
                onClick={() => handleFilterChange('All')}
                clickable
                className={`${roomDetailClasses?.chip} ${currDarkThemeClass}`}
            />
            {farms.map(farm => (
                <Chip key={farm.id} {...sChipsProps(farm)} />
            ))}
        </Stack>
    );

    const outlinedInputProps = {
        className: `${roomDetailClasses.filterInput} ${currDarkThemeClass}`,
        size: "small",
        placeholder: "Search for Strains",
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ),
        onChange: handleSearch,
        onKeyPress: handleKeyPress, 
        value: searchTerm
    };

    const strainTableValues = {
        animationClass: 'farmsTabContent',
        currDarkThemeClass,
        emptyTableMsg: !strainPerms?.read ? ` ${noPermTablePrefixMsg} strains` : undefined,
        entity: 'Strains',
        tableEntries: data,
        tableTitles: [
            { title: 'Sl. No' },
            ...strainKeyTitleMap.map(e => ({ title: e.title })),
            { title: '' },
        ],
        toShowAddBtn: false,
        page,
        rowsPerPage,
        totalCount,
        onPageChange: handlePageChange,
        loading,
        onClick: handleAddNewStrain,
        ...otherProps
    };

    const strainsPopupProps = {
        currDarkThemeClass,
        open: openStrainsPopup || open,
        farms,

        handleAddStrain: handleEntitySave('strain/', 'strains'),
        onClose: handlePopupClose,
        ...detailChildren 
    }; 

    const handleSyncToMetrc = async () => {
        if (selectedFarm) {
            setSyncLoading(true);
            let syncTometrcUrl = `strain/?licenseNumber=${selectedFarm.license_number}&sync_to_metrc=true`;
    
            try {
                const response = await APIService.fetchInstance(syncTometrcUrl);
                if (response.status === 200) {
                    fetchData();
                    feedbackCX.setContextValue(true, {
                        type: 'success',
                        message: 'Strains Synced to Metrc Successfully.',
                    });
                    setTimeout(() => {
                        feedbackCX.setContextValue(false, {});
                    }, 5000);
                }

            } catch (error) {
                feedbackCX.setContextValue(true, {
                    type: 'error',
                    message: `Error syncing to Metrc`,
                });
            } finally {
                setSyncLoading(false);
            }
        } 
    };

    const metrcTagFilterTypoProps = {
        className: `${metrcTagsClasses?.filterTitleTypo} ${currDarkThemeClass}`,
    };

    // const metrcTagFilterProps = {
    //     direction: 'row',
    //     spacing: 3,
    //     alignItems: 'center',
    //     style: { maxWidth: '820px', overflowX: 'auto', overflowY: 'hidden'}
    // };

    const metrcTagFilterProps = {
        direction: 'row',
        spacing: 3,
        alignItems: 'center',
        style: { 
            maxWidth: '850px', 
            overflowX: 'auto', 
            overflowY: 'hidden',
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent',
            '&::-webkit-scrollbar': {
                width: '3px',
                height: '3px',
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(155, 155, 155, 0.5)',
                borderRadius: '1px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: 'rgba(155, 155, 155, 0.7)',
            },
        }
    };

    return (
        <Paper className={`${farmsClasses?.tableListPaper} ${currDarkThemeClass} farmsTabContent`} square  variant="none"  style={{ marginTop: '-10px' }} >
            <Stack {...metrcTagFilterWrapperProps} padding={2}>
                <Stack {...metrcTagFilterProps}>
                    <Typography {...metrcTagFilterTypoProps}>Show by Farm:</Typography>
                    {metrcTagFarmChipsList}
                </Stack>
                <Stack direction='row' sx={{justifyContent: 'flex-end'}}>
                {selectedFilter !== 'All' && (
                   <Button
                   onClick={handleSyncToMetrc}
                   disabled={syncLoading}  style={{ display: 'flex', alignItems: 'center', paddingRight: '20px' }} sx={{ color: '#B1D23A',  '&.Mui-disabled': { color: '#B1D23A', }, }}>
                   <MetrcLogo style={{ width: 24, height: 24, marginRight: 8 }} />
                   {syncLoading ? 'Syncing...' : 'Sync to Metrc'}
               </Button>
                )}
                <OutlinedInput {...outlinedInputProps}/> 
            </Stack>
            </Stack>
            <Box className={`${farmsClasses?.tableContainer} ${currDarkThemeClass}`}>
                <DisplayMetrcTagsTable {...strainTableValues} />
            </Box>
            {(open && addNewKey) || openStrainsPopup && <CreateStrainPopup {...strainsPopupProps} />}
            {open && updateKey && <StrainEntryPopup {...strainsPopupProps} />}
        </Paper>
    );
}