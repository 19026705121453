import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { msgClasses } from "../constants";


// Purpose: It is a single detail entry of an expanded accordion

// 22-05-2023: TODO: Only activate tooltip if text overflows


export default function AccordionDetailMsg(props) {
    const {
        currDarkThemeClass='',
        otherClasses={},
        showTitleTooltip=true,
        showValueTooltip=true,
        title='',
        value='',
    } = props;

    const boxProps = {
        className: `${msgClasses?.accordionDetail?.box} ${currDarkThemeClass}  ${otherClasses?.box ?? ''}`,
    };

    const titleTypoProps = {
        className: `${msgClasses?.accordionDetail?.titleTypo} ${currDarkThemeClass} ${otherClasses?.title ?? ''}`,
    };

    const valueTypoProps = {
        className: `${msgClasses?.accordionDetail?.valueTypo} ${currDarkThemeClass}  ${otherClasses?.value ?? ''}`,
    };


    return (
        <Box {...boxProps}>
            <Tooltip title={showTitleTooltip ? title : ''}><Typography {...titleTypoProps}>{title}</Typography></Tooltip>
            <Tooltip title={showValueTooltip ? value : ''}><Typography {...valueTypoProps}>{value}</Typography></Tooltip>
        </Box>
    );
}