import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SetupOutlinePage from "./SetupOutline";
import APIService from '../../api/apiService';
import useThemeVal from "../../utility/useThemeVal";
import {loginPageClasses } from "../utility/constants";
import { FeedbackContext } from "../../App";
const forgetPasswordInfo = "Forgot Your Password?";
const actionTitle = 'Please enter your email address to reset your password.';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
 
  const feedbackCX = useContext(FeedbackContext);

 
  const currDarkThemeClass = useThemeVal();

  const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

  const handleFinally = () => {
    setLoading(false);
  }

  const handleSuccess = () => {
    feedbackCX.setContextValue(true, {
      type: 'success',
      message: 'Password reset email sent successfully!',
    });
  };
  

  const handleError = (err) => {
    const helperText = err?.response?.status === 0 ? 'The server could not be reached'
                            : err?.response?.status === 400 ? 'Please check your inputs'
                            : err?.response?.status === 401 ? 'Invalid credentials'
                            : err?.response?.status === 500 ? 'Internal Server Error. We regret the inconvenience'
                            : 'An error occured';

        handleSetValues('error', true);
        
        handleSetValues('helperText', helperText);
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setLoading(true);

    const domain = window.location.origin;

    const entity = 'accounts/forgot_password/';
    const payload = {
      'email': values?.email,
      'domain': domain,
    };
    
    

    APIService.createInstance(entity, payload)
      .then(handleSuccess)
      .catch(handleError)
      .finally(handleFinally);
  };

  const sPOutlineProps = {
    currDarkThemeClass,
  };

  const sPBoxProps = {
    className: `${loginPageClasses?.box} ${currDarkThemeClass}`,
  };

  const sPInfoTypoProps = {
    className: `${loginPageClasses?.infoTypo} ${currDarkThemeClass}`,
  };

  const sPActionTypoProps = {
    className: `${loginPageClasses?.actionTypo} ${currDarkThemeClass}`,
  };

  const sPFormBoxProps = {
    component: 'form',
    onSubmit: handleResetPassword,
  };

  const handleSetTextField = (prop, value) => {
    handleSetValues(prop, value);
  };

  const sPTextFieldProps = (prop, otherProps) => ({
    autoComplete: 'off',
    disabled: loading,
    fullWidth: true,
    inputProps: {
      ...otherProps?.inputProps
    },
    InputProps: {
      sx: {
        ...(prop === 'code' && {
          '&.MuiOutlinedInput-root.MuiInputBase-root': {
            pr: '15px',
          }
        }),
      },
    },
    label: ' ',
    onChange: ({target: {value}}) => handleSetTextField(prop, value),
    required: true,
    className: `${loginPageClasses?.textField} ${currDarkThemeClass}`,
    value: values[prop] ?? '',
    ...otherProps,
  });

  const otherEmailArgs = {
    autoFocus: true,
    type: 'email',
    label: 'Your Email Address',
  };

  const lPProgressProps = {
    color: 'warning',
    size: 20,
  };

  const sPProceedBtnProps = (message='Proceed', otherArgs={}) => ({
    children: message,
    disabled: loading,
    onClick: undefined,
    startIcon: loading && <CircularProgress {...lPProgressProps} />,
    className: `${loginPageClasses?.proceedBtn} ${currDarkThemeClass}`,
    ...otherArgs
  });

  return (
    <SetupOutlinePage {...sPOutlineProps}>
      <Box {...sPBoxProps}>
        <Typography {...sPInfoTypoProps}>{forgetPasswordInfo}</Typography>
        <Typography {...sPActionTypoProps}>{actionTitle}</Typography>

        <Box {...sPFormBoxProps}>
          <TextField {...sPTextFieldProps('email', otherEmailArgs)} />
          <Button {...sPProceedBtnProps('Reset Password', {type: 'submit'})} />
        </Box>
      </Box>
    </SetupOutlinePage>
  );
}

