import { useCallback, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EntityDetailPopup from "../../utility/EntityDetailPopup";
import { popupClasses } from "../../utility";
import { Box } from "@mui/material";

const STATUS_TYPES = [
  { value: 'received', label: 'Received' },
  { value: 'used', label: 'Used' },
];

const TYPE_OPTIONS = [
  { value: 'medical_plant', label: 'Medical Plant' },
  { value: 'medical_package', label: 'Medical Package' },
];

const typeLabelMapping = TYPE_OPTIONS.reduce((acc, { value, label }) => {
  acc[value] = label;
  return acc;
}, {});

export default function MetrcTagsEditPopup(props) {
  const {
    currDarkThemeClass = '',
    handleTagSave,
    handleSetSelTag,
    selTag,
    farms,
    ...otherProps
  } = props;

  const [values, setValues] = useState({});

  const handleSetValues = useCallback(
    (prop, value) => setValues(state => ({ ...state, [prop]: value })),
    []
  );

  const getTextFieldProps = ({ prop, label, type = 'text', inputProps = {}, otherTextProps = {} }) => ({
    label,
    type,
    className: `${popupClasses?.createStrain?.textField} ${currDarkThemeClass}`,
    disabled: !otherProps?.toShowSave || values?.loading,
    fullWidth: true,
    onChange: ({ target: { value } }) => handleSetSelTag(prop, value),
    value: selTag?.[prop] ?? '',
    inputProps,
    ...otherTextProps,
  });

  const getTypographyProps = (label, value) => ({
    className: `${popupClasses?.tagEntry?.typo} ${currDarkThemeClass}`,
    component: "div",
    variant: "body1",
    children: `${label}: ${value ?? '-'}`,
  });

  const handleTagEntrySave = async () => {
    if (Object.values(selTag).some(val => !val)) return; // Optionally set error

    handleSetValues('loading', true);

    try {
      const res = await handleTagSave(selTag);
      if (res?.success) {
        otherProps?.onClose?.();
      }
    } catch (error) {
      console.error('Error saving tag:', error);
    } finally {
      handleSetValues('loading', false);
    }
  };

  const selectedTagFarmId = selTag?.farm_id;
  const selectedFarm = farms.find(farm => farm.id === selectedTagFarmId);
  const popUpFarmName = selectedFarm ? selectedFarm.name : "No Farm Found";

  const ReadOnlyFields = (
    <span>
    <Typography {...getTypographyProps('Farm Name', popUpFarmName )} />
      <Typography {...getTypographyProps('Tag', selTag?.tag)} />
      <Typography {...getTypographyProps('Type', typeLabelMapping[selTag?.type])} />
      <Typography {...getTypographyProps('Commissioned', selTag?.commissioned)} />
    </span>
  )

  const entityDetailProps = {
    currDarkThemeClass,
    loading: values?.loading,
    onSaveClick: handleTagEntrySave,
    headContentText: ReadOnlyFields ,
    ...otherProps,
  };

  return (
    <EntityDetailPopup {...entityDetailProps}>

      <TextField
        select
        {...getTextFieldProps({
          prop: 'status',
          label: 'Status',
        })}
      >
        {STATUS_TYPES.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
      
      <TextField
        {...getTextFieldProps({
          prop: 'used_on',
          label: 'Used',
          type: 'date',
        })}
      />
    </EntityDetailPopup>
  );
}
