import { Fragment, useEffect, } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { endpoints, inProdInvRowTitleWd, tableClasses } from "../constants";

import APIService from "../../../api/apiService";


// const tableRowHeadersContentMap = [
//     { isEmptyRowHeader: true },
//     { isEmptyRowHeader: false, rowHeader: 'RowHeader' },
// ];

    
// const tableEntriesContentMap = [
//     { isHeader: true, keys: [ {val: 'key'} ] },
//     { isHeader: false, values: [ {val: 'value'} ], },
// ];
    
// const tableHeaderContentMap = [
//     { entryRowTitle: 'Header', content: [ {value: 'Value'} ], isTableHeader: true },
// ];


export default function InProdInvTable(props) {

    const { 
        columnCount=0,
        currDarkThemeClass='',
        otherClasses={},
        showDetailBtn=true,
        tableRowHeadersContentMap=[],
        tableEntriesContentMap=[],
        tableHeaderContentMap=[],
        title='',

        handleOnClick=()=>{},
     } = props;

    // useEffect(() => {
    //     const entity = endpoints?.harvest?.list;

    //     APIService.fetchInstance(entity)
    //     .then(console.log)
    //     .catch(console.log)
    // }, []);

     
    const boxProps = {
        className: `${tableClasses?.inProdInv?.box} ${currDarkThemeClass} ${otherClasses?.box ?? ''}`,
    };

    const titleBoxProps = {
        className: `${tableClasses?.inProdInv?.titleBox} ${currDarkThemeClass} ${otherClasses?.titleBox ?? ''}`,
    };

    const titleTypo = {
        className: `${tableClasses?.inProdInv?.titleTypo} ${currDarkThemeClass} ${otherClasses?.titleTypo ?? ''}`,
    };

    const btnProps = {
        children: 'See Strain Details',
        className: `${tableClasses?.inProdInv?.button} ${currDarkThemeClass} ${otherClasses?.button ?? ''}`,
        disabled: !showDetailBtn,
        
        ...(showDetailBtn && {
            onClick: handleOnClick,
        }),
    };

    const tableBoxProps = {
        className: `${tableClasses?.inProdInv?.tableBox} ${currDarkThemeClass} ${otherClasses?.tableBox ?? ''}`,
        sx: {
            // gridTemplateColumns: `repeat(${(tableHeaderContentMap?.[0]?.content?.length ?? 0) + 1}, 1fr)`,
            gridTemplateColumns: `minmax(${inProdInvRowTitleWd}px, auto) repeat(${columnCount}, 1fr)`,
        },
    };

    // const rowHeadersBoxProps = {
    //     className: `${tableClasses?.inProdInv?.rowHeadersBox} ${currDarkThemeClass} ${otherClasses?.rowHeadersBox ?? ''}`,
    // };
    
    // const rowHeaderTypoProps = rowHeader => ({
    //     className: `${tableClasses?.inProdInv?.rowHeaderTypo} ${currDarkThemeClass} ${otherClasses?.rowHeaderTypo ?? ''}`,
    // });

    // const rowHeadersList = tableRowHeadersContentMap?.map((e, i) => <Typography key={i} {...rowHeaderTypoProps(e)} >{e?.isEmptyRowHeader ? '' : e?.rowHeader}</Typography>);

    // const entriesBoxProps = {
    //     className: `${tableClasses?.inProdInv?.entriesBox} ${currDarkThemeClass} ${otherClasses?.entriesBox ?? ''}`,
    // };
    
    // const tableEntryBoxProps = {
    //     className: `${tableClasses?.inProdInv?.tableEntryBox} ${currDarkThemeClass} ${otherClasses?.tableEntryBox ?? ''}`,
    // };

    // const entryHeaderTypoProps = entryHeader => ({
    //     className: `${tableClasses?.inProdInv?.entryHeaderTypo} ${currDarkThemeClass} ${otherClasses?.entryHeaderTypo ?? ''}`,        
    // });

    // const entryValueTypoProps = entryValue => ({
    //     className: `${tableClasses?.inProdInv?.entryValueTypo} ${currDarkThemeClass} ${otherClasses?.entryValuesTypo ?? ''}`,        
    // });


    // const entriesList = tableEntriesContentMap?.map((e, i) => (
    //     <Box key={i} {...tableEntryBoxProps}>
    //         {
    //             e?.isHeader 
    //             ? e?.keys?.map((el, idx) => <Typography key={idx} {...entryHeaderTypoProps(el)}>{el?.val}</Typography>)
    //             : e?.values?.map((el, idx) => <Typography key={idx} {...entryValueTypoProps(el)}>{el?.val}</Typography>)
    //         }
    //     </Box>
    // ));

    const entryRowTitleTypoProps = entryRowTitle => ({
        className: `${tableClasses?.inProdInv?.entryRowTitleTypo} ${currDarkThemeClass} ${otherClasses?.entryRowTitleTypo ?? ''}`,
    });
    
    const entryValueTypoProps = (entryValue, entry) => ({
        className: `${tableClasses?.inProdInv?.entryValueTypo} ${currDarkThemeClass} ${otherClasses?.entryContentTypo ?? ''} ${entry?.isTableHeader ? tableClasses?.inProdInv?.tableHeader : ''}`,
    });


    // 21-04-2023: Preferred approach than rowHeadersList & entriesList
    const entries = tableHeaderContentMap?.map((e, i) => ( 
        <Fragment key={i}>
            
            <Typography {...entryRowTitleTypoProps(e)}>{e?.entryRowTitle ?? ''}</Typography>
            {
                e?.content?.map((el, idx) => <Typography key={idx} {...entryValueTypoProps(el, e)} >{el?.value}</Typography>)
            }

        </Fragment>
    ));


    return (
        <Box {...boxProps}>

            <Box {...titleBoxProps}>
                <Typography {...titleTypo}>{title}</Typography>
                {
                    showDetailBtn && <Button {...btnProps} />
                }
            </Box>

            <Box {...tableBoxProps}>

                {/* <Box {...rowHeadersBoxProps}>
                    {rowHeadersList}
                </Box>
                
                <Box {...entriesBoxProps}>
                    {entriesList}
                </Box> */}

                {entries}

            </Box>

        </Box>
    );
}