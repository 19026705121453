import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DownArrowIcon, cardClasses, cropClasses } from '../../utility';

const defAccordionDetail = undefined;
const defAccordionSummary = undefined;

export default function BasicAccordion({
  summary = defAccordionSummary,
  detail = defAccordionDetail,
  currDarkThemeClass = '',
  title = '',
  children,
  toShowExpandIcon = true,
  accordionCustomProps = {},
  downArrowCustomProps = {},
  accordionDetailsCustomProps = {},
  accordionSummaryCustomProps = {},
  defaultExpanded = false,
  expanded, // Expanded state controlled by parent
  onChange, // Handler function passed from parent
}) {
  const bAccordionProps = {
    elevation: 0,
    square: true,
    defaultExpanded,
    expanded,
    onChange,
    ...accordionCustomProps,
  };

  const bDownArrowIconProps = {
    className: `${cropClasses.overviewArrowIcon} ${currDarkThemeClass}`,
    ...downArrowCustomProps,
  };

  const bAccordionDetailsProps = {
    ...accordionDetailsCustomProps,
  };
  
  const dICardTitleTypoProps = {
    className: `${cardClasses?.detailInfo?.titleTypo} ${currDarkThemeClass}`,
  };

  return (
    <Accordion {...bAccordionProps}>
      <AccordionSummary expandIcon={toShowExpandIcon && <DownArrowIcon {...bDownArrowIconProps} />} {...accordionSummaryCustomProps}>
        {title && <Typography {...dICardTitleTypoProps}>{title}</Typography>}
        {summary}
      </AccordionSummary>
      <AccordionDetails {...bAccordionDetailsProps}>{children || <Typography>{detail}</Typography>}</AccordionDetails>
    </Accordion>
  );
}
