import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function EntityDetailStepper(props) {
  const { activeStep = -1, steps, ...otherProps } = props;

  const stepProps = (step) => ({
    ...otherProps?.stepProps,
    ...step?.stepProps,
  });

  const stepperProps = {
    alternativeLabel: true,
    activeStep,
    ...otherProps?.stepperContainerProps
  };

  const formatDate = (date) =>
    date.toLocaleDateString('en-GB', {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

  const getDate = (primary, fallback) => new Date(primary || fallback);

  return (
    <Stepper {...stepperProps}>
      {steps.map((step, index) => {
        return (
          <Step key={index} {...stepProps(step)}>
            <StepLabel>{step?.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}
