import React from 'react';
import { Fade, Typography, Paper } from '@mui/material';
import { EyeIcon2, fadeTimingMs, metricPlanCardClasses, BaseDetailCard, phasePlanNewAndDetailRoute, plansClasses } from '../../utility';
import NoPermissionMsg from '../../utility/NoPermissionMsg';
import { useNavigate } from 'react-router';

export default function _PhaseTab(props) {
  const { phasePlanPerms, phaseplans, currDarkThemeClass } = props;

  const navigate = useNavigate();

  const pTabBoxProps = {
    className: `${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`,
    variant: 'square',
    elevation: 0,
  };

  const pTabCardBoxProps = {
    className: `${plansClasses?.tabCardBox} ${currDarkThemeClass}`,
  };

  const subHeader = (phase) => (
    <Typography className={`${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`}>
      {phase.description}
    </Typography>
  );
  
  const handleShow = (id) => () => {
    navigate(`${phasePlanNewAndDetailRoute}/${id}?tab=overview`);
  };

  const pPhaseCardProps = (phase) => ({
    key: phase.id,
    baseEntity: phase.name,
    subHeader: subHeader(phase),
    currDarkThemeClass,
    isHeaderClickable: true, // Make the header clickable
    headerClickHandler: handleShow(phase.id), // Use handleShow for navigation
    cardActionBtns: [
      {
        key: 'details',
        title: 'Show SubPhases',
        icon: EyeIcon2,
        otherProps: {
          onClick: handleShow(phase.id),
        },
        startIconProps: {
          className: `${metricPlanCardClasses.linkIcon} ${currDarkThemeClass}`,
        },
      },
    ],
  });

  const pSiteDevTypoProps = {
    className: `${plansClasses?.siteDevTypo} ${currDarkThemeClass}`,
  };

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Paper {...pTabBoxProps}>
        {phasePlanPerms.read ? (
          <>
            {phaseplans?.length > 0 ? (
              <Paper {...pTabCardBoxProps}>
                {phaseplans?.map((phase) => (
                  <BaseDetailCard {...pPhaseCardProps(phase)} />
                ))}
              </Paper>
            ) : (
              <Typography {...pSiteDevTypoProps}>No Phases Available</Typography>
            )}
          </>
        ) : (
          <NoPermissionMsg entity='Phases' />
        )}
      </Paper>
    </Fade>
  );
}
