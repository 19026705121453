import React from 'react'
import {
    RefreshIcon,
    fadeTimingMs,
    farmsClasses,
    errFeedbackFn,
    SelDropdownIcon,
    miscClasses,
    noPermTablePrefixMsg,
} from '../../utility'
import { Fade, Paper, Typography, Select, Button, MenuItem } from '@mui/material';
import APIService from '../../../api/apiService';
import FarmCard from '../../farms/FarmCard';


export default function _FarmsTab(props) {
    const {
        currDarkThemeClass = '',
        organization = 'Unknown',
        title = 'Farms',
        handleSetValues = () => { },
        metrcKey = '',
        setMetrcKey = () => { },
        setValues = () => { },
        metrc_id = '',
        loading = true,
        farmPerms = [],
        feedbackCX = null,
        metrc = [],
        farms = [],
        handleConfigurationsRes = () => { },
        handleErrorWithFeedback = () => { }
    } = props;

    const handleMetrcChange = ({ target: { value } }) => {
        handleSetValues('metrc_id', value);
        setMetrcKey(value);
    };

    const handleRefreshData = async () => {
        console.log('this rann');
        if (!farmPerms?.create) return;

        handleSetValues('loading', true);

        const errConfig = {};

        // Will need to allow data_fetch=true func in collection/farm/ ?
        // Or, just update farm and metrcrooms when data_fetch = true and save their response
        // const entity = `farm/?metrc_id=${metrcKey}&data_fetch=true`;
        const entity = `farm/?data_fetch=true`;
        try {
            let response = await APIService.fetchInstance(entity)
            handleConfigurationsRes(setValues)(response)
        } catch (error) {
            handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn(errConfig))(error)
        }
    };

    const farmsPaperProps = {
        className: `${farmsClasses?.paper} ${currDarkThemeClass} customScroll`,
        square: true,
        variant: 'none',
        sx: {
            padding: '60px 0px'
        }
    };

    const farmsOrgPaperProps = {
        className: `${farmsClasses?.orgPaper} ${currDarkThemeClass}`,
        variant: 'none',
    };

    const farmsOrgTitleTypoProps = {
        className: `${farmsClasses?.orgTitleTypo} ${currDarkThemeClass}`,
    };

    const farmOrgNameTypoProps = {
        className: `${farmsClasses?.orgNameTypo} ${currDarkThemeClass}`,
    };

    const farmsTitleTypoProps = {
        className: `${farmsClasses?.titleTypo} ${currDarkThemeClass}`,
    };

    const farmsKeyTypoProps = {
        className: `${farmsClasses?.keyTypo} ${currDarkThemeClass}`,
    };

    const farmsKeyRefreshAddPaperProps = {
        className: `${farmsClasses?.keyRefreshAddPaper} ${currDarkThemeClass}`,
        variant: 'none',
    };

    const farmsIconProps = {
        className: `${farmsClasses?.icon} ${currDarkThemeClass}`,
    };

    const farmsKeySelProps = {
        className: `${farmsClasses?.keySelect} ${currDarkThemeClass}`,
        IconComponent: SelDropdownIcon,
        label: ' ',
        onChange: handleMetrcChange,
        value: metrc_id ?? '',
    };

    const farmsRefreshBtnProps = {
        className: `${farmsClasses?.refreshBtn} ${currDarkThemeClass}`,
        disabled: loading || !farmPerms?.create,
        onClick: handleRefreshData,
        startIcon: <RefreshIcon {...farmsIconProps} />,
    };

    const farmsKeyMenuItemProps = {
        className: `${farmsClasses?.keyMenuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: `${miscClasses?.menuItemFocusDark}`,
    };

    const orgKeyMenuItems = metrc?.map((e, i) =>
        <MenuItem key={i} value={e.metrc_id} {...farmsKeyMenuItemProps}>{e.metrc_id}</MenuItem>
    ) || (
            metrcKey ? <MenuItem key={`metrc-0`} value={metrcKey} {...farmsKeyMenuItemProps} >{metrcKey}</MenuItem>
                : []
        );

    const noEntityProps = {
        className: `${farmsClasses?.noEntityTypo} ${currDarkThemeClass}`,
    };

    const farmsListPaperProps = {
        className: `${farmsClasses?.listPaper} ${currDarkThemeClass} farmsTabContent`,
        variant: 'none',
    };

    const otherFarmCardProps = {
        org_name: organization?.org_name,
        currDarkThemeClass,
    };

    const farmsList = (
        !farmPerms?.read ? <Typography {...noEntityProps}>{noPermTablePrefixMsg} farms</Typography>
            : farms?.length > 0 ?
                <Paper {...farmsListPaperProps}>
                    {farms.map((e, i) => <FarmCard key={i} {...e} {...otherFarmCardProps} />)}
                </Paper>
                : <Typography {...noEntityProps}>No Farms Available</Typography>
    );

    return <Fade in={true} timeout={fadeTimingMs}>
        <Paper {...farmsPaperProps} style={{ padding: '50px 0px' }}>
            <Paper {...farmsOrgPaperProps}>
                <Typography {...farmsOrgTitleTypoProps}>ORGANIZATION</Typography>
                <Typography {...farmOrgNameTypoProps}>{organization?.org_name ?? 'Unknown'}</Typography>
            </Paper>
            <Typography {...farmsTitleTypoProps}>{title}</Typography>
            <Typography {...farmsKeyTypoProps}>Compliance Org Key</Typography>
            <Paper {...farmsKeyRefreshAddPaperProps}>
                <Select {...farmsKeySelProps}>{orgKeyMenuItems}</Select>
                <Button {...farmsRefreshBtnProps}>Refresh Data</Button>
            </Paper>
            {
                farmsList
            }
        </Paper>
    </Fade>
}

