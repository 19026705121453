import React, { useMemo, useState } from 'react';
import { Timeline, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineItem } from '@mui/lab';
import { timelineClasses } from '../../utility';
import BasicAccordion from './BasicAccordion';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const defLevelCompleteIcon = <CheckCircleIcon color="primary" />;

export default function EntityDetailTimeline(props) {
  const {
    timelineEntries = [
      { key: 'Clone Management', isLevelCompleted: true, subtitle: '21 OCT -- 21 JAN', withSubtitle: true },
      { key: 'sleep', defaultExpanded: true },
      { key: 'repeat', withSubtitle: true },
      { key: 'Vegetative', withSubtitle: true, subtitle: 'eh' },
    ],
    timelineItems = undefined,
    currDarkThemeClass,
    levelCompletedIcon = defLevelCompleteIcon, 
    isEnableSeparator = true,
    accordionProps = {}
  } = props;

  const eDTTimelineProps = {
    className: `${timelineClasses?.timeline} ${currDarkThemeClass}`,
  };

  const eDTItemProps = {
    className: `${timelineClasses?.timelineItem} ${currDarkThemeClass}`,
  };

  const eDTSeparatorProps = e => ({
    className: `${timelineClasses?.timelineSeparator} ${e?.isLevelCompleted && timelineClasses?.specialTimelineSeparator} ${currDarkThemeClass}`,
  });

  const eDTDotProps = e => ({
    className: `${timelineClasses?.timelineDot} ${e?.isLevelCompleted && timelineClasses?.specialTimelineDot} ${
      e?.defaultExpanded && timelineClasses?.expandedDot
    } ${currDarkThemeClass}`,
  });

  const eDTConnectorProps = e => ({
    className: `${timelineClasses?.timelineConnector} ${e?.isLevelCompleted && timelineClasses?.specialTimelineConnector} ${currDarkThemeClass}`,
  });

  const eDTAccordionSummaryProps = {
    className: `${timelineClasses?.accordionSummary} ${currDarkThemeClass}`,
  };

  const eDTAccordionDetailsProps = {
    className: `${timelineClasses?.accordionDetail} ${currDarkThemeClass}`,
  };

  const eDTAccordionProps = e => ({
    summary: (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="row" gap={1} width="50%">
          {e?.isLevelCompleted && levelCompletedIcon}
          <Typography>{e?.key}</Typography>
        </Box>
        {e?.withSubtitle && <Typography>{e?.subtitle}</Typography>}
      </Box>
    ),
    detail: e.key,
    currDarkThemeClass,
    // toShowExpandIcon: false,
    accordionSummaryCustomProps: eDTAccordionSummaryProps,
    accordionDetailsCustomProps: eDTAccordionDetailsProps,
    defaultExpanded: e?.defaultExpanded,
    ...accordionProps
  });

  const timelineEntriesWithAccordion = useMemo(
    () =>
      timelineEntries?.map((e, i) => (
        <TimelineItem key={i} {...eDTItemProps}>
          {isEnableSeparator && (
            <TimelineSeparator {...eDTSeparatorProps(e)}>
              <TimelineDot {...eDTDotProps(e)} />
              {i < timelineEntries?.length - 1 && <TimelineConnector {...eDTConnectorProps(e)} />}
            </TimelineSeparator>
          )}
          <TimelineContent>
            <BasicAccordion {...eDTAccordionProps(e)}>
              {e?.children}
            </BasicAccordion>
          </TimelineContent>
        </TimelineItem>
      )),
    [timelineEntries]
  );

  return <Timeline {...eDTTimelineProps}>{timelineItems || timelineEntriesWithAccordion}</Timeline>;
}
