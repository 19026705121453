import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from '@mui/icons-material/Close';
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { popupClasses } from "./constants";

export default function EntityDetailPopup(props) {
    const {
        loading = false,
        modalMaxWidth = '860px',
        onClose,
        onSaveClick = () => console.log('Save Details'),
        onValidateClick = () => console.log('Validate Details'), // New validation handler
        open = false,
        otherEntityModalPaperProps = {},
        toDisableSave = false,
        toShowSave = true,
        toShowValidate = false,  // New prop to toggle Validate button
        headContentText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        saveText = 'Save',
        validateText = 'Validate',
        title = '',
        currDarkThemeClass = '',
        children,
    } = props;

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        if (toShowSave) onSaveClick(event);
    };

    const handleValidate = event => {
        event.preventDefault();
        event.stopPropagation();
        if (toShowValidate) onValidateClick(event);
    };

    const eDPModalProps = {
        className: `${popupClasses?.entityDetail?.modal} ${currDarkThemeClass}`,
        onClose,
        open,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     outline: 0,
        //     '& .MuiBackdrop-root': {
        //         backgroundColor: 'grey.700',
        //     },
        // },
    };

    const eDPPaperProps = {
        // className: 'customScroll',
        className: `${popupClasses?.entityDetail?.paper} ${currDarkThemeClass} customScroll`,
        component: 'form',
        onSubmit: handleSubmit,
        square: true,
        sx: {
        //     backgroundColor: 'primary.100',
        //     maxHeight: '100vh',
        //     overflow: 'auto',
        //     px: '72px', // 72/1.1
        //     py: '64px', // 64/1.1
            maxWidth: modalMaxWidth,
            ...(!headContentText && { width: '100%' }),
        },
        variant: 'none',
        ...otherEntityModalPaperProps,
    };

    const eDPSaveBtnProps = {
        className: `${popupClasses?.entityDetail?.validateBtn} ${currDarkThemeClass}`,
        disabled: toDisableSave || loading || !toShowSave,
        startIcon: loading && <CircularProgress color="success" size={20} />,
        type: 'submit',
    };

    const eDPValidateBtnProps = {
        className: `${popupClasses?.entityDetail?.validateBtn} ${currDarkThemeClass}`, 
        onClick: handleValidate,
        disabled: loading || !toShowValidate,
    };

    return (
        <Modal {...eDPModalProps}>
            <Paper {...eDPPaperProps}>
                <Box className={`${popupClasses?.entityDetail?.closeIconBox} ${currDarkThemeClass}`}>
                    <CloseIcon className={`${popupClasses?.entityDetail?.closeIcon} ${currDarkThemeClass}`} onClick={onClose} />
                </Box>
                <Paper className={`${popupClasses?.entityDetail?.headerPaper} ${currDarkThemeClass}`} square>
                    <Typography className={`${popupClasses?.entityDetail?.titleTypo} ${currDarkThemeClass}`}>{title}</Typography>
                    <Paper className={`${popupClasses?.entityDetail?.headContentPaper} ${currDarkThemeClass}`} square>
                        <Typography className={`${popupClasses?.entityDetail?.headContentTypo} ${currDarkThemeClass}`}>
                            {headContentText}
                        </Typography>
                        <Box>
                            {toShowSave && <Button {...eDPSaveBtnProps} variant="contained">{saveText}</Button>}
                            {toShowValidate && <Button {...eDPValidateBtnProps} style={{marginLeft: "20px", fontWeight: "bolder"}} variant="outlined">{validateText}</Button>}
                        </Box>
                    </Paper>
                </Paper>
                {children}
            </Paper>
        </Modal>
    );
}