import { createContext, useState, useMemo, lazy, Suspense, useCallback } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import Box from "@mui/material/Box";
import commandCenterImage from './assets/CommandCenter.png'
import ExternalDetail from "./components/account/ExternalDetail";
import LoginPage from './components/setup/LoginPage';
import LandingPage from './components/setup/LandingPage';
// import OrganizationSetup from "./components/setup/OrganizationSetup_Old";
import OrganizationSetup from "./components/setup/OrganizationSetup_2";
import SignUpPage from './components/setup/SignUpPage';

import InvitePage from './components/account/InvitePage';
import Organization from './components/account/Organization';
import Profile from './components/account/Profile';
import Settings from './components/account/Settings';

import Farms from './components/farms/Farms';
// import FarmDetail from "./components/farms/FarmDetail"; // Lazy Loading
// import RoomDetail from "./components/farms/RoomDetail"; // Lazy Loading
// import Room from "./components/farms/Room"; // This is a test component; Lazy Loading

// import CreateAliasRoom from "./components/farms/CreateAliasRoom";
// import CreateNewGrowplan from './components/plans/CreateNewGrowplan';
import CropPlanNewAndDetail from "./components/crops/Detail/CropPlanNewAndDetail";

// import CropRun from './components/plans/CropRun';

import Crops from "./components/crops/Crops";
import Logistics from "./components/logistics/Logistics"

import Executive from "./components/farms/Executive";
// import GrowplanDetail from './components/plans/GrowplanDetail';
import PhasePlan from "./components/plans/PhasePlan";
import QualityPlanNewAndDetail from './components/plans/QualityPlanNewAndDetail';

// import BaseWizard from "./components/utility/wizards/BaseWizard";
// import ContainedRoute from "./components/utility/ContainedRoute";
import DashDrawer from "./components/utility/DashDrawer";
import FarmsWizard from "./components/utility/wizards/FarmsWizard";
import PlansWizard from "./components/utility/wizards/PlansWizard";
import RedirectPage from "./components/utility/RedirectPage";
import TaskStatusSnackbar from "./components/utility/feedback/TaskStatusSnackbar";

import { 
    growExecutiveRoute, growPlanGenNewAndDetailRoute, metricPlanGenNewAndDetailRoute, phasePlanGenNewAndDetailRoute, qualityPlanGenNewAndDetailRoute, externalGenNewAndDetailRoute, baseTestRoute,
  DARK_MODE, LIGHT_MODE, DARK_THEME_CLASS, getDarkThemeClass, plansHomeRoute, cropsHomeRoute, cropsHomeWithTabRoute, cropsPlanGenNewAndDetailRoute, cropsRunGenNewAndDetailRoute, plansHomeWithTabRoute, farmsWizardRoute, plansWizardRoute,
  growCommandCenter, configurationsHomeRoute, farmByIdRoute, configurationMetricRunGenNewAndDetailRoute,
  configurationDefaultRoutineNewAndDetailRoute, logisticsHomeRoute, logisticsHomeWithTabRoute
} from "./components/utility/constants";

import getTokenDetails from "./auth/getTokenDetails";

import "./index.css";
import "./scss/styles.css";
import { Configurations, MetricplanDetail } from "./components/configurations";
import ForgotPassword from "./components/setup/ForgotPassword";
import PasswordResetConfirmation from "./components/setup/PasswordResetConfirmation";
import DefaultRoutineDetail from "./components/configurations/Details/DefaultRoutineDetail";
import _MetrcTag from "./components/logistics/Tabs/_MetrcTagsTab";
import Command from "./components/commandcenter/Command";
import FRTLContextProvider, { FRTLContext } from "./context/FRTLContext";


// import useControlledNavigate from "./utility/useControlledNavigate";


// Lazy loading:

// Strategy: Load all top level routes: /dashboard, /farms, /plans etc. Defer detail/inner level components: /farms/farm, /farms/farm/room, etc.

// const CropRun = lazy(() => import('./components/plans/CropRun')); // This works
// const Farms = lazy(() => import('./components/farms/Farms'));
const FarmDetail = lazy(() => import('./components/farms/FarmDetail'));
const RoomDetail = lazy(() => import('./components/farms/RoomDetail'));

// Note: DashDrawer is provided in this manner to prevent rendering animation when moving across routes a/c to design spec


let prevThemeMode = localStorage.getItem('themeMode');

prevThemeMode = prevThemeMode !== LIGHT_MODE && prevThemeMode !== DARK_MODE ? null : prevThemeMode;

const defState = {
  themeMode: prevThemeMode ?? DARK_MODE,
};

if (!prevThemeMode) {
  localStorage.setItem('themeMode', DARK_MODE);
}

export const MetrcKeyContext = createContext(null);
export const NavControlContext = createContext(null);
export const FeedbackContext = createContext(null);


export default function App() {

  let {tokenDetails} = getTokenDetails();

  const [metrcKey, setMetrcKey] = useState(tokenDetails?.metrc_id); // can use values like for navContextValues
  const [tokenChange, setTokenChange] = useState(false);
  const [toOpen, setToOpen] = useState(true);
  const [values, setValues] = useState(defState);

  const handleSetValues = useCallback((prop, value) => setValues(state => ({...state, [prop]: value})), [setValues]);

  // const handleFeedbackClose = useCallback(() => { handleSetValues('feedback', false); handleSetValues('feedbackProps', {}) }, [handleSetValues]);
  const handleFeedbackClose = () => { handleSetValues('feedback', false); handleSetValues('feedbackProps', {}) };

  const navContextValues = {
    contextValue: values?.navContext ?? {},
    setContextValue: value => handleSetValues('navContext', value),
  };

  const feedbackContextValues = {
    contextValue: values?.feedback ?? false,
    setContextValue: (open=false, feedbackProps={}) => { handleSetValues('feedback', open); handleSetValues('feedbackProps', feedbackProps); },
  };

  const gmTheme = useMemo(() => createTheme({
      breakpoints: { 
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536, xxl: 3072 },
      },
      palette: {
        themeMode: values?.themeMode,
        primary: {
          100: '#F7FAEA',
          200: '#E6F1C1',
          300: '#D6E797',
          400: '#C5DE6E',
          500: '#BDD959',
          600: '#B1D23A',
          700: '#819C24',
          800: '#4E5D15',
          900: '#1A1F07',
          dashboard: {
            bg: '#E7F2D5',
            cardBg: '#E9FFC6',
            cardBtn: '#6B9219',
            cardValue: '#413E2E',
            cardTitle: '#496019', // identical to subTitle
            cardTitleMega: '#031628',
            divider: '#4C6E871A',
            forecastKeyTitle: '#AFE33F',
            projectionBg: '#97BF41',
            popupBg: '#070A01',
            popupBgWithOpacity: '#070A01E8',
            title: '#324001',
            subTitle: '#496019',
            white: '#FFFFFF',
          },
        },
        grey: {
          // 100: '#F6F6F4',
          100: '#1A1B19',
          200: '#D9DBD3',
          300: '#BDC0B2',
          400: '#A1A591',
          500: '#6F745F',
          600: '#5D614F',
          700: '#414437',
          800: '#252720',
          900: '#090A08',
        },
        dark: {
          100: '#303130',
          200: '#2E2F2D',
          300: '#2B2C2B',
          400: '#242523',
          '400-00': '#24252300',
          500: '#1F201E',
          600: '#1D1E1C',
          700: '#1A1B19',
          800: '#151614',
          900: '#090A08',
        },
        background: {
          paper: '#E6F1C1',
          default: '#F7FAEA',
        } 
      },
      typography: {
        fontFamily: 'Space Grotesk, sans-serif',
        letterSpacing: '-0.05em', // Not sure if this is being applied
      },
  }), [values?.themeMode]); // 10022023: custom value to bypass createTheme's dark colour palette which is causing side effects that cannot be mitigated without exhaustively changing each page 

  // const test = useToken();

  const dashToggleProps = {
    themeMode: values?.themeMode,
    toOpen,

    handleSetParentValues: handleSetValues,
    setToOpen,
  };

  const dashProfileProps = {
    ...dashToggleProps,
    tokenChange,
  };

  const dashboardDrawerProps = {
    ...dashToggleProps,
    collapseDrawer: values?.collapseDrawer,
  };
  
  const dashboardProps = {
    toOpen,

    handleToggleDashDrawer: () => setValues(state => ({...state, collapseDrawer: !state?.collapseDrawer})),
    handleSetParentValues: handleSetValues,
  };

  const currDarkThemeClass = useMemo(() => getDarkThemeClass(gmTheme), [gmTheme?.palette?.themeMode]);
  // const currDarkThemeClass = useThemeVal(); // 06-04-2023: TODO: Giving incorrect value since this component is not wrapped with ThemeProvider

  const taskStatusSnackbarProps = {
    currDarkThemeClass,
    open: values?.feedback,

    handleClose: handleFeedbackClose,

    ...(values?.feedbackProps ?? {}),
  };

  return (
    <ThemeProvider theme={gmTheme}>

      <Box className={`wrapper-root wrapper ${currDarkThemeClass}`}>
        <BrowserRouter>
            
            <FeedbackContext.Provider value={feedbackContextValues}>
            <NavControlContext.Provider value={navContextValues}>
            <MetrcKeyContext.Provider value={{metrcKey, setMetrcKey}}>
            <FRTLContextProvider>
              
              <Suspense fallback={<DashDrawer {...dashToggleProps} isLazyLoading={true} />}>
         
                  <Routes>
                  <Route path='/' element={<LandingPage />} /> 
                  <Route path='/organization-setup' element={<OrganizationSetup />} />
                  <Route path='/signup' element={<SignUpPage />} />
                  <Route path='/login' element={<LoginPage />} />
                  <Route path='/forget-password' element={<ForgotPassword />} />
                  <Route path='/reset/passsword/:token' element={<PasswordResetConfirmation />} />
                  <Route path='/invite' element={<InvitePage />} /> 
                  <Route path={externalGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><ExternalDetail /></>} />
                  <Route path='/organization' element={<><DashDrawer {...dashToggleProps} /><Organization /></>} />
                  <Route path='/profile' element={<><DashDrawer {...dashProfileProps} /><Profile setTokenChange={setTokenChange}/></>} />
                  <Route path='/settings' element={<><DashDrawer {...dashToggleProps} /><Settings /></>} />
                  
                  <Route path={growExecutiveRoute} element={<><DashDrawer {...dashboardDrawerProps} /><Executive {...dashboardProps} /></>} />
                  <Route path={configurationsHomeRoute} element={<><DashDrawer {...dashboardDrawerProps} /><Configurations /></>} />
                  <Route path='/configurations/farm/:farmid/room/:roomid' element={<><DashDrawer {...dashToggleProps} /><RoomDetail /></>} />
                  <Route path = {configurationMetricRunGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><MetricplanDetail /></>}/>
                  <Route path = {configurationDefaultRoutineNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><DefaultRoutineDetail /></>} />
                                    
                  <Route path='/farms' element={<><DashDrawer {...dashToggleProps} /><Farms /></>} />
                  <Route path= {farmByIdRoute} element={<><DashDrawer {...dashToggleProps} /><FarmDetail /></>} />
                  {/* <Route path='/farms/farm/:farmid/room/:roomid' element={<><DashDrawer {...dashToggleProps} /><RoomDetail /></>} /> */}
                  {/* <Route path={farmsWizardRoute} element={<><DashDrawer {...dashToggleProps} /><FarmsWizard /></>} /> */}
                  <Route path={farmsWizardRoute} element={<><DashDrawer {...dashToggleProps} /><FarmsWizard /></>} />
                  
                  <Route path={phasePlanGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><PhasePlan /></>} />
                  <Route path={qualityPlanGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><QualityPlanNewAndDetail /></>} />
                  <Route path={plansWizardRoute} element={<><DashDrawer {...dashToggleProps} /><PlansWizard /></>} />

                  {/* <Route path={cropsHomeRoute} element={<><DashDrawer {...dashToggleProps} /><CropRun /></>} /> */}
                  <Route path={cropsHomeRoute} element={<><DashDrawer {...dashToggleProps} /><Crops /></>} />
                  <Route path={cropsPlanGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><CropPlanNewAndDetail {...dashToggleProps} /></>} />
                  <Route path={cropsRunGenNewAndDetailRoute} element={<><DashDrawer {...dashToggleProps} /><CropPlanNewAndDetail {...dashToggleProps} /></>} />
                  {/* <Route path={growCommandCenter}  element={<><DashDrawer {...dashToggleProps} /><div><img src={commandCenterImage} alt="Coming Soon" style={{ width: '100%' }}/></div> </>}/> */}
                  <Route path={growCommandCenter}  element={<><DashDrawer {...dashToggleProps} /><Command /> </>}/>

                  <Route path={logisticsHomeRoute} element={<><DashDrawer {...dashToggleProps} /><Logistics /></>} /> 
                  <Route path={logisticsHomeWithTabRoute} element={<><DashDrawer {...dashToggleProps} /><_MetrcTag {...dashToggleProps}/></>} /> 
                  <Route path='*' element={<RedirectPage />} />

                </Routes>
              </Suspense>
            
            </FRTLContextProvider>
            </MetrcKeyContext.Provider>
            </NavControlContext.Provider>
            </FeedbackContext.Provider>
        
        </BrowserRouter>
        {
          values?.feedback && <TaskStatusSnackbar {...taskStatusSnackbarProps} />
        }
      </Box>

    </ThemeProvider>
  );
}
