import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { 
    GrowDashCalendarIcon,
    GrowDashHarvestIcon,
    GrowDashPriceIcon,
    GrowDashCountIcon,
} from "../CustomSvgIcons";

import { GrowDashDoubleFlowerIcon } from "../icons/GrowDashDoubleFlowerIcon";
import { GrowDashLeavesIcon } from "../icons/GrowDashLeavesIcon";
import { GrowDashLightBulbIcon } from "../icons/GrowDashLightBulbIcon";
import { GrowDashWeightScaleIcon } from "../icons/GrowDashWeightScaleIcon";

import { 
    dashMiniCardClasses,
    getDarkThemeClass,
} from "../constants";
import { GrowDashFY2DIcon } from "../icons/GrowDashFY2DIcon";
import { GrowDashTDF2DIcon } from "../icons/GrowDashTDF2DIcon";
import { GrowDashOH2DIcon } from "../icons/GrowDashOH2DIcon";
import { GrowDashDFL2DIcon } from "../icons/GrowDashDFL2DIcon";

const boxHt = 208;
const boxPadding = 14;
const boxWd = 180;
const textWd = boxWd - 2 * boxPadding;
// const textWd = 146;
const iconHt = '48px';
const iconWd = '48px';

const defDescription = 'Description';

const defIcon = props => <Box {...props} />;

const defIconName = "default";

const defOtherIconProps = {
    // className: `${dashMiniCardClasses?.otherIcon}`,
    sx: {
        backgroundColor: '#444444',
        borderRadius: '50%',
        height: iconHt,
        width: iconWd,
    },
};

const defTitle = 'Title';

const defValue = 'Value';

const iconMap = {
    "GrowDashCalendarIcon": GrowDashCalendarIcon,
    "GrowDashCountIcon": GrowDashCountIcon,
    "GrowDashHarvestIcon": GrowDashHarvestIcon,
    "GrowDashPriceIcon": GrowDashPriceIcon,
    
    "GrowDashDoubleFlowerIcon": GrowDashDoubleFlowerIcon,
    "GrowDashLeavesIcon": GrowDashLeavesIcon,
    "GrowDashLightBulbIcon": GrowDashLightBulbIcon,
    "GrowDashWeightScaleIcon": GrowDashWeightScaleIcon,

    "GrowDashFY2DIcon": GrowDashFY2DIcon,
    "GrowDashTDF2DIcon": GrowDashTDF2DIcon,
    "GrowDashOH2DIcon": GrowDashOH2DIcon,
    "GrowDashDFL2DIcon": GrowDashDFL2DIcon,

    "default": defIcon,
};

export default function DashMiniCard(props) {

    const theme = useTheme();

    const {
        description=defDescription,
        icon=defIconName,
        title=defTitle,
        value=defValue,

        otherBoxProps={},
        otherBoxSxProps={},
        otherDescTypoProps={},
        otherDescTypoSxProps={},
        otherDetailBoxSxProps={},
        otherIconProps={},
        otherIconSxProps={},
        otherTitleTypoProps={},
        otherTitleTypoSxProps={},
        otherValueTypoProps={},
        otherValueTypoSxProps={},
    } = props;

    const boxProps = {
        className: `${dashMiniCardClasses?.box} ${getDarkThemeClass(theme)}`,
        sx: {
            ...otherBoxSxProps,
        },
        ...otherBoxProps,
    };

    const iconProps = {
        className: `${dashMiniCardClasses?.icon} ${getDarkThemeClass(theme)}`,
        sx: {
            ...otherIconSxProps,
        },
        ...otherIconProps,
    };

    const detailBoxProps = {
        sx: {
            ...otherDetailBoxSxProps,
        },
    };

    const valueTypoProps = {
        className: `${dashMiniCardClasses?.valueTypo} ${getDarkThemeClass(theme)}`,
        noWrap: true,
        sx: {
            ...otherValueTypoSxProps,
        },
        ...otherValueTypoProps,
    };

    const titleTypoProps = {
        className: `${dashMiniCardClasses?.titleTypo} ${getDarkThemeClass(theme)}`,
        noWrap: true,
        sx: {
            ...otherTitleTypoSxProps,
        },
        ...otherTitleTypoProps,
    };

    const titleDescProps = {
        className: `${dashMiniCardClasses?.descTypo} ${getDarkThemeClass(theme)}`,
        noWrap: true,
        sx: {
            ...otherDescTypoSxProps,
        },
        ...otherDescTypoProps,
    };

    return (
        <Box {...boxProps}>
            {/* {iconMap?.[icon]?.(iconProps) ?? iconMap.default(defOtherIconProps)} */}
            {icon?.(iconProps) ?? iconMap.default(defOtherIconProps)}
            <Box {...detailBoxProps}>
                <Typography {...valueTypoProps}>{value}</Typography>
                <Typography {...titleTypoProps}>{title}</Typography>
                <Typography {...titleDescProps}>{description}</Typography>
            </Box>            
        </Box>
    );
}