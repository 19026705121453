import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { msgClasses } from "../constants";


export default function RowHeaderTypo(props) {
    
    const {
        currDarkThemeClass='',
        showTitleTooltip=true,
        value,
    } = props;


    const typoProps = {
        className: `${msgClasses?.rowHeaderTypo?.typo} ${currDarkThemeClass}`,
    };

    return (
        <Tooltip title={showTitleTooltip ? (value ?? ' ') : ' '}>
            <Typography {...typoProps}>{value ?? ' '}</Typography>
        </Tooltip>
    )
}