import { useEffect, useState } from "react";

import { useTheme } from "@mui/material";

import { getDarkThemeClass } from "../components/utility/constants";


export default function useThemeVal() {
    const theme = useTheme();

    const [themeVal, setThemeVal] = useState(getDarkThemeClass(theme));
    
    useEffect(() => {
        setThemeVal(_ => getDarkThemeClass(theme));

    }, [theme?.palette?.themeMode]);

    return themeVal;
}