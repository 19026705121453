import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, MenuItem, Tooltip, Paper } from "@mui/material";

import BaseWizard from "./BaseWizard";
import useThemeVal from "../../../utility/useThemeVal";
import {
  endpoints,
  NEW,
  farmsHomeWithTabRoute,
  getRoomDetailRoute,
  FARM_WIZARD,
  setWizardDetails,
  farmsHomeWithRoomsTabRoute,
  FARM_WIZARD_TYPE,
  wizardClasses,
  cropClasses,
  miscClasses,
} from "../constants";
import APIService from "../../../api/apiService";
import useToken from "../../../utility/useToken";
import { failsTokenAndSetupCheck } from "../../../checks/setup";
import { DownArrowIcon } from "../CustomSvgIcons";

const wizardType = FARM_WIZARD_TYPE;
const baseWizardStep = 3;
const FARM_WIZARD_VISITED_KEY = 'farm_wizard_visited';

const BLINK_ANIMATION_STYLES = `
  @keyframes blinkingBorder {
    0% {
      border-color: rgba(197, 222, 110, 0);
    }
    50% {
      border-color: rgba(197, 222, 110, 1);
    }
    100% {
      border-color: rgba(197, 222, 110, 0);
   } }`;

const todoMap = [
  { stepTitle: "Add your Org", step: 0 },
  { stepTitle: "Compliance Org Key", step: 1 },
  { stepTitle: "Add your farm", step: 2 },
  { stepTitle: "Select Farm and Add Room", step: 3, linkFn: getRoomDetailRoute, link: farmsHomeWithTabRoute },
];
const stepCount = todoMap.length;

const FarmsWizard = (props) => {
  const [values, setValues] = useState({});
  const [selectedFarmId, setSelectedFarmId] = useState("");
  const [farmList, setFarmList] = useState([]);
  const [attemptedNavigation, setAttemptedNavigation] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(() => {
    return !localStorage.getItem(FARM_WIZARD_VISITED_KEY);
  });
  
  const currDarkThemeClass = useThemeVal();
  const navigate = useNavigate();
  const { token, tokenDetails = {} } = useToken();
  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), []);

  useEffect(() => {
    if (invalidTokenState) return;

    const entity = endpoints?.farm?.list;

    APIService.fetchInstance(entity)
      .then(res => {
        const farms = res?.data?.farms?.results ?? [];
        setFarmList(farms);
        handleSetValues('farms', farms);
      })
      .catch(error => {
        console.error("Error fetching farms:", error);
      });
  }, [invalidTokenState, handleSetValues]);

  useEffect(() => {
    if (isFirstVisit) {
      const timer = setTimeout(() => {
        localStorage.setItem(FARM_WIZARD_VISITED_KEY, 'true');
        setIsFirstVisit(false);
      }, 4000);
      return () => clearTimeout(timer);
    }}, [isFirstVisit]);
    
  useEffect(() => {
    if (attemptedNavigation && selectedFarmId) {
      handleNavigateToRoom();
    }
  }, [attemptedNavigation, selectedFarmId]);

  const handleNavigateToRoom = useCallback(() => {
    const res = {
      action: FARM_WIZARD.ROOM_CREATE,
      execute: true,
    };

    setWizardDetails(wizardType, res);
    const link = getRoomDetailRoute(selectedFarmId, NEW);
    navigate(link);
    setAttemptedNavigation(false);
  }, [selectedFarmId, navigate]);

  const handleFarmChange = (event) => {
    const farmId = event.target.value;
    setSelectedFarmId(farmId);
    const selectedFarm = farmList.find(farm => farm.id === farmId);
    if (selectedFarm) {
      handleSetValues('selectedFarm', selectedFarm);
    }
  };

  const handleExecute = useCallback((executeStep) => {
    if (executeStep === baseWizardStep) {
      if (!selectedFarmId) {
        setAttemptedNavigation(true);
        return false;
      }
      handleNavigateToRoom();
      return true;
    }
    return false;
  }, [selectedFarmId, handleNavigateToRoom]);

  const renderFarmSelection = () => {
    if (farmList.length === 0) {
      return (
        <Paper sx={{ p: 2, bgcolor: theme => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100' }}>
          <p style={{ color: theme => theme.palette.text.secondary }}>
            No farms available. Please add a farm first.
          </p>
        </Paper>
      );
    }

    const MenuItemProps = {
      className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
      focusVisibleClassName: miscClasses?.menuItemFocusDark,
    };

    const MenuTextFieldProps = ({ prop, inputProps = {}, otherSxProps = {}, otherInputProps = {}, ...otherTextProps }) => ({
      autoComplete: 'off',
      className: `${cropClasses?.textField} ${currDarkThemeClass}`,
      inputProps,
      InputProps: {
        ...otherInputProps,
      },
      label: ' ',
      sx: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {...(isFirstVisit && {
              animation: 'blinkingBorder 1s ease-in-out 4',
              borderWidth: '4px',
            }) }},
        maxWidth: '150%',
        ...otherSxProps,
      },
      value: selectedFarmId,
      onChange: handleFarmChange,
      ...otherTextProps,
    });

    const farmArgs = {
      label: 'Select a Farm',
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    };

    return (
      <Stack spacing={2} >
        <TextField {...MenuTextFieldProps(farmArgs)}
        inputProps={{ style: { backgroundColor: 'transparent' }}}
        >
          {farmList.map(farm => (
            <MenuItem key={farm.id} value={farm.id} {...MenuItemProps}>
              <Stack sx={{ width: '80%' }}>
                <Stack direction="row"  alignItems="center">
                  <span style={{ fontWeight: 500 }}>{farm.farm_name} ( {farm.total_metrc_room_mapped} / {farm.room_count} Metric Location ) </span>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </TextField>
        <style>{BLINK_ANIMATION_STYLES}</style>
      </Stack>
    );
  };

  const wizardProps = {
    baseWizardStep,
    renderFarmSelection,
    currDarkThemeClass,
    finishSetupRoute: farmsHomeWithRoomsTabRoute,
    infoTitle: "Farm Setup Completion Progress",
    infoSubTitle: "Select a farm to add rooms to it.",
    stepCount,
    todoMap,
    values,
    selectedFarmId,
    wizardType,
    handleExecuteStep: handleExecute,
    handleSetValues,
    navigate,
    disableComplete: false,
    completeButtonText: selectedFarmId 
      ? `Add Room (${farmList.find(f => f.id === selectedFarmId)?.room_count - farmList.find(f => f.id === selectedFarmId)?.alias_room_count} available)` 
      : "Select a Farm to Add Room",
  };

  return <BaseWizard {...wizardProps} />;
};

export default FarmsWizard;