import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ActualsIcon, ExpectedIcon } from "../CustomSvgIcons";

import { cardClasses } from "../constants";


export default function InProdInvCard(props) {
    
    const {
        actuals='',
        currDarkThemeClass='',
        expected='',
        otherClasses={},
        showFooter=true,
        showActuals=true,
        title='',
        value='',

    } = props;

    const boxProps = {
        className: `${cardClasses?.inProdInv?.box} ${currDarkThemeClass} ${otherClasses?.box ?? ''}`,
    };

    const valueTypoProps = {
        className: `${cardClasses?.inProdInv?.valueTypo} ${currDarkThemeClass} ${otherClasses?.valueTypo ?? ''}`,
    };

    const titleTypoProps = {
        className: `${cardClasses?.inProdInv?.titleTypo} ${currDarkThemeClass} ${otherClasses?.titleTypo ?? ''}`,
    };

    const footerBoxProps = {
        className: `${cardClasses?.inProdInv?.footerBox} ${currDarkThemeClass} ${otherClasses?.footerBox ?? ''}`,
    };

    const expTypoProps = {
        className: `${cardClasses?.inProdInv?.expTypo} ${currDarkThemeClass} ${otherClasses?.expTypo ?? ''}`,
    };
    
    const actualsTypoProps = {
        className: `${cardClasses?.inProdInv?.actualsTypo} ${currDarkThemeClass} ${otherClasses?.actualsTypo ?? ''}`,
    };

    const iconProps = {
        className: `${cardClasses?.inProdInv?.icon} ${currDarkThemeClass} ${otherClasses?.icon ?? ''}`,
    };
    
    
    return (
        <Box {...boxProps}>
            
            <Typography {...valueTypoProps}>{value}</Typography>
            
            <Typography {...titleTypoProps}>{title}</Typography>

            {
                showFooter && 
                <Box {...footerBoxProps}>
                    <Typography {...expTypoProps}>
                        <ExpectedIcon {...iconProps} />
                        Projected: {expected}
                    </Typography>
                    
                    {
                        showActuals && 
                        <Typography {...actualsTypoProps}>
                            <ActualsIcon {...iconProps} />
                            Actuals: {actuals}
                        </Typography>
                    }
                </Box>
            }

        </Box>
    );
}