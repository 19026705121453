import React, { useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsGantt from 'highcharts/modules/gantt';
import HighchartsReact from 'highcharts-react-official';
import Box from '@mui/material/Box';

// Initialize Highcharts Gantt
HighchartsGantt(Highcharts);

export default function PhasePlanGanttChartBar({ phasesData, currDarkThemeClass }) {
  const chartRef = useRef(null);

  if (!phasesData || !phasesData.default_routines || phasesData.default_routines.length === 0) {
    return <h2><div>No phases data available to render.</div></h2>;
  }

  const isDarkTheme = currDarkThemeClass === 'dark-theme'; // Determine if it's a dark theme

  const generateIdFromName = (name) => name.toLowerCase().replace(/\s+/g, '_');
  const allSubPhases = phasesData?.subphases;
  const startDay = Math.min(...allSubPhases?.map(sb => sb.start_day));
  const endDay = Math.max(...allSubPhases?.map(sb => sb.end_day));
  
  // Colors for compliance and non-compliance
  const complianceColor = '#4055a7'; // Green for compliant phases
  const nonComplianceColor = '#CC00FF'; // Red for non-compliant phases

  // Determine top-level phase color based on compliance
  const topLevelColor = phasesData.isCompliance ? complianceColor : nonComplianceColor;

  // Top-level phase using phasesData.name
  const topLevelPhase = {
    name: phasesData.name,
    id: generateIdFromName(phasesData.name),
    start: startDay,
    end: endDay,
    collapsed: true,
    color: topLevelColor, // Set color for the top-level phase based on compliance
    visible: true,
    // milestone: true
  };

  // Iterate over subphases to generate series data and set colors based on is_metrc_compliance
  const seriesData = [
    topLevelPhase, // Include the top-level phase
    ...phasesData?.subphases?.map((e, i, arr) => ({
      name: e.name,
      id: generateIdFromName(e.name), // Generate ID from subphase name
      start: e.start_day,  // Subphase start day
      end: e.end_day,      // Subphase end day
      parent: topLevelPhase.id, // Subphases are children of the top-level phase
      dependency: i > 0 ? generateIdFromName(arr[i - 1].name) : null,
      color: e.is_metrc_compliance ? complianceColor : nonComplianceColor, // Set color based on compliance
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: 'lighter',
          fontSize: '12px',
          color: isDarkTheme ? '#A1A591' : '#000000', // Adjust text color based on theme
          textOutline: 'none', // Remove any text outline for clarity
        },
        formatter: function () {
          // Display the total number of routines for this subphase
          return `Attached Routines: ${e.default_routines ? e.default_routines.length : 0}`;
        },
        align: 'center', // Center the label horizontally in the bar
        verticalAlign: 'middle', // Align vertically in the middle of the bar
        x: 0, // Adjust the position as needed
        y: 0, // Optional: fine-tune vertical positioning if needed
      
      },
    })),
  ];
  topLevelPhase.dataLabels = {
    enabled: true,
    style: {
      fontWeight: 'lighter',
      fontSize: '12px',
      color: isDarkTheme ? '#A1A591' : '#000000', // Adjust text color based on theme
      textOutline: 'none', // Remove any text outline for clarity
    },
    formatter: function () {
      // Display the total number of subphases
      return `Total Subphases: ${phasesData?.subphases?.length || 0} `;
    },
    align: 'left', // Align the label to the left
    verticalAlign: 'middle', // Align vertically to the middle of the bar
    x: 10, // Move the label inside the bar, to the left side, closer to the starting edge
    y: 0, // Optional: fine-tune vertical alignment if needed
  };
  

  // Gantt chart options
  const options = {
    chart: {
      renderTo: 'container',
      styledMode: false, // Set styledMode to false to allow colors to work
      backgroundColor: isDarkTheme ? '#1e1e1e' : '#ffffff', // Chart background based on theme
    },
    title: {
      text: 'Phase Plan Gantt Chart',
      style: {
        color: isDarkTheme ? '#D9DBD3' : '#000000', // Title color based on theme
        fontFamily: 'Space Grotesk, sans-serif', // ✅ Apply font
      },
    },
    tooltip: {
      formatter: function () {
        const startDay = this.point.start !== undefined ? this.point.start : this.x;
        const endDay = this.point.end !== undefined ? this.point.end : this.x2;
        return `<b>${this.point.name}</b><br/>
                Start : Day ${startDay}<br/>
                End : Day ${endDay}<br/>
                Total : Days ${endDay - startDay + 1}`;
      },
      style: {
        color: isDarkTheme ? '#A1A591' : '#000000', // Tooltip text color based on theme
        fontFamily: 'Space Grotesk, sans-serif', // ✅ Apply font
      },
      backgroundColor: isDarkTheme ? '#333333' : '#ffffff', // Tooltip background based on theme
      borderColor: isDarkTheme ? '#ffffff' : '#000000', // Tooltip border color based on theme
    },
    xAxis: [
      {
        type: 'linear',
        tickInterval: 10, // One tick per day
        labels: {
          formatter: function () {
            return `${Math.floor(this.value)}`; // Show the day number (starting from 1)
          },
          step: 1, // Ensure each tick shows a label, no skipping
          align: 'center',
          style: {
            color: isDarkTheme ? '#D9DBD3' : '#000000', // Axis label color based on theme
            fontSize: '14px', // Increase font size here for the x-axis labels
            fontFamily: 'Space Grotesk, sans-serif' ,// ✅ Apply font
            whiteSpace: 'nowrap', // Prevent label text from wrapping
          },
        },
        min: startDay, // Set min to the start day of the data
        max: endDay,   // Set max to the end day of the data
        title: {
          text: 'Days',
          style: {
            fontSize: '16px',
            fontFamily: 'Space Grotesk, sans-serif' ,// ✅ Apply font
            color: isDarkTheme ? '#D9DBD3' : '#000000', // X-axis title color based on theme
          },
        },
        gridLineWidth: 1,
        gridLineColor: isDarkTheme ? '#444444' : '#e6e6e6', // Grid line color based on theme
      }
    ],
    yAxis: {
      title: {
        text: null, // Optional: You can disable the y-axis title
      },
      gridLineWidth: 1,  // Ensure grid lines have some contrast
      gridLineColor: isDarkTheme ? '#444444' : '#e6e6e6', // Grid line color based on theme
      labels: {
        style: {
          color: isDarkTheme ? '#A1A591' : '#000000', // Label color based on theme
          fontFamily: 'Space Grotesk, sans-serif' ,// ✅ Apply font
          fontSize: '14px',
        },
      },
    },
    series: [
      {
        name: 'My Phases',
        data: seriesData, // Use dynamic series data here
        pointPadding: 0.83,  // Reduce padding between bars
        groupPadding: 0.83,
        borderRadius: 15,  
      },
    ],
    credits: {
      enabled: false, // Disable the default Highcharts credits
    },
    scrollbar: {
      enabled: false, // Enable the scrollbar for horizontal navigation
    },
    navigator: {
      enabled: true,
      xAxis: {
        labels: {
          enabled: false,  // Disable labels in the navigator
        },
        min: startDay, // Set the navigator min to the start day of the data
        max: endDay,   // Set the navigator max to the end day of the data
      },
    },
  };

  return (
    <Box id='container' className={isDarkTheme ? 'highcharts-dark' : 'highcharts-light'} sx={{ marginTop: 4 }}>
      <HighchartsReact
        key={Date.now()} // generate a new timestamp every time the component re-renders
        highcharts={Highcharts}
        constructorType='ganttChart'
        options={options}
        ref={chartRef}
      />
    </Box>
  );
};
