import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import bgImage from '../../images/landingPage_916x964.png'
import getTokenDetails from "../../auth/getTokenDetails";

import { 
    landingPageBgColor, lPAccessBtnBgColor, lPAccessBtnTxtColor, lPHeadlineTxtColor, 
    fadeTimingMs, contentMaxWd,
    defaultNoAuthRoute, landingPageRoute, defaultNoAccountRoute, growExecutiveRoute, farmsHomeRoute,
    accountAppLabel, dashboardModelName,
    crudActions,
    landingPageClasses,
    getDarkThemeClass,
 } from '../utility/constants';


const headline = 'More for the Grow.';
const description = 'SmartGrow is a SaaS platform powered by big data designed to help businesses maximize margins and profits through their cannabis cultivation operations.';

// 1

// const lPBoxProps = {
//     sx: {
//         backgroundColor: landingPageBgColor,
//         minHeight: '100vh',
//         minWidth: 'fit-content',
//         width: '100%',
//     },
// };

// const lPNavBoxProps = {
//     sx: {
//         alignItems: 'center',
//         background: 'linear-gradient(178.15deg, rgba(0, 18, 6, 0.5) 1.56%, rgba(255, 255, 255, 0.5) 465.89%, rgba(0, 18, 6, 0.5) 465.89%)',
//         backdropFilter: 'blur(4px)',
//         display: 'flex',
//         height: '70px',
//         justifyContent: 'space-between',
//         // left: 0,
//         maxWidth: `${contentMaxWd}px`,
//         position: 'fixed',
//         top: 0,
//         width: '100%',
//     },
// };

// const lPContentBoxProps = {
//     sx: {
//         alignItems: 'center',
//         display: 'flex',
//         flexDirection: 'column',
//     },
// };

// const lPNavTitleTypoProps = {
//     noWrap: true,
//     sx: {
//         color: '#FFFFFF',
//         fontSize: '14px',
//         fontWeight: 700,
//         lineHeight: '18px',
//         ml: '36px',
//         textTransform: 'uppercase',
//     },
// };

// 2

// const lPHeadlineTypoProps = {
//     sx: {
//         color: lPHeadlineTxtColor,
//         fontSize: '64px',
//         fontWeight: 500,
//         letterSpacing: '-0.055em',
//         lineHeight: 0.92,
//         mt: '127px',
//         textAlign: 'center',
//     },
// };

// const lPDescTypoProps = {
//     sx: {
//         color: lPHeadlineTxtColor,
//         fontSize: '16px',
//         fontWeight: 500,
//         lineHeight: 1.35,
//         mt: '24px',
//         maxWidth: '648px',
//         textAlign: 'center',
//     },
// };

// 3

// const lPBgImageBoxProps = {
//     sx: {
//         mt: '161px',
//     },
// };


export default function LandingPage(props) {

    const theme = useTheme();
    
    const navigate = useNavigate();

    // 1

    const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    const {token, tokenDetails} = getTokenDetails();

    const {permissions=[], is_staff=false} = tokenDetails || '';
    
    const growDashPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${dashboardModelName}`)) ?? [], [token]);
    
    const growDashPerms = Object.fromEntries(Object.keys(crudActions).map(action => [action, is_staff ? true : (growDashPermissions?.includes(`${accountAppLabel}.${crudActions?.[action]}_${dashboardModelName}`) ?? false)]));

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate(landingPageRoute);
    }


    const lPBoxProps = {

        className: `${landingPageClasses?.box} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: landingPageBgColor,
        //     minHeight: '100vh',
        //     minWidth: 'fit-content',
        //     width: '100%',
        // },
    };

    const lPNavBoxProps = {
        className: `${landingPageClasses?.navBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     background: 'linear-gradient(178.15deg, rgba(0, 18, 6, 0.5) 1.56%, rgba(255, 255, 255, 0.5) 465.89%, rgba(0, 18, 6, 0.5) 465.89%)',
        //     backdropFilter: 'blur(4px)',
        //     display: 'flex',
        //     height: '70px',
        //     justifyContent: 'space-between',
        //     // left: 0,
        //     maxWidth: `${contentMaxWd}px`,
        //     position: 'fixed',
        //     top: 0,
        //     width: '100%',
        // },
    };

    const lPContentBoxProps = {
        className: `${landingPageClasses?.contentBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     flexDirection: 'column',
        // },
    };

    const lPNavTitleTypoProps = {
        noWrap: true,
        className: `${landingPageClasses?.navTitleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: '#FFFFFF',
        //     fontSize: '14px',
        //     fontWeight: 700,
        //     lineHeight: '18px',
        //     ml: '36px',
        //     textTransform: 'uppercase',
        // },
    };

    const lPNavAccessBtnProps = ({otherSxProps={}, login, logout, otherNavBtnClass=''}) => ({
        // className: landingPageClasses?.navAccessBtn,
        className: `${landingPageClasses?.navAccessBtn} ${currDarkThemeClass} ${otherNavBtnClass}`,
        onClick: () => logout ? handleLogout() : navigate(login ? defaultNoAuthRoute : defaultNoAccountRoute),
        sx: {
        //     alignItems: 'center',
        //     backgroundColor: lPAccessBtnBgColor,
        //     borderRadius: 0,
        //     color: lPAccessBtnTxtColor,
        //     fontSize: '14px',
        //     fontWeight: 500,
        //     flexGrow: 0,
        //     justifyContent: 'center',
        //     letterSpacing: '-0.01em',
        //     lineHeight: '18px',
        //     minWidth: 'fit-content',
            // ...(ml && {ml}),
            // mr: mr ?? '36px',
        //     px: '16px',
        //     py: '8px',
        //     textTransform: 'none',
        //     whiteSpace: 'nowrap',
        //     '&:hover': {
        //         backgroundColor: lPAccessBtnBgColor,
        //     },
        ...otherSxProps,
        },
        variant: 'contained',
    });

    const lPNavAccessGrowAssistBtnProps = ({otherSxProps={}, otherNavBtnClass=''}) => ({
        // className: landingPageClasses?.navAccessBtn,
        className: `${landingPageClasses?.navAccessBtn} ${currDarkThemeClass} ${landingPageClasses?.loginBtn}`,
        onClick: () => { window.open("http://app.smartgrow.farm/grow-assist/MFzqkoAnyUCE7BTa", '_blank') },
        sx: {
        ...otherSxProps,
        },
        variant: 'contained',
    });

    // 2

    const lPHeadlineTypoProps = {
        className: `${landingPageClasses?.headlineTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: lPHeadlineTxtColor,
        //     fontSize: '64px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.055em',
        //     lineHeight: 0.92,
        //     mt: '127px',
        //     textAlign: 'center',
        // },
    };

    const lPDescTypoProps = {
        className: `${landingPageClasses?.descTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: lPHeadlineTxtColor,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.35,
        //     mt: '24px',
        //     maxWidth: '648px',
        //     textAlign: 'center',
        // },
    };

    const lPAccessBtnProps = {
        // className: landingPageClasses?.accessBtn,
        className: `${landingPageClasses?.accessBtn} ${currDarkThemeClass}`,
        onClick: () => localStorage.token ? ( growDashPerms?.read ? navigate(growExecutiveRoute) : navigate(farmsHomeRoute) ) : navigate(defaultNoAccountRoute),
        // sx: {
        //     alignItems: 'center',
        //     backgroundColor: lPAccessBtnBgColor,
        //     borderRadius: 0,
        //     color: lPAccessBtnTxtColor,
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     flexGrow: 0,
        //     justifyContent: 'center',
        //     letterSpacing: '-0.01em',
        //     lineHeight: '20px',
        //     minWidth: 'fit-content',
        //     mt: '40px',
        //     px: '24px',
        //     py: '14px',
        //     textTransform: 'none',
        //     whiteSpace: 'nowrap',
        //     '&:hover': {
        //         backgroundColor: lPAccessBtnBgColor,
        //     },
        // },
        variant: 'contained',
    };

    // 3

    const lPBgImageBoxProps = {
        className: `${landingPageClasses?.bgImageBox} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '161px',
        // },
    };    

    const loginProps = {
        login: true,
        otherNavBtnClass: landingPageClasses?.loginBtn,
        // otherSxProps: {
        //     '&': {
        //         ml: 'auto',
        //         mr: '16px',
        //     },
        // },
    };

    const logoutProps = {
        logout: true,
    };

    return (
        <Box {...lPBoxProps}>
            <Box {...lPNavBoxProps}>
                <Typography {...lPNavTitleTypoProps}>SMARTGROW</Typography>
                {
                    !localStorage.token ?
                    <>
                        <Button {...lPNavAccessBtnProps(loginProps)}>Login</Button>
                        <Button {...lPNavAccessBtnProps({})}>Request Access</Button>
                    </>
                    : <>
                        <Button {...lPNavAccessGrowAssistBtnProps({})}>Grow Assist</Button>
                        <Button {...lPNavAccessBtnProps(logoutProps)}>Logout</Button>
                    </>
                }
            </Box>

            <Fade in={true} timeout={fadeTimingMs}>
                <Box {...lPContentBoxProps}>
                    <Typography {...lPHeadlineTypoProps}>{headline}</Typography>
                    <Typography {...lPDescTypoProps}>{description}</Typography>

                    <Button {...lPAccessBtnProps}>{localStorage.token ? 'Application' : 'Request Access'}</Button>
                    
                    <Fade in={true} timeout={fadeTimingMs}>
                        <Box {...lPBgImageBoxProps}>
                            <img src={bgImage} alt="Smartgrow Dashboard" />
                        </Box>
                    </Fade>
                </Box>
            </Fade>
        </Box>
    );
}