import React, { useState, useEffect, useContext } from 'react';
import { TextField, MenuItem } from '@mui/material';
import * as XLSX from 'xlsx';
import APIService from '../../../api/apiService';
import { FeedbackContext } from '../../../App';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import { popupClasses } from '../../utility';

export default function MetrcTagExportPopup(props) {
  const { currDarkThemeClass, open, onClose } = props;

  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [loading, setLoading] = useState(false);
  const feedbackCX = useContext(FeedbackContext);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        setLoading(true);
        const farmsResponse = await APIService.fetchInstance('collection/farm/');
        const results = farmsResponse?.data?.farms?.results || [];
        const farmOptions = results.map((farm) => ({
          id: farm?.id,
          name: farm?.farm_name,
        }));
        setFarms(farmOptions);
      } catch (error) {
        console.error('Error fetching farms:', error);
        feedbackCX.setContextValue(true, {
          type: 'error',
          message: 'Failed to load farms.',
        });
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchFarms();
    }
  }, [open, feedbackCX]);

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const formatValue = (value, type) => {
    const typeMapping = {
      medical_plant: 'Medical Plant',
      medical_package: 'Medical Package',
    };

    const statusMapping = {
      used: 'Used',
      received: 'Received',
    };

    if (type === 'type') {
      return typeMapping[value] || value;
    } else if (type === 'status') {
      return statusMapping[value] || value;
    }
    return value;
  };

  const handleExport = async () => {
    if (!selectedFarm) {
      feedbackCX.setContextValue(true, {
        type: 'warning',
        message: 'Please select a farm to export data.',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await APIService.fetchInstance(`metrc-tag-export/?farm_id=${selectedFarm}`);
      const data = response?.data || [];

      if (data.length === 0) {
        feedbackCX.setContextValue(true, {
          type: 'info',
          message: 'No data available for the selected farm.',
        });
        return;
      }

      const exportData = data.map((row) => ({
        Tag: row.tag || '',
        Type: formatValue(row.type, 'type'),
        Status: formatValue(row.status, 'status'), 
        Commissioned: row.commissioned || '',
        Used: row.used_on || '',
      }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Metrc Tags');
      XLSX.writeFile(workbook, `Metrc_Tags_Export_${selectedFarm}.xlsx`);
      
      onClose(); 
    //   feedbackCX.setContextValue(true, {
    //     type: 'success',
    //     message: 'Export successful!',
    //   });
    } catch (error) {
      console.error('Error exporting data:', error);
      feedbackCX.setContextValue(true, {
        type: 'error',
        message: 'Failed to export data.',
      });
    } finally {
      setLoading(false);
    }
  };

  const entityDetailPopupProps = {
    loading,
    modalMaxWidth: '600px',
    onClose,
    open,
    currDarkThemeClass,
    title: "Export Metrc Tags",
    headContentText: "Please select a farm to export Metrc tags.",
    onSaveClick: handleExport,
    toDisableSave: !selectedFarm || loading,
    saveText: loading ? "Exporting..." : "Export"
  };

  const selectFarmProps = {
    select: true,
    label: "Select Farm",
    value: selectedFarm,
    onChange: handleFarmChange,
    fullWidth: true,
    className: `${popupClasses?.createStrain?.textField} ${currDarkThemeClass}`,
  };

  return (
    <EntityDetailPopup {...entityDetailPopupProps}>
      <TextField {...selectFarmProps}>
        <MenuItem value="" disabled>Select Farm</MenuItem>
        {farms.map((farm) => (
          <MenuItem key={farm.id} value={farm.id}>
            {farm.name}
          </MenuItem>
        ))}
      </TextField>
    </EntityDetailPopup>
  );
}
