import { useState, useCallback, useEffect, useMemo } from 'react';
import {useSearchParams } from 'react-router-dom';
import { Box,  CircularProgress , Button } from '@mui/material';
import './Command.scss';
import CommandView from './CommandView';
import useThemeVal from '../../utility/useThemeVal';
import {commandHomeEndpoint} from '../utility/constants';
import apiService from '../../api/apiService';
import { useToken } from '../../utility';
import { failsTokenAndSetupCheck } from '../../checks/setup';
import CropRunTab from './Tabs/CropRunTab';


export default function Command() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); // Fetched data state
  const [statusChoices, setStatusChoices] = useState([]); // Store API response
  const [tableData, setTableData] = useState([]);
  const[phaseDetails,setPhaseDetails] = useState([]);
  const [error, setError] = useState(null); // Error state
  const [open, setOpen] = useState(false);
  const currDarkThemeClass = useThemeVal();
  const [tabValue, setTabValue] = useState('CropRun');
  const [searchParams, setSearchParams] = useSearchParams('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // ✅ State for Drawer
  const [drawerData, setDrawerData] = useState(null); // ✅ Store fetched sub-phase details
  
  const [selectedStates, setSelectedStates] = useState(["Active", "Planning", "Ready For Grow"]); // ✅ Default values

  
  const tab = searchParams.get('tab');

  const { token, tokenDetails} = useToken();

  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue); // Update the selected tab
    setSearchParams({ tab: newValue }); // Update the URL to reflect the current tab

  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(()=>{
    if(invalidTokenState) return;
    const fetchData = async () =>{
      setLoading(true);
      try {

        // const entityWithID = commandHomeEndpoint;
        // // ✅ Correctly format selected states in query params
        // const selectedStatuses = selectedStates;
        const queryParams =   selectedStates.map(s => `status=${encodeURIComponent(s)}`).join("&");
        const url = `${commandHomeEndpoint}?${queryParams}`;
        console.log("🔍 Fetching Data from:", url); // ✅ Debugging the API request

        const response = await apiService.fetchInstance(url);

        if(response && response.data){
          // setData(response.data);
          const { table_data, cropplan_data,status_choices ,phase_plan_details} = response.data;

        setTableData(table_data);  
        setPhaseDetails(phase_plan_details);
        setData(cropplan_data);   
        setStatusChoices(status_choices)

        
        }
        else{
          console.log("No Data Received");
        } 

      }
      catch(error){
        console.error('error fetching data:',error.message)
        setError(error.message);
    }
    finally{
      setLoading(false);
    }
    };
    fetchData();
  },[invalidTokenState,selectedStates]);

  useEffect(() => {
    if (tab) {
      setTabValue(tab); // Ensure `tabValue` matches `tabContentMap` keys
    } else {
      setTabValue('cropRun'); // Default to CropRun
      setSearchParams({ tab: 'cropRun' }); // Update query parameter
    }
  }, [tab]);

  const handleClose = () => {
    setOpen(false);
  };

  const tabs = {
    CROPRUN: 'cropRun',
    COMMANDVIEW: 'commandView',
  
  };
  
  const handleSubphaseClick = async (subphaseId) => {
    console.log(`🛠️ Fetching details for Sub-Phase ID: ${subphaseId}`);

    if (!subphaseId) {
        console.error("❌ Invalid Sub-Phase ID:", subphaseId);
        return;
    }

    try {
        setLoading(true);

        // ✅ Ensure the URL does NOT have duplicate `/api/`
        const baseURL = "command-center/home-details"; // ✅ No leading `/api/`
        const url = `${baseURL}/${subphaseId}/`;

        console.log(`🔍 API Call to: ${url}`); // ✅ Debugging the API URL

        const response = await apiService.fetchInstance(url);
        

        if (!response || !response.data) {
            throw new Error("No response data received from API.");
        }

        // ✅ Store response in state and open the drawer
        setDrawerData(response.data);
        setIsDrawerOpen(true);
        console.log("routineData1", drawerData)

    } catch (error) {
        console.error("❌ API Fetch Error:", error);
        setError("Failed to fetch sub-phase details. Please try again.");
    } finally {
        setLoading(false);
    }
};

  const tabContentMap = {
    cropRun: <CropRunTab 
    data={data} 
    tableData={tableData}  
    statusChoices={statusChoices}
    phaseDetails={phaseDetails}
    selectedStates={selectedStates} // ✅ Pass selectedStates
    setSelectedStates={setSelectedStates} // ✅ Allow updates from child components
    onSubphaseClick={handleSubphaseClick} // ✅ Pass API call function
    drawerData={drawerData}
    />,
    commandView: <CommandView onClose={handleClose} currDarkThemeClass={currDarkThemeClass} />,
  };
  
  return (
    <Box
    className={`customScroll ${currDarkThemeClass}`}
      sx={{
        fontFamily: 'Space Grotesk',
        fontStyle: 'normal',
        color: 'white', 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100vh', // Full screen height
        width: '100%', 
        // marginLeft: '20px',
        // backgroundColor: 'black',  // Add a background color for contrast
        backgroundColor: currDarkThemeClass === 'dark-theme' ? 'black' : 'white', // Adjust colors dynamically
        padding: '20px',
        // overflowX: 'auto',
        overflowY: 'auto', 
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',  // Vertically center the header and buttons
          marginBottom: '20px',
        }}
      >
        {/* Command Center Header */}
        <h1 style={{ color: currDarkThemeClass === 'dark-theme' ? '#D9DBD3' : 'black', marginRight: '20px' , fontSize: '24px',fontWeight: '500',lineHeight: '120%',fontFamily: 'Space Grotesk' }}>Command Center</h1>

        {/* Tab buttons next to the header */}
        <Box
          sx={{
            display: 'flex',
            
            borderRadius: '8px', // Round corners of the entire container
            // overflow: 'hidden',  // Ensure the buttons stay inside the rounded container
            backgroundColor: 'gray', // Set background for the entire box to make the buttons blend in
          }}
        >
          <Button
          onClick={(e) => handleSetTabValue(e, tabs.CROPRUN)}
            sx={{
              // color: '#1A1B19',
              // color: tabValue === tabs.CROPRUN ? '#FFF' : '#1A1B19',
              color: tabValue === tabs.CROPRUN ? '#1A1B19' : '#FFF',
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              fontSize: '14px',
              borderRadius: '10px 0 0 10px',  // Rounded left corner only
              // backgroundColor: '#BDC0B2;',
              // backgroundColor: tabValue === tabs.CROPRUN ? '#414437' : '#BDC0B2', // Active or inactive color
              backgroundColor: tabValue === tabs.CROPRUN ? '#BDC0B2' : '#414437',
            }}
          >
            Crop Run
          </Button>
          <Button
           onClick={(e) => handleSetTabValue(e, tabs.COMMANDVIEW)}
            sx={{
              // color: '#FFF',
              color: tabValue === tabs.COMMANDVIEW ? '#1A1B19' : '#FFF',
              paddingTop: '6px',
              paddingBottom: '6px',
              paddingLeft: '12px',
              paddingRight: '12px',
              fontSize: '14px',
              borderRadius: '0 10px 10px 0',  // Rounded right corner only
              // backgroundColor: '#414437',
              backgroundColor: tabValue === tabs.COMMANDVIEW ? '#BDC0B2' : '#414437', // Active or inactive color
            }}
          >
            Command View
          </Button>
          
        </Box>
        
      </Box>
      <Box>
      <Box
  sx={{
    
    width: '100%', // Full width
    height: '1px',  // Thickness of the line
    position: 'fixed',
    backgroundColor: '#414437',  // Color of the line
    position: 'relative', // Ensure it's part of the document flow
    marginTop: '5px', // Keep it aligned with the top edge of the section
    marginBottom:'20px',
    zIndex: 1, // Ensure it stays on top of content below
  }}
/>
<Box sx={{ flexGrow: 1  }} currDarkThemeClass={currDarkThemeClass}>
  {tabContentMap[tabValue] }
</Box>
</Box>
    </Box>
  );
}