import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SmileyIcon } from '../CustomSvgIcons';
import { miscClasses } from '../constants';

const defToDisable = () => false;
const defHandleCreate = () => console.log('create entity');

export default function NoEntityMsg(props) {
  const {
    msg,
    entity,
    createBtnChildren,
    isLogo = true,
    headContent = 'Oops so empty',
    isHeadContent = true,
    isCreateEnabled = false,
    isAction = true,
    toDisable = defToDisable,
    onCreateClick = defHandleCreate,

    actionBtnsList = [],
    otherHeadContentProps = {},
    otherLogoProps = {},
    otherTypoProps = {},
    otherCreateBtnProps = {},

    currDarkThemeClass = '',
  } = props;

  const noEMBoxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    sx: {
      width: '100%',
    },
  };

  const noEMIconProps = {
    sx: {
      width: '60px',
      height: '60px',
      fill: 'none',
    },
    ...otherLogoProps,
  };

  const noEMHeadProps = {
    sx: {
      color: 'grey.400',
      fontSize: '2.5rem',
      textAlign: 'center',
    },
    ...otherHeadContentProps,
  };

  const noEMTypoProps = {
    sx: {
      color: 'grey.400',
      fontSize: '1.2rem',
      letterSpacing: '-0.05em',
      mx: 'auto',
      textAlign: 'center',
    },
    ...otherTypoProps,
  };

  const createBtnProps = {
    children: createBtnChildren ?? `Add your ${entity ?? 'Data'}`,
    className: `${miscClasses?.noEntityCreateButton} ${currDarkThemeClass}`,
    disabled: toDisable(),
    onClick: onCreateClick,
    variant: 'contained',
    ...otherCreateBtnProps,
  };

  return (
    <Box {...noEMBoxProps}>
      {isLogo && <SmileyIcon {...noEMIconProps} />}
      {isHeadContent && <Typography {...noEMHeadProps}>{headContent}</Typography>}
      <Typography {...noEMTypoProps}>{msg ? msg : `Start by creating your first ${entity ?? 'Data'}. Have fun`}</Typography>
      {isAction && (
        <Box display="flex">
          {isCreateEnabled && <Button {...createBtnProps} />}
          {actionBtnsList}
        </Box>
      )}
    </Box>
  );
}
