import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Box, Fade, MenuItem, Paper, Stack, TextField, Typography, InputAdornment, Button, Collapse, } from '@mui/material';
import { DisplayTable } from '../../utility';
import {
    popupClasses,
    cardClasses,
    metricPlanClasses,
    miscClasses,
    fadeTimingMs,
    checkTempRefOrId,
    NEW,
    taskValuesEntry,
    dashboardClasses
} from '../../utility/constants';
import { CheckboxField } from '../../utility/CheckBoxField';
import {RoutineStepper} from "../../utility/RoutineStepper";

const tableTitleMap = [
    { key: 'dependent_datatype', title: 'Dependent Key Name' },
    { key: 'datatype', title: 'Key Name' },
    { key: 'key', title: 'Value Name' },
    { key: 'isRequired', title: 'Is Required' },
    { key: 'isEditable', title: 'Is Editable' },
    { key: 'isVisible', title: 'Is Visible' },
];

export const getTaskValuesByLevel = (taskLevel) => {
    const level = taskLevel;

    const taskValues = taskValuesEntry.map(taskValue => ({
        ...taskValue,
        tempRef: `temp_${Date.now()}_${taskValue.datatype}`,  // Add tempRef to each task value
    }));

    switch (level) {
        case 4:
            return [];
        case 0:
            return taskValues.slice(0, 4);
        case 1:
            return taskValues.slice(0, 2);
        case 2:
            return taskValues.slice(0, 6);
        case 3:
            return taskValues.slice(6,8);
        default:
            return [];
    }
};

const taskValueTableTitles = tableTitleMap.map(e => ({ title: e.title }));

export const RoutineTasksTab = (props) => {
    const { values, handleSetValues, currDarkThemeClass, defaultRoutinesPerms, defaultroutineid } = props;

    const [toOpen , setToOpen] = useState(false);

    const handleToggleTable = () => {
        setToOpen(prev => !prev);
    };

    const defaultTaskValue = getTaskValuesByLevel(values?.task_level);
 
    const checkTempRefOrId = (task, identifier) => {
        return task.id === identifier || task.tempRef === identifier;
      };
      
      const handleSetTask = useCallback(
        (task, prop, value) => {
          const updatedTasks = values?.routine_tasks ? [...values.routine_tasks] : [];
      
          const taskIndex = updatedTasks.findIndex(e => 
            checkTempRefOrId(e, task?.id ?? task?.tempRef)
          );
      
          if (taskIndex !== -1) {
            const updatedTask = { ...updatedTasks[taskIndex] };
      
            switch (prop) {
              case 'task_values_remove':
                updatedTask.task_values = Array.isArray(value) ?  [ ...value] : [ ...value];
                break;

              case 'task_values':
                updatedTask.task_values = Array.isArray(value) 
                    ?  [...updatedTask.task_values, ...value]
                    : [...updatedTask.task_values, ...value];
                break;
      
              case 'general_functions':
                updatedTask.general_functions = [{
                  default_task_general_function_orders: value.map((func, index) => ({
                    ...func,
                    priority: index + 1,
                    general_function: func.general_fn?.id,
                    dropdown_description: func.dropdown_description
                  }))
                }];
                break;
      
              case 'default_parent_task':
                updatedTask[prop] = value;
                updatedTask.task_values = updatedTask.task_values?.map(taskValue => ({
                  ...taskValue,
                  dependent_datatype: 'none',
                }));
                break;
      
              case 'task_level':
                updatedTask[prop] = value;
                updatedTask.task_values = getTaskValuesByLevel(value);
                updatedTask.general_functions = values?.defaultgeneralfns?.[0] 
                  ? [{ default_task_general_function_orders: [values.defaultgeneralfns[0]]  }] : null;
                break;
      
              default:
                updatedTask[prop] = value;
            }
      
            updatedTasks[taskIndex] = updatedTask;
          } else {
            updatedTasks[0] = {
              ...updatedTasks[0],
              [prop]: prop === 'task_values' ? (Array.isArray(value) ? value : [value]) : value,
              tempRef: `task_${Date.now()}`,
              general_functions: values?.defaultgeneralfns?.[0] ? [{     default_task_general_function_orders: [values.defaultgeneralfns[0]]   }] : null,
              task_values: defaultTaskValue,
            };
          }
      
          handleSetValues('routine_tasks', updatedTasks);
        },
        [values?.routine_tasks, values?.task_level, values?.defaultgeneralfns, defaultTaskValue]
      );

    const selectedRoutine = values?.routine_tasks?.[0]?.default_parent_task ?? " ";
    const filteredDatatype = values?.defaulttaskvalues?.filter(taskValue => taskValue.default_task_id === selectedRoutine) ?? [];

    const handleSetTaskValues = useCallback(
        (task, task_value, prop, value) => {
            handleSetValues(
                'routine_tasks',
                values?.routine_tasks?.map(e => {
                    // Ensure we're targeting the correct task
                    if (e.id === task?.id || e.tempRef === task?.tempRef) {
                        return {
                            ...e,
                            task_values: e?.task_values?.map(v => {
                                // Specifically target the exact task value
                                if (
                                    (v.id && v.id === task_value?.id) || 
                                    (v.tempRef && v.tempRef === task_value?.tempRef)
                                ) {
                                    // Only update the specific property for this task value
                                    return { ...v, [prop]: value };
                                }
                                return v;
                            }),
                        };
                    }
                    return e;
                })
            );
        },
        [values?.routine_tasks]
    );

    useEffect(() => {
        const currentTask = values?.routine_tasks?.[0];
        if (!currentTask?.default_parent_task) return;
        const fromFrtlTaskValues = currentTask?.task_values?.filter( taskValue => taskValue.datatype === 'from_frtl' );

        if (fromFrtlTaskValues?.length > 0) {
            const toFrtlDatatype = values?.defaulttaskvalues?.find(defaultTaskValue =>  defaultTaskValue.default_task_id === currentTask.default_parent_task && defaultTaskValue.datatype === 'to_frtl');
            if (toFrtlDatatype) {
                fromFrtlTaskValues.forEach(taskValue => { handleSetTaskValues( currentTask,  taskValue,  'dependent_datatype',  toFrtlDatatype.datatype );});
            }
        }
    }, [values?.routine_tasks?.[0]?.default_parent_task, values?.defaulttaskvalues]);

    const handleAddKeyRow = useCallback((paramsArray = [{}]) => {
        handleSetValues(
            'routine_tasks',
            values?.routine_tasks?.map(task => ({
                ...task,
                task_values: [
                    ...task.task_values,
                    ...paramsArray.map((params, index) => ({
                        isRequired: params.isRequired ?? true,
                        isVisible: params.isVisible ?? true,
                        isEditable: params.isEditable ?? true,
                        datatype: params.datatype ?? null,
                        key: params.key ?? null,
                        dependent_datatype: params.dependent_datatype ?? null,
                        tempRef: params.tempRef ?? `temp_${Date.now()}_${task.task_values.length + index}`
                    }))
                ]
            }))
        );
    }, [values?.routine_tasks]);

    const dRDTabTaskTextFieldProps = ({ task, prop, inputProps = {}, otherTextProps = {}, isTaskValue = false, task_value }) => ({
        autoComplete: 'off',
        size: 'small',
        className: `${cardClasses.strainAccordion.textField} ${currDarkThemeClass}`,
        disabled: !defaultRoutinesPerms.update || values?.loading || values?.processing || values?.saving,
        inputProps,
        label: '',
        onChange: ({ target: { value } }) =>
            isTaskValue
                ? handleSetTaskValues(task, task_value, prop, value)
                : handleSetTask(task, prop, value),
        required: true,
        value: values?.[prop] ?? '',
        sx: { minWidth: '200px' },
        ...otherTextProps,
    });

    const dRMenuItemProps = {
        className: `${metricPlanClasses?.menuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: miscClasses?.menuItemFocusDark,
    };

    const taskNameProps = (task) => ({
        prop: 'name',
        task,
        otherTextProps: {
            value: task?.name ?? "",
            InputProps: {
                startAdornment: <InputAdornment position="start">Task Name: </InputAdornment>,
            },
        },
    });

    const assigneeProps = (task) => ({
        prop: 'task_role',
        task,
        otherTextProps: {
            value: task?.task_role ?? [],
            select: true,
            SelectProps: {
                renderValue: (selected) =>
                    values?.assignees
                        ?.filter(e => selected?.includes(e?.id))
                        ?.map(e => e?.name)
                        ?.join(', '),
                multiple: true,
            },
            InputProps: {
                startAdornment: <InputAdornment position="start">Role: </InputAdornment>,
            },
        },
    });

    const dependentRoutinesProps = (task) => ({
        prop: 'default_parent_task',
        task,
        otherTextProps: {
            value: task?.default_parent_task ?? "None",
            select: true,
            required: false,
            disabled: false,
            SelectProps: {
                renderValue: (selected) => {
                    if (selected === 'none') return 'None';
                    const selectedOption = values?.defaulttask?.find(option => option.id === selected);
                    return selectedOption ? selectedOption.name : selected;
                }
            },
            InputProps: {
                startAdornment: <InputAdornment position="start">Dependent Routine: </InputAdornment>,
            },
        }
    });

    const keyProps = (task, task_value) => ({
        prop: 'key',
        isTaskValue: true,
        task,
        task_value,
        otherTextProps: {
            value: task_value?.key ?? '',
            required: false,
            disabled: false,
            // disabled: true,
        },
    });

    const keyOptionsProps = (task, task_value) => ({
        prop: 'datatype',
        isTaskValue: true,
        task,
        task_value,
        otherTextProps: {
            value: task_value?.datatype ?? '',
            select: true,
            required: false,
            SelectProps: {
                renderValue: (selected) => {
                    return selected ? selected.toUpperCase() : '';
                }
            },
            // disabled: defaultroutineid === NEW ? false : true,
        },
    });

    const dependentKeyProps = (task, task_value) => ({
        prop: 'dependent_datatype',
        isTaskValue: true,
        task,
        task_value,
        otherTextProps: {
            value: task_value?.dependent_datatype ?? "NONE",
            select: true,
            required: false,
            disabled: false,
            SelectProps: {
                renderValue: (selected) => {
                    if (selected === 'none') return 'NONE';
                    const options = filteredDatatype.find(option => option.datatype === selected);
                    return options ? options.datatype.toUpperCase() : selected;
                }
            },
        },
    });

    const assigneesList = useMemo(
        () =>
            (values?.assignees ?? [])?.map((e, i) => (
                <MenuItem key={i} value={e?.id} {...dRMenuItemProps}>
                    {e?.name}
                </MenuItem>
            )),
        []
    );

    const dependentRoutineList = useMemo(() => {
        const firstTaskId = values?.routine_tasks?.[0]?.id ?? null;

        return [
            <MenuItem key="none" value={null} {...dRMenuItemProps}>
                None
            </MenuItem>,
            ...(values?.defaulttask ?? [])?.map((e, i) => (
                <MenuItem
                    key={i}
                    value={e?.id}
                    {...dRMenuItemProps}
                    disabled={firstTaskId === e?.id}
                >
                    {e.name}
                </MenuItem>
            )),
        ];
    }, [values?.routine_tasks, values?.defaulttask]);


    const keyOptionsList = useMemo(() => {
        const selectedKeyNames = values?.routine_tasks?.flatMap(task =>
            task?.task_values?.map(v => v.datatype).filter(Boolean)
        ) || [];

        return values?.key_options
            ?.filter(option => !selectedKeyNames.includes(option))
            ?.map((e, i) => (
                <MenuItem key={i} value={e} {...dRMenuItemProps}>
                    {e?.toUpperCase()}
                </MenuItem>
            )) || [];
    }, [values?.routine_tasks, values?.key_options]);


    const createTableEntries = (task, task_value) => {
        const key = <TextField {...dRDTabTaskTextFieldProps(keyProps(task, task_value))} sx={{ minWidth: '350px' }} />;
        const datatype = ( <TextField{...dRDTabTaskTextFieldProps(keyOptionsProps(task, task_value))}select sx={{ minWidth: '250px' }} >{keyOptionsList}</TextField>);
        const dependent_datatype = (task?.default_parent_task || task?.default_parent_task !== null) ? (<TextField {...dRDTabTaskTextFieldProps(dependentKeyProps(task, task_value))} select sx={{ minWidth: '250px' }}>{defaultTaskKeys}</TextField>) : null;
        const isRequired = (<div style={{ paddingLeft: "35px" }}> <CheckboxField value={task_value?.isRequired ?? false} onChange={(checked) => handleSetTaskValues(task, task_value, 'isRequired', checked)} /> </div>);
        const isVisible = (<div style={{ paddingLeft: "35px" }}> <CheckboxField value={task_value?.isVisible ?? false} onChange={(checked) => handleSetTaskValues(task, task_value, 'isVisible', checked)} /> </div>);
        const isEditable = (<div style={{ paddingLeft: "35px" }}> <CheckboxField value={task_value?.isEditable ?? false} onChange={(checked) => handleSetTaskValues(task, task_value, 'isEditable', checked)} /> </div>);
        return { key, datatype, dependent_datatype, isRequired, isEditable, isVisible };
    };

    const getTableTitles = (task) => {
        const baseTitles = [{ title: 'Sl. No' }];

        if (task?.default_parent_task) {
            return [
                ...baseTitles,
                ...taskValueTableTitles,
            ];
        }
        return [
            ...baseTitles,
            ...taskValueTableTitles.slice(1, 6)
        ];
    };

    const getTableEntries = useCallback(
        task => {
            const entries = [];
            if (defaultRoutinesPerms?.read) {
                if (task?.default_parent_task !== null) {
                    task?.task_values?.forEach((value, idx) => {
                        entries.push([
                            `${idx + 1}`.padStart(2, 0),
                            ...tableTitleMap.map(e => {
                                const entry = createTableEntries(task, value);
                                return entry[e.key];
                            }),
                        ]);
                    });
                } else {
                    task?.task_values?.forEach((value, idx) => {
                        entries.push([
                            `${idx + 1}`.padStart(2, 0),
                            ...tableTitleMap.slice(1, 6).map(e => {
                                const entry = createTableEntries(task, value);
                                return entry[e.key];
                            }),
                        ]);
                    });
                }
            }
            return entries;
        },
        [values?.routine_tasks]
    );

    const defaultTaskKeys = useMemo(() => {
        //already selected datatypes
        const selectedDatatypes = values?.routine_tasks?.flatMap(task =>
            task?.task_values?.map(v => v.dependent_datatype).filter(Boolean)
        ) || [];

        return [
            <MenuItem key="none" value={null} {...dRMenuItemProps}>
                NONE
            </MenuItem>,
            ...filteredDatatype
                .filter(e => !selectedDatatypes.includes(e.datatype))
                .map((e, i) => (
                    <MenuItem key={i} value={e.datatype} {...dRMenuItemProps}>
                        {e.datatype.toUpperCase()}
                    </MenuItem>
                ))
        ];
    }, [values, filteredDatatype]);

    const currentTask = values?.routine_tasks?.[0] || {
        name: '',
        task_role: [],
        occurrence: '',
        occurrence_timestamp: '',
        default_parent_task: null
    };

    const tableProps = {
        currDarkThemeClass,
        entity: 'Task value',
        tableTitles: getTableTitles(currentTask),
        tableEntries: getTableEntries(currentTask),
        toShowAddBtn: false,
    };

    const general_fns = values?.defaultgeneralfns;

    const routineStepperProps ={
        currDarkThemeClass,
        values,
        general_fns,
        handleSetValues,
        defaultroutineid,
        handleSetTask,
        handleAddKeyRow,
        handleSetTaskValues
    }

    return (
      <Fade in={true} timeout={fadeTimingMs}>
        <Box>
          <Typography className={`${popupClasses?.routines?.titleTypo} ${currDarkThemeClass}`} style={{ padding: '16px' }}>
            {defaultroutineid === NEW ? `${currentTask.name || 'Create New '} Task` : 'Edit Task Details'}
          </Typography>
          <Paper className={`${popupClasses?.routines?.tasksContainer} ${currDarkThemeClass}`} variant="none" elevation={0} square>
            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              <TextField {...dRDTabTaskTextFieldProps(taskNameProps(currentTask))} />
              <TextField {...dRDTabTaskTextFieldProps(assigneeProps(currentTask))}>{assigneesList}</TextField>
              <TextField {...dRDTabTaskTextFieldProps(dependentRoutinesProps(currentTask))}>{dependentRoutineList}</TextField>
              {/* <Button style={{ width: '150px', fontWeight: 'bolder' }} onClick={handleAddKeyRow}>
                Add Key
              </Button> */}
              <Button style={{ width: '150px', fontWeight: 'bolder' }} onClick={handleToggleTable}>
                {toOpen ? "Hide Table" : "Show Table"}
              </Button>
            </Stack>

        {(values?.is_user_created || defaultroutineid === NEW) &&
              <Box>
                <RoutineStepper {...routineStepperProps}/>
              </Box>
        }

            <Collapse in={toOpen}>
            <Box>
              <DisplayTable {...tableProps} />
            </Box>
            </Collapse>
          </Paper>
        </Box>
      </Fade>
    );
}