import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Box, Button, Chip, Fade, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import DisplayMetrcTagsTable from '../../utility/tables/DisplayMetrcTagsTable';
import APIService from '../../../api/apiService';
import { DetailAndDelBtn, metrcTagsClasses, popupClasses, roomDetailClasses, tableClasses } from '../../utility';
import { FeedbackContext } from '../../../App';
import MetrcTagsEditPopup from '../Popup/MetrcTagsEditPopup';
import SearchIcon from '@mui/icons-material/Search';
import MetrcTagImportBtn from '../Popup/MetrcTagImportBtn';
import MetrcTagExportPopup from '../Popup/MetrcTagExportBtn';

const typeMap = {
  medical_plant: 'Medical Plant',
  medical_package: 'Medical Package',
};

const statusMap = {
  received: 'Received',
  used: 'Used',
};

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

// custom throttle 
function throttle(func, limit) {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

export default function _MetrcTagsTab(props) {
  const { currDarkThemeClass = '',
     ...otherProps } = props;

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const [farms, setFarms] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');

  const searchRef = useRef(null);
  const feedbackCX = useContext(FeedbackContext);

  const debouncedSearch = useCallback(
    debounce((term) => {
      fetchData(0, rowsPerPage, term);
    }, 1500),
    [rowsPerPage]
  );
  
  //not used but kept for later use
  const throttledSearch = useCallback(
    throttle((term) => {
      fetchData(0, rowsPerPage, term);
    }, 1000),
    [rowsPerPage]);

  useEffect(() => {
    return () => {
      if (searchRef.current) {
        clearTimeout(searchRef.current);
      }
    };
  }, []);

  const handleEditClick = (tag) => {
    setSelectedTag(tag);
    setIsEditPopupOpen(true);
  };

  const formatData = (data) => {
    return data.map((item) => [
      item.tag || '',
      typeMap[item.type] || item.type,
      statusMap[item.status] || item.status,
      item.commissioned,
      item.used_on,
      <DetailAndDelBtn
        currDarkThemeClass={currDarkThemeClass}
        toShowDelBtn={false}
        onDetailClick={() => handleEditClick(item)}
      />,
    ]);
  };

  const fetchData = async (page, limit, searchTerm='') => {
    setLoading(true);
    try {
      let url = `metrc-tag/?limit=${limit}&page=${page + 1}`;
      if (selectedFilter !== 'All') {
          url += `&farm_id=${selectedFilter}`;
      }
      if (searchTerm) {
          url += `&tag__icontains=${encodeURIComponent(searchTerm)}`;
      }

      const response = await APIService.fetchInstance(url);
      const formattedData = formatData(response?.data?.results);
      setData(formattedData);
      // setFilteredData(formattedData);
      setTotalCount(response?.data?.count || 0);

      const farmsResponse = await APIService.fetchInstance('collection/farm/');
      const results = farmsResponse?.data?.farms?.results || [];
      const farmOptions = results.map(farm => ({
        id: farm?.id,
        name: farm?.farm_name
      }));
      setFarms(farmOptions);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [ page, rowsPerPage, selectedFilter]);

  const handlePageChange = (newPage, newRowsPerPage) => {
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
  };

  const tagKeyTitleMap = [
    { key: 'tag', title: 'Tag' },
    { key: 'type', title: 'Type' },
    { key: 'status', title: 'Status' },
    { key: 'commissioned', title: 'Commissioned' },
    { key: 'used_on', title: 'Used' },
    { key: 'action', title: ' ' },
  ];

  const tableTitles = tagKeyTitleMap.map((e) => ({ title: e.title }));

  const displayProps = {
    currDarkThemeClass,
    tableEntries: data,
    tableTitles,
    totalCount,
    onPageChange: handlePageChange,
    loading,
    rowsPerPage,
    page,
    entity: 'Metrc Tags',
    ...otherProps,
  };

  const handleTagSave = async (updatedTag) => {
    try {
      const response = await APIService.modifyInstance(`metrc-tag/${updatedTag.id}`, updatedTag);
      setIsEditPopupOpen(false);
      if (response.status === 200) {
        feedbackCX.setContextValue(true, {
          type: 'success',
          message: 'Tag updated successfully.',
        });

        fetchData(page, rowsPerPage);
      }
    } catch (error) {
      setIsEditPopupOpen(false);
      feedbackCX.setContextValue(true, {
        type: 'error',
        message: 'Error updating tag',
      });
      return { success: false };
    }
  };

  const handlePopupClose = () => {
    setIsEditPopupOpen(false);
    setSelectedTag(null);
  };

  const handleSetSelTag = (prop, value) => {
    setSelectedTag(prevTag => ({
      ...prevTag,
      [prop]: value,
    }));
  };

  const handleFilterChange = (farmId) => {
    if (farmId === 'All' || farmId === selectedFilter) {
      setSelectedFilter('All');
    } else {
      setPage(0)
      setSelectedFilter(farmId);
    }
  };

  const metrcTagFilterTypoProps = {
    className: `${metrcTagsClasses?.filterTitleTypo} ${currDarkThemeClass}`,
  };

  const metrcTagFilterProps = {
    direction: 'row',
    spacing: 3,
    alignItems: 'center'
  };

  const sChipsProps = (farm) => ({
    key: farm.id,
    label: farm.name,
    variant: selectedFilter === farm.id ? 'filled' : 'outlined',
    onClick: () => handleFilterChange(farm.id),
    clickable: true,
    className: `${roomDetailClasses?.chip} ${currDarkThemeClass}`
  });

  const metrcTagFilterWrapperProps = {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    }

  const metrcTagFarmChipsList = (
    <Stack direction='row' spacing={1}>
      <Chip
        key="All"
        label="All"
        variant={selectedFilter === 'All' ? 'filled' : 'outlined'}
        onClick={() => handleFilterChange('All')}
        clickable
        className={`${roomDetailClasses?.chip} ${currDarkThemeClass}`}
      />
      {farms.map(farm => (
        <Chip key={farm.id} {...sChipsProps(farm)} />
      ))}
    </Stack>
  );

  const handleImportComplete = () => {
    fetchData(page, rowsPerPage);
  };
  
  const metrcTagsEditPopupProps = {
    currDarkThemeClass,
    open: isEditPopupOpen,
    onClose: handlePopupClose,
    selTag: selectedTag,
    handleSetSelTag: handleSetSelTag,
    handleTagSave: handleTagSave,
    toShowSave: true,
    title: "Edit Tag",
    farms,
    ...otherProps
  }

  const metrcTagImportBtnProps = {
      currDarkThemeClass,
      onImportComplete: handleImportComplete,
      farms: farms
  }

  const handleSensorSearch = ({ target: { value } }) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };
  


  const outlinedInputProps = {
    className: `${roomDetailClasses.filterInput} ${currDarkThemeClass}`,
    size: "small",
    placeholder: "Search using Metrc Tag",
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    onChange: handleSensorSearch,
    value: searchTerm
  };

  const handleExportPopupOpen = () => {
    setIsExportPopupOpen(true);
  };

  const handleExportPopupClose = () => {
    setIsExportPopupOpen(false);
  };

  const metrcTagExportBtnProps = {
        currDarkThemeClass,
        open:isExportPopupOpen,
        onClose:handleExportPopupClose
  }


  return (
    <Fade in={true} timeout={2000}>
      <Box>
      <Stack {...metrcTagFilterWrapperProps}  padding={2}>
        <Stack {...metrcTagFilterProps}>
          <Typography {...metrcTagFilterTypoProps}>Show by Farm:</Typography>
          {metrcTagFarmChipsList}
        </Stack>
        <Stack direction='row' gap={2} sx={{justifyContent: 'flex-end'}}>
            <MetrcTagImportBtn {...metrcTagImportBtnProps}/>
            <Button onClick={handleExportPopupOpen}>Export</Button>
            <MetrcTagExportPopup  {...metrcTagExportBtnProps} />
            <OutlinedInput {...outlinedInputProps}/> {/* Search Input  {...outlinedInputProps}  */}
          </Stack>
      </Stack>
        <Box className={`${tableClasses?.container} ${currDarkThemeClass}`}>
          <DisplayMetrcTagsTable {...displayProps} />
        </Box>
        {isEditPopupOpen && (
            <MetrcTagsEditPopup  {...metrcTagsEditPopupProps} />
  )}
      </Box>
    </Fade>
  );
}