import { useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material";

import { defaultNoAuthRoute, errFeedbackFn, fadeTimingMs, getHandleErrorWithFeedbackCX, profileClasses } from "../utility/constants";

import APIService from "../../api/apiService";
import { FeedbackContext } from "../../App";
import getTokenDetails from "../../auth/getTokenDetails";
import useThemeVal from "../../utility/useThemeVal";
import useToken from "../../utility/useToken";
import { failsTokenAndSetupCheck } from "../../checks/setup";


const keys = ['first_name', 'last_name'];

const dummyData = {
    first_name: 'Rajat',
    last_name: 'Bhansali',
    email: 'rajat.bhansali@cron-labs.com',
    address: 'Bengaluru, India',
    phone_number: '7377680194',
    role: 'Admin',
};


export default function Profile(props) {
    const theme = useTheme();

    const {
        setTokenChange=() => {},
    } = props

    const [savedRes, setSavedRes] = useState({});
    const [values, setValues] = useState({});

    const feedbackCX = useContext(FeedbackContext);

    const navigate = useNavigate();

    const currDarkThemeClass = useThemeVal(theme);

    // let token = localStorage.getItem('token');
    // let tokenDetails = {};

    // try {
    //     tokenDetails = JSON.parse(atob(token?.split('.')?.[1]));
    // } catch {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     token = undefined;
    // }

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));
    const handleSetSavedRes = (prop, value) => setSavedRes(state => ({...state, [prop]: value}));

    // const {token, tokenDetails} = getTokenDetails();
    const {token, tokenDetails} = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    const {user_id, is_staff} = tokenDetails;

    const handleUserRes = res => {
        const {data} = res;
        // console.log('got user data');console.log(data);

        setValues(state => ({
            ...state,
            ...data,
        }));
        setSavedRes(state => ({
            ...state,
            ...data,
        }));
    };

    const handleModifyUserRes = res => {
        const {data} = res;

        const {token} = data;
        
        localStorage.setItem('token', token);
        
        delete data.token;

        handleUserRes({data});

        setTokenChange(state => !state);
    };

    const handleError = err => {
        console.log(err);

        // setValues(state => ({
        //     ...state,
        //     ...dummyData,
        // }));
        // setSavedRes(state => ({
        //     ...state,
        //     ...dummyData,
        // }));

    }

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    const handleFinally = () => {
        handleSetValues('loading', false);
        handleSetValues('saving', false);
    };

    useEffect(() => {
        // if (!token) return;
        if (invalidTokenState) return;

        handleSetValues('loading', true);

        const errConfig = {};

        const entityWithID = `user/${user_id}/`;

        APIService.fetchInstance(entityWithID)
        .then(handleUserRes)
        // .catch(handleError)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

    }, []);

    // DO NOT CALL navigate() in the render path
    // if (!token) {
    //     navigate(defaultNoAuthRoute);
    // }

    if (invalidTokenState) return <></>;

    const profileBoxProps = {
        // className: 'main-content-wrapper customScroll',
        className: `${profileClasses?.box} ${currDarkThemeClass} customScroll`,
        // sx: {
        //     backgroundColor: 'primary.100',
        //     flexGrow: 1,
        //     minHeight: '100vh',
        //     pl: '32px',
        //     pr: '32px',
        //     pt: '60px',
        // },
    };

    const profileWelcomeTypoProps = {
        className: `${profileClasses?.welcomeTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '36px',
        //     fontWeight: 700,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.08,
        //     mt: '12px',
        // },
    };

    const toDisable = () => keys.every(key => values?.[key] === savedRes?.[key]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (toDisable()) return;

        handleSetValues('saving', true);

        const errConfig = {};

        // console.log('values');console.log(values);

        const entityWithID = `user/${user_id}/`;

        APIService.modifyInstance(entityWithID, values)
        .then(handleModifyUserRes)
        // .catch(handleError)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

        // const timerId = setTimeout(() => {
        //     clearTimeout(timerId);
        //     handleSetValues('saving', false);
        // }, 5000);
    }

    const profileFormBoxProps = {
        component: 'form',
        onSubmit: handleSubmit,
        className: `${profileClasses?.formBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        //     mt: '24px', // self assumed
        // },
    };

    const profileFormContentBoxProps = {
        className: `${profileClasses?.formContentBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     // justifyContent: 'space-between',
        // },
    };

    const profileInputSectionBoxProps = {
        className: `${profileClasses?.inputSectionBox} ${currDarkThemeClass}`,
        // sx: {
        //     flexBasis: '70%',
        //     flexShrink: 0,
        //     minWidth: `${detailTextMaxWd}px`,
        //     maxWidth: `${detailTextMaxWd}px`,
        //     mr: '16px',
        // },
    };
    
    const profileSectionBoxProps = {
        className: `${profileClasses?.sectionBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     // maxWidth: `${detailTextMaxWd}px`,
        // },
    };

    const profileTextFieldProps = ({prop, inputProps={}, otherTextProps={}, otherSxProps={}}) => ({
        autoComplete: 'off',
        disabled: values?.loading || values?.saving,
        fullWidth: true,
        // inputProps,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        //     ...inputProps,
        // },
        // InputLabelProps: {
        //     // ...(prop === 'description' && {
        //     //     shrink: true,
        //     // }),
        //     sx: {
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        // InputProps: {
        //     sx: {
        //         borderRadius: 0,
        //         '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
        //             borderColor: 'grey.800',
        //         },
        //     },
        // },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        required: true,
        className: `${profileClasses?.textField} ${currDarkThemeClass}`,
        sx: {
            // color: 'grey.800',
            // mt: '20px',
            ...otherSxProps,
        },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const firstNameProps = {
        inputProps: {},
        otherSxProps: {
            mr: '8px',
            width: 'calc(50% - 8px)',
        },
        otherTextProps: {
            label: 'First Name',
            fullWidth: false,
        },
        prop: 'first_name',
    };

    const lastNameProps = {
        inputProps: {},
        otherSxProps: {
            ml: '8px',
            width: 'calc(50% - 8px)',
        },
        otherTextProps: {
            label: 'Last Name',
            fullWidth: false,
        },
        prop: 'last_name',
    };

    const emailProps = {
        inputProps: {},
        otherSxProps: {},
        otherTextProps: {
            disabled: true,
            label: 'Email',
            onChange: undefined,
            type: 'email',
            value: savedRes?.email ?? 'Unknown',
        },
        // prop: 'email',
    };

    const addressProps = {
        inputProps: {},
        otherSxProps: {},
        otherTextProps: {
            label: 'Address',
        },
        prop: 'address',
    };

    const phoneNumProps = {
        inputProps: {},
        otherSxProps: {},
        otherTextProps: {
            disabled: true,
            label: 'Phone Number',
            onChange: undefined,
            value: 'Coming Soon...',
            // type: 'tel',
        },
        // prop: 'phone_number',
    };


    const profilePhotoSectionBoxProps = {
        className: `${profileClasses?.photoSectionBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     flexBasis: '30%',
        //     flexGrow: 0,
        //     // flexShrink: 0,
        //     // ml: '32px',
        //     minWidth: '244px', // self assumed
        //     maxWidth: '244px', // self assumed
        //     ml: 'auto',
        // },
    };

    const profilePhotoBoxProps = {
        className: `${profileClasses?.photoBox} ${currDarkThemeClass}`,
        // sx: {
        //     // backgroundColor: '#EEEEEEAA',
        //     backgroundColor: 'grey.200',
        //     borderRadius: '50%',
        //     // height: '300px',
        //     // width: '300px',
        //     height: '244px',
        //     width: '100%',
        // },
    };

    const profileContentFooterBoxProps = {
        className: `${profileClasses?.contentFooterBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     mt: '48px',
        // },
    };

    const profileRoleTypoProps = {
        className: `${profileClasses?.roleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     fontSize: '18px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     // mt: '24px',
        // },
    };

    const gDProgressProps = {
        size: 16,
        style: {
            color: theme.palette.grey['400'],
        },
    };

    const saveBtnProps = {
        children: 'Save Details',
        disabled: toDisable() || values?.saving,
        onClick: undefined,
        startIcon: values?.saving && <CircularProgress {...gDProgressProps} />,
        className: `${profileClasses?.saveBtn} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'primary.700',
        //     borderRadius: 0,
        //     color: 'primary.100',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05em',
        //     lineHeight: 1.25,
        //     // mt: '12px', // self assumed
        //     py: '16px',
        //     textTransform: 'none',
        //     width: '244px',
        //     '&:hover': {
        //         backgroundColor: 'primary.700',
        //     },
        // },
        type: 'submit',
        variant: 'contained',
    };


    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...profileBoxProps}>
                <Typography {...profileWelcomeTypoProps}>Welcome {savedRes?.first_name ?? 'User'}</Typography>

                <Box {...profileFormBoxProps}>
                    <Box {...profileFormContentBoxProps}>
                        <Box {...profileInputSectionBoxProps}>
                            <Box {...profileSectionBoxProps}>
                                <TextField {...profileTextFieldProps(firstNameProps)} />
                                <TextField {...profileTextFieldProps(lastNameProps)} />
                            </Box>
                            <TextField {...profileTextFieldProps(emailProps)} />
                            {/* <TextField {...profileTextFieldProps(addressProps)} /> */}
                            <TextField {...profileTextFieldProps(phoneNumProps)} />
                        </Box>

                        <Box {...profilePhotoSectionBoxProps}>
                            <Box {...profilePhotoBoxProps}> {/* Can control img via '& img' */}
                                {/* <img src={savedRes?.src ?? ''} alt="Profile Photo" /> ? - pick up from localStorage or get in response */}
                            </Box>
                        </Box>
                    </Box>

                    <Box {...profileContentFooterBoxProps}>
                        <Typography {...profileRoleTypoProps}>
                            {/* Your {savedRes?.roles_list?.length > 1 ?  'roles are:' : 'role is:'} {is_staff ? 'Admin' : (savedRes?.roles_list?.join(', ') || 'Unknown')} */}
                            {/* Your role is: {savedRes?.role ?? 'User' } */}
                            Your role is: { is_staff ? 'Admin' : 'User' }
                        </Typography>
                        <Button {...saveBtnProps} />                
                    </Box>
                </Box>
            </Box>
        </Fade>
    );
}