import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useThemeVal from '../../../utility/useThemeVal';
import { useSearchParams } from 'react-router-dom';
import { FeedbackContext } from '../../../App';
import {
  BaseNewAndDetail,
  EditIcon,
  ExpandCard,
  NEW,
  PlansDetailInfoCard,
  PlansIcon,
  defErrProps,
  defUndVal,
  defaultRoutineModelName,
  endpoints,
  errFeedbackFn,
  fadeTimingMs,
  landingPageRoute,
  metricPlanClasses,
  miscClasses,
  routineNewAndDetailRoute,
  routinesHomeRoute,
  taskLevelOptions,
} from '../../utility';
import { hasPermissions, useToken } from '../../../utility';
import { failsTokenAndSetupCheck } from '../../../checks/setup';
import APIService from '../../../api/apiService';
import { DefaultRoutineBreadCrumbs } from '../../utility/BreadcrumbLinks';
import { Box, Button, Checkbox, Fade, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { isNotValidDetailPageView } from '../../../checks/detailPage';
import SiteUnderDevMsg from '../../utility/messages/SiteUnderDevMsg';
import PrevNextFab from '../../utility/fabs/PrevNextFab';
import { getRoutinesFabState, handleRoutinesNextStep, handleRoutinesPrevStep } from '../../utility/exec';
import { getTaskValuesByLevel, RoutineTasksTab } from '../Tabs/_RoutinesTasksTab';
import { ScheduleTab } from '../Tabs/_ScheduleRoutineTab';

const tabs = {
  OVERVIEW: 'overview',
  SPECIFICS: 'specifics',
  TASKS: 'tasks',
  SCHEDULE: 'schedule',
  // GENERAL_FUNCTIONS: 'general-function',
};

const tabEntityMap = [
  { key: tabs.OVERVIEW, label: 'Overview', defaultForDetail: true, validForNew: false },
  { key: tabs.SPECIFICS, label: 'Specifics', validForNew: true, defaultForDetail: true, prevTab: null, nextTab: tabs.TASKS, defaultForNew: true },
  { key: tabs.TASKS, label: 'Tasks', validForNew: true, defaultForDetail: true, prevTab: tabs.SPECIFICS, nextTab: tabs.SCHEDULE },
  { key: tabs.SCHEDULE, label: 'Schedule', validForNew: true, defaultForDetail: true, prevTab: tabs.TASKS, nextTab: null },
  // { key: tabs.GENERAL_FUNCTIONS, label: 'General Functions', validForNew: false, defaultForDetail: true },
];

const specificsTabTitle = 'Modify your Routines.';

const handleDefaultRoutineRes = (setValues, setSavedRes) => res => {
  const { data = [] } = res;
  let stateValues = {};
  for (let [key, value] of Object.entries(data)) {
    stateValues = { ...stateValues, [key]: value?.results ?? value };
  }

  setValues(state => ({ ...state, ...stateValues }));
  setSavedRes(state => ({ ...state, ...stateValues }));
};

const handleErrorWithFeedback = feedbackCX => (handleSetValues, errPropsFn) => err => {
  handleSetValues('error', true);
  handleSetValues('loading', true);
  const errProps = errPropsFn?.(err) ?? defErrProps;

  feedbackCX?.setContextValue?.(true, errProps);
};

// overview tab
const OverviewTab = props => {
  const { currDarkThemeClass = '', savedRes = [], defaultRoutinesPerms = [], handleSetChangeTab } = props;

  const dRDOverviewBoxProps = {
    className: `${metricPlanClasses?.overviewBox} ${currDarkThemeClass}`,
  };

  const dRDGenInfoCardProps = {
    currDarkThemeClass,
    cardEntries: [
      { key: 'Name', value: savedRes?.name ?? defUndVal },
      { key: 'Tasks Count', value: savedRes?.tasks_count ?? 0 },
      { key: 'Task Level', value: taskLevelOptions.find(task => task.value === savedRes?.task_level)?.label ?? 'N/A' },
    ],
    cardTitle: 'General Info',
    ...(defaultRoutinesPerms?.update
      ? {
        onClick: () => handleSetChangeTab(tabs.SPECIFICS),
      }
      : {
        toAllowEdit: false,
      }),
    otherBoxProps: {
      gridArea: 'a',
    },
  };

  const dRDDescCardProps = {
    cardEntries: [{ value: savedRes?.default_routine_instruction ?? 'No Instruction Available' }],
    cardTitle: 'Routine Instruction',
    currDarkThemeClass,
    otherBoxProps: {
      gridArea: 'b',
    },
    ...(defaultRoutinesPerms?.update
      ? {
        onClick: () => handleSetChangeTab(tabs.SPECIFICS),
      }
      : {
        toAllowEdit: false,
      }),
  };

  const dRDCardBoxProps = {
    className: `${metricPlanClasses?.cardBox} ${currDarkThemeClass}`,
    sx: {
      gridArea: 'c',
    },
  };

  const dRDCardHeaderBoxProps = {
    className: `${metricPlanClasses?.cardHeaderBox} ${currDarkThemeClass}`,
  };

  const dRDCardHeaderTypoProps = {
    className: `${metricPlanClasses?.cardHeaderTypo} ${currDarkThemeClass}`,
  };

  const dRDAMCEditIconProps = {
    className: `${metricPlanClasses?.attachMetricCycleEditIcon} ${currDarkThemeClass}`,
  };

  const dRDCardHeaderEditBtnProps = {
    children: 'Edit',
    className: `${metricPlanClasses?.cardHeaderEditBtn} ${currDarkThemeClass}`,
    disableRipple: true,
    onClick: () => defaultRoutinesPerms.update && handleSetChangeTab(tabs.TASKS),
    startIcon: <EditIcon {...dRDAMCEditIconProps} />,
  };

  const dRDContentBoxProps = {
    className: `${metricPlanClasses?.contentBox} ${currDarkThemeClass} customScroll`,
  };

  const expandCardProps = e => ({
    actions: [],
    cardIcon: PlansIcon,
    cardTitle: e?.name,
    currDarkThemeClass,
    entries: [{ key: 'Description', value: e?.description ?? 'No Description' }],
  });

  const tasksList = savedRes?.routine_tasks?.map((task, i) => <ExpandCard key={i} {...expandCardProps(task)} />);

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...dRDOverviewBoxProps}>
        <PlansDetailInfoCard {...dRDGenInfoCardProps} />
        <PlansDetailInfoCard {...dRDDescCardProps} />
        <Box {...dRDCardBoxProps}>
          <Box {...dRDCardHeaderBoxProps}>
            <Typography {...dRDCardHeaderTypoProps}>Attached Tasks</Typography>
            {defaultRoutinesPerms?.update && <Button {...dRDCardHeaderEditBtnProps} />}
          </Box>
          <Box {...dRDContentBoxProps}>{tasksList}</Box>
        </Box>
      </Box>
    </Fade>
  );
};

// specifics Tab
const SpecificsTab = props => {
  const { currDarkThemeClass, values, defaultroutineid, handleSetValues, defaultRoutinesPerms } = props;


  const handleTaskLevelChange = (newTaskLevel) => {
    handleSetValues('task_level', newTaskLevel);
    const defaultTaskValue = getTaskValuesByLevel(newTaskLevel);
  
    const updatedTasks = values?.routine_tasks?.map(task => ({
      ...task,
      task_values: defaultTaskValue.map(taskValue => ({
        ...taskValue,
        isRequired: true,
        isEditable: true,
        // key: '',
        dependent_datatype: null
      }))
    })) || [{
      name: '',
      task_role: [],
      occurrence: '',
      occurrence_timestamp: '',
      default_parent_task: null,
      general_functions: [{"default_task_general_function_orders": []}],
      task_values: defaultTaskValue
    }];
  
    handleSetValues('routine_tasks', updatedTasks);
  };

  const dRDGenInfoBoxProps = {
    className: `${metricPlanClasses?.genInfoBox} ${currDarkThemeClass} roomDetailTabsContent`,
  };

  const dRDDTabTitleTypoProps = {
    className: `${metricPlanClasses?.tabTitleTypo} ${currDarkThemeClass}`,
  };

  const dRMenuItemProps = {
    className: `${metricPlanClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const dRDTabTextFieldProps = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
    autoComplete: 'off',
    className: `${metricPlanClasses?.textField} ${currDarkThemeClass}`,
    disabled: !defaultRoutinesPerms.update || values?.loading || values?.processing || values?.saving,
    inputProps,
    label: ' ',
    onChange: ({ target: { value } }) => handleSetValues(prop, value),
    required: true,
    value: values?.[prop] ?? '',

    ...otherTextProps,
  });

  const taskLevelList = useMemo(
    () =>
      taskLevelOptions.map((e, i) => (
        <MenuItem key={i} value={e?.value} {...dRMenuItemProps}>
          {e.label}
        </MenuItem>
      ))
  );

  const handleIsComplianceChange = event => {
    const isChecked = event.target.checked;
    handleSetValues('is_metrc_compliance', isChecked);

  };

  const isComplianceCheckboxProps = {
    checked: values?.is_metrc_compliance,
    onChange: handleIsComplianceChange,
    style: { color: 'white' },
  };

  const isComplianceCheckboxLabelProps = {
    control: <Checkbox {...isComplianceCheckboxProps} />,
    label: <Typography style={{ color: 'white' }}>Is Metrc Compliance</Typography>,
  };

  const configurations = [
    {
      otherTextProps: {
        autoFocus: true,
        label: 'Routine Name',
      },
      prop: 'name',
    },
    {
      otherTextProps: {
        label: 'Routine Level',
        select: true,
        disabled: defaultroutineid !== NEW,
        SelectProps: {
          renderValue: selected => taskLevelOptions.find(option => option.value === selected)?.label || '',
        },
      },
      prop: 'task_level',
    },
    {
      inputProps: {
        sx: {
          p: 0,
        },
      },
      otherTextProps: {
        label: 'Routine Instruction',
        minRows: 6,
        multiline: true,
        placeholder: 'Write a Instruction for your Routine',
        required: false,
      },
      prop: 'default_routine_instruction',
    },
  ];

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box {...dRDGenInfoBoxProps}>
        <Typography {...dRDDTabTitleTypoProps}>{defaultroutineid === NEW ? 'Create Your Routine' : specificsTabTitle}</Typography>
        {configurations.map((e, i) => (
        <TextField key={i} {...dRDTabTextFieldProps(e)} onChange={(event) => {
          if (e.prop === 'task_level') handleTaskLevelChange(event.target.value);
          else handleSetValues(e.prop, event.target.value);
        }}
        select={e.otherTextProps?.select}
        >
        {e.otherTextProps?.select ? taskLevelList : null}
        </TextField>
        ))}
       <FormControlLabel {...isComplianceCheckboxLabelProps} />
      </Box>
    </Fade>
  );
};

export default function DefaultRoutineDetail(props) {

  const [savedRes, setSavedRes] = useState({});
  const [values, setValues] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const feedbackCX = useContext(FeedbackContext);

  const { defaultroutineid } = useParams();

  const currDarkThemeClass = useThemeVal();

  const baseEndpointCheck = defaultroutineid === NEW;

  const tabValue = searchParams.get("tab");

  const navigate = useNavigate();

  const { token, tokenDetails } = useToken();

  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  const showPrevNextFab = useMemo(
    () =>
      // defaultroutineid === NEW &&
      !values?.loading?.fetching &&
      !values?.loading?.processing &&
      baseEndpointCheck &&
      Boolean(tabEntityMap?.find(e => e?.validForNew && e?.key === tabValue)),
    [values?.loading?.fetching, values?.loading?.processing, baseEndpointCheck, tabValue]
  );

  const permissionsList = useMemo(() => hasPermissions([defaultRoutineModelName], tokenDetails), [token]);

  const defaultRoutinesPerms = useMemo(() => permissionsList?.[defaultRoutineModelName] ?? {}, [permissionsList]);

  const baseSaveBtnChildren = defaultroutineid !== NEW && tabValue === tabs.OVERVIEW ? 'Delete Routine' : 'Save Details';

  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

  const handleSetChangeTab = useCallback(value => handleSetValues('changeTab', value), [handleSetValues]);

  const getDefaultTabForEndpoint = useCallback(id => tabEntityMap?.find(tab => (id === NEW ? tab?.defaultForNew : tab?.defaultForDetail))?.key, []);

  const getValidTabForNewEntity = tab =>
    tabEntityMap?.find(val => val?.key === tab && val?.validForNew)?.key ?? tabEntityMap?.find(tab => tab?.defaultForNew)?.key;

  const haveChangesInTabs = useCallback(
    tab => {
      if (tab === tabs.SPECIFICS) {
        return (
          values?.name !== savedRes?.name ||
          values?.task_level !== savedRes?.task_level ||
          values?.default_routine_instruction !== savedRes?.default_routine_instruction ||
          values?.is_metrc_compliance !== savedRes?.is_metrc_compliance
        );
      }

      if (tab === tabs.TASKS) {
        return JSON.stringify(values?.routine_tasks) !== JSON.stringify(savedRes?.routine_tasks);
      }

      if (tab === tabs.SCHEDULE) {
        // if (defaultroutineid === NEW) {
        //   return true;
        // }
        return JSON.stringify(values?.routine_tasks) !== JSON.stringify(savedRes?.routine_tasks);
      }
      return false;
    },
    [values, savedRes]
  );

  const handleSetTabValue = useCallback((event, newValue) => {
    setSearchParams({ tab: newValue });
    handleSetChangeTab(newValue);
  }, [setSearchParams, handleSetChangeTab]);

  const showSaveBtn = useCallback(tab =>
    (defaultroutineid === NEW && [tabs.SCHEDULE].includes(tab)) ||
      ([tabs.SPECIFICS, tabs.SCHEDULE, tabs.TASKS].includes(tab) && haveChangesInTabs(tab)) ||
      (defaultroutineid !== NEW && [tabs.OVERVIEW].includes(tab) && values?.is_user_created === true),
    [haveChangesInTabs]
  );

  const toDisable = useCallback(() => {
    // Send true to disable save else false
    if (defaultroutineid === NEW && tabValue === tabs.SPECIFICS ) return true;
    if (defaultroutineid === NEW && tabValue === tabs.TASKS ) return true;
    // if (tabValue === tabs.SCHEDULE && values?.occurrence !== null ) return true;

    if (defaultroutineid !== NEW && tabValue === tabs.OVERVIEW) return false;

    if (defaultroutineid === NEW ? !defaultRoutinesPerms?.create : !defaultRoutinesPerms?.update) return;

    const specificsKey = ['name', 'default_routine_instruction', 'is_metrc_compliance'];
    const tasksKey = ['name', 'task_role'];
    if (tabValue === tabs.TASKS || tabValue === tabs.SCHEDULE) return false;

    return defaultroutineid === NEW
      ? false
      : specificsKey.every(e => values?.[e] === savedRes?.[e]) && tasksKey.every(e => values?.[e] === savedRes?.[e]);
  }, [values]);

  const handleSave = () => {
    if (defaultroutineid !== NEW && tabValue === tabs.OVERVIEW && values?.is_user_created === true) {
      const entityWithID = endpoints.default_routine.detail(+defaultroutineid);

      APIService.deleteInstance(entityWithID)
        .then(() => {
          feedbackCX.setContextValue(true, {
            type: 'success',
            message: 'Routine deleted successfully.',
            autoHideDuration: 3000,
          });

          navigate(routinesHomeRoute);

        }).catch((error) => {
          handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn({}))(error);
        });
      return;
    }
    if (defaultroutineid === NEW) {
      const entity = "default-routine/";

      const payload = {
        ...values,
        is_user_created: true,
        task_count: values?.routine_tasks?.length || 0
      };

      APIService.createInstance(entity, payload)
        .then((response) => {
          
          const { data: { id } } = response;

          const defRoute = `${routineNewAndDetailRoute}/${id}/?tab=${tabEntityMap[0].key}`;
          feedbackCX.setContextValue(true, {
            type: 'success',
            message: 'Custom Routine Created successfully.',
            autoHideDuration: 3000,
          });

          navigate(defRoute);
        })
        .catch(handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn({})))
        .finally(handleFinally);
    } else {
      handleSetValues('loading', true);

      const entityWithID = endpoints.default_routine.detail(+defaultroutineid);
      let errConfig = {};

      APIService.modifyInstance(entityWithID, values)
        .then(handleDefaultRoutineRes(setValues, setSavedRes))
        .catch(handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn(errConfig)))
        .finally(handleModifyFinally);
    }
  };

 const handleModifyFinally = () => {
     if(defaultroutineid !== NEW){
      const id = values?.id;
    const defRoute = `${routineNewAndDetailRoute}/${id}/?tab=${tabEntityMap[0].key}`;
    navigate(defRoute);
    handleSetValues('loading', false);
    feedbackCX.setContextValue(true, {
      type: 'success',
      message: 'Routine Updated Successfully.',
      autoHideDuration: 3000,
    });
  }
 }

  const handleFinally = () => {
    handleSetValues('loading', false);
  };

  useEffect(() => {
    if (invalidTokenState) return;
    const errConfig = {};

    const fetchNewDeps = () => {
      handleSetValues('loading', true);

      const entity = endpoints.default_routine.new;

      APIService.fetchInstance(entity)
        .then(handleDefaultRoutineRes(setValues, setSavedRes))
        .catch(handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn(errConfig)))
        .finally(handleFinally);
    };
    const fetchDefaultRoutineByID = () => {
      let entityWithID = endpoints.default_routine.detail(+defaultroutineid);

      APIService.fetchInstance(entityWithID)
        .then(handleDefaultRoutineRes(setValues, setSavedRes))
        .catch(handleErrorWithFeedback(feedbackCX)(setValues, errFeedbackFn(errConfig)))
        .finally(handleFinally);
    };

    if (defaultroutineid === NEW) {
      if (!defaultRoutinesPerms?.create) return;

      fetchNewDeps();
      // set default values for new default routine
    } else if (isNotValidDetailPageView(token, defaultroutineid)) return;
    else {
      if (!defaultRoutinesPerms?.read) return;

      fetchNewDeps();
      fetchDefaultRoutineByID();
    }
  }, [defaultroutineid]);

  useEffect(() => {
    if (invalidTokenState || (defaultroutineid === NEW ? !defaultRoutinesPerms?.create : !defaultRoutinesPerms?.read || isNaN(+defaultroutineid))) {
      navigate(landingPageRoute);
    }
  }, [token, defaultroutineid]);

  if (invalidTokenState || (defaultroutineid === NEW ? !defaultRoutinesPerms?.create : !defaultRoutinesPerms?.read || isNaN(+defaultroutineid))) {
    return <></>;
  }

  const breadcrumbsProps = {
    currDarkThemeClass,
    currLink: defaultroutineid === NEW ? values?.name ?? 'Create New Routine' : savedRes?.name ?? 'Unknown',

    navigate,
  };

  const overviewProps = {
    currDarkThemeClass,
    defaultRoutinesPerms,
    defaultroutineid,
    savedRes,
    handleSetChangeTab,
  };

  const specificsProps = {
    currDarkThemeClass,
    values,
    defaultroutineid,
    handleSetValues,
    defaultRoutinesPerms,
  };

  const tasksProps = {
    currDarkThemeClass,
    values,
    handleSetValues,
    defaultroutineid,
    defaultRoutinesPerms,
  };

  const scheduleProps = {
    currDarkThemeClass,
    values,
    savedRes,
    handleSetValues,
    defaultroutineid,
    defaultRoutinesPerms,
  };

  const tabContentMap = {
    [tabs.SPECIFICS]: <SpecificsTab {...specificsProps} />,
    [tabs.TASKS]: <RoutineTasksTab {...tasksProps} />,
    [tabs.OVERVIEW]: <OverviewTab {...overviewProps} />,
    [tabs.SCHEDULE]: <ScheduleTab {...scheduleProps} />,
    [tabs.GENERAL_FUNCTIONS]: <SiteUnderDevMsg />,
  };

  const baseProps = {
    // variables
    baseEntityName: savedRes?.name,
    baseid: defaultroutineid,
    changeTab: values?.changeTab,
    createBaseEntityMsg: "Create New Routine",
    tabEntityMap,
    loading: values?.loading,
    breadcrumbs: <DefaultRoutineBreadCrumbs {...breadcrumbsProps} />,
    tabContentMap,

    currDarkThemeClass,
    // components

    baseEndpointCheck,
    // functions
    getDefaultTabForEndpoint,
    getValidTabForNewEntity,
    handleSave,
    handleSetChangeTab,
    showSaveBtn,
    baseSaveBtnChildren,
    toDisable,

  };

  const fabState = getRoutinesFabState({ defaultroutineid, values, tabValue });

  const prevNextFabProps = {
    currDarkThemeClass,

    ...fabState,

    handleNextStep: handleRoutinesNextStep({ defaultroutineid, tabEntityMap, tabValue, values, handleSetTabValue, handleSetValues }),
    handlePrevStep: handleRoutinesPrevStep({ defaultroutineid, tabEntityMap, tabValue, values, handleSetTabValue, handleSetValues }),
  };

  return (
    <>
      <BaseNewAndDetail {...baseProps} />
      {showPrevNextFab && <PrevNextFab {...prevNextFabProps} />}
    </>
  )
}
