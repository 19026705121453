import React, { useCallback, useContext, useState } from 'react'
import EntityDetailPopup from '../EntityDetailPopup';
import { Box, Fade, MenuItem, TextField } from '@mui/material';
import { NEW, defErrProps, errFeedbackFn, fadeTimingMs, getMetricPlanRoute, miscClasses, popupClasses } from '../constants';
import { DownArrowIcon } from '../CustomSvgIcons';
import APIService from '../../../api/apiService';
import { FeedbackContext } from '../../../App';
import { useNavigate } from 'react-router';


const keys = ['name', 'description', 'run_type', 'day_target', 'day_control', 'start_day'];

const runTypes = [
    { value: 1, label: 'Diminished' },
    { value: 2, label: 'Day Parts' }
];

const synonymsMap = {
    1: {
        dayTarget: 'Start Target',
        dayControl: 'Control',
        nightTarget: 'End Target',
        nightControl: 'Control',
        dayTime: null,
        nightTime: null,
    },

    2: {
        dayTarget: 'Day Target',
        dayControl: 'Day Control',
        nightTarget: 'Night Target',
        nightControl: 'Night Control',
        dayTime: 'Day Time',
        nightTime: 'Night Time'
    }
};

const targetAndControlProps = (label, prop, disable = false) => ({
    otherTextProps: {
        label: label,
        disabled: disable
    },
    inputProps: {
        type: 'number',
        step: 0.01,
        min: 0.00
    },
    prop: prop,
});

const timeControlProps = (label, prop) => ({
    otherTextProps: {
        label: label,
    },
    inputProps: {
        type: 'time',
    },
    prop: prop,
});

const getRunTempRef = runs => `run-${runs.length}`

export default function AddMetricRunPopup(props) {
    const {
        currDarkThemeClass = '',
        runs = [],
        metrc_plan = -1,
        handleAddRun,
        handleSaveRun,
        ...otherProps
    } = props;

    const navigate = useNavigate();

    const feedbackCX = useContext(FeedbackContext);

    const handleErrorWithFeedback = errPropsFn => err => {
        const errProps = errPropsFn?.(err) ?? defErrProps;

        feedbackCX.setContextValue(true, errProps);

    }

    const handleFinally = () => {
        handleSetValues('creating', false);
        handleClosePopup();
    }

    const maxStartDay = runs?.reduce((max, current) => current.start_day > max.start_day ? current : max, runs?.[0])?.start_day ?? 0;

    const [values, setValues] = useState({ metrc_plan: metrc_plan });

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    const toDisable = () => keys.some(key => !values?.[key]);

    const handleMetricPlanCreate = () => {
        if (toDisable()) return;

        handleSetValues('creating', true);

        if (metrc_plan !== NEW) {
            const errConfig = {
                create: true,
                resource: 'Metric Run',
            };

            const entity = 'metricrun/';

            APIService.createInstance(entity, values)
                .then(handleSaveRun)
                // .catch(handleError);
                .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
                .finally(handleFinally);
        }
        else {
            const payload = {
                tempRef: getRunTempRef(runs),
                ...values
            };
            handleAddRun(payload);
            handleClosePopup();
        }
    };

    const cMPTextFieldProps = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
        autoComplete: 'off',
        className: `${popupClasses?.createMetricPlan?.textField} ${currDarkThemeClass}`,
        disabled: values?.loading,
        fullWidth: true,
        inputProps,
        label: ' ',
        required: true,
        value: values?.[prop] ?? '',

        onChange: ({ target: { value } }) => handleSetValues(prop, value),

        ...otherTextProps,
    });

    const menuItemProps = {
        className: `${popupClasses?.createMetricPlan?.menuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: miscClasses?.menuItemFocusDark,
    };

    const runTypesList = runTypes?.map((e, i) =>
        <MenuItem key={i} value={e.value} {...menuItemProps}>{e.label}</MenuItem>
    ) || [];

    if (runTypesList.length === 0) {
        runTypesList.push(
            <MenuItem key={-1} value={-1} disabled {...menuItemProps}>No Run Types Available</MenuItem>
        );
    }

    const handleClosePopup = () => {
        setValues({});
        otherProps?.onClose?.();
    }

    const entityDetailProps = {
        ...otherProps,

        currDarkThemeClass,
        headContentText: null,
        loading: values?.creating,
        title: 'Create a New Metric Run',
        toDisableSave: toDisable(),
        saveText: 'Create Metric Run',

        onClose: handleClosePopup,
        onSaveClick: handleMetricPlanCreate,
    };

    const nameProps = {
        inputProps: {
            minLength: 1,
            maxLength: 255,
        },
        otherTextProps: {
            // autoFocus: true,
            label: 'Name of Run',
        },
        prop: 'name',
    };

    const descProps = {
        inputProps: {
            minLength: 1,
            maxLength: 255,
        },
        otherTextProps: {
            label: 'Description',
            multiline: true,
            placeholder: 'Write a description for your Metric Run',
        },
        prop: 'description',
    };

    const runTypesProps = {
        otherTextProps: {
            label: 'Choose a Run Type',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
        },
        prop: 'run_type',
    };

    const cMPMetricAndTargetTypeBoxProps = {
        className: `${popupClasses?.createMetricPlan?.metricAndTargetTypeBox} ${currDarkThemeClass}`,
    };

    const startDayProps = {
        otherTextProps: {
            label: 'Start Day',
        },
        inputProps: {
            type: 'number',
            min: maxStartDay
        },
        prop: 'start_day',
    };

    const dayTargetProps = targetAndControlProps(synonymsMap[+values?.run_type ?? 1]?.dayTarget, 'day_target');
    const dayControlProps = targetAndControlProps(synonymsMap[+values?.run_type ?? 1]?.dayControl, 'day_control');

    const nightTargetProps = targetAndControlProps(synonymsMap[+values?.run_type ?? 1]?.nightTarget, 'night_target');
    const nightControlProps = targetAndControlProps(synonymsMap[+values?.run_type ?? 1]?.nightControl, 'night_control');

    const dayTimeProps = timeControlProps(synonymsMap[+values?.run_type]?.dayTime, 'day_time');
    const nightTimeProps = timeControlProps(synonymsMap[+values?.run_type]?.nightTime, 'night_time');

    return (
        <EntityDetailPopup {...entityDetailProps}>
            <TextField {...cMPTextFieldProps(nameProps)} />
            <TextField {...cMPTextFieldProps(descProps)} />
            <TextField {...cMPTextFieldProps(startDayProps)} />
            <TextField {...cMPTextFieldProps(runTypesProps)}>{runTypesList}</TextField>
            {
                values?.run_type &&
                <Fade in timeout={fadeTimingMs}>
                    <Box {...cMPMetricAndTargetTypeBoxProps}>
                        <TextField {...cMPTextFieldProps(dayTargetProps)} />
                        <TextField {...cMPTextFieldProps(nightTargetProps)} />
                    </Box>
                </Fade>
            }
            {
                values?.run_type &&
                <Fade in timeout={fadeTimingMs}>
                    <Box {...cMPMetricAndTargetTypeBoxProps}>
                        <TextField {...cMPTextFieldProps(dayControlProps)} />
                        {values?.run_type === 2 && <TextField {...cMPTextFieldProps(nightControlProps)} />}
                    </Box>
                </Fade>
            }
            {
                values?.run_type === 2 &&
                <Fade in timeout={fadeTimingMs}>
                    <Box {...cMPMetricAndTargetTypeBoxProps}>
                        <TextField {...cMPTextFieldProps(dayTimeProps)} />
                        <TextField {...cMPTextFieldProps(nightTimeProps)} />
                    </Box>
                </Fade>
            }

        </EntityDetailPopup>
    )
};