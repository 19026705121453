import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import EntityDetailPopup from '../EntityDetailPopup'
import { popupClasses } from '../constants';
import { MenuItem, TextField } from '@mui/material';
import { DownArrowIcon } from '../CustomSvgIcons';
import { FRTLContext } from '../../../context/FRTLContext';

const keys = ['farm', 'room', 'table', 'level'];

export default function CropRunFRTLPopup(props) {
    const {
        open,
        onClose,
        // isMetrcRoutine,
        selectedFarmId,
        currDarkThemeClass,
        currentRoom,
        ...otherProps
    } = props;

    const [values, setValues] = useState({
    ...otherProps,
});

// const isMetrcRoutine = true;

    const handleSetValues = useCallback((prop, value) => {
        setValues(state => ({ ...state, [prop]: value }))
    }, []);

    const { frtlContextValue, setFrtlContextValue } = useContext(FRTLContext);
    
    const handleFRTLNewDepsRes = useCallback(() => {
        if (!frtlContextValue) return;

        const { farms = [], rooms = [], tables = [], levels = [] } = frtlContextValue;

        if (!currentRoom && !rooms.some(room => room.id === 0)) {
            rooms.unshift({ id: 0, room_name: 'All Rooms', farm_id: 0 });
        }

        if (!tables.some(table => table.id === 0)) { 
         tables.unshift({ id: 0, name: 'All Tables', room_id: 0 });
        }

        if (!levels.some(level => level.id === 0)) { 
            levels.unshift({ id: 0, name: 'All Levels', roomtable_id: 0 });
        }

        const payload = {
            farms,
            rooms,
            tables,
            levels
        };
        if (selectedFarmId) {
            const selectedFarm = farms.find(farm => farm.id === selectedFarmId);
            if (selectedFarm) {
                payload.farm = selectedFarm.id;
            } }

        if (currentRoom) {
            const roomExists = rooms.find(room => room.id === currentRoom.id);
            if (roomExists) { payload.room = roomExists.id;}}
        setValues(state => ({
            ...state,
            ...payload
        }));
    }, [frtlContextValue, selectedFarmId, currentRoom, setFrtlContextValue]);

    useEffect(() => {
        handleFRTLNewDepsRes();
      }, [frtlContextValue, selectedFarmId, handleFRTLNewDepsRes]);


    const toDisable = () => keys.some(key => values?.[key] === 0? false: !values?.[key]);

    const onSaveClick = () => {
        if (toDisable()) return;
        otherProps?.handleEditFrtl(`${values?.farm}.${values?.room}.${values?.table}.${values?.level}`);
        onClose();
    };

    const menuItemProps = value => ({
        className: `${popupClasses?.sensor?.menuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: popupClasses?.sensor?.menuItemFocus,
        value: value,
    }); 

      const farmsList = useMemo(() => {
        let farms = values?.farms || [];
        
        if (selectedFarmId) {
            farms = farms.filter(farm => farm.id === selectedFarmId);
        }

        return  farms?.map((farm, index) => (
            <MenuItem key={index} {...menuItemProps(farm?.id)}>
                {`Farm (${farm?.id}) - ${farm?.farm_name}`}
            </MenuItem> 
        ));
    }, [values?.farms, selectedFarmId]);

    const getFarmDisplay = useMemo(() => {
        if (selectedFarmId && values?.farms) {
            const farm = values.farms.find(f => f.id === selectedFarmId);
            return farm ? `Farm (${farm.id}) - ${farm.farm_name}` : '';
        }
        return '';
    }, [selectedFarmId, values?.farms]);

    const getRoomDisplay = useMemo(() => {
        if (currentRoom && values?.rooms) {
            const room = values.rooms.find(r => r.id === currentRoom);
            return room ? `Room (${room.id}) - ${room.room_name}` : ''; }
        return ''; 
    }, [currentRoom, values?.rooms]);

    const roomsList = useMemo(() => {
        let rooms = values?.rooms || [];
    
        if (values?.farm) {
        rooms = rooms.filter(e => e.id === 0 || e.farm_id === values.farm);
        } else if (selectedFarmId) {
        rooms = rooms.filter(e => e.id === 0 || e.farm_id === selectedFarmId);
        }
    
        return rooms.map((room, index) => (
            <MenuItem key={index} disabled={room.id === 0} {...menuItemProps(room.id)}>
                {room.id !== 0 ? `Room (${room.id}) - ${room.room_name}` : `${room.room_name}`}
            </MenuItem>
        ));
    }, [values?.rooms, values?.farm, selectedFarmId]);

    const tablesList = useMemo(() => {
        let tables = values?.tables;
  
        if (values?.room !== '' && values?.room) {
          tables = tables?.filter(e => e?.id === 0 || e?.room_id === values?.room);
        } else if(values?.room === 0) tables = tables?.slice(0, 1);
  
        return tables?.map((table, index) => (
          <MenuItem key={index} {...menuItemProps(table?.id)}>{table?.id !== 0 ? `Table (${table?.id}) - ${table?.name}` : `${table?.name}`}</MenuItem>
        ));
      }, [values?.tables, values?.room]);
    
    const levelsList = useMemo(() => {
        let levels = values?.levels;
        levels = levels?.filter(level => level?.roomtable_id === values?.table || !level?.id);

        if(values?.room === 0) levels = levels?.slice(0, 1);

        return levels?.map((level, index) => (<MenuItem key={index} {...menuItemProps(level?.id)}>{level?.id !== 0 ? `Level (${level?.id}) - ${level?.name}` : `${level?.name}`}</MenuItem>));
    }, [values?.levels, values?.table, values?.room]);
    

    const aSTextFieldProps = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
        autoComplete: 'off',
        className: `${popupClasses?.sensor?.textField} ${currDarkThemeClass}`,
        disabled: false,
        fullWidth: true,
        inputProps,
        label: ' ',
        onChange: ({ target: { value } }) => handleSetValues(prop, value),
        required: true,
        value: values?.[prop] ?? '',

        ...otherTextProps,
    });

    const EntityDetailPopupProps = {
        open,
        onClose,
        toDisableSave: toDisable(),
        title: 'FRTL Selection',
        headContentText: 'Please select the appropriate FRTL values of your choice',
        currDarkThemeClass,
        onSaveClick
    };

    const fFarmProps = {
        otherTextProps: {
            label: 'Farm',
            select: !selectedFarmId,
            SelectProps: selectedFarmId ? undefined : {
                IconComponent: !!values?.farm ? null : DownArrowIcon,
            },
            value: values?.farm || '',
            disabled: !!values.farm
        },
        prop: 'farm',
    };

    const fRoomProps = {
        otherTextProps: {
            label: 'Room',
            select: !currentRoom,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
            value: values?.room ?? '',

            onChange: ({ target: { value } }) => {
                if(value === 0){
                    handleSetValues('room', value);
                    handleSetValues('table', value);
                    handleSetValues('level', value);
                }
                else{
                    handleSetValues('room', value);
                }
            },
            disabled: !values?.farm,
        },
        prop: 'room'
    };

    const fTableProps = {
        otherTextProps: {
            label: 'Table',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
            value: values?.room === 0 ? 0 : values?.table ?? '',
            onChange: ({ target: { value } }) => {
                if(value === 0){
                    handleSetValues('table', value);
                    handleSetValues('level', value);
                }
                else{
                    handleSetValues('table', value);
                }
            },
            disabled: !values?.room,
        },
        prop: 'table',
    };

    const fLevelProps = {
        otherTextProps: {
            label: 'Level',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
            value: values?.room === 0 || values?.table === 0 ? 0 : values?.level ?? '',
            disabled: !values?.room || !values?.table,
        },
        prop: 'level',
    };

    return (
        <EntityDetailPopup {...EntityDetailPopupProps}>

            {selectedFarmId ? ( <TextField {...aSTextFieldProps({...fFarmProps,
                        otherTextProps: {...fFarmProps.otherTextProps,
                            value: getFarmDisplay,}  })} />
) : (
            <TextField {...aSTextFieldProps(fFarmProps)}>{farmsList}</TextField> )}
            <TextField {...aSTextFieldProps(fRoomProps)}>{roomsList}</TextField>
            <TextField {...aSTextFieldProps(fTableProps)}>{tablesList}</TextField>
            <TextField {...aSTextFieldProps(fLevelProps)}>{levelsList}</TextField>
        </EntityDetailPopup>
    );
}
