// import { useEffect, useRef } from "react";

import Dialog from "@mui/material/Dialog";

import { maxForecastTableWd } from "../constants";

const defOpen = false;
const defOnClose = console.log;

const defChildren = <></>;


export default function BlackPopupBG(props) {
    
    const {
        open=defOpen,
        onClose=defOnClose,
        otherPaperSxProps={},

        children=defChildren
    } = props;

    // const ref = useRef();

    // useEffect(() => {
    //     if (ref?.current) {
    //         const children = Array.from(ref.current?.children);
    //         const container = children?.find?.(e => e?.className?.includes?.('MuiDialog-container'));

    //         if (container) {
    //             container?.classList?.add('customScroll');
    //         }
    //     }
    // });

    const dialogProps = {
        maxWidth: `${maxForecastTableWd}px`,
        open,
        onClose,
        // ref,
        PaperProps: {
            elevation: 0,
            sx: {
                backgroundColor: 'transparent',
                ...otherPaperSxProps,
            },
        },
        scroll: 'body',
        sx: {
            backgroundColor: 'primary.dashboard.popupBgWithOpacity',
            // justifyContent: 'flex-start',
        },
    };

    return (
        <Dialog {...dialogProps}>
            {children}
        </Dialog>
    );
}