import { Fade, Typography, Stack, Paper, Chip } from '@mui/material';
import React, { useContext } from 'react';
import {
  fadeTimingMs,
  BaseDetailCard,
  metricPlanCardClasses,
  getFormattedTableEntry,
  EyeIcon2,
  cropsPlanNewAndDetailRoute,
  DelIcon2,
  cropsRunNewAndDetailRoute,
} from '../../utility';
import NoEntityMsg from '../../utility/messages/NoEntityMsg';
import { useNavigate } from 'react-router';
import APIService from '../../../api/apiService';
import { FeedbackContext } from '../../../App';

export default function _CropRunTab(props) {
  const { values, currDarkThemeClass, cropplanperms, handleErrorWithFeedback, handleSetValues } = props;
  const feedbackCX = useContext(FeedbackContext);

  const navigate = useNavigate();
  console.log(cropplanperms);

  const handleShow = id => () => {
    if (!cropplanperms?.update) {
      handleErrorWithFeedback({ response: { status: 400, data: { text: 'Do Not have permissions to View/update' } } });
      return;
    }
    console.log('id is ', id, `${cropsRunNewAndDetailRoute}/${id}/`);

    navigate(`${cropsRunNewAndDetailRoute}/${id}/`);
  };

  const handleDelete = (id) => () => {
    if (!cropplanperms?.delete) {
      handleErrorWithFeedback({ response: { status: 400, data: { text: 'Do not have permissions to delete crop run' } } });
      return;
    }
    const entityWithID = `cropplan/${id}/`;
    APIService.deleteInstance(entityWithID)
      .then(() => {
        handleSetValues(
          'cropruns',
          values?.cropruns?.filter((plan) => plan?.id !== id)
        )
        feedbackCX.setContextValue(true, {
          type: 'success',
          message: 'Deleted crop run successfully.',
          autoHideDuration: 3000,
        }) })
      .catch((data) => {        
        handleErrorWithFeedback({ response: { status: 510, data: { text: data?.response?.data?.message ? data?.response?.data?.messag : 'Internal Server Error!'}}
        });
      });
  };

  const plansBoxProps = {
    className: `${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`,
    variant: 'square',
    elevation: 0,
  };

  const cdSubHeaderProps = {
    direction: 'row',
    spacing: 2,
    alignItems: 'center',
  };

  const cdSubHeaderTypo = {
    className: `${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`,
    variant: 'subtitle2',
  };

  const subHeader = croprun => {
    const entryTitleValueMap = [
      //   { title: 'Room', key: 'room_name' },
      { title: 'Date Of Serialization', key: 'date_of_serialization', isDate: true },
      //   { title: 'Strain Count', key: 'strain_count' },
      { title: 'Plant Count', key: 'plant_count' },
    ];

    const entries = entryTitleValueMap.map((e, i) => (
      <Typography key={i} {...cdSubHeaderTypo}>
        {e?.title} : {getFormattedTableEntry(croprun, e)}
      </Typography>
    ));

    return <Stack {...cdSubHeaderProps}>{entries}</Stack>;
  };

  const cardActionBtns = croprun => [
    {
      key: 'details',
      title: 'Show Details',
      icon: EyeIcon2,
      otherProps: {
        onClick: handleShow(croprun?.id),
      },
    },
    {
      key: 'delete',
      title: 'Delete Run',
      icon: DelIcon2,
      otherClasses: metricPlanCardClasses.delBtn,
      otherProps: {
        onClick: handleDelete(croprun?.id),
      },
    },
  ];

  const cropPlanCardProps = croprun => ({
    baseEntity: croprun?.name,
    extrasTitle: <Chip label={croprun?.run_status} color="primary" variant="outlined" size="small" />,
    isHeaderClickable: true,
    headerClickHandler: handleShow(croprun?.id ?? croprun?.tempRef),
    noEntityMsg: 'Unknown Cropplan',
    currDarkThemeClass,
    subHeader: subHeader(croprun),
    cardActionBtns: cardActionBtns(croprun),
  });

  const plansCardList =
    values?.cropruns?.length > 0 ? (
      values?.cropruns?.map((e, i) => <BaseDetailCard key={i} {...cropPlanCardProps(e)} />)
    ) : (
      <NoEntityMsg entity="Crop Run"  isCreateEnabled={values?.qualityPlanExists} onCreateClick={() => cropplanperms?.create && navigate(`${cropsRunNewAndDetailRoute}/new/`)} />
    );

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Paper {...plansBoxProps}>{plansCardList}</Paper>
    </Fade>
  );
}
