import SvgIcon from "@mui/material/SvgIcon";

export const GrowDashFY2DIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 100 100">
        <path d="M89.999 83.333H83.332V90H89.999V83.333Z" fill="#5D614F"/>
        <path d="M76.667 76.667H70V83.333H76.667V76.667Z" fill="#5D614F"/>
        <path d="M83.334 70H76.668V76.667H83.334V70Z" fill="#5D614F"/>
        <path d="M46.667 76.667C30.098 76.667 16.667 63.235 16.667 46.667C16.667 30.099 30.098 16.667 46.667 16.667C63.236 16.667 76.667 30.098 76.667 46.667V63.334H83.333V46.667C83.333 26.416 66.917 10 46.667 10C26.417 10 10 26.416 10 46.667C10 66.918 26.416 83.334 46.667 83.334H63.334V76.668L46.667 76.667Z" fill="#5D614F"/>
        <path d="M46.667 23.333C33.78 23.333 23.334 33.779 23.334 46.666C23.334 59.553 33.779 70 46.667 70H70V46.667C70 33.779 59.554 23.333 46.667 23.333ZM63.333 63.333H46.667C37.461 63.333 30 55.872 30 46.667C30 37.462 37.461 30 46.667 30C55.873 30 63.334 37.461 63.334 46.667L63.333 63.333Z" fill="#5D614F"/>
        <path d="M46.668 36.667C41.144 36.667 36.668 41.143 36.668 46.667C36.668 52.191 41.144 56.667 46.668 56.667H56.668V46.667C56.668 41.143 52.191 36.667 46.668 36.667ZM50.001 50H46.668C44.827 50 43.335 48.507 43.335 46.667C43.335 44.827 44.827 43.334 46.668 43.334C48.511 43.334 50.001 44.826 50.001 46.667V50Z" fill="#5D614F"/>
    </SvgIcon>
);