import { useCallback, useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import InProdInvCard from "../utility/cards/InProdInvCard";
import InProdInvTable from "../utility/tables/InProdInvTable";
import StrainDetailInProdInvPopup from "../utility/popups/StrainDetailInProdInvPopup";

import { GoBackIcon, SGDashIcon } from "../utility/CustomSvgIcons";

import { 
    convertWeight, 
    dashboardClasses, 
    endpoints, 
    errFeedbackFn, 
    fadeTimingMs, 
    getDollarFormatNum, getFixedDecimalVal, getHandleErrorWithFeedbackCX, getTimezoneSansLocaleDate, logMsgToConsole } from "../utility/constants";

import useThemeVal from "../../utility/useThemeVal";

import APIService from "../../api/apiService";

import { FeedbackContext } from "../../App";


const title = <>In&#8209;Production Inventory</>;
// const dummyAggregate = [
//     {title: 'Harvest Count', value: 4, showFooter: false},
//     {title: 'Plant Count', value: 351, expected: 450, actuals: 280},
//     {title: 'Revenue', value: '$234,000', expected: '$240,000', actuals: '$234,000'},
// ];

// let dummyHarvestTable = [
//     {
//         tableHeaderContentMap: [
//             // {entryRowTitle: '', content: [ {value: 'ID/Name'}, {value: 'Strain Count'}, {value: 'Plant Count'}, {value: 'Harvest Date'} ], isTableHeader: true},
//             // {entryRowTitle: 'Projected', content: [ {value: 'ID/Name'}, {value: 'Strain Count'}, {value: 'Plant Count'}, {value: 'Harvest Date'} ]},
//             // {entryRowTitle: 'Actuals', content: [ {value: 'ID/Name'}, {value: 'Strain Count'}, {value: 'Plant Count'}, {value: 'Harvest Date'} ]},

//             {entryRowTitle: '', content: [ {value: 'ID/Name'}, {value: 'Strain Count'}, {value: 'Plant Count'}, {value: 'Harvest Date'}, {value: 'Dry Weight'}, {value: 'Wet Weight'}, {value: 'Dry/Wet Coeff'}, {value: 'RTS Date'}, {value: 'Price'}, {value: 'RTS(Amount)'} ], isTableHeader: true},
//             {entryRowTitle: 'Projected', content: [ {value: 'CID-451'}, {value: '5'}, {value: '548'}, {value: '2/12/2024'}, {value: '128'}, {value: '986'}, {value: '0.13'}, {value: '3/10/2024'}, {value: '$1,800'}, {value: '$238,218'}, ]},
//             {entryRowTitle: 'Actuals', content: [ {value: 'HID-23'}, {value: '5'}, {value: '497'}, {value: '2/15/2024'}, {value: '111'}, {value: '795'}, {value: '0.14'}, {value: '3/10/2024'}, {value: '$2,400'}, {value: '$248,218'}, ]},
//         ],
//         title: 'Projected Crop/ Actual Harvest for Crop 16',
//     },
// ];

// let len = 1;

// dummyHarvestTable = dummyHarvestTable.map(e => ({
//      ...e, 
//      columnCount: e?.tableHeaderContentMap?.reduce((res, val) => {
//         // len = (val?.content?.length ?? 0) + 1; // Included entryRowTitle in 1fr
//         len = (val?.content?.length ?? 0); // Excluding entryRowTitle from 1fr since it needs custom space

//         return res < len ? len : res;
//      }, 0)
// }));

const SEE_STRAIN_DETAIL_PROP = 'seeStrainDetails';

const handlePopupClose = (handleSetValues, prop=SEE_STRAIN_DETAIL_PROP) => () => handleSetValues(prop, false);

const RTS_DIFF = 35;

const strainTableHeaderContentMap = [
    { key: 'ID', valueKey: 'strain_name', projValueFn: e => e?.strain?.strain_name, sortPos: 0 },
    
    { key: 'Plant Count', valueKey: 'plant_count', projValueKey: 'planter_size', sortPos: 1, formatFn: getFixedDecimalVal(0) },
    
    { key: 'Harvest Date', valueFn: e => getTimezoneSansLocaleDate(e?.finished), projValueFn: (e, cropplan) => getProjHarvestDate(cropplan), sortPos: 2 },
    
    { key: 'Dry Weight', valueKey: 'total_packaged_weight', projValueFn: e => +e?.planter_size * +e?.strain?.dry_weight, sortPos: 3, formatFn: convertWeight(undefined, undefined, 0) },
    
    { key: 'Wet Weight', valueKey: 'total_wet_weight', projValueFn: e => +e?.planter_size * +e?.strain?.wet_weight, sortPos: 4, formatFn: convertWeight(undefined, undefined, 0) },
    
    { key: 'Dry/Wet Coeff', valueKey: null, valueFn: e => +e?.total_packaged_weight / (+e?.total_wet_weight || 1), projValueFn: e => +e?.strain?.dry_weight / (+e?.strain?.wet_weight || 1), sortPos: 5, formatFn: getFixedDecimalVal(2) },
    
    { key: 'RTS', valueFn: e => getTimezoneSansLocaleDate(e?.ready_to_sell), projValueFn: (e, cropplan) => getProjRTSDate(cropplan), sortPos: 6 },
    
    { key: 'Price (per lb)', valueKey: 'flower_price', projValueFn: e => e?.strain?.flower_price ?? 0, sortPos: 7, formatFn: e => getDollarFormatNum(e, 0) },
    
    { key: 'RTS Amount', valueKey: null, valueFn: e => convertWeight()(+e?.total_packaged_weight ?? 0) * (+e?.flower_price ?? 0), projValueFn: e => convertWeight()(+e?.strain?.dry_weight * +e?.planter_size) * (+e?.strain?.flower_price ?? 0), sortPos: 8, formatFn: e => getDollarFormatNum(e, 0) },
];

strainTableHeaderContentMap.sort((a, b) => a?.sortPos - b?.sortPos);


const handleSeeStrainDetail = (values, handleSetValues, harvestID) => () => {

    const harvest = values?.harvests?.find(e => e?.id === harvestID);

    const strains = [];

    // harvest?.harvest_strainharvests?.filter(e => e?.type === HARVEST_TYPE_INACTIVE)?.forEach(dataVal => {
    harvest?.harvest_strainharvests?.forEach(dataVal => {
        
        const crop = harvest?.cropplan?.crops?.find(e => e?.strain?.strain_name === dataVal?.strain_name);

        const result = {
            tableHeaders: { entryRowTitle: '', content: [], isTableHeader: true },
            projected: { entryRowTitle: 'Projected', content: [] },
            actuals: { entryRowTitle: 'Actuals', content: [] },
        };

        strainTableHeaderContentMap?.forEach(val => {
    
            // Table Headers
            result.tableHeaders.content.push( {value: val?.key} );
    
            // Projected
            // result.projected.content.push( {value: ''} );
            result.projected.content.push( { value: val?.projValueFn?.(crop, harvest?.cropplan) ?? crop?.[val?.projValueKey], formatFn: val?.formatFn } );
            
            // Actuals
            result.actuals.content.push( { value: val?.valueFn?.(dataVal) ?? dataVal?.[val?.valueKey], formatFn: val?.formatFn } );
        })

        const tableHeaderContentMap = Object.values(result).map(e => ({ ...e, content: e?.content?.map( ({value, formatFn}) => ({value: formatFn?.(value) ?? value}) )})); // Cleaning up content values to only contain the object structure that is necessary i.e. [ {value: 'Some Value'}, ... ]

        strains.push({ tableHeaderContentMap, columnCount: strainTableHeaderContentMap?.length, title: '' });
    });

    const otherPopupProps = {
        // strains: dummyHarvestTable?.map(e => ({ ...e, title: '', })), // To be changed with actual data
        strains,
        title: `Projected/ Actual Strains for Harvest ${harvest?.code}`, // TODO: Send as a Typography element rather than with irregularly spaced characters
    };

    handleSetValues(SEE_STRAIN_DETAIL_PROP, true);
    
    handleSetValues('otherPopupProps', otherPopupProps);

}
    
// const tableHeaderContentMap = [
//     { entryRowTitle: 'Header', content: [ {value: 'Value'} ], isTableHeader: true },
// ];


const getStrainCount = e => e?.harvest_strainharvests?.length;

const getProjStrainCount = e => e?.crops?.length ?? 0;

const getPlantCount = (res=0, val) => res + val?.plant_count ?? 0;

const getProjPlantCount = (res, val) => (res ?? 0) + (+val?.planter_size ?? 0);

const getHarvestDate = (res, val) => ( (!res && res !== 0) || res < Date.parse(val?.finished) ) ? val?.finished : res;

const getProjHarvestDate = e => {
    const serDate = new Date(e?.serialization_date);
    const harvestDate = new Date(serDate?.getFullYear?.(), serDate?.getMonth?.(), serDate?.getDate?.() + e?.phaseplan?.end_day);
    
    return getTimezoneSansLocaleDate(harvestDate);
}

const getDryWeight = (res=0, val) => ( res ?? 0 ) + +val?.total_packaged_weight ?? 0;

const getProjDryWeight = (res=0, val) => ( res ?? 0 ) + (+val?.strain?.dry_weight ?? 0) * (+val?.planter_size ?? 0);

const getWetWeight = (res=0, val) => ( res ?? 0 ) + +val?.total_wet_weight ?? 0;

const getProjWetWeight  =(res=0, val) => (res ?? 0) + (+val?.strain?.wet_weight ?? 0) * (+val?.planter_size ?? 0);

const getRTSDate = (res, val) => ( (!res && res !== 0) || res < Date.parse(val?.ready_to_sell) ) ? val?.ready_to_sell : res;

const getProjRTSDate = (cropplan) => {
    const serDate = new Date(cropplan?.serialization_date);
    const projRTSDate = new Date(serDate?.getFullYear?.(), serDate?.getMonth?.(), serDate?.getDate?.() + cropplan?.phaseplan?.end_day + RTS_DIFF);

    return getTimezoneSansLocaleDate(projRTSDate);
}

const getFlowerPrice = (res=0, val) => (res ?? 0) + (+val?.flower_price ?? 0);

const getProjFlowerPrice = (res=0, val) => (res ?? 0) + (+val?.strain?.flower_price ?? 0);

const getRTSAmount = (res=0, val) => (res ?? 0) + (( convertWeight()(+val?.total_packaged_weight) * +val?.flower_price ) ?? 0);

const getProjRTSAmount = (res=0, val) => {
    // (res ?? 0) + getFixedDecimalVal(0)( convertWeight()(+val?.strain?.dry_weight * +val?.planter_size) * +val?.strain?.flower_price)
    
    const value = convertWeight()(+val?.strain?.dry_weight * +val?.planter_size) * +val?.strain?.flower_price;
    
    const roundedVal = getFixedDecimalVal(0)(value);

    return (+res ?? 0) + +roundedVal;
};


const harvestTableHeaderContentMap = [
    { key: 'ID/Name', valueKey: 'code', projValueKey: 'name', sortPos: 0, isAggregate: false, isProjAggregate: false, },

    { key: 'Strain Count', valueKey: null, valueFn: getStrainCount, projValueFn: getProjStrainCount, sortPos: 1, isAggregate: false, isProjAggregate: false, },
    
    // { key: 'Plant Count', valueKey: null, aggregateKey: 'plant_count', valueFn: (res=0, val) => res + val?.plant_count, sortPos: 2, isAggregate: true  },
    { key: 'Plant Count', valueKey: null, aggregateKey: 'plant_count', valueFn: getPlantCount, projValueFn: getProjPlantCount, sortPos: 2, isAggregate: true, isProjAggregate: true },
    
    { key: 'Harvest Date', valueKey: null, aggregateKey: 'harvest_date', valueFn: getHarvestDate, projValueFn: getProjHarvestDate, sortPos: 3, isAggregate: true, isProjAggregate: false, formatFn: getTimezoneSansLocaleDate },
    
    { key: 'Dry Weight', valueKey: null, aggregateKey: 'total_packaged_weight', valueFn: getDryWeight, projValueFn: getProjDryWeight, sortPos: 4, isAggregate: true, isProjAggregate: true, formatFn: convertWeight(undefined, undefined, 0) },
    
    { key: 'Wet Weight', valueKey: null, aggregateKey: 'total_wet_weight', valueFn: getWetWeight, projValueFn: getProjWetWeight, sortPos: 5, isAggregate: true, isProjAggregate: true, formatFn: convertWeight(undefined, undefined, 0) },

    { key: 'Dry/Wet Coeff', valueKey: null, aggregateKey: null, valueFn: () => null, sortPos: 6, isAggregate: true, isProjAggregate: true, isPostAggregate: true, formatFn: getFixedDecimalVal(2)},

    { key: 'RTS', valueKey: 'ready_to_sell', valueFn: getRTSDate, projValueKey: 'ready_to_sell', projValueFn: getProjRTSDate, sortPos: 7, isAggregate: true, isProjAggregate: false, formatFn: getTimezoneSansLocaleDate, },

    { key: 'Price', valueKey: null, aggregateKey: 'flower_price', valueFn: getFlowerPrice, projValueFn: getProjFlowerPrice, sortPos: 8, isAggregate: true, isProjAggregate: true, formatFn: val => getDollarFormatNum(val, 0) },
    
    { key: 'RTS(Amount)', valueKey: null, aggregateKey: 'rts_amount', valueFn: getRTSAmount, projValueFn: getProjRTSAmount, sortPos: 9, isAggregate: true, isProjAggregate: true, formatFn: val => getDollarFormatNum(val, 0) },
];

// Sorting: Can be enabled for ascending order if necessary;
harvestTableHeaderContentMap.sort((a, b) => a?.sortPos - b?.sortPos);


const handleInProdInvRes = handleSetValues => res => {
    let {data} = res;

    // data = data?.filter(e => e?.harvest_strainharvests?.length > 0); // It will be decided in serializer whether the strainharvest is inactive or not; 
                                                                     // By that point, that harvest was already addded;
                                                                     //  So filtering out any harvest which does not have any strainharvest
    // Alternate: Filter out these harvests in backend <- In effect 26-04-2023

    if (logMsgToConsole?.inProdInv) {
        console.log('got InProdInv data');console.log(res);
    }

    handleSetValues('harvests', data);

    let totalPlantCount = 0, projPlantCount = 0;
    let totalRTS = 0, projRTS = 0;

    const harvestTable = [];

    data?.forEach(dataVal => {
            const result = {
                tableHeaders: {
                    entryRowTitle: '',
                    content: [],
                    isTableHeader: true,
                },
                projected: {
                    entryRowTitle: 'Projected',
                    content: [],
                },
                actuals: {
                    entryRowTitle: 'Actuals',
                    content: [],
                },
            };

            harvestTableHeaderContentMap?.forEach(val => {
        
                // Table Headers
                result.tableHeaders.content.push( {value: val?.key} );
        
                // Projected
                // result.projected.content.push( {value: ''} );
                if (!val?.isProjAggregate) {
                    result.projected.content.push( { value: val?.projValueFn?.(dataVal?.cropplan) ?? dataVal?.cropplan?.[val?.projValueKey] } );
                } else {
                    result.projected.content.push( { ...val, value: null} );
                }
                
                // Actuals: only not aggregated values added to 'content'
                if (!val?.isAggregate) {
                    result.actuals.content.push( { value: val?.valueFn?.(dataVal) ?? dataVal?.[val?.valueKey] } );
                } else {
                    result.actuals.content.push( { ...val, value: null } ); // added entry to maintain order & prevent the need to sort again
                }
            })

            // Calculating Aggregated Values only from HARVEST_TYPE_INACTIVE strain harvests
            
            // The below filter is not needed as such strainharvests are filtered out in backend
            // dataVal?.harvest_strainharvests?.filter(e => e?.type === HARVEST_TYPE_INACTIVE)?.forEach(shVal => {
            let rtsProj = 0;

            dataVal?.cropplan?.crops?.forEach(cpVal => {
                result.projected.content.forEach(contentVal => {
                    if (contentVal?.isProjAggregate) {
                        contentVal.value = contentVal?.projValueFn?.(contentVal?.value, cpVal);
                    }

                    if (contentVal?.aggregateKey === 'rts_amount') {
                        rtsProj = contentVal?.value;
                    }
                });
                
                // Calcuations for Expected aggregate
                projPlantCount += +cpVal?.planter_size
            })

            projRTS += +getFixedDecimalVal(0)(rtsProj);
            
            let rtsAmt = 0;
            
            dataVal?.harvest_strainharvests?.forEach(shVal => {

                result.actuals.content.forEach(contentVal => {
                    if (contentVal?.isAggregate) {
                        contentVal.value = contentVal?.valueFn?.(contentVal?.value, shVal);
                    }

                    if (contentVal?.aggregateKey === 'rts_amount') {
                        // totalRTS = contentVal?.value;
                        rtsAmt = contentVal?.value;
                    }
                });

                // Calculation for Actuals aggregate 
                totalPlantCount += shVal?.plant_count ?? 0;
            })
            
            totalRTS += +getFixedDecimalVal(0)(rtsAmt);

            // Custom logic for any item that depends on the aggregated values & not worth looping over; 
            // Logic for this section is not a reliable pattern to be used for loop i.e. adding isPostAggregate to any other element will not give correct value
            // In other words, this is the section for one time / custom logic
            // Can take logic from this section and move it upwards when needed

            const projDryWetVals = result.projected.content.filter(e => e?.aggregateKey === 'total_packaged_weight' || e?.aggregateKey === 'total_wet_weight');
            const projDryWetCoeff = result.projected.content.find(e => e?.isPostAggregate) || {};
            
            projDryWetCoeff.value = projDryWetVals?.[0]?.aggregateKey === 'total_packaged_weight' ? +projDryWetVals?.[0]?.value / (+projDryWetVals?.[1]?.value || 1) : +projDryWetVals?.[1]?.value / (+projDryWetVals?.[0]?.value || 1)

            const dryWetVals = result.actuals.content.filter(e => e?.aggregateKey === 'total_packaged_weight' || e?.aggregateKey === 'total_wet_weight');
            const dryWetCoeff = result.actuals.content.find(e => e?.isPostAggregate) || {};

            dryWetCoeff.value = dryWetVals?.[0]?.aggregateKey === 'total_packaged_weight' ? +dryWetVals?.[0]?.value / (+dryWetVals?.[1]?.value || 1) : +dryWetVals?.[1]?.value / (+dryWetVals?.[0]?.value || 1)
            // dryWetCoeff.value = dryWetCoeff.formatFn?.(dryWetCoeff.value); // Taken care of in next line

            const tableHeaderContentMap = Object.values(result).map(e => ({ ...e, content: e?.content?.map( ({value, formatFn}) => ({value: formatFn?.(value) ?? value}) )})); // Cleaning up content values to only contain the object structure that is necessary i.e. [ {value: 'Some Value'}, ... ]

            harvestTable.push({tableHeaderContentMap, columnCount: harvestTableHeaderContentMap?.length, title: `Projected Crop/ Actual Harvest for ${dataVal?.code}`, id: dataVal?.id });
    });

    handleSetValues('harvestTable', harvestTable);

    const aggregate = [
        {title: 'Harvest Count', value: data?.length ?? 0, showFooter: false},
        {title: 'Plant Count', value: totalPlantCount, expected: projPlantCount, showActuals: false},
        {title: 'Revenue', value: getDollarFormatNum(totalRTS, 0), expected: getDollarFormatNum(projRTS, 0), showActuals: false},
    ]

    handleSetValues('aggregate', aggregate);

}


export default function InProdInventory(props) {

    const {
        handleOnClick=()=>{},
    } = props;

    const [values, setValues] = useState({});

    const feedbackCX = useContext(FeedbackContext);

    const handleSetValues = useCallback((prop, value) => setValues(state => ({...state, [prop]: value})), [setValues]);    

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    useEffect(() => {

        // Can be used if FOIL i.e. Flash Of Intermittent Light occurs
        // const timerID = setTimeout(() => {
        //     clearTimeout(timerID);

        //     handleSetValues('aggregate', dummyAggregate);
        //     handleSetValues('harvestTable', dummyHarvestTable);
    
        // }, 1000);
        
        // handleSetValues('aggregate', dummyAggregate);
        // handleSetValues('harvestTable', dummyHarvestTable);

        const errConfig = {};

        const entity = endpoints?.harvest?.list;

        APIService.fetchInstance(entity)
        .then(handleInProdInvRes(handleSetValues))
        // .catch(handleError) // TODO: Add error feedback snackbar
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        
    }, []);

    const currDarkThemeClass = useThemeVal();

    const boxProps = {
        className: `${dashboardClasses?.inProdInv?.box} ${currDarkThemeClass}`,
    };

    const logoBoxProps = {
        className: `${dashboardClasses?.inProdInv?.logoBox} ${currDarkThemeClass}`,
    };
    
    const logoBtnBoxProps = {
        className: `${dashboardClasses?.inProdInv?.logoBtnBox} ${currDarkThemeClass}`,
    };

    const logoIconProps = {
        className: `${dashboardClasses?.inProdInv?.logoIcon} ${currDarkThemeClass}`,
    };
    
    const backIconProps = {
        className: `${dashboardClasses?.inProdInv?.backIcon} ${currDarkThemeClass}`,
    };

    const backBtnProps = {
        children: 'Back to Dashboard',
        className: `${dashboardClasses?.inProdInv?.backBtn} ${currDarkThemeClass}`,
        onClick: handleOnClick,
        startIcon: <GoBackIcon {...backIconProps} />,
    };

    const titleTypoProps = {
        className: `${dashboardClasses?.inProdInv?.titleTypo} ${currDarkThemeClass}`,
    };

    const cardsBoxProps = {
        className: `${dashboardClasses?.inProdInv?.cardsBox} ${currDarkThemeClass}`,
    };

    const inProdInvCardProps = cardData => ({
        ...cardData,

        currDarkThemeClass,
    });

    const cardsList = values?.aggregate?.map((e, i) => <InProdInvCard key={i} {...inProdInvCardProps(e)} />)
            
    const tablesBoxProps = {
        className: `${dashboardClasses?.inProdInv?.tablesBox} ${currDarkThemeClass}`,
    };
    
    const inProdInvTableProps = table => ({
        ...table,

        currDarkThemeClass,

        handleOnClick: handleSeeStrainDetail(values, handleSetValues, table?.id),
    });

    const tablesList = values?.harvestTable?.map((e, i) => <InProdInvTable key={i} {...inProdInvTableProps(e)} />)

    const popupProps = {
        currDarkThemeClass,
        onClose: handlePopupClose(handleSetValues),
        open: values?.[SEE_STRAIN_DETAIL_PROP],
        
        ...values?.otherPopupProps,
    };

    return (
        <Fade in={true} timeout={fadeTimingMs}>
            <Box {...boxProps}>
                
                <Box {...logoBoxProps}>
                    <Box {...logoBtnBoxProps}>
                        <SGDashIcon {...logoIconProps} />
                        <Button {...backBtnProps} />
                    </Box>
                    <Typography {...titleTypoProps}>{title}</Typography>
                </Box>

                <Box {...cardsBoxProps}>
                    {cardsList}
                </Box>

                <Box {...tablesBoxProps}>
                    {tablesList}
                </Box>

                { values?.[SEE_STRAIN_DETAIL_PROP] && <StrainDetailInProdInvPopup {...popupProps} /> }

            </Box>
        </Fade>
    );
}