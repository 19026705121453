import Alert from "@mui/material/Alert";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Snackbar from "@mui/material/Snackbar";

import { feedbackClasses, snackbarDefDuration } from "../constants";


export default function TaskStatusSnackbar(props) {
    
    const {
        anchorOrigin={ vertical: 'top', horizontal: 'center', },
        autoHideDuration=snackbarDefDuration,
        currDarkThemeClass='',
        message='',
        open=false,
        severity='info',

        handleClose=() => {},
    } = props;


    const actionBtnProps = {
        className: `${feedbackClasses?.taskStatusSnackbar?.actionBtn} ${currDarkThemeClass}`,        
        onClick: handleClose,
    };

    const action = <CloseOutlinedIcon {...actionBtnProps} />;

    const snackbarProps = {
        action,
        anchorOrigin,
        autoHideDuration,
        className: `${feedbackClasses?.taskStatusSnackbar?.snackbar} ${currDarkThemeClass}`,
        // message,
        open: Boolean(open),
        // open,
        
        onClose: handleClose,
    };

    const alertProps = {
        className: `${feedbackClasses?.taskStatusSnackbar?.alert} ${currDarkThemeClass}`,
        severity,
        variant: 'filled',
        
        onClose: handleClose,
    };

    return (
        <Snackbar {...snackbarProps}>
            <Alert {...alertProps}>{message}</Alert>
        </Snackbar>
    );
}