import React, { useEffect, useMemo, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider, Stack, Tab, Tabs, tableClasses } from '@mui/material';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import { DARK_MODE, DisplayTable, PLANT_TYPES, cropClasses, phasePlanClasses } from '../../utility';
import DetailInfoCard from '../../utility/DetailInfoCard';
import BasicAccordion from '../utils/BasicAccordion';
import EntityDetailTimeline from '../utils/EntityDetailTimeline';
import { useTheme } from '@mui/material';
import apiService from '../../../api/apiService';

const overviewKeysMap = [
  { key: 'Crop Name', lookup: 'name' },
  { key: 'Crop Description', lookup: 'description' },
  { key: 'Date of Serialization', lookup: 'date_of_serialization', gridArea: '1/1/2/3', textFieldProps: { type: 'date', InputProps: {} } },
  { key: 'Phase Plan', lookup: 'phaseplan', gridArea: '2/1/3/2' },
  { key: 'Quality Plan', lookup: 'qualityplan', gridArea: '2/2/3/3' },
  { key: 'User', lookup: 'owner', gridArea: '3/1/4/2' },
  { key: 'Plant Count', lookup: 'plant_count', gridArea: '3/2/4/3' },
];

const getNameById = (id, data, lookup) => {
  const item = data?.find(item => item?.id === id);
  return item ? item?.[lookup] : '';
};

export default function ReviewRoutine(props) {
  const { onClose, handleSave, currDarkThemeClass, values } = props;

  const [state, setState] = useState({
    strain: values?.defStrainWithDefRoutines ?? [],
    activeStep: 0,
    open: true,
    isAccordionOpen: true,
    selectedStrain: 0, // State to keep track of the selected strain
    selectedPhase: 0, // State to keep track of the selected phase
  });

  const steps = ['Specifics', ...state.strain.map(s => s.strain_name)];

  const theme = useTheme();

  const handlePhaseChange = (event, newValue) => {
    setState(prevState => ({ ...prevState, selectedPhase: newValue }));
  };

  const CustomStepLabel = ({ children, step, active }) => (
    <Typography variant="body1" sx={{ color: active ? theme.palette.primary['600'] : theme.palette.grey['500'] }}>
      {children}
    </Typography>
  );

  const handleAccordionChange = (event, expanded) => {
    setState(prevState => ({ ...prevState, isAccordionOpen: expanded }));
  };

  const isLastStep = state.activeStep === steps.length - 1;

  const handleNext = () => {
    if (state.activeStep === 0) {
      // If on the Specifics step, just increment the step
      setState(prevState => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
    } else {
      // If on a strain-specific step, update the selected strain and then increment the step
      if (state.selectedStrain < state.strain.length - 1) {
        setState(prevState => ({ ...prevState, selectedStrain: prevState.selectedStrain + 1 }));
      }
      setState(prevState => ({ ...prevState, activeStep: prevState.activeStep + 1 }));
    }
  };

  const handleBack = () => {
    if (state.activeStep === 0) {
      // If on the Specifics step, just decrement the step
      setState(prevState => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
    } else {
      // If on a strain-specific step, update the selected strain and then decrement the step
      if (state.selectedStrain > 0) {
        setState(prevState => ({ ...prevState, selectedStrain: prevState.selectedStrain - 1 }));
      }
      setState(prevState => ({ ...prevState, activeStep: prevState.activeStep - 1 }));
    }
  };

  const handleFinish = () => {
    if (handleSave) {
      handleSave();
    }
    setState(prevState => ({ ...prevState, open: false }));
    onClose();
  };

  const entityDetailPopupProps = {
    open: state.open,
    headContentText: '',
    currDarkThemeClass,
    onClose: onClose,
    toShowSave: false,
    title: 'Review',
    modalMaxWidth: '1000px',
  };

  const phasePlanName = useMemo(() => getNameById(values?.phaseplan, values?.phaseplans, 'name'), [values?.phaseplan, values?.phaseplans]);
  const qualityPlanName = useMemo(() => getNameById(values?.qualityplan, values?.qualityplans, 'name'), [values?.qualityplan, values?.qualityplans]);
  const ownerName = useMemo(() => getNameById(values?.owner, values?.users, 'first_name'), [values?.owner, values?.users]);

  const valueMap = {
    phaseplan: phasePlanName,
    qualityplan: qualityPlanName,
    owner: ownerName,
  };

  const oTInfoTypoProps = {
    className: `${phasePlanClasses?.overviewInfoTypo} ${currDarkThemeClass}`,
  };

  const oTInfoTypoKeyProps = {
    className: `${cropClasses.overviewKeyTypo} ${currDarkThemeClass}`,
  };

  const overviewDefaultEntries = overviewKeysMap
    .filter(e => e.default)
    .map((e, i) => ({
      value: (
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap" margin="0.3rem 0" key={i}>
          <Typography {...oTInfoTypoKeyProps}>{e.key} :</Typography>
          <Typography {...oTInfoTypoProps}>{values[e.lookup]}</Typography>
        </Stack>
      ),
    }));

  const accordionDetailsEntries = overviewKeysMap
    .filter(e => !e.default)
    .map(e => (
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" margin="0.3rem 0" key={e.key}>
        <Typography {...oTInfoTypoKeyProps}>{e.key}</Typography>
        <Typography {...oTInfoTypoProps}>{valueMap[e.lookup] ?? values[e.lookup]}</Typography>
      </Stack>
    ));

  const accordionDetailsContent = <Stack>{accordionDetailsEntries}</Stack>;

  const tableTitles = [
    { key: 'strain_name', label: 'Strain' },
    { key: 'batch_name', label: 'Batch Name' },
    { key: 'frtl', label: 'FRTL' },
    { key: 'from_type', label: 'From Type' },
    { key: 'plant_count', label: 'Plant Count' },
    { key: 'quality_plan', label: 'Quality Plan' },
    { key: 'phase_plan', label: 'Phase Plan' },
  ];

  const createTableEntries = (strains, values) => {
    const tableEntries = [];
    strains?.forEach((strain, index) => {
      const strainName = strain?.strain_name ?? '';
      strain.batches.forEach((batch, batchIndex) => {
        const batchValues = tableTitles.map(title => {
          const valueMap = {
            strain_name: strainName,
            batch_name: batch.name,
            frtl: batch.frtl,
            from_type: PLANT_TYPES.find(type => type?.value === +batch?.plant_type)?.label || '',
            plant_count: batch.plant_count,
            quality_plan: getNameById(batch?.qualityplan, values?.qualityplans, 'name'),
            phase_plan: getNameById(batch?.phaseplan, values?.phaseplans, 'name'),
          };
          return valueMap[title.key] ?? '';
        });

        tableEntries.push(batchValues);
      });
    });

    return tableEntries;
  };

  const strainTableEntries = useMemo(() => createTableEntries(values?.strains, values), [values?.strains, values]);
  const TableValues = {
    entity: 'Strain',
    tableEntries: strainTableEntries,
    toShowAddBtn: false,
    currDarkThemeClass: currDarkThemeClass,
    tableTitles: tableTitles.map(title => ({ title: title.label })), // Extract only the labels for table titles
  };
  const strainsContent = <DisplayTable {...TableValues} />;

  const oTAccordionProps = {
    detail: strainsContent,
    accordionCustomProps: {
      className: `${cropClasses.overviewAccordion} ${currDarkThemeClass}`,
      onChange: handleAccordionChange,
      expanded: state.isAccordionOpen,
    },
    accordionDetailsCustomProps: {
      className: `${cropClasses.overviewAccordionDetails} ${currDarkThemeClass}`,
    },
    currDarkThemeClass,
    title: 'Strains Review',
  };

  const cropPlanDividerProps = {
    className: `${cropClasses?.divider} ${currDarkThemeClass}`,
  };
  const accordion = <BasicAccordion {...oTAccordionProps} />;

  const OverviewCardProps = {
    currDarkThemeClass,
    entries: [...overviewDefaultEntries, { value: accordionDetailsContent }, { value: accordion }],
    cardTitleMarginBottom: '12px',
    toShowEditBtn: false,
    title: 'Specifics Review',
  };

  const timelineEntries =
    state.strain[state.selectedStrain]?.phases[state.selectedPhase]?.subphases.map(subphase => ({
      name: subphase.name,
      key: subphase.name,
      children: (
        <>
          {subphase.routines.length > 0 ? (
            subphase.routines.map(routine => (
              <div key={routine.name}>
                <Typography variant="subtitle1">{`Routine: ${routine.name}`}</Typography>
                <Divider {...cropPlanDividerProps} />
              </div>
            ))
          ) : (
            <div>
              <Typography variant="subtitle1">Not Available Routine</Typography>
              <Divider {...cropPlanDividerProps} />
            </div>
          )}
        </>
      ),
      withSubtitle: true,
    })) || [];
  const displayTableAddBtnProps = {
    className: `${tableClasses?.displayTable?.addBtn} ${currDarkThemeClass}`,
  };

  console.log(props);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
      <EntityDetailPopup {...entityDetailPopupProps}>
      <div style={{ marginBottom: '20px' }}>
            <Stack sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button disabled={state.activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Previous
              </Button>
              <Stack sx={{ flex: '1 1 auto' }} />
              <Button onClick={isLastStep ? handleFinish : handleNext}>{isLastStep ? 'Save' : 'Next Step'}</Button>
            </Stack>
          </div>
        <div style={{ marginBottom: '20px' }}>
          <Stepper activeStep={state.activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel optional={<CustomStepLabel active={state.activeStep === index}>{label}</CustomStepLabel>} />
              </Step>
            ))}
          </Stepper>
        </div>
        <React.Fragment>
          {state.activeStep === 0 && <DetailInfoCard {...OverviewCardProps} />}
          {state.activeStep > 0 && (
            <>
              <Tabs value={state.selectedPhase} onChange={handlePhaseChange}>
                {state.strain[state.selectedStrain]?.phases.map((phase, index) => (
                  <Tab
                    key={index}
                    label={phase.name}
                    sx={{
                      // bgcolor: state.selectedPhase === index ? 'primary.700' : '',
                      textTransform: 'none',
                      borderRadius: '2px',
                      color: theme?.palette?.themeMode === DARK_MODE ? 'white' : theme?.palette?.grey?.['700'],
                      margin: '0 8px',
                      border: state.selectedPhase === index ? '1px solid' : '0px solid',
                      ...displayTableAddBtnProps, // Place the spread operator here
                    }}
                  />
                ))}
              </Tabs>
              <EntityDetailTimeline timelineEntries={timelineEntries} currDarkThemeClass={currDarkThemeClass} isEnableSeparator={false} />
            </>
          )}
        </React.Fragment>
      </EntityDetailPopup>
    </div>
  );
}
