import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import OrgOutlinePage from "../setup/OrgSetupOutline";
import { InviteUserIcon } from "../utility/CustomSvgIcons";
import { inviteFormMaxWd, fadeTimingMs, landingPageRoute, farmDashboardRoute, defaultNoAuthRoute, invitePageClasses } from "../utility/constants";
import APIService from "../../api/apiService";
import useThemeVal from "../../utility/useThemeVal";

const welcomeMsg = name => `Welcome to SmartGrow, ${name}!`;
const welcomeSubMsg = (name, emailStage=true) => `You have been invited to join as a team member by ${name}. ${emailStage ? "Please enter the 6 digit verification code sent to your mail" : "To Login Create a Secure Password for your account first. After that you will be redirected to your Dashboard."}`;

const linkExpiredMsg = 'Alas! The link has expired...';
const linkExpiredSubMsg = 'Please contact the administrator of the organization to re-send a new invite link';

const instructions = [
    'At least 12 characters.',
    'A mixture of both uppercase and lowercase letters.',
    'A mixture of letters and numbers.',
    'Inclusion of at least one special character, e.g., ! @ # ? ]',
];

export default function InvitePage(props) {
    
    const [values, setValues] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const auth = searchParams.get('auth');

    const currDarkThemeClass = useThemeVal();

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleInvitedUserRes = res => {
        const {data} = res;

        setValues(state => ({
            ...state,
            ...data,
        }));

    };

    const handleInviteUserEmailRes = res => {
        handleSetValues('emailStage', false);
    }

    const handleInviteUserSetupRes = res => {
        localStorage.clear();
        
        const {data} = res;
        // console.log('got res for new invited user');
        // console.log(res);

        localStorage.setItem('token', data?.token);
        
        setValues({});

        const timerId = setTimeout(() => {
            clearTimeout(timerId);
            // navigate(landingPageRoute);
            navigate(farmDashboardRoute);

        }, 2000);
    }

    const handleError = err => {
        console.log(err);
        if (err.response.status === 400) {
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            
            if (err?.response?.data?.is_active) {
                navigate(defaultNoAuthRoute);
            } else {
                handleSetValues('expiration', err?.response?.data?.expiration);
            }

        } else if (err.response.status === 404) {
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            
            navigate(landingPageRoute);

        }
    }

    useEffect(() => {
        if (!localStorage.auth && !auth) return;

        let payload = null;
        let data = {};

        if (!auth) {
            payload = localStorage.auth;
        } else {
            const authValue = auth.split('.')[1];
            
            localStorage.setItem('auth', authValue);
            localStorage.setItem('token', auth);
            
            payload = authValue;
            
            setSearchParams({});
        }

        try {
            // data = JSON.parse(atob(token.split('.')[1]));
            data = JSON.parse(atob(payload));
        } catch {
            localStorage.clear();
            return;
        }

        handleSetValues('processing', true);
        handleSetValues('emailStage', true);
        const entity = `invited-user/?email=${data.email}&user_id=${data.user_id}`;

        APIService.fetchInstance(entity)
        .then(handleInvitedUserRes)
        .catch(handleError)
        .finally(() => handleSetValues('processing', false));

    }, []);

    useEffect(() => {
        if (!localStorage.auth && !auth) navigate(landingPageRoute);
    }, [localStorage.auth, auth])

    if (!localStorage.auth && !auth) return <></>;

    const inviteOutlineProps = {
        currDarkThemeClass,
        logoBoxProps: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        },
    };

    const inviteContentBoxProps = {
        className: `${invitePageClasses?.contentBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     flexDirection: 'column',
        //     justifyContent: 'center',
        //     // mt: '136px',
        //     mt: '126px',
        //     mx: 'auto',
        //     pb: '56px',
        //     maxWidth: `${inviteFormMaxWd}px`,
        // },
    };

    const inviteUserIconProps = {
        className: `${invitePageClasses?.userIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '48px',
        //     width: '48px',
        // },
    };

    const inviteWelTypoProps = {
        className: `${invitePageClasses?.welTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '30px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.01em',
        //     lineHeight: 1.13,
        //     mt: '36px',
        //     textAlign: 'center',
        // },
    };

    const inviteWelSubTypoProps = {
        className: `${invitePageClasses?.welSubTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: 1.5,
        //     mt: '12px',
        //     textAlign: 'center',
        // },
    };

    const handleSubmit = event => {
        event.preventDefault();

        const auth = localStorage.auth;

        if (!auth) return;

        handleSetValues('processing', true);
        
        const entity = 'invited-user/';

        const userData = JSON.parse(atob(auth));


        if (values?.emailStage) {
            if (!values?.verification_code) return;
            
            // handleSetValues('processing', true);
        
            // const entity = 'invited-user/';

            const payload = {
                verification_code: values?.verification_code,
                email: userData?.email,
                user_id: userData?.user_id,
            };

            APIService.createInstance(entity, payload)
            .then(handleInviteUserEmailRes)
            .catch(err => {
                // console.log(err);
                handleSetValues('error', true);

                if (err?.response?.data?.codeErr) {
                    handleSetValues('helperText', 'Invalid code');
                    handleSetValues('verification_code', '');
                } else {
                    handleSetValues('helperText', 'An error occured');
                }
            })
            .finally(() => handleSetValues('processing', false));

            return;
        }

        if (!values?.password || !values?.confirm) {
            handleSetValues('error', true);
            handleSetValues('helperText', 'This is a required value');
            return;
        } else if (values.password.length < 12 || values.password !== values.confirm) {
            handleSetValues('error', true);
            handleSetValues('helperText', 'Passwords do not match');
            handleSetValues('processing', false);
            return;
        }

        const regexList = [
            {regex: /[0-9]+/, helperText: 'Please add a number'},
            {regex: /[a-z]+/, helperText: 'Please add a lowercase character'},
            {regex: /[A-Z]+/, helperText: 'Please add an uppercase character'},
            {regex: /\W+|_/, helperText: 'Please add a special character'},
        ];

        for (let item of regexList) {
            if (!item.regex.test(values.password)) {
                handleSetValues('error', true);
                handleSetValues('check', true);
                handleSetValues('helperText', item.helperText);
                return;
            }
        }

        // handleSetValues('processing', true);
        
        // const entity = 'invited-user/';

        // const userData = JSON.parse(atob(auth));

        const payload = {
            user_id: userData?.user_id,
            email: userData?.email,
            organization: userData?.organization,
            password: values?.password,
        };

        APIService.createInstance(entity, payload)
        .then(handleInviteUserSetupRes)
        .catch(err => {
            console.log(err);
        })
        .finally(() => handleSetValues('processing', false));

    };

    const inviteFormBoxProps = {
        component: 'form',
        onSubmit: handleSubmit,
        className: `${invitePageClasses?.formBox} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '20px',
        // },
    };

    const handleValueChange = (prop, value) => {
        if (values?.error) {
            handleSetValues('error', false);
            handleSetValues('helperText', '');
        }
        handleSetValues(prop, value);
    }

    const inviteTextFieldProps = ({prop, inputProps={}, otherTextProps={}, otherSxProps={}}) => ({
        autoComplete: 'off',
        disabled: values?.processing,
        error: values?.error,
        helperText: ( (prop === 'confirm' && !values?.check) ? '' : values?.helperText ) ?? '',
        fullWidth: true,
        inputProps,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        //     ...inputProps,
        // },
        // InputLabelProps: {
        //     sx: {
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        // InputProps: {
        //     sx: {
        //         borderRadius: 0,
        //         '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
        //             borderColor: 'grey.800',
        //         },
        //     },
        // },
        label: ' ',
        onChange: ({target: {value}}) => handleValueChange(prop, value),
        required: true,
        className: `${invitePageClasses?.textField} ${currDarkThemeClass}`,
        sx: {
            // color: 'grey.800',
            // mt: '20px',
            ...otherSxProps,
        },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const verificationProps = {
        inputProps: {
            minLength: 6,
            maxLength: 6,
        },
        otherTextProps: {
            autoFocus: true,
            label: '6 digit verification code',
            type: 'number',
        },
        prop: 'verification_code',
    };

    const visibilityIconProps = {
        className: `${invitePageClasses?.visibilityIcon} ${currDarkThemeClass}`,
        onClick: () => !values?.processing ? handleSetValues('showPassword', !values?.showPassword) : undefined,
    };

    const passwordProps = {
        inputProps: {
            minLength: 12,
        },
        otherTextProps: {
            autoFocus: true,
            label: 'Enter Your Password',
            type: values?.showPassword ? 'text' : 'password',
            InputProps: {
                endAdornment: values?.showPassword ? <VisibilityOffIcon {...visibilityIconProps} /> : <VisibilityIcon {...visibilityIconProps} />,
            },
        },
        prop: 'password',
    };

    const confirmPasswordProps = {
        inputProps: {
            minLength: 12,
        },
        otherTextProps: {
            label: 'Re-Enter Password to confirm',
            type: values?.showPassword ? 'text' : 'password',
            InputProps: {
                endAdornment: values?.showPassword ? <VisibilityOffIcon {...visibilityIconProps} /> : <VisibilityIcon {...visibilityIconProps} />,
            },
        },
        prop: 'confirm',
    };

    const inviteInstructionBoxProps = {
        component: 'ul',
        className: `${invitePageClasses?.instructionBox} ${currDarkThemeClass}`,
        // sx: {
        //     mt: '20px',
        //     pl: '8px',
        // },
    };

    const inviteInsTypoProps = {
        component: 'li',
        className: `${invitePageClasses?.instructionTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.700',
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: 1.5,
        //     ml: '16px',
        // },
    };

    const instructionsList = instructions.map((ins, i) => 
        <Typography key={i} {...inviteInsTypoProps}>{ins}</Typography>
    );

    const inviteProgressProps = {
        color: 'warning',
        size: 20,
    };

    const inviteSubmitBtnProps = {
        children: values?.emailStage ? 'Verify Email' : 'Submit and Login to my Dashboard',
        disabled: values?.processing,
        onClick: undefined,
        startIcon: values?.processing && <CircularProgress {...inviteProgressProps}/>,
        className: `${invitePageClasses?.submitBtn} ${currDarkThemeClass}`,
        // sx: {
        //     color: '#FFFFFF',
        //     backgroundColor: 'primary.800',
        //     borderRadius: 0,
        //     display: 'flex',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        //     mt: '20px',
        //     p: '16px',
        //     textTransform: 'none',
        //     width: '472px',
        //     '&:hover': {
        //         backgroundColor: 'primary.800',
        //     },
        // },
        type: 'submit',
    };

    
    return (
        <OrgOutlinePage {...inviteOutlineProps}>
            <Fade in={true} timeout={fadeTimingMs} >
                <Box {...inviteContentBoxProps}>
                    <InviteUserIcon {...inviteUserIconProps} />
                    {
                        !values?.expiration ? (
                            <>
                                <Typography {...inviteWelTypoProps}>{welcomeMsg(values?.first_name ?? 'New User')}</Typography>
                                <Typography {...inviteWelSubTypoProps}>{welcomeSubMsg(values?.admin_name ?? 'Admin', values?.emailStage)}</Typography>
                                <Box {...inviteFormBoxProps}>
                                    {
                                        values?.emailStage ? 
                                            <TextField {...inviteTextFieldProps(verificationProps)} />
                                        : <>
                                            <TextField {...inviteTextFieldProps(passwordProps)} />
                                            <TextField {...inviteTextFieldProps(confirmPasswordProps)} />
                                            <Box {...inviteInstructionBoxProps}>
                                                {instructionsList}
                                            </Box>
                                        </>
                                    }
                                    <Button {...inviteSubmitBtnProps} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography {...inviteWelTypoProps}>{linkExpiredMsg}</Typography>
                                <Typography {...inviteWelSubTypoProps}>{linkExpiredSubMsg}</Typography>
                            </>
                        )
                    }
                </Box>
            </Fade>
        </OrgOutlinePage>
    );
}