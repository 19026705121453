import { useContext, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import EntityDetailPopup from "../utility/EntityDetailPopup";

import { 
    accountAppLabel, crudActions, dashboardModelName, entityModelMap, errFeedbackFn, otherRoomCreatePermissions, 
    popupClasses,
    getHandleErrorWithFeedbackCX,
} from "../utility/constants";

import APIService from "../../api/apiService";
import { FeedbackContext } from "../../App";
// import getTokenDetails from "../../auth/getTokenDetails";

const serialNo = 'Sl.No';
const Entity = 'Entity';
const Create = 'Create';
const Read = 'View';
const Update = 'Change';
const Delete = 'Delete';
const All = 'All';

// const entities = ['Farms', 'Rooms', 'Strains', 'Sensors'];
const entities = Object.keys(entityModelMap);
const actions = ['add', 'view', 'change', 'delete'];

const keys = ['name'];

export default function CreatePermissionsPopup(props) {
    const {
        edit=false,
        handleAddPerm, // for adding the created perm to the list
        handleSaveModifiedPerm,
        perm={},
        savedPerm={},
        currDarkThemeClass='',
        ...otherProps
    } = props;

    const [values, setValues] = useState({});

    const feedbackCX = useContext(FeedbackContext);

    useEffect(() => {
        if (edit) {
            setValues({
                ...perm,
                data: perm?.group?.permissions?.map(e => e?.codename),
            });
        } else {
            setValues({});
        }
    }, [edit]);
    // const navigate = useNavigate();

    const handleSetValues = (prop, value) => setValues(state => ({...state, [prop]: value}));

    const handleError = err => {
        console.log('An error occured');console.log(err);

        handleSetValues('loading', false);
    }

    const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

    const handleFinally = () => {
        handleSetValues('loading', false);
    }

    const handlePermCreateAndSaveRes = res => {
        const {data} = res; 
        // console.log('got data');console.log(data);

        setValues({}); // Will also set loading to false

        if (edit) {
            handleSaveModifiedPerm(data);
        } else {
            handleAddPerm(data);
        }

        otherProps?.onClose?.();

    };

    const cRTextFieldProps = ({prop, inputProps={}, otherTextProps={}, otherSxProps={}}) => ({
        autoComplete: 'off',
        className: `${popupClasses?.createPermission?.textField} ${currDarkThemeClass}`,
        disabled: values?.loading,
        fullWidth: true,
        inputProps,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        //     ...inputProps,
        // },
        // InputLabelProps: {
        //     sx: {
        //         '&.MuiInputLabel-root': {
        //             color: 'grey.800',
        //         },
        //     },
        // },
        // InputProps: {
        //     sx: {
        //         borderRadius: 0,
        //         '&.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline': {
        //             borderColor: 'grey.800',
        //         },
        //     },
        // },
        label: ' ',
        onChange: ({target: {value}}) => handleSetValues(prop, value),
        // onChange: ({target: {value}}) => handleValueChange(prop, value),
        required: true,
        sx: {
            // color: 'grey.800',
            // mt: '20px',
            ...otherSxProps,
        },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const permNameProps = {
        otherTextProps: {
            autoFocus: true,
            label: 'Role Name',       
        },
        prop: 'name',
    };
    
   
    const toDisable = () => {
        if (edit) {
            // return keys.every(key => key === 'cycle_name' ? savedPerm?.[key] === values?.[key] : +savedPerm?.[key] === +values?.[key])
            const prevPermList = savedPerm?.group?.permissions?.map(e => e?.codename);
            const newPermList = values?.data ?? [];

            const forwardCheck = prevPermList?.every(perm => newPermList.includes(perm));
            const backwardCheck = newPermList?.every(perm => prevPermList.includes(perm));

            if (newPermList.length < 1) return true;

            return keys.every(key => values?.[key] === savedPerm?.[key]) && forwardCheck && backwardCheck;
        }
        return keys.some(key => !values?.[key]?.trim()) || (values?.data?.length ?? 0) < 1;
    };

    const handlePermCreateAndSave = () => {
        if (toDisable() || (edit && isNaN(+perm.id))) return;

        handleSetValues('loading', true);

        let errConfig = {};

        if (edit) {
            const prevPermList = savedPerm?.group?.permissions?.map(e => e?.codename);
            const newPermList = values?.data;

            const forwardCheck = prevPermList?.every(perm => newPermList.includes(perm));
            const backwardCheck = newPermList?.every(perm => prevPermList.includes(perm));

            // const entityWithID = `perm/${perm.id}/`;
            const entityWithID = `role/${perm.id}/`;
            
            const payload = {
                name: values?.name,
                data: (forwardCheck && backwardCheck) ? [] : values?.data,
            };

            APIService.modifyInstance(entityWithID, payload)
            .then(handlePermCreateAndSaveRes)
            // .catch(handleError)
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
            .finally(handleFinally)

            return;
        }

        errConfig = {
            create: true,
            resource: 'Permission',
        };

        // const entity = 'perm/';
        const entity = 'role/';

        APIService.createInstance(entity, values)
        .then(handlePermCreateAndSaveRes)
        // .catch(handleError);
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally)

    };

    const handleClosePopup = () => {
        setValues({});
        otherProps?.onClose?.();
    }

    const entityDetailProps = {
        currDarkThemeClass,
        headContentText: null,
        loading: values?.loading,
        onClose: handleClosePopup,
        onSaveClick: handlePermCreateAndSave,
        title: edit ? savedPerm?.name : 'Create a New Role',
        // title: edit ? savedPerm?.name : 'Create New Permission',
        toDisableSave: toDisable(),
        saveText: edit ? 'Save Role' : 'Create Role',
        // saveText: edit ? 'Save Permission' : 'Create Permission',
        ...otherProps,
    };

    const cRRolesListBoxProps = {
        className: `${popupClasses?.createPermission?.rolesListBox} ${currDarkThemeClass} customScroll`,
        // sx: {
        //     borderBottom: '1px solid',
        //     borderColor: 'primary.300',
        //     height: '360px',
        //     minWidth: 'fit-content',
        //     mt: '24px',
        //     overflow: 'auto', // but fix header position
        //     // position: 'relative',
        // },
    };

    const cRRoleHeaderBoxProps = {
        className: `${popupClasses?.createPermission?.roleHeaderBox} ${currDarkThemeClass}`,
        // sx: {
        //     // backgroundColor: 'primary.800',
        //     backgroundColor: 'primary.300',
        //     display: 'flex',
        //     left: 0,
        //     minWidth: 'fit-content',
        //     position: 'sticky',
        //     top: 0,
        //     zIndex: 1,
        // },
    };

    const cRRoleHeaderTypoProps = (flexBasis='15%') => ({
        className: `${popupClasses?.createPermission?.roleHeaderTypo} ${currDarkThemeClass}`,
        sx: {
            // color: 'primary.100',
            // color: 'primary.800',
            flexBasis,
            // py: '8px',
            // textAlign: 'center',
        },
    });

    const cRRoleContentBoxProps = {
        className: `${popupClasses?.createPermission?.roleContentBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     flexDirection: 'column',
        // },
    };

    const cREntityEntryBoxProps = {
        className: `${popupClasses?.createPermission?.entryBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     mt: '8px',
        //     textAlign: 'center',
        // },
    };

    const cREntitySlNoTypoProps = {
        className: `${popupClasses?.createPermission?.slNoTypo} ${currDarkThemeClass}`,
        // sx: {
        //     flexBasis: '10%',
        // },
    };

    const cREntityNameTypoProps = {
        className: `${popupClasses?.createPermission?.nameTypo} ${currDarkThemeClass}`,
        // sx: {
        //     flexBasis: '15%',
        // },
    };

    const cRCheckboxProps = ({otherSxProps={}, otherTextProps={}}) => ({
        autoComplete: 'off',
        checkedIcon: <CheckCircleIcon />,
        className: `${popupClasses?.createPermission?.checkbox} ${currDarkThemeClass}`,
        disabled: values?.loading,
        icon: <CircleOutlinedIcon />,
        // inputProps: {
        //     sx: {
        //         px: '15px',
        //         py: '16px',
        //     },
        // },
        sx: {
            // color: 'grey.800',
            // mt: '20px',
            ...otherSxProps,
        },
        ...otherTextProps,
    });

    // const handlePermissionSel = permValue => ({target: {checked}}) => {
    //     const sepIndex = permValue.indexOf('_') + 1;
    //     const entity = permValue.substring(sepIndex);

    //     if (checked) {
    //         if (permValue.includes('view')) {
    //             handleSetValues('data', [...(values?.data ?? []), permValue]);
    //         } else {
    //             let otherPermissions = [`view_${entity}`];
                
    //             if (permValue === 'add_room') {
                    
    //                 const otherEntities = ['sensor', 'light', 'roomtable'];

    //                 otherEntities.forEach(otherEntity => {
    //                     otherPermissions.push(`view_${otherEntity}`, `add_${otherEntity}`);
    //                 })
    //             }
    //             // const viewPerm = `view_${entity}`;

    //             // handleSetValues('data', [...(values?.data ?? []), permValue, viewPerm]);
    //             handleSetValues('data', [...(values?.data ?? []), permValue, ...otherPermissions]);
    //         }

    //     } else {
    //         if (!permValue.includes('view')) {
    //             handleSetValues('data', values?.data?.filter(e => e !== permValue))
    //         } else {
    //             // If you remove view, you will remove all active permissions
    //             const entityPerms = Object.values(crudActions).map(action => `${action}_${entity}`);

    //             handleSetValues('data', values?.data?.filter(e => !entityPerms.includes(e)))
    //         }

    //         if (values?.data?.includes('add_room') && otherRoomCreatePermissions.includes(permValue)) {
    //             // handleSetValues('data', values?.data?.filter(e => e !== 'add_room'));
    //             setValues(state => ({
    //                 ...state,
    //                 data: state.data.filter(e => e !== 'add_room')
    //             }));
    //         }
    //     }
    // };

    const handlePermissionSel = permValue => ({target: {checked}}) => {
        const sepIndex = permValue.indexOf('_');
        const currAction = permValue.substring(0, sepIndex);
        const entity = permValue.substring(sepIndex + 1);

        const {
            allowActions=actions,
            // isCustomPerm=false,
        } = Object.values(entityModelMap).find(e => e?.contenttype === entity) || {};

        if (checked) {
            if (!allowActions?.includes(currAction)) return; // check for when html & css are overriden

            if (permValue.includes('view') && allowActions?.includes('view')) {
                handleSetValues('data', [...(values?.data ?? []), permValue]);
            } else {
                
                // let otherPermissions = allowActions?.includes('view') ? [`view_${entity}`] : []; // Not implementing this check as it is assumed that view is a prerequisite for any other activity
                let otherPermissions = [`view_${entity}`];
                
                handleSetValues('data', [...(values?.data ?? []), permValue, ...otherPermissions]);
            }

        } else { // unchecking
            if (!permValue.includes('view')) {
                handleSetValues('data', values?.data?.filter(e => e !== permValue));
            } else {
                // If you remove view, you will remove all active permissions
                const entityPerms = Object.values(crudActions).map(action => `${action}_${entity}`);

                handleSetValues('data', values?.data?.filter(e => !entityPerms.includes(e)))
            }

        }
    };

    const permissionProps = (permValue, indeterminate=false) => ({
        otherSxProps: {
            flexBasis: indeterminate ? '100%' : '15%',
            mt: 0,
        },
        otherTextProps: {
            checked: values?.data?.includes(permValue) ?? false,
            indeterminate,
            disabled: indeterminate,
            // onChange: ({target: {checked}}) => checked ? handleSetValues('data', [...(values?.data ?? []), permValue]) : handleSetValues('data', values?.data?.filter(e => e !== permValue)),
            onChange: indeterminate ? undefined : handlePermissionSel(permValue),
            value: permValue,
        },
    });

    // const re = /s\b/;

    const permEntriesList = entities.map((entity, i) => {
        // const formattedEntityName = entity.replace(re, '').toLowerCase();
        const { 
            isCustomPerm=false,
            contenttype:formattedEntityName, 
            allowActions=actions, 
        } = entityModelMap[entity]; // allowActions has the default value of actions; So if undefined it will always allow all actions; Also we need both isCustomPerm and allowActions to be true

        // const formattedEntityName = entityModelMap[entity]?.contenttype;

        const permissionsList = actions.map((action, idx) => 
            // <Checkbox key={`perm-${idx}`} {...cRCheckboxProps(permissionProps(`${action}_${formattedEntityName}`, !allowActions?.includes(action)) && isCustomPerm)} />
            isCustomPerm && !allowActions?.includes(action) 
                ? <Tooltip key={`perm-${idx}`} title='Not Applicable'><Box sx={{mt: 0, flexBasis: '15%'}}><Checkbox {...cRCheckboxProps(permissionProps(`${action}_${formattedEntityName}`, true))} /></Box></Tooltip>
                : <Checkbox key={`perm-${idx}`} {...cRCheckboxProps(permissionProps(`${action}_${formattedEntityName}`))} />
        );

        // const allEntityActionsList = actions.map(action => `${action}_${formattedEntityName}`);
        const allEntityActionsList = allowActions.map(action => `${action}_${formattedEntityName}`);

        const allPermissionProps = {
            otherSxProps: {
                flexBasis: '15%',
                mt: 0,
            },
            otherTextProps: {
                checked: allEntityActionsList.every(action => values?.data?.includes(action)),
                onChange: ({target: {checked}}) => {
                    
                    if (checked) {
                        const newDataValues = allEntityActionsList.filter(action => !values?.data?.includes(action))
                        handleSetValues('data', [...(values?.data ?? []), ...newDataValues]);

                        // if (formattedEntityName === 'room') {
                        //     setValues(state => ({
                        //         ...state,
                        //         data: [
                        //             ...state.data,
                        //             ...otherRoomCreatePermissions.filter(perm => !state.data.includes(perm)),
                        //         ],
                        //     }));
                        // }
                    } else {
                        
                        handleSetValues('data', values?.data?.filter(action => !allEntityActionsList.includes(action)));

                        // if (values?.data?.includes('add_room') && otherRoomCreatePermissions?.some(perm => perm.includes(formattedEntityName))) {
                        //     setValues(state => ({
                        //         ...state,
                        //         data: state.data.filter(e => e !== 'add_room')
                        //     }));
                        // }

                    }
                },
                value: `all_${formattedEntityName}`,
            },
        };

        return (
            <Box key={`${entity}`} {...cREntityEntryBoxProps}>
                <Typography {...cREntitySlNoTypoProps}>{`${i + 1}`.padStart(2, 0)}</Typography>
                <Typography {...cREntityNameTypoProps}>{entity}</Typography>
                {permissionsList}
                {/* {allPermissionCheckbox} */}
                <Checkbox key={`perm-all`} {...cRCheckboxProps(allPermissionProps)} />
            </Box>
        );
    });

    console.log('props =', props);
    console.log('values =', values);
    


    return (
        <EntityDetailPopup {...entityDetailProps}>
            <TextField {...cRTextFieldProps(permNameProps)} />
            <Box {...cRRolesListBoxProps}>
                <Box {...cRRoleHeaderBoxProps}>
                    <Typography {...cRRoleHeaderTypoProps('10%')}>{serialNo}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{Entity}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{Create}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{Read}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{Update}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{Delete}</Typography>
                    <Typography {...cRRoleHeaderTypoProps()}>{All}</Typography>
                </Box>

                <Box {...cRRoleContentBoxProps}>
                    {permEntriesList}
                </Box>
            </Box>
        </EntityDetailPopup>
    );
}