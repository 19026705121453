import React, { useMemo, useState, useEffect } from 'react';
import { Fade, Paper, Stack, Typography, useTheme, Checkbox, Button } from '@mui/material';
import { DisplayTable, errFeedbackFn, roomDetailClasses } from '../../../utility';
import CreatePlantPopup from '../../Popup/CreatePlantPopup';
import APIService from '../../../../api/apiService';
import { EditIcon2 , IconHistory} from '../../../utility/CustomSvgIcons';
import { IconButton } from '@mui/material';
import _PlantHistoryPopup from './_PlantHistoryPopup';


const plantKeyTitleMap = [
  { key: 'batch_name', title: 'Batch Name' },
  { key: 'strain_name', title: 'Strain Name' },
  { key: 'metrc_tag_number', title: 'Metric Tag I.D' },
  { key: 'plant_phase', title: 'Growth Phase' },
  { key: 'wet_weight', title: 'Wet Weight' },
  { key: 'dry_weight', title: 'Dry Weight' },
  { key: 'frtl', title: 'FRTL' },
];

const _PlantsTab = props => {
  const { plants = [], currDarkThemeClass = '', values: parentValues, handleSetValues, handleErrorWithFeedback } = props;

  const plantTableTitles = useMemo(() => plantKeyTitleMap.map(e => ({ title: e.title })), []);

  const [values, setValues] = useState({
    plantDetails: plants,
    selectedRows: [],
    selectAll: false,
    openCreatePlant: false,
    isEditMode: false,
    currentPlant: null,
    openHistoryPopup: false,
    plantHistory: [],

  });

  const theme = useTheme();
  useEffect(() => {
    setValues(state => ({ ...state, plantDetails: plants }));
  }, [plants]);

  const handlePlantAddRes = res => {
    const { data } = res;
    handleSetValues('plants', [...parentValues?.plants, data]);
    setValues(state => ({ ...state, openCreatePlant: false }));
  };

  const handleEditPlantRes = res => {
    const { data } = res;
    const updatedPlants = values.plantDetails.map(plant => (plant.id === data.id ? data : plant));
    handleSetValues('plants', updatedPlants);
    setValues(state => ({ ...state, openCreatePlant: false, isEditMode: false, currentPlant: null }));
  };
  const handleHistoryIconClick = plant => {
    setValues(state => ({
      ...state,
      openHistoryPopup: true,
      plantHistory: plant.plant_phase_history || [],
      currentMetricTag: plant.metrc_tag_number
    }));
  };
  const handleCloseHistoryPopup = () => {
    setValues(state => ({
      ...state,
      openHistoryPopup: false,
      plantHistory: [],
    }));
  };
  
  const handleDeletePlantRes = res => {
    const { data } = res;
    const updatedPlants = values.plantDetails.filter(plant => !values.selectedRows.includes(plant.id));
    handleSetValues('plants', updatedPlants);
    setValues(state => ({ ...state, selectedRows: [], selectAll: false }));
  };

  const handleAddPlant = payload => {
    const entity = `cropplan-plants/`;
    // if (Object.values(payload).some(val => val === null || val === undefined)) return; // Check for missing values
    if (values.isEditMode) {
      handleEditPlant(payload);
    } else {
      APIService.createInstance(entity, payload)
        .then(handlePlantAddRes)
        .catch(handleErrorWithFeedback(errFeedbackFn({})));
    }
  };

  const handleEditPlant = payload => {
    const entity = `cropplan-plants/${payload.id}/`;
    APIService.modifyInstance(entity, payload)
      .then(handleEditPlantRes)
      .catch(handleErrorWithFeedback(errFeedbackFn({})));
  };

  const handleDeletePlant = () => {
    const { selectedRows } = values;
    if (selectedRows.length === 0) return;
    const promises = selectedRows.map(id => APIService.deleteInstance(`cropplan-plants/${id}/`));
    Promise.all(promises)
      .then(handleDeletePlantRes)
      .catch(handleErrorWithFeedback(errFeedbackFn({})));
  };

  const handleAddPlantDetails = () => {
    setValues(state => ({
      ...state,
      openCreatePlant: true,
      isEditMode: false,
      currentPlant: null,
    }));
  };

  const handleEditPlantDetails = plant => {
    setValues(state => ({
      ...state,
      openCreatePlant: true,
      isEditMode: true,
      currentPlant: plant,
    }));
  };

  const handleDestroyPlants = () => {
    const { plantDetails, selectedRows } = values;
    if (selectedRows.length > 0) {
      const plantsToDelete = selectedRows.map(id => plantDetails.find(plant => plant.id === id));
      const promises = plantsToDelete.map(plant => APIService.deleteInstance(`cropplan-plants/${plant.id}/`));
      Promise.all(promises)
        .then(() => {
          const updatedPlants = plantDetails.filter(plant => !selectedRows.includes(plant.id));
          setValues(prevState => ({
            ...prevState,
            plantDetails: updatedPlants,
            selectedRows: [],
            selectAll: false,
          }));
        })
        .catch(handleErrorWithFeedback(errFeedbackFn({})));
    }
  };

  const actionBtnsList = [
    { key: 'Create Plant', onClick: handleAddPlantDetails },
    { key: 'Destroy', onClick: handleDestroyPlants },
    { key: 'Phase Change' },
    { key: 'Create Crop Run' },
    { key: 'Split Planting' },
    { key: 'Rename' },
  ].map((e, i) => (
    <Button key={i} style={{ color: theme?.palette?.primary?.['400'] }} onClick={e.onClick}>
      {e.key}
    </Button>
  ));

  const handleCheckboxChange = id => {
    setValues(prevData => {
      const { selectedRows } = prevData;
      const updatedSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter(selectedId => selectedId !== id)
        : [...selectedRows, id];
      return {
        ...prevData,
        selectedRows: updatedSelectedRows,
        selectAll: false,
      };
    });
  };

  const handleSelectAllChange = () => {
    setValues(prevData => {
      const { plantDetails, selectAll } = prevData;
      const updatedSelectAll = !selectAll;
      const updatedSelectedRows = updatedSelectAll ? plantDetails.map(plant => plant.id) : [];
      return {
        ...prevData,
        selectAll: updatedSelectAll,
        selectedRows: updatedSelectedRows,
      };
    });
  };

  const plantSubTypoProps = {
    className: `${roomDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`,
  };

  const detailplantsPaperProps = {
    className: `${roomDetailClasses?.detailLightsPaper} ${currDarkThemeClass} roomDetailTabsContent`,
    square: true,
    variant: 'none',
  };

  const actionProps = {
    style: { color: theme?.palette?.primary?.['400'] },
  };

  const plantEntries = values.plantDetails.map((val, idx) => [
    <Checkbox
      key={`checkbox-${idx}`}
      checked={values.selectAll || values.selectedRows.includes(val.id)}
      onChange={() => handleCheckboxChange(val.id)}
      style={{ color: theme?.palette?.grey?.['400'] }}
    />,
    `${idx + 1}`.padStart(2, ''),
    ...plantKeyTitleMap.map(e => val[e.key]),
    <Stack direction="row" spacing={1}>
      <IconButton key={`edit-${idx}`} onClick={() => handleEditPlantDetails(val)}>
        <EditIcon2 style={{ color: 'inherit' }} />
      </IconButton>
      <IconButton onClick={() => handleHistoryIconClick(val)}>
        <IconHistory style={{ color: 'grey' }} />
      </IconButton>
    </Stack>,
  ]);

  const plantsTableValues = {
    entity: 'Plants',
    tableEntries: plantEntries,
    toShowAddBtn: false,
    tableTitles: [
      {
        title: (
          <Checkbox
            checked={values.selectAll}
            onChange={handleSelectAllChange}
            style={{ color: theme?.palette?.grey?.['400'] }}
          />
        ),
      },
      { title: '#' },
      ...plantTableTitles,
      { title: 'Actions' },
    ],
    animationClass: 'farmsTabContent',
    currDarkThemeClass,
  };

  const pActionStackProps = {
    direction: 'row',
    spacing: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
  };

  const pTPlantPopupProps = {
    open: values?.openCreatePlant,
    currDarkThemeClass,
    parentValues,
    handleSetValues,
    handleAddPlant,
    isEditMode: values?.isEditMode,
    currentPlant: values?.currentPlant,
    onClose: () => setValues(state => ({ ...state, openCreatePlant: false, isEditMode: false, currentPlant: null })),
  };

  const historyPopupProps = {
    open: values.openHistoryPopup,
    onClose: handleCloseHistoryPopup,
    history: values.plantHistory,
    currDarkThemeClass: currDarkThemeClass,
      metrcTagNumber: values.currentMetricTag  
  };
  return (
    <Fade in={true} timeout={1000}>
      <Paper {...detailplantsPaperProps}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
          <Typography {...plantSubTypoProps}>{`Plants (${plants?.length ?? 0})`}</Typography>
          <Stack {...pActionStackProps}>{actionBtnsList}</Stack>
        </Stack>
        <DisplayTable {...plantsTableValues} />
        <CreatePlantPopup {...pTPlantPopupProps} />
        <_PlantHistoryPopup {...historyPopupProps} />
      </Paper>
    </Fade>
  );
};

export default _PlantsTab;
