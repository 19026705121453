import React from 'react'
import { Card, CardActions, CardHeader, Typography, Button, Stack } from '@mui/material';
import { DelIcon2 } from '../CustomSvgIcons';
import { defFunc, metricPlanCardClasses } from '..';

// constants
const defBaseEntity = 'Entity';
const defNoEntityMsg = `Unknown ${defBaseEntity}`;
const defSubHeader = <Typography>{defBaseEntity}</Typography>;
const defCardActionBtns = [
    {
        key: 'delete',
        title: 'Remove',
        icon: DelIcon2,
        otherClasses: metricPlanCardClasses.delBtn,
        otherProps: {
            onClick: () => console.log('delete clicked')
        }
    }
]

export default function BaseDetailCard(props) {
    const {
        baseEntity = defBaseEntity,
        extrasTitle = '',
        noEntityMsg = defNoEntityMsg,
        currDarkThemeClass = '',
        subHeader = defSubHeader,
        cardActionBtns = defCardActionBtns,
        isHeaderClickable = false,
        headerClickHandler = defFunc,
    } = props;

    const baseCardProps = {
        className: `${metricPlanCardClasses.card} ${currDarkThemeClass}`,
        elevation: 0
    };

    const cdTitleTypoProps = {
      className: `${metricPlanCardClasses.titleTypo} ${currDarkThemeClass}`,
      ...(isHeaderClickable && { sx: { cursor: 'pointer' } }),
    };

    const cdLinkIconProps = {
        className: `${metricPlanCardClasses.linkIcon} ${currDarkThemeClass}`
    }

    const cdSubHeaderBtnProps = (iconPosition, Icon, otherClasses = '', othersProps = {}) => ({
        className: `${metricPlanCardClasses.button} ${currDarkThemeClass} ${otherClasses}`,
        disableRipple: true,
        [iconPosition]: <Icon  {...cdLinkIconProps} />,
        ...othersProps
    })

    const cdActionsProps = {
        className: `${metricPlanCardClasses.cardActions} ${currDarkThemeClass}`
    }


    const cdHeaderProps = {
        disableTypography: true,
        title: <Typography {...cdTitleTypoProps}>{baseEntity ?? noEntityMsg} {extrasTitle}</Typography>,
        subheader: subHeader,
        ...(isHeaderClickable && { onClick: headerClickHandler})
    }

    return <Card {...baseCardProps}>
        <CardHeader {...cdHeaderProps} />
        <CardActions {...cdActionsProps}>
            {
                cardActionBtns.map((item) => (<Button {...cdSubHeaderBtnProps('startIcon', item?.icon, item?.otherClasses ?? '', item?.otherProps)} key={item?.key}>{item?.title}</Button>))
            }
        </CardActions>
    </Card>
}