import React, { createContext, useState } from 'react';

export const FRTLContext = createContext({
    frtlContextValue: null,
    setFrtlContext: () => { }
});

export default function FRTLContextProvider({ children }) {
    const [frtlContextValue, setFrtlContextValue] = useState(null);

    return (
        <FRTLContext.Provider value={{ frtlContextValue, setFrtlContextValue }}>
            {children}
        </FRTLContext.Provider>
    );
}
