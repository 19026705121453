import { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import { actionBtnWd, confirmationBoxMaxWd, popupClasses } from "./constants"; 

// Show loading in Proceed Btn or somewhere else

export default function ConfirmationPopupWithLoading(props) {
    
    const {
        // loading=false,
        maxWidth=confirmationBoxMaxWd,
        onClose=() => console.log('close modal'),
        // onDecline=() => console.log('decline'),
        onProceed=() => () => console.log('proceed'),
        open=false,

        declineMsg='No',
        message='Are You Sure You Want To Continue?',
        proceedMsg='Yes', 
        title='This action cannot be reversed!',
        currDarkThemeClass='',
    } = props;

    const theme = useTheme();

    const [loading, setLoading] = useState(false);

    const cPModalProps = {
        className: `${popupClasses?.confirmationWithLoading?.modal} ${currDarkThemeClass}`,
        open,
        onClose,
    };

    const cPWrapperBoxProps = {
        className: `${popupClasses?.confirmationWithLoading?.wrapperBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     minHeight: '100vh',
        // },
    };

    const cPContentBoxProps = {
        className: `${popupClasses?.confirmationWithLoading?.contentBox} ${currDarkThemeClass}`,
        sx: {
            // backgroundColor: 'primary.100',
            // px: '72px',
            // py: '60px',
            // width: '100%',
            maxWidth,
        },
    };

    const cPTitleBoxProps = {
        className: `${popupClasses?.confirmationWithLoading?.titleBox} ${currDarkThemeClass}`,
    };

    const cPTitleTypoProps = {
        className: `${popupClasses?.confirmationWithLoading?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.800',
        //     fontSize: '24px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        // },
    };
    
    const cPMessageBoxProps = {
        className: `${popupClasses?.confirmationWithLoading?.messageBox} ${currDarkThemeClass}`,
    };


    const cPMessageTypoProps = {
        className: `${popupClasses?.confirmationWithLoading?.messageTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'primary.900',
        //     mt: '16px',
        // },
    };
    const cPActionBtnsBoxProps = {
        className: `${popupClasses?.confirmationWithLoading?.actionBtnsBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'flex-end',
        //     mt: '32px',
        // },
    };

    const cPDeclineBtnProps = {
        // onClick: onDecline,
        disabled: loading,
        onClick: onClose,
        className: `${popupClasses?.confirmationWithLoading?.declineBtn} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'grey.400',
        //     borderRadius: 0,
        //     color: 'grey.200',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05em',
        //     lineHeight: 1.25,
        //     p: '12px',
        //     textTransform: 'none',
            // width: `${actionBtnWd}px`,
        //     '&:hover': {
        //         backgroundColor: 'grey.400',
        //     },
        // },
    };

    const cPProgProps = {
        size: 16,
        style: {
            color: theme.palette.grey['500'],
        },
    }

    const onClick = async (event) => {
        const res = await onProceed(setLoading)(event);
        
        setLoading(false);

        if (res?.success) {
            onClose?.();
        }
    };

    const cPProceedBtnProps = {
        disabled: loading,
        // onClick: onProceed(setLoading),
        onClick,
        startIcon: loading && <CircularProgress {...cPProgProps} />,
        className: `${popupClasses?.confirmationWithLoading?.proceedBtn} ${currDarkThemeClass}`,
        // sx: {
        //     backgroundColor: 'primary.700',
        //     borderRadius: 0,
        //     color: 'primary.100',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     letterSpacing: '-0.05em',
        //     lineHeight: 1.25,
        //     ml: '16px',
        //     p: '12px',
        //     textTransform: 'none',
        //     width: `${actionBtnWd}px`,
        //     '&:hover': {
        //         backgroundColor: 'primary.700',
        //     },
        // },
    };

    return (
        <Modal {...cPModalProps}>
            <Box {...cPWrapperBoxProps}>
                <Box {...cPContentBoxProps}>
                    <Box {...cPTitleBoxProps}>
                        <Typography {...cPTitleTypoProps}>{title}</Typography>
                    </Box>
                    {/* <Divider {...cPDividerProps} /> */}
                    <Box {...cPMessageBoxProps}>
                        <Typography {...cPMessageTypoProps}>{message}</Typography>
                    </Box>
                    <Box {...cPActionBtnsBoxProps}>
                        <Button {...cPDeclineBtnProps}>{declineMsg}</Button>
                        <Button {...cPProceedBtnProps}>{proceedMsg}</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}