export default function hasNotCompletedSetup(tokenDetails) {
    // Should you check for metric_id if already checking for metrcSetup ??
    // const {orgSetup, metrcSetup, farmSetup, metrc_id} = tokenDetails;
    
    // const {orgSetup, metrcSetup, farmSetup} = tokenDetails || '';
    const {orgSetup, metrcSetup} = tokenDetails || '';

    return !orgSetup || !metrcSetup;
}

export function failsTokenAndSetupCheck(token, tokenDetails) {
    // const {orgSetup, metrcSetup} = tokenDetails || '';

    // return !token || !orgSetup || !metrcSetup;
    return !token || hasNotCompletedSetup(tokenDetails);
}