import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { CalendarIcon, DelIcon2, EditIcon2, InfoIcon, ProfileIcon } from './CustomSvgIcons';
import { compClasses } from './constants';
import { roomDetailClasses } from './constants';

export default function DetailAndDelBtn(props) {
  const {
    onDetailClick = () => console.log('Show Detail'),
    onDelClick = () => console.log('Delete entity'),
    onProfileClick = () => console.log('Navigate to Profile/Instructions'),
    onCalendarClick = () => console.log('Navigate to Schedule'),
    onInfoClick = () => console.log('Navigate to Info'),
    toShowDelBtn = true,
    toShowInfoBtn = false,
    toShowProfileBtn = false,
    toShowCalendarBtn = false,
    toShowDetailBtn = true,
    currDarkThemeClass = '',
  } = props;

  const detailBtnProps = {
    className: `${roomDetailClasses?.editIcon}`,
    disabled: !toShowDetailBtn,
    onClick: toShowDetailBtn ? onDetailClick : () => {},
    sx: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };

  const delBtnProps = {
    className: `${roomDetailClasses?.editIcon} ${currDarkThemeClass}`,
    disabled: !toShowDelBtn,
    onClick: toShowDelBtn ? onDelClick : () => {},
    sx: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>

{toShowProfileBtn && (
        <Tooltip title="Assignee" arrow>
          <span>
            <ProfileIcon onClick={onProfileClick} />
          </span>
        </Tooltip>
      )}
      
      {toShowCalendarBtn && (
        <Tooltip title="Schedule" arrow>
          <span>
            <CalendarIcon onClick={onCalendarClick} />
          </span>
        </Tooltip>
      )}
      
      {toShowInfoBtn && (
        <Tooltip title="Instruction" arrow>
          <span>
            <InfoIcon onClick={onInfoClick} />
          </span>
        </Tooltip>
      )}

      {toShowDetailBtn && (
        <Tooltip title="Edit" arrow>
          <span>
            <EditIcon2 {...detailBtnProps} />
          </span>
        </Tooltip>
      )}
      {toShowDelBtn && (
        <Tooltip title="Delete" arrow>
          <span>
            <DelIcon2 {...delBtnProps} />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
}
