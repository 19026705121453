import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import ProgressRing from "../charts/ProgressRing";
import useThemeVal from "../../../utility/useThemeVal";

import { StepCheckedIcon, StepConnectorIcon, StepUnCheckedIcon } from "../CustomSvgIcons";
import { 
    fadeTimingMs, NEW, 
    getWizardDetails, setWizardDetails, wizardClasses,
    defaultNoAuthRoute,defaultNoMetrcRoute,landingPageRoute, getRoomDetailRoute, FARM_WIZARD,
    profileClasses,
} from "../constants";

// import APIService from "../../../api/apiService";
import getTokenDetails from "../../../auth/getTokenDetails";
import hasNotCompletedSetup from "../../../checks/setup";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Margin } from "@mui/icons-material";


// const infoTitle = 'Farm Setup Completion Progress';
// const infoSubTitle = `Don't worry if its taking too long. Save your progress and Come back later to Continue from here`;

// const todoMap = [
//     {stepTitle: 'Add your Org', step: 0},
//     {stepTitle: 'Compliance Org Key', step: 1},
//     {stepTitle: 'Add your farm', step: 2},
//     {stepTitle: 'Add your room', step: 3, linkFn: getRoomDetailRoute, link: farmsHomeWithTabRoute},
// ];

// const stepCount = todoMap.length;

// const todoTitle = 'List of things on your Plate';

// const handleSetup = (handleSetValues) => () => {
//     const {current} = JSON.parse(localStorage?.farmWizardSteps ?? '{}');

//     // executeStep = current while executing , so current is the source

//     if (+current >= stepCount) {
//         handleSetValues('finishSetup', true);
//         return;
//     }

//     if (!current || isNaN(+current)) {
//         handleSetValues('executeStep', 0);
//     } else if (!isNaN(+current)) {
//         handleSetValues('executeStep', +current);
//     }
// }

const handleActionBtnClick = (handleSetValues, wizardType, baseWizardStep, stepCount) => () => {
    if (!wizardType) return;
    
    const {current} = getWizardDetails(wizardType);
    
    if (+current >= stepCount) {
        handleSetValues('finishSetup', true);
        return;
    }
    
    // executeStep sets to current while executing, so current is the source
    if (!current || isNaN(+current)) {
        handleSetValues('executeStep', baseWizardStep);
    } else if (!isNaN(+current)) {
        handleSetValues('executeStep', +current);
    }

};


export default function BaseWizard(props) {

    const {
        baseWizardStep=0,
        completeActionTypo='Finish',
        currDarkThemeClass='',
        finishSetupRoute,
        incompleteActionTypo='Complete Setup',
        infoTitle='Setup Completion Progress',
        infoSubTitle=`Don't worry if its taking too long. Save your progress and Come back later to Continue from here`,
        otherBaseWizardClasses={},
        permCheck=false,
        stepCount=0,
        renderFarmSelection,
        todoMap=[],
        todoTitle='List of things on your Plate',
        values={},
        wizardType='',
        selectedFarmId,
        // setValues=()=>{},
        handleExecuteStep=()=>{},
        handleSetValues=()=>{},
        navigate=()=>{},
        // onActionBtnClick=()=>{},
    } = props;

    const {token, tokenDetails} = getTokenDetails(); // 12-04-2023: Eventually replace with useToken() that automatically navigates away for failed token checks

    useEffect(() => {
        // const {current, lastStepCompleted=false} = JSON.parse(localStorage?.farmWizardSteps ?? '{}'); // 11-04-2023: TODO: Need to add a verification step before simply setting lastStepCompleted
        const {current, lastStepCompleted=false} = getWizardDetails(wizardType);
        
        // Not setting executeStep here ensures no execution will take place upon page reload
        if ((!current && current !== 0) || isNaN(+current)) {
            
            const res = {
                action: null,
                current: baseWizardStep,
                lastStepCompleted: false,
                execute: false,
            };

            setWizardDetails(wizardType, res);

            handleSetValues('currStep', baseWizardStep);
        } else if (lastStepCompleted) {
                        
            const res = {
                action: null,
                current: +current + 1,
                lastStepCompleted: false,
                execute: false,
            };

            setWizardDetails(wizardType, res);

            handleSetValues('currStep', +current + 1);
        } else {
            handleSetValues('currStep', +current);
        }

    }, []);

    useEffect(() => {
        if ( !values?.executeStep && values?.executeStep !== 0 ) return;

        handleExecuteStep(values?.executeStep);

    }, [values?.executeStep]);
    
    useEffect(() => {
        if (values?.finishSetup) {
            localStorage.removeItem(wizardType);

            if (!finishSetupRoute) {
                navigate(landingPageRoute);
            } else {
                navigate(finishSetupRoute);
            }
        }
    }, [values?.finishSetup])

    useEffect(() => {
        if (!token) {
            navigate(defaultNoAuthRoute);
        } else if (hasNotCompletedSetup(tokenDetails)) {
            navigate(defaultNoMetrcRoute)
        }

        if (permCheck) { // 13-04-2023: Will need to add a feedback mechanism to let the user know if he doesn't have permission to do something
            navigate(landingPageRoute);
        }

    }, [token]);

    if (!token || hasNotCompletedSetup(tokenDetails) || permCheck) return <></>; // No hooks can be created beyond this point without commenting it out


    const boxProps = {
        className: `${wizardClasses?.base?.box} ${currDarkThemeClass} ${otherBaseWizardClasses?.box}`,
    };

    const contentBoxProps = {
        className: `${wizardClasses?.base?.contentBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.contentBox}`,
    };

    const leftBoxProps = {
        className: `${wizardClasses?.base?.leftBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.leftBox}`,
    };

    const ringBoxProps = {
        className: `${wizardClasses?.base?.ringBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.ringBox}`,
    };

    const completionCent = !values?.currStep ? 0 : +values?.currStep < stepCount ? parseFloat(+values?.currStep / stepCount).toFixed(2) : 1;

    const progRingValue = `${Math.round(completionCent * 100)}%`;

    const progRingProps = {
        completionCent,
        currDarkThemeClass,
        value: progRingValue,
    };

    const infoTypoProps = {
        className: `${wizardClasses?.base?.infoTypo} ${currDarkThemeClass} ${otherBaseWizardClasses?.infoTypo}`,
    };

    const infoSubTypoProps = {
        className: `${wizardClasses?.base?.infoSubTypo} ${currDarkThemeClass} ${otherBaseWizardClasses?.infoSubTypo}`,
    };

    const actionBtnProps = {
        // children: values?.currStep >= stepCount ? 'Completed Setup' : 'Complete My Farm Setup',
        children: values?.currStep >= stepCount ? completeActionTypo : incompleteActionTypo,
        className: `${wizardClasses?.base?.actionBtn} ${currDarkThemeClass} ${otherBaseWizardClasses?.actionBtn}`,
        onClick: handleActionBtnClick(handleSetValues, wizardType, baseWizardStep, stepCount),
        variant: 'outlined',
    };

    const rightBoxProps = {
        className: `${wizardClasses?.base?.rightBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.rightBox}`,
    };

    const todoTypoProps = {
        className: `${wizardClasses?.base?.todoTypo} ${currDarkThemeClass} ${otherBaseWizardClasses?.todoTypo}`,
    };

    const todoListBoxProps = {
        className: `${wizardClasses?.base?.todoListBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.todoListBox}`,
    };

    const stepConnectorIconProps = (e) => ({
        className: `${wizardClasses?.base?.stepConnectorIcon} ${currDarkThemeClass} ${e?.step < values?.currStep ? wizardClasses?.base?.currStep : ''} ${otherBaseWizardClasses?.stepConnectorIcon}`,
    });

    const todoItemBoxProps = {
        className: `${wizardClasses?.base?.todoItemBox} ${currDarkThemeClass} ${otherBaseWizardClasses?.todoItemBox}`,
    };
    
    const stepStatusIconProps = {
        className: `${wizardClasses?.base?.stepStatusIcon} ${currDarkThemeClass} ${otherBaseWizardClasses?.stepStatusIcon}`,
    };

    const todoStepTypoProps = (e) => ({
        className: `${wizardClasses?.base?.todoStepTypo} ${currDarkThemeClass} ${e?.step === values?.currStep ? wizardClasses?.base?.currStep : ''} ${otherBaseWizardClasses?.todoStepTypo}`,
    });


    const todoList = todoMap?.map((e, i) => (
        <Fragment key={i}>
            { i > 0 && <StepConnectorIcon {...stepConnectorIconProps(e)} /> }
            <Box {...todoItemBoxProps}>
                { e?.step < values?.currStep ? <StepCheckedIcon {...stepStatusIconProps} /> : <StepUnCheckedIcon {...stepStatusIconProps} /> }
                <Typography {...todoStepTypoProps(e)}>{e?.stepTitle}</Typography>
            </Box>
        </Fragment>
    ));
    
    const handleAddRoom = () => {
        // Use the selectedFarm from the parent component's values
        const selectedFarm = values?.selectedFarm;
        
        if (!selectedFarm?.id) {
            // If no farm is selected in the parent, we can't proceed
            return;
        }
    
        const res = {
            action: FARM_WIZARD.ROOM_CREATE,
            current: baseWizardStep,
            execute: true,
        };
    
        setWizardDetails(wizardType, res);
    
        // Use the selectedFarm.id from the parent component
        const link = getRoomDetailRoute(selectedFarmId, NEW);
        navigate(link);
    };

 
    
    // console.log(values?.selectedFarm);
    const saveBtnProps = {
        onClick: handleAddRoom,
        disabled: !values?.selectedFarm || (values?.selectedFarm?.total_metrc_room_mapped === values?.selectedFarm?.room_count),
        className: `${profileClasses?.saveBtn} ${currDarkThemeClass}`,
        type: 'submit',
        variant: 'contained',
        children: 'Add Room',
        sx: {
            mt: '24px',
        },
    };

    const renderAddRoomButton = () => {
        if (values?.selectedFarm && values?.currStep >= 3 && values?.currStep <= 4) {
            return <Button {...saveBtnProps} />;
        }
        return null;
    };
    
    return (
        <Box {...boxProps}>
            <Fade in={true} timeout={fadeTimingMs}>
                <Box {...contentBoxProps}>
                    <Box {...leftBoxProps}>
                        <Box {...ringBoxProps}>
                            <ProgressRing {...progRingProps} />
                        </Box>
                        <Typography {...infoTypoProps}>{infoTitle}</Typography>
                        <Typography {...infoSubTypoProps}>{infoSubTitle}</Typography>
                        <Button {...actionBtnProps} />
                    </Box>
                    <Box {...rightBoxProps}>
                        <Typography {...todoTypoProps}>{todoTitle}</Typography>
                        <Box {...todoListBoxProps}>
                            {todoList}
                        </Box>
                        {renderFarmSelection()}
                        {renderAddRoomButton()}
                    </Box>                  
                </Box>
            </Fade>
        </Box>
    );
}