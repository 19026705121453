import React, { useCallback, useEffect, useState } from 'react';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import { DownArrowIcon, EyeIcon, cropClasses, miscClasses, popupClasses } from '../../utility';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import CropRunFRTLPopup from '../../utility/popups/CropRunFRTLPopup';

export default function CreatePlantPopup(props) {
  const { open, onClose, currDarkThemeClass, parentValues, handleAddPlant, isEditMode, currentPlant } = props;

  const [values, setValues] = useState({
    batch_name: '',
    strain_name: '',
    metrc_tag_number: '',
    plant_phase: '',
    wet_weight: '',
    dry_weight: '',
    frtl: '',
    selectedStrain: '',
    batches_options: [],
    batch: '',
  });

  const [metrcError, setMetrcError] = useState(false); 

  useEffect(() => {
    if (isEditMode && currentPlant) {
      const selectedStrain = parentValues?.strains?.find(e => e.strain_name === currentPlant.strain_name) ?? {};
      setValues({
        ...currentPlant,
        selectedStrain: selectedStrain?.id || '',
        batches_options: selectedStrain?.batches || [],
        batch: currentPlant.batch || '',
      });
    } else {
      setValues({
        batch_name: '',
        strain_name: '',
        metrc_tag_number: '',
        wet_weight: '',
        dry_weight: '',
        plant_phase: '',
        frtl: '',
        selectedStrain: '',
        batches_options: [],
        batch: '',
      });
    }
  }, [isEditMode, currentPlant, parentValues]);

  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), []);

  const handleMetrcChange = (value) => {
    // Check if Metric Tag ID has exactly 24 digits
    if (value.length === 24) {
      setMetrcError(false);
    } else {
      setMetrcError(true);
    }
    handleSetValues('metrc_tag_number', value);
  };

  const eDPopupProps = {
    open,
    onClose,
    currDarkThemeClass,
    headContentText: isEditMode ? 'Edit Your Plant' : 'Add Your Plant',
    onSaveClick: () => handleAddPlant(values),
  };

  const cropPlanMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };
  
  const textFieldProp = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
    autoComplete: 'off',
    className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
    fullWidth: true,
    inputProps,
    label: '',
    onChange: ({ target: { value } }) => handleSetValues(prop, value),
    value: values?.[prop] ?? '',

    ...otherTextProps,
  });

  const strainsList =
    parentValues?.strains?.map((e, i) => (
      <MenuItem key={i} value={e?.id ?? e?.tempRef} {...cropPlanMenuItemProps}>
        {e?.strain_name}
      </MenuItem>
    )) ?? [];

  if (!strainsList?.length) {
    strainsList?.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Strain. Add Strain First.
      </MenuItem>
    );
  }

  const cppStrainProps = {
    prop: 'selectedStrain',
    otherTextProps: {
      label: 'Strain',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
      onChange: ({ target: { value } }) => {
        const selectedStrain = parentValues?.strains?.find(e => (e?.id && e?.id === value) || (e?.tempRef && e?.tempRef === value)) ?? {};
        handleSetValues('strain_name', selectedStrain?.strain_name ?? '');
        handleSetValues('selectedStrain', value);
        handleSetValues('batches_options', selectedStrain?.batches ?? []);
        handleSetValues('batch', ''); // Clear batch selection when strain changes
      },
    },
  };

  const batchesList =
    values?.batches_options?.map((e, i) => (
      <MenuItem key={i} value={e?.id ?? e?.tempRef} {...cropPlanMenuItemProps}>
        {e?.name}
      </MenuItem>
    )) ?? [];

  if (!batchesList?.length) {
    batchesList?.push(
      <MenuItem key={-1} value={-1} disabled {...cropPlanMenuItemProps}>
        No Available Batches in Selected Strain.
      </MenuItem>
    );
  }

  const cppBatchProps = {
    prop: 'batch',
    otherTextProps: {
      label: 'Batch',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
      disabled: isEditMode, // Disable batch selection when editing
    },
  };

  const cpGPProps = {
    prop: 'plant_phase',
    otherTextProps: {
      label: 'Growth Phase',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  };

  const cpDryWetProps = {
    prop: 'dry_weight',
    otherTextProps: {
      label: 'Dry Weight',
    },
  };

  const cpwtWetProps = {
    prop: 'wet_weight',
    otherTextProps: {
      label: 'Wet Weight',
    },
  };

  const growthPhaseOptions = [
    { value: 'Vegetative', label: 'Vegetative' },
    { value: 'Flowering', label: 'Flowering' },
    { value: 'Harvested', label: 'Harvested' },
    { value: 'Destroyed', label: 'Destroyed' },
  ];

  const cppMPProps = {
    prop: 'metrc_tag_number',
    otherTextProps: {
      label: 'Metric Tag I.D.',
      required: true,
      error: metrcError,
      helperText: metrcError ? 'Metric Tag ID must have exactly 24 digits' : null,
    },
  };

  const cppSmartgrowTagProps = {
    prop: 'smartgrow_tag_number',
    otherTextProps: {
      label: 'SmartGrow Tag Number',
      InputProps: {
        startAdornment: <InputAdornment position="start">SG</InputAdornment>,
      },
    },
  };

  const handleOpenFRTLPopup = () => {
    handleSetValues('frtlOpen', true);
  };

  const handleFRTLClose = () => {
    handleSetValues('frtlOpen', false);
  };

  const cppFrtlProps = {
    otherTextProps: {
      label: 'FRTL',
      value: values?.frtl ?? '',
      placeholder: 'FARM - ROOM - TABLE - LEVEL',
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleOpenFRTLPopup}>
              <EyeIcon />
            </IconButton>
          </InputAdornment>
        ),
      },
    },
    inputProps: {
      disabled: true,
    },
  };

  // Props for CropRunFRTLPopup
  const cropRunFRTLProps = {
    open: values?.frtlOpen,
    currDarkThemeClass,
    onClose: handleFRTLClose,
    handleEditFrtl: value => handleSetValues('frtl', value),
    selectedFarmId: parentValues?.farm, // Pass the farm_id from parentValues
    // If you have the current room, you can pass it here
    // currentRoom: parentValues?.room,
    farm: +values?.frtl?.split('.')[0] ?? '',
    room: +values?.frtl?.split('.')[1] ?? '',
    table: +values?.frtl?.split('.')[2] ?? '',
    level: +values?.frtl?.split('.')[3] ?? '',
  };

  return (
    <EntityDetailPopup {...eDPopupProps}>
      <TextField {...textFieldProp(cppStrainProps)}>{strainsList}</TextField>
      <TextField {...textFieldProp(cppBatchProps)}>{batchesList}</TextField>
      <TextField {...textFieldProp(cppSmartgrowTagProps)} />
      <TextField {...textFieldProp(cppMPProps)} onChange={(e) => handleMetrcChange(e.target.value)} /> 

      <TextField {...textFieldProp(cpGPProps)}>
        {growthPhaseOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      
      <TextField {...textFieldProp(cpwtWetProps)}/>
      <TextField {...textFieldProp(cpDryWetProps)}/>
      <TextField {...textFieldProp(cppFrtlProps)} />
    
      {values?.frtlOpen && <CropRunFRTLPopup {...cropRunFRTLProps} />}
    </EntityDetailPopup>
  );
}