import { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
// import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

import { CropPlanBreadcrumbs, CropRunBreadcrumbs } from '../../utility/BreadcrumbLinks';

import {
  detailCropPlanEndpoint,
  listCropPlanEndpoint,
  cropsRunNewAndDetailRoute,
  fadeTimingMs,
  cropPlanModelName,
  cropPlanAppLabel,
  NEW,
  cropplanNewKeys,
  crudActions,
  landingPageRoute,
  cropClasses,
  logMsgToConsole,
  cropsHomeRoute,
  getWizardRoute,
  PLAN_WIZARD_TYPE,
  PLAN_WIZARD,
  errFeedbackFn,
  getHandleErrorWithFeedbackCX,
  newCropPlanEndpoint,
  routinesCreateEndpoint,
} from '../../utility/constants';

import APIService from '../../../api/apiService';
import { failsTokenAndSetupCheck } from '../../../checks/setup';
import { isNotValidDetailPageView } from '../../../checks/detailPage';
// import getOptionsForGanttChart from "../../utility/getOptionsForGanttChart";
import { FeedbackContext } from '../../../App';
import useThemeVal from '../../../utility/useThemeVal';
import useToken from '../../../utility/useToken';
import _PlantsTab from './Tabs/_PlantTab';
import _SpecificsTab from './Tabs/_SpecificsTab';
import _PhasesTab from './Tabs/_PhasesTab';
import OverviewTab from './Tabs/OverviewTab';
import ReviewRoutine from '../Popup/ReviewRoutine';
import {
  getCropPlanFabState,
  getFabState,
  handleCropPlanNextStep,
  handleCropPlanPrevStep,
  handleRoomDetailNextStep,
  handleRoomDetailPrevStep,
} from '../../utility/exec';
import PrevNextFab from '../../utility/fabs/PrevNextFab';
import { hasPermissions } from '../../../utility/permissions';

// Note: Both metriccycles and suphases entries have start_day and end_day; Only prefix 'subphase' in metriccycle object

const title = 'Create Crop Run';

const tabs = {
  OVERVIEW: { key: 'overview', label: 'Overview' },
  SPECIFICS: { key: 'specifics', label: 'Specifics' },
  PHASES: { key: 'phases', label: 'Phases' },
  QUALITYPLAN: { key: 'qualityplan', label: 'QualityPlan' },
  PLANTS: { key: 'plants', label: 'Plants' },
};

const tabEntityMap = [
  { key: tabs.OVERVIEW.key, label: tabs.OVERVIEW.label, validForNew: false }, // defaultTab
  {
    key: tabs.SPECIFICS.key,
    label: tabs.SPECIFICS.label,
    validForNew: true,
    value: [...cropplanNewKeys, 'description', 'strains', 'run_status'],
    nextTab: tabs.PHASES.key,
  },
  { key: tabs.PHASES.key, label: tabs.PHASES.label, validForNew: true, prevTab: tabs.SPECIFICS.key },
  { key: tabs.QUALITYPLAN.key, label: tabs.QUALITYPLAN.label, validForNew: false },
  { key: tabs.PLANTS.key, label: tabs.PLANTS.label, validForNew: false },
];

const popupMap = { REVIEW: 'review' };

const cropPlanValidation = values => {
  let errMsg = '',
    isValidationFailed = false;
  // plant count validation
  // all batches plant count should be less than crop plan plant count
  const plantCount = values?.strains?.reduce((acc, curr) => acc + curr?.batches?.reduce((acc, curr) => acc + +curr?.plant_count, 0), 0);
  if (plantCount > values?.plant_count) {
    errMsg += `\n There are more plants in the batches than the crop plan allows.`;
    isValidationFailed = true;
  }
  return { isValidationFailed, errMsg };
};

let NewDefaultValues = { run_status: 1 };

const applyDefaults = (handleSetValues, defaults) => {
  Object.keys(defaults).forEach(key => {
    handleSetValues(key, defaults[key]);
  });
};

export default function CropPlanNewAndDetail(props) {
  const theme = useTheme();
  // const toggleRef = useRef();

  const [savedRes, setSavedRes] = useState({});
  const [values, setValues] = useState({});

  const feedbackCX = useContext(FeedbackContext);

  const { cropplanid } = useParams();
  // Optimisation:
  // const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]); // Obtain value once and memoise it
  const currDarkThemeClass = useThemeVal();

  let queryParam = 'created';
  

  if (window.location.pathname.includes(cropsRunNewAndDetailRoute)) {
    queryParam = 'Saved';
  }

  const validTabs = tabEntityMap.filter(tab => (cropplanid === NEW ? tab.validForNew : true)).map(e => e.key);
  const defaultTab = validTabs[0];

  const [tabValue, setTabValue] = useState(defaultTab);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const tab = searchParams.get('tab');

  // const {token, tokenDetails} = getTokenDetails();
  const { token, tokenDetails, permissions } = useToken();

  const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

  const { is_staff = false } = tokenDetails || '';

  const cropPlanPermissions = useMemo(() => permissions?.filter(perm => perm.includes(`_${cropPlanModelName}`)) ?? [], [token]);

  const cropPlanPerms = useMemo(() => hasPermissions([cropPlanModelName], { ...tokenDetails, permissions })[cropPlanModelName] ?? {}, [token]);

  const handleSetValues = (prop, value) => setValues(state => ({ ...state, [prop]: value }));
  const handleSetSavedRes = (prop, value) => setSavedRes(state => ({ ...state, [prop]: value }));
  const handleSetLoading = (value = false) => handleSetValues('loading', value) ;
  const handleSetProcessing = (value = false) => handleSetValues('processing', value);

  const setValuesAndSavedRes = (data = {}) => {
    setValues(state => ({ ...state, ...data }));
    setSavedRes(state => ({ ...state, ...data }));

    const { run_status, owner } = data;
    
    //if status is true then user selected status otherwise defulat value is 1 
    NewDefaultValues = { run_status: run_status ? run_status : 1 };
    // set default values for new cropplan
    applyDefaults(handleSetValues, { ...NewDefaultValues, owner: owner });
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setSearchParams({ tab: newValue });
  };

  const handleError = err => {
    console.log('An error occured');
    console.log(err);

    if (cropplanid !== NEW && err?.response?.status === 404) {
      navigate(landingPageRoute);
    }
  };

  const handleErrorWithFeedback = getHandleErrorWithFeedbackCX(feedbackCX);

  const handleFinally = () => {
    handleSetLoading(false);
    handleSetProcessing(false);
  };

  const createCropRunFinally = () => {
    handleSetLoading(false);
    handleSetProcessing(false);
    feedbackCX.setContextValue(true, {
      type: 'success',
      message: 'Created Crop Run successfully.',
      autoHideDuration: 3000
    });
  };

  const handlePopupClose =
    (popup, closeall = false) =>
    () => {
      handleSetValues(popup, false);

      if (closeall) Object.values(popupMap).map(value => handleSetValues(value, false));
    };

  const handleRoutineSetup = cropplanid => res => {
    const defRoute = `${cropsRunNewAndDetailRoute}/${cropplanid}/`;

    const route = getWizardRoute(PLAN_WIZARD_TYPE, PLAN_WIZARD.CROPPLAN_CREATE, defRoute);
    navigate(route);
  };

  const handleCropPlanCreateRes = res => {
    const {
      data: { id, status, batch_tempRef_map, strain_tempRef_map },
    } = res;

    handleSetLoading(true);

    const entity = routinesCreateEndpoint;

    let payload = [];

    // prepare payload
    let strainsWithRoutines = values?.defStrainWithDefRoutines ?? [];

    for (let strain of strainsWithRoutines) {
      for (let phase of strain?.phases ?? []) {
        for (let subphase of phase?.subphases ?? []) {
          for (let defRoutine of subphase?.routines ?? []) {
            payload.push({
              ...defRoutine,
              cropplan: id,
              phaseplan: phase?.id,
              default_routine: defRoutine?.id,
              routine_instruction: defRoutine?.default_routine_instruction,
              routine_level: 2,
              routine_level_id: strain_tempRef_map[strain?.tempRef] ?? -1,
              subphase: subphase?.id,
              routine_tasks: (defRoutine?.routine_tasks || []).map(task => ({
                ...task,
                task_level_id: batch_tempRef_map[task?.tempRef] ?? -1,
              })),
            });
          }
        }
      }
    }

    APIService.createInstance(entity, payload).then(handleRoutineSetup(id)).catch(handleError).finally(handleFinally);
  };

  const handleCropPlanRes = res => {
    if (logMsgToConsole?.crop) {
      console.log('crop plan fetched');
      console.log(res);
    }
    
    const { data } = res;

    setValuesAndSavedRes(data);
  };

  const handleCropPlanNewRes = res => {
    if (logMsgToConsole?.crop) {
      console.log('crop plan fetched');
      console.log(res);
    }

    const { data } = res;

    setValuesAndSavedRes(data);
  };

  const handleCropPlanModifyRes = res => {
    const { data } = res;

    const { id, status, run_status } = data;
     NewDefaultValues = { run_status: run_status };

    setValuesAndSavedRes(data);

    handleSetValues('statusInfo', null);

    navigate(`${cropsRunNewAndDetailRoute}/${id}/`);
  };

  const [loading, setLoading] = useState({});
  const showPrevNextFab = useMemo(
    () => cropplanid === NEW && !loading?.CropPlanNewAndDetail && !loading?.save && tabValue !== 'overview',
    [loading?.save, loading?.CropPlanNewAndDetail, tabValue, cropplanid]
  );

  useEffect(() => {
    if (!tab || !validTabs.includes(tab)) {
      handleSetTabValue(null, defaultTab);
    } else {
      // if (cropplanid === NEW && tab === 'overview') { // comparison check for when more than one tab in /new
      handleSetTabValue(null, tab);
    }
  }, [tab]);

  useEffect(() => {
    // if (!token || hasNotCompletedSetup(tokenDetails)) return;
    if (invalidTokenState) return;

    let errConfig = {};

    const fetchNewDeps = () => {
      handleSetLoading(true);

      const entity = newCropPlanEndpoint;

      APIService.fetchInstance(entity)
        .then(handleCropPlanNewRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally);
    };

    const fetchCropPlanById = () => {
      handleSetLoading(true);

      const entityWithID = detailCropPlanEndpoint(cropplanid, queryParam);

      APIService.fetchInstance(entityWithID)
        .then(handleCropPlanRes)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(handleFinally);
    };

    if (cropplanid === NEW) {
      if (!cropPlanPerms?.create) return;

      fetchNewDeps();
    } else if (isNotValidDetailPageView(token, cropplanid)) return;
    else {
      if (!cropPlanPerms?.read) return;

      fetchNewDeps();
      fetchCropPlanById();
    }
  }, [cropplanid]);

  useEffect(() => {
    // if (!token) navigate(defaultNoAuthRoute)
    // else if (hasNotCompletedSetup(tokenDetails)) navigate(defaultNoMetrcRoute)
    // else if (hasInvalidNewAndDetailPerms(token, cropPlanPerms, cropplanid)) navigate(landingPageRoute)
    if (!invalidTokenState && !cropPlanPerms?.read) navigate(landingPageRoute);
  }, [token]);

  // if (hasInvalidNewAndDetailPerms(token, cropPlanPerms, cropplanid)) return <></>;
  if (invalidTokenState || !cropPlanPerms?.read) return <></>;

  const cropPlanBoxProps = {
    className: `${cropClasses?.box} customScroll ${currDarkThemeClass}`,
  };

  const cropPlanHeaderBoxProps = {
    className: `${cropClasses?.headerBox} ${currDarkThemeClass}`,
  };

  const cropPlanTitleBoxProps = {
    className: `${cropClasses?.titleBox} ${currDarkThemeClass}`,
  };

  const cropPlanTitleTypoProps = {
    className: `${cropClasses?.titleTypo} ${currDarkThemeClass}`,
  };

  const breadcrumbsProps = {
    currDarkThemeClass: currDarkThemeClass,
    currLink: cropplanid === NEW ? 'Create Crop Run' : savedRes?.name,
    navigate,
  };

  const cropPlanProgProps = {
    size: 16,
    style: {
      color: theme.palette.grey['500'],
    },
  };

  const toDisable = () => {
    // disable save details when overview tab is opened
    if (tabValue === tabs.OVERVIEW.key) return true;
    if (tabValue === tabs.PHASES.key && cropplanid !== NEW) return true;
    // disable save when there's no edit or create permissions
    if (cropplanid === NEW ? !cropPlanPerms?.create : !cropPlanPerms?.update) return;

    if (cropplanid === NEW) {
      return cropplanNewKeys.some(key => !values?.[key]);
    }

    const requiredKeys = [...cropplanNewKeys, 'description', 'strains', 'run_status'];
    const extractedValues = requiredKeys.reduce((acc, key) => {
      acc[key] = values[key];
      return acc;
    }, {});

    const extractedSavedRes = requiredKeys.reduce((acc, key) => {
      acc[key] = savedRes[key];
      return acc;
    }, {});
    return JSON.stringify(extractedSavedRes) === JSON.stringify(extractedValues);
    // return cropplanNewKeys.every(key => key === 'variable_days' ? +values?.[key] === +savedRes?.[key] : values?.[key] === savedRes?.[key]);
  };

  const handleSave = () => {
    if (toDisable() || (cropplanid !== NEW && !cropPlanPerms?.update) || values?.processing) return;

    handleSetProcessing(true);

    let errConfig = {};

    if (cropplanid === NEW) {
      errConfig = {
        create: true,
        resource: 'Crop Plan',
      };

      const entity = listCropPlanEndpoint;

      APIService.createInstance(entity, values)
        .then(handleCropPlanCreateRes)
        // .catch(handleError)
        // .finally(handleSetProcessing)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(createCropRunFinally);

      return;
    }

    const { value, prop, link } = tabEntityMap?.find(e => e?.key === tabValue) || '';

    if (!prop) {
      const entityWithID = detailCropPlanEndpoint(cropplanid, queryParam);
      const payload = Object.fromEntries(value.map(e => [e, values[e]]));

      // validation checks
      const { isValidationFailed, errMsg } = cropPlanValidation(values);
      if (isValidationFailed) {
        const errConfig = { severity: 'warning', finalErrMsg: errMsg };
        handleErrorWithFeedback(errFeedbackFn(errConfig))();
        handleSetProcessing(false);
        return;
      }

      const isSameStrains = JSON.stringify(values?.strains) === JSON.stringify(savedRes?.strains);
      if (isSameStrains) {
        payload.strains = []; // This will simply avoid making any changes to attached strains in backend
      } else {
        payload.metrc_rooms = values?.strains;
      }

      APIService.modifyInstance(entityWithID, payload)
        .then(handleCropPlanModifyRes)
        // .catch(handleError)
        // .finally(handleSetProcessing)
        .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
        .finally(() => {
          feedbackCX.setContextValue(true, {
            type: 'success',
            message: 'Crop Run updated successfully.',
            autoHideDuration: 3000,
          });
          handleFinally();
        });;
    } else {
      // todo: implement api call when there is prop to lookup for
    }
  };

  const handleReview = () => {
    handleSetValues(popupMap.REVIEW, true);
  };

  const cropPlanSaveBtnProps = {
    children: cropplanid === NEW ? 'Review & Create CropRun' : 'Save Details',
    className: `${cropClasses?.saveBtn} ${currDarkThemeClass}`,
    disabled: values?.processing || toDisable(),
    onClick: cropplanid === NEW ? handleReview : handleSave,
    startIcon: values?.processing && <CircularProgress {...cropPlanProgProps} />,
    variant: 'contained',
  };

  const cropPlanTabsProps = {
    className: `${cropClasses?.tabs} ${currDarkThemeClass}`,
    onChange: handleSetTabValue,
    TabIndicatorProps: {
      sx: {
        backgroundColor: currDarkThemeClass ? 'grey.200' : 'grey.700',
      },
    },
    value: tabValue ?? defaultTab,
  };

  const cropPlanTabProps = {
    className: `${cropClasses?.tab} ${currDarkThemeClass}`,
  };
  const TabName = 'Specifics';

  const rDTabProps = value => ({
    className: `${cropClasses?.tab} ${currDarkThemeClass} ${value === TabName && cropplanid === NEW ? cropClasses?.hideTab : ''}`,
    disabled: cropplanid === NEW,
  });

  const tabList = tabEntityMap
    .filter(tab => (cropplanid === NEW ? tab.validForNew : true))
    .map((e, i) => <Tab key={i} {...cropPlanTabProps} label={e.label} value={e.key} {...rDTabProps(e.key)} />);

  const cropPlanDividerProps = {
    className: `${cropClasses?.divider} ${currDarkThemeClass}`,
  };

  const plantTabProps = {
    plants: values?.plants,
    currDarkThemeClass,
    values,
    handleSetValues,
    handleErrorWithFeedback,
  };

  const specificsTabProps = {
    cropPlanPerms,
    queryParam,
    cropplanid,
    currDarkThemeClass,
    values,
    handleSetValues,
    savedRes,
    handleErrorWithFeedback,
    // Add other props as needed
  };

  const overviewTabProps = {
    values,
    cropPlanPerms,
    cropplanid,
    currDarkThemeClass,
  };
  
  const selectedCropRunFarm = values?.farm;

  const PhasesTabProps = {
    values,
    cropPlanPerms,
    cropplanid,
    selectedCropRunFarm,
    currDarkThemeClass,
    handleErrorWithFeedback,
    handleSetParentValues: handleSetValues,
  };

  const reviewPopupProps = {
    values,
    currDarkThemeClass,
    handleSave,
    onClose: handlePopupClose(popupMap.REVIEW),
  };

  if (logMsgToConsole?.cropPlan) {
    console.log(values);
    console.log(props);
  }

  const fabState = getCropPlanFabState({ cropplanid, values, tabValue }) || {};

  const rDPrevNextFabProps = {
    currDarkThemeClass,

    ...fabState,

    handleNextStep: handleCropPlanNextStep({ cropplanid, values, tabValue, tabEntityMap, handleSetValues, handleSetTabValue }),
    handlePrevStep: handleCropPlanPrevStep({ cropplanid, values, tabValue, tabEntityMap, handleSetValues, handleSetTabValue }),
  };

  return (
    <Box {...cropPlanBoxProps}>
      <Fade in={true} timeout={fadeTimingMs}>
        <Box {...cropPlanHeaderBoxProps}>
          <Box {...cropPlanTitleBoxProps}>
            <Typography {...cropPlanTitleTypoProps}>{cropplanid === NEW ? title : savedRes?.name ?? 'Unknown'}</Typography>

            {/* <Breadcrumbs {...breadcrumbsProps}>
                            {breadCrumbs}
                            <Typography {...detailBLinkTypoProps}>
                                { savedRes?.name ?? ( cropplanid === NEW ? 'Create Crop Plan' : 'Unknown' ) }
                            </Typography>
                        </Breadcrumbs> */}
            {queryParam === 'created' ? <CropPlanBreadcrumbs {...breadcrumbsProps} /> : <CropRunBreadcrumbs {...breadcrumbsProps} />}
          </Box>

          {/* { (cropplanid !== NEW && cropPlanPerms?.update) && <Button {...cropPlanSaveBtnProps} /> } */}
          {((cropplanid === NEW && tabValue !== tabs.SPECIFICS.key) || (cropplanid !== NEW && tabValue !== 'overview')) && (
            <Fade in={true} timeout={fadeTimingMs}>
              <Box>{(cropplanid === NEW ? cropPlanPerms?.create : cropPlanPerms?.update) && <Button {...cropPlanSaveBtnProps} />}</Box>
            </Fade>
          )}
        </Box>
      </Fade>

      <Tabs {...cropPlanTabsProps}>{tabList}</Tabs>

      <Divider {...cropPlanDividerProps} />

      {tabValue === tabs.OVERVIEW.key ? (
        <OverviewTab {...overviewTabProps} />
      ) : tabValue === tabs.SPECIFICS.key ? (
        <_SpecificsTab {...specificsTabProps} />
      ) : tabValue === tabs.PLANTS.key ? (
        <_PlantsTab {...plantTabProps} />
      ) : tabValue === tabs.PHASES.key ? (
        <_PhasesTab {...PhasesTabProps} />
      ) : (
        <></>
      )}

      {values?.[popupMap.REVIEW] && <ReviewRoutine {...reviewPopupProps} />}
      {showPrevNextFab && <PrevNextFab {...rDPrevNextFabProps} />}
    </Box>
  );
}
