import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FeedbackContext } from '../../../App';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Divider, Fade, InputAdornment, MenuItem, Paper, Stack, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import {
    AddMetricRunPopup, DelIcon2, DiminishedIcon, DownArrowIcon, DownloadingMetrcDataMsg, EditIcon, EditIcon3, ExpandCard, ExternalLinkIcon, NEW, NightIcon, PLAN_WIZARD, PLAN_WIZARD_TYPE, PlansDetailInfoCard, PlansIcon, SaveIcon, SunIcon,
    defErrProps, defUndVal, errFeedbackFn, fadeTimingMs, getMetricPlanRoute, getWizardDetails, landingPageRoute, logMsgToConsole, metrcPlanModelName, metrcRunModelName, metricPlanCardClasses, metricPlanClasses, miscClasses, phasePlanNewAndDetailRoute
} from '../../utility';
import useThemeVal from '../../../utility/useThemeVal';
import { failsTokenAndSetupCheck } from '../../../checks/setup';
import { hasPermissions, useToken } from '../../../utility';
import APIService from '../../../api/apiService';
import { MetricPlanBreadcrumbs } from '../../utility/BreadcrumbLinks';
import NoEntityMsg from '../../utility/messages/NoEntityMsg';
import PrevNextFab from '../../utility/fabs/PrevNextFab';
import { getMetricPlanFabState, handleMetricPlanNextStep, handleMetricPlanPrevStep } from '../../utility/exec';

const tabs = {
    OVERVIEW: 'overview',
    GENERAL_INFO: 'general-info',
    METRIC_RUNS: 'metric-runs'
}
const tabEntityMap = [
    { key: tabs.OVERVIEW, label: 'Overview', validForNew: false },
    { key: tabs.GENERAL_INFO, label: 'General Info', validForNew: true, nextTab: tabs.METRIC_RUNS },
    { key: tabs.METRIC_RUNS, label: 'Metric Runs', validForNew: true, prevTab: tabs.GENERAL_INFO },
];

const runTypeMap = {
    1: 'Diminshed',
    2: 'Day Parts'
};

const dTargetMap = {
    1: 'Start: T',
    2: 'Day: T'
};

const eTargetMap = {
    1: 'End: T',
    2: 'Night: T'
};

const metricPlanActionTitle = "Provide Details for this Metric.";

const keys = ['name', 'description', 'phaseplan', 'frtl', 'unit', 'metrc_type'];

const defListener = () => console.log('listener');

const handleSaveCheck = (values, savedRes) => {
    // Enabled when at least one check has passed i.e. return true and the other tab does not have invalid state
    let genInfoCheck = false;

    const genInfoValid = Boolean(values?.name && values?.description && values?.phaseplan);

    const genInfoChange = (values?.name !== savedRes?.name) || (values?.description !== savedRes?.description) || (+values?.phaseplan !== +savedRes?.phaseplan) || (+values?.unit !== +savedRes?.unit);

    const metricRunInfoValid = Boolean(values?.runs?.length > 0);

    genInfoCheck = genInfoValid && genInfoChange && metricRunInfoValid;
    return !genInfoCheck;
};

// generalised function to send post requests to create instances
const handleCreateEntity = (entity, resultHandler, errorHandler, finallyHandler = () => { }) => async (payload) => {
    let res;
    try {
        res = await APIService.createInstance(entity, payload)
            .then(resultHandler);
    } catch (err) {
        errorHandler(err);
    } finally {
        finallyHandler();
    }
};

// diminished metric run card gradient component
const DimGradient = (props) => {
    const { run, currDarkThemeClass } = props;
    const dProps = {
        className: `${metricPlanClasses?.diminishedGradient} ${currDarkThemeClass}`,
        sx: {
            background: 'linear-gradient(90deg, #FFF 6.62%, rgba(255, 255, 255, 0.00) 104.41%)'
        }
    };
    const cdLinkIconProps = {
        className: `${metricPlanCardClasses?.linkIcon} ${currDarkThemeClass}`,
    };

    const btnProps = (show_icon) => ({
        className: `${metricPlanCardClasses?.button} ${currDarkThemeClass} ${metricPlanCardClasses?.delBtn}`,
        disableRipple: true,
        disabled: true,
        startIcon: show_icon && <DiminishedIcon {...cdLinkIconProps} />
    });

    const infoKeyMap = [
        { key: 'day_target', title: `Start.T: ${run?.day_target ?? '---'}`, showIcon: true },
        { key: 'day_control', title: `C (+/-) ${run?.day_control ?? '---'}` },
        { key: 'night_target', title: `End.T: ${run?.night_target ?? '---'}` }
    ];

    const dimStackProps = {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }

    return <Box>
        <Stack {...dimStackProps}>{infoKeyMap.map(info => (<Button key={info?.key} {...btnProps(info?.showIcon)}>{info?.title}</Button>))}</Stack>
        <Divider {...dProps} />
    </Box >
};

// day parts metric run 
const DayParts = (props) => {
    const { values: run, currDarkThemeClass, clientWidth, handleSetValues } = props;
    const iconProps = {
        className: `${metricPlanClasses?.daypartsIcon} ${currDarkThemeClass}`
    }
    const timeKeyMap = [
        {
            start: '00:00:00',
            end: run?.day_time,
            textFieldProps: {
                className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
                InputProps: {
                    disabled: true,
                    startAdornment: <InputAdornment position='start'>
                        <NightIcon {...iconProps} />
                    </InputAdornment>
                },
                defaultValue: '---',
                size: 'small',
                sx: {
                    maxWidth: '85px'
                }
            },
            typoProps: {
                className: `${metricPlanClasses?.subPhaseTypo} ${currDarkThemeClass}`,
                children: `T:${run?.night_target} C:${run?.night_control}`,
                variant: 'subtitle2'
            },
            dividerProps: {
                className: `${metricPlanClasses?.nightDivider} ${currDarkThemeClass}`
            }

        },
        {
            start: run?.day_time,
            end: run?.night_time,
            textFieldProps: {
                className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
                InputProps: {
                    startAdornment: <InputAdornment position='start'>
                        <SunIcon {...iconProps} />
                    </InputAdornment>
                },
                inputProps: {
                    type: 'time',
                    max: run?.night_time,
                },
                value: run?.day_time,
                onChange: (event) => {
                    handleSetValues('day_time', event?.target?.value);
                },
                size: 'small',
                disabled: !run?.canEdit ?? true
            },
            typoProps: {
                className: `${metricPlanClasses?.subPhaseTypo} ${currDarkThemeClass}`,
                children: `T:${run?.day_target} C:${run?.day_control}`,
                variant: 'subtitle2'
            },
            dividerProps: {
                className: `${metricPlanClasses?.dayDivider} ${currDarkThemeClass}`
            }
        },
        {
            start: run?.night_time,
            end: '23:59:59',
            textFieldProps: {
                className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
                InputProps: {
                    startAdornment: <InputAdornment position='start'>
                        <NightIcon {...iconProps} />
                    </InputAdornment>
                },
                inputProps: {
                    type: 'time',
                    min: run?.day_time,
                },
                value: run?.night_time,
                onChange: (event) => {
                    handleSetValues('night_time', event?.target?.value);
                },
                disabled: !run?.canEdit ?? true,
                size: 'small'
            },
            typoProps: {
                className: `${metricPlanClasses?.subPhaseTypo} ${currDarkThemeClass}`,
                children: `T:${run?.night_target} C:${run?.night_control}`,
                variant: 'subtitle2'
            },
            dividerProps: {
                className: `${metricPlanClasses?.nightDivider} ${currDarkThemeClass}`
            }
        }
    ];

    const sTypoProps = (item) => ({
        component: 'div',
        children: <Stack direction='row' gap={2} alignItems='center' sx={{ minHeight: '50px' }}>
            <TextField {...item?.textFieldProps} />
            <Typography {...item?.typoProps}></Typography>
        </Stack>
    });

    const sBoxProps = {
        sx: {
            minWidth: '150px',
        }
    }

    const stProps = {
        direction: 'row',
        alignItems: 'center',
        gap: 2
    }
    return <Stack {...stProps}>
        {
            timeKeyMap?.map((time, index) => {
                let startTime = new Date(`2000-01-01T${time?.start}`), endTime = new Date(`2000-01-01T${time?.end}`);
                // Calculate the time difference in milliseconds
                let timeDifferenceInSeconds = (endTime - startTime) / 1000;
                let wd = (timeDifferenceInSeconds / 86400) * clientWidth;
                wd = Math.floor(wd);

                let sProps = {
                    wd,
                    sTypoProps: sTypoProps(time),
                    sDividerProps: time?.dividerProps,
                    sBoxProps: sBoxProps
                }
                return <Fragment key={index}><SubphaseComp {...sProps} /></Fragment>
            })
        }
    </Stack>
};

// common component for subphase and runs details
const SubphaseComp = (props) => {
    const {
        sToolTipProps = { title: '', disableHoverListener: true },
        sTypoProps = {},
        sDividerProps = {},
        wd = 'inherit',
        sBoxProps = {},
        otherProps = {}
    } = props;
    return <Tooltip {...sToolTipProps}>
        <Box width={wd} {...sBoxProps}>
            <Typography {...sTypoProps} />
            <Divider {...sDividerProps} />
        </Box>
    </Tooltip>
};

// metric run card
const MetricRunCard = (props) => {
    const { index, currDarkThemeClass, run, metrcRunsPerms, clientWidth, handleDelete, handleMetricRunEdit } = props;

    const [values, setValues] = useState({ ...run });

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    const handleEdit = () => {
        if (values?.canEdit) {
            handleMetricRunEdit(values);
            handleSetValues('canEdit', false);
        }
        else
            handleSetValues('canEdit', true);
    }

    const mRChipProps = {
        variant: 'outlined',
        label: `${+index + 1 ?? -1}`,
        className: `${metricPlanClasses.metricRunIndex} ${currDarkThemeClass}`,
    }

    const cardActionBtns = [
        {
            key: 'delete',
            title: 'Remove from plan',
            icon: DelIcon2,
            otherClasses: metricPlanCardClasses?.delBtn,
            otherProps: {
                onClick: handleDelete
            }
        },
        {
            key: 'edit',
            title: values?.canEdit ? 'Save' : 'Edit',
            icon: values?.canEdit ? SaveIcon : EditIcon3,
            otherClasses: values?.canEdit ? '' : metricPlanCardClasses?.delBtn,
            otherProps: {
                onClick: handleEdit,
                disabled: !metrcRunsPerms?.update ?? true
            },
        }
    ];

    const cdLinkIconProps = {
        className: `${metricPlanCardClasses?.linkIcon} ${currDarkThemeClass}`
    };

    const cdSubHeaderBtnProps = (iconPosition, Icon, otherClasses = '', othersProps = {}) => ({
        className: `${metricPlanCardClasses?.button} ${currDarkThemeClass} ${otherClasses}`,
        disableRipple: true,
        [iconPosition]: <Icon  {...cdLinkIconProps} />,
        ...othersProps
    });

    const metricRunTextFieldProps = ({ prop, className = `${metricPlanClasses?.textField} ${currDarkThemeClass}`, inputProps = {}, otherSxProps = {}, otherInputProps = {}, ...otherTextProps }) => ({
        autoComplete: 'off',
        className: className,
        // disabled: cropplanid !== NEW && !cropPlanPerms?.update || values?.processing,
        inputProps,
        InputLabelProps: {
            // ...((prop === 'serialization_date' || prop === 'description') && {
            //     shrink: true,
            // }),
            shrink: false
        },
        InputProps: {
            ...otherInputProps,
        },
        label: ' ',
        onChange: ({ target: { value } }) => handleSetValues(prop, value),
        // required: true,
        sx: {
            ...otherSxProps,
        },
        value: values?.[prop] ?? '',
        ...otherTextProps,
    });

    const mRDayProps = {
        className: `${metricPlanClasses?.metricBaseTextField} ${metricPlanClasses?.stDayTextField} ${currDarkThemeClass}`,
        inputProps: {
            min: 0,
            step: 1,
        },
        otherInputProps: {
            required: false,
            disabled: !values?.canEdit ?? true,
        },
        type: values?.canEdit ? 'number' : 'text',
        prop: 'start_day'
    };

    const mRStartTargetProps = (prop, targetmap) => ({
        className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
        type: values?.canEdit ? 'number' : 'text',
        inputProps: {
            step: 0.01
        },
        prop: prop,
        otherInputProps: {
            startAdornment: <InputAdornment position="start" disableTypography>{targetmap[run?.run_type] ?? '---'}</InputAdornment>,
            disabled: !values?.canEdit ?? true
        }
    });

    const mRDControlProps = {
        className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
        type: values?.canEdit ? 'number' : 'text',
        inputProps: {
            step: 0.01
        },
        prop: 'day_control',
        otherInputProps: {
            startAdornment: <InputAdornment position="start" disableTypography>C (+/-) </InputAdornment>,
            disabled: !values?.canEdit ?? true
        }
    };

    const mRNControlProps = {
        className: `${metricPlanClasses?.metricBaseTextField} ${currDarkThemeClass}`,
        type: values?.canEdit ? 'number' : 'text',
        inputProps: {
            step: 0.01
        },
        prop: 'night_control',
        otherInputProps: {
            startAdornment: <InputAdornment position="start" disableTypography>C (+/-) </InputAdornment>,
            disabled: !values?.canEdit ?? true
        }
    }

    const mRStDayProps = {
        className: `${metricPlanClasses.subPhaseTypo} ${currDarkThemeClass}`,
        variant: 'body2'
    };


    const stWithTextField = (
        <Stack flexDirection='row' columnGap={2} alignItems='center'>
            <Typography {...mRStDayProps}>Starts on Day</Typography>
            <TextField {...metricRunTextFieldProps(mRDayProps)} />
        </Stack>);

    const baseStackProps = (maxWidth) => ({
        flexDirection: 'row',
        columnGap: 1,
        alignItems: 'center',
        maxWidth
    });

    const dimTargetAndControlInfo = (
        <Stack {...baseStackProps('45%')}>
            <TextField {...metricRunTextFieldProps(mRStartTargetProps('day_target', dTargetMap))} />
            <TextField {...metricRunTextFieldProps(mRStartTargetProps('night_target', eTargetMap))} />
            <TextField {...metricRunTextFieldProps(mRDControlProps)} />
        </Stack>
    );

    const daypartsTargetAndControlInfo = (
        <Stack {...baseStackProps('58%')}>
            <TextField {...metricRunTextFieldProps(mRStartTargetProps('day_target', dTargetMap))} />
            <TextField {...metricRunTextFieldProps(mRDControlProps)} />
            <TextField {...metricRunTextFieldProps(mRStartTargetProps('night_target', eTargetMap))} />
            <TextField {...metricRunTextFieldProps(mRNControlProps)} />
        </Stack>
    );

    const infoMap = {
        1: dimTargetAndControlInfo,
        2: daypartsTargetAndControlInfo
    }


    const mRTitleTypo = {
        className: `${metricPlanClasses?.metricRunTitleTypo} ${currDarkThemeClass}`,
    }

    const mRDividerProps = {
        className: `${metricPlanClasses?.divider} ${currDarkThemeClass}`
    }

    const mRCardContentProps = {
        className: `${metricPlanClasses?.metricRunCardContent} ${currDarkThemeClass}`
    }

    const cardHeader = (
        <CardHeader title={
            <Stack flexDirection='row' gap={1} alignItems='center'>
                <Chip {...mRChipProps} />
                <Typography {...mRTitleTypo}>{run?.name ?? 'unknown'}</Typography>
            </Stack>
        } />);

    const cardActions = (
        <CardActions>{cardActionBtns.map((item) => (<Button {...cdSubHeaderBtnProps('startIcon', item?.icon, item?.otherClasses ?? '', item?.otherProps)} key={item?.key}>{item?.title}</Button>))}</CardActions>
    );

    const mRCardProps = {
        className: `${metricPlanClasses?.metricCard} ${currDarkThemeClass}`,
        raised: false,
        square: true
    }

    const diminishedProps = {
        run: values,
        currDarkThemeClass
    };

    const dayPartsProps = {
        values,
        clientWidth,
        currDarkThemeClass,
        handleSetValues
    }

    const runTypeMap = {
        1: <DimGradient {...diminishedProps} />,
        2: <DayParts {...dayPartsProps} />
    }

    return <Card {...mRCardProps}>
        <Stack flexDirection='row' justifyContent='space-between'>
            {cardHeader}
            {cardActions}
        </Stack>
        <CardContent {...mRCardContentProps}>
            <Stack flexDirection='row' justifyContent='space-between'>
                {stWithTextField}
                {infoMap[run?.run_type] ?? ''}
            </Stack>
            <Divider {...mRDividerProps} />
            {runTypeMap[run?.run_type]}
        </CardContent>
    </Card>
};

const MetricRunsTab = (props) => {
    const { currDarkThemeClass,
        scalingFactor,
        values,
        handleSetValues,
        metrcRunsPerms,
        total,
        handleErrorWithFeedback,
        currPhasePlan = []
    } = props;
    const feedbackCX = useContext(FeedbackContext)
    // sort runs by start date
    const runs = useMemo(() => values?.runs?.toSorted((a, b) => a?.start_day - b?.start_day), [values?.runs]);

    // count for number of grid columns required
    const gridCols = useMemo(() => Math.max(total, values?.runs[values?.runs.length - 1]?.start_day ?? -1), [values?.runs, total]);

    // handle metric run delete
    const handleMetricRunDelete = (id) => () => {
        // isNan(id) => run is not saved in backend
        if (isNaN(+id)) {
            handleSetValues('runs', values?.runs?.filter(run => run?.tempRef !== id));
            return;
        }

        const entityWithID = `metricrun/${id}`;
        const errConfig = {};

        APIService.deleteInstance(entityWithID)
            .then(() => { handleSetValues('runs', values?.runs?.filter((run) => run?.id !== id))
                    feedbackCX.setContextValue(true, {
                    type: 'success',
                    message: 'Deleted Metrc Run successfully.',
                    autoHideDuration: 3000,
                    }) })
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)));
    }

    // handle metric run edit
    const handleMetricRunEdit = (id) => (payload) => {
        // isNaN(id) => run not saved in backend yet, but still editable through state.
        if (isNaN(+id)) {
            handleSetValues('runs', values?.runs?.map(e => e.tempRef === id ? payload : e));
            return;
        }

        const entityWithId = `metricrun/${id}`;
        const errConfig = {};

        APIService.modifyInstance(entityWithId, payload)
            .then(res => handleSetValues('runs', values?.runs?.map(e => e.id === id ? res.data : e)))
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
    }

    const mPDRunsBoxProps = {
        className: `${metricPlanClasses?.cycleTableBox} ${currDarkThemeClass}`,
    };

    // subphases representation in metric runs tab
        const sToolTipProps = (subphase) => ({
        title: `${subphase?.name ?? 'unknown'} (${subphase?.start_day ?? 0} - ${subphase?.end_day ?? 0})`
    });

    const sTypoProps = (subphase) => ({
        className: `${metricPlanClasses.subPhaseTypo} ${currDarkThemeClass}`,
        children: `${subphase?.start_day ?? '?'} ${subphase?.name ?? 'unkown'}`,
        variant: 'subtitle2',
    });

    const sDividerProps = (subphase) => ({
        className: `${metricPlanClasses.subPhaseDivider} ${currDarkThemeClass}`
    });

    const subphasesList = (
        currPhasePlan.subphases?.map((subphase, index) => {
            const wd = (subphase?.end_day - subphase?.start_day) > 2? (subphase?.end_day - subphase?.start_day) * scalingFactor : 3 * scalingFactor;
            let sProps = {
                wd,
                sToolTipProps: sToolTipProps(subphase),
                sTypoProps: sTypoProps(subphase),
                sDividerProps: sDividerProps(subphase),
                }
            return <Fragment key={index}><SubphaseComp {...sProps} /></Fragment>
        })
    );

    const subphases = (
        <Stack flexDirection='row' gap={2}>
            {
                currPhasePlan?.subphases?.length > 0 ? subphasesList : <NoEntityMsg entity='Suphase' />
            }
        </Stack>
    );

    // metric runs representation
    const mRBoxProps = {
        gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
        className: `${metricPlanClasses.metricRunBox} ${currDarkThemeClass}`
    }

    const rBoxProps = (start, end) => ({
        sx: {
            gridColumnStart: start,
            gridColumnEnd: end
        }
    });

    const mRToolTipProps = (run) => ({
        title: `${run?.name ?? 'unknown'} (${run?.start_day ?? 'unkown'})`
    });

    const mRIndexProps = (index) => ({
        className: `${metricPlanClasses.metricRunIndex} ${currDarkThemeClass}`,
        variant: 'outlined',
        label: `${+index + 1}`
    });

    const mRTypoProps = (run, index) => ({
        className: `${metricPlanClasses.subPhaseTypo} ${currDarkThemeClass}`,
        component: 'div',
        children: <Stack flexDirection='row' gap={3} marginBottom={2} alignItems='center'>
            <Typography variant='subtitle2'>{run?.start_day ?? 'unknown'}</Typography>
            <Chip {...mRIndexProps(index)} />
        </Stack>
    });

    const mRDividerProps = (run) => ({
        className: `${metricPlanClasses.runDivider} ${currDarkThemeClass}`,
    });

    const metricruns = (
        <Box {...mRBoxProps}>
            {
                runs?.map((run, index) => {
                    let start = run?.start_day ?? 0;
                    const end = index !== runs?.length - 1 ? runs[index + 1]?.start_day - 1 : -1;
                    start = start || start + 1;
                    let sProps = {
                        sToolTipProps: mRToolTipProps(run),
                        sTypoProps: mRTypoProps(run, index),
                        sDividerProps: mRDividerProps(run),
                        sBoxProps: rBoxProps(start, end)
                    }
                    return <Fragment key={index}><SubphaseComp {...sProps} /></Fragment>
                })
            }
        </Box>
    );

    // all runs
    const mRCardProps = (run, index) => ({
        currDarkThemeClass,
        index,
        run,
        metrcRunsPerms,
        clientWidth: values?.clientWidth ?? 1100,
        handleDelete: handleMetricRunDelete(run?.id || run?.tempRef),
        handleMetricRunEdit: handleMetricRunEdit(run?.id || run?.tempRef)
    });
    
    // const runsSorted = values?.runs?.toSorted((a, b) => a?.start_day - b?.start_day);

    const runsList = (
        runs?.map((run, index) => (
            <Fragment key={run?.id ?? index}><MetricRunCard {...mRCardProps(run, index)} /></Fragment>
        ))
    );

    const mRARTypoProps = {
        className: `${metricPlanClasses?.cycleTypeTypo} ${currDarkThemeClass}`,
        sx: {
            padding: 0
        }
    }

    const mRPaperProps = {
        className: `${metricPlanClasses?.metricRunPaperWrapper} ${currDarkThemeClass}`
    }

    return <Fade in={true} timeout={fadeTimingMs}>
        <Box {...mPDRunsBoxProps}>
            {subphases}
            {metricruns}
            <Paper {...mRPaperProps}>
                <Typography {...mRARTypoProps}>All Runs</Typography>
                <Stack rowGap={2}>
                    {runsList}
                </Stack>
            </Paper>
        </Box>
    </Fade>
};

// metric plan general info tab
const GeneralInfoTab = (props) => {
    const { currDarkThemeClass, values, handleSetValues, metrcPlanPerms } = props;

    const mPDTabTextFieldProps = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
        autoComplete: 'off',
        className: `${metricPlanClasses?.textField} ${currDarkThemeClass}`,
        disabled: !metrcPlanPerms.update || values?.loading || values?.processing || values?.saving,
        inputProps,
        label: ' ',
        onChange: ({ target: { value } }) => handleSetValues(prop, value),
        required: true,
        value: values?.[prop] ?? '',

        ...otherTextProps,
    });

    const mPDGenInfoBoxProps = {
        className: `${metricPlanClasses?.genInfoBox} ${currDarkThemeClass} roomDetailTabsContent`,
    };

    const mPDDTabTitleTypoProps = {
        className: `${metricPlanClasses?.tabTitleTypo} ${currDarkThemeClass}`,
    };

    const nameProps = {
        otherTextProps: {
            autoFocus: true,
            label: 'Name of Metric Plan',
        },
        prop: 'name',
    };



    const descProps = {
        inputProps: {
            sx: {
                p: 0,
            },
        },
        otherTextProps: {
            label: 'Description',
            maxRows: 6,
            minRows: 6,
            multiline: true,
            placeholder: 'Write a description for your metric plan',
            required: false,
        },
        prop: 'description',  
    };

    const phasePlanMenuItemProps = {
        className: `${metricPlanClasses?.menuItem} ${currDarkThemeClass}`,
        focusVisibleClassName: miscClasses?.menuItemFocusDark,
    };


    const phasePlanProps = {
        otherTextProps: {
            label: 'Default Phase Plan',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
        },
        prop: 'phaseplan',
    };

    const metrcTypesProps = {
        otherTextProps: {
            label: 'Metric Type',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
        },
        prop: 'metrc_type',
    };

    const phasePlansList = values?.phaseplans?.map((e, i) => <MenuItem key={i} value={e?.id} {...phasePlanMenuItemProps}>{e?.name}</MenuItem>) ?? [];

    if (!phasePlansList?.length) {
        phasePlansList.push(<MenuItem key={-1} value={-1} disabled {...phasePlanMenuItemProps}>No Available Phase Plans</MenuItem>)
    }

    const metrcTypesList = values?.metrc_types?.map((e, i) => <MenuItem key={i} value={e?.id} {...phasePlanMenuItemProps}>{e?.type}</MenuItem>) ?? [];

    if (!metrcTypesList?.length) {
        metrcTypesList.push(<MenuItem key={-1} value={-1} disabled {...phasePlanMenuItemProps}>No Available Metrc Types</MenuItem>)
    }

    const chosenMetrcType = values?.metrc_types?.find(e => e?.id === values?.metrc_type)?.type ?? '';

    const unitsList = values?.units?.filter(e => e?.name === chosenMetrcType)?.map((e, i) =>
        <MenuItem key={i} value={e.id} {...phasePlanMenuItemProps}>{`${e.name} ( ${e.symbol} )`}</MenuItem>
    ) || [];

    if (!unitsList?.length) {
        unitsList.push(<MenuItem key={-1} value={-1} disabled {...phasePlanMenuItemProps}>No Available Units</MenuItem>)
    }

    const unitProps = {
        otherTextProps: {
            label: 'Choose a Unit',
            select: true,
            SelectProps: {
                IconComponent: DownArrowIcon,
            },
            value: values?.unit,

            onChange: ({ target: { value } }) => handleSetValues('unit', values?.units?.find(e => +e?.id === Number(value))?.id)
        },
        prop: 'unit',
    };

    return <Fade in={true} timeout={fadeTimingMs}>
        <Box {...mPDGenInfoBoxProps}>
            <Typography {...mPDDTabTitleTypoProps}>{metricPlanActionTitle}</Typography>         
            <TextField {...mPDTabTextFieldProps(metrcTypesProps)}>{metrcTypesList}</TextField>
            <TextField {...mPDTabTextFieldProps(unitProps)}>{unitsList}</TextField>
            <TextField {...mPDTabTextFieldProps(phasePlanProps)}>{phasePlansList}</TextField>
            <TextField {...mPDTabTextFieldProps(nameProps)} />
            <TextField {...mPDTabTextFieldProps(descProps)} />
        </Box>

    </Fade>
};

// metric plan overview tab
const OverviewTab = (props) => {
    const {
        currDarkThemeClass = '',
        savedRes = [],
        metrcPlanPerms = [],
        handleSetTabValue = defListener,
    } = props;

    const mPDOverviewBoxProps = {
        className: `${metricPlanClasses?.overviewBox} ${currDarkThemeClass}`,
    };

    const phasePlanLinkProps = {
        className: `${metricPlanClasses?.phasePlanLinkIcon} ${currDarkThemeClass}`,
    };
    
    const phaseplanLinkBoxProps = {
        className: `${metricPlanClasses?.phasePlanLinkBox} ${currDarkThemeClass}`,
        component: 'a',
        href: `${phasePlanNewAndDetailRoute}/${savedRes?.phaseplan}?tab=overview`,
        target: '_blank',
    };

    const phaseplanLink = <Box {...phaseplanLinkBoxProps}><ExternalLinkIcon {...phasePlanLinkProps} /></Box>

    const usedMetrcType = savedRes?.metrc_types?.find(metrc_type => metrc_type?.id === savedRes?.metrc_type);
    const usedPhaseplan = savedRes?.phaseplans?.find(phaseplan => phaseplan?.id === savedRes?.phaseplan);

    const mPDGenInfoCardProps = {
        cardEntries: [
            { key: 'Name', value: savedRes?.name ?? defUndVal, icon: PlansIcon },
            { key: 'Phase', value: <>{usedPhaseplan?.name ?? ' '} {phaseplanLink}</>, icon: PlansIcon },
            { key: 'Metric Type', value: `${usedMetrcType?.type ?? ''}` ?? defUndVal, icon: PlansIcon },
            { key: 'Metric Runs Count', value: savedRes?.runs?.length ?? 0, icon: PlansIcon },
        ],
        cardTitle: 'General Info',
        ...(metrcPlanPerms.update ? {
            onClick: () => handleSetTabValue(null, 'general-info'),
        } : {
            toAllowEdit: false,
        }),
        currDarkThemeClass,
        otherBoxProps: {
            gridArea: 'a',
        },
    };

    const mPDDescCardProps = {
        cardEntries: [
            { value: savedRes?.description ?? 'No Description Available' },
        ],
        cardTitle: 'Plan Description',
        currDarkThemeClass,
        otherBoxProps: {
            gridArea: 'b',
        },
        ...(metrcPlanPerms.update ? {
            onClick: () => handleSetTabValue(null, 'general-info'),
        } : {
            toAllowEdit: false,
        }),
    };

    const mPDCardBoxProps = {
        className: `${metricPlanClasses?.cardBox} ${currDarkThemeClass}`,
        sx: {
            gridArea: 'c',
        },
    };

    const mPDCardHeaderBoxProps = {
        className: `${metricPlanClasses?.cardHeaderBox} ${currDarkThemeClass}`,
    };

    const mPDCardHeaderTypoProps = {
        className: `${metricPlanClasses?.cardHeaderTypo} ${currDarkThemeClass}`,
    };

    const mPDAMCEditIconProps = {
        className: `${metricPlanClasses?.attachMetricCycleEditIcon} ${currDarkThemeClass}`,
    };

    const mPDContentBoxProps = {
        className: `${metricPlanClasses?.contentBox} ${currDarkThemeClass} customScroll`,
    };

    const mPDCardHeaderEditBtnProps = {
        children: 'Edit',
        className: `${metricPlanClasses?.cardHeaderEditBtn} ${currDarkThemeClass}`,
        disableRipple: true,
        onClick: () => metrcPlanPerms.update && handleSetTabValue(null, 'metric-runs'),
        startIcon: <EditIcon {...mPDAMCEditIconProps} />,
    };

    // attached metric runs
    // extra constants to display depending upon run type
    const extras = (run_type, run) => {
        let dayparts = [
            { key: 'Day Target', value: +run?.day_target ?? 0.00 },
            { key: 'Day Control', value: +run?.day_control ?? 0.00 },
            { key: 'Day Time', value: run?.day_time ?? '' },
            { key: 'Night Target', value: +run?.night_target ?? 0.00 },
            { key: 'Night Control', value: +run?.night_control ?? 0.00 },
            { key: 'Night Time', value: run?.night_time ?? '' }
        ];

        let diminished = [
            { key: 'Start Target', value: +run?.day_target ?? 0.00 },
            { key: 'End Target', value: +run?.night_target ?? 0.00 },
            { key: 'Control', value: +run?.day_control ?? run?.night_control ?? 0.00 }
        ];

        if (run_type === 1) return diminished;
        else if (run_type === 2) return dayparts;
        else return [];
    }
    const expandCardProps = (e) => ({
        // ...(metrcPlanPerms.update && {
        //     actions: [
        //         { children: 'Edit', icon: EditIcon, onClick: () => console.log('hey') },
        //     ],
        // }),
        actions: [],
        // cardHeaderInfo: e?.type ? 'High Cycle' : 'Low Cycle',
        cardIcon: PlansIcon,
        cardTitle: e?.name,
        currDarkThemeClass,
        entries: [
            { key: 'Run Type', value: runTypeMap[e?.run_type] ?? 'None' },
            { key: 'Start Day', value: e?.start_day },
            ...extras(e?.run_type ?? 1, e)
        ],
    });
    const metricRunsList = savedRes?.runs?.map((run, i) => <ExpandCard key={i} {...expandCardProps(run)} />)

    return (
        <Fade in={true} timeout={fadeTimingMs} >
            <Box {...mPDOverviewBoxProps}>
                <PlansDetailInfoCard {...mPDGenInfoCardProps} />
                <PlansDetailInfoCard {...mPDDescCardProps} />
                <Box {...mPDCardBoxProps}>
                    <Box {...mPDCardHeaderBoxProps}>
                        <Typography {...mPDCardHeaderTypoProps}>Attached Metric Runs</Typography>
                        {
                            metrcPlanPerms.update && <Button {...mPDCardHeaderEditBtnProps} />
                        }
                    </Box>
                    <Box {...mPDContentBoxProps}>
                        {metricRunsList}
                    </Box>
                </Box>
            </Box>
        </Fade>
    )
};

export default function MetricplanDetail() {
    const theme = useTheme();

    const { metricplanid } = useParams();

    const [detailChildren, setDetailChildren] = useState({});
    const [open, setOpen] = useState(false);
    const [savedRes, setSavedRes] = useState({});
    const [values, setValues] = useState({});

    const ref = useRef(null);

    const validTabs = tabEntityMap.filter(tab => metricplanid === NEW ? tab?.validForNew : true).map(e => e.key);
    const defaultTab = validTabs[0];

    const [tabValue, setTabValue] = useState(defaultTab);
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const feedbackCX = useContext(FeedbackContext);

    const currDarkThemeClass = useThemeVal();

    // current used phaseplan
    const currPhasePlan = useMemo(() => values?.phaseplans?.find(phaseplan => phaseplan?.id === values?.phaseplan) ?? [], [values?.phaseplans, values?.phaseplan]);

    const { token, tokenDetails, permissions } = useToken();
    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    // const permissionsList = useMemo(() => hasPermissions([metrcRunModelName, metrcPlanModelName], tokenDetails), [token]);

    const metrcPlanPerms = useMemo(() => hasPermissions([metrcRunModelName], { ...tokenDetails, permissions })[metrcRunModelName] ?? {}, [token]);
    const metrcRunsPerms = useMemo(() => hasPermissions([metrcPlanModelName], { ...tokenDetails, permissions })[metrcPlanModelName] ?? {}, [token]);

    const tab = searchParams.get('tab');

    const tabValuePermCheckMap = {
        [tabs.GENERAL_INFO]: () => (metricplanid === NEW ? metrcPlanPerms?.create : metrcPlanPerms?.update) ?? false,
        [tabs.METRIC_RUNS]: () => (metricplanid === NEW ? metrcPlanPerms?.create : false) ?? false
    }

    const showPrevNextFab = useMemo(() => metricplanid === NEW && tabValue !== 'overview' && !values?.loading, [tabValue, metricplanid]);

    const toDisable = () => handleSaveCheck(values, savedRes);

    const handleSetValues = (prop, value) => setValues(state => ({ ...state, [prop]: value }));

    const handleSetSavedRes = (prop, value) => setSavedRes(state => ({ ...state, [prop]: value }));

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams({ tab: newValue });
    };

    const handleMetricPlanRes = res => {
        const { data } = res;

        if (logMsgToConsole?.metricplan) {
            console.log('metric plan response'); console.log(res);
        }

        setValues(state => ({
            ...state,
            ...data,
        }));

        setSavedRes(state => ({
            ...state,
            ...data,
        }));

    };

    const handleErrorWithFeedback = errPropsFn => err => {
        if (logMsgToConsole?.metricplan) {
            console.log('An error occured'); console.log(err);
        }

        const errProps = errPropsFn?.(err) ?? defErrProps;

        feedbackCX.setContextValue(true, errProps);

    };

    const handleFinally = () => {
        handleSetValues('loading', false);
        handleSetValues('processing', false);
        handleSetValues('saving', false);
    }

    const handleEntityEntryCreateRes = prop => res => {
        handleSetValues('loading', false);

        const { data } = res;

        const newState = data?.length > 0 ? [...values?.[prop], ...data] : [...values?.[prop], data];
        const newSavedRes = data?.length > 0 ? [...savedRes?.[prop], ...data] : [...savedRes?.[prop], data];

        handleSetValues(prop, newState);
        handleSetSavedRes(prop, newSavedRes);

        return Promise.resolve({ success: true });
    };

    const handleAddRun = payload => handleSetValues('runs', [...(values?.runs ?? []), payload]);

    const { action, execute } = getWizardDetails(PLAN_WIZARD_TYPE);

    const wizardActive = execute && action === PLAN_WIZARD.METRICPLAN_CREATE;

    useEffect(() => {
        if (!tab || !validTabs.includes(tab)) {
            handleSetTabValue(null, defaultTab);
        } else {
            handleSetTabValue(null, tab);
        }

    }, [tab]);

    useEffect(() => {
        // function to update component width
        function updateComponentWidth() {
            if (ref.current) {
                let width = ref.current.clientWidth;
                handleSetValues('clientWidth', width);
            }
        }
        updateComponentWidth();
        // attach resize event with component's updateComponentWidth function
        window.addEventListener('resize', updateComponentWidth);

        // cleanup function to remove listener whenever the component unmounts
        return () => {
            window.removeEventListener('resize', updateComponentWidth);
        }
    }, []);

    useEffect(() => {
        if (invalidTokenState) return;

        const errConfig = {};

        handleSetValues('loading', true);

        let entityWithID = `metricplan/${metricplanid}/`;

        entityWithID = metricplanid === NEW ? 'new/metricplan/' : entityWithID;

        APIService.fetchInstance(entityWithID)
            .then(handleMetricPlanRes)
            .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
            .finally(handleFinally);

    }, [metricplanid])

    useEffect(() => {

        if (!invalidTokenState && !metrcRunsPerms?.read) navigate(landingPageRoute);

    }, [token]);

    // calculate scaling factor for subphases representation
    let { total, scalingFactor } = useMemo(() => {
        let res = currPhasePlan.subphases?.[currPhasePlan?.subphases?.length - 1]?.end_day;
        res = (res === 0 || !res) ? 100 : res;
        let scalingFactor = values?.clientWidth / res;
                return {
            total: res,
            scalingFactor
        }
    }, [values?.clientWidth, currPhasePlan, values?.phaseplan]);

    const mPDBoxProps = {
        className: `${metricPlanClasses?.box} ${currDarkThemeClass} customScroll`,
        ref: ref
    };

    const mPDHeaderBoxProps = {
        className: `${metricPlanClasses?.headerBox} ${currDarkThemeClass}`,
    };

    const mPDTitleAndNavBoxProps = {
        className: `${metricPlanClasses?.titleAndNavBox} ${currDarkThemeClass}`,
    };

    const mPDTitleTypoProps = {
        className: `${metricPlanClasses?.titleTypo} ${currDarkThemeClass}`,
    };

    const breadcrumbsProps = {
        currDarkThemeClass,
        currLink: metricplanid === NEW ? values?.name ?? 'create metric plan' : savedRes?.name ?? 'Unknown',

        navigate,
    };

    const gDTabsProps = {
        className: `${metricPlanClasses?.tabs} ${currDarkThemeClass} roomDetailTabsContent`,
        value: tabValue ?? defaultTab,
        onChange: handleSetTabValue,
    };

    const mPDTabProps = {
        className: `${metricPlanClasses?.tab} ${currDarkThemeClass}`,
    };

    const mPDBtnContainerProps = {
        gap: 2,
        flexDirection: 'row'
    };

    const mPDProgressProps = {
        className: `${metricPlanClasses?.progress} ${currDarkThemeClass}`,
        size: 16,
        style: {
            color: theme.palette.grey['500'],
        },
    };

    // metric plan modify
    const handleMetricPlanModifyRes = res => {
        const { data } = res;

        if (logMsgToConsole?.metricplan) {
            console.log('modified metric successfully'); console.log(data);
        }

        // const metriccycles = getCurrMetricCycles(data, +metricplanid);

        setValues(state => ({
            ...state,
            ...data,
            // metriccycles,
        }));
        setSavedRes(state => ({
            ...state,
            ...data,
            // metriccycles,
        }));

        handleSetValues('saving', false);

        handleSetTabValue(null, 'overview');
    };

    // metric plan create instance
    const handleMetricPlanCreateRes = res => {
        const { data } = res;
        navigate(getMetricPlanRoute(data?.id));
    }

    const handleMPSave = () => {
        if (toDisable() || !metrcPlanPerms.update) return;

        handleSetValues('saving', true);
        // preparing payload
        let payload = Object.fromEntries(keys.map(key => [key, values[key]]));

        // if the new metric plan is created then handle another way
        if (metricplanid === NEW) {
            // create new metric plan
            let entity = 'metricplan/';
            let errConfig = {};

            // add runs to payload when creating new metric plan
            payload = {
                ...payload,
                runs: values?.runs ?? []
            };

            handleCreateEntity(entity, handleMetricPlanCreateRes, handleErrorWithFeedback(errFeedbackFn(errConfig)), handleFinally)(payload);
        }
        else {
            const errConfig = {};

            const entityWithID = `metricplan/${metricplanid}/`;

            if (logMsgToConsole?.metricplan) {
                console.log('payload');
                console.log(payload);
            }
            // return;

            APIService.modifyInstance(entityWithID, payload)
                .then(handleMetricPlanModifyRes)
                .catch(handleErrorWithFeedback(errFeedbackFn(errConfig)))
                .finally(handleFinally);
        }
    };

    const mPDSaveBtnProps = {
        className: `${metricPlanClasses.saveBtn} ${currDarkThemeClass}`,
        disabled: values?.loading || values?.saving || values?.processing || toDisable() || !metrcPlanPerms.update,
        onClick: handleMPSave,
        startIcon: values?.saving && <CircularProgress {...mPDProgressProps} />
    }

    const handleEntityCreate = entityName => {
        if (entityName === tabs.METRIC_RUNS) {
            setOpen(true);
            setDetailChildren({
                runs: values?.runs ?? [],
                metrc_plan: metricplanid,
                handleSaveRun: handleEntityEntryCreateRes('runs'),
                handleAddRun
            });
            handleSetValues('canAddRun', true);
        }
    }

    const mPDAddBtnProps = {
        className: `${metricPlanClasses.button} ${currDarkThemeClass}`,
        onClick: () => handleEntityCreate(tabs.METRIC_RUNS)
    }

    const mPDDividerProps = {
        className: `${metricPlanClasses?.divider} ${currDarkThemeClass}`,
    };

    const downloadMsgProps = {
        currDarkThemeClass,
    };
    // const mPDSaveBtnProps = {
    //     children: 'Save Details',
    //     className: `${metricPlanClasses?.saveBtn} ${currDarkThemeClass}`,
    //     disabled: values?.loading || values?.saving || values?.processing || toDisable() || !metrcPlanPerms.update,
    //     onClick: handleMPSave,
    //     startIcon: values?.saving && <CircularProgress {...mPDProgressProps} />,
    // };

    const mPDMetricRunTabProps = {
        currDarkThemeClass,
        scalingFactor,
        currPhasePlan,
        values,
        total,
        metrcRunsPerms,
        handleSetValues,
        handleErrorWithFeedback
    }

    const handlePopupClose = () => {
        setOpen(false);
        setDetailChildren({});
        handleSetValues('canAddRun', false);
    };

    const runPopupProps = {
        currDarkThemeClass,
        open,

        onClose: handlePopupClose,

        ...detailChildren,
    };

    const tabList = tabEntityMap.filter(tab => metricplanid === NEW ? tab?.validForNew : true).map((e, i) =>
        <Tab key={i} {...mPDTabProps} label={e.label} value={e.key} />
    );

    const metricRunTab =
      values?.runs?.length > 0 ? (
        <MetricRunsTab {...mPDMetricRunTabProps} />
      ) : (
        metrcRunsPerms?.create &&
        tabValue === tabs?.METRIC_RUNS &&
        (!values?.runs || !values?.runs?.length > 0) && (
          <NoEntityMsg entity="Metric Run" isCreateEnabled onCreateClick={() => handleEntityCreate(tabs.METRIC_RUNS)} />
        )
      );
       
        

    const generalInfoProps = {
        currDarkThemeClass,
        values,
        metrcPlanPerms,
        handleSetValues,
    }
    const generalInfoTab = <GeneralInfoTab {...generalInfoProps} />;

    const overViewTabProps = {
        currDarkThemeClass,
        savedRes,
        metrcPlanPerms,
        handleSetTabValue
    };

    const overviewTab = <OverviewTab {...overViewTabProps} />;

    const fabState = getMetricPlanFabState({ metricplanid, values, tabValue }) || {};

    const mPPrevNextFabProps = {
        currDarkThemeClass,
        ...fabState,

        handleNextStep: handleMetricPlanNextStep({ metricplanid, tabEntityMap, tabValue, values, handleSetTabValue }),
        handlePrevStep: handleMetricPlanPrevStep({ metricplanid, tabEntityMap, tabValue, values, handleSetTabValue }),
    };

    return (
      <Box {...mPDBoxProps}>
        <Fade in={true} timeout={fadeTimingMs}>
          <Box {...mPDHeaderBoxProps}>
            <Box {...mPDTitleAndNavBoxProps}>
              <Typography {...mPDTitleTypoProps}>
                {savedRes?.name ?? (metricplanid === NEW ? 'Create Metric Plan' : 'Unknown Metric Plan')}
              </Typography>
              <MetricPlanBreadcrumbs {...breadcrumbsProps} />
            </Box>
            <Fade in={true} timeout={fadeTimingMs}>
              <Stack {...mPDBtnContainerProps}>
                {tabValuePermCheckMap?.[tabValue]?.() && <Button {...mPDSaveBtnProps}> Save Plan </Button>}
                {metrcRunsPerms.create && tabValue === tabs.METRIC_RUNS && values?.runs?.length >= 1 && (
                  <Button {...mPDAddBtnProps}>Add a Run</Button>
                )}
              </Stack>
            </Fade>
          </Box>
        </Fade>

        <Tabs {...gDTabsProps}>{tabList}</Tabs>

        <Divider {...mPDDividerProps} />
        {values?.loading ? (
          <DownloadingMetrcDataMsg {...downloadMsgProps} />
        ) : tabValue === tabs.OVERVIEW ? (
          overviewTab
        ) : tabValue === tabs.GENERAL_INFO ? (
          generalInfoTab
        ) : tabValue === tabs.METRIC_RUNS ? (
          metricRunTab
        ) : (
          <></>
        )}

        {values?.canAddRun && tabValue === tabs.METRIC_RUNS ? <AddMetricRunPopup {...runPopupProps} /> : <></>}
        {showPrevNextFab && <PrevNextFab {...mPPrevNextFabProps} />}
      </Box>
    );
};