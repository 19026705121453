import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import APIService from '../../../api/apiService';
import EntityDetailPopup from '../../utility/EntityDetailPopup';
import {
  Box,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
  Tabs,
  Button,
  InputAdornment,
  IconButton,
  Fade,
  Tooltip,
  MenuItem,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Checkbox,
  FormControl,
} from '@mui/material';
import {
  AddEntityPlusIcon,
  CUSTOM,
  DAILY,
  DetailAndDelBtn,
  DisplayTable,
  EntityDetailStepper,
  EyeIcon,
  FRTLPopup,
  ONCE,
  WEEKLY,
  cropClasses,
  fadeTimingMs,
  getTimezoneSansLocaleDate,
  logMsgToConsole,
  miscClasses,
  popupClasses,
  taskOccurrenceChoices,
  taskStatus,
} from '../../utility';
import NoEntityMsg from '../../utility/messages/NoEntityMsg';
import { NEW, getFormattedTime } from '../../utility/constants';
import TimelineIcon from '@mui/icons-material/Timeline';
import TableViewIcon from '@mui/icons-material/TableView';
import CropRunFRTLPopup from '../../utility/popups/CropRunFRTLPopup';
import { FeedbackContext } from '../../../App';
import { DeleteOutline } from '@mui/icons-material';

const headerText = 'Configure your tasks for the routine. Attach general functions to make them really powerful. Customize the way you like.';
const editText = `Edit this in the 'Specifics' tab.`;

const SPECIFICS_TAB = 'specifics';
const ASSIGNEE_TAB = 'assignee';
const ROUTINE_SCHEDULE_TAB = 'routine-schedule';
const GENERAL_FUNCTION_TAB = 'general-function';
const LOGS_TAB = 'logs';
const INSTRUCTIONS_TAB = 'instructions';

const frtlKeys = ['frtl', 'to_frtl', 'from_frtl'];

const tabs = [
  { key: 'Specifics', value: SPECIFICS_TAB },
  { key: 'Assignee', value: ASSIGNEE_TAB },
  { key: 'Schedule', value: ROUTINE_SCHEDULE_TAB },
  { key: 'Instruction', value: INSTRUCTIONS_TAB },
  // { key: 'General Functions', value: GENERAL_FUNCTION_TAB },
  // { key: 'Logs', value: LOGS_TAB },
];

const scheduleView = [
  { key: 'Timeline', value: 0, icon: <TimelineIcon /> },
  { key: 'Table', value: 1, icon: <TableViewIcon /> },
];

const defState = routine => ({
  ...routine,
  ...(routine?.routine_tasks?.length && {
    selectedTab: SPECIFICS_TAB,
    selectedTask: routine?.routine_tasks?.[0]?.id ?? routine?.routine_tasks?.[0]?.tempRef,
  }),
});

const formatDate = date =>
  date.toLocaleDateString('en-GB', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

const getDate = (primary, fallback) => new Date(primary || fallback);

const scheduleKeyTitleMap = [
  {
    key: 'perform_on',
    title: 'Perform Date',
    applyFn: getTimezoneSansLocaleDate,
    extra_params: [
      'en-GB',
      '',
      {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      },
    ],
  },
  { key: 'timestamp', title: 'Perform Time', applyFn: getFormattedTime, extra_params: [] },
  { key: 'status', title: 'Status' },
  {key:'performed_by_name',title: 'Performed By'}
];

const scheduleTableTitles = scheduleKeyTitleMap.map(e => ({
  title: e.title,
}));

const checkTempRefOrId = (value, reference) => (value?.id && value?.id === reference) || (value?.tempRef && value?.tempRef === reference);

function generateSentence(taskSchedules, occurrence, subphase, occurrence_timestamp, showFeedback) {
  let sentence = 'This task will occur ';
  switch (occurrence) {
    case 0:
      let date = new Date(taskSchedules?.[0]?.perform_on);
      sentence += `once, at ${getFormattedTime(taskSchedules?.[0]?.timestamp)}, on ${formatDate(date)} of ${subphase?.name}.`;
      break;
    
    case 1:
      let startDate = new Date(taskSchedules?.[0]?.perform_on);
      let endDate = new Date(taskSchedules?.[taskSchedules?.length - 1]?.perform_on);
      sentence += `daily at ${getFormattedTime(taskSchedules?.[0]?.timestamp)}, from ${formatDate(startDate)} to ${formatDate(endDate)} of ${subphase?.name}.`;
      break;
    
    case 2:
      if (taskSchedules?.indexOf('T') !== -1 ? taskSchedules?.split('T')[0] : subphase?.start_date){
       
      }
      let date1 = new Date(taskSchedules?.[0]?.perform_on);
      let lastDay = new Date(taskSchedules?.[taskSchedules?.length - 1]?.perform_on);

      let timestamp = '', days = '';
      if (occurrence_timestamp) {
        [timestamp, days] = occurrence_timestamp.split('|');
      }
      let formattedDays = days?.split(',').map(day => day.charAt(0).toUpperCase() + day.slice(1)).join(', ');
      sentence += `weekly at ${getFormattedTime(taskSchedules?.[0]?.timestamp)} , on ${formattedDays} from ${formatDate(date1)} to ${formatDate(lastDay)}.`;
      break;
    
    case 3:
      let dates = taskSchedules?.map(schedule => formatDate(new Date(schedule.perform_on)));
      let lastDate = dates?.pop();
      sentence += `at ${getFormattedTime(taskSchedules?.[0]?.timestamp)}, on ${dates?.join(', ')} and ${lastDate}.`;
      break;
    
    default:
      sentence = 'Add your schedule';
      break;
  }
  return sentence;
}


function getNewTaskSchedules(occurrence, occurence_timestamp, subphase, tempStartDate, tempEndDate, existingSchedules = [], showFeedback) {
  let task_schedule = [];
  switch (+occurrence) {
    case 0: // ONCE case
      task_schedule = [
        {
          tempRef: 'taskschedule-0',
          perform_on: occurence_timestamp && occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[0] : subphase?.start_date,
          timestamp: occurence_timestamp && occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[1] : occurence_timestamp,
          status: 'open',
        },
      ];
      break;
    case 1: // DAILY case
      let currDate = tempStartDate ? new Date(tempStartDate) : new Date(subphase?.start_date),
        endDate = tempEndDate ? new Date(tempEndDate) : new Date(subphase?.end_date),
        idx = 0;

      while (currDate <= endDate) {
        task_schedule.push({
          tempRef: `taskschedule-${idx}`,
          perform_on: currDate.toISOString().split('T')[0],
          timestamp: occurence_timestamp && occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[1] : occurence_timestamp,
          status: 'open',
        });
        idx++;
        currDate.setDate(currDate.getDate() + 1);
      }
      break;
    case CUSTOM:
      // For custom, we'll create a single schedule and append it to existing ones
      const nextIndex = existingSchedules.length;
      if (existingSchedules.some(schedule => 
        schedule.perform_on === (occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[0] : subphase?.start_date)
      )) {
          showFeedback();
          return existingSchedules;
        }

      task_schedule = [
        {
          tempRef: `taskschedule-${nextIndex}`,
          perform_on: occurence_timestamp && occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[0] : subphase?.start_date,
          timestamp: occurence_timestamp && occurence_timestamp?.indexOf('T') !== -1 ? occurence_timestamp?.split('T')[1] : occurence_timestamp,
          status: 'open',
          isCustom: true
        }
      ];
      // Combine with existing schedules for custom case
      task_schedule = [...existingSchedules, ...task_schedule];
      break;
    default:
      break;
  }
  return task_schedule;
}

const _scheduleComp = props => {
  const { cropplanid, values: parentValues, subphase, selectedTask, currDarkThemeClass, handleTaskChanges, textFieldProps, showFeedback } = props;
  const isDarkTheme = currDarkThemeClass;
  const [view, setView] = useState(1);
  const [reset, setReset] = useState(false);
  const [values, setValues] = useState({  selectedDays: [],});
  const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);
 
  useEffect(() => {
    if (!selectedTask?.occurrence) {
      setValues(prevState => ({ ...prevState, occurrence: 0, previousOccurrence: 0 }));
    } else {
      setValues(prevState => ({ ...prevState, previousOccurrence: selectedTask?.occurrence }));
    }
    if (selectedTask?.task_task_schedules?.length > 0) {
      setValues(prevState => ({
        ...prevState,
        prevSchedules: selectedTask?.task_task_schedules,
        tempStartDate: selectedTask?.task_task_schedules?.[0]?.perform_on,
        tempEndDate: selectedTask?.task_task_schedules?.[selectedTask?.task_task_schedules?.length - 1]?.perform_on,
      }));
    }
    if (!selectedTask?.occurence_timestamp) setValues(prevState => ({ ...prevState, occurence_timestamp: `${subphase?.start_date}` }));
  }, []);

  useEffect(() => {
    // Modify the reset logic to exclude CUSTOM schedule type
    if ('occurrence' in selectedTask && 
        'previousOccurrence' in values && 
        selectedTask?.occurrence !== values?.previousOccurrence &&
        selectedTask?.occurrence !== CUSTOM) { // Add check for CUSTOM
      setReset(true);
    } else {
      handleTaskChanges('task_task_schedules', values?.prevSchedules);
    }
  }, [
    selectedTask?.occurrence,
    selectedTask?.occurence_timestamp,
    values?.tempStartDate,
    values?.tempEndDate,
    values?.previousOccurrence,
    values?.prevSchedules,
  ]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      handleTaskChanges('occurrence', newValue);

      // if the previousOccurrence is same as selected Tab, reset button should not appear.
      if (values?.previousOccurrence === newValue) {
        setReset(false);
      }
    },
    [parentValues?.routine_tasks]
  );

  const rPTabProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
  };

  const rPTabsProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
    value: selectedTask?.occurrence,
    onChange: handleTabChange,
    sx: {
      marginBottom: '20px',
    },
  };

  const rPTypoProps = {
    className: `${popupClasses?.routines?.typo} ${currDarkThemeClass}`,
  };

  const occurrenceTabsList = useMemo(
    () => taskOccurrenceChoices?.map((occurrence, i) => <Tab value={occurrence.value} label={occurrence.label} key={i} {...rPTabProps} />),
    [parentValues?.selectedTask, parentValues?.routine_tasks]
  );
  const handleTimestampChange = ({ target: { value } }) => {
    const currentTimestamp = selectedTask?.occurence_timestamp || '';
    const [datepart, existingDays] = currentTimestamp.split('|');
    const [date, _] = datepart.split('T');
    
    const newTimestamp = existingDays 
      ? `${date}T${value}|${existingDays}` 
      : `${date}T${value}`;
    
    handleTaskChanges('occurence_timestamp', newTimestamp);
    setReset(true);
  };
  

  const occurrenceTimeStampProps = {
    otherTextProps: {
      value: (selectedTask?.occurence_timestamp?.split(/[T|]/)[1] ?? '00:00'),
      onChange: handleTimestampChange,
      type: 'time',
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Typography {...rPTypoProps}>Time</Typography>
          </InputAdornment>
        ),
      },
    },
  };
  const handleDateChange = ({ target: { value } }) => {
    const currentTimestamp = selectedTask?.occurence_timestamp || '';
    const [_, timepart, existingDays] = currentTimestamp.split(/[T|]/);
    
    const newTimestamp = existingDays
      ? `${value}T${timepart || '00:00'}|${existingDays}`
      : `${value}T${timepart || '00:00'}`;
    
    handleTaskChanges('occurence_timestamp', newTimestamp);
    setReset(true);
  };
  const occurrenceDateProps = {
    otherTextProps: {
      required: true,
      value: selectedTask?.occurence_timestamp?.split('T')?.[0],
      onChange: handleDateChange,
      type: 'date',
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Typography {...rPTypoProps}>Date</Typography>
          </InputAdornment>
        ),
      },
    },
    inputProps: {
      min: subphase?.start_date,
      max: subphase?.end_date,
    },
  };

  const taskScheduleDateProps = (prop, label, defaultValue) => ({
    otherTextProps: {
      value: values?.[prop]?.split('T')[0],
      onChange: ({ target: { value } }) => {
        handleSetValues(prop, value);
        setReset(true);
      },
      type: 'date',
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Typography {...rPTypoProps}>{label}</Typography>
          </InputAdornment>
        ),
      },
    },
    inputProps: {
      min: subphase?.start_date,
      max: subphase?.end_date,
    },
  });
  const toggleBtnProps = {
    size: 'small',
    color: 'primary',
    value: view,
    exclusive: true,
    onChange: (event, newValue) => setView(newValue),
    sx: {
      gridArea: '2/2/3/3',
      justifySelf: 'end',
    },
  };

  const scheduleToggleButton = useMemo(
    () =>
      !reset ? (
        <ToggleButtonGroup {...toggleBtnProps}>
          {scheduleView?.map((e, i) => (
            <ToggleButton value={e.value} key={i}>
              {e.icon}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : (
        <></>
      ),
    [view, reset]
  );

  const handleDeleteSchedule = (scheduleIndex) => {
    const updatedSchedules = selectedTask?.task_task_schedules?.filter((_, index) => index !== scheduleIndex) || [];
    
    setValues(prevState => ({
      ...prevState,
      prevSchedules: updatedSchedules,
    }));

    handleTaskChanges('task_task_schedules', updatedSchedules);
  };

  
  // Modify the table entries to include delete action
  const tableEntries = useMemo(
    () => {
      const filteredSchedules = selectedTask?.task_task_schedules?.filter(schedule => {
        if (selectedTask?.occurrence === CUSTOM) {
          return schedule.createdInMode === CUSTOM;
        }
        return schedule.createdInMode === selectedTask?.occurrence;
      });

      return filteredSchedules?.map((schedule, i) => [
        i + 1,
        ...scheduleKeyTitleMap?.map(e => 
          ('applyFn' in e ? e?.applyFn(schedule?.[e?.key], ...e?.extra_params) : schedule?.[e?.key])
        ),
        // Add delete action column
        <IconButton 
          onClick={() => handleDeleteSchedule(i)}
          disabled={schedule?.status === 'complete'}
          sx={{ 
            color: isDarkTheme ? '#e0e0e0' : '#666',
            '&:hover': {
              color: isDarkTheme ? '#ff6b6b' : '#ff4444'
            },
            '&.Mui-disabled': {
              color: isDarkTheme ? '#666' : '#ccc'
            }
          }}
          size="small"
          title={schedule?.status === 'complete' ? "Cannot delete completed schedule" : "Delete schedule"}
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      ]);
    },
    [selectedTask?.task_task_schedules, selectedTask?.occurrence, isDarkTheme]
  );
  const sTDTProps = {

    rowsPerPage: 5,
    toShowAddBtn: false,
    emptyTableMsg : "No Schedule Exists, Please Create One.",
    tableTitles: [
      { title: 'Sl No.' }, 
      ...scheduleTableTitles,
      { title: 'Actions' } // Add actions column
    ],
    tableEntries,
    currDarkThemeClass,
    baseProps: {
      sx: {
        gridArea: '3/1/4/3',
      },
    },
  };

  const handleResetSchedules = () => {
    // Keep completed tasks only from the current mode
    let completedTaskSchedules = (selectedTask?.task_task_schedules ?? [])?.filter(e => 
      e?.status === 'complete' && e.createdInMode === selectedTask?.occurrence
    );
    
    let newTaskSchedules = [];
    const cleanTimestamp = selectedTask?.occurence_timestamp?.split('|')[0];
  
    if (selectedTask?.occurrence === WEEKLY && values.selectedDays?.length > 0) {
      const startDate = new Date(values.tempStartDate);
      const endDate = new Date(values.tempEndDate);
      const selectedDaysSet = new Set(values.selectedDays);
      const [_, timestamp, selectedDays] = selectedTask?.occurence_timestamp?.split(/[T|]/) || [];
  
      while (startDate <= endDate) {
        if (selectedDaysSet.has(startDate.getDay())) {
          newTaskSchedules.push({
            tempRef: `taskschedule-${newTaskSchedules.length}`,
            perform_on: startDate.toISOString().split('T')[0],
            timestamp: timestamp || '00:00',
            status: 'open',
            createdInMode: WEEKLY
          });
        }
        startDate.setDate(startDate.getDate() + 1);
      }
    } else if (selectedTask?.occurrence === CUSTOM) {
      // For custom schedules, get existing schedules from custom mode
      const existingCustomSchedules = (selectedTask?.task_task_schedules ?? [])
        .filter(schedule => schedule.createdInMode === CUSTOM);
      
      newTaskSchedules = getNewTaskSchedules(
        selectedTask?.occurrence,
        cleanTimestamp,
        subphase,
        values?.tempStartDate,
        values?.tempEndDate,
        existingCustomSchedules,
        showFeedback
      ).map(schedule => ({
        ...schedule,
        createdInMode: CUSTOM
      }));
    } else {
      // Handle other schedule types (ONCE, DAILY)
      newTaskSchedules = getNewTaskSchedules(
        selectedTask?.occurrence,
        cleanTimestamp,
        subphase,
        values?.tempStartDate,
        values?.tempEndDate,
        showFeedback
      ).map(schedule => ({
        ...schedule,
        createdInMode: selectedTask?.occurrence
      }));
    }

    // Handle case for existing data without createdInMode
    const allSchedules = [...completedTaskSchedules, ...newTaskSchedules].map(schedule => {
      if (schedule.createdInMode === undefined) {
        // If no mode is set, assign it to the current mode
        return {
          ...schedule,
          createdInMode: selectedTask?.occurrence
        };
      }
      return schedule;
    });
  
    setValues(prevState => ({
      ...prevState,
      prevSchedules: allSchedules,
      previousOccurrence: selectedTask?.occurrence,
    }));
  
    handleTaskChanges('task_task_schedules', allSchedules);
    setReset(false);
  };

  // When handling initial data load, assign modes to existing schedules
  useEffect(() => {
    if (selectedTask?.task_task_schedules?.length > 0) {
      const schedulesWithModes = selectedTask.task_task_schedules.map(schedule => ({
        ...schedule,
        createdInMode: schedule.createdInMode || selectedTask.occurrence
      }));
      
      setValues(prevState => ({
        ...prevState,
        prevSchedules: schedulesWithModes,
        tempStartDate: schedulesWithModes[0]?.perform_on,
        tempEndDate: schedulesWithModes[schedulesWithModes.length - 1]?.perform_on,
      }));

      handleTaskChanges('task_task_schedules', schedulesWithModes);
    }
  }, []);

  const handleDaySelection = (dayIndex, isSelected) => {
    setValues((prevState) => {
      const currentSelectedDays = prevState.selectedDays || [];
      
      let updatedDays;
      if (isSelected) {
        updatedDays = currentSelectedDays.includes(dayIndex) 
          ? currentSelectedDays 
          : [...currentSelectedDays, dayIndex].sort((a, b) => a - b);
      } else {
        updatedDays = currentSelectedDays.filter((day) => day !== dayIndex);
      }
  
      const uniqueDayNames = Array.from(new Set(updatedDays))
        .map((day) => ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][day]);
      
      const [dateTime] = (selectedTask?.occurence_timestamp || '').split('|');
      const newTimestamp = uniqueDayNames.length
        ? `${dateTime}|${uniqueDayNames.join(',')}`
        : dateTime;
      handleTaskChanges('occurence_timestamp', newTimestamp);
      setReset(true);
      return {
        ...prevState,
        selectedDays: updatedDays,
        occurence_timestamp: newTimestamp,
      };
    });
  };
  useEffect(() => {
    if (selectedTask?.occurence_timestamp) {
      const parts = selectedTask.occurence_timestamp.split('|');
      
      // If not in WEEKLY occurrence, reset accordingly
      if (selectedTask.occurrence !== WEEKLY) {
        const [dateTime] = parts;
        handleTaskChanges('occurence_timestamp', dateTime);
        
        setValues(prevState => ({
          ...prevState,
          selectedDays: []
        }));
      } else if (parts.length > 1) {
        // For weekly, parse day indexes
        const selectedDayNames = parts[parts.length - 1].split(',');
        const dayIndexes = selectedDayNames.map(day => 
          ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(day.toLowerCase())
        ).filter(index => index !== -1);
        
        // Ensure setting selectedDays only if in WEEKLY mode
        if (dayIndexes.length > 0) {
          setValues(prevState => ({
            ...prevState,
            selectedDays: dayIndexes
          }));
        }
      }
    }
  }, [selectedTask?.occurence_timestamp, selectedTask?.occurrence]);
  
  
  const schedule = useMemo(() => {
    const isTaskCompleted = selectedTask?.task_status === 'complete';

    // Special handling for CUSTOM schedule type
    if (selectedTask?.occurrence === CUSTOM) {
      return (
        <>
          <DisplayTable {...sTDTProps} />
          <Button 
            variant="contained" 
            sx={{ 
              gridArea: '2/2/3/3',
              justifySelf: 'end',
              marginBottom: '1rem'
            }} 
        
            onClick={handleResetSchedules}
            disabled={isTaskCompleted}
          >
            Add Schedules
          </Button>
        </>
      );
    }

    // Existing logic for other schedule types
    if (reset) {
      return (
        <Button variant="contained" sx={{ gridArea: '3/1/4/3' }} onClick={handleResetSchedules}    disabled={isTaskCompleted}>
          Generate Schedules
        </Button>
      );
    }

    if (view) {
      return <DisplayTable {...sTDTProps} />;
    } else {
      let activeStep = -1;
      const formatLabel = selectedTask.task_task_schedules.map((e, index) => {
        const completed = e.status === 'complete';
        if (completed) activeStep = index + 1;
        return {
          label: formatDate(getDate(e.perform_on)),
          stepProps: {
            completed,
          },
        };
      });

      return (
        <EntityDetailStepper
          activeStep={activeStep}
          steps={formatLabel}
          stepperContainerProps={{
            sx: {
              gridArea: '3/1/4/3',
              overflow: 'scroll',
            },
          }}
        />
      );
    }
  }, [view, selectedTask?.task_task_schedules, reset, selectedTask?.occurrence, 
      selectedTask?.occurence_timestamp, subphase, values?.tempStartDate, 
      values?.tempEndDate, values?.selectedDays]);
  return (
    <Fade timeout={fadeTimingMs} in>
      <Box>
        <Tabs {...rPTabsProps}>{occurrenceTabsList}</Tabs> 
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
          {selectedTask?.occurrence === DAILY ? (
            <>
              <TextField {...textFieldProps(taskScheduleDateProps('tempStartDate', 'Start Date'))} />
              <TextField {...textFieldProps(taskScheduleDateProps('tempEndDate', 'End Date'))} />
              <TextField {...textFieldProps(occurrenceTimeStampProps)} />
            </>
          ) : selectedTask?.occurrence === ONCE ? (
            <>
              <TextField {...textFieldProps(occurrenceDateProps)} />
              <TextField {...textFieldProps(occurrenceTimeStampProps)} />
            </>
          ) : 
          selectedTask?.occurrence === WEEKLY ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <TextField {...textFieldProps(taskScheduleDateProps('tempStartDate', 'Start Date'))} />
                  <TextField {...textFieldProps(taskScheduleDateProps('tempEndDate', 'End Date'))} />
                </div>
          
                <TextField {...textFieldProps(occurrenceTimeStampProps)} />
          
                <FormControl 
  sx={{ 
    gridColumn: "1/3",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)", 
    gap: 1,
    mt: 2
  }}
>
{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
  <FormControlLabel
    key={index}
    control={
      <Checkbox
        size="small"
        checked={values?.selectedDays ? values.selectedDays.includes(index) : false}
        onChange={(e) => handleDaySelection(index, e.target.checked)}
          sx={{
            color: isDarkTheme ? '#e0e0e0' : '#333333', 
            '&.Mui-checked': {
              color: isDarkTheme ? '#9ec96b' : '#0077cc', 
            },
          }}
        />
      }
      label={day}
      sx={{
        margin: 0,
        justifyContent: 'center',
        color: isDarkTheme ? '#e0e0e0' : '#333333',
        fontSize: '0.875rem', 
      }}
    />
  ))}
</FormControl>
              </div>
            </>
          ) 
          : selectedTask?.occurrence === CUSTOM ? (
            <>
            <TextField {...textFieldProps(occurrenceDateProps)} />
            <TextField {...textFieldProps(occurrenceTimeStampProps)} />
          </>
          ) : (
            <></>
          )}
           {!isNaN(+selectedTask?.occurrence) && selectedTask?.occurence_timestamp && (
            <>
              {selectedTask?.occurrence !== CUSTOM && scheduleToggleButton}
              {schedule}
            </>
          )}
        </Box>
      </Box>
    </Fade>
  );
}

export default function RoutinesPopup(props) {
  const { routine, subphase, cropplanid, selectedCropRunFarm, assignees, currDarkThemeClass, onClose, open, toShowValidate = true, validateText = 'Validate', onValidateClick = () => console.log('Validate Details'),loading = false, isMetrc, 
    rowIndex, sendDataToParent
   } = props;

  const [values, setValues] = useState(defState(routine));
  const [popup, setPopup] = useState({});
  const [detailChildren, setDetailChildren] = useState(null);
  const feedbackCX = useContext(FeedbackContext);
  const [validateStatus, setValidateStatus] = useState({
    'is_validated': true,
    'rowIndex': rowIndex,
    'sync_to_metrics': 'Syncing...'
  })

  const fetchRoutines = async (routineId) => {
    try {
      const response = await APIService.fetchInstance(`cropplan-routines/${routineId}/`);
      if (response.status === 200) {
        setValues(defState(response.data));
        if (sendDataToParent) {
          sendDataToParent(response.data);
        }}
    } catch (error) {
      console.error('Error refreshing routine data:', error);
    }};

  const handleSetValues = (prop, value) => setValues(state => ({ ...state, [prop]: value }));
  const handleSetPopup = useCallback((prop, value = false) => setPopup(state => ({ ...state, [prop]: value })));

  const selectedTask = useMemo(
    () => values?.routine_tasks?.find(e => checkTempRefOrId(e, values?.selectedTask)),
    [values?.selectedTask, values?.routine_tasks]
  );

  // Check if all tasks have metrc_review status
  const isAllTasksInMetrcReview = useMemo(() => {
    return values?.routine_tasks?.every(task => task?.status === 'metrc_review');
  }, [values?.routine_tasks]);

  const handleValidate = event => {
    event.preventDefault();
    event.stopPropagation();
    if (toShowValidate) onValidateClick(event);
  };

  const showFeedback = () =>{
    feedbackCX.setContextValue(true, {
      message: 'Schedule already exists for this Date',
      autoHideDuration: 3000,
    });
  }

  // Function to call when you want to send data to parent
  const passDataToParent = async () => {
    if (sendDataToParent) {
      sendDataToParent(validateStatus); // Pass data back to the parent
    }
  };

const handleValidateClick = async () => {
  try {
    const id = routine?.id;
    if (!id) {
      console.error('Routine ID is missing');
      return;
    }

    const response = await APIService.modifyInstance(`cropplan-routines/${id}/`, { is_validated: true });
    
    if (response.status === 200) {
      feedbackCX.setContextValue(true, {
        type: 'success',
        message: 'Metrc Synced Successfully.',
        autoHideDuration: 3000,
      }) 
      console.log('Routine validated successfully');
      await fetchRoutines(id);
      setValues(defState(response.data));
      onClose(); 
    }
  } catch (error) {
    console.error('Error validating routine:', error);
    feedbackCX.setContextValue(true, {
      type: 'success',
      message: 'Error syncing to Metrc. Please try again later.',
      autoHideDuration: 3000,
    }) 
  }
};

const rPValidateBtnProps = {
  className: `${popupClasses?.entityDetail?.saveBtn} ${currDarkThemeClass}`, 
  onClick: handleValidateClick,
  disabled: loading || !isAllTasksInMetrcReview,
};

  const handleTaskValuesChanges = (key, value) => {
    handleSetValues(
      'routine_tasks',
      values?.routine_tasks?.map(e =>
        checkTempRefOrId(e, values?.selectedTask)
          ?  {
            ...e,
            task_values: e?.task_values?.map(defvalue => 
              defvalue?.key === key 
                ? { ...defvalue, value: value === '' ? null : value }
                : defvalue
            ),
          }
          : e
      )
    );
  };

  const handleRoutinesPopupSave = async routineData => {
    try {
      const id = routineData?.id || routine?.id;
      if (!id) {
        console.error('Routine ID is missing');
        return;
      }

      const response = await APIService.modifyInstance(`cropplan-routines/${id}/`, routineData);
      if (response.status === 200) {
        feedbackCX.setContextValue(true, {
          type: 'success',
          message: 'Routine saved successfully.',
          autoHideDuration: 3000,
        }) 
        console.log('Routine saved successfully', response.data);
        await fetchRoutines(id);
        setValues(defState(response.data));  
        onClose();  
      }
    } catch (error) {
      console.error('Error saving routine:', error);
    }
  }

  const handleTaskChanges = (prop, value) => {
    handleSetValues(
      'routine_tasks',
      values?.routine_tasks?.map(e => (checkTempRefOrId(e, values?.selectedTask) ? { ...e, [prop]: value } : e))
    );
  };

  useEffect(() => {
    if (open && routine?.id) {
      fetchRoutines(routine.id);
    } }, [open, routine?.id]);


  const rPTypoProps = {
    className: `${popupClasses?.routines?.typo} ${currDarkThemeClass}`,
  };

  const handleTask = task => () => {
    handleSetValues('selectedTask', task?.id ?? task?.tempRef);
    handleSetValues('selectedTab', SPECIFICS_TAB);
  };
  const handleProfileClick = () => {
    handleSetValues('selectedTab', ASSIGNEE_TAB);
  };
  const handleCalendarClick = () => {
    handleSetValues('selectedTab', ROUTINE_SCHEDULE_TAB);
  };

  const handleInfoClick = () => {
    handleSetValues('selectedTab', INSTRUCTIONS_TAB);
  };

  const rPTaskProps = {
    className: `${popupClasses?.routines?.task} ${currDarkThemeClass}`,
  };

  const rPTaskValueProps = task => ({
    className: `${popupClasses?.routines?.taskValuesContainer} ${currDarkThemeClass} ${checkTempRefOrId(task, values?.selectedTask) && `selected`}`,
  });

  const handlePopupClose = () => {
    setPopup({});
    setDetailChildren({});
  };
  const selectedCropRunFarmProp = selectedCropRunFarm;

  const handleFrtlPopupOpen = (frtlKey, frtl) => {
    const [farm, room, table, level] = frtl?.split('.').map(e => +e ?? e) ?? [props?.farm?.id ?? '', props?.room ?? '', '', ''];
    handleSetPopup('frtl', true);
    setDetailChildren({
      currDarkThemeClass,
      farm: selectedCropRunFarmProp || farm ,
      room,
      table,
      level,
      handleEditFrtl: value => handleTaskValuesChanges(frtlKey, value),
    });
  };

  const textFieldProps = ({ prop, inputProps = {}, otherTextProps = {}, otherTextFieldClasses = '' }) => ({
    autoComplete: 'off',
    className: `${popupClasses?.routines?.textfield} ${currDarkThemeClass} ${otherTextFieldClasses}`,
    disabled: values?.processing,
    fullWidth: true,
    inputProps,
    value: (otherTextProps?.type === 'number' ? (!values?.[prop] && values?.[prop] !== 0 ? '' : Number(values?.[prop])) : values?.[prop]) ?? '',

    onChange: ({ target: { value } }) => handleSetValues(prop, value),

    ...otherTextProps,
  });

  const rPTaskValueInputProps = (task, defaultValue) => ({
    className: `${popupClasses?.routines?.textfield} ${currDarkThemeClass}`,
    value: defaultValue?.value ?? '',
    fullWidth: true,
    placeholder: 'Edit',
    required: defaultValue?.isRequired && defaultValue.hasOwnProperty('isRequired'),
    disabled: !defaultValue?.isEditable && defaultValue.hasOwnProperty('isEditable'),
    onChange: ({ target: { value } }) => handleTaskValuesChanges(defaultValue?.key, value),
    ...(frtlKeys.includes(defaultValue?.datatype) && {
      InputProps: {
        endAdornment: (
          <InputAdornment position="end" onClick={() => handleFrtlPopupOpen(defaultValue?.key, defaultValue?.value)}>
            <IconButton>
              <EyeIcon />
            </IconButton>
          </InputAdornment>
        ),
      },
    }),
  });

  const cropPlanMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const statusList = useMemo(() => {
    // Check if all routine_tasks have isMetrc as true
    const isMetrc = values?.routine_tasks?.every(task => task.isMetrc) ?? false;

    // Define indices for non-Metrc tasks
    const nonMetrcIndices = [1, 2, 3, 4, 8]; // pending, open, complete, ongoing, archive

    // Filter status list based on isMetrc
    const statusesToShow = isMetrc 
        ? taskStatus // Show all statuses if isMetrc is true
        : taskStatus.filter((_, index) => nonMetrcIndices.includes(index));

    return statusesToShow.map((status, i) => (
        <MenuItem key={i} value={status.id} disabled={status.disabled} {...cropPlanMenuItemProps}>
            {status.name}
        </MenuItem>
    ));
}, [taskStatus, values?.routine_tasks]);
  const specificsTab = useMemo(() => {
    const task = values?.routine_tasks?.find(e => checkTempRefOrId(e, values?.selectedTask)) ?? {};
  
    // Check if the task status is 'metrc_synced', 'archive
    const isEdit = task?.status === 'metrc_synced' || task?.status === 'archive' || task?.status === 'metrc_validated';
  
    const tasksKeys = [
      { key: 'name', label: 'Task Name' },
      {
        key: 'status',
        label: 'Status',
        otherProps: {
          children: statusList,
          select: true,
        },
      },
      { key: 'man_hours', label: 'Man Hours', otherProps: { type: 'number' } },
      { key: 'start', label: 'Started On', otherProps: { type: 'datetime-local', disabled: true } },
      { key: 'end', label: 'Ended On', otherProps: { type: 'datetime-local', disabled: true } },
      { key: 'assignee_name', label: 'Owned By', otherProps: { type: 'name', disabled: true } },
    ];

    const tasksEdits =
      tasksKeys?.map((e, i) => (
        <Box display="grid" gridTemplateColumns="1fr 1fr" key={`tasks-edits-${i}`}>
          <Typography {...rPTypoProps}>{e?.label}</Typography>
          <TextField
            {...textFieldProps({
              prop: e.key,
              otherTextProps: {
                value: task?.[e?.key],
                onChange: ({ target: { value } }) => handleTaskChanges(e.key, value),
                disabled: isEdit || e?.otherProps?.disabled, // Disable field if status is metrc_synced or archive
                ...e.otherProps,
              },
            })}
          />
        </Box>
      )) ?? [];
  
    const list =
      task?.task_values?.map(
        (defaultValue, j) =>
          (!defaultValue.hasOwnProperty('isVisible') || defaultValue?.isVisible) && (
            <Box display="grid" gridTemplateColumns="1fr 1fr" key={j}>
              <Typography {...rPTypoProps}>{defaultValue?.key}</Typography>
              <TextField
                {...rPTaskValueInputProps(task, defaultValue)}
                disabled={isEdit || !defaultValue?.isEditable || defaultValue.datatype==="frtl" || defaultValue.datatype==="to_frtl"} 
              />
            </Box>
          )
      ) ?? [];


    // const

  
    // const

    return [...list, ...tasksEdits];
  }, [values?.selectedTask, values?.routine_tasks, values?.task_values]);
  

  const InstructionTab = useMemo(() => {
    const task = values?.routine_tasks?.find(e => checkTempRefOrId(e, values?.selectedTask)) ?? {};
  
    // Function to handle changes to instructions using the unique ID
    const handleInstructionChange = (value, id) => {
      let updatedInstructions;
      if (task?.task_instructions?.length > 0) {
        updatedInstructions = task.task_instructions
          .map(instr => 
            instr.id === id ? (value.trim() === '' ? null : { ...instr, instruction: value }) : instr
          )
          .filter(instr => instr !== null);
      } else {
        updatedInstructions = value.trim() === '' ? [] : [{ instruction: value }];
      }
  
      handleTaskChanges('task_instructions', updatedInstructions);
    };
  
    // Mapping over the task_instructions and rendering the list
    const instructionsList = task?.task_instructions?.length
      ? task.task_instructions.map(instructionObj => (
          <Box display="grid" key={`instruction-${instructionObj?.id}`}>
            <TextField
              {...textFieldProps({
                prop: `task_instructions`,
                otherTextProps: {
                  value: instructionObj?.instruction || '',
                  onChange: ({ target: { value } }) => handleInstructionChange(value, instructionObj?.id),
                  placeholder: 'Enter instruction here...',
                  multiline: true,
                  rows: 10,
                },
              })}
            />
          </Box>
        ))
      : [
          <Box display="grid" key="instruction-empty">
            <Typography {...rPTypoProps}>Specific instruction for task</Typography>
            <TextField
              {...textFieldProps({
                prop: `task_instructions`,
                otherTextProps: {
                  value: '',
                  onChange: ({ target: { value } }) => handleInstructionChange(value, null),
                  placeholder: 'Enter instruction here...',
                  multiline: true,
                  rows: 10,
                },
              })}
            />
          </Box>,
        ];
  
    return instructionsList;
  }, [values?.selectedTask, values?.routine_tasks]);
  
  
  const statusProps = task => ({
    label: `Status: ${taskStatus.find(e => e.id === task?.status).name ?? 'Pending'}`,
    className: `${popupClasses?.routines?.chip} ${currDarkThemeClass}`,
  });

  const tasksList = useMemo(() => {
    let tasksList = values?.routine_tasks?.map((task, i) => {
      const values = task?.task_values?.map(
        (defaultValue, j) =>
          (!defaultValue.hasOwnProperty('isVisible') || defaultValue?.isVisible) && (
            <Stack direction="row" justifyContent="space-between" key={j}>
              <Typography {...rPTypoProps}>{defaultValue?.key}</Typography>
              <Typography {...rPTypoProps}>{defaultValue?.value ?? editText}</Typography>
              {/* <TextField {...rPTaskValueInputProps(task, defaultValue)} /> */}
            </Stack>
          )
      );

      const rPDetailAndDelBtnProps = {
        toShowProfileBtn: true,
        toShowCalendarBtn: true,
        toShowInfoBtn: true,
        onProfileClick: handleProfileClick,
        onCalendarClick: handleCalendarClick,
        onInfoClick: handleInfoClick,
        onDetailClick: handleTask(task)
      };
      return (
        <Box key={i} {...rPTaskProps}>
          <Typography {...rPTypoProps} variant="subtitle1">{`Task #${task?.id ?? i + 1}: ${task?.name ?? 'Unknown Task'}`}</Typography>
          <Stack direction="row" justifyContent="space-between" marginTop={1} marginBottom={1}>
            <Chip {...statusProps(task)} />
            <DetailAndDelBtn {...rPDetailAndDelBtnProps} />
          </Stack>
          <Box {...rPTaskValueProps(task)}>{values}</Box>
          <Typography {...rPTypoProps} fontSize={14}>
            {generateSentence(task?.task_task_schedules, task?.occurrence, subphase, task?.occurence_timestamp, showFeedback)}
          </Typography>
        </Box>
      );
    });

    return tasksList;
  }, [routine, values?.selectedTask, values?.routine_tasks]);

  const rpStackProps = (task, assignee) => ({
    className: `${popupClasses?.routines?.assignee} ${currDarkThemeClass} ${task?.task_role?.includes(assignee?.id) && 'selected'}`,
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const handleAssign = assignee => () => {
    // un-assign if unassign is clicked or otherwise
    handleSetValues(
      'routine_tasks',
      values?.routine_tasks?.map(e =>
        checkTempRefOrId(e, values?.selectedTask)
          ? {
              ...e,
              task_role: (e.task_role || []).includes(assignee.id)
                ? e.task_role.filter(role => role !== assignee.id)
                : [...(e.task_role || []), assignee.id],
            }
          : e
      )
    );
  };

  const rpAssigneeBtnProps = assignee => ({
    onClick: handleAssign(assignee),
  });

  const assigneesList = useMemo(() => {
    const task = values?.routine_tasks?.find(e => checkTempRefOrId(e, values?.selectedTask));

    const list = assignees?.map((assignee, i) => (
      <Stack key={i} {...rpStackProps(task, assignee)}>
        <Typography {...rPTypoProps}>{assignee?.name}</Typography>
        <Button {...rpAssigneeBtnProps(assignee)}>{task?.task_role?.includes(assignee?.id) ? 'Unassign' : 'Assign'}</Button>
      </Stack>
    ));

    return list;
  }, [values?.selectedTask, values?.routine_tasks]);

  const rPTabProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
  };

  const tabValuesList = tabs.map((e, i) => <Tab value={e.value} label={e.key} key={i} {...rPTabProps} />);

  const instructions = useMemo(
    () =>
      routine?.default_routine_instruction?.split('\n')?.map((instruction, i) => (
        <Typography key={i} {...rPTypoProps}>
          {instruction ?? ''}
        </Typography>
      )) ??
      routine?.routine_instruction?.split('\n')?.map((instruction, i) => (
        <Typography key={i} {...rPTypoProps}>
          {instruction ?? ''}
        </Typography>
      )) ??
      '',
    []
  );

  const entityDetailPopupProps = {
    currDarkThemeClass,
    onClose,
    open,
    headContentText: headerText,
    modalMaxWidth: '100vw',
    title: `Routine: ${routine?.name ?? 'Unknown Routine'}`,
    onSaveClick: () => handleRoutinesPopupSave(values),
    onValidateClick:()=> handleValidateClick(values),
    toShowValidate: isAllTasksInMetrcReview,  
    loading: loading
  };

  const rPTitleTypoProps = {
    className: `${popupClasses?.routines?.titleTypo} ${currDarkThemeClass}`,
  };

  const rPPaperProps = {
    className: `${popupClasses?.routines?.tasksContainer} ${currDarkThemeClass}`,
    varaint: 'none',
    elevation: 0,
    square: true,
  };

  const rToolboxProps = {
    width: '50%',
    className: `${popupClasses?.routines?.toolboxContainer} ${currDarkThemeClass}`,
  };

  const handleTabChange = useCallback((event, newValue) => handleSetValues('selectedTab', newValue), []);

  const rPTabsProps = {
    className: `${cropClasses?.phases?.phaseTab} ${currDarkThemeClass}`,
    variant: 'scrollable',
    scrollButtons: 'auto',
    value: values?.selectedTab,
    onChange: handleTabChange,
  };

  const rPScheduleProps = {
    cropplanid,
    currDarkThemeClass,
    subphase,
    values,
    selectedTask,
    handleSetValues,
    handleTaskChanges,
    textFieldProps,
    showFeedback
  };

  if (logMsgToConsole?.popups?.routine) {
    console.log(props);
    console.log(values);
  }

  return (
    <EntityDetailPopup {...entityDetailPopupProps}>
      {instructions}
      {values?.routine_tasks?.length !== 0 ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop={2}>
          <Box width="45%">
            <Stack justifyContent="space-between" direction="row">
              <Typography {...rPTitleTypoProps}>All Tasks</Typography>
              <Tooltip title="coming soon">
                <IconButton>
                  <AddEntityPlusIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Paper {...rPPaperProps}>{tasksList}</Paper>
          </Box>
          <Box {...rToolboxProps}>
            <Typography {...rPTitleTypoProps}>{`Task #${selectedTask?.id ?? ''} Toolbox`}</Typography>
            <Paper {...rPPaperProps}>
              <Tabs {...rPTabsProps}>{tabValuesList}</Tabs>
              <Box display="flex" flexDirection="column" gap={1}>
                {values?.selectedTab === ASSIGNEE_TAB ? (
                  assigneesList
                ) : values?.selectedTab === ROUTINE_SCHEDULE_TAB ? (
                  <_scheduleComp {...rPScheduleProps} />
                ) : values?.selectedTab === GENERAL_FUNCTION_TAB ? (
                  <NoEntityMsg msg="This Tab is under development" isHeadContent={false} isLogo={false} />
                ) : values?.selectedTab === SPECIFICS_TAB ? (
                  specificsTab
                ) : values?.selectedTab === LOGS_TAB ? (
                  <NoEntityMsg msg="This Tab is under development" isHeadContent={false} isLogo={false} />
                ) : values?.selectedTab === INSTRUCTIONS_TAB ? (
                  InstructionTab 
                ): (
                  <></>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <NoEntityMsg
          msg="Tasks for this routine will be created only when above Subphase -> Routines Tasks are done"
          isHeadContent={false}
          isLogo={false}
        />
      )}

      {popup?.frtl && <CropRunFRTLPopup selectedFarm={selectedCropRunFarmProp} open={popup?.frtl} onClose={handlePopupClose} {...detailChildren} />}
    </EntityDetailPopup>
  );
}
