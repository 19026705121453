import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react'
import {
    BaseNewAndDetail,
    getDarkThemeClass,
    defErrProps,
} from '../utility'
import { useTheme } from '@emotion/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useToken from '../../utility/useToken';
import { FeedbackContext, MetrcKeyContext } from '../../App';
import { failsTokenAndSetupCheck } from '../../checks/setup';
import APIService from '../../api/apiService';
import _MetrcTags from './Tabs/_MetrcTagsTab';

const baseEntityName = 'Logistics';
const baseEndpointCheck = false;

const metrcTagsKey = 'metrc-tags';

const tabEntityMap = [
    { key: metrcTagsKey, label: 'Metrc Tags', defaultForDetail: true, validForNew: false },];

const tabCreateTitleMap = {
};

// functions
// const showSaveBtn = (tab) => Object.keys(tabCreateTitleMap).includes(tab);

const handleConfigurationsRes = (setValues) => (res) => {
    const { data = [] } = res;
    let stateValues = {}
    for (let [key, value] of Object.entries(data)) {
        stateValues = { ...stateValues, [key]: value?.results ?? value }
    }

    setValues(state => ({ ...state, ...stateValues, farmsWizard: stateValues?.rooms?.length === 0 }));
}

const handleErrorWithFeedback = feedbackCX => (handleSetValues, errPropsFn) => err => {
    console.log('error occureed');
    handleSetValues('error', true);
    handleSetValues('loading', true);
    const errProps = errPropsFn?.(err) ?? defErrProps;

    feedbackCX?.setContextValue?.(true, errProps);
}

export default function Configurations(props) {

    const [values, setValues] = useState({});

    const navigate = useNavigate();

    const feedbackCX = useContext(FeedbackContext);

    const theme = useTheme();

    const currDarkThemeClass = useMemo(() => getDarkThemeClass(theme), [theme?.palette?.themeMode]);

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    const { token, tokenDetails } = useToken();

    const invalidTokenState = useMemo(() => failsTokenAndSetupCheck(token, tokenDetails), [token]);

    // const baseSaveBtnChildren = `Add ${tabCreateTitleMap?.[tab] ?? ''}`;

    // disable function to disable save buttons for different tabs.
    const toDisable = () => {
        // different toDisable conditions based on tab
        switch (tab) {
            default:
                return true;
        }
    }

    const handleSetValues = useCallback((prop, value) => setValues(state => ({ ...state, [prop]: value })), [setValues]);

    const handleSetLoading = prop => value => handleSetValues('loading', { ...values?.loading, [prop]: value });

    const handleEntityEntryCreateRes = prop => res => {
        handleSetLoading(prop)(false);

        const { data } = res;
        const newState = data?.length > 0 ? [...values?.[prop], ...data] : [...values?.[prop], data];

        handleSetValues(prop, newState);

        return Promise.resolve({ success: true });
    }

    const handleEntityEntryModifyRes = prop => res => {
        const { data } = res;

        handleSetValues(prop, values?.[prop]?.map(e => e.id === data.id ? data : e))

        return Promise.resolve({ success: true });
    };

    const getDefaultTabForEndpoint = () => tabEntityMap?.find(tab => tab?.defaultForDetail)?.key;

    const metrcTagsprops = {
        currDarkThemeClass,
        strains: values?.strains,
        openStrainsPopup: values?.openStrainsPopup ?? false,
        addNew: values?.add ?? false,
        update: values?.update ?? false,
        handleErrorWithFeedback,
        handleEntityEntryCreateRes,
        handleEntityEntryModifyRes,
        handleSetValues
    }

    const tabContentMap = {
        [metrcTagsKey]: <_MetrcTags {...metrcTagsprops} />,
    };

    const handleSave = () => {
        console.log("Everythting is upto date")
    }

    const handleShowSave = () => {
        console.log("Everythting is upto date")
    }
    const defShowSaveBtn = () => false;

    const baseProps = {
        baseEntityName,
        baseid: undefined,
        currDarkThemeClass,
        tabContentMap,
        tabEntityMap,
        createBaseEntityMsg: '',
        baseEndpointCheck,
        enablePopup: true,
        showSaveBtn: defShowSaveBtn,
        toDisable,
        handleSave,
        getDefaultTabForEndpoint
    }

    return (
        <BaseNewAndDetail {...baseProps} />
    )
}
