import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Avatar, Stack, Checkbox, Tooltip, TextField, MenuItem, FormControlLabel, Button } from '@mui/material';
import BaseNewAndDetail from '../utility/outlines/BaseNewAndDetail';
import {
  cropClasses,
  errFeedbackFn,
  externalDetailClasses,
  fadeTimingMs,
  getHandleErrorWithFeedbackCX,
  metrcVersion,
  miscClasses,
  NEW,
  popupClasses,
} from '../utility/constants';
import useThemeVal from '../../utility/useThemeVal';
import DisplayTable from '../utility/DisplayTable';
import EntityDetailPopup from '../utility/EntityDetailPopup';
import NoEntityMsg from '../utility/messages/NoEntityMsg';
import APIService from '../../api/apiService';
import { DetailAndDelBtn, DownArrowIcon } from '../utility';
import { endpoints } from '../utility/constants';
import { FeedbackContext } from '../../App';
import { IntegrationsBreadcrumbs } from '../utility/BreadcrumbLinks';

const integrationContent = {
  1: {
    name: 'Metrc',
    about: 'Metrc is the most trusted and experienced provider of cannabis regulatory systems in the United States. Our solution combines advanced software, radio-frequency identification (RFID) technology, a dedicated customer-support team, and a secure database to track and trace cannabis from growth, harvest, and processing to testing, transport, and sale',
    link: 'https://www.metrc.com/',
    tabEntityMap: [
      { key: 'overview', label: 'Overview', defaultForDetail: true },
      { key: 'guidelines', label: 'Guidelines' },
      { key: 'privacy-policy', label: 'Privacy Policy' },
      { key: 'metrickey', label: 'Metrc Key' },
      { key: 'endpoints', label: 'Endpoints/API' },
    ]
  },
  2: {
    name: 'TrolMaster',
    about: 'TrolMaster Agro Instruments Co., Ltd. is a leading agriculture technology company specializing in advanced environmental controllers for the horticultural industry.',
    link: 'https://www.trolmaster.com/',
    tabEntityMap: [
      { key: 'overview', label: 'Overview', defaultForDetail: true },
      { key: 'guidelines', label: 'Guidelines' },
      { key: 'privacy-policy', label: 'Privacy Policy' },
    ]
  }
};

const tableTitles = [
  { key: 'default', title: 'Default', minWidth: 40 },
  { key: 'metrc_id', title: 'Metrc Key', minWidth: 100 },
  { key: 'state', title: 'State' },
  { key: 'environments', title: 'Environment' },
  { key: 'version', title: 'Metrc Version' },
  { key: 'metrc_status', title: 'Status', minWidth: 40 },
  { key: 'actions', title: 'Actions' },
];

const defaultList = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

const statusList = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

const getMetrcTempRef = metrc_ids => {
  let num;
  const result = metrc_ids?.length
    ? metrc_ids?.reduce((res, val) => {
        if (val?.tempRef) {
          num = Number(val?.tempRef?.split('-')?.[1]);
          if (num > res) return num;
        }
        return res;
      }, 0)
    : 0;
  return `metrc-${result}`;
};

export default function ExternalDetail(props) {
  const { title = '' } = props;
  const feedbackCX = useContext(FeedbackContext);
  const [savedRes, setSavedRes] = useState({});
  const [values, setValues] = useState({});
  const { integrationid } = useParams();
  const currDarkThemeClass = useThemeVal();
  
  const currentIntegration = integrationContent[integrationid] || integrationContent[1];

  const handleSetValues = (prop, value) => setValues(state => ({ ...state, [prop]: value }));
  const handleSetSavedRes = (prop, value) => setSavedRes(state => ({ ...state, [prop]: value }));
  const handleSetChangeTab = value => handleSetValues('changeTab', value);

  useEffect(() => {
    handleSetValues('name', currentIntegration.name);
    handleSetSavedRes('name', currentIntegration.name);
    if (integrationid === '1') {
      fetchMetrcCollectionEntries();
    }
  }, [integrationid]);

  const fetchMetrcCollectionEntries = async () => {
    try {
      const response = await APIService.fetchInstance('collection/metrc/');
      setValues(state => ({ ...state, ...response.data }));
      setSavedRes(state => ({ ...state, ...response.data }));
    } catch (error) {
      getHandleErrorWithFeedbackCX(feedbackCX)(errFeedbackFn({}));
    }
  };

  const handleEditMetrc = entry => {
    handleSetValues('metrcOpen', true);
    handleSetValues('selMetrc', entry);
    handleSetValues('selectedMetrc', entry?.id ?? entry?.tempRef);
  };

  const handleAddMetrc = () => {
    handleSetValues('metrcOpen', true);
    let tempRef = getMetrcTempRef(values?.metrc_ids ?? []);
    handleSetValues('selMetrc', {
      metrc_id: '',
      state: '',
      environment_type: '',
      version: 'v2',
      metrc_status: 0,
      default: false,
      tempRef,
    });
    handleSetValues('selectedMetrc', tempRef);
  };

  const handleDeleteMetrc = async id => {
    try {
      const entityWithID = endpoints.metrc.detail(id);
      await APIService.deleteInstance(entityWithID);
      const updatedEntries = values?.metrc_ids.filter(entry => entry.id !== id);
      handleSetValues('metrc_ids', updatedEntries);
    } catch (error) {
      getHandleErrorWithFeedbackCX(feedbackCX)(errFeedbackFn({}));
    } finally {
      handleFinally();
    }
  };

  const handleModifyMetrcRes = res => {
    handleSetValues(
      'metrc_ids',
      values?.metrc_ids?.map(e => (e?.id && e?.id === values?.selectedMetrc ? res.data : e))
    );
  };

  const handleMetrcAddRes = res => {
    handleSetValues('metrc_ids', [...values?.metrc_ids, res.data]);
  };

  const handleFinally = () => {
    handleSetValues('loading', false);
    handlePopupClose();
  };

  const handleAddOrEditMetrc = () => {
    handleSetValues('loading', true);
    let isEdit = !isNaN(+values?.selectedMetrc);
    if (isEdit) {
      let entityWithID = endpoints.metrc.detail(+values?.selectedMetrc);
      APIService.modifyInstance(entityWithID, values?.selMetrc)
        .then(handleModifyMetrcRes)
        .catch(getHandleErrorWithFeedbackCX(feedbackCX)(errFeedbackFn({})))
        .finally(handleFinally);
    } else {
      let entity = endpoints.metrc.list;
      APIService.createInstance(entity, values?.selMetrc)
        .then(handleMetrcAddRes)
        .catch(getHandleErrorWithFeedbackCX(feedbackCX)(errFeedbackFn({})))
        .finally(handleFinally);
    }
  };

  const handlePopupClose = () => {
    handleSetValues('metrcOpen', false);
  };

  const handleCheckboxChange = async id => {
    try {
      const removedEntry = values?.metrc_ids.find(entry => entry.default);
      const removed_id = removedEntry?.id;
      const newDefaultEntry = values?.metrc_ids.find(entry => entry.id === id);

      const payload = {
        ...newDefaultEntry,
        default: true,
        default_id: id,
        removed_id: removed_id,
        metrc_status: newDefaultEntry.metrc_status === 'Active',
      };

      await APIService.modifyInstance(`metrc-key/${id}/`, payload);

      setValues(prevValues => ({
        ...prevValues,
        metrc_ids: prevValues.metrc_ids.map(entry => (entry.id === id ? { ...entry, default: true } : { ...entry, default: false })),
      }));
    } catch (error) {
      console.error('Error setting default Metrc entry:', error);
    }
  };

  const getStatusColor = metrc_status => {
    if (metrc_status === true) {
      return '#f44336';
    } else if (metrc_status === false) {
      return '#4caf50';
    } else {
      return '#000';
    }
  };

  const getEnvironmentName = (code) => {
    const environment = savedRes?.environments?.find(env => env.code === code);
    return environment ? environment.name : 'Unknown Environment';
  };

  const tableEntries = (values?.metrc_ids ?? [])?.map((entry, i) => [
    <Checkbox checked={entry.default} onChange={() => handleCheckboxChange(entry.id)} />,
    entry?.metrc_id,
    entry?.state,
    getEnvironmentName(entry?.environment_type),
    entry?.version,
    <Tooltip title={entry.metrc_status ? 'Inactive' : 'Active' } arrow>
      <Avatar sx={{ backgroundColor: getStatusColor(entry?.metrc_status), width: 24, height: 24 }}>
        {entry?.metrc_status}
      </Avatar>
    </Tooltip>,
    <DetailAndDelBtn onDetailClick={() => handleEditMetrc(entry)} onDelClick={() => handleDeleteMetrc(entry?.id ?? entry?.tempRef)} />,
  ]);

  const OverviewTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box className={`${externalDetailClasses?.box} ${currDarkThemeClass}`}>
        <Typography className={`${externalDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`}>About</Typography>
        <Typography className={`${externalDetailClasses?.tabContentTypo} ${currDarkThemeClass}`}>
          {currentIntegration.about}
        </Typography>
        <Typography className={`${externalDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`}>Links</Typography>
        <Link 
          className={`${externalDetailClasses?.tabContentLinkTypo} ${currDarkThemeClass}`} 
          href={currentIntegration.link} 
          target="_blank"
        >
          {currentIntegration.link}
        </Link>
      </Box>
    </Fade>
  );

  const MetrcKeyTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box className={`${externalDetailClasses?.box} ${currDarkThemeClass}`}>
        <Typography className={`${externalDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`} sx={{ mt: '32px' }}>
          Metrc Key
        </Typography>
        <DisplayTable
          entity="Metrc Entry"
          tableTitles={tableTitles}
          tableEntries={tableEntries}
          currDarkThemeClass={currDarkThemeClass}
          onClick={handleAddMetrc}
        />
      </Box>
    </Fade>
  );

  const GuidelinesTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box className={`${externalDetailClasses?.box} ${currDarkThemeClass}`}>
        <Typography className={`${externalDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`}>
          Guidelines will be available soon
        </Typography>
      </Box>
    </Fade>
  );

  const PrivacyPolicyTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box className={`${externalDetailClasses?.box} ${currDarkThemeClass}`}>
        <Typography className={`${externalDetailClasses?.tabTitleTypo} ${currDarkThemeClass}`}>
          Privacy Policy will be available soon
        </Typography>
      </Box>
    </Fade>
  );

  const EndpointsTab = (
    <Fade in={true} timeout={fadeTimingMs}>
      <Box className={`${externalDetailClasses?.box} ${currDarkThemeClass}`}>
        <NoEntityMsg msg="Application is under development" isCreateEnabled={false} entity="Endpoints/API" />
      </Box>
    </Fade>
  );

  const defUndVal = '';

  const baseEndpointCheck = integrationid === NEW;
  const navigate = useNavigate();
  const breadcrumbsProps = {
    currLink: baseEndpointCheck ? values?.name ?? 'Create Integration' : savedRes?.name ?? defUndVal,
    currDarkThemeClass,
    navigate,
  };
  const breadcrumbs =  <IntegrationsBreadcrumbs {...breadcrumbsProps} />;
  const tabContentMap = {
    overview: OverviewTab,
    guidelines: GuidelinesTab,
    'privacy-policy': PrivacyPolicyTab,
    ...(integrationid === '1' && {
      metrickey: MetrcKeyTab,
      endpoints: EndpointsTab
    })
  };

  const externalDetailProps = {
    baseEntityName: savedRes?.name,
    baseid: integrationid,
    changeTab: values?.changeTab,
    createBaseEntityMsg: '',
    tabEntityMap: currentIntegration.tabEntityMap,
    tabContentMap,
    breadcrumbs,
    baseEndpointCheck: false,
    currDarkThemeClass,
    handleSetChangeTab,
    showSaveBtn: () => false,
  };

  const metrcPopupProps = {
    loading: values?.loading,
    open: values?.metrcOpen,
    currDarkThemeClass,
    onClose: handlePopupClose,
    modalMaxWidth: '860px',
    toDisableSave: false,
    toShowSave: true,
    headContentText: 'Edit or Add Metrc Entry',
    saveText: 'Save',
    title: values?.metrcEdit ? 'Edit Metrc' : 'Add Metrc',
    onSaveClick: () => handleAddOrEditMetrc(),
  };

  const stateMenuItemProps = {
    className: `${cropClasses?.menuItem} ${currDarkThemeClass}`,
    focusVisibleClassName: miscClasses?.menuItemFocusDark,
  };

  const metrcVersionList = metrcVersion?.map((e, i) => (
    <MenuItem key={e.id} value={e.name} {...stateMenuItemProps}>
      {e.name}
    </MenuItem>
  ));

  if(!metrcVersionList?.length){
    metrcVersionList?.push(
      <MenuItem key="v2" value="v2" disabled {...stateMenuItemProps}>
          v2
      </MenuItem>
    )
  }

  const list = savedRes?.states?.map((e, i) => (
    <MenuItem key={i} value={e?.code} {...stateMenuItemProps}>
      {e?.name}
    </MenuItem>
  )) ?? [];

  if (!list?.length) {
    list?.push(
      <MenuItem key={-1} value={-1} disabled {...stateMenuItemProps}>
        No Available States.
        </MenuItem>
    );
  }

  const environmentList = savedRes?.environments?.map((e, i) => (
    <MenuItem key={i} value={e?.code} {...stateMenuItemProps}>
      {e?.name}
    </MenuItem>
  )) ?? [];

  if (!environmentList?.length) {
    environmentList?.push(
      <MenuItem key={-1} value={-1} disabled {...stateMenuItemProps}>
        No Available Environments.
      </MenuItem>
    );
  }

  const textFieldProp = ({ prop, inputProps = {}, otherTextProps = {} }) => ({
    autoComplete: 'off',
    className: `${popupClasses?.strainEntry?.textField} ${currDarkThemeClass}`,
    fullWidth: true,
    inputProps,
    label: '',
    onChange: ({ target: { value } }) => handleSetValues('selMetrc', { ...values?.selMetrc, [prop]: value }),
    required: true,
    value: values?.selMetrc?.[prop],
    ...otherTextProps,
  });

  const metrcKeyProps = {
    prop: 'metrc_id',
    otherTextProps: {
      label: 'Metrc Key',
    },
  };

  const stateProps = {
    prop: 'state',
    otherTextProps: {
      label: 'State',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  };

  const environmentProps = {
    prop: 'environment_type',
    otherTextProps: {
      label: 'Environment',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  };

  const metrcVersionProps = {
    prop: 'version',
    otherTextProps: {
      label: 'Metrc Version',
      required: true,
      select: true,
      SelectProps: {
        IconComponent: DownArrowIcon,
      },
    },
  };

  return (
    <>
      <BaseNewAndDetail {...externalDetailProps} />
      {values?.metrcOpen && integrationid === '1' && (
        <EntityDetailPopup {...metrcPopupProps}>
          <TextField {...textFieldProp(metrcKeyProps)} />
          <TextField {...textFieldProp(stateProps)}>{list}</TextField>
          <TextField {...textFieldProp(metrcVersionProps)}>{metrcVersionList}</TextField>
          <TextField {...textFieldProp(environmentProps)}>{environmentList}</TextField>
        </EntityDetailPopup>
      )}
    </>
  );
}