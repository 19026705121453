import React, { useMemo, useState } from 'react';
import { cropClasses, cropsHomeRoute, fadeTimingMs, maxAccordionCardsBoxHt, phasePlanClasses } from '../../../utility';
import { Fade, Box, Typography, Stack, Divider, TextField } from '@mui/material';
import DetailInfoCard from '../../../utility/DetailInfoCard';
import TodayIcon from '@mui/icons-material/Today';
import { useNavigate } from 'react-router';
import EntityDetailTimeline from '../../utils/EntityDetailTimeline';
import BasicAccordion from '../../utils/BasicAccordion';
import NoEntityMsg from '../../../utility/messages/NoEntityMsg';

const overviewKeysMap = [
  { key: 'CROP ID', lookup: 'id', default: true },
  { key: 'CROP NAME', lookup: 'name', default: true },
  { key: 'DATE OF SERIALIZATION', lookup: 'date_of_serialization', textFieldProps: { type: 'date', InputProps: {} }, default: true },
  { key: 'STRAINS', lookup: 'strains', gridArea: '4/1/5/3', default: true },
  { key: 'SERIALIZED PLANTS', lookup: 'serialized_plants', default: true },
  { key: 'Phase Plan', lookup: 'phaseplan', gridArea: '2/1/3/2' },
  { key: 'Quality Plan', lookup: 'qualityplan', gridArea: '2/2/3/3' },
  { key: 'Owner', lookup: 'owner', gridArea: '3/1/4/2' },
  { key: 'Crop Description', lookup: 'description', gridArea: '1/1/2/3' },
  // { key: 'Plant Count', lookup: 'plant_count', gridArea: '3/2/4/3' },
];

const getNameById = (id, data, lookup) => {
  const item = data?.find(item => item?.id === id);
  return item ? item?.[lookup] : '';
};

const RenderTextField = ({ label, value, currDarkThemeClass, gridArea, ...others }) => (
  <Stack gridArea={gridArea}>
    <Typography className={`${cropClasses.overviewKeyTypo} ${currDarkThemeClass}`} {...others?.typoProps}>
      {label}
    </Typography>
    <TextField
      className={`${cropClasses?.overviewTextField} ${currDarkThemeClass}`}
      value={value}
      InputProps={{ readOnly: true }}
      {...others?.textFieldProps}
    />
  </Stack>
);

export const specificroute = `${cropsHomeRoute}/plans`;

export default function OverviewTab(props) {
  const { currDarkThemeClass, cropplanid, cropPlanPerms, values } = props;

  const navigate = useNavigate();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleAccordionChange = (event, expanded) => {
    setIsAccordionOpen(expanded);
  };

  const handleEdit = res => {
    const defRoute = `${specificroute}/${values?.id}?tab=specifics`;
    navigate(defRoute);
  };

  const oTInfoTypoProps = {
    className: `${phasePlanClasses?.overviewInfoTypo} ${currDarkThemeClass}`,
  };

  const oTInfoTypoKeyProps = {
    className: `${cropClasses.overviewKeyTypo} ${currDarkThemeClass}`,
  };

  const phasePlanName = useMemo(() => getNameById(values?.phaseplan, values?.phaseplans, 'name'), [values?.phaseplan, values?.phaseplans]);
  const qualityPlanName = useMemo(() => getNameById(values?.qualityplan, values?.qualityplans, 'name'), [values?.qualityplan, values?.qualityplans]);
  const ownerName = useMemo(() => getNameById(values?.owner, values?.users, 'first_name'), [values?.owner, values?.users]);
  const strainNames = useMemo(() => values?.strains?.map(strain => strain.strain_name).join(', ') || '', [values?.strains]);

  const valueMap = {
    phaseplan: phasePlanName,
    qualityplan: qualityPlanName,
    owner: ownerName,
    strains: strainNames,
    serialized_plants: `${Math.round(values.serialized_plants)}/${values.plant_count}`,
  };

  const oTAccDetailsProps = (e, i) => ({
    key: i,
    label: e?.key ?? '',
    value: valueMap[e?.lookup] ?? values[e?.lookup],
    currDarkThemeClass,
    gridArea: e?.gridArea,
    textFieldProps: e?.textFieldProps,
  });

  const accordionDetailsEntries = overviewKeysMap.filter(e => !e.default).map((e, i) => <RenderTextField {...oTAccDetailsProps(e, i)} />);

  const accordionDetailsContent = (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
      {accordionDetailsEntries}
    </Box>
  );

  const overviewDefaultEntries = overviewKeysMap
  .filter(e => e.default)
  .map((e, i) => ({
    value: (
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" margin="1rem 0" key={i}>
        <Typography {...oTInfoTypoKeyProps}>{e.key} :</Typography>
        <Typography {...oTInfoTypoProps}>{valueMap[e.lookup] || values[e.lookup]}</Typography>
      </Stack>
    ),
  }));

  const oTDividerProps = {
    textAlign: 'left',
    className: `${cropClasses.overviewDivider} ${currDarkThemeClass}`,
  };

  const oTAccordionProps = {
    detail: accordionDetailsContent,
    accordionCustomProps: {
      className: `${cropClasses.overviewAccordion} ${currDarkThemeClass}`,
      onChange: handleAccordionChange,
      expanded: isAccordionOpen,
    },
    accordionDetailsCustomProps: {
      className: `${cropClasses.overviewAccordionDetails} ${currDarkThemeClass}`,
    },
    summary: <Divider {...oTDividerProps}>{isAccordionOpen ? 'Hide Details' : 'More Details'}</Divider>,
    currDarkThemeClass,
  };

  const accordion = <BasicAccordion {...oTAccordionProps} />;

  const serializedPlantsInfo = {
    value: (
      <Stack direction="row" justifyContent="space-between" flexWrap="wrap" margin="1rem 0">
        <Typography {...oTInfoTypoKeyProps}>Serialized Plants :</Typography>
        <Typography {...oTInfoTypoProps}>{Math.round(values.serialized_plants)}/{values.plant_count}</Typography>
      </Stack>
    ),
  };

const OverviewCardProps = {
    currDarkThemeClass,
    entries: [...overviewDefaultEntries, { value: accordion }],
    cardTitleMarginBottom: '28px',
    toShowEditBtn: cropPlanPerms?.update ?? false,
    editLink: handleEdit,
    title: 'Crop Info',
  };

  const eventDetailsProps = {
    currDarkThemeClass,
    entries: [{value: <NoEntityMsg msg='Timeline Events Under Development'/>}],
    cardTitleMarginBottom: '28px',
    toShowEditBtn: false,
    otherEntriesBoxSxProps: {
      height: `${maxAccordionCardsBoxHt}px`,
      overflow: 'auto',
    },
    title: 'Timeline for Crop Events',
  };

  const oTBoxProps = {
    sx: { width: '49%' },
  };

  const oTSTProps = {
    direction: 'row',
    justifyContent: 'space-between',
    sx: {
      marginTop: '16px',
    },
  };

  if (cropplanid === 'NEW') return <></>;

  return (
    <Fade in={true} timeout={fadeTimingMs}>
      <Stack {...oTSTProps}>
        <Box {...oTBoxProps}>
          <DetailInfoCard {...eventDetailsProps} />
        </Box>
        <Box {...oTBoxProps}>
          <DetailInfoCard {...OverviewCardProps} />
        </Box>
      </Stack>
    </Fade>
  );
}