import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import BlackPopupBG from "./BlackPopupBG";
import GrowDashboardDetailTable from "../tables/GrowDashboardDetailTable";
import SGTransparentBGBtn from "../buttons/TransparentBGBtn";

import { GoBackIcon } from "../CustomSvgIcons";

import { 
    defEmptyArr, defEmptyText, 
    fadeTimingMs, 
    forecastPopupDescClass, 
    forecastPopupStatBoxClass, 
    forecastPopupStatKeyClass, 
    forecastPopupStatValueClass, 
    forecastPopupTitleClass,
    growDashMiniCardTableWd,
    maxForecastTableWd,
} from "../constants";



const dummyKeyValuesList = [
    {key: 'Harvest Count', value: 558},
    {key: 'Inactive Strains Count', value: 6},
];
const dummyTableTitles = ['Harvest', 'Strain', 'Plant Count'];
const dummyTableEntries = [
    ['H15', 'Kitchen Sink', '100'],
    ['H15', 'Miracle Alien Cookies', '200'],
    ['H17', 'Sour Diesel', '158'],
    ['H17', 'Purple Guava Fig', '100'],
];


export default function SeeMoreCardPopup(props) {

    const {
        explanation=defEmptyText,
        keyValuesList=defEmptyArr,
        tableEntries=defEmptyArr,
        tableTitles=defEmptyArr,
        title='Executive Stats',
        propEntryCellSxProps,
        propTitleCellSxProps,    
        ...otherProps
    } = props;

    const theme = useTheme();

    const sMMCPopupProps = {
        ...otherProps,
        // otherPaperSxProps: {
        //     verticalAlign: 'inherit',
        // },
    };

    const sMMCContentBoxProps = {
        sx: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    };

    const sMMCTitleTypoProps = {
        className: forecastPopupTitleClass,
        sx: {
            mb: '12px',
        },
    };

    const sMMCDescTypoProps = {
        className: forecastPopupDescClass,
    };

    const sMMCStatsBoxProps = {
        className: forecastPopupStatBoxClass,
        sx: {
            mt: '22px',
            // '&:first-of-type': {
            //     mt: 0,
            // },
            '&:last-of-type': {
                mb: '38px',
            },
        },
    };

    const sMMCStatsKeyTypoProps = {
        className: forecastPopupStatKeyClass,
        sx: {},
    };

    const sMMCStatsValueTypoProps = {
        className: forecastPopupStatValueClass,
        sx: {},
    };


    const statsList = keyValuesList?.map((e, i) => (
        <Box key={i} {...sMMCStatsBoxProps}>
            <Typography {...sMMCStatsKeyTypoProps}>{e?.key}</Typography>
            <Typography {...sMMCStatsValueTypoProps}>{e?.value}</Typography>
        </Box>
    ));

    const firstColBasis = 10;
    const secondColBasis = 10;

    const sMMCDetailTableProps = {
        otherEntryCellSxProps: (idx) => ({
            // prev: below enabled 
            // flexBasis: `${Math.floor( 100 / ( tableTitles?.length || 1 ) )}%`,
            
            // flexBasis: idx === 0 ? `${firstColBasis}%` 
            //             : idx === 1 ? `${secondColBasis}%` 
            //                 : `${Math.floor( (100 - firstColBasis - secondColBasis ) / ( tableTitles?.length > 2 ? tableTitles?.length - 2 : 1 ) )}%`,
            
        
            px: '12px', // prev: 0
            ...propEntryCellSxProps?.(idx),                
        }),
        otherEntryRowSxProps: {
            // display: 'flex',
            justifyContent: 'space-between',
        },
        otherHeadSxProps: {
            left: 0,
            position: 'sticky',
            top: 0,
        },
        otherTableContainerSxProps: {
            // width: `${growDashMiniCardTableWd}px`,
            // width: `${maxForecastTableWd}px`, // 01032023: prev: enabled
            // width: 'fit-content',
            mt: '38px',
        },
        otherTitleCellSxProps: (idx) => ({
            // prev: below enabled 
            // flexBasis: `${Math.floor( 100 / ( tableTitles?.length || 1 ) )}%`,

            // flexBasis: idx === 0 ? `${firstColBasis}%` : `${Math.floor( (100 - firstColBasis) / ( tableTitles?.length > 1 ? tableTitles?.length - 1 : 1 ) )}%`,
            
            // flexBasis: idx === 0 ? `${firstColBasis}%` 
            //             : idx === 1 ? `${secondColBasis}%` 
            //                 : `${Math.floor( (100 - firstColBasis - secondColBasis ) / ( tableTitles?.length > 2 ? tableTitles?.length - 2 : 1 ) )}%`,
            
            textAlign: 'center',
            px: '12px', // prev: 0
            // minWidth: 'fit-content',
            ...propTitleCellSxProps?.(idx),
        }),
        otherTitleRowSxProps: {
            // display: 'flex', // this will override display: table-cell; and stickyHeader: true in component will not work; Hence, need to set position again
            justifyContent: 'space-between',
        },
        tableEntries,
        tableTitles,
    };

    const sMMCGoBackIconProps = {
        sx: {
            mr: '2px', // 8px already applied in component
            height: '24px',
            width: '24px',
            '& path': {
                fill: 'none',
                stroke: theme.palette.primary.dashboard.white,
            },
        },
    };

    const sMMCBackBtnProps = {
        children: 'Back to Executive',
        startIcon: <GoBackIcon {...sMMCGoBackIconProps} />,
        onClick: otherProps?.onClose,
        sx: {
            alignSelf: 'center',
            mt: '48px',
        },
    };
    
    return (
        <BlackPopupBG {...sMMCPopupProps}>
            <Fade in={true} timeout={fadeTimingMs}>
                <Box {...sMMCContentBoxProps}>
                    <Typography {...sMMCTitleTypoProps}>{title}</Typography>
                    <Typography {...sMMCDescTypoProps}>{explanation}</Typography>
                    {statsList}
                    <GrowDashboardDetailTable {...sMMCDetailTableProps} />
                    <SGTransparentBGBtn {...sMMCBackBtnProps} />
                </Box>
            </Fade>
        </BlackPopupBG>
    );
}