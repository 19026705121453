import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import BlackPopupBG from "./BlackPopupBG";
import InProdInvTable from "../tables/InProdInvTable";

import { fadeTimingMs, popupClasses } from "../constants";



export default function StrainDetailInProdInvPopup(props) {
    
    const {
        currDarkThemeClass='',
        otherClasses={},
        strains=[],
        title='',

        ...otherProps
    } = props;


    const popupBgProps = {
        ...otherProps,
        otherPaperSxProps: {
            borderRadius: 0,
            minWidth: '90%',
        },
    };    

    const closeIconBoxProps = {
        className: `${popupClasses?.strainDetailInProdInv?.closeIconBox} ${currDarkThemeClass} ${otherClasses?.closeIconBox}`,
    };

    const closeIconProps = {
        className: `${popupClasses?.strainDetailInProdInv?.closeIcon} ${currDarkThemeClass} ${otherClasses?.closeIcon}`,
        
        onClick: otherProps?.onClose,
    };


    const boxProps = {
        className: `${popupClasses?.strainDetailInProdInv?.box} ${currDarkThemeClass} ${otherClasses?.box}`,
    };

    const titleTypoProps = {
        className: `${popupClasses?.strainDetailInProdInv?.titleTypo} ${currDarkThemeClass} ${otherClasses?.titleTypo}`,
    };

    const tablesBoxProps = {
        className: `${popupClasses?.strainDetailInProdInv?.tablesBox} ${currDarkThemeClass} ${otherClasses?.tablesBox}`,
    };

    const inProdInvTableProps = table => ({
        ...table,

        currDarkThemeClass,
        otherClasses: {
            box: popupClasses?.strainDetailInProdInv?.entryTableBox,
            entryRowTitleTypo: popupClasses?.strainDetailInProdInv?.entryRowTitleTypo,
            entryContentTypo: popupClasses?.strainDetailInProdInv?.entryContentTypo,
        },
        showDetailBtn: false,

    });

    const tablesList = strains?.map((e, i) => <InProdInvTable key={i} {...inProdInvTableProps(e)} />);

    return (
        <BlackPopupBG {...popupBgProps}>
            <Fade in={true} timeout={fadeTimingMs}>
                <Box {...boxProps}>
                    <Box {...closeIconBoxProps}><CloseIcon {...closeIconProps} /></Box>
                    
                    <Typography {...titleTypoProps}>{title}</Typography>

                    <Box {...tablesBoxProps}>{tablesList}</Box>

                </Box>
            </Fade>
        </BlackPopupBG>
    );
}