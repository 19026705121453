import { Typography, Stack, Paper, Chip } from '@mui/material';
import React, { useContext } from 'react';
import { EyeIcon2, metricPlanCardClasses, DelIcon2, getMetricPlanRoute, BaseDetailCard } from '../../utility';
import APIService from '../../../api/apiService';
import { useNavigate } from 'react-router';
import { FeedbackContext } from '../../../App';

export default function _MetricplansTab(props) {
  const { metrcplans, currDarkThemeClass, metrcPlanPerms, handleSetValues, handleErrorWithFeedback } = props;
  const feedbackCX = useContext(FeedbackContext)

  const navigate = useNavigate();

  const handleDelete = (id) => () => {
    if (!metrcPlanPerms?.delete) {
        handleErrorWithFeedback({
            response: {
                status: 400,
                data: { text: 'Do not have permissions to delete' }
            }
        });
        return;
    }

    const entityWithID = `metricplan/${id}/`;
    APIService.deleteInstance(entityWithID)
        .then(() => {
            handleSetValues('metrcplans',  metrcplans?.filter((plan) => plan?.id !== id) )
            feedbackCX.setContextValue(true, {
            type: 'success',
            message: 'Deleted Metric Plan successfully.',
            autoHideDuration: 3000,
          }) }
        )
        .catch(handleErrorWithFeedback);
};


  const handleShow = id => () => {
    if (!metrcPlanPerms?.update) {
      handleErrorWithFeedback({ response: { status: 400, data: { text: 'Do Not have permissions to View/update' } } });
      return;
    }
    navigate(getMetricPlanRoute(id));
  };

  const mPaperProps = {
    className: `${metricPlanCardClasses.paperWrapper} ${currDarkThemeClass}`,
    variant: 'square',
    elevation: 0,
  };

  const cardActionBtns = plan => [
    {
      key: 'details',
      title: 'Show All Runs',
      icon: EyeIcon2,
      otherProps: {
        onClick: handleShow(plan?.id),
      },
    },
    {
      key: 'delete',
      title: 'Remove from plan',
      icon: DelIcon2,
      otherClasses: metricPlanCardClasses.delBtn,
      otherProps: {
        onClick: handleDelete(plan?.id),
      },
    },
  ];

  const cdSubHeaderProps = {
    direction: 'row',
    spacing: 2,
    alignItems: 'center',
  };

  const cdSubHeaderTypo = {
    className: `${metricPlanCardClasses.subHeaderTypo} ${currDarkThemeClass}`,
    variant: 'subtitle2',
  };

  const subHeader = plan => (
    <Stack {...cdSubHeaderProps}>
      <Typography {...cdSubHeaderTypo}>Contains: {plan?.runs_count ?? 0} Metric Runs </Typography>
    </Stack>
  );

  const mpCardProps = (key, plan) => ({
    key,
    baseEntity: plan?.name,
    noEntityMsg: 'Unknown Plan',
    extrasTitle: <Chip label={plan?.metrc_type_name} color="primary" variant="outlined" size="small" />,
    currDarkThemeClass,
    cardActionBtns: cardActionBtns(plan),
    subHeader: subHeader(plan),
    isHeaderClickable: true, // Make the header clickable
    headerClickHandler: handleShow(plan?.id), // Use handleShow for navigation
  });

  if (!metrcPlanPerms?.read) return <Typography>No Permissions to View Metric Plans</Typography>;

  return (
    <Paper {...mPaperProps}>
      {metrcplans?.map((plan, i) => (
        <BaseDetailCard {...mpCardProps(i, plan)} />
      ))}
    </Paper>
  );
}
