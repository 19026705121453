import { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { DownArrowIcon } from "./CustomSvgIcons";

import { cardClasses, transitionDuration } from "./constants";


export default function ExpandCard(props) {
    
    const {
        actions=[],
        cardHeaderInfo,
        cardIcon=()=>{},
        cardTitle,
        currDarkThemeClass='',
        disabledActionBtn=false,
        entries=[],
        showTitleTool=true,
        chart = undefined
        // onTitleClick,
    } = props;

    const [toExpand, setToExpand] = useState(false);

    const eCPProps = {
        className: `${cardClasses?.expand?.accordion} ${currDarkThemeClass}`,
        elevation: 0,
        expanded: toExpand,
        square: true,
        // sx: {
        //     backgroundColor: toExpand ? 'primary.100' : 'primary.400',
        //     borderBottom: '1px solid',
        //     borderColor: 'primary.700',
        //     px: '20px',
        //     py: '24px',
        //     transition: `background-color ${transitionDuration}ms ease-in-out`,
        //     '&::before': {
        //         content: 'none',
        //     },
        //     '&.MuiAccordion-root.Mui-expanded': {
        //         my: 0,
        //     },
        // },
    };

    const eCPSummaryProps = {
        className: `${cardClasses?.expand?.accordionSummary} ${currDarkThemeClass}`,
        // sx: {
        //     minHeight: 0,
        //     px: 0,
        //     '&.MuiButtonBase-root:hover': {
        //         cursor: 'default',
        //     },
        //     '&.MuiAccordionSummary-root.Mui-expanded': {
        //         minHeight: 0,
        //     },
        //     '& .MuiAccordionSummary-content': {
        //         flexDirection: 'column',
        //         my: 0,
        //     },
        // },
    };

    const eCPTitleBoxProps = {
        className: `${cardClasses?.expand?.titleBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     width: '100%',
        // },
    };

    const eCPTitleTypoProps = {
        className: `${cardClasses?.expand?.titleTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '20px',
        //     fontWeight: 500,
        //     lineHeight: 1.45,
        // },
    };
    
    const eCPTitleCropIconProps = {
        className: `${cardClasses?.expand?.titleCropIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '24px',
        //     mr: '18px',
        //     verticalAlign: 'sub',
        //     width: '24px',
        //     '& path': {
        //         fill: theme.palette.grey['900'],
        //     },
        // },
    };
    
    const eCPTitleQPTypoProps = {
        className: `${cardClasses?.expand?.titleQPTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: 1.5,
        // },
    };
    
    const eCPTitleActionBoxProps = {
        className: `${cardClasses?.expand?.actionBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: disabledActionBtn ? 'flex-end' : 'space-between',
        //     mt: '24px',
        //     width: '100%',
        // },
    };

    const eCPActionBtnIconProps = {
        className: `${cardClasses?.expand?.actionBtnIcon} ${currDarkThemeClass}`,
        // sx: {
        //     height: '20px',
        //     ml: '6px',
        //     width: '20px',
        //     '& path': {
        //         fill: disabledActionBtn ? disabledColor : theme.palette.grey['900'],
        //     },
        // },
    };

    const eCPSeeMoreIconProps = {
        className: `${cardClasses?.expand?.seeMoreIcon} ${currDarkThemeClass} ${toExpand ? cardClasses?.expand?.expandedState : ''}`,
        // sx: {
        //     height: '24px',
        //     transform: toExpand ? 'rotateZ(180deg)' : 'none',
        //     transition: `transform ${collapseTransition}ms ease-in-out`,
        //     width: '24px',
        //     '& path': {
        //         fill: theme.palette.grey['900'],
        //     },
        // },
    };

    // const eCPActionEditBtnProps = {
    //     children: 'Edit',
    //     disabled: disabledActionBtn,
    //     disableElevation: true,
    //     onClick: onEdit,
    //     size: 'small',
    //     startIcon: <EditIcon {...eCPActionBtnIconProps}/>,
    //     sx: {
    //         alignItems: 'center',
    //         color: 'grey.900',
    //         border: 'none',
    //         fontSize: '16px',
    //         fontWeight: 500,
    //         lineHeight: 1.5,
    //         minWidth: 0,
    //         mr: '24px',
    //         p: 0,
    //         // pl: '6px',
    //         textTransform: 'none',
    //     },
    // };

    // const eCPActionUnlinkBtnProps = {
    //     children: 'Unlink Crop',
    //     disabled: disabledActionBtn,
    //     disableElevation: true,
    //     onClick: onUnlink,
    //     size: 'small',
    //     startIcon: <UnlinkIcon {...eCPActionBtnIconProps}/>,
    //     sx: {
    //         alignItems: 'center',
    //         color: 'grey.900',
    //         border: 'none',
    //         fontSize: '16px',
    //         fontWeight: 500,
    //         lineHeight: 1.5,
    //         minWidth: 0,
    //         // grouping edit and unlink in a Box is adding extra 2.3px; unbundling them removes the extra space; 
    //         // hence, using mr: 'auto' on Unlink to create the group
    //         mr: 'auto',
    //         p: 0,
    //         // pl: '6px',
    //         textTransform: 'none',
    //     },
    // };

    const eCPActionBtnProps = (e, isLastItem) => ({
        children: e?.children ?? '',
        className: `${cardClasses?.expand?.actionBtn} ${currDarkThemeClass}`,
        disabled: disabledActionBtn,
        disableElevation: true,
        onClick: e?.onClick,
        size: 'small',
        startIcon: e?.icon(eCPActionBtnIconProps),
        sx: {
        //     alignItems: 'center',
        //     color: 'grey.900',
        //     border: 'none',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     minWidth: 0,
            mr: isLastItem ? 'auto' : '24px',
        //     p: 0,
        //     // pl: '6px',
        //     textTransform: 'none',
        },
    });

    const actionBtnList = actions?.map((e, i) => 
        <Button key={i} {...eCPActionBtnProps(e, i === actions?.length - 1)} />
    );

    const eCPActionSeeCloseBtnProps = {
        children: toExpand ? 'Close' : 'See More',
        className: `${cardClasses?.expand?.actionSeeCloseBtn} ${currDarkThemeClass}`,
        disableElevation: true,
        disableRipple: true,
        endIcon: <DownArrowIcon {...eCPSeeMoreIconProps}/>,
        onClick: () => setToExpand(state => !state),
        size: 'small',
        sx: {
        //     alignItems: 'center',
        //     color: 'grey.900',
        //     border: 'none',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.5,
        //     minWidth: 0,
        //     p: 0,
            ...(actions?.length < 1 && {
                ml: 'auto',
            }),
        //     // pl: '6px',
        //     textTransform: 'none',
        //     '& .MuiButton-endIcon': {
        //         ml: '7px',
        //     },
        },
    };

    const eCPDetailBoxProps = {
        className: `${cardClasses?.expand?.detailBox} ${currDarkThemeClass}`,
        // sx: {
        //     display: 'flex',
        //     borderBottom: '1px solid',
        //     borderColor: 'grey.200',
        //     flexDirection: 'column',
        //     mt: '24px',
        //     pb: '16px',
        // },
    };

    const eCPDetailEntryBoxProps = {
        className: `${cardClasses?.expand?.detailEntryBox} ${currDarkThemeClass}`,
        // sx: {
        //     alignItems: 'center',
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     mb: '8px',
        //     '&:last-of-type': {
        //         mb: 0,
        //     },
        // },
    };

    const eCPDetailEntryKeyTypoProps = {
        className: `${cardClasses?.expand?.detailEntryKeyTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.900',
        //     fontSize: '16px',
        //     fontWeight: 500,
        //     lineHeight: 1.56,
        // },
    };
    
    const eCPDetailEntryValueTypoProps = {
        className: `${cardClasses?.expand?.detailEntryValueTypo} ${currDarkThemeClass}`,
        // sx: {
        //     color: 'grey.600',
        //     fontSize: '16px',
        //     fontWeight: 400,
        //     lineHeight: 1.56,
        // },
    };

    const entriesList = entries.map((e, i) => 
        <Box key={i} {...eCPDetailEntryBoxProps}>
            <Typography {...eCPDetailEntryKeyTypoProps}>{e.key}</Typography>                     
            <Typography {...eCPDetailEntryValueTypoProps}>{e.value}</Typography>                     
        </Box>
    )

    return (
        <>
            <Accordion {...eCPProps}>
                <AccordionSummary {...eCPSummaryProps}>
                    <Box {...eCPTitleBoxProps}>
                        <Tooltip title={showTitleTool ? cardTitle : ''}>
                            <Typography {...eCPTitleTypoProps}>
                                {/* <CropRunIcon {...eCPTitleCropIconProps}/> {name} */}
                                {cardIcon(eCPTitleCropIconProps)} {cardTitle}
                            </Typography>
                        </Tooltip>
                        <Typography {...eCPTitleQPTypoProps}>
                            {cardHeaderInfo}
                        </Typography>
                    </Box>
                    {
                        <Collapse in={toExpand} timeout={transitionDuration}>
                            <Box {...eCPDetailBoxProps}>
                                {entriesList}
                                {chart}
                            </Box>
                        </Collapse>
                    }
                    <Box {...eCPTitleActionBoxProps}>
                        {
                            !disabledActionBtn && 
                            <>
                                {/* <Button {...eCPActionEditBtnProps} />
                                <Button {...eCPActionUnlinkBtnProps} /> */}
                                {actionBtnList}
                            </>
                        }
                        <Button {...eCPActionSeeCloseBtnProps} />
                    </Box>
                </AccordionSummary>
            </Accordion>
        </>
    )
}